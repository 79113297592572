import React from 'react';
import {
    Button,
    Card,
    CenterLayout,
    ColumnLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import { Nullable } from '@embroker/shotwell/core/types';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Applicant } from '../../entities/Applicant';
import { Coverage } from '../../types/Coverage';
import { AppTypeCode } from '../../types/enums';
import { getCoverageDetails } from './coverageDetails';
import { CoverageCardIcon } from './CoverageCardIcon.view';

const REMOVE_BUTTON_TEXT = 'Remove';
const ADD_COVERAGE_BUTTON_TEXT = 'Get a Quote';
const LEARN_MORE_BUTTON_TEXT = 'Learn More';

interface CoverageCard {
    coverage: Coverage;
    applicant: Nullable<EntityProps<Applicant>>;
    isSelected?: boolean;
    handleAddAndRemoveCoverage: (appType: AppTypeCode, isSelected: boolean) => void;
    handleShowDetails: (appType: AppTypeCode, isSelected?: boolean) => void;
}

export function CoverageCard({
    coverage,
    applicant,
    isSelected,
    handleAddAndRemoveCoverage,
    handleShowDetails,
}: CoverageCard) {
    const { appType } = coverage;
    const { title, description, status, icon } = getCoverageDetails(
        appType,
        applicant?.naicsIndustry,
    );

    return (
        <Card isSelected={isSelected} statusLabel={status}>
            <Card.Header>
                <CoverageCardIcon name={icon} />
                <Text style="heading 5" color="brand-400">
                    {title}
                </Text>
            </Card.Header>
            <Card.Body>
                <Text>{description}</Text>
            </Card.Body>
            <Card.Footer>
                <ColumnLayout>
                    <Button
                        data-e2e={`selectCoverage${appType.replace(/^AppTypeCodeList/, '')}`}
                        onClick={() => {
                            handleAddAndRemoveCoverage(appType, !isSelected);
                        }}
                        appearance="secondary"
                    >
                        {isSelected ? REMOVE_BUTTON_TEXT : ADD_COVERAGE_BUTTON_TEXT}
                    </Button>
                    <CenterLayout>
                        <TextButton
                            onClick={() => {
                                handleShowDetails(appType, isSelected);
                            }}
                        >
                            {LEARN_MORE_BUTTON_TEXT}
                        </TextButton>
                    </CenterLayout>
                </ColumnLayout>
            </Card.Footer>
        </Card>
    );
}
