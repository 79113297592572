import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    Button,
    ColumnLayout,
    GridLayout,
    Notification,
    PageLayout,
    Spinner,
    StackLayout,
    Text,
    TextButton,
    useModal,
} from '@embroker/ui-toolkit/v2';
import React, { useContext, useEffect, useState } from 'react';
import { GetEmbrokerExcessEnabled } from '../../../../quote/embrokerExcess/useCases/GetEmbrokerExcessEnabled';
import { GetActiveUserProfile } from '../../../../userOrg/useCases/GetActiveUserProfile';
import { AppContext } from '../../../../view/AppContext';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { BrokerActivity } from '../activity/BrokerActivity';
import { BrokerConfirmationModal } from '../modals/BrokerConfirmationModal';
import { UploadBORLetterModal } from '../modals/UploadBORLetterModal';
import { BrokerDashboardQuickLinks } from './BrokerDashboardQuickLinks';
import { BrokerKickOutModals, BrokerKickoutModalTypes } from './BrokerKickOutModals';
import { DashboardHeader } from './DashboardHeader';
import { BrokerNaicsAppetite } from './DashboardNaicsAppetite';
import { DigitalQuotesPerWeekReport } from './reports/DigitalQuotesPerWeekReport';
import { GrossPremiumPerWeekReport } from './reports/GrossPremiumPerWeekReport';
import { PoliciesBoundPerWeekReport } from './reports/PoliciesBoundPerWeekReport';

interface BrokerDashboardProps {
    applicationId?: UUID;
    modalType?: BrokerKickoutModalTypes;
}

export function BrokerDashboard({ applicationId, modalType }: BrokerDashboardProps) {
    const { activeSession } = useContext(AppContext);
    const uploadBORLetterModal = useModal();
    const [showNotification, setShowNotification] = useState(false);
    const { navigate } = useNavigation();

    const handleDismiss = () => {
        setShowNotification(false);
    };
    const getStartedLink = URI.build('/shopping/excess-before-you-begin', {
        qd: JSON.stringify({
            app_type_list: ['AppTypeCodeListEmbrokerExcess'],
        }),
    });

    const handleNavigateToStartApplication = () => {
        navigate(getStartedLink);
    };

    const organizationId = activeSession.organizationId;
    const { result: getActiveUserProfileResult, isLoading: isGetActiveUserProfileLoading } =
        useUseCase(GetActiveUserProfile);

    const { result: getEmbrokerExcessEnabledResult } = useUseCase(GetEmbrokerExcessEnabled);

    useEffect(() => {
        if (getEmbrokerExcessEnabledResult !== undefined && isOK(getEmbrokerExcessEnabledResult)) {
            setShowNotification(getEmbrokerExcessEnabledResult.value);
        }
    }, [setShowNotification, getEmbrokerExcessEnabledResult]);

    if (
        isGetActiveUserProfileLoading ||
        getActiveUserProfileResult === undefined ||
        getEmbrokerExcessEnabledResult === undefined
    ) {
        return <Spinner />;
    }

    const handleUploadBORLetter = async () => {
        uploadBORLetterModal.show();
    };

    let dashboardHeading = 'Your Dashboard';
    let isNewBroker = false;
    if (isOK(getActiveUserProfileResult)) {
        const { firstName, lastName, organizationCount } = getActiveUserProfileResult.value;
        dashboardHeading = `${firstName} ${lastName}'s Dashboard`;
        isNewBroker = organizationCount === 0;
    } else {
        container.get<Logger>(Log).error(getActiveUserProfileResult);
    }

    return (
        <React.Fragment>
            {organizationId && applicationId && (
                <BrokerConfirmationModal
                    organizationId={organizationId}
                    applicationId={applicationId}
                />
            )}
            <PageLayout.Section>
                <DashboardHeader />
            </PageLayout.Section>
            {organizationId && modalType && <BrokerKickOutModals modalToShow={modalType} />}
            {uploadBORLetterModal.visible && <UploadBORLetterModal modal={uploadBORLetterModal} />}
            <PageLayout.Section>
                <ColumnLayout
                    split="-1"
                    responsive={{ containerWidth: { smallerThan: 'tablet' } }}
                    center
                >
                    <Text style="heading 2">{dashboardHeading}</Text>
                    <Button size="small" icon="upload" onClick={handleUploadBORLetter}>
                        Upload BOR letter
                    </Button>
                </ColumnLayout>
            </PageLayout.Section>
            {showNotification && (
                <PageLayout.Section>
                    <Notification
                        backgroundColor="neutral-light"
                        image="man"
                        onDismiss={handleDismiss}
                    >
                        <Text style="heading 5">Check Out What's New!</Text>
                        <Text style="microcopy" color="brand-400">
                            Introducing <b>Excess Tech E&O/Cyber:</b> Technology professional
                            liability and cyber coverage over and above an existing
                            (&quot;underlying&quot;) policy or policies. Embroker's all-new Excess
                            Tech E&O/Cyber follows the form of the underlying coverage, and provides
                            increased limits over that insurance program.{' '}
                            <TextButton
                                as="a"
                                href="https://access.embroker.com/for-brokers/excess-tech-eo-cyber-program/"
                                size="small"
                            >
                                <u>Learn more</u>
                            </TextButton>{' '}
                            or start your Application{' '}
                            <TextButton onClick={handleNavigateToStartApplication} size="small">
                                <u>here</u>
                            </TextButton>
                        </Text>
                        .
                    </Notification>
                </PageLayout.Section>
            )}
            <PageLayout.Section>
                <StackLayout gap="24">
                    <Text style="heading 4">Overview</Text>
                    <div>
                        <BrokerDashboardQuickLinks />
                    </div>
                    <BrokerActivity isNewBroker={isNewBroker} />
                </StackLayout>
            </PageLayout.Section>
            <PageLayout.Section>
                <StackLayout gap="24">
                    <Text style="heading 4">Performance</Text>
                    <GridLayout>
                        <GrossPremiumPerWeekReport
                            className="u-grid-size-12"
                            title="Gross Written Premium"
                        />
                        <DigitalQuotesPerWeekReport
                            className="u-grid-size-6"
                            title="Number of quotes"
                            width={460}
                        />
                        <PoliciesBoundPerWeekReport
                            className="u-grid-size-6"
                            title="Number of bound policies"
                            width={460}
                        />
                    </GridLayout>
                </StackLayout>
            </PageLayout.Section>
            <PageLayout.Section>
                <StackLayout gap="24">
                    <Text style="heading 4">Appetite Guide</Text>
                    <BrokerNaicsAppetite />
                </StackLayout>
            </PageLayout.Section>
        </React.Fragment>
    );
}
