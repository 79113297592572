import { execute } from '@embroker/shotwell/core/UseCase';
import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { IndexableObject } from '@embroker/shotwell/core/object';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { PageNotFound } from '@embroker/shotwell/view/components/PageNotFound';
import { map, Matcher, mount, NaviRequest, redirect, route } from 'navi';
import * as React from 'react';
import { bundleRoutes } from '../../../bundle/view/routes/bundleRoutes';
import { GetGlobalConfig } from '../../../config/useCases/GetGlobalConfigUseCase';
import { AppContext } from '../../../view/AppContext';
import { withWizard } from '../../../view/routes';
import { RouteDefinitions } from '../../../view/routes/Route';
import { DeepLinkData } from '../../types/DeepLinkData';
import { RedirectApplicationDataArray } from '../../types/RedirectApplicationDataArray';
import { ShoppingCoverageCodeArray } from '../../types/ShoppingCoverageCodeArray';
import { OptOutFromStreamlineRenewal } from '../../useCases/OptOutFromStreamlineRenewal';
import { ApplicationCreationQuestionnaire } from '../components/ApplicationCreationQuestionnaire';
import { Application } from '../components/ApplicationQuestionnaire';
import { EarlyQuoteReservation } from '../components/EarlyQuoteReservation';
import { RiskAssessment } from '../components/RiskAssessment';
import { ShoppingGuidancePage } from '../components/ShoppingGuidancePage';
import { ShoppingDashboard } from '../components/dashboard/ShoppingDashboard';
import { ExcessBeforeYouBegin } from '../components/excess/ExcessBeforeYouBegin';
import { ExcessThankYou } from '../components/excess/ExcessThankYou';
import { SubmitForReview } from '../components/excess/SubmitForReview';
import { FullRedirectChoose } from '../components/ineligibleScreens/FullRedirectChoose';
import { FullRedirectContinue } from '../components/ineligibleScreens/FullRedirectContinue';
import { PartialQuoteRedirect } from '../components/ineligibleScreens/PartialQuoteRedirect';

import { ErrorCode } from '../../errors';
import { SubmitStreamlineRenewal } from '../../useCases/SubmitStreamlineRenewal';
import { ApplicationSubmission } from '../components/ApplicationSubmission';
import { GuidanceFlowFullyIneligible } from '../components/GuidanceFlowFullyIneligible';
import { MonolineIneligible } from '../components/MonolineIneligible';
import { StreamlineRenewalIneligiblePage } from '../components/ineligibleScreens/StreamlineRenewalIneligiblePage';
import { lawBundleWizardRoutes } from './lawBundleWizardRoutes';
import { GetActiveOrganizationProfile } from '@app/userOrg/useCases/GetActiveOrganizationProfile';
import { MPLVerticalList, NAICS_CODE_TO_VERTICAL } from '@app/userOrg/types/enums';
import { AppTypeCodeListManualWorkersCompensation } from '@app/shopping/types/enums';
import {
    DEFAULT_REDIRECT,
    GUIDANCE_PAGE_REDIRECT,
    LAW_BUNDLE_REDIRECT,
} from '@app/userOrg/useCases/GetOnboardingRedirect';
import { SelectOrganization } from '@app/userOrg/useCases/SelectOrganization';

const lawFirmNAICSCode = '541110';
const companyESPRaisedFunding = true;
const companyPCoMLRaisedFunding = false;
const companyTechEOCyberUnfundedRaisedFunding = false;
const includeTechEO = true;
const cannabisOperationNaicsCode = 'CANNABIS';

interface DeepLinkProps {
    urls: string[];
    redirectUrl: string;
    params?: {
        qd?: string;
        page?: string;
    };
}

const deepLinks: IndexableObject<DeepLinkProps> = {
    lpl: {
        urls: ['/dashboard/lpl', '/shopping/lpl'],
        redirectUrl: 'shopping/questionnaire',
        params: {
            qd: JSON.stringify({
                app_type_list: ['AppTypeCodeListEverestLawyersProfessionalLiability'],
                naics_code: lawFirmNAICSCode,
            }),
        },
    },
    bop: {
        urls: ['/dashboard/bop', '/shopping/bop'],
        redirectUrl: 'shopping/questionnaire',
        params: {
            qd: JSON.stringify({ app_type_list: ['AppTypeCodeListCNABOP'] }),
        },
    },
    esp_do: {
        urls: ['/dashboard/esp_do', '/shopping/esp_do'],
        redirectUrl: 'shopping/questionnaire',
        params: {
            qd: JSON.stringify({
                app_type_list: ['AppTypeCodeListManualDirectorsAndOfficers'],
                raisedFunding: companyESPRaisedFunding,
            }),
        },
    },
    esp_eo: {
        urls: ['/dashboard/esp_eo', '/shopping/esp_eo'],
        redirectUrl: 'shopping/questionnaire',
        params: {
            qd: JSON.stringify({
                app_type_list: ['AppTypeCodeListManualDefaultPL'],
                raisedFunding: companyESPRaisedFunding,
            }),
        },
    },
    esp_all: {
        urls: ['/dashboard/esp_all', '/shopping/esp_all'],
        redirectUrl: 'shopping/questionnaire',
        params: {
            qd: JSON.stringify({
                app_type_list: ['AppTypeCodeListESP'],
                raisedFunding: companyESPRaisedFunding,
            }),
        },
    },
    pcoml_do: {
        urls: ['/dashboard/pcoml_do', '/shopping/pcoml_do'],
        redirectUrl: 'shopping/questionnaire',
        params: {
            qd: JSON.stringify({
                app_type_list: ['AppTypeCodeListManualDirectorsAndOfficers'],
                raisedFunding: companyPCoMLRaisedFunding,
            }),
        },
    },
    pcoml_epl: {
        urls: ['/dashboard/pcoml_epl', '/shopping/pcoml_epl'],
        redirectUrl: 'shopping/questionnaire',
        params: {
            qd: JSON.stringify({
                app_type_list: ['AppTypeCodeListManualEmploymentPractices'],
                raisedFunding: companyPCoMLRaisedFunding,
            }),
        },
    },
    pcoml_do_epl: {
        urls: ['/dashboard/pcoml', '/shopping/pcoml'],
        redirectUrl: 'shopping/questionnaire',
        params: {
            qd: JSON.stringify({
                app_type_list: ['AppTypeCodeListPCoML'],
                raisedFunding: companyPCoMLRaisedFunding,
            }),
        },
    },
    crime: {
        urls: ['/shopping/crime'],
        redirectUrl: 'shopping/questionnaire',
        params: {
            qd: JSON.stringify({
                app_type_list: ['AppTypeCodeListEmbrokerCrime'],
            }),
        },
    },
    cyber: {
        urls: ['/shopping/cyber'],
        redirectUrl: 'shopping/questionnaire',
        params: {
            qd: JSON.stringify({
                app_type_list: ['AppTypeCodeListCyberCowbell'],
            }),
        },
    },
    tech_eo_unfunded: {
        urls: ['/shopping/tech_eo_unfunded'],
        redirectUrl: 'shopping/questionnaire',
        params: {
            qd: JSON.stringify({
                app_type_list: ['AppTypeCodeListEmbrokerCyber'],
                raisedFunding: companyTechEOCyberUnfundedRaisedFunding,
                includeTechEO: includeTechEO,
            }),
        },
    },
    mpl_bundle: {
        urls: ['/shopping/mpl-bundle'],
        redirectUrl: 'shopping/guidance-page',
        params: {
            qd: JSON.stringify({}),
        },
    },
    cannabis_operation: {
        urls: ['/shopping/general_liability_cannabis'],
        redirectUrl: 'shopping/questionnaire',
        params: {
            qd: JSON.stringify({
                app_type_list: ['AppTypeCodeListManualGL'],
                naics_code: cannabisOperationNaicsCode,
            }),
        },
    },
    excess: {
        urls: ['/shopping/excess'],
        redirectUrl: '/shopping/excess-before-you-begin',
        params: {
            qd: JSON.stringify({
                app_type_list: ['AppTypeCodeListEmbrokerExcess'],
            }),
        },
    },
    hartford_bop: {
        urls: ['/shopping/hartford_bop'],
        redirectUrl: 'shopping/questionnaire',
        params: {
            qd: JSON.stringify({
                app_type_list: ['AppTypeCodeListHartfordBOP'],
            }),
        },
    },
};

const deepLinkRoutes: IndexableObject<Matcher<AppContext>> = {};

for (const deepLink of Object.values(deepLinks)) {
    const { urls, redirectUrl, params } = deepLink;
    for (const url of urls) {
        deepLinkRoutes[url] = map(async (request: NaviRequest<AppContext>) => {
            const redirectURI = URI.build('/', redirectUrl, {
                page: params?.page,
                qd: params?.qd,
            });

            return redirect(redirectURI);
        });
    }
}

// Overrides default behaviour for handling WC deeplinks
const handleWorkersCompDeeplinkRedirect = async () => {
    const getActiveOrganizationProfileResp = await execute(GetActiveOrganizationProfile);
    if (isErr(getActiveOrganizationProfileResp)) {
        return route({ view: <ErrorPage errors={getActiveOrganizationProfileResp.errors} /> });
    }

    const naicsCode = getActiveOrganizationProfileResp?.value?.organization?.naics;
    if (naicsCode) {
        const vertical = NAICS_CODE_TO_VERTICAL[naicsCode];
        const isLawVertical = vertical === 'LawFirm';
        const isMPLVertical = MPLVerticalList.includes(vertical);
        if (isLawVertical) {
            return redirect(LAW_BUNDLE_REDIRECT);
        }

        if (isMPLVertical) {
            return redirect(GUIDANCE_PAGE_REDIRECT);
        }

        if (!isLawVertical && !isMPLVertical) {
            const applicationUrl = URI.build('/shopping/questionnaire', {
                qd: JSON.stringify({
                    app_type_list: [AppTypeCodeListManualWorkersCompensation],
                }),
            });
            return redirect(applicationUrl);
        }
    }

    return redirect(DEFAULT_REDIRECT);
};

export const EMBROKER = 'Embroker';

const GET_COVERED_EMBROKER = 'Get Covered | Embroker';

export const routes: RouteDefinitions<AppContext> = {
    ...deepLinkRoutes,
    '/shopping/public': {
        auth: 'none',
        isOrganizationSpecific: false,
        handler: map(async (request: NaviRequest<AppContext>) => {
            // We still want to handle any traffic that is directed to /shopping/public, all traffic will be redirected to the signup
            return redirect('/');
        }),
    },
    '/shopping': map(async (request) => {
        const { organizationId } = request.params;
        return route({
            title: EMBROKER,
            view: <ShoppingDashboard organizationId={organizationId as UUID} />,
            data: { hideHeader: true },
        });
    }),
    '/shopping/questionnaire': withWizard(
        map(async (request: NaviRequest<AppContext>) => {
            const { qd, page: firstPage } = request.params;
            const deepLinkData = DeepLinkData.parse(qd, { firstPage });

            return route({
                title: GET_COVERED_EMBROKER,
                view: <ApplicationCreationQuestionnaire deepLinkData={deepLinkData} />,
                data: { fullscreen: true },
            });
        }),
    ),
    'shopping/law-bundle': mount(lawBundleWizardRoutes),
    '/shopping/opt-out-streamline-renewal': withWizard(
        map(async (request: NaviRequest<AppContext>, context: AppContext) => {
            const { applicationId: queryApplicationId } = request.query;

            if (!queryApplicationId) {
                return route({ view: <PageNotFound /> });
            }

            const validatedApplicationIdResult = UUID.validate(queryApplicationId);
            if (isErr(validatedApplicationIdResult)) {
                return route({ view: <PageNotFound /> });
            }

            const validatedApplicationId = validatedApplicationIdResult.value;

            const optOutResult = await execute(OptOutFromStreamlineRenewal, {
                applicationId: validatedApplicationId,
            });
            if (isErr(optOutResult)) {
                if (
                    optOutResult.errors.some(
                        (err) => err.code === ErrorCode.StreamlineRenewalAlreadyPurchased,
                    )
                ) {
                    return redirect('/policies');
                }
                return route({ view: <ErrorPage errors={optOutResult.errors} /> });
            }

            return redirect(
                URI.build('/shopping/application', {
                    applicationId: validatedApplicationId,
                    page: 'basic_info',
                }),
            );
        }),
    ),
    '/shopping/application': withWizard(
        map(async (request: NaviRequest<AppContext>, context: AppContext) => {
            const { applicationId: applicationIdQueryArgument } = request.query;
            const { page } = request.params;
            const pageInternal = typeof page === 'string' ? page.replace(/-/g, '_') : undefined;
            const validatedApplicationIdResult = UUID.validate(applicationIdQueryArgument);

            if (isErr(validatedApplicationIdResult)) {
                return route({ view: <PageNotFound /> });
            }

            return route({
                title: GET_COVERED_EMBROKER,
                view: (
                    <Application
                        applicationId={validatedApplicationIdResult.value}
                        page={pageInternal}
                    />
                ),
                data: { fullscreen: true },
            });
        }),
    ),
    '/shopping/application/partial-quote-redirect': map(
        async (request: NaviRequest<AppContext>) => {
            const { shoppingCoverageList, redirectionData, quoteURI } = request.query;
            const shoppingCoverageListResult =
                ShoppingCoverageCodeArray.decode(shoppingCoverageList);
            if (isErr(shoppingCoverageListResult)) {
                return route({ view: <PageNotFound /> });
            }
            const redirectionDataResult = RedirectApplicationDataArray.decode(redirectionData);
            if (isErr(redirectionDataResult)) {
                return route({ view: <PageNotFound /> });
            }
            const decodedQuoteURI = URI.decodeURIComponent(quoteURI);
            if (isErr(decodedQuoteURI)) {
                return route({ view: <PageNotFound /> });
            }

            return route({
                title: EMBROKER,
                view: (
                    <PartialQuoteRedirect
                        eligibleShoppingCoverages={shoppingCoverageListResult.value}
                        redirectApplicationListData={redirectionDataResult.value}
                        quoteURI={decodedQuoteURI.value}
                    />
                ),
                data: { fullscreen: true },
            });
        },
    ),
    '/shopping/application/full-redirect-continue': map(
        async (request: NaviRequest<AppContext>) => {
            const { redirectApplicationId } = request.query;
            if (!UUID.check(redirectApplicationId)) {
                return route({ view: <PageNotFound /> });
            }
            return route({
                title: EMBROKER,
                view: <FullRedirectContinue redirectApplicationId={redirectApplicationId} />,
                data: { fullscreen: true },
            });
        },
    ),
    '/shopping/application/full-redirect-choose': map(async (request: NaviRequest<AppContext>) => {
        const { redirectionData } = request.query;
        const redirectionDataResult = RedirectApplicationDataArray.decode(redirectionData);
        if (isErr(redirectionDataResult)) {
            return route({ view: <PageNotFound /> });
        }
        if (redirectionDataResult.value.length === 0) {
            return route({ view: <PageNotFound /> });
        }
        return route({
            title: EMBROKER,
            view: <FullRedirectChoose redirectApplicationDataList={redirectionDataResult.value} />,
            data: { fullscreen: true },
        });
    }),
    '/shopping/early-quote-reservation': map(async () => {
        return route({
            title: EMBROKER,
            view: <EarlyQuoteReservation />,
            data: { fullscreen: true },
        });
    }),
    '/shopping/excess-before-you-begin': {
        auth: 'any',
        isOrganizationSpecific: false,
        handler: map(async (request) => {
            const { qd } = request.params;
            const { applicationId } = request.query;
            let validatedApplicationId: UUID | undefined;
            if (applicationId !== undefined) {
                const validatedApplicationIdResult = UUID.validate(applicationId);

                if (isErr(validatedApplicationIdResult)) {
                    return route({ view: <PageNotFound /> });
                }

                validatedApplicationId = validatedApplicationIdResult.value;
            }
            return route({
                title: EMBROKER,
                view: <ExcessBeforeYouBegin qd={qd} applicationId={validatedApplicationId} />,
                data: { fullscreen: true },
            });
        }),
    },
    '/shopping/excess-submit-for-review': map(async (request: NaviRequest<AppContext>) => {
        const { applicationId } = request.query;

        if (applicationId == null) {
            return route({ view: <PageNotFound /> });
        }

        const validatedApplicationIdResult = UUID.validate(applicationId);
        if (isErr(validatedApplicationIdResult)) {
            return route({ view: <PageNotFound /> });
        }

        const validatedApplicationId = validatedApplicationIdResult.value;
        return route({
            title: EMBROKER,
            view: <SubmitForReview applicationId={validatedApplicationId} />,
            data: { fullscreen: true },
        });
    }),
    '/shopping/excess-thank-you': map(async () => {
        return route({
            title: EMBROKER,
            view: <ExcessThankYou />,
            data: { fullscreen: true },
        });
    }),
    '/shopping/guidance-page': map(async (req) => {
        const getGlobalConfigResp = await execute(GetGlobalConfig);
        const isMplEnabled =
            isOK(getGlobalConfigResp) && getGlobalConfigResp.value.config.isMplEnabled;

        if (!isMplEnabled) {
            return redirect('/');
        }

        const mplGuidanceRequested = req.params.mplGuidanceRequested === 'true';

        return route({
            title: 'Guidance Page',
            data: { fullscreen: true },
            view: (
                <ShoppingGuidancePage
                    organizationId={req.params.organizationId as UUID}
                    isMPLGuidance={mplGuidanceRequested}
                />
            ),
        });
    }),
    '/shopping/risk-assessment': map(async (req) => {
        const appIdValidated = UUID.validate(req.params.applicationId);
        if (isErr(appIdValidated)) {
            container.get<Logger>(Log).error(appIdValidated);
            return redirect('/');
        }
        const orgIdValidated = UUID.validate(req.params.organizationId);
        if (isErr(orgIdValidated)) {
            container.get<Logger>(Log).error(orgIdValidated);
            return redirect('/');
        }

        return route({
            title: EMBROKER,
            view: (
                <RiskAssessment
                    applicationId={appIdValidated.value}
                    organizationId={orgIdValidated.value}
                    completed={/^true$/i.test(req.params.completed)}
                />
            ),
            data: { fullscreen: true },
        });
    }),
    '/shopping/application/guided-flow-ineligible': map(async (req) => {
        return route({
            view: <GuidanceFlowFullyIneligible />,
            data: { fullscreen: true },
        });
    }),
    '/shopping/application/mono-line-ineligible': map(async (req) => {
        return route({
            view: <MonolineIneligible />,
            data: { fullscreen: true },
        });
    }),
    '/shopping/application/submission': map(async (req) => {
        return route({
            title: 'Application submission',
            view: <ApplicationSubmission taskId={req.params.taskId as UUID} />,
            data: { fullscreen: true },
        });
    }),
    '/shopping/select-applicant-redirect': {
        auth: 'default',
        isOrganizationSpecific: false,
        handler: map(async (req: NaviRequest<AppContext>) => {
            const { applicantId, next, ...other } = req.params;
            if (UUID.check(applicantId)) {
                await execute(SelectOrganization, {
                    organizationId: applicantId,
                });
            }

            return redirect(URI.build(next, other));
        }),
    },
    '/shopping/submit-streamline-renewal': map(async (req) => {
        const { applicationId: reqApplicationId } = req.params;
        if (!reqApplicationId) {
            return route({ view: <PageNotFound /> });
        }
        const validatedApplicationIdResult = UUID.validate(reqApplicationId);
        if (isErr(validatedApplicationIdResult)) {
            return route({ view: <PageNotFound /> });
        }
        const validatedApplicationId = validatedApplicationIdResult.value;

        const streamlineSubmissionResult = await execute(SubmitStreamlineRenewal, {
            applicationId: validatedApplicationId,
        });
        if (isErr(streamlineSubmissionResult)) {
            if (
                streamlineSubmissionResult.errors.some(
                    (error) => error.code == ErrorCode.StreamlineRenewalNotEligible,
                )
            ) {
                return route({
                    view: (
                        <StreamlineRenewalIneligiblePage applicationId={validatedApplicationId} />
                    ),
                    data: { fullscreen: true },
                });
            } else if (
                streamlineSubmissionResult.errors.some(
                    (error) => error.code == ErrorCode.StreamlineRenewalAlreadyPurchased,
                )
            ) {
                return redirect('/policies');
            } else if (
                streamlineSubmissionResult.errors.some(
                    (error) => error.code == ErrorCode.StreamlineRenewalAlreadyQuoted,
                )
            ) {
                return redirect(
                    URI.build('/shopping/application/quote/lpl/signature', {
                        applicationId: validatedApplicationId,
                    }),
                );
            } else {
                return route({
                    title: 'Error',
                    view: <ErrorPage errors={streamlineSubmissionResult.errors} />,
                    data: { fullscreen: true },
                });
            }
        }

        const taskId = streamlineSubmissionResult.value.taskId;

        if (!taskId) {
            return route({
                title: 'Error',
                view: <ErrorPage />,
                data: { fullscreen: true },
            });
        }

        return redirect(
            URI.build('/shopping/application/submission', {
                taskId: streamlineSubmissionResult.value.taskId,
            }),
        );
    }),

    'shopping/bundle': mount(bundleRoutes),

    'shopping/cyber-cowbell': mount(bundleRoutes),

    'shopping/wc': map(handleWorkersCompDeeplinkRedirect),
    'dashboard/wc': map(handleWorkersCompDeeplinkRedirect),
};
