import { Hyperlink, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { CoverageCatalog } from '../../CoverageCatalog';
import { BundleQuote } from '../../entities/BundleQuote';
import { BundleCoverageAppStatusEnum } from '../../types/BundleQuoteCoverage';
import { BundleQuoteEndorsements } from './BundleQuoteEndorsements';

export interface BundleQuoteFooterProps {
    bundleQuote: BundleQuote;
}

export function BundleQuoteFooter({ bundleQuote }: BundleQuoteFooterProps) {
    const preBindEndorsements = bundleQuote.getPreBindEndorsements();

    return (
        <StackLayout gap="56">
            {Object.keys(preBindEndorsements).length > 0 && (
                <BundleQuoteEndorsements
                    preBindEndorsements={preBindEndorsements}
                    bundleQuote={bundleQuote}
                />
            )}
            <StackLayout gap="20">
                {bundleQuote.getSelectedCoverages().map((coverage) => {
                    const Component = CoverageCatalog.getFooterComponent(coverage.type);
                    if (
                        !Component ||
                        coverage.status === BundleCoverageAppStatusEnum.NotEligible ||
                        !coverage.questionnaireData ||
                        !CoverageCatalog.isQuestionnaireDataValidType(
                            coverage.questionnaireData,
                            coverage.type,
                        )
                    ) {
                        return null;
                    }
                    return (
                        <Component
                            key={coverage.type}
                            questionnaireData={coverage.questionnaireData}
                        />
                    );
                })}
                <Text color="ui-500" style="body 2" data-e2e="cyber-carrier-description-terms">
                    AM Best Ratings shown are subject to&nbsp;
                    <Hyperlink href="http://www.embroker.com/terms" target="_blank">
                        Embroker's Terms
                    </Hyperlink>
                    . Please&nbsp;
                    <Hyperlink href="mailto:hello@embroker.com">contact us</Hyperlink> with any
                    questions.
                </Text>
            </StackLayout>
        </StackLayout>
    );
}
