import { isDateAfter, isDateBefore } from '@embroker/service-app-engine';
import { Data, Immutable, isNumber } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    Card,
    ColumnLayout,
    Form,
    Icon,
    Modal,
    ModalActions,
    ModalState,
    StackLayout,
    StatusMessage,
    Text,
    TextButton,
    Tooltip,
    useModal,
} from '@embroker/ui-toolkit/v2';
import React, { useContext } from 'react';
import { WizardForm } from '../../../../../view/hooks/useWizardForm';
import { espCoverageDetails } from '../../../../espUtils/quoteDocumentUtils';
import {
    CoverageRestriction,
    ShoppingCoverageCodeListCyberSplit,
} from '../../../types/CoverageRestriction';
import { ESPCoverageRateItem } from '../../../types/ESPRate';
import { InsuranceApplicationRestriction } from '../../../types/InsuranceApplicationRestriction';
import {
    getCyberSplitLimitRetentionOptions,
    getLimitRetentionOptions,
    getRestrictedLimitRetentionOptions,
} from '../ESPCoverage/limitRetentionOptions';
import { ESPQuoteOptionsFormData } from '../ESPQuoteLandingPage';
import { FAQModalContent } from '../Modals/FAQModal';
import { HowMuchCoverageModalContent } from '../Modals/HowMuchCoverageModal';
import { HowWeCompareModalContent } from '../Modals/HowWeCompareModal';
import {
    AnswersContext,
    coverageIsSelectedField,
    coverageLevelField,
    coverageLimitField,
    coverageRetentionField,
} from './ESPCoveragesPage';
import { ESPDoDifferenceModal } from './ESPDoDifferenceModal';
import { ESPEoCyberDifferenceModal } from './ESPEoCyberDifferenceModal';
import { ESPEplDifferenceModal } from './ESPEplDifferenceModal';
import { AppContext } from '../../../../../view/AppContext';

function getLimitValue(limit: number): Money {
    return Money.tryFromFloat(limit);
}

function getRetentionValue(retention: number, coverageRestriction?: CoverageRestriction): Money {
    const retentionMoney = Money.tryFromFloat(retention);

    if (!coverageRestriction) {
        return retentionMoney;
    }

    return Money.isLessThan(retentionMoney, coverageRestriction.minRetention)
        ? coverageRestriction.minRetention
        : retentionMoney;
}

function getLevel(
    value?: string,
    coverageRestriction?: CoverageRestriction,
): { value?: string; values: { value: string; title: string }[] } {
    if (coverageRestriction && !coverageRestriction.allowPlus) {
        return {
            value: 'standard',
            values: [
                {
                    value: 'standard',
                    title: 'Standard',
                },
            ],
        };
    }

    return {
        value: value,
        values: [
            {
                value: 'plus',
                title: 'Plus',
            },
            {
                value: 'standard',
                title: 'Standard',
            },
        ],
    };
}

interface ESPSelectedCoverageProps {
    coverage: ESPCoverageRateItem;
    revenue: number;
    isSubmitting: boolean;
    fields: WizardForm<OpaqueForm<ESPQuoteOptionsFormData>>['fields'];
    coverageRestriction?: CoverageRestriction;
    newInsurerDocumentsReleaseDate?: Date;
    fiduciaryDocumentsReleaseDate?: Date;
    submittedAt?: Date;
    isQuoteReferred: boolean;
    restrictions?: Immutable<InsuranceApplicationRestriction>;
    higherLimitRequest?: number;
    state?: string;
    showEpliStandaloneInCalifornia?: () => void;
}

export const ESPSelectedCoverage = ({
    coverage,
    fields,
    isSubmitting,
    coverageRestriction,
    newInsurerDocumentsReleaseDate,
    fiduciaryDocumentsReleaseDate,
    submittedAt,
    isQuoteReferred,
    restrictions,
    higherLimitRequest,
    state,
    showEpliStandaloneInCalifornia,
}: ESPSelectedCoverageProps) => {
    const { answers } = useContext(AnswersContext);
    const limitField = coverageLimitField(coverage.coverageType, fields);
    const retentionField = coverageRetentionField(coverage.coverageType, fields);
    const levelField = coverage.level && coverageLevelField(coverage.coverageType, fields);
    const isSelectedField = coverageIsSelectedField(coverage.coverageType, fields);

    const faqModal = useModal();
    const howWeCompareModal = useModal();
    const howMuchCoverageModal = useModal();
    const doModal = useModal();
    const epliModal = useModal();
    const eoCyberModal = useModal();
    const modals: Data<ModalState & ModalActions> = {
        do: doModal,
        epli: epliModal,
        eoCyber: eoCyberModal,
        techCyber: eoCyberModal,
    };

    const { globalConfig } = useContext(AppContext);

    if (!limitField || !retentionField) {
        return null;
    }

    const getCoverageUrl = (
        coverageType: 'do' | 'epli' | 'fiduciary' | 'eoCyber' | 'techCyber',
        newInsurerDocumentsReleaseDate?: Date,
        fiduciaryDocumentsReleaseDate?: Date,
        submittedAt?: Date,
        effectiveDate?: Date,
    ): string => {
        const espIptChangesEnabled = globalConfig?.espIptChangesEnabled;
        const releaseDate = globalConfig?.espIptChangesReleaseDate;
        if (
            espIptChangesEnabled &&
            releaseDate &&
            effectiveDate &&
            !isDateBefore(effectiveDate, releaseDate)
        ) {
            switch (coverageType) {
                case 'do':
                case 'epli':
                case 'techCyber':
                    return espCoverageDetails[coverageType].document[
                        coverage.level == 'standard'
                            ? 'post_ipt_2024_standard'
                            : 'post_ipt_2024_plus'
                    ];
            }
        }

        if (
            fiduciaryDocumentsReleaseDate !== undefined &&
            submittedAt !== undefined &&
            coverageType == 'fiduciary'
        ) {
            if (isDateAfter(submittedAt, fiduciaryDocumentsReleaseDate)) {
                return espCoverageDetails['fiduciary'].document.new_standard;
            }
        }

        if (newInsurerDocumentsReleaseDate !== undefined && submittedAt !== undefined) {
            if (isDateAfter(submittedAt, newInsurerDocumentsReleaseDate)) {
                if (coverageType == 'fiduciary') {
                    return espCoverageDetails['fiduciary'].document.standard;
                }
                if (coverageType == 'do' || coverageType == 'epli') {
                    return espCoverageDetails[coverageType].document[
                        coverage.level == 'standard' ? 'new_standard' : 'new_plus'
                    ];
                }
                return espCoverageDetails[coverageType].document[coverage.level ?? 'old'];
            }
        }
        return coverageType != 'fiduciary'
            ? espCoverageDetails[coverageType].document[coverage.level ?? 'old']
            : espCoverageDetails['fiduciary'].document.standard;
    };

    if (globalConfig === undefined) {
        return null;
    }

    const effectiveDate = fields.startDate.props.value;
    const isClearBlueToEverspanSwitchActive =
        effectiveDate !== undefined &&
        isDateAfter(effectiveDate, globalConfig.espClearBlueEverspanSwitchDate);

    const showEPLIStandaloneModal = () => {
        if (
            globalConfig?.espIptChangesEnabled &&
            isEpliStandalone(fields, coverage.coverageType, state) &&
            showEpliStandaloneInCalifornia
        ) {
            showEpliStandaloneInCalifornia();
        }
    };

    if (coverage.coverageType == 'techCyber') {
        const cyberRestriction = restrictions?.coverageRestrictions.find(
            (cr) => cr.coverageType === ShoppingCoverageCodeListCyberSplit,
        );

        const cyberLimits = restrictions?.areManualRestrictionsApplied
            ? getLimitRetentionOptions(coverage.coverageType, undefined, true, cyberRestriction)
                  .limits
            : getCyberSplitLimitRetentionOptions(true).limits;

        return (
            <Card
                data-e2e={`${coverage.coverageType}-card`}
                onDismiss={() => {
                    fields.isCyberSelected.props.onChange({
                        target: {
                            value: false,
                        },
                    });

                    fields.isTechSelected.props.onChange({
                        target: {
                            value: false,
                        },
                    });

                    showEPLIStandaloneModal();
                }}
                menuItems={[
                    <TextButton
                        target="_blank"
                        key={'download' + coverage.coverageType + 'Coverage'}
                        href={getCoverageUrl(
                            coverage.coverageType,
                            newInsurerDocumentsReleaseDate,
                            fiduciaryDocumentsReleaseDate,
                            submittedAt,
                            effectiveDate,
                        )}
                    >
                        Download coverage details
                    </TextButton>,
                    <TextButton onClick={faqModal.show} key="1">
                        FAQs
                    </TextButton>,
                    <TextButton onClick={howWeCompareModal.show} key="2">
                        How We Compare
                    </TextButton>,
                ]}
            >
                <Card.Header>
                    <Icon name={espCoverageDetails[coverage.coverageType].icon} />
                    <Text style="heading 5">{espCoverageDetails[coverage.coverageType].title}</Text>
                </Card.Header>
                <Card.Body>
                    <StackLayout>
                        <Text>{espCoverageDetails[coverage.coverageType].text}</Text>
                        <Text style="heading 5">
                            Tech E&O
                            <Tooltip
                                trigger="click"
                                iconSize="small"
                                text={
                                    levelField?.props.value === 'plus'
                                        ? 'The Tech E&O limits are for the Liability coverages, including Insuring Agreements 1, 2, 3, and 4 on the "PLUS" form.'
                                        : 'The Tech E&O limits are for the Liability coverages, including Insuring Agreements 1a, 1b, and 2a on the "Standard" form.'
                                }
                            />
                        </Text>
                        <ColumnLayout
                            gap="16"
                            responsive={{ screenWidth: { smallerThan: 'tablet' } }}
                        >
                            <Form.Field
                                data-e2e="tech-limit"
                                type={fields.techLimit.type}
                                inputProps={{
                                    ...fields.techLimit.props,
                                    value:
                                        fields.techLimit.props.value === undefined
                                            ? undefined
                                            : Money.toFloat(
                                                  getLimitValue(fields.techLimit.props.value),
                                              ),
                                    filterable: false,
                                    items: getLimitRetentionOptions(
                                        coverage.coverageType,
                                        undefined,
                                        true,
                                    ).limits,
                                    label: 'Limit',
                                    disabled:
                                        getRestrictedLimitRetentionOptions(
                                            coverage.coverageType,
                                            coverageRestriction,
                                        ).limits.length == 1 || isSubmitting,
                                    onChange: (e: { target: { value: number } }) => {
                                        fields.techLimit.props.onChange(e);
                                        if (
                                            fields.cyberLimit.props.value &&
                                            e.target.value &&
                                            isNumber(e.target.value)
                                        ) {
                                            if (fields.cyberLimit.props.value > e.target.value) {
                                                fields.cyberLimit.props.onChange(e);
                                            }
                                        }
                                    },
                                }}
                            />
                            <Form.Field
                                data-e2e="tech-retention"
                                type={fields.techRetention.type}
                                inputProps={{
                                    ...fields.techRetention.props,
                                    value:
                                        fields.techRetention.props.value === undefined
                                            ? undefined
                                            : Money.toFloat(
                                                  getRetentionValue(
                                                      fields.techRetention.props.value,
                                                      coverageRestriction,
                                                  ),
                                              ),
                                    filterable: false,
                                    items: getRestrictedLimitRetentionOptions(
                                        coverage.coverageType,
                                        coverageRestriction,
                                        answers,
                                    ).retentions,
                                    label: 'Retention',
                                    disabled:
                                        getRestrictedLimitRetentionOptions(
                                            coverage.coverageType,
                                            coverageRestriction,
                                        ).retentions.length == 1 || isSubmitting,
                                    onChange: (e: { target: { value: number } }) => {
                                        fields.techRetention.props.onChange(e);
                                        fields.cyberRetention.props.onChange(e);
                                    },
                                }}
                            />
                        </ColumnLayout>
                        <Text style="heading 5">
                            1st Party Expenses
                            <Tooltip
                                trigger="click"
                                iconSize="small"
                                text="These cover 1st party expenses after a network security event. These limits are rarely contractually required, so you’re unlikely to need higher coverage limits — plus, lowering the limit is a good way to reduce your premium!"
                            />
                        </Text>
                        <ColumnLayout
                            gap="16"
                            responsive={{ screenWidth: { smallerThan: 'tablet' } }}
                        >
                            <Form.Field
                                data-e2e="cyber-limit"
                                type={fields.cyberLimit.type}
                                inputProps={{
                                    ...fields.cyberLimit.props,
                                    value:
                                        fields.cyberLimit.props.value === undefined
                                            ? undefined
                                            : Money.toFloat(
                                                  getLimitValue(fields.cyberLimit.props.value),
                                              ),
                                    filterable: false,
                                    items: cyberLimits.filter(
                                        (item) =>
                                            fields.techLimit.props.value !== undefined &&
                                            item.value <= fields.techLimit.props.value,
                                    ),
                                    label: 'Limit',
                                    disabled:
                                        getRestrictedLimitRetentionOptions(
                                            coverage.coverageType,
                                            cyberRestriction,
                                        ).limits.length == 1 || isSubmitting,
                                }}
                            />
                            <Form.Field
                                data-e2e="cyber-retention"
                                type={fields.cyberRetention.type}
                                inputProps={{
                                    ...fields.cyberRetention.props,
                                    value:
                                        fields.techRetention.props.value === undefined
                                            ? undefined
                                            : Money.toFloat(
                                                  getRetentionValue(
                                                      fields.techRetention.props.value,
                                                      cyberRestriction,
                                                  ),
                                              ),
                                    filterable: false,
                                    items: getRestrictedLimitRetentionOptions(
                                        coverage.coverageType,
                                        cyberRestriction,
                                        answers,
                                    ).retentions,
                                    label: 'Retention',
                                    readOnly: true,
                                }}
                            />
                        </ColumnLayout>
                        {levelField && !isQuoteReferred ? (
                            <React.Fragment>
                                <ColumnLayout split="-1">
                                    <Text style="heading 5">Select your coverage </Text>
                                    <TextButton onClick={modals[coverage.coverageType].show}>
                                        What's the difference?
                                    </TextButton>
                                </ColumnLayout>
                                <Form.Field
                                    type={levelField.type}
                                    inputProps={{
                                        ...levelField.props,
                                        value: getLevel(levelField.props.value, coverageRestriction)
                                            .value,
                                        items: getLevel(levelField.props.value, coverageRestriction)
                                            .values,
                                        disabled: isSubmitting,
                                    }}
                                    messages={levelField.messages}
                                />
                            </React.Fragment>
                        ) : null}
                    </StackLayout>
                </Card.Body>
                <Card.Footer>
                    {!isQuoteReferred ? (
                        <Text as="span" style="body 1" color="brand-500">
                            Premium:{' '}
                            <Text as="span" style="heading 5">
                                <MoneyDisplay value={coverage.premium} />
                            </Text>
                        </Text>
                    ) : null}
                </Card.Footer>
                <Modal size="large" {...eoCyberModal}>
                    <ESPEoCyberDifferenceModal />
                </Modal>
                <FAQModalContent
                    modal={faqModal}
                    isClearBlueToEverspanSwitchActive={isClearBlueToEverspanSwitchActive}
                />
                <HowWeCompareModalContent
                    modal={howWeCompareModal}
                    coverageType={coverage.coverageType}
                />
                <HowMuchCoverageModalContent
                    modal={howMuchCoverageModal}
                    coverageType={coverage.coverageType}
                />
                <Modal size="large" {...doModal}>
                    <ESPDoDifferenceModal />
                </Modal>
                <Modal size="large" {...epliModal}>
                    <ESPEplDifferenceModal />
                </Modal>
            </Card>
        );
    }

    const showEpliWarning =
        globalConfig?.espIptChangesEnabled && state === 'CA' && coverage.coverageType === 'epli';

    return (
        <Card
            onDismiss={() => {
                isSelectedField?.props.onChange({
                    target: {
                        value: false,
                    },
                });

                showEPLIStandaloneModal();
            }}
            menuItems={[
                <TextButton
                    target="_blank"
                    key={'download' + coverage.coverageType + 'Coverage'}
                    href={getCoverageUrl(
                        coverage.coverageType,
                        newInsurerDocumentsReleaseDate,
                        fiduciaryDocumentsReleaseDate,
                        submittedAt,
                        effectiveDate,
                    )}
                >
                    Download coverage details
                </TextButton>,
                <TextButton onClick={faqModal.show} key="1">
                    FAQs
                </TextButton>,
                <TextButton onClick={howWeCompareModal.show} key="2">
                    How We Compare
                </TextButton>,
                coverage.coverageType != 'fiduciary' && (
                    <TextButton onClick={howMuchCoverageModal.show}>
                        How Much Coverage Do I Need
                    </TextButton>
                ),
            ]}
            data-e2e={`${coverage.coverageType}-card`}
        >
            <Card.Header>
                <Icon name={espCoverageDetails[coverage.coverageType].icon} />
                <Text style="heading 5" data-e2e="tile-header">
                    {espCoverageDetails[coverage.coverageType].title}
                </Text>
            </Card.Header>
            <Card.Body>
                <StackLayout>
                    <Text>{espCoverageDetails[coverage.coverageType].text}</Text>
                    {showEpliWarning && (
                        <StatusMessage status="info">
                            To purchase Employment Practices Liability digitally, you must include
                            at least one other coverage as part of your quote
                        </StatusMessage>
                    )}
                    <ColumnLayout gap="16" responsive={{ screenWidth: { smallerThan: 'tablet' } }}>
                        <Form.Field
                            type={limitField.type}
                            data-e2e={`${coverage.coverageType}-limit`}
                            inputProps={{
                                ...limitField.props,
                                value:
                                    limitField.props.value === undefined
                                        ? undefined
                                        : Money.toFloat(getLimitValue(limitField.props.value)),
                                filterable: false,
                                items: higherLimitRequest
                                    ? getRestrictedLimitRetentionOptions(
                                          coverage.coverageType,
                                          coverageRestriction,
                                          undefined,
                                      ).limits
                                    : getLimitRetentionOptions(
                                          coverage.coverageType,
                                          undefined,
                                          true,
                                          coverageRestriction,
                                      ).limits,
                                label: 'Limit',
                                disabled:
                                    getRestrictedLimitRetentionOptions(
                                        coverage.coverageType,
                                        coverageRestriction,
                                    ).limits.length == 1 || isSubmitting,
                            }}
                        />
                        <Form.Field
                            type={retentionField.type}
                            data-e2e={`${coverage.coverageType}-retention`}
                            inputProps={{
                                ...retentionField.props,
                                value:
                                    retentionField.props.value === undefined
                                        ? undefined
                                        : Money.toFloat(
                                              getRetentionValue(
                                                  retentionField.props.value,
                                                  coverageRestriction,
                                              ),
                                          ),
                                filterable: false,
                                items: getRestrictedLimitRetentionOptions(
                                    coverage.coverageType,
                                    coverageRestriction,
                                    answers,
                                ).retentions,
                                label: 'Retention',
                                disabled:
                                    getRestrictedLimitRetentionOptions(
                                        coverage.coverageType,
                                        coverageRestriction,
                                    ).retentions.length == 1 || isSubmitting,
                            }}
                        />
                    </ColumnLayout>
                    {levelField && !isQuoteReferred ? (
                        <React.Fragment>
                            <ColumnLayout split="-1">
                                <Text style="heading 5">Select your coverage </Text>
                                <TextButton onClick={modals[coverage.coverageType].show}>
                                    What's the difference?
                                </TextButton>
                            </ColumnLayout>
                            <Form.Field
                                type={levelField.type}
                                inputProps={{
                                    ...levelField.props,
                                    value: getLevel(levelField.props.value, coverageRestriction)
                                        .value,
                                    items: getLevel(levelField.props.value, coverageRestriction)
                                        .values,
                                    disabled: isSubmitting,
                                }}
                                messages={levelField.messages}
                            />
                        </React.Fragment>
                    ) : null}
                </StackLayout>
            </Card.Body>
            <Card.Footer>
                {!isQuoteReferred ? (
                    <Text as="span" style="body 1" color="brand-500">
                        Premium:{' '}
                        <Text as="span" style="heading 5">
                            <MoneyDisplay value={coverage.premium} />
                        </Text>
                    </Text>
                ) : null}
            </Card.Footer>
            <Modal size="large" {...eoCyberModal}>
                <ESPEoCyberDifferenceModal />
            </Modal>
            <FAQModalContent
                modal={faqModal}
                isClearBlueToEverspanSwitchActive={isClearBlueToEverspanSwitchActive}
            />
            <HowWeCompareModalContent
                modal={howWeCompareModal}
                coverageType={coverage.coverageType}
            />
            <HowMuchCoverageModalContent
                modal={howMuchCoverageModal}
                coverageType={coverage.coverageType}
            />
            <Modal size="large" {...doModal}>
                <ESPDoDifferenceModal />
            </Modal>
            <Modal size="large" {...epliModal}>
                <ESPEplDifferenceModal />
            </Modal>
        </Card>
    );
};

function isEpliStandalone(
    fields: WizardForm<OpaqueForm<ESPQuoteOptionsFormData>>['fields'],
    coverageToBeDeselected: 'do' | 'epli' | 'fiduciary' | 'eoCyber' | 'techCyber',
    state?: string,
): boolean {
    if (coverageToBeDeselected === 'epli') {
        return false;
    }

    const isEplSelected = fields.isEplSelected?.props?.value;

    if (!isEplSelected) {
        return false;
    }

    if (state !== 'CA' && isEplSelected) {
        return false;
    }

    const fieldsNameMap: { [key: string]: string } = {
        ['do']: 'isDnoSelected',
        ['fiduciary']: 'isFiduciarySelected',
        ['eoCyber']: 'isEoSelected',
        ['techCyber']: 'isTechSelected',
    };

    const coveragesFields = Object.values(fieldsNameMap).filter((item) => {
        return item !== fieldsNameMap[coverageToBeDeselected];
    });

    for (const field of coveragesFields) {
        const isFieldSelected = (fields as any)[field]?.props?.value;
        if (isFieldSelected) {
            return false;
        }
    }

    return true;
}
