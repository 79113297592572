import React from 'react';
import { Text } from '@embroker/ui-toolkit/v2';
import { execute } from '@embroker/shotwell/core/UseCase';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { LandingPageLayout } from '../../../view/components/LandingPageLayout.view';
import { PublishShoppingUserClickEvent } from '../../useCases/PublishShoppingUserClickEvent';

export function MonolineIneligible() {
    const { navigate } = useNavigation();

    const handleOnClick = () => {
        execute(PublishShoppingUserClickEvent, {
            clickEventName: 'Ineligibility Shop all Coverages CTA clicked',
        });
        navigate('/shopping');
    };

    return (
        <LandingPageLayout
            title="We're sorry!"
            primaryAction={{
                label: 'Shop All Coverages',
                onClick: handleOnClick,
            }}
            imageProps={{
                width: '100%',
                name: 'full-ineligibility',
            }}
        >
            <Text style="body 1" color="brand-400">
                Unfortunately, based on some of your responses, your company isn't eligible for this
                coverage at this time. We'll be in touch if our guidelines change.
            </Text>
            <Text style="body 1" color="brand-400">
                In the meantime, feel free to shop our full suite of policies for other coverages
                you may need.
            </Text>
        </LandingPageLayout>
    );
}
