export const UserStatusCodeList = [
    'UserStatusCodeListUserWithPassword',
    'UserStatusCodeListUserWithoutPassword',
    'UserStatusCodeListNotAUser',
    'UserStatusCodeListArchivedUser',
] as const;
type UserStatusCodeList = typeof UserStatusCodeList;
export type UserStatusCode = UserStatusCodeList[number];

export const MainAreaOfFocusList = [
    { title: 'Adult Entertainment', value: 'Adult Entertainment' },
    { title: 'Advertising and Digital Marketing', value: 'Advertising and Digital Marketing' },
    { title: 'Agriculture & Farming', value: 'Agriculture & Farming' },
    { title: 'Artificial Intelligence', value: 'Artificial Intelligence' },
    { title: 'Autonomous Vehicles', value: 'Autonomous Vehicles' },
    { title: 'Biotechnology: Drug Development', value: 'Biotechnology: Drug Development' },
    { title: 'Biotechnology: All Other', value: 'Biotechnology: All Other' },
    { title: 'Blockchain Technology', value: 'Blockchain Technology' },
    { title: 'Business Process Outsourcing', value: 'Business Process Outsourcing' },
    { title: 'Cannabis', value: 'Cannabis' },
    { title: 'Clothing & Apparel', value: 'Clothing & Apparel' },
    {
        title: 'Cloud Infrastructure and Cloud Storage',
        value: 'Cloud Infrastructure and Cloud Storage',
    },
    { title: 'Consumer Goods', value: 'Consumer Goods' },
    { title: 'Consumer Research', value: 'Consumer Research' },
    { title: 'Cryptocurrency: ICO or planned ICO', value: 'Cryptocurrency: ICO or planned ICO' },
    {
        title: 'Cryptocurrency: no ICO or sale of tokens',
        value: 'Cryptocurrency: no ICO or sale of tokens',
    },
    { title: 'Cyber Security', value: 'Cyber Security' },
    { title: 'Data and Analytics', value: 'Data and Analytics' },
    { title: 'Database', value: 'Database' },
    { title: 'Digital Media & Entertainment', value: 'Digital Media & Entertainment' },
    { title: 'Drones', value: 'Drones' },
    { title: 'E-Commerce and Shopping', value: 'E-Commerce and Shopping' },
    { title: 'Educational Software / EdTech', value: 'Educational Software / EdTech' },
    { title: 'Energy & Renewables', value: 'Energy & Renewables' },
    { title: 'Enterprise Software', value: 'Enterprise Software' },
    { title: 'Fin-Tech: Broker/Dealer', value: 'Fin-Tech: Broker/Dealer' },
    { title: 'Fin-Tech: Personal Finance', value: 'Fin-Tech: Personal Finance' },
    { title: 'Fin-Tech: Lending', value: 'Fin-Tech: Lending' },
    { title: 'Fin-Tech: Payments', value: 'Fin-Tech: Payments' },
    { title: 'Fin-Tech: Consumer Banking', value: 'Fin-Tech: Consumer Banking' },
    { title: 'Fin-Tech: Investment Management', value: 'Fin-Tech: Investment Management' },
    { title: 'Food & Beverage', value: 'Food & Beverage' },
    { title: 'Government & Military / CivicTech', value: 'Government & Military / CivicTech' },
    {
        title: 'Hardware - Manufacturing and Development',
        value: 'Hardware - Manufacturing and Development',
    },
    { title: 'Health & Beauty', value: 'Health & Beauty' },
    { title: 'Health Care', value: 'Health Care' },
    { title: 'Hospitality', value: 'Hospitality' },
    { title: 'HR Technology', value: 'HR Technology' },
    { title: 'Insurance Services', value: 'Insurance Services' },
    { title: 'IoT', value: 'IoT' },
    { title: 'Medical Devices', value: 'Medical Devices' },
    { title: 'Messaging & Telecommunications', value: 'Messaging & Telecommunications' },
    { title: 'Navigation & Mapping', value: 'Navigation & Mapping' },
    { title: 'Network Hardware', value: 'Network Hardware' },
    { title: 'Network Security', value: 'Network Security' },
    { title: 'Online Gaming', value: 'Online Gaming' },
    {
        title: 'On Demand Marketplace / Sharing Economy',
        value: 'On Demand Marketplace / Sharing Economy',
    },
    { title: 'Professional Services', value: 'Professional Services' },
    { title: 'Real Estate Software & Services', value: 'Real Estate Software & Services' },
    { title: 'Retail Technology', value: 'Retail Technology' },
    { title: 'Robotics', value: 'Robotics' },
    { title: 'SaaS - All Other', value: 'SaaS - All Other' },
    { title: 'Social Networking & Collaboration', value: 'Social Networking & Collaboration' },
    { title: 'Software Development', value: 'Software Development' },
    { title: 'Sustainability / Greentech', value: 'Sustainability / Greentech' },
    { title: 'Transportation', value: 'Transportation' },
    { title: 'Virtual Reality Software & Hardware', value: 'Virtual Reality Software & Hardware' },
    { title: 'Wearables', value: 'Wearables' },
    { title: 'Web Design', value: 'Web Design' },
    { title: 'Web Service Providers', value: 'Web Service Providers' },
];

const VerticalList = [
    'LawFirm',
    'Accounting',
    'TaxPreparation',
    'RealEstateAgent',
    'HomeInspectorAndBuildingInspectionServices',
    'NonTechnologyConsultants',
    'TechCompanies',
] as const;
type VerticalList = typeof VerticalList;
export type Vertical = VerticalList[number];

export const MPLVerticalList = [
    'Accounting',
    'TaxPreparation',
    'RealEstateAgent',
    'HomeInspectorAndBuildingInspectionServices',
    'NonTechnologyConsultants',
];

type MPLVerticalList = typeof MPLVerticalList;
export type MPLVertical = MPLVerticalList[number];

export const NAICS_CODE_TO_VERTICAL: { [key: string]: Vertical } = {
    541110: 'LawFirm',
    541211: 'Accounting',
    541213: 'TaxPreparation',
    531210: 'RealEstateAgent',
    541350: 'HomeInspectorAndBuildingInspectionServices',
    541611: 'NonTechnologyConsultants',
    541612: 'NonTechnologyConsultants',
    541613: 'NonTechnologyConsultants',
    541614: 'NonTechnologyConsultants',
    541618: 'NonTechnologyConsultants',
    541620: 'NonTechnologyConsultants',
    334413: 'TechCompanies',
    541715: 'TechCompanies',
    541519: 'TechCompanies',
    454110: 'TechCompanies',
    517919: 'TechCompanies',
    519190: 'TechCompanies',
    541490: 'TechCompanies',
    518210: 'TechCompanies',
    541714: 'TechCompanies',
    334418: 'TechCompanies',
    517312: 'TechCompanies',
    334220: 'TechCompanies',
    541513: 'TechCompanies',
    334310: 'TechCompanies',
    334111: 'TechCompanies',
    334419: 'TechCompanies',
    517410: 'TechCompanies',
    541713: 'TechCompanies',
    519130: 'TechCompanies',
    511210: 'TechCompanies',
    415110: 'TechCompanies',
    541512: 'TechCompanies',
    541690: 'TechCompanies',
    541511: 'TechCompanies',
};
