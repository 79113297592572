import React from 'react';
import {
    ComplexFormFieldViewProps,
    getFormFieldProps,
    getInputProps,
} from '../../types/ComplexFieldTypes';
import { ColumnLayout, Form, SelectInputAsync, StackLayout } from '@embroker/ui-toolkit/v2';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { execute } from '@embroker/shotwell/core/UseCase';
import { GetInvestors } from '../../../../../shopping/useCases/GetInvestors';

type FundraisingRound = {
    fundraiseDate: Date;
    moneyRaised: string;
    leadInvestor: string;
};

export function assertFundraisingRoundType(input: unknown): input is FundraisingRound {
    if (typeof input !== 'object' || input === null) {
        return false;
    }
    return 'fundraiseDate' in input || 'moneyRaised' in input || 'leadInvestor' in input;
}

export const fundraisingRoundFormatValidationError = (error: InvalidArgument) => {
    return `${error.details.argument}-${error.details.validator}`;
};

const getInvestors = async (searchPattern: string) => {
    const getInvestorsResult = await execute(GetInvestors, { searchPattern });

    if (isErr(getInvestorsResult)) {
        return Promise.resolve([]);
    }
    const { value: investors } = getInvestorsResult;
    const options = (investors || []).map((investor) => ({
        label: investor,
        value: investor,
    }));

    return Promise.resolve(options);
};

const parseMessageForInput = (
    messages: any,
    inputName: keyof FundraisingRound,
    inputLabel: string,
) => {
    const defaultMessage = `Please enter at least one fundraising round with complete date, amount and investor name`;

    const inputMessage = messages
        .map((message: string) => {
            const [fieldName, validator] = message.split('-');

            return {
                fieldName,
                validator,
            };
        })
        .filter(({ fieldName }: { fieldName: string }) => fieldName === inputName)
        .map(({ validator }: { validator: string }) => validator)[0];

    if (inputMessage) {
        let message = 'Please enter a valid ' + inputLabel;

        switch (inputMessage) {
            case 'string.empty': {
                message = 'You must enter your ' + inputLabel;
                break;
            }

            case 'string.pattern.base':
            case 'string.pattern.name': {
                message = defaultMessage;
                break;
            }
        }
        return [message];
    }

    return [];
};

export function FundraisingRoundFormFieldView({
    questionProps,
    inputFieldProps,
    onComplexFieldChange,
}: ComplexFormFieldViewProps) {
    const { key } = questionProps;
    const { messages } = inputFieldProps;
    const formFieldProps = getFormFieldProps(questionProps);
    const inputProps = getInputProps(questionProps, inputFieldProps);
    const inputValue = assertFundraisingRoundType(inputProps.value) ? inputProps.value : undefined;

    const initialInvestorOptions = inputValue?.leadInvestor
        ? [
              {
                  label: inputValue.leadInvestor,
                  value: inputValue.leadInvestor,
              },
          ]
        : [];

    return (
        <Form.Field {...formFieldProps}>
            <StackLayout gap="12">
                <Form.Field
                    title="Fundraise Date"
                    type="month"
                    label="MM/YYYY"
                    inputProps={{
                        value: inputValue?.fundraiseDate,
                        onChange: (e) =>
                            onComplexFieldChange(key, {
                                ...inputValue,
                                fundraiseDate: e.target.value,
                            }),
                    }}
                    messages={parseMessageForInput(messages, 'fundraiseDate', 'fundraise date')}
                />
                <ColumnLayout grow="fixed">
                    <Form.Field
                        label="e.g. $1,000,000"
                        type="number"
                        title="Money Raised"
                        inputProps={{
                            value: inputValue?.moneyRaised,
                            onChange: (e) =>
                                onComplexFieldChange(key, {
                                    ...inputValue,
                                    moneyRaised: e.target.value,
                                }),
                        }}
                        messages={parseMessageForInput(messages, 'moneyRaised', 'money raised')}
                    />
                    <Form.Field
                        title="Lead Investor"
                        inputProps={{
                            value: inputValue?.leadInvestor,
                        }}
                        messages={parseMessageForInput(messages, 'leadInvestor', 'lead investor')}
                    >
                        <SelectInputAsync
                            label="Lead Investor"
                            request={getInvestors}
                            maxMenuHeight={100}
                            value={inputValue?.leadInvestor}
                            options={initialInvestorOptions}
                            hasErrors={
                                parseMessageForInput(messages, 'leadInvestor', 'lead investor')
                                    ?.length > 0
                            }
                            onChange={(newValue) =>
                                onComplexFieldChange(key, {
                                    ...inputValue,
                                    leadInvestor: newValue.value,
                                })
                            }
                        ></SelectInputAsync>
                    </Form.Field>
                </ColumnLayout>
            </StackLayout>
        </Form.Field>
    );
}
