import { QuoteExtended } from '@embroker/shotwell-api/app';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, Failure, isErr } from '@embroker/shotwell/core/types/Result';
import { startOfToday } from 'date-fns';
import { QuoteExpiration } from '../../../quote/types/QuoteExpiration';
import { QuotingEngineWCChubb } from '../../../shopping/types/enums';
import {
    BundleCoverageTypeEnum,
    BundleQuoteCoverage,
    BundleQuoteCoverageMetadata,
} from '../../types/BundleQuoteCoverage';
import { toBundleQuoteCoverage } from '../bundleMappingFunctions';
import { WCChubbQuote } from './entities/WCChubbQuote';
import { toWCChubbQuoteDetails, toWCChubbQuoteOptions } from './mappingFunctions';
import { JSONSerdes } from '@embroker/shotwell/core/encoding';
import { QuestionnaireData } from '../../types/BundleQuoteQuestionnaireData';

export async function buildWCChubbCoverage(
    wcChubbQuoteExtended: Immutable<QuoteExtended>,
    isBroker: boolean,
): AsyncResult<BundleQuoteCoverage<WCChubbQuote>, OperationFailed | InvalidArgument> {
    if (!wcChubbQuoteExtended) {
        return Failure(OperationFailed({ message: 'wcChubbQuoteExtended is null or undefined' }));
    }
    const wcChubbCoverageMetadata = getWcCoverageMetadata();

    const questionnaireDataResult = JSONSerdes.deserialize(wcChubbQuoteExtended.questionnaire_data);
    if (isErr(questionnaireDataResult)) {
        return Failure(
            InvalidArgument({
                argument: 'questionnaire_data',
                value: wcChubbQuoteExtended.questionnaire_data,
            }),
        );
    }

    const questionnaireData = QuestionnaireData.create(
        questionnaireDataResult.value as QuestionnaireData,
    );
    if (isErr(questionnaireData)) {
        return Failure(
            InvalidArgument({
                argument: 'Questionnaire data',
                value: questionnaireData.errors,
            }),
        );
    }

    if (!wcChubbQuoteExtended.quote) {
        return toBundleQuoteCoverage<WCChubbQuote>(
            BundleCoverageTypeEnum.WCChubbBundleCoverage,
            wcChubbQuoteExtended.app_status,
            wcChubbCoverageMetadata,
            questionnaireData.value,
            wcChubbQuoteExtended.app_valid_until,
        );
    }
    if (!wcChubbQuoteExtended.quote.details.wc_chubb) {
        return Failure(OperationFailed({ message: 'WCChubbQuoteDetails is null or undefined' }));
    }
    if (!wcChubbQuoteExtended.quote.options.wc_chubb) {
        return Failure(OperationFailed({ message: 'WCChubbQuoteOptions is null or undefined' }));
    }

    const wcChubbCoverage = await WCChubbQuote.create({
        isIndication: wcChubbQuoteExtended.quote.is_indication,
        id: wcChubbQuoteExtended.quote.id,
        applicationId: wcChubbQuoteExtended.quote.app_id,
        details: toWCChubbQuoteDetails(wcChubbQuoteExtended.quote.details.wc_chubb),
        options: toWCChubbQuoteOptions(wcChubbQuoteExtended.quote.options.wc_chubb),
        status: 'draft',
        totalPayable: wcChubbQuoteExtended.quote.total_payable,
        totalPremium: wcChubbQuoteExtended.quote.total_premium,
        annualTechnologyFee: wcChubbQuoteExtended.quote.annual_technology_fee,
        daysToExpire: QuoteExpiration.getDaysLeftUntilExpiration({
            quotingEngine: QuotingEngineWCChubb,
            applicationStatus: wcChubbQuoteExtended.app_status,
            isBroker: isBroker,
            quoteEffectiveDate: wcChubbQuoteExtended.quote.options.wc_chubb.effective_date,
            today: startOfToday(),
            validUntil: wcChubbQuoteExtended.app_valid_until || null,
        }),
        quoteNumber: wcChubbQuoteExtended.quote.quote_number,
        fees: wcChubbQuoteExtended.quote.fees || undefined,
        fileKey: wcChubbQuoteExtended.quote.file_key || undefined,
    });

    if (isErr(wcChubbCoverage)) {
        return Failure(
            InvalidArgument({ argument: 'WC Chubb Coverage', value: wcChubbCoverage.errors }),
        );
    }

    return toBundleQuoteCoverage<WCChubbQuote>(
        BundleCoverageTypeEnum.WCChubbBundleCoverage,
        wcChubbQuoteExtended.app_status,
        wcChubbCoverageMetadata,
        questionnaireData.value,
        wcChubbQuoteExtended.app_valid_until,
        wcChubbCoverage.value,
    );
}

export const getWcCoverageMetadata = (): BundleQuoteCoverageMetadata => {
    return {
        title: 'Workers Compensation',
        description:
            'Subject to state law, WC covers employee injuries/disease occurring the course/scope of employment. Employers Liability responds to claims alleging employer negligence.',
        icon: 'worker',
        name: 'wc',
    };
};
