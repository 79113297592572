import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Immutable } from '@embroker/shotwell/core/types';
import { USD } from '@embroker/shotwell/core/types/Money';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import {
    Card,
    CardLayout,
    ColumnLayout,
    GridLayout,
    StackLayout,
    StatusLabel,
    Text,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { DisplayPolicy } from '../../../../policy/types/DisplayPolicy';
import { Application } from '../../../../shopping/entities/Application';
import { ProductIcon } from '../../../../shopping/view/components/ProductIcon';
import { getCoverageLabel } from './BrokerClientPage';
import { ClientPageRenewalsActionButton } from './ClientPageRenewalsActionButton';

export interface ClientPageRenewalsProps {
    renewals: Immutable<Array<EntityProps<Application>>>;
    activePolicies: Immutable<DisplayPolicy[]>;
    orgId: UUID;
}

export function ClientPageRenewals({ renewals, activePolicies, orgId }: ClientPageRenewalsProps) {
    const noItems = renewals.length === 0;
    return (
        <StackLayout gap="24">
            <StackLayout>
                <Text style="heading 4">Upcoming Renewals</Text>
                <Text style="default">
                    Policies can be renewed up to 90 days in advance of the renewal date.
                </Text>
            </StackLayout>
            {!noItems ? (
                <GridLayout>
                    {renewals.map((app) => {
                        const policy = activePolicies.find(
                            (policy) => policy.id === app.renewedPolicyIdList[0],
                        );
                        const appType = app.appType;
                        return (
                            <Card key={app.id} className="u-grid-size-6" appearance="loose">
                                <Card.Header>
                                    <ProductIcon type={appType}></ProductIcon>
                                    <Text style="heading 5">{getCoverageLabel(appType)}</Text>
                                </Card.Header>
                                <Card.Body>
                                    <StackLayout gap="8">
                                        <ColumnLayout gap="4">
                                            <Text color="ui-400">Previous premium: </Text>
                                            <Text color="ui-500">
                                                <MoneyDisplay
                                                    value={policy?.premiumPerYear ?? USD(0)}
                                                />
                                            </Text>
                                        </ColumnLayout>
                                        <ColumnLayout gap="4" split="-1">
                                            <Text color="ui-400">Policy expires:</Text>
                                            <Text color="ui-500">
                                                {policy ? (
                                                    <DateDisplay
                                                        format="M/d/yyyy"
                                                        value={policy.endDate}
                                                    />
                                                ) : (
                                                    'soon'
                                                )}
                                            </Text>
                                            <StatusLabel type="purple">
                                                Up for a renewal
                                            </StatusLabel>
                                        </ColumnLayout>
                                    </StackLayout>
                                </Card.Body>
                                <Card.Footer>
                                    <ClientPageRenewalsActionButton
                                        application={app}
                                        organizationId={orgId}
                                    />
                                </Card.Footer>
                            </Card>
                        );
                    })}
                </GridLayout>
            ) : (
                <CardLayout>
                    <CardLayout.Body>
                        <Text style="body 1">Client does not have policy renewals yet.</Text>
                    </CardLayout.Body>
                </CardLayout>
            )}
        </StackLayout>
    );
}
