import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Immutable } from '@embroker/shotwell/core/types';
import { FormData, OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { StackLayout } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { Quote } from '../../../quote/entities/Quote';
import { WizardForm } from '../../../view/hooks/useWizardForm';
import { CoverageCatalog } from '../../CoverageCatalog';
import { distributedPrefix } from '../../coverageDefinition/coverageDefinition';
import { BundleQuote } from '../../entities/BundleQuote';
import {
    BundleCoverageAppStatusEnum,
    BundleCoverageType,
    BundleQuoteCoverageType,
    QuestionnaireDataType,
} from '../../types/BundleQuoteCoverage';
import { BundleQuoteFormData } from '../../types/BundleQuoteFormData';
import { BundleQuoteOrganization } from '../../types/BundleQuoteOrganization';
import { BundleQuoteCoverage } from './BundleQuoteCoverage';
import { BundleQuoteCoverageListTitle } from './BundleQuoteCoverageListTitle.view';
import { Documents } from './BundleQuoteLandingPage';
import { BundleQuoteCoverageMultiCoverage } from './BundleQuoteCoverageMultiCoverage';

export interface UnderlyingCoverageProps<
    Q extends EntityProps<Quote>,
    T extends FormData = distributedPrefix<Q['options']>,
> {
    fields: WizardForm<OpaqueForm<T>>['fields'];
    value: T;
    setValue: (value: T) => void;
    trigger: WizardForm<OpaqueForm<T>>['trigger'];
    quote: Q;
    questionnaireData: QuestionnaireDataType<Q>;
    organizationInfo: BundleQuoteOrganization;
    disabled?: boolean;
}

export interface BundleQuoteCoverageListProps<T extends BundleQuoteFormData> {
    fields: WizardForm<OpaqueForm<T>>['fields'];
    value: T;
    trigger: WizardForm<OpaqueForm<T>>['trigger'];
    setValue: (value: any) => void;
    handleCoverageSelectToggle: (checked: boolean, coverageType: BundleCoverageType) => void;
    submit: () => void;
    previous: () => void;
    hasPrevious: boolean;
    messages: readonly string[];
    quoteHasExpired: boolean;
    appHasExpired: boolean;
    bundleQuote: BundleQuote;
    documents: Documents;
    newFlowEnabled: boolean;
    isDirty: boolean;
}

export function BundleQuoteCoverageList<T extends BundleQuoteFormData>({
    bundleQuote,
    fields,
    quoteHasExpired,
    appHasExpired,
    trigger,
    handleCoverageSelectToggle,
    setValue,
    value,
    isDirty = false,
}: BundleQuoteCoverageListProps<T>) {
    const bindable = bundleQuote.coverageList.filter(
        (coverage) =>
            coverage.status === BundleCoverageAppStatusEnum.QuotesReady &&
            !coverage.quote?.isIndication,
    );
    const referred = bundleQuote.coverageList.filter(
        (coverage) =>
            coverage.status === BundleCoverageAppStatusEnum.Referred ||
            coverage.status === BundleCoverageAppStatusEnum.ReferredAwaitingReview ||
            coverage.quote?.isIndication,
    );
    const notEligible = bundleQuote.coverageList.filter(
        (coverage) =>
            coverage.status === BundleCoverageAppStatusEnum.NotEligible ||
            coverage.status === BundleCoverageAppStatusEnum.ClearanceFailed,
    );

    function renderCoverage(
        coverage: Immutable<BundleQuoteCoverageType>,
        referred = false,
        ineligible = false,
    ) {
        const coverageDefinition = CoverageCatalog.findCoverageDefinitionByType(coverage.type);

        const isFormDirty = isDirty;
        const isProductFieldsDirty = CoverageCatalog.isAnyProductFieldDirty(
            coverage.type,
            bundleQuote,
            fields,
        );

        const isCoverageDirty = isFormDirty || isProductFieldsDirty;
        const isMultipleCoverageCoverageType = CoverageCatalog.isMultipleCoverageCoverageType(
            coverage.type,
        );

        const BundleQuoteCoverageComponent = isMultipleCoverageCoverageType
            ? BundleQuoteCoverageMultiCoverage
            : BundleQuoteCoverage;

        return coverageDefinition ? (
            <BundleQuoteCoverageComponent
                key={coverage.type}
                coverage={coverage}
                organizationInfo={bundleQuote.organizationInfo}
                fields={fields}
                quoteHasExpired={quoteHasExpired}
                appHasExpired={appHasExpired}
                isDirty={isCoverageDirty}
                value={value}
                handleCoverageSelectToggle={handleCoverageSelectToggle}
                setValue={setValue}
                trigger={trigger}
                isSelected={bundleQuote.isCoverageSelected(coverage.type)}
                isReferred={referred}
                isNotEligible={ineligible}
            />
        ) : null;
    }

    return (
        <StackLayout gap="48" className="u-1/1">
            {bindable.length > 0 && (
                <StackLayout gap="24" className="u-1/1">
                    {bindable.map((coverage) => {
                        return renderCoverage(coverage);
                    })}
                </StackLayout>
            )}
            {referred.length > 0 && (
                <StackLayout gap="24" className="u-1/1">
                    <BundleQuoteCoverageListTitle
                        title="Needs Review"
                        toolTip="The policy indicated below will need to be reviewed by an underwriter (UW). Once they've made a decision, they will be in touch via email in 1-2 business days."
                    />
                    {referred.map((coverage) => {
                        return renderCoverage(coverage, true);
                    })}
                </StackLayout>
            )}
            {notEligible.length > 0 && (
                <StackLayout gap="24" className="u-1/1">
                    <BundleQuoteCoverageListTitle
                        title="Ineligible Policies"
                        toolTip="Unfortunately, based on some of your responses, your company isn't eligible for this coverage at this time. We'll be in touch if our guidelines change."
                    />
                    {notEligible.map((coverage) => {
                        return renderCoverage(coverage, false, true);
                    })}
                </StackLayout>
            )}
        </StackLayout>
    );
}
