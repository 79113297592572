import { ContainerModule } from '@embroker/shotwell/core/di';
import { Module } from '../Module';
import { PolicyFilterRepository } from './repositories/PolicyFilterRepository';
import { PolicyFilterRepositoryImpl } from './repositories/PolicyFilterRepository/PolicyFilterRepositoryImpl';
import { PolicyRepository } from './repositories/PolicyRepository';
import { APIPolicyRepository } from './repositories/PolicyRepository/APIPolicyRepository';
import { CreateManagePolicyRequest } from './useCases/CreateManagePolicyRequest';
import { GetDocumentURL } from './useCases/GetDocumentURL';
import { GetPolicies } from './useCases/GetPolicies';
import { GetPolicy } from './useCases/GetPolicy';
import { GetPolicyFilter } from './useCases/GetPolicyFilter';
import { GetPolicySeries } from './useCases/GetPolicySeries';
import { GetPolicyTransferRequestSignedDate } from './useCases/GetPolicyTransferRequestSignedDate';
import { GetUnprocessedPoliciesUseCase } from './useCases/GetUnprocessedPolicies';
import { SendManagePolicyRequestUseCase } from './useCases/SendManagePolicyRequest';
import { UpdatePolicyFilter } from './useCases/UpdatePolicyFilter';
import { UploadPolicy } from './useCases/UploadPolicy';
import { routes } from './view/routes';
import { PublishReturnFromAscendEvent } from './useCases/PublishReturnFromAscendEvent';

export const PolicyModule: Module = {
    routes,
    container: new ContainerModule((bind) => {
        bind<GetPolicies>(GetPolicies.type).to(GetPolicies).inSingletonScope();
        bind<GetPolicy>(GetPolicy.type).to(GetPolicy).inSingletonScope();
        bind<GetPolicySeries>(GetPolicySeries.type).to(GetPolicySeries).inSingletonScope();
        bind<UploadPolicy>(UploadPolicy.type).to(UploadPolicy).inSingletonScope();
        bind<PolicyRepository>(PolicyRepository).to(APIPolicyRepository).inSingletonScope();
        bind<PolicyFilterRepository>(PolicyFilterRepository)
            .to(PolicyFilterRepositoryImpl)
            .inSingletonScope();
        bind<GetDocumentURL>(GetDocumentURL.type).to(GetDocumentURL).inSingletonScope();
        bind<CreateManagePolicyRequest>(CreateManagePolicyRequest.type)
            .to(CreateManagePolicyRequest)
            .inSingletonScope();
        bind<SendManagePolicyRequestUseCase>(SendManagePolicyRequestUseCase.type)
            .to(SendManagePolicyRequestUseCase)
            .inSingletonScope();
        bind<GetUnprocessedPoliciesUseCase>(GetUnprocessedPoliciesUseCase.type)
            .to(GetUnprocessedPoliciesUseCase)
            .inSingletonScope();
        bind<GetPolicyFilter>(GetPolicyFilter.type).to(GetPolicyFilter).inSingletonScope();
        bind<UpdatePolicyFilter>(UpdatePolicyFilter.type).to(UpdatePolicyFilter).inSingletonScope();
        bind<GetPolicyTransferRequestSignedDate>(GetPolicyTransferRequestSignedDate.type)
            .to(GetPolicyTransferRequestSignedDate)
            .inSingletonScope();
        bind<PublishReturnFromAscendEvent>(PublishReturnFromAscendEvent.type)
            .to(PublishReturnFromAscendEvent)
            .inSingletonScope();
    }),
};
