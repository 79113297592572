import { errorCodes } from '@embroker/shotwell/core/Error';
import { ErrorObject } from '@embroker/shotwell/core/types/Result';
import { Immutable } from '@embroker/shotwell/core/types';

export const ErrorCode = errorCodes({
    PurchaseQuoteNotConsistent: 0x2200,
});

export type PurchaseQuoteNotConsistent = ErrorObject<
    typeof ErrorCode.PurchaseQuoteNotConsistent,
    {}
>;

export function PurchaseQuoteNotConsistent(): Immutable<PurchaseQuoteNotConsistent> {
    return {
        name: 'PurchaseQuoteNotConsistent',
        code: ErrorCode.PurchaseQuoteNotConsistent,
        message:
            'We’re finding the best options for your request and will send you a revised proposal shortly.',
        details: {},
    };
}
