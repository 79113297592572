import { USD } from '@embroker/shotwell/core/types/Money';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    BoxLayout,
    Card,
    ColumnLayout,
    Form,
    Icon,
    ReactProps,
    SelectChangeEvent,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import { addYears, startOfToday } from 'date-fns';
import React, { useCallback, useContext } from 'react';
import { WizardForm } from '../../../../view/hooks/useWizardForm';
import { WCGAQuote } from '../../entities/WCGAQuote';
import { WCGAQuoteOptionsFormData } from './WCGAQuoteLandingPage';
import { WOS_PDF_URL } from './wos/constants';
import { AppContext } from '../../../../view/AppContext';
import { hasRole } from '../../../../userOrg/entities/Session';
import { EffectiveDate } from '../../../types/EffectiveDate';

interface WCGAQuoteStepProps extends ReactProps<'div'> {
    quote: WCGAQuote;
    fields: WizardForm<OpaqueForm<WCGAQuoteOptionsFormData>>['fields'];
    trigger: WizardForm<OpaqueForm<WCGAQuoteOptionsFormData>>['trigger'];
    setValue: WizardForm<OpaqueForm<WCGAQuoteOptionsFormData>>['setValue'];
    value: WizardForm<OpaqueForm<WCGAQuoteOptionsFormData>>['value'];
    isSubmitting: boolean;
}

/**
 * Constant sum of money for display in the component
 */
const eachAccident = USD(100000000);
/**
 * Constant sum of money for display in the component
 */
const eachEmployee = USD(100000000);
/**
 * Constant sum of money for display in the component
 */
const totalLimit = USD(100000000);

const DATE_FORMAT = 'MM/dd/yyyy';
const MAX_FUTURE_DAYS_ALLOWED = 90;

export const WCGACoverage = ({
    quote,
    trigger,
    setValue,
    fields,
    value,
    isSubmitting,
    className,
}: WCGAQuoteStepProps) => {
    const { activeSession } = useContext(AppContext);
    const isAdmin = hasRole(activeSession, 'admin');
    const handleStartDateChange = (event: { target: { value: string } }) => {
        const newDate = new Date(event.target.value);
        const isNewDateDifferentDay = newDate > value.startDate || newDate < value.startDate;
        if (isNewDateDifferentDay) {
            setValue({
                ...value,
                startDate: newDate,
            });
            trigger('update');
        }
    };

    const handleWoSChange = (event: SelectChangeEvent<boolean, false>) => {
        const newWoSValue = event.target.value;
        setValue({
            ...value,
            includeBlanketWoS: newWoSValue === true,
        });
        trigger('update');
    };

    const isEffectiveDateSelectable = useCallback(
        (effectiveDate: Date) =>
            EffectiveDate.isSelectedEffectiveDateValid(
                effectiveDate,
                startOfToday(),
                MAX_FUTURE_DAYS_ALLOWED,
                isAdmin,
            ),
        [isAdmin],
    );

    return (
        <div className={className}>
            <StackLayout>
                <Text style="heading 4">When do you want your coverage to begin?</Text>
                <ColumnLayout gap="none">
                    <Form.Field
                        className="u-1/3@desktop u-1/2@tablet u-1/2"
                        type={fields.startDate.type}
                        inputProps={{
                            ...fields.startDate.props,
                            onChange: handleStartDateChange,
                            disabled: isSubmitting,
                            isSelectable: isEffectiveDateSelectable,
                        }}
                        messages={fields.startDate.messages}
                    />
                    <BoxLayout>
                        <Text style="heading 5" data-e2e="end-date">
                            &ndash;{' '}
                            <DateDisplay
                                format={DATE_FORMAT}
                                value={addYears(value.startDate, 1)}
                            />
                        </Text>
                    </BoxLayout>
                </ColumnLayout>
                <Card isSelected={false} locked={false} data-e2e="wc-card">
                    <Card.Header>
                        <Icon name="worker" />
                        <Text style="heading 5">Workers Compensation</Text>
                    </Card.Header>
                    <Card.Body>
                        <StackLayout>
                            <Text>
                                Workers Compensation insurance protects businesses and employees by
                                providing wage replacement and medical benefits to employees who
                                become injured or disabled on the job or as a result of their
                                employment.
                            </Text>
                            <ColumnLayout>
                                <BoxLayout gap="12" className="u-1/2">
                                    <Text style="body 1" color="brand-500">
                                        Each Accident
                                    </Text>
                                    <Text>
                                        <MoneyDisplay value={eachAccident} />
                                    </Text>
                                </BoxLayout>
                                <BoxLayout gap="12" className="u-1/2">
                                    <Text style="body 1" color="brand-500">
                                        Each Employee
                                    </Text>
                                    <Text>
                                        <MoneyDisplay value={eachEmployee} />
                                    </Text>
                                </BoxLayout>
                            </ColumnLayout>
                            <BoxLayout gap="12" className="u-1/2">
                                <Text style="body 1" color="brand-500">
                                    Total Limit
                                </Text>
                                <Text>
                                    <MoneyDisplay value={totalLimit} />
                                </Text>
                            </BoxLayout>
                            <Text as="span" style="body 1" color="brand-500">
                                Premium:{' '}
                                <Text as="span" style="heading 5">
                                    <MoneyDisplay value={quote.totalPremium} />
                                </Text>
                            </Text>
                            <br />
                            <ColumnLayout split="-1">
                                <Text as="span" style="label 1">
                                    Blanket Waiver of Subrogation
                                </Text>
                                <TextButton target="_blank" href={WOS_PDF_URL}>
                                    What is the difference?
                                </TextButton>
                            </ColumnLayout>
                            <Form.Field
                                type="select"
                                inputProps={{
                                    value: quote.options.blanketWoS,
                                    filterable: false,
                                    items: [
                                        {
                                            value: true,
                                            title: 'Include Blanket Waiver of Subrogation',
                                        },
                                        {
                                            value: false,
                                            title: 'Exclude Blanket Waiver of Subrogation',
                                        },
                                    ],
                                    label: 'Blanket Waiver of Subrogation',
                                    onChange: handleWoSChange,
                                    disabled: isSubmitting,
                                }}
                            />
                            <Text as="span" style="body 2">
                                (Based on your answers, we recommend you{' '}
                                {quote.options.isWosRecommended ? '' : 'do not'} include it.)
                            </Text>
                        </StackLayout>
                    </Card.Body>
                </Card>
                <Text>Carrier: {quote.details.insurerName}</Text>
                <Text>
                    All Great American insurance companies are A+ AM Best Rated Workers’
                    compensation is a statutory product in most states that protects the company
                    against claims made by employees employees who are injured while at work.
                </Text>
            </StackLayout>
        </div>
    );
};
