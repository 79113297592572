import { TextButton } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { CoverageCatalog } from '../../CoverageCatalog';
import { DocumentType } from '../../types/BundleQuoteDocument';
import { DocumentsItemComponentProp } from './BundleQuoteLandingPage';

export function GenerateDocumentButton({
    isSubmitting,
    fileUrl,
    documentType,
    handleTrigger,
    isDisabled,
    coverageType,
}: DocumentsItemComponentProp) {
    const documentTitle = coverageType ? CoverageCatalog.getDocumentTitle(coverageType) : undefined;
    const toDisplayName =
        documentType && documentTitle
            ? documentTitle + ' ' + DocumentType.toDisplayName(documentType)
            : DocumentType.toDisplayName(documentType);
    if (fileUrl) {
        return (
            <TextButton
                as="a"
                disabled={isDisabled || isSubmitting}
                icon="download"
                color="primary-500"
                href={fileUrl}
                target="_blank"
                rel="noopener"
                data-e2e="download-document-button"
            >
                {toDisplayName}
            </TextButton>
        );
    }
    return (
        <TextButton
            as="button"
            disabled={isDisabled || isSubmitting}
            icon="download"
            color="primary-500"
            onClick={handleTrigger}
            data-e2e="generate-document-button"
        >
            {toDisplayName}
        </TextButton>
    );
}
