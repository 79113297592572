import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    BoxLayout,
    Card,
    ColumnLayout,
    Form,
    Hyperlink,
    Icon,
    SelectChangeEvent,
    StackLayout,
    Text,
    Tooltip,
} from '@embroker/ui-toolkit/v2';
import { addYears, format, isAfter, isValid } from 'date-fns';
import React, { useCallback, useContext, useMemo } from 'react';
import { hasRole } from '../../../../userOrg/entities/Session';
import { AppContext } from '../../../../view/AppContext';
import { WizardForm } from '../../../../view/hooks/useWizardForm';
import { EffectiveDate } from '../../../types/EffectiveDate';
import { PageComponentProps } from '../../../view/components/QuoteLandingPage';
import { withQuoteInfoProps } from '../../../view/components/WithExtraProps';
import { LPLQuote } from '../../entities/LPLQuote';
import { ClaimsExpenseType, PerClaimLimit } from '../../types/LPLQuoteOptions';
import { LPLIndividualInsuredList } from './LPLIndividualInsuredList';
import { LPLQuoteFormData, isDateFeb29 } from './createLPLQuoteForm';
import {
    getDefaultAggregateLimit,
    getDefaultClaimExpenseType,
    getDefaultSeparateClaimExpenseLimit,
    getLPLQuoteFormItems,
    isSeparateClaimsExpensesOptionSelected,
} from './getLPLQuoteFormItems';

export function getRetroactiveDate(
    hasCurrentPolicy: boolean,
    policyStartDate: Date,
    currentRetroactiveDate?: Date,
): string {
    // If a firm has a current policy and they have no retro date (full prior acts coverage), the retro date is set to N/A
    // If a firm has a current policy and there is a retro date, the retro date is the same as the one if the current policy
    if (hasCurrentPolicy) {
        return currentRetroactiveDate ? format(currentRetroactiveDate, 'MM/dd/yyyy') : 'N/A';
    }
    // If a firm does not have a current policy, the retro date is the new policy effective date
    if (!isValid(policyStartDate)) {
        return '';
    }
    return format(policyStartDate, 'MM/dd/yyyy');
}

interface LPLQuoteStepProps
    extends PageComponentProps<LPLQuoteFormData, LPLQuote>,
        withQuoteInfoProps {
    status: WizardForm<OpaqueForm<LPLQuoteFormData>>['status'];
}

const MAX_FUTURE_DAYS_ALLOWED = 90;
const DATE_FORMAT = 'MM/dd/yyyy';

export function LPLQuoteStep({
    quote,
    isSubmitting,
    fields,
    value,
    setValue,

    lplQuoteInfo,
    today,
    referredQuote,

    className,
    status,
}: LPLQuoteStepProps) {
    const { activeSession } = useContext(AppContext);
    const isAdmin = hasRole(activeSession, 'admin');
    const isBroker = hasRole(activeSession, 'broker');
    const isDirty = status === 'dirty';
    const isRenewal = lplQuoteInfo.isRenewal;
    const isEffectiveDateSelectable = useCallback(
        (effectiveDate: Date) => {
            if (isRenewal) {
                return false;
            }
            if (isDateFeb29(effectiveDate)) {
                return false;
            }
            return EffectiveDate.isSelectedEffectiveDateValid(
                effectiveDate,
                today,
                MAX_FUTURE_DAYS_ALLOWED,
                isAdmin,
            );
        },
        [isAdmin, today, isRenewal],
    );

    const lplQuoteFormItems = useMemo(
        () =>
            getLPLQuoteFormItems(
                value.perClaimLimit,
                value.claimsExpenseType,
                lplQuoteInfo.totalAttorneys,
                lplQuoteInfo.stateWithLargestNumber,
                lplQuoteInfo.higherLimit,
            ),
        [
            value.perClaimLimit,
            value.claimsExpenseType,
            lplQuoteInfo.totalAttorneys,
            lplQuoteInfo.stateWithLargestNumber,
            lplQuoteInfo.higherLimit,
        ],
    );

    const isSeparateClaimLimitDropdownVisible = isSeparateClaimsExpensesOptionSelected(
        value.claimsExpenseType,
    );

    const retroactiveDate = getRetroactiveDate(
        lplQuoteInfo.hasCurrentPolicy,
        value.effectiveDate,
        lplQuoteInfo.currentRetroactiveDate,
    );

    const policyEndDate = isValid(value.effectiveDate)
        ? addYears(value.effectiveDate, 1)
        : undefined;

    const handleEffectiveDateChange = (event: { target: { value: string; date: Date } }) => {
        const newDate = event.target.date;
        if (!isValid(newDate) || isRenewal) {
            return;
        }

        const newValue = {
            ...value,
            effectiveDate: newDate,
        };
        setValue(newValue);
    };

    const handlePerClaimLimitChange = (e: SelectChangeEvent<PerClaimLimit, false>) => {
        // User can choose reduced set of aggregateLimit and separateClaimExpenseLimit options based on perClaimLimit he selected.
        // After change of perClaimLimit default aggregateLimit and separateClaimExpenseLimit to first valid option.
        if (!e.target.value) return;
        const newClaimsExpenseType: ClaimsExpenseType = getDefaultClaimExpenseType(
            lplQuoteInfo.stateWithLargestNumber,
            e.target.value,
        );
        const newValue = {
            ...value,
            perClaimLimit: e.target.value,
            aggregateLimit: getDefaultAggregateLimit(
                e.target.value,
                lplQuoteInfo.stateWithLargestNumber,
            ),
            claimsExpenseType: newClaimsExpenseType,
            separateClaimExpenseLimit: getDefaultSeparateClaimExpenseLimit(newClaimsExpenseType),
        };
        setValue(newValue);
    };

    const handleClaimsExpenseTypeChange = (e: SelectChangeEvent<ClaimsExpenseType, false>) => {
        if (!e.target.value) {
            return;
        }
        const newValue = {
            ...value,
            claimsExpenseType: e.target.value,
            separateClaimExpenseLimit: getDefaultSeparateClaimExpenseLimit(e.target.value),
        };
        setValue(newValue);
    };

    const isGapBetweenPolicies =
        lplQuoteInfo.currentPolicyEndDate &&
        isAfter(value.effectiveDate, lplQuoteInfo.currentPolicyEndDate);

    const hidePremium = (isDirty || quote.isIndication) && !isBroker;

    return (
        <StackLayout className={className} gap="32">
            {referredQuote ? (
                <Text>
                    This is an indication only and is subject to further underwrite review. Embroker
                    will be in touch about next steps.
                </Text>
            ) : null}
            {isGapBetweenPolicies ? (
                <Text>
                    Before you proceed with binding this quote, please be aware that there is a gap
                    in coverage between the existing policy expiration date and the proposed
                    effective date of your new policy. If you would like further assistance on this
                    matter, please{' '}
                    <Hyperlink href="https://app.embroker.com/support/contact" target="_blank">
                        contact Embroker
                    </Hyperlink>
                    .
                </Text>
            ) : null}
            <Text style="heading 4">When do you want your coverage to begin?</Text>
            <ColumnLayout center>
                <Form.Field
                    className="u-1/3@desktop u-1/2@tablet u-1/2"
                    type={fields.effectiveDate.type}
                    messages={fields.effectiveDate.messages}
                    inputProps={{
                        ...fields.effectiveDate.props,
                        onChange: handleEffectiveDateChange,
                        isSelectable: isEffectiveDateSelectable,
                        disabled: isSubmitting || isRenewal,
                    }}
                />
                <BoxLayout>
                    <Text style="heading 5" data-e2e="end-date">
                        &ndash;{' '}
                        <span>
                            {policyEndDate ? (
                                <DateDisplay format={DATE_FORMAT} value={policyEndDate} />
                            ) : null}
                        </span>
                    </Text>
                </BoxLayout>
            </ColumnLayout>
            <Text style="heading 4">Named Insured</Text>
            <Text as="p" style="body 1" color="brand-500">
                {lplQuoteInfo.userInfo.companyName}
            </Text>
            <Card data-e2e="lpl-card">
                <Card.Header>
                    <Icon name="law" />
                    <Text style="heading 5">Lawyers Professional Liability</Text>
                </Card.Header>
                <Card.Body>
                    <StackLayout>
                        <Text>
                            Protect The Firm, the attorneys & employees for claims arising out of
                            providing a professional service
                        </Text>
                        <ColumnLayout
                            gap="16"
                            responsive={{ screenWidth: { smallerThan: 'tablet' } }}
                        >
                            <Form.Field
                                label="Per claim limit"
                                data-e2e="per-claim-limit"
                                type={fields.perClaimLimit.type}
                                messages={fields.perClaimLimit.messages}
                                inputProps={{
                                    ...fields.perClaimLimit.props,
                                    filterable: false,
                                    items: lplQuoteFormItems.perClaimLimitItems,
                                    disabled: isSubmitting,
                                    onChange: handlePerClaimLimitChange,
                                }}
                            />
                            <Form.Field
                                label="Aggregate limit"
                                data-e2e="agregate-limit"
                                type={fields.aggregateLimit.type}
                                messages={fields.aggregateLimit.messages}
                                inputProps={{
                                    ...fields.aggregateLimit.props,
                                    filterable: false,
                                    items: lplQuoteFormItems.aggregateLimitItems,
                                    disabled: isSubmitting,
                                }}
                            />
                        </ColumnLayout>
                        <Form.Field
                            label="Per claim deductible"
                            data-e2e="per-claim-deductible"
                            type={fields.perClaimDeductible.type}
                            messages={fields.perClaimDeductible.messages}
                            inputProps={{
                                ...fields.perClaimDeductible.props,
                                filterable: false,
                                items: lplQuoteFormItems.perClaimDeductibleItems,
                                disabled: isSubmitting,
                            }}
                        />
                        <Form.Field
                            label="First dollar / damages only deductible"
                            data-e2e="first-dollar"
                            type={fields.deductibleType.type}
                            messages={fields.deductibleType.messages}
                            tooltip="First dollar defense relieves the firm of any burden to pay a deductible with respect to defense expenses (legal fees). The deductible is applied only to an actual payment for damages and/or settlements."
                            inputProps={{
                                ...fields.deductibleType.props,
                                disabled: isSubmitting,
                                filterable: false,
                                items: lplQuoteFormItems.deductibleTypeItems,
                            }}
                        />
                        <Form.Field
                            label="Additional claims expense options"
                            data-e2e="additional-claims"
                            type={fields.claimsExpenseType.type}
                            messages={fields.claimsExpenseType.messages}
                            tooltip="Claims Expense in Addition to Limit provides a separate additional limit specifically for defense costs on a claim and is uncapped. This is a good way to provide additional coverage for defense costs while preserving standard limit for damages & settlement amounts. Separate Claims Expense Coverage provides a separate additional limit for defense costs on a claim and is capped at a certain amount. Once eroded on defense costs, the standard limit is used for defense costs, settlements and damages. This is an economical way to provide more protection for the firm."
                            inputProps={{
                                ...fields.claimsExpenseType.props,
                                disabled: isSubmitting,
                                onChange: handleClaimsExpenseTypeChange,
                                items: lplQuoteFormItems.claimsExpenseTypeItems,
                            }}
                        />
                        {isSeparateClaimLimitDropdownVisible ? (
                            <Form.Field
                                label="Separate Claims Expense Coverage Limit"
                                type={fields.separateClaimExpenseLimit.type}
                                messages={fields.separateClaimExpenseLimit.messages}
                                inputProps={{
                                    ...fields.separateClaimExpenseLimit.props,
                                    disabled: isSubmitting,
                                    filterable: false,
                                    items: lplQuoteFormItems.separateClaimExpenseLimitItems,
                                }}
                            />
                        ) : null}
                    </StackLayout>
                </Card.Body>
                <Card.Footer>
                    <ColumnLayout split="-1">
                        {!hidePremium && (
                            <Text as="span" style="body 1" color="brand-500">
                                Premium:{' '}
                                <Text as="span" style="heading 5">
                                    <MoneyDisplay value={quote.totalPremium} />
                                </Text>
                            </Text>
                        )}
                        <Text as="span" style="body 1" color="brand-500">
                            Policy retroactive date:{' '}
                            <Text as="span" style="heading 5">
                                {retroactiveDate}

                                <Tooltip
                                    iconSize="small"
                                    text="If N/A is shown, policy provides Full Prior Acts."
                                />
                            </Text>
                        </Text>
                    </ColumnLayout>
                </Card.Footer>
            </Card>
            <LPLIndividualInsuredList
                individualInsuredList={lplQuoteInfo.attorneyList}
                effectiveDate={value.effectiveDate}
            />
            <Text color="ui-400">Carrier: Everest National Insurance Company</Text>
            <Text color="ui-400">
                Company is AM Best rated A+ (Superior) with a Financial Size Category of XV($2
                Billion or greater) and is part of the S&P 500 Index. The Lawyers Leadership Team at
                Everest has deep experience protecting law firms for legal malpractice over an
                average of 20 years.
            </Text>
        </StackLayout>
    );
}
