import type * as APIType from '@embroker/shotwell-api/app';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { keysOf } from '@embroker/shotwell/core/object';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import {
    ActionHandler,
    CreateFormParams,
    FormAction,
    OpaqueForm,
} from '@embroker/shotwell/view/hooks/useForm';
import { GetDocumentUrl } from '../../../documents/useCases/GetDocumentUrl';
import { QuotingEngineWCChubb } from '../../../shopping/types/enums';
import { WizardForm } from '../../../view/hooks/useWizardForm';
import { BundleQuote } from '../../entities/BundleQuote';
import {
    BundleCoverageAppStatusEnum,
    BundleCoverageType,
    BundleCoverageTypeEnum,
} from '../../types/BundleQuoteCoverage';
import { DocumentType } from '../../types/BundleQuoteDocument';
import { BuildBundleDocumentListProps } from '../../view/components/buildBundleDocumentList';
import { DocumentsItem } from '../../view/components/BundleQuoteLandingPage';
import { DownloadDocumentButton } from '../../view/components/DownloadDocumentButton';
import { GenerateDocumentButton } from '../../view/components/GenerateDocumentButton';
import { getDocumentUrl, getStaticDocumentUrl } from '../bundleMappingFunctions';
import {
    CoverageDefinition,
    distributedPrefix,
    GetDocumentDownloadMetadataResponse,
    ProductFormData,
    WCChubb,
    WCChubbDocumentTitle,
    WCChubbProductName,
    WCChubbProductTitle,
} from '../coverageDefinition';
import { buildWCChubbCoverage } from './buildWCChubbCoverage';
import { WCChubbBundleCoverageOptions } from './components/WCChubbBundleCoverageOptions';
import { WCChubbCoverageModalSummary } from './components/WCChubbCoverageModalSummary';
import { WCChubbFooter } from './components/WCChubbFooter';
import { WCChubbQuoteSummary } from './components/WCChubbQuoteSummary';
import { WCChubbQuote } from './entities/WCChubbQuote';
import {
    WCChubbEachEmployeeLimit,
    WCChubbPerAccidentLimit,
    WCChubbPolicyLimit,
    WCChubbQuoteOptions,
} from './types/WCChubbQuoteOptions';
import { wcChubbFormDataType } from './wcChubbFormData';
import { getRecommendedQuotingOptionsWcChubb } from './recommendedQuotingOptions';

enum ChubbSpecimenType {
    WC = 'https://embroker.s3.us-west-2.amazonaws.com/Chubb_WC_PolicySpecimen.pdf',
}

export const WCChubbCoverageDefinition: CoverageDefinition<WCChubbQuote> = {
    isLimitHigherThenAllowed(
        formValue: distributedPrefix<WCChubbQuoteOptions>,
        coverageSelected: boolean,
    ): boolean {
        return false;
    },
    onSuccessHandler(
        value: any,
        action: string,
        setDocumentUrl: (
            url: URI,
            documentType: DocumentType,
            bundleCoverageType: BundleCoverageType,
        ) => void,
        onGenerateDocument: () => void,
    ) {
        switch (action) {
            case 'generateWCChubbQuoteDocument':
                setDocumentUrl(
                    value.fileUrl,
                    DocumentType.QuoteDocument,
                    BundleCoverageTypeEnum.WCChubbBundleCoverage,
                );
                onGenerateDocument();
                break;
            case 'downloadWCChubbGeneralTerms':
                window.open(value.fileUrl as string, '_blank');
                break;
        }
    },
    productName: WCChubbProductName,
    productTitle: WCChubbProductTitle,
    documentTitle: WCChubbDocumentTitle,
    type: BundleCoverageTypeEnum.WCChubbBundleCoverage,
    quotingEngine: QuotingEngineWCChubb,
    async buildCoverage(quoteExtended, isBroker) {
        return buildWCChubbCoverage(quoteExtended, isBroker);
    },
    mapFormDataToQuoteOptions(formData: any): WCChubbQuoteOptions | undefined {
        if (!isWCChubbFormData(formData)) {
            return undefined;
        }
        return {
            effectiveDate: formData.effectiveDate,
            isDeselected: !formData.wcChubbSelected,
            policyLimit: formData.wcChubbPolicyLimit,
            perAccidentLimit: formData.wcChubbPerAccidentLimit,
            eachEmployeeLimit: formData.wcChubbEachEmployeeLimit,
        };
    },
    getRecomendedQuoteOptions: getRecommendedQuotingOptionsWcChubb,
    apiProductDesignation: WCChubb,
    mapQuoteOptionsToAPI(options: WCChubbQuoteOptions): APIType.WcChubbQuoteOptions {
        return {
            effective_date: options.effectiveDate,
            is_deselected: options.isDeselected,
            per_accident_limit: options.perAccidentLimit,
            policy_limit: options.policyLimit,
            disease_each_employee: options.eachEmployeeLimit,
        };
    },
    coverageOptionsComponent: WCChubbBundleCoverageOptions,
    summaryComponent: WCChubbQuoteSummary,
    modalSummaryComponent: WCChubbCoverageModalSummary,
    footerComponent: WCChubbFooter,
    isFieldsValidType(
        input: any,
    ): input is WizardForm<OpaqueForm<distributedPrefix<WCChubbQuoteOptions>>>['fields'] {
        return isWCChubbFormData(input);
    },
    isOptionsValidType(input: any): input is WCChubbQuoteOptions {
        return isWCChubbQuoteOptions(input);
    },
    getProductFields(
        bundleQuote: BundleQuote,
    ): CreateFormParams<ProductFormData<distributedPrefix<WCChubbQuoteOptions>>>['fields'] {
        return {
            wcChubbPolicyLimit: {
                type: 'select',
                validator: Joi.number().valid(...WCChubbPolicyLimit),
            },
            wcChubbPerAccidentLimit: {
                type: 'select',
                validator: Joi.number().valid(...WCChubbPerAccidentLimit),
            },
            wcChubbEachEmployeeLimit: {
                type: 'select',
                validator: Joi.number().valid(...WCChubbEachEmployeeLimit),
            },
            wcChubbSelected: {
                type: 'checkbox',
                validator: Joi.boolean(),
            },
        };
    },
    getInitialValues(bundleQuote): Record<string, unknown> {
        const chubbWCCoverage = bundleQuote.coverageList.find(
            (coverage) => coverage.type === this.type,
        );

        // TODO: improve error handling
        if (
            chubbWCCoverage === undefined ||
            chubbWCCoverage.quote === undefined ||
            !this.isOptionsValidType(chubbWCCoverage.quote.options)
        ) {
            return {};
        }

        const chubbWCOptions = chubbWCCoverage.quote.options;
        return {
            wcChubbStatutory: 'Statutory',
            wcChubbPolicyLimit: chubbWCOptions.policyLimit,
            wcChubbPerAccidentLimit: chubbWCOptions.perAccidentLimit,
            wcChubbEachEmployeeLimit: chubbWCOptions.eachEmployeeLimit,
            wcChubbSelected: !chubbWCOptions.isDeselected,
        };
    },
    getProductActions(
        bundleQuote: BundleQuote,
        abortSignal: AbortSignal,
    ): Record<
        FormAction,
        | {
              action: ActionHandler<WCChubbQuoteOptions>;
              fields: (keyof distributedPrefix<WCChubbQuoteOptions>)[];
          }
        | ActionHandler<ProductFormData<WCChubbQuoteOptions>>
    > {
        const formFields = this.getProductFields(bundleQuote);
        return {
            generateWCChubbQuoteDocument: {
                action: async () => {
                    return getDocumentUrl(
                        bundleQuote,
                        BundleCoverageTypeEnum.WCChubbBundleCoverage,
                        DocumentType.QuoteDocument,
                        abortSignal,
                    );
                },
                fields: ['effectiveDate', ...keysOf(formFields)],
            },
            downloadWCChubbGeneralTerms: {
                action: async () => {
                    return getStaticDocumentUrl(
                        DocumentType.GeneralTerms,
                        BundleCoverageTypeEnum.WCChubbBundleCoverage,
                        bundleQuote,
                    );
                },
                fields: ['effectiveDate', ...keysOf(formFields)],
            },
        };
    },
    getCoverageDocuments({
        bundleQuote,
        documents,
        trigger,
        isDirty,
        value,
    }: BuildBundleDocumentListProps): DocumentsItem[] | undefined {
        const wcChubbCoverage = bundleQuote.getCoverageByCoverageType(
            BundleCoverageTypeEnum.WCChubbBundleCoverage,
        );
        const shouldIncludeWcChubbDocs =
            !bundleQuote.isCoverageReferred(BundleCoverageTypeEnum.WCChubbBundleCoverage) &&
            bundleQuote.isCoverageEnabled(BundleCoverageTypeEnum.WCChubbBundleCoverage) &&
            wcChubbCoverage?.status !== BundleCoverageAppStatusEnum.NotEligible &&
            value.wcChubbSelected;

        if (!shouldIncludeWcChubbDocs) {
            return undefined;
        }

        const wcChubbQuoteDocument: DocumentsItem = {
            component: GenerateDocumentButton,
            isDisabled: isDirty,
            documentType: DocumentType.QuoteDocument,
            fileUrl: documents.find(
                (document) =>
                    document.documentType === DocumentType.QuoteDocument &&
                    document.coverageType === BundleCoverageTypeEnum.WCChubbBundleCoverage,
            )?.url,
            handleTrigger: () => trigger('generateWCChubbQuoteDocument'),
            coverageType: BundleCoverageTypeEnum.WCChubbBundleCoverage,
        };
        const wcChubbGeneralTerms: DocumentsItem = {
            component: DownloadDocumentButton,
            isDisabled: isDirty,
            documentType: DocumentType.GeneralTerms,
            handleTrigger: () => trigger('downloadWCChubbGeneralTerms'),
            coverageType: BundleCoverageTypeEnum.WCChubbBundleCoverage,
        };
        return [wcChubbQuoteDocument, wcChubbGeneralTerms];
    },
    toggleSelected(value: boolean): Record<string, unknown> {
        return { wcChubbSelected: value };
    },
    getMaxFutureDaysAllowed(): number {
        return 90;
    },
    async getDocumentDownloadMetadata(
        documentType: DocumentType,
        wcChubbQuote?: WCChubbQuote,
    ): AsyncResult<GetDocumentDownloadMetadataResponse, OperationFailed | InvalidArgument> {
        if (wcChubbQuote === undefined) {
            return Failure(InvalidArgument({ argument: 'wcChubbQuote', value: documentType }));
        }

        if (documentType === DocumentType.GeneralTerms) {
            const specimenUrl = getPolicySpecimenUrl(wcChubbQuote);
            return Success({ fileKey: '', downloadUrl: specimenUrl });
        }

        let fileKey;
        switch (documentType) {
            case DocumentType.QuoteDocument:
                fileKey = wcChubbQuote.fileKey;
                break;
            default:
                break;
        }
        if (fileKey === undefined) {
            return Failure(InvalidArgument({ argument: 'fileKey', value: documentType }));
        }
        const downloadUrlResult = await execute(GetDocumentUrl, {
            fileKey: fileKey,
        });
        if (isErr(downloadUrlResult)) {
            return downloadUrlResult;
        }
        return Success({
            fileKey: fileKey,
            downloadUrl: downloadUrlResult.value.downloadUrl,
        });
    },
    getPremiumRange() {
        return undefined;
    },
};

function isWCChubbQuoteOptions(options: any): options is WCChubbQuoteOptions {
    return 'effectiveDate' in options && 'perAccidentLimit' in options;
}

function isWCChubbFormData(input: any): input is wcChubbFormDataType {
    return 'wcChubbPerAccidentLimit' in input && 'wcChubbSelected' in input;
}

function getPolicySpecimenUrl(quote: WCChubbQuote): ChubbSpecimenType {
    return ChubbSpecimenType.WC;
}
