import { isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    Button,
    RadioGroup,
    Spinner,
    Stack,
    StackLayout,
    StatusMessage,
    Text,
    WizardLayout,
    useStepper,
} from '@embroker/ui-toolkit/v2';
import React, { useState } from 'react';
import { GetPolicy } from '../../../../policy/useCases/GetPolicy';
import { NameOrAddressChangeEndorsement } from './NameOrAddressChangeEndorsement';
import { OtherEndorsementPage } from './OtherEndorsementPage';

interface EndorsementIntakeProps {
    policyId: UUID;
    isPolicyReferred?: boolean;
    onDismiss: () => void;
}

export function EndorsementIntake({
    policyId,
    isPolicyReferred,
    onDismiss,
}: EndorsementIntakeProps) {
    if (isPolicyReferred) {
        return <ReferredPolicyComponent onDismiss={onDismiss} />;
    } else {
        return <RegularPolicyComponent policyId={policyId} onDismiss={onDismiss} />;
    }
}

interface ReferredPolicyComponentProps {
    onDismiss: () => void;
}

function ReferredPolicyComponent({ onDismiss }: ReferredPolicyComponentProps) {
    return (
        <WizardLayout title="Modify Policy" onDismiss={onDismiss}>
            <Text style="heading 3">Make changes to your policy</Text>
            <StatusMessage status="warning">
                Your policy is now in a state of referral. This means that you won't be able to
                automatically renew your policy, or purchase a new one. Please contact our customer
                success team if you have any questions.
            </StatusMessage>
        </WizardLayout>
    );
}

interface RegularPolicyComponentProps {
    policyId: UUID;
    onDismiss: () => void;
}
function RegularPolicyComponent({ policyId, onDismiss }: RegularPolicyComponentProps) {
    const [valueSelected, setValueSelected] = useState<string>();

    const { activeStepIndex, setActiveStep } = useStepper({
        steps: ['initial-question', 'name-address-endorsement', 'other-endorsement'],
        initialStep: 'initial-question',
    });

    const { result: getPolicyResult, isLoading: isGetPolicyLoading } = useUseCase(GetPolicy, {
        policyId,
    });

    if (isGetPolicyLoading || getPolicyResult === undefined) {
        return <Spinner />;
    }

    if (isErr(getPolicyResult)) {
        return null;
    }

    const handleNext = () => {
        if (valueSelected === 'yes') {
            setActiveStep('name-address-endorsement');
        } else {
            setActiveStep('other-endorsement');
        }
    };

    const loadedPolicy = getPolicyResult.value.policy;
    const insuredName = loadedPolicy.insuredPartiesList[0].name;

    return (
        <WizardLayout title="Modify Policy" onDismiss={onDismiss}>
            <Stack activeIndex={activeStepIndex}>
                <StackLayout>
                    <Text style="heading 3" data-e2e="modify-policy-header">
                        Make changes to your policy
                    </Text>
                    <Text style="body 1" data-e2e="modify-policy-body">
                        An endorsement is a change to your original insurance policy that lets you
                        add or remove coverage, make corrections, or update details like your
                        business address. Adding an endorsement may increase the cost of your
                        insurance.
                    </Text>
                    <Text style="heading 4" data-e2e="modify-policy-question">
                        Do you want to change your Business Name or Address?
                    </Text>
                    <RadioGroup
                        value={valueSelected}
                        items={[
                            {
                                title: 'Yes',
                                value: 'yes',
                            },
                            {
                                title: 'No',
                                value: 'no',
                            },
                        ]}
                        onChange={(event) => {
                            setValueSelected(event.target.value === 'yes' ? 'yes' : 'no');
                        }}
                    />
                    <WizardLayout.Actions>
                        <Button
                            disabled={valueSelected === undefined}
                            onClick={handleNext}
                            data-e2e="next-page"
                        >
                            Next
                        </Button>
                    </WizardLayout.Actions>
                </StackLayout>
                <StackLayout>
                    <NameOrAddressChangeEndorsement
                        policyId={policyId}
                        initialValue={{
                            policyEffectiveDate: loadedPolicy.startDate,
                            policyExpirationDate: loadedPolicy.endDate,
                            namedInsured: insuredName,
                            headquarters: {
                                addressLine1: loadedPolicy.address,
                                city: loadedPolicy.city,
                                zip: loadedPolicy.zip,
                                state: loadedPolicy.state,
                            },
                        }}
                        onDismiss={onDismiss}
                    />
                </StackLayout>
                <StackLayout>
                    <OtherEndorsementPage policyId={policyId} onDismiss={onDismiss} />
                </StackLayout>
            </Stack>
        </WizardLayout>
    );
}
