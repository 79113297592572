import { container, ContainerModule } from '@embroker/shotwell/core/di';
import { SessionLost } from '@embroker/shotwell-api/request';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { execute } from '@embroker/shotwell/core/UseCase';
import { LocationRepository } from '../locations/repositories/LocationsRepository';
import { APILocationRepository } from '../locations/repositories/LocationsRepository/APILocationRepository';
import { Module } from '../Module';
import { ActiveSession, AppContext, AppContextStore } from '../view/AppContext';
import {
    SessionDeauthenticated,
    SessionOrganizationSelected,
    SessionUserLogin,
} from './entities/Session';
import { SignedUp, UserLoginByAdmin } from './entities/User';
import { UserInviteAccepted } from './entities/UserInvite';
import { DataEnricherRepository } from './repositories/DataEnricher';
import { APIDataEnricherRepository } from './repositories/DataEnricher/APIDataEnricherRepository';
import { EntityTypeRepository } from './repositories/EntityTypeRepository';
import { APIEntityTypeRepository } from './repositories/EntityTypeRepository/APIEntityTypeRepository';
import { NotificationRepository } from './repositories/NotificationRepository';
import { APINotificationRepository } from './repositories/NotificationRepository/APINotificationRepository';
import { OrganizationRepository } from './repositories/OrganizationRepository';
import { APIOrganizationRepository } from './repositories/OrganizationRepository/APIOrganizationRepository';
import { SessionRepository } from './repositories/SessionRepository';
import { APISessionRepository } from './repositories/SessionRepository/APISessionRepository';
import { UserInviteRepository } from './repositories/UserInviteRepository';
import { APIUserInviteRepository } from './repositories/UserInviteRepository/APIUserInviteRepository';
import { UserRepository } from './repositories/UserRepository';
import { APIUserRepository } from './repositories/UserRepository/APIUserRepository';
import { AcceptUserInvite } from './useCases/AcceptUserInvite';
import { AddNewOrganization } from './useCases/AddNewOrganization';
import { AutoLogin } from './useCases/AutoLogin';
import { ChangePassword } from './useCases/ChangePassword';
import { CheckUserStatus } from './useCases/CheckUserStatus';
import { ForgottenPassword } from './useCases/ForgottenPassword';
import { GetActiveOrganizationProfile } from './useCases/GetActiveOrganizationProfile';
import { GetActiveSession } from './useCases/GetActiveSession';
import { GetActiveUserProfile } from './useCases/GetActiveUserProfile';
import { GetEnricherCompany } from './useCases/GetEnricherCompany';
import { GetEntityTypes } from './useCases/GetEntityTypes';
import { GetInvitedOrganizationData } from './useCases/GetInvitedOrganizationData';
import { GetNotificationsCount } from './useCases/GetNotificationsCount';
import { GetOrganizationProfile } from './useCases/GetOrganizationProfile';
import { GetOrganizationsForUser } from './useCases/GetOrganizationsForUser';
import { GetSettings } from './useCases/GetSettings';
import { GetUserInvites } from './useCases/GetUserInvites';
import { Login } from './useCases/Login';
import { LoginByAdmin } from './useCases/LoginByAdmin';
import { Logout } from './useCases/Logout';
import { RemoveUserFromOrganization } from './useCases/RemoveUserFromOrganization';
import { ResetPassword } from './useCases/ResetPassword';
import { SelectOrganization } from './useCases/SelectOrganization';
import { SendUserInvites } from './useCases/SendUserInvites';
import { SignUp } from './useCases/SignUp';
import { SignUpInvitedUser } from './useCases/SignUpInvitedUser';
import { UpdateOrganizationProfile } from './useCases/UpdateOrganizationProfile';
import { UpdateUserProfile } from './useCases/UpdateUserProfile';
import { routes } from './view/routes';
import { SetPassword } from './useCases/SetPassword';
import { DomainEventSourceLocal } from '@embroker/shotwell/core/types/DomainEventSource';
import { OrganizationCreated } from './entities/Organization';
import { CheckUsernameAvailability } from './useCases/CheckUsernameAvailability';
import { ProspectUserLoginByToken } from './useCases/ProspectUserLoginByToken';
import { CheckIfUserIsProspect } from './useCases/CheckIfUserIsProspect';
import { GetDnbNaicsCode } from './useCases/GetDnbNaicsCode';
import { PublishSignUpStartedEvent } from './useCases/PublishSignUpStartedEvent';
import { UpdateOrganizationNaicsCode } from './useCases/UpdateOrganizationNaicsCode';
import { GetOnboardingRedirect } from './useCases/GetOnboardingRedirect';
import { GetUserOnboardingEntrypoint } from './useCases/GetUserOnboardingEntrypoint';
import { UpdateUserOnboardingDetails } from './useCases/UpdateUserOnboardingDetails';
import { GetUserOnboardingDetails } from './useCases/GetUserOnboardingDetails';
import { PublishDnbNaicsRetrievedEvent } from './useCases/PublishOnboardingDnbNaicsRetrievedEvent';
import { PublishOnboardingNaicsChangedEvent } from './useCases/PublishOnboardingNaicsChangedEvent';
import { PublishOnboardingStartedEvent } from './useCases/PublishOnboardingStartedEvent';
import { PublishOnboardingCompletedEvent } from './useCases/PublishOnboardingCompletedEvent';
import { PublishOnboardingStepVisitedEvent } from './useCases/PublishOnboardingStepVisitedEvent';
import { RemoveUserOnboardingDetails } from './useCases/RemoveUserOnboardingDetails';
import { UpdateUserOnboardingQuestionnaireData } from './useCases/UpdateUserOnboardingQuestionnaireData';
import { RemoveUserOnboardingQuestionnaireData } from './useCases/RemoveUserOnboardingQuestionnaireData';
import { UserOnboardingRepository } from './repositories/UserOnboardingRepository';
import { APIUserOnboardingRepository } from './repositories/UserOnboardingRepository/APIUserOnboardingRepository';
import { PublishUserOrgUserClickEvent } from './useCases/PublishUserOrgUserClickEvent';
import { StreamlineRenewalProspectLogin } from './useCases/StreamlineRenewalProspectLogin';
import { GetReturnUserLoginRedirect } from './useCases/GetReturnUserLoginRedirect';
import { PublishOnboardingWelcomeBackPopupEvent } from './useCases/PublishOnboardingWelcomeBackPopupEvent';
import { PublishSingleInProgressAppWelcomeBackPopupEvent } from './useCases/PublishSingleInProgressAppWelcomeBackPopupEvent';
import { PublishSingleValidAndExpiredQuotePopupEvent } from './useCases/PublishSingleValidAndExpiredQuotePopupEvent';
import { GetNBRVFunnelStatus } from './useCases/GetNBRVFunnelStatus';
import { NBRVFunnelRepository } from './repositories/NBRVFunnelRepository';
import { APINBRVFunnelRepository } from './repositories/NBRVFunnelRepository/APINBRVFunnelRepository';
import { SetNBRVFunnelStatus } from './useCases/SetNBRVFunnelStatus';

const userOrgModuleSubscribers = [
    subscribeToOrganizationSelected,
    subscribeToOrganizationCreated,
    subscribeToSignUpEvent,
    subscribeToSignInEvent,
    subscribeToInvitationAccepted,
    subscribeToDeauthenticateEvent,
    subscribeToLoginAsAdminEvent,
    subscribeToNoSessionEvent,
];

/**
 * The UserOrg module.
 */
export const UserOrgModule: Module = {
    routes,
    container: new ContainerModule((bind) => {
        bind<EntityTypeRepository>(EntityTypeRepository)
            .to(APIEntityTypeRepository)
            .inSingletonScope();
        bind<OrganizationRepository>(OrganizationRepository)
            .to(APIOrganizationRepository)
            .inSingletonScope();
        bind<SessionRepository>(SessionRepository).to(APISessionRepository).inSingletonScope();
        bind<UserRepository>(UserRepository).to(APIUserRepository).inSingletonScope();
        bind<UserOnboardingRepository>(UserOnboardingRepository)
            .to(APIUserOnboardingRepository)
            .inSingletonScope();
        bind<NotificationRepository>(NotificationRepository)
            .to(APINotificationRepository)
            .inSingletonScope();
        bind<UserInviteRepository>(UserInviteRepository)
            .to(APIUserInviteRepository)
            .inSingletonScope();
        bind<AcceptUserInvite>(AcceptUserInvite.type).to(AcceptUserInvite).inSingletonScope();
        bind<AddNewOrganization>(AddNewOrganization.type).to(AddNewOrganization).inSingletonScope();
        bind<AutoLogin>(AutoLogin.type).to(AutoLogin).inSingletonScope();
        bind<ChangePassword>(ChangePassword.type).to(ChangePassword).inSingletonScope();
        bind<SetPassword>(SetPassword.type).to(SetPassword).inSingletonScope();
        bind<GetOrganizationProfile>(GetOrganizationProfile.type)
            .to(GetOrganizationProfile)
            .inSingletonScope();
        bind<GetActiveOrganizationProfile>(GetActiveOrganizationProfile.type)
            .to(GetActiveOrganizationProfile)
            .inSingletonScope();
        bind<GetActiveUserProfile>(GetActiveUserProfile.type)
            .to(GetActiveUserProfile)
            .inSingletonScope();
        bind<Login>(Login.type).to(Login).inSingletonScope();
        bind<Logout>(Logout.type).to(Logout).inSingletonScope();
        bind<LoginByAdmin>(LoginByAdmin.type).to(LoginByAdmin).inSingletonScope();
        bind<RemoveUserFromOrganization>(RemoveUserFromOrganization.type)
            .to(RemoveUserFromOrganization)
            .inSingletonScope();
        bind<ForgottenPassword>(ForgottenPassword.type).to(ForgottenPassword).inSingletonScope();
        bind<SelectOrganization>(SelectOrganization.type).to(SelectOrganization).inSingletonScope();
        bind<SignUp>(SignUp.type).to(SignUp).inSingletonScope();
        bind<UpdateOrganizationProfile>(UpdateOrganizationProfile.type)
            .to(UpdateOrganizationProfile)
            .inSingletonScope();
        bind<UpdateUserProfile>(UpdateUserProfile.type).to(UpdateUserProfile).inSingletonScope();
        bind<GetEntityTypes>(GetEntityTypes.type).to(GetEntityTypes).inSingletonScope();
        bind<GetUserInvites>(GetUserInvites.type).to(GetUserInvites).inSingletonScope();
        bind<SendUserInvites>(SendUserInvites.type).to(SendUserInvites).inSingletonScope();
        bind<GetActiveSession>(GetActiveSession.type).to(GetActiveSession).inSingletonScope();
        bind<GetOrganizationsForUser>(GetOrganizationsForUser.type)
            .to(GetOrganizationsForUser)
            .inSingletonScope();
        bind<ResetPassword>(ResetPassword.type).to(ResetPassword).inSingletonScope();
        bind<GetNotificationsCount>(GetNotificationsCount.type)
            .to(GetNotificationsCount)
            .inSingletonScope();
        bind<GetInvitedOrganizationData>(GetInvitedOrganizationData.type)
            .to(GetInvitedOrganizationData)
            .inSingletonScope();
        bind<SignUpInvitedUser>(SignUpInvitedUser.type).to(SignUpInvitedUser).inSingletonScope();
        bind<LocationRepository>(LocationRepository).to(APILocationRepository).inSingletonScope();
        bind<GetSettings>(GetSettings.type).to(GetSettings).inSingletonScope();
        bind<CheckUserStatus>(CheckUserStatus.type).to(CheckUserStatus).inSingletonScope();
        bind<CheckUsernameAvailability>(CheckUsernameAvailability.type)
            .to(CheckUsernameAvailability)
            .inSingletonScope();
        bind<GetDnbNaicsCode>(GetDnbNaicsCode.type).to(GetDnbNaicsCode).inSingletonScope();
        bind<DataEnricherRepository>(DataEnricherRepository)
            .to(APIDataEnricherRepository)
            .inSingletonScope();
        bind<GetEnricherCompany>(GetEnricherCompany.type).to(GetEnricherCompany).inSingletonScope();
        bind<ProspectUserLoginByToken>(ProspectUserLoginByToken.type)
            .to(ProspectUserLoginByToken)
            .inSingletonScope();
        bind<CheckIfUserIsProspect>(CheckIfUserIsProspect.type)
            .to(CheckIfUserIsProspect)
            .inSingletonScope();
        bind<UpdateOrganizationNaicsCode>(UpdateOrganizationNaicsCode.type)
            .to(UpdateOrganizationNaicsCode)
            .inSingletonScope();
        bind<PublishSignUpStartedEvent>(PublishSignUpStartedEvent.type)
            .to(PublishSignUpStartedEvent)
            .inSingletonScope();
        bind<GetOnboardingRedirect>(GetOnboardingRedirect.type)
            .to(GetOnboardingRedirect)
            .inSingletonScope();
        bind<GetUserOnboardingEntrypoint>(GetUserOnboardingEntrypoint.type)
            .to(GetUserOnboardingEntrypoint)
            .inSingletonScope();
        bind<UpdateUserOnboardingDetails>(UpdateUserOnboardingDetails.type)
            .to(UpdateUserOnboardingDetails)
            .inSingletonScope();
        bind<UpdateUserOnboardingQuestionnaireData>(UpdateUserOnboardingQuestionnaireData.type)
            .to(UpdateUserOnboardingQuestionnaireData)
            .inSingletonScope();
        bind<RemoveUserOnboardingDetails>(RemoveUserOnboardingDetails.type)
            .to(RemoveUserOnboardingDetails)
            .inSingletonScope();
        bind<RemoveUserOnboardingQuestionnaireData>(RemoveUserOnboardingQuestionnaireData.type)
            .to(RemoveUserOnboardingQuestionnaireData)
            .inSingletonScope();
        bind<GetUserOnboardingDetails>(GetUserOnboardingDetails.type)
            .to(GetUserOnboardingDetails)
            .inSingletonScope();
        bind<PublishDnbNaicsRetrievedEvent>(PublishDnbNaicsRetrievedEvent.type)
            .to(PublishDnbNaicsRetrievedEvent)
            .inSingletonScope();
        bind<PublishOnboardingNaicsChangedEvent>(PublishOnboardingNaicsChangedEvent.type)
            .to(PublishOnboardingNaicsChangedEvent)
            .inSingletonScope();
        bind<PublishOnboardingStartedEvent>(PublishOnboardingStartedEvent.type)
            .to(PublishOnboardingStartedEvent)
            .inSingletonScope();
        bind<PublishOnboardingCompletedEvent>(PublishOnboardingCompletedEvent.type)
            .to(PublishOnboardingCompletedEvent)
            .inSingletonScope();
        bind<PublishOnboardingStepVisitedEvent>(PublishOnboardingStepVisitedEvent.type).to(
            PublishOnboardingStepVisitedEvent,
        );
        bind<PublishUserOrgUserClickEvent>(PublishUserOrgUserClickEvent.type)
            .to(PublishUserOrgUserClickEvent)
            .inSingletonScope();
        bind<StreamlineRenewalProspectLogin>(StreamlineRenewalProspectLogin.type)
            .to(StreamlineRenewalProspectLogin)
            .inSingletonScope();
        bind<GetReturnUserLoginRedirect>(GetReturnUserLoginRedirect.type)
            .to(GetReturnUserLoginRedirect)
            .inSingletonScope();
        bind<PublishOnboardingWelcomeBackPopupEvent>(PublishOnboardingWelcomeBackPopupEvent.type)
            .to(PublishOnboardingWelcomeBackPopupEvent)
            .inSingletonScope();
        bind<PublishSingleValidAndExpiredQuotePopupEvent>(
            PublishSingleValidAndExpiredQuotePopupEvent.type,
        )
            .to(PublishSingleValidAndExpiredQuotePopupEvent)
            .inSingletonScope();
        bind<PublishSingleInProgressAppWelcomeBackPopupEvent>(
            PublishSingleInProgressAppWelcomeBackPopupEvent.type,
        )
            .to(PublishSingleInProgressAppWelcomeBackPopupEvent)
            .inSingletonScope();
        bind<NBRVFunnelRepository>(NBRVFunnelRepository)
            .to(APINBRVFunnelRepository)
            .inSingletonScope();
        bind<GetNBRVFunnelStatus>(GetNBRVFunnelStatus.type)
            .to(GetNBRVFunnelStatus)
            .inSingletonScope();
        bind<SetNBRVFunnelStatus>(SetNBRVFunnelStatus.type)
            .to(SetNBRVFunnelStatus)
            .inSingletonScope();
    }),
    bootstrap: async () => {
        for (const subscriber of userOrgModuleSubscribers) {
            subscriber();
        }
        const appContext = performAutoLogin();
        return appContext;
    },
};

function subscribeToOrganizationSelected() {
    const eventBus = container.get<DomainEventBus>(DomainEventBus);
    eventBus.subscribe<SessionOrganizationSelected>(
        'Session',
        'OrganizationSelected',
        async (event) => {
            AppContextStore.update({
                activeSession: {
                    ...AppContextStore.context.activeSession,
                    organizationId: event.organizationId,
                },
            });
        },
    );
}

function subscribeToOrganizationCreated() {
    const eventBus = container.get<DomainEventBus>(DomainEventBus);
    eventBus.subscribe<OrganizationCreated>('Organization', 'Created', async (event) => {
        const result = await execute(GetActiveUserProfile);
        if (isOK(result)) {
            const userOrganizationIdList = result.value.organizations.map(
                (organization) => organization.id,
            );
            AppContextStore.update({
                activeSession: {
                    ...AppContextStore.context.activeSession,
                    userOrganizationIdList,
                },
            });
        }
    });
}

async function getActiveSessionAndStoreIt() {
    const getActiveSessionResult = await execute(GetActiveSession);
    if (isOK(getActiveSessionResult)) {
        const activeSessionResult = getActiveSessionResult.value;
        const activeSession = {
            isAuthenticated: activeSessionResult.session.authenticatedUserId != null,
            authenticatedUserId: activeSessionResult.session.userId,
            userId: activeSessionResult.session.userId,
            organizationId: activeSessionResult.session.organizationId,
            userOrganizationIdList: activeSessionResult.session.userOrganizationIdList,
            roles: activeSessionResult.session.roles,
        } as ActiveSession;
        AppContextStore.update({
            hasActiveSession: true,
            activeSession,
        });
    }
}

function subscribeToSignUpEvent() {
    const eventBus = container.get<DomainEventBus>(DomainEventBus);
    eventBus.subscribe<SignedUp>('User', 'SignedUp', async () => {
        await getActiveSessionAndStoreIt();
    });
}

function subscribeToSignInEvent() {
    const eventBus = container.get<DomainEventBus>(DomainEventBus);
    eventBus.subscribe<SessionUserLogin>('Session', 'UserLogin', async () => {
        await getActiveSessionAndStoreIt();
    });
}

function subscribeToInvitationAccepted() {
    const eventBus = container.get<DomainEventBus>(DomainEventBus);
    eventBus.subscribe<UserInviteAccepted>('UserInvite', 'Accepted', async () => {
        const result = await execute(GetActiveUserProfile);
        if (isOK(result)) {
            const userOrganizationIdList = result.value.organizations.map(
                (organization) => organization.id,
            );
            AppContextStore.update({
                activeSession: {
                    ...AppContextStore.context.activeSession,
                    userOrganizationIdList,
                },
            });
        }
    });
}

function subscribeToDeauthenticateEvent() {
    const eventBus = container.get<DomainEventBus>(DomainEventBus);

    eventBus.subscribe<SessionDeauthenticated>('Session', 'Deauthenticated', async (event) => {
        if (event.source != DomainEventSourceLocal) {
            window.location.href = '/login';
        }
    });
}

function subscribeToLoginAsAdminEvent() {
    const eventBus = container.get<DomainEventBus>(DomainEventBus);

    eventBus.subscribe<UserLoginByAdmin>('User', 'LoginByAdmin', async (event) => {
        if (event.source != DomainEventSourceLocal) {
            window.location.href = '/login';
        }
    });
}

function subscribeToNoSessionEvent() {
    const eventBus = container.get<DomainEventBus>(DomainEventBus);

    eventBus.subscribe<SessionLost>('Session', 'SessionLost', async (event) => {
        window.location.href = '/logout';
    });
}

async function performAutoLogin(): Promise<AppContext> {
    const result = await execute(AutoLogin);
    if (isErr(result)) {
        return {} as AppContext;
    }
    const hasActiveSession = result.value.isAuthenticated;
    const activeSession = {
        isAuthenticated: result.value.isAuthenticated,
        authenticatedUserId: result.value.isAuthenticated ? result.value.userId : null,
        userId: result.value.isAuthenticated ? result.value.userId : null,
        organizationId: result.value.isAuthenticated ? result.value.organizationId : null,
        userOrganizationIdList: result.value.isAuthenticated
            ? result.value.userOrganizationIdList
            : undefined,
        roles: result.value.isAuthenticated ? result.value.roles : null,
    } as ActiveSession;
    return {
        hasActiveSession,
        activeSession,
    } as AppContext;
}
