import React from 'react';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { GetQuestionerForm, GetQuestionerFormResponse } from '../../useCases/GetQuestionerForm';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { AppTypeCode } from '../../../shopping/types/enums';
import { Immutable, Spinner } from '@embroker/ui-toolkit/v2';
import { ExpandedApplicationView } from '../../../shopping/useCases/GetApplication';
import { QuestionerRenderer } from './QuestionerRenderer';
import { navigateToErrorPage } from '../../../view/errors';
import { execute } from '@embroker/shotwell/core/UseCase';
import { SaveQuestionerAnswers } from '@app/shoppingQuestioner/useCases/SaveQuestionerAnswers';

interface QuestionerRendererProps {
    appTypeCode?: AppTypeCode[];
    application: Immutable<ExpandedApplicationView>;
}
export function QuestionerApplicationQuestionnaire({ application }: QuestionerRendererProps) {
    const { navigate } = useNavigation();

    // Stubbing questionnaireIds for now to work with stubbed Questioner data
    // TODO: Integrate with gateway
    // https://embroker.atlassian.net/browse/EM-43122
    const questionnaireIds = ['questionnaire1'];
    const getQuestionerFormResp = useUseCase(GetQuestionerForm, { questionnaireIds });
    const { isLoading, result: getQuestionerFormResult } = getQuestionerFormResp;

    if (isLoading || !getQuestionerFormResult) {
        return <Spinner />;
    }

    if (getQuestionerFormResult && isErr(getQuestionerFormResult)) {
        navigateToErrorPage(navigate, getQuestionerFormResult.errors);
        return null;
    }

    const { questionerPageDefinitions, formQuestionDefinitions, questionerQuestions } =
        getQuestionerFormResult.value as GetQuestionerFormResponse;

    const handleSaveAndExit = () => {
        // TODO: Do save and exit things here
        navigate('/');
    };

    const handleFormSubmit = async (formData: { [key: string]: unknown }) => {
        execute(SaveQuestionerAnswers, {
            formData,
            questionerQuestions,
        });
    };

    const handlePageComplete = async (formData: { [key: string]: unknown }) => {
        execute(SaveQuestionerAnswers, {
            formData,
            questionerQuestions,
        });
    };

    return (
        <QuestionerRenderer
            pages={questionerPageDefinitions}
            questions={formQuestionDefinitions}
            onFormSubmit={handleFormSubmit}
            onSaveAndExit={handleSaveAndExit}
            onPageComplete={handlePageComplete}
        />
    );
}
