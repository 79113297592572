import React, { PropsWithChildren } from 'react';
import {
    ElementProps,
    PageLayout,
    WizardLayout,
    ColumnLayout,
    StackLayout,
    usePageLayout,
    Text,
    ButtonBar,
    Button,
    ImageProps,
    Image,
    Spinner,
    Header,
} from '@embroker/ui-toolkit/v2';

// TODO: https://embroker.atlassian.net/browse/EM-39112 to address
// the duplication of ActionProps interface and consolidating those
// into one reused interface
// https://github.com/embroker/main/blob/e1c8f720e52d93dbcf424754ab55515b802e2b55/app/src/view/components/ModalLayout.view.tsx#L12C1-L17
interface ActionProps {
    label: string;
    onClick?: () => void;
    'data-e2e'?: string;
}

export interface LandingPageLayoutProps extends PropsWithChildren<ElementProps<'div'>> {
    /**
     * Title to be displayed on the page
     */
    title?: string;
    /**
     * Image component props
     */
    imageProps?: ImageProps;
    /**
     * Primary CTA button
     */
    primaryAction?: ActionProps;
    /**
     * Secondary CTA button
     */
    secondaryAction?: ActionProps;
    /**
     * Loading state of the page (renders a Spinner component)
     */
    isLoading?: boolean;
}

export function LandingPageLayout({
    title,
    children,
    imageProps,
    primaryAction,
    secondaryAction,
    isLoading,
}: LandingPageLayoutProps) {
    const state = usePageLayout({
        header: (
            <Header>
                <a href="#">
                    <Image name="logotype" height={20} />
                </a>
            </Header>
        ),
    });

    return (
        <PageLayout {...state}>
            {isLoading && <Spinner />}
            <WizardLayout>
                <WizardLayout.Cover>
                    <ColumnLayout
                        gap="64"
                        responsive={{ containerWidth: { smallerThan: 'tablet' } }}
                    >
                        <StackLayout gap="16" className="u-5/7@large-tablet u-1/1">
                            {title ? (
                                <Text style="heading 3" color="brand-400" data-e2e="page-title">
                                    {title}
                                </Text>
                            ) : null}
                            <StackLayout gap="48">
                                <StackLayout gap="12" data-e2e="body-text">
                                    {children ? (
                                        typeof children === 'string' ? (
                                            <Text>{children}</Text>
                                        ) : (
                                            children
                                        )
                                    ) : null}
                                </StackLayout>
                                {(primaryAction || secondaryAction) && (
                                    <ButtonBar
                                        gap="24"
                                        responsive={{ containerWidth: { smallerThan: 430 } }}
                                    >
                                        {primaryAction && (
                                            <Button
                                                appearance="primary"
                                                type="button"
                                                onClick={primaryAction.onClick}
                                                data-e2e={primaryAction['data-e2e']}
                                            >
                                                {primaryAction.label}
                                            </Button>
                                        )}
                                        {secondaryAction && (
                                            <Button
                                                appearance="secondary"
                                                type="button"
                                                onClick={secondaryAction.onClick}
                                                data-e2e={secondaryAction['data-e2e']}
                                            >
                                                {secondaryAction.label}
                                            </Button>
                                        )}
                                    </ButtonBar>
                                )}
                            </StackLayout>
                        </StackLayout>
                        {imageProps && (
                            <StackLayout className="u-1/2@large-tablet u-1/1">
                                <Image {...imageProps} />
                            </StackLayout>
                        )}
                    </ColumnLayout>
                </WizardLayout.Cover>
            </WizardLayout>
        </PageLayout>
    );
}
