import { CoverLayout, Placeholder, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';

export function LocationStepGuidelines() {
    return (
        <CoverLayout>
            <StackLayout split="-1" gap="24">
                <StackLayout gap="4">
                    <Text style="overline" color={'brand-500'}>
                        Step 2
                    </Text>
                    <Text style={'heading 3'}>Just a little more information.</Text>
                </StackLayout>
                <Text style="body 1">
                    This is the address that will appear for the requesting business on the
                    certificate of insurance.
                </Text>
                <Placeholder
                    backgroundColor="ui-50"
                    appearance="compact"
                    imageMaxWidth={270}
                    imageName="illustration-male-document"
                />
            </StackLayout>
        </CoverLayout>
    );
}
