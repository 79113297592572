import { State } from '@embroker/shotwell/core/types/StateList';
import { CheckBox, TextButton, useModal } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { AgreementToConductModal } from '../../../../signature/components/AgreementToConductModal';
import { WarrantyAndFraudModal } from '../../../../signature/components/WarrantyAndFraudModal';

export interface SignatureFieldProps {
    readOnly: boolean;
    fullName: string;
    titleValue: string;
    state?: State;
    onChange: any;
    value: ('transfers' | 'warranty')[];
}

export const SignatureField = React.forwardRef(function SignatureField(
    { readOnly, fullName, titleValue, state, onChange, value = [] }: SignatureFieldProps,
    ref: React.Ref<HTMLDivElement>,
) {
    const warrantyFraudStateAndActions = useModal();
    const agreementStateAndActions = useModal();

    const handleChange =
        (checkbox: 'transfers' | 'warranty') =>
        ({ target: { checked } }: any) => {
            const newValue = [...value];
            if (checked) {
                newValue.push(checkbox);
            } else {
                const index = value.indexOf(checkbox);
                newValue.splice(index, 1);
            }
            onChange(newValue);
        };

    return (
        <div ref={ref} tabIndex={-1}>
            <CheckBox
                readOnly={readOnly}
                checked={value.includes('transfers')}
                onChange={handleChange('transfers')}
            >
                <span>
                    By checking this box, you are electronically signing an insurance application
                    and confirm that you have read and consent to our{' '}
                    <TextButton onClick={agreementStateAndActions.show}>
                        Agreement to Conduct Electronic Transfers
                    </TextButton>
                    .
                </span>
                <AgreementToConductModal stateAndActions={agreementStateAndActions} />
            </CheckBox>
            <br />
            <br />
            <CheckBox
                readOnly={readOnly}
                checked={value.includes('warranty')}
                onChange={handleChange('warranty')}
            >
                <span>
                    By checking this box, you certify all information provided in the insurance
                    application is true and correct and acknowledge that you have read and agree to
                    the{' '}
                    <TextButton onClick={warrantyFraudStateAndActions.show}>
                        Warranty and Fraud Statement
                    </TextButton>
                    .
                </span>
                <WarrantyAndFraudModal
                    stateAndActions={warrantyFraudStateAndActions}
                    fullName={fullName}
                    title={titleValue}
                    state={state}
                />
            </CheckBox>
            <br />
            <br />
            <hr />
            <br />
        </div>
    );
});
