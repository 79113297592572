import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { TextButton } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { WizardForm } from '../../../view/hooks/useWizardForm';

interface DownloadGeneralTermsAndConditionsButtonProps<T extends FormData> {
    isSubmitting: boolean;
    trigger: WizardForm<OpaqueForm<T>>['trigger'];
    isDisabled?: boolean;
}

export function DownloadGeneralTermsAndConditionsButton<T extends FormData>({
    isSubmitting,
    trigger,
    isDisabled,
}: DownloadGeneralTermsAndConditionsButtonProps<T>) {
    return (
        <TextButton
            as="button"
            disabled={isDisabled || isSubmitting}
            icon="download"
            color="ui-50"
            onClick={() => {
                trigger('downloadGTC');
            }}
            data-e2e="general-terms-and-conditions"
        >
            General Terms & Conditions
        </TextButton>
    );
}
