import {
    BoxLayout,
    Button,
    ColumnLayout,
    Image,
    StackLayout,
    Text,
    useResponsive,
    PageLayout,
    WizardLayout,
    usePageLayout,
} from '@embroker/ui-toolkit/v2';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { URI } from '@embroker/shotwell/core/types/URI';
import React from 'react';

export const BundleQuoteHigherLimitAndReferralThankYou = () => {
    const { navigate } = useNavigation();

    const isMobile = useResponsive({ screenWidth: { smallerThan: 'tablet' } });

    const navigateToDashboard = () => {
        navigate(URI.build('/summary'));
    };

    const state = usePageLayout({ isFullscreen: true, backgroundColor: 'ui-200' });

    return (
        <PageLayout {...state}>
            <WizardLayout onDismiss={navigateToDashboard} backgroundColor="ui-200">
                <PageLayout.Section style={{ minHeight: '100vh' }}>
                    <ColumnLayout
                        responsive={{
                            containerWidth: { smallerThan: 'tablet' },
                        }}
                        gap={isMobile ? '32' : 'none'}
                    >
                        <BoxLayout className="u-1/2@large-tablet u-1/1">
                            <StackLayout gap="24">
                                <Text
                                    style="heading 1"
                                    color="brand-400"
                                    data-e2e="application-received"
                                >
                                    Thank you!
                                </Text>
                                <Text style="body 1" color="brand-400">
                                    You have successfully submitted your request!
                                </Text>
                                <Text style="body 1" color="brand-400">
                                    For the next step, we’ll submit your request to an Underwriter
                                    for review. Once the review is complete, an Embroker Team Member
                                    will be in touch with you via email in 1-2 business days.
                                </Text>
                                <Text style="body 1" color="brand-400">
                                    From there, we will ask you a few additional questions to better
                                    understand your business so we can help you get protected.
                                </Text>

                                <StackLayout gap={isMobile ? 'none' : '24'}>
                                    <span />

                                    <ColumnLayout
                                        gap="16"
                                        responsive={{ containerWidth: { smallerThan: 430 } }}
                                    >
                                        <Button
                                            onClick={navigateToDashboard}
                                            data-e2e="go-to-dashboard"
                                        >
                                            Go to Dashboard
                                        </Button>
                                    </ColumnLayout>
                                </StackLayout>
                            </StackLayout>
                        </BoxLayout>

                        <BoxLayout className="u-1/2@large-tablet u-1/1">
                            <Image width="100%" name="illustration-review" />
                        </BoxLayout>
                    </ColumnLayout>
                </PageLayout.Section>
            </WizardLayout>
        </PageLayout>
    );
};
