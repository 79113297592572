import { container, ContainerModule } from '@embroker/shotwell/core/di';
import { Module } from '../Module';
import { AppContext } from '../view/AppContext';
import { CookieSnapshotService } from './services/CookieSnapshotService';

/**
 * The claims module.
 */
export const MarketingModule: Module = {
    container: new ContainerModule((bind) => {
        bind<CookieSnapshotService>(CookieSnapshotService)
            .to(CookieSnapshotService)
            .inSingletonScope();
    }),
    bootstrap: async () => {
        container.get<CookieSnapshotService>(CookieSnapshotService);

        return {} as AppContext;
    },
};
