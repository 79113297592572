import { container } from '@embroker/shotwell/core/di';
import { isDomainEventLocal } from '@embroker/shotwell/core/event/DomainEvent';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { execute } from '@embroker/shotwell/core/UseCase';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { useDomainEvent } from '@embroker/shotwell/view/hooks/useDomainEvent';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    CardLayout,
    CenterLayout,
    ColumnLayout,
    Nullable,
    Spinner,
    StackLayout,
    Text,
    GridLayout,
    PageLayout,
    useModal,
} from '@embroker/ui-toolkit/v2';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { GetRecommendedCoverages } from '../../../recommendations/useCases/GetRecommendedCoverages';
import { RecommendedCoverages } from '../../../summary/view/components/RecommendedCoverages';
import { OrganizationUpdated } from '../../../userOrg/entities/Organization';
import { hasRole } from '../../../userOrg/entities/Session';
import { GetActiveOrganizationProfile } from '../../../userOrg/useCases/GetActiveOrganizationProfile';
import { GetOrganizationProfile } from '../../../userOrg/useCases/GetOrganizationProfile';
import { AppContext } from '../../../view/AppContext';
import { PolicyEndorsed, PolicyTransfered } from '../../entities/Policy';
import { BorStatusIsBor } from '../../types/BorStatus';
import { DisplayPolicy } from '../../types/DisplayPolicy';
import { PolicyFilter as PolicyFilterType } from '../../types/PolicyFilter';
import { GetPolicies, GetPoliciesRequest } from '../../useCases/GetPolicies';
import { GetPolicyFilter } from '../../useCases/GetPolicyFilter';
import { GetUnprocessedPoliciesUseCase } from '../../useCases/GetUnprocessedPolicies';
import { EmbrokerPolicyList } from './EmbrokerPolicyList';
import { EndorsementSubmittedStatus } from './EndorsementSubmittedStatus';
import { PolicyFilter } from './PolicyFilter';
import { PolicyList } from './PolicyList';
import { PolicyUpload } from './PolicyUpload';
import { GetGlobalConfig } from '../../../config/useCases/GetGlobalConfigUseCase';
import { AscendPaymentsModal } from '../modals/AscendPaymentsModal';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { GetQuoteSummaryByPaymentIds } from '@app/shopping/useCases/GetQuoteSummaryByPaymentIds';
import { GetBillingInfo } from '@app/payments/useCases/GetBillingInfo';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { NAICS_CODE_TO_VERTICAL } from '@app/userOrg/types/enums';

interface PoliciesProps {
    paymentIdList: string;
}

export function Policies({ paymentIdList }: PoliciesProps) {
    const [policies, setPolicies] = useState<Nullable<DisplayPolicy[]>>(null);
    const [hasAnyPolicy, setHasAnyPolicy] = useState(false);
    const { result: unprocessedPoliciesResult, reload: reloadUnprocessedPolicies } = useUseCase(
        GetUnprocessedPoliciesUseCase,
    );
    const { result: globalConfigResult } = useUseCase(GetGlobalConfig);
    const { result: policyFilterResult, reload: reloadFilter } = useUseCase(GetPolicyFilter);
    const { result: recommendedCoverages } = useUseCase(GetRecommendedCoverages);
    const { result: getActiveOrganizationProfileResponse } = useUseCase(
        GetActiveOrganizationProfile,
    );
    const [isLoading, setIsLoading] = useState(false);
    const [unprocessedPolicyCount, setUnprocessedPolicyCount] = useState(0);
    const [showEndorsementMessage, setShowEndorsementMessage] = useState(false);
    const [endorsementPolicyName, setEndorsementPolicyName] = useState('');
    const [endorsementPolicyNumber, setEndorsementPolicyNumber] = useState('');
    const [paymentList, setPaymentList] = useState('');
    const [quoteInfo, setQuoteInfo] = useState({});

    const ascendPaymentsModal = useModal();

    useEffect(() => {
        if (paymentIdList && paymentIdList != paymentList) {
            setPaymentList(paymentIdList);
            ascendPaymentsModal.show();
        }
    }, [paymentIdList, ascendPaymentsModal, paymentList]);

    const { activeSession } = useContext(AppContext);

    const organizationId: UUID = activeSession.organizationId as UUID;
    const { result: existingBillingInfo } = useUseCase(GetBillingInfo, {
        organizationId: organizationId,
    });

    const { result: paymentIdListResult } = useUseCase(GetQuoteSummaryByPaymentIds, {
        paymentIdList: paymentIdList,
        policyList: policies,
    });
    useEffect(() => {
        if (paymentIdListResult == undefined) {
            return;
        }
        if (isErr(paymentIdListResult)) {
            return;
        }
        const quoteInfo = paymentIdListResult.value;
        setQuoteInfo(quoteInfo.quote);
    }, [paymentIdListResult]);

    const handleFetchPolicies = useCallback((filter: PolicyFilterType) => {
        const request = {
            filter,
        } as GetPoliciesRequest;

        setIsLoading(true);

        execute(GetPolicies, request)
            .then((response) => {
                if (isOK(response)) {
                    setPolicies(response.value.policyList as DisplayPolicy[]);
                    setHasAnyPolicy(response.value.hasAnyPolicy);
                } else {
                    container.get<Logger>(Log).error(response.errors);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        if (!policyFilterResult || !unprocessedPoliciesResult) {
            return;
        }
        if (isErr(policyFilterResult)) {
            container.get<Logger>(Log).error(policyFilterResult.errors);
            return;
        }
        if (isErr(unprocessedPoliciesResult)) {
            container.get<Logger>(Log).error(unprocessedPoliciesResult.errors);
            return;
        }
        setUnprocessedPolicyCount(
            policyFilterResult.value.filter.showActive ? unprocessedPoliciesResult.value.length : 0,
        );
    }, [policyFilterResult, unprocessedPoliciesResult]);

    useEffect(() => {
        if (!policyFilterResult) {
            return;
        }
        if (isOK(policyFilterResult)) {
            handleFetchPolicies(policyFilterResult.value.filter);
        } else {
            container.get<Logger>(Log).error(policyFilterResult.errors);
        }
    }, [policyFilterResult, handleFetchPolicies]);

    const [organizationName, setOrganizationName] = useState('');
    const [organizationEmail, setOrganizationEmail] = useState<Nullable<EmailAddress>>(null);

    useDomainEvent<OrganizationUpdated>('Organization', 'Updated', async (event) => {
        if (!isDomainEventLocal(event)) {
            return;
        }
        const getOrganizationProfileResult = await execute(GetOrganizationProfile, {
            organizationId: event.id,
        });
        if (isOK(getOrganizationProfileResult)) {
            setOrganizationName(getOrganizationProfileResult.value.organization.companyLegalName);
            setOrganizationEmail(getOrganizationProfileResult.value.organization.email);
        } else {
            container.get<Logger>(Log).error(getOrganizationProfileResult.errors);
        }
    });

    useDomainEvent<PolicyEndorsed>('Policy', 'Endorsed', async (event) => {
        if (!isDomainEventLocal(event)) {
            return;
        }
        showEndorsementToastMessage(event.policyName, event.policyNumber);
    });

    useDomainEvent<PolicyTransfered>('Policy', 'Transferred', async (event) => {
        if (!isDomainEventLocal(event)) {
            return;
        }
        reloadPolicies();
    });

    const { result: organizationProfileResult } = useUseCase(GetActiveOrganizationProfile);
    useEffect(() => {
        if (organizationProfileResult == undefined) {
            return;
        }
        if (isErr(organizationProfileResult)) {
            container.get<Logger>(Log).error(organizationProfileResult.errors);
            return;
        }
        setOrganizationName(organizationProfileResult.value.organization.companyLegalName);
        setOrganizationEmail(organizationProfileResult.value.organization.email);
    }, [organizationProfileResult]);

    if (
        policyFilterResult === undefined ||
        policyFilterResult === null ||
        policies === null ||
        unprocessedPoliciesResult === undefined ||
        recommendedCoverages === undefined ||
        organizationName === undefined ||
        organizationName === '' ||
        globalConfigResult === undefined ||
        existingBillingInfo === undefined ||
        getActiveOrganizationProfileResponse === undefined
    ) {
        return <Spinner />;
    }

    if (isErr(unprocessedPoliciesResult)) {
        return <ErrorPage errors={unprocessedPoliciesResult.errors} />;
    }
    if (isErr(recommendedCoverages)) {
        return <ErrorPage errors={recommendedCoverages.errors} />;
    }
    if (isErr(policyFilterResult)) {
        return <ErrorPage errors={policyFilterResult.errors} />;
    }
    if (isErr(globalConfigResult)) {
        return <ErrorPage errors={globalConfigResult.errors} />;
    }
    if (isErr(existingBillingInfo)) {
        return <ErrorPage errors={existingBillingInfo.errors} />;
    }
    if (isErr(getActiveOrganizationProfileResponse)) {
        return <ErrorPage errors={getActiveOrganizationProfileResponse.errors} />;
    }

    const billingEmail =
        String(existingBillingInfo.value.billingEmailAddress) ?? String(organizationEmail);

    function showEndorsementToastMessage(policyName: string, policyNumber: string) {
        setShowEndorsementMessage(true);
        setEndorsementPolicyName(policyName);
        setEndorsementPolicyNumber(policyNumber);
    }
    const bundleRepresentationEnabled =
        globalConfigResult.value.config.isBundleRepresentationEnabled;
    const isBroker = hasRole(activeSession, 'broker');
    const embrokerPolicies: DisplayPolicy[] = [];
    const bundlePolicies: DisplayPolicy[] = [];
    const nonEmbrokerPolicies: DisplayPolicy[] = [];

    for (const policy of policies) {
        if (policy.borStatus == BorStatusIsBor) {
            if (bundleRepresentationEnabled && policy.bundleId != null) {
                bundlePolicies.push(policy);
            } else {
                embrokerPolicies.push(policy);
            }
        } else {
            nonEmbrokerPolicies.push(policy);
        }
    }

    const bundlePoliciesTitle = 'Your Program Policies';
    const policiesTitle = 'Your Policies';
    const pageTitle =
        bundlePolicies.length == policies.length && hasAnyPolicy
            ? bundlePoliciesTitle
            : policiesTitle;

    const reloadPolicies = () => {
        execute(GetPolicyFilter).then((result) => {
            if (isOK(result)) {
                handleFetchPolicies(result.value.filter);
            } else {
                container.get<Logger>(Log).error(result.errors);
            }
        });
    };

    const organization = getActiveOrganizationProfileResponse.value.organization;
    const isLawFirm =
        organization.naics && NAICS_CODE_TO_VERTICAL[organization.naics] === 'LawFirm';

    if (!isBroker) {
        return (
            <React.Fragment>
                <AscendPaymentsModal
                    email={billingEmail}
                    quoteInfo={quoteInfo}
                    modal={ascendPaymentsModal}
                />
                <PageLayout.Section>
                    <GridLayout>
                        <StackLayout className="u-grid-size-12">
                            <ColumnLayout
                                split="-2"
                                responsive={{ containerWidth: { smallerThan: 'tablet' } }}
                            >
                                <Text style="heading 3">{pageTitle}</Text>
                                <PolicyFilter
                                    filter={policyFilterResult.value.filter}
                                    isLoading={isLoading}
                                    onChange={reloadFilter}
                                />
                            </ColumnLayout>
                            {showEndorsementMessage ? (
                                <EndorsementSubmittedStatus
                                    policyNumber={endorsementPolicyNumber}
                                    policyName={endorsementPolicyName}
                                />
                            ) : null}
                            {embrokerPolicies.length > 0 ? (
                                <EmbrokerPolicyList
                                    hasAnyPolicy={hasAnyPolicy}
                                    policyList={embrokerPolicies}
                                    showActionButtons
                                />
                            ) : null}
                            {bundlePolicies.length > 0 ? (
                                <PolicyList
                                    policyList={bundlePolicies}
                                    showActionButtons
                                    title={
                                        pageTitle !== bundlePoliciesTitle
                                            ? bundlePoliciesTitle
                                            : null
                                    }
                                />
                            ) : null}
                            {nonEmbrokerPolicies.length > 0 ? (
                                <PolicyList
                                    policyList={nonEmbrokerPolicies}
                                    unprocessedPolicyCount={unprocessedPolicyCount}
                                    showActionButtons
                                    title="Non-Embroker Policies"
                                />
                            ) : null}
                            <PolicyUpload
                                onProcessingSuccess={() => {
                                    reloadPolicies();
                                    reloadUnprocessedPolicies();
                                }}
                                onPolicyUploaded={() => {
                                    setUnprocessedPolicyCount(unprocessedPolicyCount + 1);
                                }}
                            />
                            {!isLawFirm && (
                                <RecommendedCoverages
                                    coverageList={recommendedCoverages.value.recommendedCoverages}
                                />
                            )}
                        </StackLayout>
                    </GridLayout>
                </PageLayout.Section>
            </React.Fragment>
        );
    }

    if (hasAnyPolicy) {
        return (
            <React.Fragment>
                <AscendPaymentsModal
                    email={billingEmail}
                    quoteInfo={quoteInfo}
                    modal={ascendPaymentsModal}
                />
                <PageLayout.Section>
                    <StackLayout>
                        <ColumnLayout split="-2">
                            <Text style="heading 3">{`${organizationName}'s Policies`}</Text>
                            <PolicyFilter
                                filter={policyFilterResult.value.filter}
                                isLoading={isLoading}
                                onChange={reloadFilter}
                            />
                        </ColumnLayout>
                        {showEndorsementMessage ? (
                            <EndorsementSubmittedStatus
                                policyNumber={endorsementPolicyNumber}
                                policyName={endorsementPolicyName}
                            />
                        ) : null}
                        <EmbrokerPolicyList
                            hasAnyPolicy={hasAnyPolicy}
                            policyList={embrokerPolicies}
                            showActionButtons={false}
                        />
                    </StackLayout>
                </PageLayout.Section>
            </React.Fragment>
        );
    }

    return (
        <PageLayout.Section>
            <CardLayout>
                <CardLayout.Header>
                    <Text style="heading 5">Policies</Text>
                </CardLayout.Header>
                <CardLayout.Body>
                    <CenterLayout>
                        <Text>
                            You have no policies yet. Once your clients have coverage, the list will
                            appear here
                        </Text>
                    </CenterLayout>
                </CardLayout.Body>
            </CardLayout>
        </PageLayout.Section>
    );
}

export default Policies;
