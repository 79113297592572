import React, { useEffect, useMemo } from 'react';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import { execute } from '@embroker/shotwell/core/UseCase';
import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { FormViewLayout } from '../../../../view/components/FormViewLayout.view';
import { PublishOnboardingStepVisitedEvent } from '../../../useCases/PublishOnboardingStepVisitedEvent';
import { OnboardingPageProps } from './OnboardingFlowWrapper';
import { PageLayout, StackLayout, StatusMessage, Text, RadioGroup } from '@embroker/ui-toolkit/v2';
import { OnboardingPrefillQuestionnaireData } from '../../../types/OnboardingPrefillQuestionnaireData';
import { Success, isOK } from '@embroker/shotwell/core/types/Result';
import { GetApplicant } from '../../../../shopping/useCases/GetApplicant';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';

interface LawVerticalRefinementFormData {
    hasEmployees: 'yes' | 'no';
    locationType: 'homeoffice' | 'rent' | 'own';
    usesEmployeeOrRentalVehicles: 'yes' | 'no';
}

const createLawVerticalRefinementForm = ({
    onCompleteOnboardingStep,
}: {
    onCompleteOnboardingStep: (questionnaireData: OnboardingPrefillQuestionnaireData) => void;
}) => {
    const LawVerticalRefinementForm = createForm<LawVerticalRefinementFormData>({
        fields: {
            hasEmployees: {
                type: 'text',
                validator: Joi.string().required(),
                formatValidationError(error) {
                    return 'You must select an option';
                },
            },
            locationType: {
                type: 'text',
                validator: Joi.string().required(),
                formatValidationError(error) {
                    return 'You must select an option';
                },
            },
            usesEmployeeOrRentalVehicles: {
                type: 'text',
                validator: Joi.string().required(),
                formatValidationError(error) {
                    return 'You must select an option';
                },
            },
        },
        submit: async ({ hasEmployees, locationType, usesEmployeeOrRentalVehicles }) => {
            onCompleteOnboardingStep({
                lawVerticalRefinementData: {
                    hasEmployees: hasEmployees === 'yes',
                    locationType,
                    usesEmployeeOrRentalVehicles: usesEmployeeOrRentalVehicles === 'yes',
                },
            });
            return Success();
        },
        formatSubmitErrors(errors) {
            if (errors.length === 0) {
                return [];
            }
            container
                .get<Logger>(Log)
                .warn(`User onboarding - NAICs code refinement page formatSubmitErrors: ${errors}`);
            return ['Sorry, something went wrong. Please try again later.'];
        },
    });
    return LawVerticalRefinementForm;
};

export function LawVerticalRefinmentQuestion({ onCompleteOnboardingStep }: OnboardingPageProps) {
    const LawVerticalRefinementForm = useMemo(
        () => createLawVerticalRefinementForm({ onCompleteOnboardingStep }),
        [onCompleteOnboardingStep],
    );
    const { fields, value, messages, setValue, submit } = useForm(LawVerticalRefinementForm);

    const { result: getApplicantResult } = useUseCase(GetApplicant);
    const mainLocation =
        getApplicantResult &&
        isOK(getApplicantResult) &&
        getApplicantResult.value.applicant.headquarters.addressLine1;

    // These useEffects could be moved into the OnboardingFlowWrapper component
    useEffect(() => {
        execute(PublishOnboardingStepVisitedEvent, {
            step: 'lawVerticalRefinement',
        });
    }, []);

    const YesOrNoOptions: { title: string; value: 'yes' | 'no' }[] = [
        {
            title: 'Yes',
            value: 'yes',
        },
        {
            title: 'No',
            value: 'no',
        },
    ];

    const LocationOptions: { title: string; value: 'homeoffice' | 'rent' | 'own' }[] = [
        {
            title: 'This is my home office location',
            value: 'homeoffice',
        },
        {
            title: 'I rent an office at this location',
            value: 'rent',
        },
        {
            title: 'I own this location',
            value: 'own',
        },
    ];

    const disableSubmit = !(
        fields.hasEmployees.props.value &&
        fields.locationType.props.value &&
        fields.usesEmployeeOrRentalVehicles.props.value
    );

    return (
        <PageLayout.Section>
            <FormViewLayout
                title="Tell us a few more details about your business so we can help you find the right protection."
                errorMessages={messages}
                disableSubmit={disableSubmit}
                onFormSubmit={submit}
            >
                <StackLayout gap="16">
                    <Text>Does your firm have at least one employee?</Text>
                    <RadioGroup
                        onChange={(e) => {
                            setValue({
                                ...value,
                                hasEmployees: e?.target.value,
                            });
                        }}
                        items={YesOrNoOptions}
                        value={fields.hasEmployees.props.value}
                    />
                    <StatusMessage status="helptext">
                        &quot;Employee&quot; refers to any staff on payroll.
                    </StatusMessage>
                    <Text>Which best describes your workspace at {mainLocation}?</Text>
                    <RadioGroup
                        onChange={(e) => {
                            setValue({
                                ...value,
                                locationType: e?.target.value,
                            });
                        }}
                        items={LocationOptions}
                        value={fields.locationType.props.value}
                    />
                    <Text>
                        Does anyone at the firm use employee-owned or rental vehicles for work
                        purposes (driving to court or meeting clients, etc.)?
                    </Text>
                    <RadioGroup
                        onChange={(e) => {
                            setValue({
                                ...value,
                                usesEmployeeOrRentalVehicles: e?.target.value,
                            });
                        }}
                        items={YesOrNoOptions}
                        value={fields.usesEmployeeOrRentalVehicles.props.value}
                    />
                </StackLayout>
            </FormViewLayout>
        </PageLayout.Section>
    );
}
