import { ContainerModule } from '@embroker/shotwell/core/di';
import { Module } from '../../Module';
import { LPLQuoteRepository } from './repositories/LPLQuoteRepository';
import { APILPLQuoteRepository } from './repositories/LPLQuoteRepository/APILPLQuoteRepository';
import { CreateLPLDocument } from './useCases/CreateLPLDocument';
import { GetLastLPLQuote } from './useCases/GetLastLPLQuote';
import { PurchaseLPL } from './useCases/PurchaseLPL';
import { ReQuoteLPL } from './useCases/ReQuoteLPL';
import { GetLPLConfig } from './useCases/GetLPLConfig';
import { DownloadLPLQuoteSummary } from './useCases/DownloadLPLQuoteSummary';
import { DownloadLPLSpecimenPolicy } from './useCases/DownloadLPLSpecimenPolicy';
import { LPLRequestHigherLimit } from './useCases/LPLRequestHigherLimit';

export const LPLQuoteModule: Module = {
    container: new ContainerModule((bind) => {
        bind<LPLQuoteRepository>(LPLQuoteRepository).to(APILPLQuoteRepository).inSingletonScope();
        bind<GetLastLPLQuote>(GetLastLPLQuote.type).to(GetLastLPLQuote).inSingletonScope();
        bind<LPLRequestHigherLimit>(LPLRequestHigherLimit.type)
            .to(LPLRequestHigherLimit)
            .inSingletonScope();
        bind<ReQuoteLPL>(ReQuoteLPL.type).to(ReQuoteLPL).inSingletonScope();
        bind<PurchaseLPL>(PurchaseLPL.type).to(PurchaseLPL).inSingletonScope();
        bind<CreateLPLDocument>(CreateLPLDocument.type).to(CreateLPLDocument).inSingletonScope();
        bind<GetLPLConfig>(GetLPLConfig.type).to(GetLPLConfig).inSingletonScope();
        bind<DownloadLPLQuoteSummary>(DownloadLPLQuoteSummary.type)
            .to(DownloadLPLQuoteSummary)
            .inSingletonScope();
        bind<DownloadLPLSpecimenPolicy>(DownloadLPLSpecimenPolicy.type)
            .to(DownloadLPLSpecimenPolicy)
            .inSingletonScope();
    }),
};
