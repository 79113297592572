import React from 'react';
import { StackLayout, Text } from '@embroker/ui-toolkit/v2';
import { UnderlyingCoverageProps } from '../../../view/components/BundleQuoteCoverageList';
import { ESPQuote } from '../entities/ESPQuote';
import { BundleQuoteCardLayout } from '@app/bundle/view/components/BundleQuoteCardLayout.view';

// TODO: https://embroker.atlassian.net/browse/EM-43848
// This component is a placeholder for the actual implementation, will be handled in EM-43848
export function ESPBundleCoverageOptions(
    underlyingCoverageProps: UnderlyingCoverageProps<ESPQuote>,
) {
    return (
        <StackLayout>
            <BundleQuoteCardLayout gap="24">
                <Text>Tech Errors and Omissions / Cyber</Text>
                <Text>Options</Text>
            </BundleQuoteCardLayout>
            <BundleQuoteCardLayout gap="24">
                <Text>Directors and Officers</Text>
                <Text>Options</Text>
            </BundleQuoteCardLayout>
            <BundleQuoteCardLayout gap="24">
                <Text>Employment Practices Liability</Text>
                <Text>Options</Text>
            </BundleQuoteCardLayout>
            <BundleQuoteCardLayout gap="24">
                <Text>Fiduciary Liability</Text>
                <Text>Options</Text>
            </BundleQuoteCardLayout>
        </StackLayout>
    );
}
