import React from 'react';
import { AppTypeCode, AppTypeLabelMap } from '../../types/enums';
import { ColumnLayout, Icon, IconName, List, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import { isWithinInterval, parseISO, startOfToday } from 'date-fns';
import { NAICS_CODE_TO_VERTICAL, Vertical } from '../../../userOrg/types/enums';
import { Nullable } from '@embroker/shotwell/core/types';

interface CoverageStatus {
    status: string;
    timeFrame?: {
        start: Date;
        end: Date;
    };
}

function isWithinTimeFrame(timeFrame?: { start: Date; end: Date }): boolean {
    if (!timeFrame) {
        return true;
    }
    return isWithinInterval(startOfToday(), timeFrame);
}

const EXCESS_LABEL_START_DATE = '2023-02-19';
const EXCESS_LABEL_END_DATE = '2023-07-01';

export interface CoverageDetails {
    readonly title: React.ReactNode;
    readonly description: React.ReactNode;
    readonly sliderTitle: React.ReactNode;
    readonly sliderDescription: React.ReactNode;
    readonly sliderWhoNeedsTitle: React.ReactNode;
    readonly sliderWhoNeedsDescription: React.ReactNode;
    readonly sliderWhatIsTitle: React.ReactNode;
    readonly sliderWhatIsDescription: React.ReactNode;
    readonly sliderWhyNeedItTitle: React.ReactNode;
    readonly sliderWhyNeedItDescription: React.ReactNode;
    readonly coverages: string[];
    readonly nonIncludedCoverages: string[];
    readonly coveragesTitle: string;
    readonly estimatedTime: string;
    readonly icon: IconName;
    readonly status?: string;
}

export const lawProducts = [
    'AppTypeCodeListBOPChubb',
    'AppTypeCodeListCyberCowbell',
    'AppTypeCodeListWCChubb',
    'AppTypeCodeListEverestLawyersProfessionalLiability',
];

export function getCoverageDetails(
    appTypeCode: AppTypeCode,
    naicsIndustry?: string,
): CoverageDetails {
    const vertical = naicsIndustry ? NAICS_CODE_TO_VERTICAL[naicsIndustry] : undefined;

    // Card details
    const coverageProgramTitle =
        vertical && coverageProgramCardTitleMap.get(appTypeCode)?.get(vertical);
    const coverageTitle = coverageCardTitleMap.get(appTypeCode) || '';

    const coverageProgramDescription =
        vertical && coverageProgramCardDescriptionMap.get(appTypeCode)?.get(vertical);
    const coverageDescription = coverageCardDescriptionMap.get(appTypeCode);

    const title = coverageProgramTitle || coverageTitle || '';
    const description = coverageProgramDescription || coverageDescription || '';
    const icon =
        (vertical && coverageProgramIconMap.get(appTypeCode)?.get(vertical)) ||
        coverageIconMap.get(appTypeCode) ||
        'app-incomplete';

    // Learn more slideout
    // TODO: Work with copywriter to standardize title structure strategy across all coverages
    const doNotSuffixInsuranceToSliderTitleStrategy = [
        'AppTypeCodeListEmbrokerExcess',
        'AppTypeCodeListMPLBundle',
        'AppTypeCodeListMPL',
        'AppTypeCodeListManualCyber',
        'AppTypeCodeListEmbrokerCyber',
        'AppTypeCodeListCyberCowbell',
    ].includes(appTypeCode);

    const doSuffixInsuranceToSliderWhatIsTitleStrategy = [
        'AppTypeCodeListMPLBundle',
        'AppTypeCodeListMPL',
    ];

    const sliderTitle =
        vertical === 'LawFirm' && lawProducts.includes(appTypeCode)
            ? lawTitlesMap.get(appTypeCode)?.get(vertical)
            : title.endsWith('Insurance') || doNotSuffixInsuranceToSliderTitleStrategy
            ? title
            : `${title} Insurance`;
    const sliderDescription = coverageSliderDescriptionMap.get(appTypeCode) || '';
    const sliderWhatIsTitle =
        vertical === 'LawFirm' && lawProducts.includes(appTypeCode)
            ? lawCoverageTitleMap.get(appTypeCode)?.get(vertical)
            : doSuffixInsuranceToSliderWhatIsTitleStrategy.includes(appTypeCode)
            ? `What is ${title} Insurance?`
            : `What is ${sliderTitle}?`;
    const sliderWhatIsDescription =
        vertical === 'LawFirm' && lawProducts.includes(appTypeCode)
            ? lawCoverageDescriptionMap.get(appTypeCode)?.get(vertical)
            : whatIsCoverageDescriptionMap.get(appTypeCode) || '';
    const sliderWhoNeedsTitle = `Who Needs ${sliderTitle}?`;
    const sliderWhoNeedsDescription =
        vertical === 'LawFirm' && lawProducts.includes(appTypeCode)
            ? lawWhoNeedsDescriptionMap.get(appTypeCode)?.get(vertical)
            : whoNeedsCoverageDescriptionMap.get(appTypeCode) || '';
    const sliderWhyNeedItTitle =
        (vertical && whyNeedItProgramTitleMap.get(appTypeCode)?.get(vertical)) ||
        'Why do I need it?';
    const sliderWhyNeedItDescription = whyNeedItDescriptionMap.get(appTypeCode) || '';

    const coveragesTitle =
        vertical === 'LawFirm' && lawProducts.includes(appTypeCode)
            ? lawTitleCoveragesMap.get(appTypeCode)?.get(vertical) || ''
            : '';
    const coverages =
        vertical === 'LawFirm' && lawProducts.includes(appTypeCode)
            ? lawCoveragesMap.get(appTypeCode)?.get(vertical) || []
            : coveragesIncludedMap.get(appTypeCode) || [];
    const nonIncludedCoverages =
        vertical === 'LawFirm' && lawProducts.includes(appTypeCode)
            ? lawNonIncludedCoveragesMap.get(appTypeCode)?.get(vertical) || []
            : [];
    const estimatedTime = coveragesEstimateTimeMap.get(appTypeCode) || '';
    const status = getCoverageStatusLabel(appTypeCode);

    return {
        title,
        description,
        sliderTitle,
        sliderDescription,
        sliderWhoNeedsTitle,
        sliderWhoNeedsDescription,
        sliderWhatIsTitle,
        sliderWhatIsDescription,
        sliderWhyNeedItTitle,
        sliderWhyNeedItDescription,
        coveragesTitle,
        coverages,
        nonIncludedCoverages,
        estimatedTime,
        icon,
        status,
    };
}

function getCoverageStatusLabel(code: AppTypeCode) {
    const coverageStatus = coverageStatusMap.get(code);

    if (coverageStatus === undefined) {
        return;
    }

    const statusLabel = isWithinTimeFrame(coverageStatus.timeFrame)
        ? coverageStatus.status
        : undefined;

    return statusLabel;
}

const coverageStatusMap = new Map<AppTypeCode, CoverageStatus>([
    [
        'AppTypeCodeListEmbrokerExcess',
        {
            status: 'New',
            timeFrame: {
                start: parseISO(EXCESS_LABEL_START_DATE),
                end: parseISO(EXCESS_LABEL_END_DATE),
            },
        },
    ],
]);

export const coverageCardTitleMap = new Map<AppTypeCode, string>([
    ...(Object.entries(AppTypeLabelMap) as unknown as [AppTypeCode, string][]),
    ['AppTypeCodeListMPLBundle', 'Miscellaneous Professional Liability Bundle'],
    ['AppTypeCodeListMPL', 'Miscellaneous Professional Liability'],
]);

const coverageProgramCardTitleMap = new Map<AppTypeCode, Map<Vertical, string>>([
    [
        'AppTypeCodeListMPLBundle',
        new Map([
            ['Accounting', 'Accountants Professional Liability'],
            ['TaxPreparation', 'Tax Preparers & Bookkeepers Professional Liability'],
            ['RealEstateAgent', 'Real Estate Agent Professional Liability'],
            [
                'HomeInspectorAndBuildingInspectionServices',
                'Home Inspectors Professional Liability',
            ],
            ['NonTechnologyConsultants', 'Non-Technology Consultants Professional Liability'],
        ]),
    ],
    [
        'AppTypeCodeListMPL',
        new Map([
            ['Accounting', 'Accountants Professional Liability'],
            ['TaxPreparation', 'Tax Preparers & Bookkeepers Professional Liability'],
            ['RealEstateAgent', 'Real Estate Agent Professional Liability'],
            [
                'HomeInspectorAndBuildingInspectionServices',
                'Home Inspectors Professional Liability',
            ],
            ['NonTechnologyConsultants', 'Non-Technology Consultants Professional Liability'],
        ]),
    ],
]);

const coverageCardDescriptionMap = new Map<AppTypeCode, string>([
    [
        'AppTypeCodeListESP',
        `The all-in-one package custom designed to protect Startups. From executive decisions, and product errors, to hackers stealing PII, workplace harassment, and discrimination lawsuits.`,
    ],
    [
        'AppTypeCodeListTechEO',
        `Protects business against claims due to a failure of your technology or service. It covers the costs of lawsuits and more.`,
    ],
    [
        'AppTypeCodeListEmbrokerExcess',
        `Excess Technology E&O/Cyber coverage follows the terms and conditions of underlying primary policies (or other lower excess policies), and provides additional limits for that insurance program.`,
    ],
    [
        'AppTypeCodeListPCoML',
        `A one-two punch of insurance happiness. Your executives want their assets protected, and your company needs protection from alleged sexual harassment or discrimination suits.`,
    ],
    [
        'AppTypeCodeListManualAuto',
        `Protects business vehicles both owned and rented and will cover repair and replacement costs related to accidents, vandalism, theft, weather damage, and more.`,
    ],
    [
        'AppTypeCodeListManualCyber',
        `A modern business must-have, Cyber covers first and third party claims resulting from security/data breaches and cybercrimes like ransomware, phishing, and more.`,
    ],
    [
        'AppTypeCodeListEmbrokerCyber',
        `A modern business must-have, Cyber covers first and third party claims resulting from security/data breaches and cybercrimes like ransomware, phishing, and more.`,
    ],
    [
        'AppTypeCodeListCyberCowbell',
        `A modern business must-have, Cyber covers first and third party claims resulting from security/data breaches and cybercrimes like ransomware, phishing, and more.`,
    ],
    [
        'AppTypeCodeListManualDirectorsAndOfficers',
        `Allows executives to lead their companies fearlessly by protecting their personal assets from lawsuits alleging misuse of funds, breaches of fiduciary duty, and more.`,
    ],
    [
        'AppTypeCodeListManualEmploymentPractices',
        `Protects companies from employee lawsuits related to a variety of common workplace issues, such as harassment, discrimination, failure to promote, and more.`,
    ],
    [
        'AppTypeCodeListManualTravel',
        `Because you do not want to be stuck in whatever international destination the company is sending you to this week and worry about getting injured.`,
    ],
    [
        'AppTypeCodeListManualConstructionGL',
        `Essential coverage that protects businesses from third-party injuries and property damage claims. Covers claims of stolen ideas related to advertising as well.`,
    ],
    [
        'AppTypeCodeListManualGL',
        `Essential coverage that protects businesses from third-party injuries and property damage claims. Covers claims of stolen ideas related to advertising as well.`,
    ],
    [
        'AppTypeCodeListManualCannabis',
        `Essential coverage that protects businesses from third-party injuries and property damage claims. Covers claims of stolen ideas related to advertising as well.`,
    ],
    [
        'AppTypeCodeListManualAccountantsPL',
        `One mistake, or misplaced piece of advice and your clients may sue. Protect against claims of negligence or mistakes that cause financial harm to one of your clients.`,
    ],
    [
        'AppTypeCodeListManualConstructionPL',
        `Protects professional services providers (real estate agents, consultants, brokers, etc.) from civil lawsuits related to negligence, errors and omissions, and more.`,
    ],
    [
        'AppTypeCodeListManualTechEO',
        `How confident are you that your engineers didn't make any mistakes? Protects against claims that allege damages arising from technology services you have provided.`,
    ],
    [
        'AppTypeCodeListManualDefaultPL',
        `Protects professional services providers (real estate agents, consultants, brokers, etc.) from civil lawsuits related to negligence, errors and omissions, and more.`,
    ],
    [
        'AppTypeCodeListEverestLawyersProfessionalLiability',
        `Provides customized protection for lawyers against the many types of malpractice claims that can arise while providing professional services to clients and partners.`,
    ],
    [
        'AppTypeCodeListManualLawyersProfessionalLiability',
        `Provides customized protection for lawyers against the many types of malpractice claims that can arise while providing professional services to clients and partners.`,
    ],
    [
        'AppTypeCodeListManualProductLiability',
        `Protects businesses from claims related to product defects and shortcomings that could potentially lead to customer injuries or property damage.`,
    ],
    [
        'AppTypeCodeListManualProperty',
        `Provides financial help when business property has been lost or damaged. This policy covers not just buildings but also furniture, signage, inventory, and more.`,
    ],
    [
        'AppTypeCodeListManualWorkersCompensation',
        `Subject to state law, WC covers employee injuries/disease occurring the course/scope of employment. Employers Liability responds to claims alleging employer negligence.`,
    ],
    [
        'AppTypeCodeListGAWorkersCompensation',
        `Subject to state law, WC covers employee injuries/disease occurring the course/scope of employment. Employers Liability responds to claims alleging employer negligence.`,
    ],
    [
        'AppTypeCodeListWCChubb',
        `Subject to state law, WC covers employee injuries/disease occurring the course/scope of employment. Employers Liability responds to claims alleging employer negligence.`,
    ],
    [
        'AppTypeCodeListManualVentureCapitalAssetProtection',
        `You want efficiency and value in every investment. This all-in-one package is custom-designed to cover all your most significant liabilities.`,
    ],
    [
        'AppTypeCodeListManualHomeownersAssociation',
        `Provides protection for HOA members collectively for liability and/or property losses that they may be responsible for. Can be customized to include D&O, Crime and Umbrella.`,
    ],
    [
        'AppTypeCodeListCNABOP',
        `Get peace of mind with a single, cost-effective policy. A BOP combines multiple coverages to protect your business, your office and its contents.`,
    ],
    [
        'AppTypeCodeListHartfordBOP',
        `(HartFord) Get peace of mind with a single, cost-effective policy. A BOP combines multiple coverages to protect your business, your office and its contents.`,
    ],
    [
        'AppTypeCodeListBOPChubb',
        `Get peace of mind with a single, cost-effective policy. A BOP combines multiple coverages to protect your business, your office and its contents.`,
    ],
    [
        'AppTypeCodeListManualUmbrella',
        `Provides businesses with an extra layer of coverage that kicks in when the coverage limits of other liability policies have been reached and exhausted.`,
    ],
    [
        'AppTypeCodeListEmbrokerCrime',
        `Protects businesses from financial loss related to crimes such as employee theft and dishonesty, embezzlement, forgery, fraud, robbery, and more.`,
    ],
    [
        'AppTypeCodeListManualCrime',
        `Protects businesses from financial loss related to crimes such as employee theft and dishonesty, embezzlement, forgery, fraud, robbery, and more.`,
    ],
    [
        'AppTypeCodeListManualFiduciary',
        `Provides protection for the people administering company benefits plans from current and former employees alleging mismanagement or misleading advice.`,
    ],
    [
        'AppTypeCodeListLawBundle',
        `Combining essential coverages for law firms in a single application, this program is designed to protect you against your unique areas of business risk.`,
    ],
    [
        'AppTypeCodeListMPLBundle',
        `Protects you and your business if you're sued for negligence, common mistakes, and more. Also known as Errors and Omissions (E&O).`,
    ],
    [
        'AppTypeCodeListMPL',
        `Protects you and your business if you're sued for negligence, common mistakes, and more. Also known as Errors and Omissions (E&O).`,
    ],
]);

const coverageProgramCardDescriptionMap = new Map<AppTypeCode, Map<Vertical, string>>([
    [
        'AppTypeCodeListMPLBundle',
        new Map([
            [
                'NonTechnologyConsultants',
                `Protects Non-Technology Business and Management Consultants if you're sued for negligence, common mistakes, and more. Also known as Errors and Omissions (E&O).`,
            ],
        ]),
    ],
    [
        'AppTypeCodeListMPL',
        new Map([
            [
                'NonTechnologyConsultants',
                `Protects Non-Technology Business and Management Consultants if you're sued for negligence, common mistakes, and more. Also known as Errors and Omissions (E&O).`,
            ],
        ]),
    ],
]);

const coverageSliderDescriptionMap = new Map<AppTypeCode, string>([
    [
        'AppTypeCodeListESP',
        `The all-in-one package custom designed to protect Startups. From executive decisions, and product errors, to hackers stealing PII, workplace harassment, and discrimination lawsuits.`,
    ],
    [
        'AppTypeCodeListTechEO',
        `Protects business against claims due to a failure of your technology or service. It covers the costs of lawsuits and more.`,
    ],
    [
        'AppTypeCodeListEmbrokerExcess',
        `Embroker's Excess Tech E&O/Cyber helps you get additional limits for your business. This policy provides an added layer of coverage that kicks in when the limits on your underlying policy or policies have been exhausted.`,
    ],
    [
        'AppTypeCodeListPCoML',
        `A one-two punch of insurance happiness. Your executives want their assets protected, and your company needs protection from alleged sexual harassment or discrimination suits.`,
    ],
    [
        'AppTypeCodeListManualAuto',
        `Protects business vehicles both owned and rented and will cover repair and replacement costs related to accidents, vandalism, theft, weather damage, and more.`,
    ],
    [
        'AppTypeCodeListManualCyber',
        `A modern business must-have, Cyber covers first and third party claims resulting from security/data breaches and cybercrimes like ransomware, phishing, and more.`,
    ],
    [
        'AppTypeCodeListEmbrokerCyber',
        `A modern business must-have, Cyber covers first and third party claims resulting from security/data breaches and cybercrimes like ransomware, phishing, and more.`,
    ],
    [
        'AppTypeCodeListCyberCowbell',
        `A modern business must-have, Cyber covers first and third party claims resulting from security/data breaches and cybercrimes like ransomware, phishing, and more.`,
    ],
    [
        'AppTypeCodeListManualDirectorsAndOfficers',
        `Allows executives to lead their companies fearlessly by protecting their personal assets from lawsuits alleging misuse of funds, breaches of fiduciary duty, and more.`,
    ],
    [
        'AppTypeCodeListManualEmploymentPractices',
        `Protects companies from employee lawsuits related to a variety of common workplace issues, such as harassment, discrimination, failure to promote, and more.`,
    ],
    [
        'AppTypeCodeListManualTravel',
        `International package is the best way to protect your business from travel-related risks from employee health and safety incidents, to stolen property, to lost revenues caused by travel delays.`,
    ],
    [
        'AppTypeCodeListManualConstructionGL',
        `Embroker helps you get the right general liability insurance to protect your business against claims of property damage and bodily injury, covering your legal costs, associated medical costs and eventual settlements.`,
    ],
    [
        'AppTypeCodeListManualGL',
        `Essential coverage that protects businesses from third-party injuries and property damage claims. Covers claims of stolen ideas related to advertising as well.`,
    ],
    [
        'AppTypeCodeListManualCannabis',
        `Embroker helps you get the right general liability insurance to protect your business against claims of property damage and bodily injury, covering your legal costs, associated medical costs and eventual settlements.`,
    ],
    [
        'AppTypeCodeListManualAccountantsPL',
        'One mistake, or misplaced piece of advice and your clients may sue. Protect against claims of negligence or mistakes that cause financial harm to one of your clients.',
    ],
    [
        'AppTypeCodeListManualConstructionPL',
        'Protects professional services providers (real estate agents, consultants, brokers, etc.) from civil lawsuits related to negligence, errors and omissions, and more.',
    ],
    [
        'AppTypeCodeListManualTechEO',
        "How confident are you that your engineers didn't make any mistakes? Protects against claims that allege damages arising from technology services you have provided.",
    ],
    [
        'AppTypeCodeListManualDefaultPL',
        'Protects professional services providers (real estate agents, consultants, brokers, etc.) from civil lawsuits related to negligence, errors and omissions, and more.',
    ],
    [
        'AppTypeCodeListEverestLawyersProfessionalLiability',
        `Provides customized protection for lawyers against the many types of malpractice claims that can arise while providing professional services to clients and partners.`,
    ],
    [
        'AppTypeCodeListManualProductLiability',
        'Protects businesses from claims related to product defects and shortcomings that could potentially lead to customer injuries or property damage.',
    ],
    [
        'AppTypeCodeListManualProperty',
        `Provides financial help when business property has been lost or damaged. This policy covers not just buildings but also furniture, signage, inventory, and more.`,
    ],
    [
        'AppTypeCodeListManualWorkersCompensation',
        `Subject to state law, WC covers employee injuries/disease occurring the course/scope of employment. Employers Liability responds to claims alleging employer negligence.`,
    ],
    [
        'AppTypeCodeListGAWorkersCompensation',
        `Subject to state law, WC covers employee injuries/disease occurring the course/scope of employment. Employers Liability responds to claims alleging employer negligence.`,
    ],
    [
        'AppTypeCodeListWCChubb',
        `Subject to state law, WC covers employee injuries/disease occurring the course/scope of employment. Employers Liability responds to claims alleging employer negligence.`,
    ],
    [
        'AppTypeCodeListManualVentureCapitalAssetProtection',
        `You want efficiency and value in every investment. This all-in-one package is custom-designed to cover all your most significant liabilities.`,
    ],
    [
        'AppTypeCodeListManualHomeownersAssociation',
        `Provides protection for HOA members collectively for liability and/or property losses that they may be responsible for. Can be customized to include D&O, Crime and Umbrella.`,
    ],
    [
        'AppTypeCodeListCNABOP',
        `Get peace of mind with a single, cost-effective policy. A BOP combines multiple coverages to protect your business, your office and its contents.`,
    ],
    [
        'AppTypeCodeListBOPChubb',
        `Get peace of mind with a single, cost-effective policy. A BOP combines multiple coverages to protect your business, your office and its contents.`,
    ],
    [
        'AppTypeCodeListHartfordBOP',
        `(HartFord) Get peace of mind with a single, cost-effective policy. A BOP combines multiple coverages to protect your business, your office and its contents.`,
    ],
    [
        'AppTypeCodeListManualUmbrella',
        `Provides businesses with an extra layer of coverage that kicks in when the coverage limits of other liability policies have been reached and exhausted.`,
    ],
    [
        'AppTypeCodeListEmbrokerCrime',
        `Protects businesses from financial loss related to crimes such as employee theft and dishonesty, embezzlement, forgery, fraud, robbery, and more.`,
    ],
    [
        'AppTypeCodeListManualCrime',
        `Protects businesses from financial loss related to crimes such as employee theft and dishonesty, embezzlement, forgery, fraud, robbery, and more.`,
    ],
    [
        'AppTypeCodeListManualFiduciary',
        `Provides protection for the people administering company benefits plans from current and former employees alleging mismanagement or misleading advice.`,
    ],
    [
        'AppTypeCodeListLawBundle',
        `Combining essential coverages for law firms in a single application, this program is designed to protect you against your unique areas of business risk.`,
    ],
]);

const whoNeedsCoverageDescriptionMap = new Map<AppTypeCode, Nullable<string>>([
    [
        'AppTypeCodeListESP',
        `The Startup Package is for for companies that have raised venture funding. Without this type of protection, starting a company, or serving on its board, would entail significantly more personal financial risk than it already does.`,
    ],
    [
        'AppTypeCodeListTechEO',
        `Any company that offers any type of technology service or product should purchase this coverage. Whether you're offering consulting services to technology companies, creating and selling software as a service (SaaS) products, providing cloud storage for third parties, or even building websites for clients, it would be wise to purchase tech errors & omissions coverage for your company.`,
    ],
    ['AppTypeCodeListEmbrokerExcess', null],
    [
        'AppTypeCodeListPCoML',
        `The Business Management package is for privately-held companies. No matter how big or small your company is, your company and its leaders can be affected by litigation, and your executives and board or directors are particularly exposed without the proper protection.`,
    ],
    [
        'AppTypeCodeListManualAuto',
        `A majority of U.S. states make it mandatory for business owners to purchase liability insurance for both property damage and bodily injury that could occur as a result of one of your vehicles getting into an accident while you or one of your employees is operating it. You can also get physical damage coverage for all of the vehicles your business uses, whether you own, lease or hire the vehicles.`,
    ],
    [
        'AppTypeCodeListManualCyber',
        `If your business stores personally identifiable information (PII) such as names, addresses, credit card information, Social Security numbers, etc. on any type of computer system, then there is a regulatory obligation to keep that data secure. Data breaches and other cyber attacks can result in significant costs for recovery and legal claims making Cyber insurance a necessity for any business with this kind of exposure.`,
    ],
    [
        'AppTypeCodeListEmbrokerCyber',
        `If your business stores personally identifiable information (PII) such as names, addresses, credit card information, Social Security numbers, etc. on any type of computer system, then there is a regulatory obligation to keep that data secure. Data breaches and other cyber attacks can result in significant costs for recovery and legal claims making Cyber insurance a necessity for any business with this kind of exposure.`,
    ],
    [
        'AppTypeCodeListCyberCowbell',
        `If your business stores personally identifiable information (PII) such as names, addresses, credit card information, Social Security numbers, etc. on any type of computer system, then there is a regulatory obligation to keep that data secure. Data breaches and other cyber attacks can result in significant costs for recovery and legal claims making Cyber insurance a necessity for any business with this kind of exposure.`,
    ],
    [
        'AppTypeCodeListManualDirectorsAndOfficers',
        `Any private or public company that has a board of directors should have D&O insurance. Some of the exposures that directors and officers are most vulnerable to include regulatory actions, misrepresentation allegations, securities litigation and breaches of fiduciary duties.`,
    ],
    [
        'AppTypeCodeListManualEmploymentPractices',
        `EPLI is recommended for any company that has employees. As soon as your business starts hiring, you need to start thinking about EPLI insurance. Employee lawsuits are on the rise, and even if you’re in the right, the legal costs can still be staggering.`,
    ],
    [
        'AppTypeCodeListManualTravel',
        `While International Package insurance is not an absolute must-have for every business, it should be strongly considered by businesses and organizations that have their employees traveling frequently abroad. This is the best way to mitigate risks due to travel and prevent potential losses.`,
    ],
    [
        'AppTypeCodeListManualConstructionGL',
        `If you are a business owner who deals with and employs other people in some way, you need commercial General Liability insurance. Even if you think you’re unlikely to face a lawsuit, insurance is always a wise (and fairly affordable) investment. Few people regret buying insurance but many people regret not buying it.`,
    ],
    [
        'AppTypeCodeListManualGL',
        `If you are a business owner who deals with and employs other people in some way, you need commercial General Liability insurance. Even if you think you’re unlikely to face a lawsuit, insurance is always a wise (and fairly affordable) investment. Few people regret buying insurance but many people regret not buying it.`,
    ],
    [
        'AppTypeCodeListManualCannabis',
        `If you are a business owner who deals with and employs other people in some way, you need commercial General Liability insurance. Even if you think you’re unlikely to face a lawsuit, insurance is always a wise (and fairly affordable) investment. Few people regret buying insurance but many people regret not buying it.`,
    ],
    [
        'AppTypeCodeListManualAccountantsPL',
        `Accounting service businesses need Professional Liability coverage. The complex, multi-faceted world that is accounting makes occasional mistakes inevitable. This coverage protects you when it's a mistake a customer sues over.`,
    ],
    [
        'AppTypeCodeListManualConstructionPL',
        `Professional Liability / Errors and Omissions insurance is particularly useful for any business that offers professional services, such as lawyers, accountants, consultants, investment advisors, real estate agents, architects, medical experts, engineers, tech companies, and others. No matter how good you are at doing your job and how long you have been doing it, you are bound to make a mistake every now and then or have a disgruntled client. E&O coverage protects you in the case that you do make a mistake that a customer decides to sue you over.`,
    ],
    [
        'AppTypeCodeListManualTechEO',
        `As the technology sector continues to grow, businesses categorized as software as a service (SaaS), web development, payment processing, and anything else handling sensitive data will increasingly require a technology E&O policy. It is also likely becoming more common that your customers and partners will require your business to have it.`,
    ],
    [
        'AppTypeCodeListManualDefaultPL',
        `Professional Liability / Errors and Omissions insurance is particularly useful for any business that offers professional services, such as lawyers, accountants, consultants, investment advisors, real estate agents, architects, medical experts, engineers, tech companies, and others. No matter how good you are at doing your job and how long you have been doing it, you are bound to make a mistake every now and then or have a disgruntled client. E&O coverage protects you in the case that you do make a mistake that a customer decides to sue you over.`,
    ],
    [
        'AppTypeCodeListEverestLawyersProfessionalLiability',
        `Given how commonplace legal malpractice lawsuits have become, having legal professional liability insurance is a wise investment for any law firm.`,
    ],
    [
        'AppTypeCodeListManualProductLiability',
        `Product liability insurance covers claims related to product defects. If your company manufactures or sells a product that has a defect aIf you are manufacturing products, you should consider product liability insurance. Manufacturers are more frequently the party held liable in product liability claims. Even if your products are an input to another product, your business may still be involved in a claim.`,
    ],
    [
        'AppTypeCodeListManualProperty',
        `Larger businesses, with a more complex set of offices, equipment, stores, or other assets, are the usual buyers of commercial property coverage. Even if you just rent, it's still important to cover your valuable equipment. The policy is tailored to best match the risks of your operations.`,
    ],
    [
        'AppTypeCodeListManualWorkersCompensation',
        `If you have employees, it's likely you are legally required to have Workers Compensation insurance. Every state requires it: some even for one employee, some when you get to two or three. Contracts with other companies often also require it. You may also get it when self-employed.`,
    ],
    [
        'AppTypeCodeListGAWorkersCompensation',
        `If you have employees, it's likely you are legally required to have Workers Compensation insurance. Every state requires it: some even for one employee, some when you get to two or three. Contracts with other companies often also require it. You may also get it when self-employed.`,
    ],
    [
        'AppTypeCodeListWCChubb',
        `If you have employees, it's likely you are legally required to have Workers Compensation insurance. Every state requires it: some even for one employee, some when you get to two or three. Contracts with other companies often also require it. You may also get it when self-employed.`,
    ],
    [
        'AppTypeCodeListManualVentureCapitalAssetProtection',
        `This coverage was custom made for venture investment companies - such as VCs, private equity, and hedge funds. It is then further tailored towards your specific operational risks.`,
    ],
    [
        'AppTypeCodeListManualHomeownersAssociation',
        `Every HOA needs some form of HOA insurance, including General Liability and Directors & Officers. Many HOAs also require Property and Crime coverage. You will need to consult your association by-laws to determine precisely what coverages you need. Embroker's expert advisors can assist you with determining the best combination of coverages for your unique situation.`,
    ],
    [
        'AppTypeCodeListCNABOP',
        `Business Owners Policies are designed to make it easy for small and mid-sized companies to get the basic coverages for property and liability risks that most businesses require. Regardless of whether you own an tech company, auto repair shop, a retail store or many other types of small business, a Business Owners Policy (BOP) can be tailored to your needs. Larger businesses and those with more complex risks frequently choose to purchase specific coverages as separate policies rather than bundled together in a BOP. Be aware that certain coverages are typically not included in a BOP and must be purchased separately, like Workers Compensation, Professional Liability, Disability and others.`,
    ],
    [
        'AppTypeCodeListHartfordBOP',
        `Business Owners Policies are designed to make it easy for small and mid-sized companies to get the basic coverages for property and liability risks that most businesses require. Regardless of whether you own an tech company, auto repair shop, a retail store or many other types of small business, a Business Owners Policy (BOP) can be tailored to your needs. Larger businesses and those with more complex risks frequently choose to purchase specific coverages as separate policies rather than bundled together in a BOP. Be aware that certain coverages are typically not included in a BOP and must be purchased separately, like Workers Compensation, Professional Liability, Disability and others.`,
    ],
    [
        'AppTypeCodeListBOPChubb',
        `Business Owners Policies are designed to make it easy for small and mid-sized companies to get the basic coverages for property and liability risks that most businesses require. Regardless of whether you own an tech company, auto repair shop, a retail store or many other types of small business, a Business Owners Policy (BOP) can be tailored to your needs. Larger businesses and those with more complex risks frequently choose to purchase specific coverages as separate policies rather than bundled together in a BOP. Be aware that certain coverages are typically not included in a BOP and must be purchased separately, like Workers Compensation, Professional Liability, Disability and others.`,
    ],
    [
        'AppTypeCodeListManualUmbrella',
        `Any business that is potentially at risk of exceeding the limits of their existing liability policies should consider commercial umbrella insurance. Businesses that have commercial property that is open to the public should be considering this type of insurance given this can expose them to an increased amount of risk.`,
    ],
    [
        'AppTypeCodeListEmbrokerCrime',
        `Businesses that hire part-time employees, engage in cash transactions, manage inventory, or have expensive office equipment are more exposed to petty theft. We advise getting Commercial Crime insurance because it's a simple risk transfer mechanism for theft and manipulation.

         However, it does not cover losses due to data breaches or social engineering, which fall under Cyber Liability.`,
    ],
    [
        'AppTypeCodeListManualCrime',
        `Businesses that hire part-time employees, engage in cash transactions, manage inventory, or have expensive office equipment are more exposed to petty theft. We advise getting Commercial Crime insurance because it's a simple risk transfer mechanism for theft and manipulation.

         However, it does not cover losses due to data breaches or social engineering, which fall under Cyber Liability.`,
    ],
    [
        'AppTypeCodeListManualFiduciary',
        `If your business is a very small one that doesn’t have benefits packages to offer employees, then you probably do not need a fiduciary policy. However, as soon as you start providing any type of employee benefits, you will want to look into fiduciary liability insurance.`,
    ],
]);

const lawWhoNeedsDescriptionMap = new Map<AppTypeCode, Map<Vertical, string>>([
    ['AppTypeCodeListBOPChubb', new Map([['LawFirm', '']])],
    ['AppTypeCodeListCyberCowbell', new Map([['LawFirm', '']])],
    ['AppTypeCodeListWCChubb', new Map([['LawFirm', '']])],
    ['AppTypeCodeListEverestLawyersProfessionalLiability', new Map([['LawFirm', '']])],
]);

const whatIsCoverageDescriptionMap = new Map<AppTypeCode, string>([
    [
        'AppTypeCodeListESP',
        `Embroker's Startup Package combines Directors and Officers (D&O), Employment Practices Liability (EPL), Fiduciary, and Technology Errors and Omissions / Cyber Liability insurance in one package. The policy covers claims of breach of fiduciary duty, claims made by employees against the company, and damages arising from technology services provided.`,
    ],
    [
        'AppTypeCodeListTechEO',
        `Technology Errors and Omissions coverage (also known as Professional Liability Insurance) will protect your business if you’re sued for damages that your services/technology may have caused. It covers the legal costs that can accumulate if a client decides to sue you for causing damages due to errors, omissions, or negligent acts, usually financial.`,
    ],
    [
        'AppTypeCodeListEmbrokerExcess',
        `Technology professional liability and cyber coverage over and above an existing ("underlying") policy or policies. Embroker's all-new Excess Tech E&O/Cyber follows the form of your underlying coverage, and provides increased limits over that insurance program.`,
    ],
    [
        'AppTypeCodeListPCoML',
        `Embroker's Business Management insurance combines Directors and Officers (D&O), Employment Practices Liability (EPL) insurance in one package. The policy covers claims of breach of fiduciary duty and claims made by employees against the company. `,
    ],
    [
        'AppTypeCodeListManualAuto',
        `A Commercial Auto policy protects your company against financial responsibility if you or an employee is at fault in an accident involving injury to people or property resulting from theft, vandalism, certain weather events, and collisions.`,
    ],
    [
        'AppTypeCodeListManualCyber',
        `A Cyber Liability / Data Breach insurance policy is a financial product that enables businesses to transfer the costs involved with recovery from a cyber-related security breach or similar events. The policy can also provide access to a panel of top-tier breach coaches and other service providers.`,
    ],
    [
        'AppTypeCodeListEmbrokerCyber',
        `A Cyber Liability / Data Breach insurance policy is a financial product that enables businesses to transfer the costs involved with recovery from a cyber-related security breach or similar events. The policy can also provide access to a panel of top-tier breach coaches and other service providers.`,
    ],
    [
        'AppTypeCodeListCyberCowbell',
        `A Cyber Liability / Data Breach insurance policy is a financial product that enables businesses to transfer the costs involved with recovery from a cyber-related security breach or similar events. The policy can also provide access to a panel of top-tier breach coaches and other service providers.`,
    ],
    [
        'AppTypeCodeListManualDirectorsAndOfficers',
        `Directors and Officers liability insurance (D&O) is liability insurance that covers the directors and officers of the company against lawsuits alleging a breach of fiduciary duty. The policy will reimburse settlements or defense costs that result from covered claims.`,
    ],
    [
        'AppTypeCodeListManualEmploymentPractices',
        `Employment practices liability insurance (EPLI) covers businesses against claims made by employees against employers regarding the violation of their legal rights. The coverage applies to claims that arise under Title VII of the Civil Rights Act or any related legislation.`,
    ],
    [
        'AppTypeCodeListManualTravel',
        `In this post-COVID-19 world, even if you haven't purchased travel insurance before, you might want to consider it now. Embroker International Package includes trip cancellation or interruption coverage, baggage and personal effects coverage, medical expense coverage, and accidental death or flight accident coverage.`,
    ],
    [
        'AppTypeCodeListManualConstructionGL',
        `A commercial General Liability insurance policy provides indemnity against the risks that nearly all business owners face. It protects your business assets from any claims of injury related to your business and responds to claims of negligence made by a third party.`,
    ],
    [
        'AppTypeCodeListManualGL',
        `A commercial General Liability insurance policy provides indemnity against the risks that nearly all business owners face. It protects your business assets from any claims of injury related to your business and responds to claims of negligence made by a third party.`,
    ],
    [
        'AppTypeCodeListManualCannabis',
        `A commercial General Liability insurance policy provides indemnity against the risks that nearly all business owners face. It protects your business assets from any claims of injury related to your business and responds to claims of negligence made by a third party.`,
    ],
    [
        'AppTypeCodeListManualAccountantsPL',
        `Professional Liability insurance, sometimes called Errors & Omissions insurance (E&O), protects your business from mistakes in the accounting services you provide your customers. The policy can cover errors, omissions, negligence, and more. It does not cover fraud, intentional acts, and criminal acts.`,
    ],
    [
        'AppTypeCodeListManualConstructionPL',
        `Professional Liability insurance, also commonly referred to as Errors & Omissions insurance (E&O) or Malpractice insurance, is a type of insurance policy that can protect your business from claims related to malpractice, errors, omissions, negligence, and more during the course of providing a professional service.`,
    ],
    [
        'AppTypeCodeListManualTechEO',
        `Technology Errors and Omissions (E&O) insurance protects against claims that allege damages arising from technology services you have provided, errors, omissions, negligence, and more during the course of providing a professional service.`,
    ],
    [
        'AppTypeCodeListManualDefaultPL',
        `Professional Liability insurance, also commonly referred to as Errors & Omissions insurance (E&O) or Malpractice insurance, is a type of insurance policy that can protect your business from claims related to malpractice, errors, omissions, negligence, and more during the course of providing a professional service.`,
    ],
    [
        'AppTypeCodeListEverestLawyersProfessionalLiability',
        `Lawyers Professional Liability insurance is typically the most important policy a lawyer or law firm can purchase. Legal malpractice claims happen when an attorney is alleged to have failed to perform their duties according to proscribed standards of professional and ethical conduct.`,
    ],
    [
        'AppTypeCodeListManualProductLiability',
        `Product Liability insurance is in some ways similar to General Liability insurance, but it is a unique form of insurance that is specifically designed for businesses that manufacture and sell products. The product may be any type of good sold to consumers or businesses.`,
    ],
    [
        'AppTypeCodeListManualProperty',
        `Property insurance, known as "commercial property," can cover many things. At its simplest, the damage or loss of a building is covered. But, it can also include assets like your equipment or supplies. It can even protect you from lost income while the property is "out of order."`,
    ],
    [
        'AppTypeCodeListManualWorkersCompensation',
        `Workers Compensation insurance protects businesses and employees by providing wage replacement and medical benefits to employees who become injured or disabled on the job or as a result of their employment.`,
    ],
    [
        'AppTypeCodeListGAWorkersCompensation',
        `Workers Compensation insurance protects businesses and employees by providing wage replacement and medical benefits to employees who become injured or disabled on the job or as a result of their employment.`,
    ],
    [
        'AppTypeCodeListWCChubb',
        `Workers Compensation insurance protects businesses and employees by providing wage replacement and medical benefits to employees who become injured or disabled on the job or as a result of their employment.`,
    ],
    [
        'AppTypeCodeListManualVentureCapitalAssetProtection',
        `The Venture Capital Asset Protection (VCAP) policy protects against liabilities arising from the management of funds, directorship liability from portfolio company board seats, and liability from employment regulation.`,
    ],
    [
        'AppTypeCodeListManualHomeownersAssociation',
        `HOA insurance can be a holistic and comprehensive package of coverages designed to indemnify the HOA for its most common risks and liabilities. It can be customized to include coverage for the board of directors, injuries incurred on property, theft of association assets, and much more.`,
    ],
    [
        'AppTypeCodeListCNABOP',
        `A Business Owners Policy (BOP) is essentially a package of insurance policies that a majority of small and mid-sized business owners need to have, which is why insurance carriers bundle these coverages and sell them as one product.
        In a typical BOP, businesses would buy the following policies as one bundle or insurance package.`,
    ],
    [
        'AppTypeCodeListHartfordBOP',
        `A Business Owners Policy (BOP) is essentially a package of insurance policies that a majority of small and mid-sized business owners need to have, which is why insurance carriers bundle these coverages and sell them as one product.
        In a typical BOP, businesses would buy the following policies as one bundle or insurance package.`,
    ],
    [
        'AppTypeCodeListBOPChubb',
        `A Business Owners Policy (BOP) is essentially a package of insurance policies that a majority of small and mid-sized business owners need to have, which is why insurance carriers bundle these coverages and sell them as one product.
        In a typical BOP, businesses would buy the following policies as one bundle or insurance package.`,
    ],
    [
        'AppTypeCodeListManualUmbrella',
        `Commercial Umbrella insurance is a type of coverage that is designed to sit on top of other insurance policies your business holds, hence the name. Should the underlying insurance policy limits become exhausted, the umbrella kicks in as additional protection.`,
    ],
    [
        'AppTypeCodeListEmbrokerCrime',
        `Commercial Crime can protect your company from losses related to business-crime committed by employees or third parties. It provides coverage for loss of money, property, or other assets due to theft or manipulation.`,
    ],
    [
        'AppTypeCodeListManualCrime',
        `Commercial Crime can protect your company from losses related to business-crime committed by employees or third parties. It provides coverage for loss of money, property, or other assets due to theft or manipulation.`,
    ],
    [
        'AppTypeCodeListManualFiduciary',
        `Fiduciary liability insurance is designed to protect the business from claims of mismanagement and the legal liability arising out of their role as fiduciaries. A fiduciary liability policy covers associated legal costs to defend against claims of errors and a breach of fiduciary duty.`,
    ],
    [
        'AppTypeCodeListMPLBundle',
        `Professional Liability insurance, also commonly referred to as Errors and Omissions (E&O) insurance or Malpractice insurance, is a type of insurance policy that can protect your business from claims related to malpractice, errors, omissions, negligence, and more during the course of providing your professional service.`,
    ],
    [
        'AppTypeCodeListMPL',
        `Professional Liability insurance, also commonly referred to as Errors and Omissions (E&O) insurance or Malpractice insurance, is a type of insurance policy that can protect your business from claims related to malpractice, errors, omissions, negligence, and more during the course of providing your professional service.`,
    ],
]);

const coveragesIncludedMap = new Map<AppTypeCode, string[]>([
    [
        'AppTypeCodeListESP',
        ['Breach of contract', 'Misuses of company funds', 'Harassment and discrimination'],
    ],
    ['AppTypeCodeListTechEO', ['Contractual Liability', 'Professional Negligence']],
    [
        'AppTypeCodeListPCoML',
        ['Breach of contract', 'Misuses of company funds', 'Harassment and discrimination'],
    ],
    ['AppTypeCodeListEmbrokerExcess', []],
    [
        'AppTypeCodeListManualAuto',
        [
            'Property damage liability',
            'Bodily injury liability',
            'Personal injury protection',
            'Medical payments',
            'Collision coverage',
            'Gap insurance',
        ],
    ],
    ['AppTypeCodeListManualCyber', ['Civil lawsuits', 'Notification costs', 'Computer forensics']],
    [
        'AppTypeCodeListEmbrokerCyber',
        ['Civil lawsuits', 'Notification costs', 'Computer forensics'],
    ],
    ['AppTypeCodeListCyberCowbell', ['Civil lawsuits', 'Notification costs', 'Computer forensics']],
    [
        'AppTypeCodeListManualDirectorsAndOfficers',
        [
            'Misuses of company funds',
            'Misrepresentations of company assets',
            'Breach of fiduciary duty',
        ],
    ],
    [
        'AppTypeCodeListManualEmploymentPractices',
        ['Harassment and discrimination', 'Failure to promote', 'Wrongful termination'],
    ],
    ['AppTypeCodeListManualTravel', []],
    [
        'AppTypeCodeListManualConstructionGL',
        ['Property damage', 'Operational costs', 'Personal injury', 'Legal expenses'],
    ],
    [
        'AppTypeCodeListManualGL',
        ['Property damage', 'Operational costs', 'Personal injury', 'Legal expenses'],
    ],
    [
        'AppTypeCodeListManualCannabis',
        ['Property damage', 'Operational costs', 'Personal injury', 'Legal expenses'],
    ],
    ['AppTypeCodeListManualAccountantsPL', []],
    ['AppTypeCodeListManualConstructionPL', []],
    ['AppTypeCodeListManualTechEO', []],
    ['AppTypeCodeListManualDefaultPL', []],
    [
        'AppTypeCodeListEverestLawyersProfessionalLiability',
        [
            'Alleged errors and omissions',
            'Legal defense costs associated with malpractice lawsuits',
            'Damages and settlements',
        ],
    ],
    [
        'AppTypeCodeListManualProductLiability',
        ['Design defects', 'Manufacturing defects', 'Warning or use defects', 'Strict liability'],
    ],
    [
        'AppTypeCodeListManualProperty',
        ['Property damage', 'Equipment damage or loss', 'Lost income'],
    ],
    [
        'AppTypeCodeListManualWorkersCompensation',
        ['Injuries or deaths', 'Medical expenses', 'Missed wages'],
    ],
    [
        'AppTypeCodeListGAWorkersCompensation',
        ['Injuries or deaths', 'Medical expenses', 'Missed wages'],
    ],
    ['AppTypeCodeListWCChubb', ['Injuries or deaths', 'Medical expenses', 'Missed wages']],
    [
        'AppTypeCodeListManualVentureCapitalAssetProtection',
        ['Management liability', 'Claims of harassment or discrimination', 'Fiduciary liability'],
    ],
    [
        'AppTypeCodeListManualHomeownersAssociation',
        ['Personal injury', 'Property damage', 'Theft', 'Breach of fiduciary duty'],
    ],
    [
        'AppTypeCodeListCNABOP',
        [
            'Commercial Property Insurance',
            'General Liability Insurance',
            'Business Interruption Insurance',
        ],
    ],
    [
        'AppTypeCodeListHartfordBOP',
        [
            'Commercial Property Insurance',
            'General Liability Insurance',
            'Business Interruption Insurance',
        ],
    ],
    [
        'AppTypeCodeListBOPChubb',
        [
            'Commercial Property Insurance',
            'General Liability Insurance',
            'Business Interruption Insurance',
        ],
    ],
    ['AppTypeCodeListManualUmbrella', []],
    [
        'AppTypeCodeListEmbrokerCrime',
        [
            'Theft',
            'Fraud',
            'Forgery',
            'Employees stealing from clients',
            'Theft from Benefit Plans, like a 401K',
        ],
    ],
    [
        'AppTypeCodeListManualCrime',
        [
            'Theft',
            'Fraud',
            'Forgery',
            'Employees stealing from clients',
            'Theft from Benefit Plans, like a 401K',
        ],
    ],
    [
        'AppTypeCodeListManualFiduciary',
        [
            'Wrongful denial of benefits',
            'Improper advice or counsel',
            'Errors in plan administration',
        ],
    ],
]);

const lawCoveragesMap = new Map<AppTypeCode, Map<Vertical, string[]>>([
    [
        'AppTypeCodeListBOPChubb',
        new Map([
            [
                'LawFirm',
                [
                    'Legal defense, settlements, medical and other expenses resulting from third-party bodily injury cases',
                    'Theft or damage of your property by a third-party',
                    'Financial losses in case of business interruption',
                    'Hired/Non-Owned Auto Coverage',
                ],
            ],
        ]),
    ],
    [
        'AppTypeCodeListCyberCowbell',
        new Map([
            [
                'LawFirm',
                [
                    'Phishing: Scammers using fake emails/messages/websites to try to trick you into giving up your personal info.',
                    'Network/data breaches and privacy liability claims',
                    'Extortion and ransomware events',
                    'Loss of business income',
                ],
            ],
        ]),
    ],
    [
        'AppTypeCodeListWCChubb',
        new Map([
            [
                'LawFirm',
                [
                    'Hospital bills and medication',
                    'Physical therapy',
                    'Lost wages',
                    'Death benefits',
                    'Negligence claims by injured employees',
                ],
            ],
        ]),
    ],
    [
        'AppTypeCodeListEverestLawyersProfessionalLiability',
        new Map([
            [
                'LawFirm',
                [
                    'Negligence or errors',
                    'Actual or alleged misrepresentation',
                    'Conflicts of interest or breach of duty',
                    'Rendering or failing to render professional services',
                ],
            ],
        ]),
    ],
]);

const lawTitleCoveragesMap = new Map<AppTypeCode, Map<Vertical, string>>([
    ['AppTypeCodeListBOPChubb', new Map([['LawFirm', 'common BOP coverages INCLUDE:']])],
    ['AppTypeCodeListCyberCowbell', new Map([['LawFirm', 'COMMON CYBER COVERAGES INCLUDE:']])],
    [
        'AppTypeCodeListWCChubb',
        new Map([['LawFirm', 'common WORKERS COMPENSATION coverages Include:']]),
    ],
    [
        'AppTypeCodeListEverestLawyersProfessionalLiability',
        new Map([['LawFirm', 'LPL Commonly includes coverage for claims of:']]),
    ],
]);

const lawNonIncludedCoveragesMap = new Map<AppTypeCode, Map<Vertical, string[]>>([
    [
        'AppTypeCodeListEverestLawyersProfessionalLiability',
        new Map([
            [
                'LawFirm',
                [
                    'Phishing: Scammers using fake emails/messages/websites to try to trick you into giving up your personal info.',
                ],
            ],
        ]),
    ],
]);

const coveragesEstimateTimeMap = new Map<AppTypeCode, string>([
    ['AppTypeCodeListESP', '5'],
    ['AppTypeCodeListTechEO', '5'],
    ['AppTypeCodeListPCoML', '4-6'],
    ['AppTypeCodeListCNABOP', '2-5'],
    ['AppTypeCodeListHartfordBOP', '2-5'],
    ['AppTypeCodeListBOPChubb', '2-5'],
    ['AppTypeCodeListManualAuto', '5-10'],
    ['AppTypeCodeListEmbrokerCrime', '4-6'],
    ['AppTypeCodeListManualCrime', '8-10'],
    ['AppTypeCodeListManualCyber', '10'],
    ['AppTypeCodeListEmbrokerCyber', '10'],
    ['AppTypeCodeListCyberCowbell', '10'],
    ['AppTypeCodeListManualDirectorsAndOfficers', '8-10'],
    ['AppTypeCodeListManualEmploymentPractices', '8-10'],
    ['AppTypeCodeListManualFiduciary', '3-5'],
    ['AppTypeCodeListManualConstructionGL', '3-5'],
    ['AppTypeCodeListManualGL', '3-5'],
    ['AppTypeCodeListManualCannabis', '3-5'],
    ['AppTypeCodeListManualHomeownersAssociation', '5-15'],
    ['AppTypeCodeListManualTravel', '5-7'],
    ['AppTypeCodeListManualAccountantsPL', '12-15'],
    ['AppTypeCodeListManualConstructionPL', '12-15'],
    ['AppTypeCodeListManualTechEO', '12-15'],
    ['AppTypeCodeListManualDefaultPL', '12-15'],
    ['AppTypeCodeListEverestLawyersProfessionalLiability', '10'],
    ['AppTypeCodeListManualProductLiability', '6-8'],
    ['AppTypeCodeListManualProperty', '20'],
    ['AppTypeCodeListManualUmbrella', '1-2'],
    ['AppTypeCodeListManualWorkersCompensation', '5'],
    ['AppTypeCodeListGAWorkersCompensation', '5'],
    ['AppTypeCodeListWCChubb', '5'],
    ['AppTypeCodeListManualVentureCapitalAssetProtection', '20'],
    ['AppTypeCodeListLawBundle', '8-10'],
]);

const coverageIconMap = new Map<AppTypeCode, IconName>([
    ['AppTypeCodeListESP', 'rocket'],
    ['AppTypeCodeListTechEO', 'thief'],
    ['AppTypeCodeListEmbrokerExcess', 'excess'],
    ['AppTypeCodeListPCoML', 'business-management-insurance'],
    ['AppTypeCodeListManualAuto', 'car'],
    ['AppTypeCodeListManualCyber', 'thief'],
    ['AppTypeCodeListEmbrokerCyber', 'thief'],
    ['AppTypeCodeListCyberCowbell', 'thief'],
    ['AppTypeCodeListManualDirectorsAndOfficers', 'meeting'],
    ['AppTypeCodeListManualEmploymentPractices', 'suitcase'],
    ['AppTypeCodeListManualTravel', 'plane'],
    ['AppTypeCodeListManualConstructionGL', 'shield-in-circle'],
    ['AppTypeCodeListManualGL', 'shield-in-circle'],
    ['AppTypeCodeListManualCannabis', 'shield-in-circle'],
    ['AppTypeCodeListManualAccountantsPL', 'pen'],
    ['AppTypeCodeListManualConstructionPL', 'pen'],
    ['AppTypeCodeListManualTechEO', 'pen'],
    ['AppTypeCodeListManualDefaultPL', 'pen'],
    ['AppTypeCodeListEverestLawyersProfessionalLiability', 'law'],
    ['AppTypeCodeListManualLawyersProfessionalLiability', 'law'],
    ['AppTypeCodeListManualProductLiability', 'announcement'],
    ['AppTypeCodeListManualProperty', 'building'],
    ['AppTypeCodeListManualWorkersCompensation', 'worker'],
    ['AppTypeCodeListGAWorkersCompensation', 'worker'],
    ['AppTypeCodeListWCChubb', 'worker'],
    ['AppTypeCodeListManualVentureCapitalAssetProtection', 'chart'],
    ['AppTypeCodeListCNABOP', 'user-shield'],
    ['AppTypeCodeListHartfordBOP', 'user-shield'],
    ['AppTypeCodeListBOPChubb', 'user-shield'],
    ['AppTypeCodeListManualUmbrella', 'umbrella'],
    ['AppTypeCodeListEmbrokerCrime', 'cage'],
    ['AppTypeCodeListManualCrime', 'cage'],
    ['AppTypeCodeListManualFiduciary', 'handshake'],
    ['AppTypeCodeListManualHomeownersAssociation', 'houses'],
    ['AppTypeCodeListLawBundle', 'law-bundle'],
]);

export const coverageProgramIconMap = new Map<AppTypeCode, Map<Vertical, IconName>>([
    [
        'AppTypeCodeListMPLBundle',
        new Map([
            ['Accounting', 'calculator'],
            ['TaxPreparation', 'tax-preparers'],
            ['RealEstateAgent', 'real-estate'],
            ['HomeInspectorAndBuildingInspectionServices', 'home-inspectors'],
            ['NonTechnologyConsultants', 'non-technology'],
        ]),
    ],
    [
        'AppTypeCodeListMPL',
        new Map([
            ['Accounting', 'calculator'],
            ['TaxPreparation', 'tax-preparers'],
            ['RealEstateAgent', 'real-estate'],
            ['HomeInspectorAndBuildingInspectionServices', 'home-inspectors'],
            ['NonTechnologyConsultants', 'non-technology'],
        ]),
    ],
]);

const lawTitlesMap = new Map<AppTypeCode, Map<Vertical, string>>([
    [
        'AppTypeCodeListBOPChubb',
        new Map([
            ['LawFirm', 'Cost-effective, tailored coverage for liability and property risks'],
        ]),
    ],
    [
        'AppTypeCodeListCyberCowbell',
        new Map([
            ['LawFirm', 'Covers expenses that can arise from a security breach or cybercrime'],
        ]),
    ],
    [
        'AppTypeCodeListWCChubb',
        new Map([
            [
                'LawFirm',
                'Covers your employees and your firm in the event of a work-related injury/illness',
            ],
        ]),
    ],
    [
        'AppTypeCodeListEverestLawyersProfessionalLiability',
        new Map([['LawFirm', 'Essential protection against common risks for law firms']]),
    ],
]);

const lawCoverageDescriptionMap = new Map<AppTypeCode, Map<Vertical, string>>([
    [
        'AppTypeCodeListBOPChubb',
        new Map([
            [
                'LawFirm',
                `Damaged equipment in your office space. An injury in your workspace. A car accident while driving for work. These are just some of the common liability and property risks faced by lawyers like you.

            A Business Owners Policy (BOP) combines multiple coverages in one policy. The General Liability part can cover such liabilities as third-party injuries, while Commercial Property insurance protects your office and its contents.`,
            ],
        ]),
    ],
    [
        'AppTypeCodeListCyberCowbell',
        new Map([
            [
                'LawFirm',
                `If you store client data (names, addresses, SSN, credit card info, etc.) or transfer funds electronically, your firm is vulnerable to cybercrime — simple as that. Even if you don’t, your paper files could be at risk if they’re not adequately secured.

            Still not convinced? According to the U.S. Government Accountability Office, in 2021, the average cost of a data breach incident was $118,000.`,
            ],
        ]),
    ],
    [
        'AppTypeCodeListWCChubb',
        new Map([
            [
                'LawFirm',
                `Did you know Workers Compensation is not just for high-risk jobs? With Workers Comp, you can care for your employees, protect your firm and meet state requirements with one policy.

            Employees’ slip-and-fall workplace injuries and repetitive stress injuries from everyday computer tasks can also result in costly claims for your firm.`,
            ],
        ]),
    ],
    [
        'AppTypeCodeListEverestLawyersProfessionalLiability',
        new Map([
            [
                'LawFirm',
                'You’re in one state and practice in another? LPL protects you against costly claims of alleged errors and omissions no matter where in the world you provide your professional services.',
            ],
        ]),
    ],
]);

const lawCoverageTitleMap = new Map<AppTypeCode, Map<Vertical, string>>([
    [
        'AppTypeCodeListBOPChubb',
        new Map([['LawFirm', 'Why do you need a Business Owners Policy?']]),
    ],
    ['AppTypeCodeListCyberCowbell', new Map([['LawFirm', 'Why do you need Cyber Insurance?']])],
    ['AppTypeCodeListWCChubb', new Map([['LawFirm', 'Why do you need Workers Compensation?']])],
    [
        'AppTypeCodeListEverestLawyersProfessionalLiability',
        new Map([['LawFirm', 'Why do you need Lawyers Professional Liability Insurance?']]),
    ],
]);

const whyNeedItProgramTitleMap = new Map<AppTypeCode, Map<Vertical, string>>([
    [
        'AppTypeCodeListMPLBundle',
        new Map([
            ['Accounting', 'Why do Accountants need it?'],
            ['TaxPreparation', 'Why do Tax Preparers and Bookkeepers need it?'],
            ['RealEstateAgent', 'Why do Real Estate Agents need it?'],
            ['HomeInspectorAndBuildingInspectionServices', 'Why do Home Inspectors need it?'],
            [
                'NonTechnologyConsultants',
                'Why do Non-Technology Business and Management Consultants need it?',
            ],
        ]),
    ],
    [
        'AppTypeCodeListMPL',
        new Map([
            ['Accounting', 'Why do Accountants need it?'],
            ['TaxPreparation', 'Why do Tax Preparers and Bookkeepers need it?'],
            ['RealEstateAgent', 'Why do Real Estate Agents need it?'],
            ['HomeInspectorAndBuildingInspectionServices', 'Why do Home Inspectors need it?'],
            [
                'NonTechnologyConsultants',
                'Why do Non-Technology Business and Management Consultants need it?',
            ],
        ]),
    ],
    ['AppTypeCodeListLawBundle', new Map([['LawFirm', 'Why get it with Embroker?']])],
    ['AppTypeCodeListBOPChubb', new Map([['LawFirm', 'Why get it with Embroker?']])],
    ['AppTypeCodeListCyberCowbell', new Map([['LawFirm', 'Why get it with Embroker?']])],
    ['AppTypeCodeListWCChubb', new Map([['LawFirm', 'Why get it with Embroker?']])],
    [
        'AppTypeCodeListEverestLawyersProfessionalLiability',
        new Map([['LawFirm', 'Why get it with Embroker?']]),
    ],
]);

const defaultLawNeedItDescription = (
    <ColumnLayout key="content" top>
        <StackLayout center className="u-1/3">
            <Icon name="star" color="accent-500" />
            <Text textAlign="center">Excellent or Better Rating by AM Best</Text>
        </StackLayout>
        <StackLayout center className="u-1/3">
            <Icon name="chat" color="accent-500" />
            <Text textAlign="center">Expert Insurance Support</Text>
        </StackLayout>
        <StackLayout center className="u-1/3">
            <Icon name="certificate" color="accent-500" />
            <Text textAlign="center">Online Certificate of Insurance</Text>
        </StackLayout>
    </ColumnLayout>
);

const whyNeedItDescriptionMap = new Map<AppTypeCode, React.ReactNode>([
    [
        'AppTypeCodeListMPLBundle',
        <React.Fragment key="content">
            <Text>
                It's simple: No matter how good you are at doing your job, you're bound to make a
                mistake every now and then, or have a disgruntled client who claims that you did —
                even if you didn't.
            </Text>
            <Text>Protect yourself from financial losses and harm to your reputation by: </Text>
            <List>
                <List.Item>
                    <Text>
                        Covering the costs of legal fees and damages if you're sued for malpractice
                    </Text>
                </List.Item>
                <List.Item>
                    <Text>
                        {' '}
                        Quickly resolving the issue without having to confront the customer who's
                        suing you
                    </Text>
                </List.Item>
            </List>
        </React.Fragment>,
    ],
    [
        'AppTypeCodeListMPL',
        <React.Fragment key="content">
            <Text>
                It's simple: No matter how good you are at doing your job, you're bound to make a
                mistake every now and then, or have a disgruntled client who claims that you did —
                even if you didn't.
            </Text>
            <Text>Protect yourself from financial losses and harm to your reputation by: </Text>
            <List>
                <List.Item>
                    <Text>
                        Covering the costs of legal fees and damages if you're sued for malpractice
                    </Text>
                </List.Item>
                <List.Item>
                    <Text>
                        {' '}
                        Quickly resolving the issue without having to confront the customer who's
                        suing you
                    </Text>
                </List.Item>
            </List>
        </React.Fragment>,
    ],
    ['AppTypeCodeListBOPChubb', defaultLawNeedItDescription],
    ['AppTypeCodeListCyberCowbell', defaultLawNeedItDescription],
    ['AppTypeCodeListWCChubb', defaultLawNeedItDescription],
    ['AppTypeCodeListEverestLawyersProfessionalLiability', defaultLawNeedItDescription],
]);
