import { Text } from '@embroker/ui-toolkit/v2';
import React from 'react';

export interface QuoteExpirationProps {
    daysToExpire?: number;
}
export const QuoteExpiration = function ({ daysToExpire }: QuoteExpirationProps) {
    if (daysToExpire === undefined) {
        return null;
    }
    const quoteExpirationLabel =
        daysToExpire < 0
            ? 'Quote expired'
            : daysToExpire === 0
            ? 'Quote expires today'
            : daysToExpire === 1
            ? 'Quote expires in 1 day'
            : 'Quote expires in ' + daysToExpire + ' days';
    return (
        <Text as="span" style="heading 5" color="ui-50">
            {quoteExpirationLabel}
        </Text>
    );
};
