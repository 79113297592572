import {
    BoxLayout,
    Card,
    CardLayout,
    CheckBox,
    ColumnLayout,
    Icon,
    IconName,
    List,
    StackLayout,
    StatusLabel,
    Text,
    useResponsive,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { MPLBundleUnderlyingAppType } from '../../types/enums';

interface RiskAssessmentCardProps {
    appType: MPLBundleUnderlyingAppType;
    iconName: IconName;
    title: string;
    coverageName: string;
    description: string;
    riskExposures: string[];
    footnote: React.ReactNode;
    isTrainingIncluded: boolean;
    disabled: boolean;
    selected: boolean;
    handleCardChecked: (checked: boolean, appType: MPLBundleUnderlyingAppType) => void;
}

export function RiskAssessmentCard({
    appType,
    title,
    coverageName,
    iconName,
    riskExposures,
    description,
    footnote,
    isTrainingIncluded,
    disabled,
    selected,
    handleCardChecked,
}: RiskAssessmentCardProps) {
    const { isGreaterThanDesktop, isSmallerThanLargeDesktop } = useResponsive({
        isGreaterThanDesktop: { screenWidth: { greaterThan: 'desktop' } },
        isSmallerThanLargeDesktop: { screenWidth: { smallerThan: 'large-desktop' } },
    });

    const gridSize = isGreaterThanDesktop && isSmallerThanLargeDesktop ? 4 : 6;

    return (
        <CardLayout className={`u-grid-size-${gridSize}`} radius="16">
            <StackLayout className="o-box-layout--gap-32" split="1 -1">
                <StackLayout gap="20">
                    <Text style="label 1" color="brand-400" nowrap>
                        {title}
                    </Text>
                    <StackLayout gap="none">
                        {riskExposures.length && (
                            <List>
                                {riskExposures.map((exposure, index) => (
                                    <List.Item
                                        icon="status-incomplete"
                                        iconColor="orange"
                                        key={`${exposure}-${index}`}
                                    >
                                        <Text color="brand-400" style="body 1">
                                            {exposure}
                                        </Text>
                                    </List.Item>
                                ))}
                            </List>
                        )}
                        <Text style="microcopy">{footnote}</Text>
                    </StackLayout>
                </StackLayout>
                <StackLayout gap="24">
                    <div className="c-list-view__item--border-solid-ui-300"></div>
                    <StackLayout gap="none">
                        <StackLayout gap="16">
                            <ColumnLayout split={'-1'}>
                                <Text style="label 1" color="brand-400">
                                    RECOMMENDED COVERAGE
                                </Text>
                                <CheckBox
                                    disabled={disabled}
                                    appearance="toggle"
                                    checked={selected}
                                    onChange={(event) => {
                                        handleCardChecked(event.target.checked, appType);
                                    }}
                                    inline
                                />
                            </ColumnLayout>
                            <Card.Header>
                                <Icon size="large" color="accent-400" name={iconName} />
                                <Text color="brand-400" style="heading 5" as="span">
                                    {coverageName}
                                </Text>
                            </Card.Header>
                            <Card.Body>
                                <List>
                                    <List.Item icon="status-success">
                                        <Text color="brand-400" style="body 1">
                                            {description}
                                        </Text>
                                    </List.Item>
                                </List>
                            </Card.Body>
                        </StackLayout>
                        <Card.Footer>
                            {isTrainingIncluded ? (
                                <StatusLabel type="purple">TRAINING INCLUDED</StatusLabel>
                            ) : (
                                <BoxLayout gap="12" />
                            )}
                        </Card.Footer>
                    </StackLayout>
                </StackLayout>
            </StackLayout>
        </CardLayout>
    );
}
