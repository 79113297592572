import { Money } from '@embroker/shotwell/core/types/Money';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import {
    Collapsible,
    Icon,
    InvoiceTable,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React, { useState } from 'react';
import { BundleSummaryComponentProps } from '../../coverageDefinition';
import { WCChubbQuote } from '../entities/WCChubbQuote';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { CoverageBreakdownList } from '../../../view/components/CoverageBreakdownList.view';

const TEXT_PRIMARY_COLOR = 'brand-400';

export function WCChubbQuoteSummary({ quote }: BundleSummaryComponentProps<WCChubbQuote>) {
    const [showCoverageItems, setShowCoverageItems] = useState<boolean>(false);
    const caretName = !showCoverageItems ? 'caret-down' : 'caret-up';

    const lineItems: { label: string; value: number }[] = [
        { label: 'Per Accident Limit', value: quote.options.perAccidentLimit },
    ];

    const getTotalPrice = (): Money => {
        const sumResult = Money.sum([quote.details.totalPremium, quote.details.taxes]);
        if (isOK(sumResult)) {
            return sumResult.value;
        }
        container
            .get<Logger>(Log)
            .error('Unexpected error while calculating the sum', sumResult.errors);
        return quote.details.totalPremium;
    };

    // TODO confirm display values

    return quote ? (
        <StackLayout gap="16">
            <InvoiceTable.Section>
                <InvoiceTable.Item
                    title={
                        <React.Fragment>
                            <Text
                                style="body 1"
                                color={TEXT_PRIMARY_COLOR}
                                as="span"
                                data-e2e="coverage-list-wc-title"
                            >
                                {/* TODO CONFIRM DISPLAY VALUE */}
                                Workers Compensation Policy
                                <TextButton
                                    as={Icon}
                                    name={caretName}
                                    onClick={() => setShowCoverageItems(!showCoverageItems)}
                                />
                            </Text>
                            <br />
                            <Text style="body 2" as="span">
                                Insurer: Chubb
                            </Text>
                        </React.Fragment>
                    }
                >
                    <Text
                        style="body 1"
                        color={TEXT_PRIMARY_COLOR}
                        as="span"
                        data-e2e="wc-base-premium"
                    >
                        <MoneyDisplay value={quote.details.totalPremium} />
                    </Text>
                </InvoiceTable.Item>
                <Collapsible state={showCoverageItems ? 'open' : 'closed'}>
                    <CoverageBreakdownList coverageItems={lineItems} />
                </Collapsible>
                <InvoiceTable.Item
                    title={
                        <Text
                            style="body 1"
                            color={TEXT_PRIMARY_COLOR}
                            as="span"
                            data-e2e="wc-taxes"
                        >
                            Taxes
                        </Text>
                    }
                >
                    <Text
                        style="body 1"
                        color={TEXT_PRIMARY_COLOR}
                        as="span"
                        data-e2e="wc-taxes-value"
                    >
                        <MoneyDisplay value={quote.details.taxes} />
                    </Text>
                </InvoiceTable.Item>
            </InvoiceTable.Section>
            <InvoiceTable.Item
                title={
                    <Text
                        style="heading 5"
                        color={TEXT_PRIMARY_COLOR}
                        as="span"
                        data-e2e="wc-total"
                    >
                        {/* TODO CONFIRM DISPLAY VALUE */}
                        Total Workers Compensation Policy
                    </Text>
                }
            >
                <Text
                    style="heading 5"
                    color={TEXT_PRIMARY_COLOR}
                    as="span"
                    data-e2e="wc-total-value"
                >
                    <MoneyDisplay value={getTotalPrice()} />
                </Text>
            </InvoiceTable.Item>
        </StackLayout>
    ) : null;
}
