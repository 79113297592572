import { Money } from '@embroker/shotwell/core/types/Money';
import { URI } from '@embroker/shotwell/core/types/URI';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { FormStatus, OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { ColumnLayout, Hyperlink, StackLayout, StatusMessage, Text } from '@embroker/ui-toolkit/v2';
import { addDays, isAfter, isWithinInterval, startOfToday } from 'date-fns';
import React, { Fragment, useCallback, useContext, useEffect, useMemo } from 'react';
import { mapFromApiLimit } from '../../../quote/lpl/repositories/LPLQuoteRepository/APILPLQuoteRepository';
import { isDateFeb29 } from '../../../quote/lpl/view/components/createLPLQuoteForm';
import { indicationMessage } from '../../../quote/types/QuoteHigherLimitsState';
import { AppContext } from '../../../view/AppContext';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { WizardForm } from '../../../view/hooks/useWizardForm';
import { CoverageCatalog } from '../../CoverageCatalog';
import { BundleQuote } from '../../entities/BundleQuote';
import { BundleWarningEnum, BundleWarningStatus } from '../../types/BundleQuoteCoverage';
import { BundleQuoteFormData } from '../../types/BundleQuoteFormData';
import { BundleQuoteAddressCard } from './BundleQuoteAddressCard';
import { BundleQuoteDocumentsList } from './BundleQuoteDocumentsList';
import { BundleQuoteEffectiveDate } from './BundleQuoteEffectiveDate';
import { BundleQuoteHLSlideout } from './BundleQuoteHLSlideout';
import { DocumentsItem } from './BundleQuoteLandingPage';
import { BundleQuotePaymentOptionsCard } from './BundleQuotePaymentOptionsCard';
import { BundleQuoteTotalCard } from './BundleQuoteTotalCard.view';

export interface BundleCardsProps {
    reset: () => void;
    bundleQuote: BundleQuote;
    effectiveDate: Date;
    formValue: BundleQuoteFormData & Record<string, unknown>;
    status: WizardForm<OpaqueForm<BundleQuoteFormData>>['status'];
    next: WizardForm<OpaqueForm<BundleQuoteFormData>>['next'];
    hasPrevious: boolean;
    fields: WizardForm<OpaqueForm<any>>['fields'];
    trigger: WizardForm<OpaqueForm<BundleQuoteFormData>>['trigger'];
    bundleDocumentList: DocumentsItem[];
    onDateChange: (event: { target: { value: string; date: Date } }) => void;
    newFlowEnabled: boolean;
    warnings: BundleWarningStatus[];
    handleSubmitForReview: () => void;
    appHasExpired: boolean;
}

export const BundleCards = ({
    reset,
    bundleQuote,
    status,
    next,
    hasPrevious,
    fields,
    formValue,
    trigger,
    bundleDocumentList,
    effectiveDate,
    onDateChange,
    newFlowEnabled,
    warnings,
    handleSubmitForReview,
    appHasExpired,
}: BundleCardsProps) => {
    const isDirty = status === 'dirty';

    const areAllCoveragesDisabledOrDeselected = bundleQuote.areAllCoveragesDisabledOrDeselected();
    const indication = bundleQuote.isAnyCoverageIndication();
    const isAnyCoverageReferredAwaitingReview = bundleQuote.isAnyCoverageReferredAwaitingReview();
    const isReferred =
        bundleQuote.isAnyCoverageReferred() || bundleQuote.isAnyCoverageReferredAwaitingReview();
    const bundleQuoteEffectiveDate = useMemo(
        () => bundleQuote.effectiveDate,
        [bundleQuote.effectiveDate],
    );
    const quoteDaysToExpire = appHasExpired ? undefined : bundleQuote.getQuoteValidityInDays();
    const quoteHasExpired = quoteDaysToExpire !== undefined && quoteDaysToExpire < 0;

    const { setSlideout, closeSlideout } = useContext(AppContext);

    const isLimitHigherThenAllowed = CoverageCatalog.isLimitHigherThenAllowed(
        formValue,
        bundleQuote,
    );
    const isHigherLimitReviewed = checkIsHigherLimitReviewed(bundleQuote);

    const isGapBetweenPolicies =
        !!bundleQuote.organizationInfo.currentPolicyEndDate &&
        isAfter(formValue.effectiveDate, bundleQuote.organizationInfo.currentPolicyEndDate);
    const indicationMessages = generateWarningMessage(
        bundleQuote,
        isHigherLimitReviewed,
        indication,
        quoteHasExpired,
        appHasExpired,
        warnings,
        isGapBetweenPolicies,
    );
    const isBundleQuoteBindable = bundleQuote.isBundleQuoteBindable();
    const selectedCoverages = useMemo(() => bundleQuote.getSelectedCoverages(), [bundleQuote]);

    // TODO - take in consideration newFlowEnabled flag
    const quoteTotalValueComponent =
        isDirty || appHasExpired ? null : getQuoteTotalValueComponent(bundleQuote);
    const isEffectiveDateDisabled =
        areAllCoveragesDisabledOrDeselected ||
        hasPrevious ||
        isAnyCoverageReferredAwaitingReview ||
        appHasExpired;

    const summary = bundleQuote.getCoverageListSummary();

    const { navigate } = useNavigation();
    const {
        totalButtonContent,
        totalTitleContent = 'Premium',
        totalTitleTooltipContent = null,
        handleCTAButtonClick,
    } = isDirty
        ? { totalButtonContent: 'Recalculate', handleCTAButtonClick: () => trigger('update') }
        : (summary.bindableQuoteList.length === 0 &&
              summary.referredQuoteList.length === 0 &&
              summary.currentLimitExceedList.length === 0 &&
              summary.indicationList.length === 0) ||
          areAllCoveragesDisabledOrDeselected ||
          isAnyCoverageReferredAwaitingReview ||
          quoteHasExpired ||
          appHasExpired
        ? {
              totalButtonContent: 'Exit',
              totalTitleContent: isAnyCoverageReferredAwaitingReview
                  ? 'Estimated Premium'
                  : undefined,
              totalTitleTooltipContent: isAnyCoverageReferredAwaitingReview
                  ? `This refers to the approximate cost of an insurance policy. The final cost may be higher or lower, depending on your risk profile and other factors.`
                  : undefined,
              handleCTAButtonClick: () => navigate(URI.build('/summary')),
          }
        : newFlowEnabled
        ? summary.bindableQuoteList.length > 0
            ? {
                  totalButtonContent: 'Continue to Checkout',
                  handleCTAButtonClick: () => next(),
              }
            : {
                  totalButtonContent: 'Submit for Review',
                  totalTitleContent: 'Estimated Premium',
                  totalTitleTooltipContent: `This refers to the approximate cost of an insurance policy. The final cost may be higher or lower, depending on your risk profile and other factors.`,
                  handleCTAButtonClick: () => handleSubmitForReview(),
              }
        : summary.referredQuoteList.length > 0 ||
          summary.indicationList.length > 0 ||
          summary.currentLimitExceedList.length > 0
        ? {
              totalButtonContent: 'Submit for Review',
              totalTitleContent: 'Estimated Premium',
              totalTitleTooltipContent: `This refers to the approximate cost of an insurance policy. The final cost may be higher or lower, depending on your risk profile and other factors.`,
              handleCTAButtonClick: () => handleSubmitForReview(),
          }
        : {
              totalButtonContent: 'Continue to Checkout',
              handleCTAButtonClick: () => next(),
          };

    const isEffectiveDateSelectable = useCallback(
        (effectiveDate: Date) => {
            const today = startOfToday();
            if (isDateFeb29(effectiveDate)) {
                return false;
            }
            return isWithinInterval(effectiveDate, {
                start: today,
                end: addDays(today, getMaxSelectableEffectiveDate(bundleQuote)),
            });
        },
        [bundleQuote],
    );

    const handleSlideoutDismiss = useCallback(() => {
        reset();
        closeSlideout();
    }, [closeSlideout, reset]);

    const handleProceedWithHigherLimits = useCallback(() => {
        trigger('updateAndCloseSlideout');
    }, [trigger]);

    useEffect(() => {
        if (isLimitHigherThenAllowed && isDirty) {
            setSlideout(
                <BundleQuoteHLSlideout
                    handleDismiss={handleSlideoutDismiss}
                    handleCTAClick={handleProceedWithHigherLimits}
                    coverageSpecificText={CoverageCatalog.getHLCoverageSpecificText(
                        formValue,
                        bundleQuote,
                    )}
                />,
                { isDismissable: false },
            );
        }
    }, [
        isLimitHigherThenAllowed,
        isDirty,
        handleSlideoutDismiss,
        handleProceedWithHigherLimits,
        setSlideout,
        formValue,
        bundleQuote,
    ]);

    return (
        <Fragment>
            {!hasPrevious ? (
                <BundleQuoteTotalCard
                    title={totalTitleContent}
                    titleTooltip={totalTitleTooltipContent}
                    label={getQuoteTotalLabel(status)}
                    buttonContent={totalButtonContent}
                    onClick={handleCTAButtonClick}
                >
                    {quoteTotalValueComponent}
                </BundleQuoteTotalCard>
            ) : null}
            {!hasPrevious
                ? indicationMessages.map((indicationMessage, index) => {
                      return (
                          <StatusMessage key={index} status={indicationMessage.status}>
                              {indicationMessage.message}
                          </StatusMessage>
                      );
                  })
                : null}
            <BundleQuoteEffectiveDate
                fields={fields}
                disabled={isEffectiveDateDisabled}
                effectiveDate={effectiveDate}
                onDateChange={onDateChange}
                isEffectiveDateSelectable={isEffectiveDateSelectable}
            />
            <BundleQuoteAddressCard organizationInfo={bundleQuote.organizationInfo} />
            {!areAllCoveragesDisabledOrDeselected &&
            isBundleQuoteBindable &&
            !indication &&
            !isReferred ? (
                <BundleQuotePaymentOptionsCard
                    selectedCoverages={selectedCoverages}
                    quoteEffectiveDate={bundleQuoteEffectiveDate}
                />
            ) : null}
            {appHasExpired ? null : <BundleQuoteDocumentsList documentsList={bundleDocumentList} />}
        </Fragment>
    );
};

function generateWarningMessage(
    bundleQuote: BundleQuote,
    isHigherLimitReviewed: boolean,
    indication: boolean,
    quoteHasExpired: boolean,
    appHasExpired: boolean,
    warnings: BundleWarningStatus[],
    isGapBetweenPolicies: boolean,
): indicationMessage[] {
    const messages: indicationMessage[] = [];

    const isAnyEligibleCoverageDeselected = bundleQuote.isAnyEligibleCoverageDeselected();

    if (isGapBetweenPolicies) {
        messages.push({
            message: (
                <React.Fragment>
                    Before you proceed with binding this quote, please be aware that there is a gap
                    in coverage between the existing policy expiration date and the proposed
                    effective date of your new policy. If you would like further assistance on this
                    matter, please{' '}
                    <Hyperlink href="https://app.embroker.com/support/contact" target="_blank">
                        contact Embroker
                    </Hyperlink>
                    .
                </React.Fragment>
            ),
            status: 'info',
        });
    }
    if (appHasExpired) {
        messages.push({
            message: `This application and quotes are no longer valid, as more than 90 days have passed since the application was submitted. To get a new quote, please start and submit a new application.`,
            status: 'error',
        });
    }
    if (quoteHasExpired) {
        messages.push({
            message: `Unfortunately, your quote has expired. To get an updated quote, please select a new coverage effective date and Recalculate.`,
            status: 'warning',
        });
    }

    if (messages.length) {
        return messages;
    }

    if (isAnyEligibleCoverageDeselected) {
        messages.push({
            message: `Turning off a line of insurance will remove it from your final suite of policies. If you want to include it, simply click the button to turn it back on.`,
            status: 'info',
        });
    }

    if (isHigherLimitReviewed && !indication) {
        messages.push({
            message: `We've reviewed your Application and you're good to go! You may now customize your quote by updating your Limits, and Deductibles or Retentions, if needed. Then, simply click "Recalculate" to update your quote.`,
            status: 'success',
        });
    }

    warnings.forEach((warning) => {
        if (warning === BundleWarningEnum.CyberQuoteOptionsOverride) {
            messages.push({
                message: `The Limits and/or Retentions you selected are not available for this Cyber policy. We have updated the Limits/Retentions to the allowable amounts.`,
                status: 'warning',
            });
        }
    });

    return messages;
}

const checkIsHigherLimitReviewed = (bundleQuote: BundleQuote): boolean => {
    return bundleQuote.coverageList.reduce<boolean>((acc, curr) => {
        const coverageHigherLimit = mapFromApiLimit(bundleQuote.getCoverageHigherLimit(curr.type));
        return acc || coverageHigherLimit !== undefined;
    }, false);
};

const getMaxSelectableEffectiveDate = (bundleQuote: BundleQuote): number => {
    const maxFutureDaysAllowed = 90;
    return bundleQuote.coverageList.reduce((acc, current) => {
        const coverageMaxDays = CoverageCatalog.getMaxFutureDaysAllowed(current.type);
        if (coverageMaxDays < acc) {
            return coverageMaxDays;
        }
        return acc;
    }, maxFutureDaysAllowed);
};

const getQuoteTotalLabel = (status: FormStatus): React.ReactNode => {
    const isDirty = status === 'dirty';

    if (isDirty) {
        return 'Recalculate Premium';
    }

    return null;
};

const getQuoteTotalValueComponent = (bundleQuote: BundleQuote): React.ReactNode => {
    const quoteTotalValue = bundleQuote.getQuoteTotalValue();
    if (!quoteTotalValue) {
        return null;
    }
    if (Money.check(quoteTotalValue)) {
        return (
            <ColumnLayout gap="16" bottom>
                <MoneyDisplay value={quoteTotalValue} data-e2e="value" fractionDigits={0} />
                &nbsp;
                <StackLayout gap="4">
                    <Text style="heading 4" as="span" color="brand-400">
                        per year
                    </Text>
                    <span />
                </StackLayout>
            </ColumnLayout>
        );
    }
    return (
        <ColumnLayout gap="4" center data-e2e="referred">
            <MoneyDisplay value={quoteTotalValue.min} fractionDigits={0} />
            &nbsp;-&nbsp;
            <MoneyDisplay value={quoteTotalValue.max} fractionDigits={0} />
        </ColumnLayout>
    );
};
