import { CoverLayout, Placeholder, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';

export function SelectCoveragesStepGuidelines() {
    return (
        <CoverLayout>
            <StackLayout split="-1" gap="24">
                <StackLayout gap="4">
                    <Text style="overline" color={'brand-500'}>
                        Step 4
                    </Text>
                    <Text style={'heading 3'}>Now, just tell us what you need.</Text>
                </StackLayout>
                <Text style={'body 1'}>
                    For this step, simply select the coverages you'd like to have in your
                    certificate. This way, you can customize your certificate to fit your needs,
                    omitting any unnecessary information.
                </Text>
                <Placeholder
                    backgroundColor="ui-50"
                    appearance="compact"
                    imageMaxWidth={270}
                    imageName="illustration-shield"
                />
            </StackLayout>
        </CoverLayout>
    );
}
