import React from 'react';
import { Text } from '@embroker/ui-toolkit/v2';
import { BundleSummaryComponentProps } from '../../coverageDefinition';
import { ESPQuote } from '../entities/ESPQuote';

export function ESPBundleSummary({
    questionnaireData,
    quote,
}: BundleSummaryComponentProps<ESPQuote>) {
    return <Text>ESP Bundle Summary</Text>;
}
