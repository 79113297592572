import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ColumnLayout, GridLayout, StackLayout, Text, TextButton } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { DisplayPolicy } from '../../../policy/types/DisplayPolicy';
import { PolicyList } from '../../../policy/view/components/PolicyList';
import { useNavigation } from '../../../view/hooks/useNavigation';

const POLICIES_PER_SECTION = 2;

interface PoliciesSectionProps {
    policyList: Immutable<DisplayPolicy[]>;
    organizationId: Nullable<UUID>;
    isBundlePolicies: boolean;
}

export function PoliciesSection({
    policyList,
    organizationId,
    isBundlePolicies,
}: PoliciesSectionProps) {
    const linkToAllPolicies = () => {
        return policyList.length >= POLICIES_PER_SECTION ? (
            <TextButton
                size="small"
                icon="bold-caret-right"
                iconPosition="right"
                onClick={handleAllPoliciesClick}
            >
                {isBundlePolicies ? 'View All Programs' : 'View All Policies'}
            </TextButton>
        ) : null;
    };

    const navigation = useNavigation();
    const handleAllPoliciesClick = () => {
        if (organizationId !== null) {
            navigation.navigate(
                URI.build('/policies', {
                    organizationId: organizationId.toString(),
                }),
            );
        }
    };

    return (
        <GridLayout>
            <StackLayout className="u-grid-size-12">
                <ColumnLayout className="u-grid-size-12">
                    <Text style="heading 4" data-e2e="active-policies-heading">
                        {isBundlePolicies ? 'Your Active Programs' : 'Your Active Policies'}
                    </Text>
                    {linkToAllPolicies()}
                </ColumnLayout>
                <PolicyList
                    policyList={policyList.slice(0, POLICIES_PER_SECTION)}
                    showActionButtons
                ></PolicyList>
            </StackLayout>
        </GridLayout>
    );
}
