import { execute } from '@embroker/shotwell/core/UseCase';
import { container } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import {
    Button,
    ButtonBar,
    ColumnLayout,
    Form,
    InvoiceTable,
    StackLayout,
    Text,
    TextButton,
    useModal,
} from '@embroker/ui-toolkit/v2';
import React, { useCallback, useMemo } from 'react';
import { CalculateSKU } from '../../../analytics/useCases/CalculateSKU';
import { QuoteBindCoverage } from '../../../quote/entities/Quote';
import { CoverageCatalog } from '../../CoverageCatalog';
import { BundleCoverageAppStatusEnum } from '../../types/BundleQuoteCoverage';
import { BundleQuoteFormData } from '../../types/BundleQuoteFormData';
import { BundlePurchaseConfirmationModal } from './BundlePurchaseConfirmationModal';
import { BundleQuoteCoverage } from './BundleQuoteCoverage';
import { BundleQuoteCoverageListProps } from './BundleQuoteCoverageList';
import {
    checkIsRequiredApplicationAttestation,
    checkIsRequiredNoKnownLossesAttestation,
    checkIsSurplusLinesDiscDisabled,
} from './createBundleQuoteForm';
import { SignInsuranceApplicationSignature } from './signature/SignInsuranceApplicationSignature';
import { SignInsuranceApplicationWarrantySignature } from './signature/SignInsuranceApplicationWarrantySignature';
import { BundleQuoteCoverageListTitle } from './BundleQuoteCoverageListTitle.view';
import { BundleQuoteCardLayout } from './BundleQuoteCardLayout.view';

export const TEXT_PRIMARY_COLOR = 'brand-400';
export function BundleQuoteSignature(props: BundleQuoteCoverageListProps<BundleQuoteFormData>) {
    const {
        bundleQuote,
        fields,
        setValue,
        value,
        trigger,
        submit,
        hasPrevious,
        previous,
        messages,
        appHasExpired,
        quoteHasExpired,
        handleCoverageSelectToggle,
        newFlowEnabled,
    } = props;

    const purchaseConfirmationModal = useModal();

    const eventBus = useMemo(() => container.get<DomainEventBus>(DomainEventBus), []);

    const isSurplusLinesDiscDisabled = checkIsSurplusLinesDiscDisabled(bundleQuote);
    const isRequiredApplicationAttestation = checkIsRequiredApplicationAttestation(bundleQuote);
    const isRequiredNoKnownLossesAttestation = checkIsRequiredNoKnownLossesAttestation(
        bundleQuote,
        isRequiredApplicationAttestation,
    );
    const isApplicationAttestationSigned = isRequiredNoKnownLossesAttestation
        ? value.applicationAttestationSignature && value.applicationNoKnownLossesAttestation
        : value.applicationAttestationSignature;

    const signed =
        value.agreementToConductSignature &&
        value.warrantyAndFraudSignature &&
        (!isRequiredApplicationAttestation || isApplicationAttestationSigned);

    const handleConfirmPurchase = useCallback(() => {
        purchaseConfirmationModal.show();
    }, [purchaseConfirmationModal]);

    const onConfirmedPurchase = useCallback(() => {
        purchaseConfirmationModal.hide();

        execute(CalculateSKU, {
            event: 'quote',
            applicationId: bundleQuote.applicationId,
        }).then((skuResult) => {
            const event: QuoteBindCoverage = {
                origin: 'Quote',
                name: 'BindCoverage',
                totalPremium: bundleQuote.getTotalBundlePremium(),
                applicationId: bundleQuote.applicationId,
                createdAt: new Date(Date.now()),
                id: UUID.create(),
                sku: isOK(skuResult) ? skuResult.value : undefined,
                isRenewal: false,
            };
            eventBus.publish(event);
            submit();
        });
    }, [bundleQuote, eventBus, purchaseConfirmationModal, submit]);

    const selectedCoverages = bundleQuote.getSelectedCoverages();

    const referredQuotes = selectedCoverages.filter(
        (coverage) =>
            coverage.status === BundleCoverageAppStatusEnum.Referred ||
            coverage.status === BundleCoverageAppStatusEnum.ReferredAwaitingReview ||
            coverage.quote?.isIndication,
    );

    const downloadAppWithManifestAction = selectedCoverages
        .map((coverage) => CoverageCatalog.getDownloadAppWithManifestDocAction(coverage.type))
        .find((action) => action !== undefined);

    const handleAppDownloadClick = useMemo(() => {
        if (downloadAppWithManifestAction) {
            return () => downloadAppWithManifestAction(trigger);
        } else {
            return undefined;
        }
    }, [downloadAppWithManifestAction, trigger]);

    // temp method to get state
    // should be removed once we move prod specific signature components to adequate coverage definitions
    const state = bundleQuote.getState();

    return (
        <React.Fragment>
            <BundlePurchaseConfirmationModal
                modal={purchaseConfirmationModal}
                onConfirmPurchase={onConfirmedPurchase}
                messages={messages}
                trigger={trigger}
                bundleQuote={bundleQuote}
            />
            <BundleQuoteCardLayout gap="48">
                <StackLayout gap="48">
                    <StackLayout gap="16">
                        <Text style="heading 3" color={TEXT_PRIMARY_COLOR}>
                            Quote Summary
                        </Text>
                        <StackLayout>
                            <Text style="body 1" as="p" data-e2e="left-pannel-1">
                                Thanks for applying! Radically simple business insurance awaits —
                                all that's left to do is bind your policy and pay. We've summarized
                                your quotes below so you can see at a glance what you're getting.
                            </Text>
                            <Text style="body 1" as="p" data-e2e="left-pannel-2">
                                As Embroker's customer, you can manage your policies, issue
                                certificates of insurance and manage claims through your Embroker
                                Dashboard.
                            </Text>
                        </StackLayout>
                    </StackLayout>
                    <StackLayout gap="32">
                        <InvoiceTable>
                            <StackLayout gap="24">
                                <InvoiceTable.Section>
                                    <StackLayout gap="32">
                                        {bundleQuote.getSelectedCoverages().map((coverage, i) => {
                                            const Component = CoverageCatalog.getSummaryComponent(
                                                coverage.type,
                                            );
                                            const { quote, questionnaireData } = coverage;
                                            if (
                                                !Component ||
                                                !quote ||
                                                !CoverageCatalog.isQuoteValidType(
                                                    quote,
                                                    coverage.type,
                                                ) ||
                                                !questionnaireData ||
                                                !CoverageCatalog.isQuestionnaireDataValidType(
                                                    questionnaireData,
                                                    coverage.type,
                                                ) ||
                                                coverage.status !==
                                                    BundleCoverageAppStatusEnum.QuotesReady ||
                                                quote?.isIndication
                                            ) {
                                                return null;
                                            }

                                            return (
                                                <Component
                                                    key={coverage.type}
                                                    questionnaireData={questionnaireData}
                                                    quote={quote}
                                                />
                                            );
                                        })}
                                        <div />
                                    </StackLayout>
                                </InvoiceTable.Section>
                                <StackLayout>
                                    <InvoiceTable.Section>
                                        <StackLayout gap="8">
                                            <InvoiceTable.Item
                                                title={
                                                    <Text
                                                        style="heading 3"
                                                        color={TEXT_PRIMARY_COLOR}
                                                        as="span"
                                                    >
                                                        Total Premium
                                                    </Text>
                                                }
                                            >
                                                <Text
                                                    style="heading 3"
                                                    color={TEXT_PRIMARY_COLOR}
                                                    as="span"
                                                    data-e2e="total-payment-due-value"
                                                >
                                                    <ColumnLayout gap="16" bottom>
                                                        <MoneyDisplay
                                                            value={bundleQuote.getTotalBindablePremium()}
                                                        />
                                                        &nbsp;
                                                        <Text
                                                            style="label 1"
                                                            as="span"
                                                            color="brand-400"
                                                        >
                                                            per year
                                                        </Text>
                                                    </ColumnLayout>
                                                </Text>
                                            </InvoiceTable.Item>
                                        </StackLayout>
                                    </InvoiceTable.Section>
                                    <InvoiceTable.Section>
                                        <StackLayout gap="none">
                                            <Form.Field
                                                type={fields.agreementToConductSignature.type}
                                                messages={
                                                    fields.agreementToConductSignature.messages
                                                }
                                                data-e2e="electronic-transfer"
                                                inputProps={{
                                                    checked:
                                                        fields.agreementToConductSignature.props
                                                            .value,
                                                    onChange: (
                                                        event: React.ChangeEvent<HTMLInputElement>,
                                                    ) => {
                                                        setValue({
                                                            ...value,
                                                            agreementToConductSignature:
                                                                event.target.checked,
                                                        });
                                                        trigger('sign');
                                                    },
                                                    children: (
                                                        <SignInsuranceApplicationSignature
                                                            includeSurplusLinesDisclosure={
                                                                !isSurplusLinesDiscDisabled
                                                            }
                                                            style="body 1"
                                                            company={
                                                                bundleQuote.organizationInfo
                                                                    .companyName
                                                            }
                                                            fullName={
                                                                bundleQuote.organizationInfo
                                                                    .userInfo.fullName
                                                            }
                                                            usaState={state}
                                                        />
                                                    ),
                                                }}
                                            />
                                            <Form.Field
                                                type={fields.warrantyAndFraudSignature.type}
                                                messages={fields.warrantyAndFraudSignature.messages}
                                                inputProps={{
                                                    checked:
                                                        fields.warrantyAndFraudSignature.props
                                                            .value,
                                                    onChange: (
                                                        event: React.ChangeEvent<HTMLInputElement>,
                                                    ) => {
                                                        setValue({
                                                            ...value,
                                                            warrantyAndFraudSignature:
                                                                event.target.checked,
                                                        });
                                                        trigger('sign');
                                                    },
                                                    children: (
                                                        <SignInsuranceApplicationWarrantySignature
                                                            fullName={
                                                                bundleQuote.organizationInfo
                                                                    .userInfo.fullName
                                                            }
                                                            title={
                                                                bundleQuote.organizationInfo
                                                                    .userInfo.title
                                                            }
                                                            usaState={state}
                                                            handleAppDownloadClick={
                                                                handleAppDownloadClick
                                                            }
                                                        />
                                                    ),
                                                }}
                                            />
                                            {bundleQuote.getSelectedCoverages().map((coverage) => {
                                                return CoverageCatalog.getAttestationComponentList(
                                                    coverage.type,
                                                )?.map((Component, i) => {
                                                    if (!Component) {
                                                        return null;
                                                    }
                                                    return <Component key={i} {...props} />;
                                                });
                                            })}
                                        </StackLayout>
                                    </InvoiceTable.Section>
                                </StackLayout>
                            </StackLayout>
                        </InvoiceTable>
                        <ButtonBar responsive={{ screenWidth: { smallerThan: 'tablet' } }} gap="32">
                            <Button
                                onClick={handleConfirmPurchase}
                                disabled={!signed}
                                data-e2e="pay-now"
                            >
                                Bind and Pay
                            </Button>
                            <TextButton
                                as="button"
                                className={hasPrevious ? '' : 'u-hidden'}
                                onClick={previous}
                            >
                                Back to Quote
                            </TextButton>
                        </ButtonBar>
                    </StackLayout>
                </StackLayout>
            </BundleQuoteCardLayout>
            {newFlowEnabled && referredQuotes && referredQuotes.length > 0 ? (
                <React.Fragment>
                    <br />
                    <StackLayout>
                        <BundleQuoteCoverageListTitle
                            title="Referred Policy"
                            toolTip="This policy is being submitted to the Underwriting Team for review. Once the underwriter has made a decision, they will be in touch via email in 1-2 business days."
                        />
                        <StackLayout gap="24" className="u-1/1">
                            {referredQuotes.map((coverage) => {
                                return (
                                    <BundleQuoteCoverage
                                        key={coverage.type}
                                        coverage={coverage}
                                        organizationInfo={bundleQuote.organizationInfo}
                                        fields={fields}
                                        quoteHasExpired={quoteHasExpired}
                                        appHasExpired={appHasExpired}
                                        value={value}
                                        handleCoverageSelectToggle={handleCoverageSelectToggle}
                                        setValue={setValue}
                                        trigger={trigger}
                                        isSelected={bundleQuote.isCoverageSelected(coverage.type)}
                                        isNotEligible={false}
                                        isReferred
                                    />
                                );
                            })}
                        </StackLayout>
                    </StackLayout>
                </React.Fragment>
            ) : null}
        </React.Fragment>
    );
}
