import { CurrencyMarshaller, QuoteExtended } from '@embroker/shotwell-api/app';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { AsyncResult, Failure, isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { QuoteExpiration } from '../../../quote/types/QuoteExpiration';
import {
    BundleCoverageTypeEnum,
    BundleQuoteCoverage,
    BundleQuoteCoverageMetadata,
} from '../../types/BundleQuoteCoverage';
import { toBundleQuoteCoverage } from '../bundleMappingFunctions';
import { JSONSerdes } from '@embroker/shotwell/core/encoding';
import { ESPQuote } from './entities/ESPQuote';
import { ESPQuestionnaireData } from './types/ESPQuestionnaireData';
import { QuotingEngineESP } from '@app/shopping/types/enums';
import startOfToday from 'date-fns/startOfToday';
import { toEspRate } from '@app/quote/esp/repositories/ESPQuoteRepository/APIESPQuoteRepository';
import { ESP } from '../coverageDefinition';
import { GetApplication } from '@app/shopping/useCases/GetApplication';
import { execute } from '@embroker/shotwell/core/UseCase';
import { IneligibilityReasons } from '@app/shopping/types/IneligibilityReasons';

export async function buildESPCoverage(
    espQuoteExtended: Immutable<QuoteExtended>,
    isBroker: boolean,
): AsyncResult<BundleQuoteCoverage<ESPQuote>, OperationFailed | InvalidArgument> {
    if (!espQuoteExtended) {
        return Failure(OperationFailed({ message: 'espQuoteExtended is null or undefined' }));
    }
    const apiQuote = espQuoteExtended.quote;
    const espCoverageMetadata = getESPCoverageMetadata();

    const questionnaireDataResult = JSONSerdes.deserialize(espQuoteExtended.questionnaire_data);
    if (isErr(questionnaireDataResult)) {
        return Failure(
            InvalidArgument({
                argument: 'questionnaire_data',
                value: espQuoteExtended.questionnaire_data,
            }),
        );
    }

    const espQuestionnaireDataResp = ESPQuestionnaireData.create(
        questionnaireDataResult.value as ESPQuestionnaireData,
    );
    if (isErr(espQuestionnaireDataResp)) {
        return Failure(
            InvalidArgument({
                argument: 'ESP questionnaire data',
                value: espQuestionnaireDataResp.errors,
            }),
        );
    }

    const espQuestionnaireData = espQuestionnaireDataResp.value;

    if (!apiQuote) {
        return toBundleQuoteCoverage<ESPQuote>(
            BundleCoverageTypeEnum.ESPCoverage,
            espQuoteExtended.app_status,
            espCoverageMetadata,
            espQuestionnaireData,
            espQuoteExtended.app_valid_until,
        );
    }
    const apiEspRate = apiQuote.details[ESP];
    const apiEspQuoteOptions = apiQuote.options[ESP];

    if (!apiEspRate) {
        return Failure(OperationFailed({ message: 'ESPQuoteDetails is null or undefined' }));
    }
    if (!apiEspQuoteOptions) {
        return Failure(OperationFailed({ message: 'ESPQuoteOptions is null or undefined' }));
    }

    // TODO: https://embroker.atlassian.net/browse/EM-44619
    // As we move the tech questionnaire to the Oracle/Quentin stack, we will likely not be able to access an ‘application’
    // We will need to refactor this to account for that
    const applicationResult = await execute(GetApplication, { applicationId: apiQuote.app_id });
    if (!isOK(applicationResult)) {
        return Failure(OperationFailed({ message: 'Cannot get ESP application' }));
    }

    const application = applicationResult.value.application;

    const espQuoteResp = await ESPQuote.create({
        isIndication: apiQuote.is_indication,
        id: apiQuote.id,
        quoteNumber: apiQuote.quote_number,
        applicationId: apiQuote.app_id,
        fileKey: apiQuote.file_key ?? undefined,
        totalPremium: CurrencyMarshaller.unmarshal(apiEspRate.total_premium),
        annualTechnologyFee: apiQuote.annual_technology_fee,
        totalPayable: apiQuote.total_payable,
        status: mapReferralReasonsToStatus(
            application.status,
            application.ineligibilityReasons as IneligibilityReasons,
        ),
        options: {
            effectiveDate: apiEspQuoteOptions.effective_period_start || startOfToday(),
            isDeselected: false,
            directorsAndOfficers: apiEspQuoteOptions.directors_and_officers,
            employmentPracticesLiability: apiEspQuoteOptions.employment_practices_liability,
            fiduciary: apiEspQuoteOptions.fiduciary,
            technology: apiEspQuoteOptions.technology,
            cyber: apiEspQuoteOptions.cyber,
            partnerCode: apiEspQuoteOptions.partner_code,
            policyFee: apiEspQuoteOptions.policy_fee,
            isPolicyFeeTaxable: apiEspQuoteOptions.is_policy_fee_taxable,
        },
        details: toEspRate({
            apiEspRate,
            apiEspQuoteOptions,
            shoppingCoverageList: application.shoppingCoverageList,
            quotableShoppingCoverageList: application.quotableShoppingCoverageList || [],
            // TODO: https://embroker.atlassian.net/browse/EM-44620
            // It's unclear for now whether we to support the functionality leverage by 'existingLiabilities'
            // src/quote/esp/repositories/ESPQuoteRepository/APIESPQuoteRepository.ts
            existingLiabilities: [],
            taxes: apiQuote.taxes,
            fees: apiQuote.fees,
        }),
        daysToExpire: QuoteExpiration.getDaysLeftUntilExpiration({
            quotingEngine: QuotingEngineESP,
            applicationStatus: espQuoteExtended.app_status,
            isBroker: isBroker,
            quoteEffectiveDate: apiQuote.options.esp.effective_period_start || startOfToday(),
            today: startOfToday(),
            validUntil: espQuoteExtended.app_valid_until || null,
        }),
        referralReasons: application.ineligibilityReasons?.referralReasons,
    });

    if (isErr(espQuoteResp)) {
        return Failure(InvalidArgument({ argument: 'ESP coverage', value: espQuoteResp.errors }));
    }

    const bundleQuoteCoverage = toBundleQuoteCoverage<ESPQuote>(
        BundleCoverageTypeEnum.ESPCoverage,
        espQuoteExtended.app_status,
        espCoverageMetadata,
        espQuestionnaireData,
        espQuoteExtended.app_valid_until,
        espQuoteResp.value,
    );
    return bundleQuoteCoverage;
}

export const getESPCoverageMetadata = (): BundleQuoteCoverageMetadata => {
    return {
        title: 'ESP Insurance',
        description: 'ESP coverage description',
        icon: 'esp',
        name: 'ESP',
    };
};

// Similar logic to this can be found bellow
// src/quote/esp/repositories/ESPQuoteRepository/APIESPQuoteRepository.ts
const mapReferralReasonsToStatus = function (
    appStatus: string,
    referralReasons: Nullable<IneligibilityReasons>,
) {
    switch (appStatus) {
        case 'Purchased':
            return 'accepted';
        case 'Referred':
            if (
                referralReasons !== null &&
                referralReasons.referralReasons.length == 1 &&
                referralReasons.referralReasons[0] ==
                    'Submission referred for underwrite review of loss runs'
            ) {
                return 'draft';
            } else {
                return 'referred';
            }
        default:
            return 'draft';
    }
};
