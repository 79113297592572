import React from 'react';
import { Form, Immutable } from '@embroker/ui-toolkit/v2';
import { LocationFieldSet } from '@app/userOrg/view/components/LocationFieldSet.view';
import { FormFieldMessage } from '@embroker/shotwell/view/hooks/useForm';
import {
    ComplexFormFieldViewProps,
    getFormFieldProps,
    getInputProps,
} from '../../types/ComplexFieldTypes';
import { MailingAddress } from '@app/userOrg/types/MailingAddress';

export function assertMailingAddressType(input: unknown): input is MailingAddress {
    if (typeof input !== 'object' || input === null) {
        return false;
    }
    return 'addressLine1' in input || 'state' in input || 'zip' in input || 'city' in input;
}

export function AddressFieldFormFieldView(complexFormFieldViewProps: ComplexFormFieldViewProps) {
    const { questionProps, inputFieldProps, onComplexFieldChange } = complexFormFieldViewProps;
    const { key } = questionProps;
    const { messages } = inputFieldProps;

    const formFieldProps = getFormFieldProps(questionProps);
    const inputProps = getInputProps(questionProps, inputFieldProps);

    const value = assertMailingAddressType(inputProps.value) ? inputProps.value : undefined;

    return (
        <Form.Field {...formFieldProps}>
            <LocationFieldSet
                onChange={(value) => onComplexFieldChange(key, value)}
                fieldValue={value}
                messages={messages as Immutable<FormFieldMessage[]>}
            />
        </Form.Field>
    );
}
