import React from 'react';
import {
    BoxLayout,
    CardLayout,
    CheckBox,
    ColumnLayout,
    Icon,
    IconName,
    StackLayout,
    Text,
} from '@embroker/ui-toolkit/v2';

interface IndicativePremiumCoverageCardProps {
    isSelected: boolean;
    icon: IconName;
    title: string | React.ReactNode;
    description: string | React.ReactNode;
    onCoverageSelectionChange: (
        event: React.ChangeEvent<HTMLInputElement & HTMLButtonElement>,
    ) => void;
}

export const IndicativePremiumCoverageCard = ({
    isSelected,
    icon,
    title,
    description,
    onCoverageSelectionChange,
}: IndicativePremiumCoverageCardProps) => {
    return (
        <CardLayout boxShadow={false} borderStyle="none" radius="24" className="u-grid-size-4">
            <BoxLayout gap="24">
                <StackLayout>
                    <ColumnLayout split="-1">
                        <Icon size="large" color="accent-400" name={icon} />
                        <CheckBox
                            checked={isSelected}
                            appearance="toggle"
                            onChange={onCoverageSelectionChange}
                            data-e2e="coverage-toggle"
                            inline
                        />
                    </ColumnLayout>
                    <Text color="brand-400" style="heading 5">
                        {title}
                    </Text>
                    <Text color="ui-500" style="body 1">
                        {description}
                    </Text>
                </StackLayout>
                <BoxLayout gap="12" />
            </BoxLayout>
        </CardLayout>
    );
};
