import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { Nullable } from '@embroker/shotwell/core/types';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Joi, defineValidator } from '@embroker/shotwell/core/validation/schema';
import { Policy } from '../../entities/Policy';
import { WritingCompany } from '../../entities/WritingCompany';
import { GetWritingCompanyListError, OneOrMoreWritingCompanyNotFound } from '../../errors';
import { LobType } from '../../types/Lob';
import { SignatureRequest } from '../../types/SignatureRequest';

export interface UploadPolicyRequest {
    fileKey: string;
    agreementId: Nullable<UUID>;
    lineOfBusiness: Nullable<LobType>;
    unprocessedPolicyName: string;
    isFromOnboarding: Nullable<boolean>;
}

export const UploadPolicyRequest = {
    ...defineValidator<UploadPolicyRequest>({
        fileKey: Joi.string(),
        agreementId: UUID.schema.allow(null),
        lineOfBusiness: LobType.schema.allow(null),
        unprocessedPolicyName: Joi.string(),
        isFromOnboarding: Joi.bool().allow(null),
    }),
    create(uploadPolicyRequest: UploadPolicyRequest) {
        return UploadPolicyRequest.validate(uploadPolicyRequest);
    },
};

export interface UploadExtractPDFResponse {
    taskId: UUID;
}

export const UploadExtractPDFResponse = {
    ...defineValidator<UploadExtractPDFResponse>({
        taskId: UUID.schema,
    }),
    create(uploadExtractPDFResponse: UploadExtractPDFResponse) {
        return UploadExtractPDFResponse.validate(uploadExtractPDFResponse);
    },
};

export interface BORCreateTransferRequestResponse {
    clientId: string;
    signatureRequestList: SignatureRequest[];
}

export const BORCreateTransferRequestResponse = {
    ...defineValidator<BORCreateTransferRequestResponse>({
        clientId: Joi.string(),
        signatureRequestList: Joi.array().items(SignatureRequest.schema),
    }),
    create(borCreateTransferRequestResponse: BORCreateTransferRequestResponse) {
        return BORCreateTransferRequestResponse.validate(borCreateTransferRequestResponse);
    },
};

export interface UploadUrlResponse {
    url: string;
    file_key: string;
}

export const UploadUrlResponse = {
    ...defineValidator<UploadUrlResponse>({
        url: Joi.string(),
        file_key: Joi.string(),
    }),
    create(uploadUrlResponse: UploadUrlResponse) {
        return UploadUrlResponse.validate(uploadUrlResponse);
    },
};

export interface CreatePolicyFromPDFRequest {
    activity_id: string;
    file_key: string;
}

export const CreatePolicyFromPDFRequest = {
    ...defineValidator<CreatePolicyFromPDFRequest>({
        activity_id: Joi.string(),
        file_key: Joi.string(),
    }),
    create(createPolicyFromPDFRequest: CreatePolicyFromPDFRequest) {
        return CreatePolicyFromPDFRequest.validate(createPolicyFromPDFRequest);
    },
};

export interface GetTaskStatusResponse {
    data: any;
    error: any;
}

export const GetTaskStatusResponse = {
    ...defineValidator<GetTaskStatusResponse>({
        data: Joi.any(),
        error: Joi.any(),
    }),
    create(getTaskStatusResponse: GetTaskStatusResponse) {
        return GetTaskStatusResponse.validate(getTaskStatusResponse);
    },
};

export interface CreatePolicyFromPDFResponse {
    task_id: UUID;
}

export const CreatePolicyFromPDFResponse = {
    ...defineValidator<CreatePolicyFromPDFResponse>({
        task_id: UUID.schema,
    }),
    create(createPolicyFromPDFResponse: CreatePolicyFromPDFResponse) {
        return CreatePolicyFromPDFResponse.validate(createPolicyFromPDFResponse);
    },
};

export interface PolicyRepository {
    getPolicy(
        policyId: UUID,
    ): AsyncResult<Policy, UnknownEntity | InvalidArgument | OperationFailed>;
    getPolicies(): AsyncResult<Policy[], InvalidArgument | OperationFailed>;
    getWritingCompanyList(
        insurerIdList: UUID[],
    ): AsyncResult<WritingCompany[], GetWritingCompanyListError | OneOrMoreWritingCompanyNotFound>;
    getUnprocessedPolicies(): AsyncResult<string[], InvalidArgument | OperationFailed>;
    getPolicySeries(
        seriesId: UUID,
    ): AsyncResult<Policy[], UnknownEntity | InvalidArgument | OperationFailed>;
    getPolicyTransferRequestSignedDate(
        policyId: UUID,
    ): AsyncResult<Nullable<Date>, InvalidArgument | OperationFailed>;
    borCreateTransferRequest(
        insurerIdList: UUID[],
    ): AsyncResult<BORCreateTransferRequestResponse, InvalidArgument | OperationFailed>;
    borSendTransferRequest(
        signatureRequestIdList: string[],
    ): AsyncResult<void, InvalidArgument | OperationFailed>;
    policyUpload(
        policyUploadRequest: UploadPolicyRequest,
    ): AsyncResult<Nullable<UUID>, InvalidArgument | OperationFailed>;
    policyUploadExtractPDF(
        fileKey: string,
    ): AsyncResult<UploadExtractPDFResponse, InvalidArgument | OperationFailed>;
    uploadUrl(): AsyncResult<UploadUrlResponse, InvalidArgument | OperationFailed>;
    createPolicyFromPDF(
        createPolicyFromPDFRequest: CreatePolicyFromPDFRequest,
    ): AsyncResult<CreatePolicyFromPDFResponse, InvalidArgument | OperationFailed>;
    getTaskStatus(id: UUID): AsyncResult<GetTaskStatusResponse, InvalidArgument | OperationFailed>;
    downloadUrl(file_key: string): AsyncResult<URI, InvalidArgument | OperationFailed>;
    getClientPreviousPolicy(token: string): AsyncResult<Policy, InvalidArgument | OperationFailed>;
}

export const PolicyRepository = Symbol('PolicyRepository');
