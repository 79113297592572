import { ListOptions } from '@embroker/shotwell-api/app';
import { AppTypeCodeList, AppTypeCodeListItem } from '@embroker/shotwell-api/enums';
import {
    Aborted,
    InvalidArgument,
    OperationFailed,
    UnknownEntity,
} from '@embroker/shotwell/core/Error';
import { Data, Immutable, Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Policy } from '../../../policy/entities/Policy';
import { Application } from '../../entities/Application';
import {
    AppTypeNotAllowedForBroker,
    AppTypeNotSupported,
    ApplicationNotFound,
    EnricherAPIError,
    NameClearanceError,
    NoEligibleAppTypeFound,
    OperationNotAllowed,
    OrganizationNotFound,
    PrecreateApplicationError,
    ShoppingCoverageNotProvided,
    ShoppingCoverageNotSupportedForAppType,
    StreamlineRenewalNotEligible,
} from '../../errors';
import { EnrichmentData } from '../../types/EnrichmentData';
import { ShoppingCoverageCodeArray } from '../../types/ShoppingCoverageCodeArray';
import { AppTypeCode, EspIneligibilityReason } from '../../types/enums';

export interface GetConfigResponse {
    readonly isBOPEnabled: boolean;
    readonly isBOPChubbEnabled: boolean;
    readonly isWCGAEnabled: boolean;
    readonly isMPLWCEnabled: boolean;
    readonly isCowbellCyberEnabled: boolean;
    readonly isPCOMLEnabled: boolean;
    readonly isLPLEnabled: boolean;
    readonly isEmbrokerCrimeEnabled: boolean;
    readonly isEmbrokerCyberEnabled: boolean;
    readonly isEmbrokerExcessEnabled: boolean;
    readonly isMPLEnabled: boolean;
    readonly isHomeInspectorsMPLVerticalEnabled: boolean;
    readonly isRealEstateAgentsMPLVerticalEnabled: boolean;
    readonly isWCChubbEnabled: boolean;
}

export interface UpdateQuestionnaireRequest {
    applicationId: UUID;
    isPrefill: Nullable<boolean>;
    questionnaireData: string;
}

export interface CreateApplicationsRequest {
    readonly appTypeList: Immutable<AppTypeCode[]>;
    readonly questionnaireData: string;
}

export interface CreateApplicationsResponse {
    taskId: UUID;
    applicationList: Array<Application>;
    nameCleared: boolean;
}

export interface PrecreateApplicationRequest {
    readonly appType: Immutable<AppTypeCodeListItem>;
    readonly shoppingCoverageCodeList: Immutable<ShoppingCoverageCodeArray>;
    readonly questionnaireData: string;
}

export interface PrecreateApplicationResponse {
    applicationId: UUID;
}

export interface IsESPEligibleResponse {
    readonly isEligible: boolean;
    readonly ineligibilityReasons: EspIneligibilityReason[];
    readonly isSplittable: boolean;
}

export interface GetApplicationWithQuoteDetailsResponse {
    application: Application;
    totalPremium: Nullable<Money>;
}

export interface GetApplicationLastPageRepoRequest {
    userId: UUID;
    applicationId: UUID;
}
export interface SaveApplicationLastPageRepoRequest {
    userId: UUID;
    applicationId: UUID;
    lastPage: string;
}

export interface IntakeAdditionalInfoData {
    isDnoSelected: boolean;
    dnoRetention?: number;
    dnoLimit?: number;
    isEplSelected: boolean;
    eplRetention?: number;
    eplLimit?: number;
    isFiduciarySelected: boolean;
    fiduciaryRetention?: number;
    fiduciaryLimit?: number;
    isEnoSelected: boolean;
    enoRetention?: number;
    enoLimit?: number;
    cyberCrimeRetention?: number;
    cyberCrimeLimit?: number;
    lplLimit?: number;
    lplAggregateLimit?: number;
    lplDeductible?: number;
    isLplFirstDollarDeductible?: boolean;
    lplAdditionalClaimsOption?: string;
    lplRetroactiveDate?: Date;
    effectiveDate: Date;
    additionalInfo?: string;
}

export interface IntakeAdditionalInfoRequest {
    appType: AppTypeCode;
    additionalFiles: Array<string>;
    additionalData: IntakeAdditionalInfoData;
    applicationId: UUID;
}

export interface GetApplicationListResponse {
    applicationList: Array<Application>;
    pageInfo: PageInfo;
}

export interface GetClientApplicationResponse {
    currentApplication: Application;
    previousApplication?: Application;
    previousPolicy?: Policy;
}

export interface PageInfo {
    totalItems: number;
    size: number;
    index: number;
}

export interface CreateApplicationIntakeTaskRequest {
    appType: string;
    shoppingCoverageList: ShoppingCoverageCodeArray;
    fileUrl: string;
    applicationId?: UUID;
}

export interface CreateApplicationIntakeTaskResponse {
    taskId: UUID;
    applicationId: UUID;
}

export interface SignatureData {
    signatureLocalUtcOffset: string;
    signatureDateNow: string;
    signatureDate: string;
}
export interface ApplicationRepository {
    getApplication(id: UUID): AsyncResult<Application, InvalidArgument | OperationFailed>;
    getApplicationList(
        listOptions?: ListOptions,
        abortSignal?: AbortSignal,
        sectionType?: String,
    ): AsyncResult<
        GetApplicationListResponse,
        InvalidArgument | OperationFailed | UnknownEntity | Aborted
    >;
    getConfig(): AsyncResult<GetConfigResponse, InvalidArgument | OperationFailed | UnknownEntity>;
    deleteApplication(
        id: UUID,
    ): AsyncResult<void, InvalidArgument | OperationFailed | UnknownEntity>;
    startPrinting(id: UUID): AsyncResult<UUID, UnknownEntity>;
    getPrefill(): AsyncResult<Data, InvalidArgument | OperationFailed | UnknownEntity>;
    createApplications(
        request: CreateApplicationsRequest,
    ): AsyncResult<
        CreateApplicationsResponse,
        | InvalidArgument
        | OperationFailed
        | UnknownEntity
        | AppTypeNotAllowedForBroker
        | NoEligibleAppTypeFound
    >;
    createApplicationIntakeTask(
        request: CreateApplicationIntakeTaskRequest,
    ): AsyncResult<CreateApplicationIntakeTaskResponse, InvalidArgument | OperationFailed>;
    submitApplication(
        id: UUID,
        userTzOffsetMinutes: number,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed>;
    updateQuestionnaireData(
        request: UpdateQuestionnaireRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed>;
    getApplicationWithQuoteDetails(
        id: UUID,
    ): AsyncResult<
        GetApplicationWithQuoteDetailsResponse,
        InvalidArgument | OperationFailed | UnknownEntity
    >;
    getApplicationLastPage(
        requestBody: GetApplicationLastPageRepoRequest,
    ): AsyncResult<{ last_page: string }, InvalidArgument | OperationFailed | UnknownEntity>;
    saveApplicationLastPage(
        requestBody: SaveApplicationLastPageRepoRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed | UnknownEntity>;
    intakeAdditionalInfo(requestBody: IntakeAdditionalInfoRequest): AsyncResult<void>;
    getClientApplication(
        token: string,
    ): AsyncResult<
        GetClientApplicationResponse,
        ApplicationNotFound | OperationNotAllowed | OperationFailed
    >;
    updateClientApplication(
        token: string,
        questionnaireData: string,
    ): AsyncResult<void, ApplicationNotFound | OperationNotAllowed | OperationFailed>;
    editClientApplication(
        token: string,
        questionnaireData: string,
    ): AsyncResult<void, ApplicationNotFound | OperationNotAllowed | OperationFailed>;
    getClientQuestionnairePrefill(
        token: string,
    ): AsyncResult<Data, ApplicationNotFound | InvalidArgument | OperationFailed>;
    completeShareableApplication(
        token: string,
    ): AsyncResult<void, ApplicationNotFound | OperationNotAllowed | OperationFailed>;
    promoteShareableApplication(
        token: string,
        questionnaireData: string,
    ): AsyncResult<
        void,
        OperationFailed | ApplicationNotFound | OperationNotAllowed | NameClearanceError
    >;
    promoteApplication(
        id: UUID,
        questionnaireData: string,
    ): AsyncResult<
        void,
        | OperationFailed
        | ApplicationNotFound
        | OperationNotAllowed
        | NameClearanceError
        | AppTypeNotAllowedForBroker
    >;
    getApplicationShareToken(id: UUID): AsyncResult<string, InvalidArgument | OperationFailed>;
    getEnricherData(appId: UUID): AsyncResult<EnrichmentData, EnricherAPIError>;
    precreateApplication(
        request: PrecreateApplicationRequest,
    ): AsyncResult<
        PrecreateApplicationResponse,
        | PrecreateApplicationError
        | ShoppingCoverageNotSupportedForAppType
        | OrganizationNotFound
        | AppTypeNotSupported
        | ShoppingCoverageNotProvided
    >;
    printBinder(appId: UUID): AsyncResult<URI, OperationFailed>;
    optOutFromStreamlineRenewal(
        appId: UUID,
    ): AsyncResult<void, ApplicationNotFound | OperationFailed>;
    getPartialEligibility(applicationId: UUID): AsyncResult<AppTypeCodeList, OperationFailed>;

    submitStreamlineRenewal(
        id: UUID,
        userTzOffsetMinutes: number,
        signatureData: SignatureData,
    ): AsyncResult<
        Nullable<UUID>,
        StreamlineRenewalNotEligible | InvalidArgument | OperationFailed
    >;
    recreateRenewal(applicationId: UUID): AsyncResult<UUID, ApplicationNotFound | OperationFailed>;
}

export const ApplicationRepository = Symbol('ApplicationRepository');
