import { Immutable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import {
    ESPCyberCoverageQuoteOptionsItem,
    ESPDoEpliCoverageQuoteOptionsItem,
    ESPDoEpliCoverageQuoteOptionsItemSchema,
    ESPEoCyberCoverageQuoteOptionsItem,
    ESPEoCyberCoverageQuoteOptionsItemSchema,
    ESPFiduciaryCoverageQuoteOptionsItem,
    ESPFiduciaryCoverageQuoteOptionsItemSchema,
    ESPQuoteOptions,
    ESPTechCyberCoverageQuoteOptionsItem,
    ESPTechCyberCoverageQuoteOptionsItemSchema,
} from './ESPQuoteOptions';
import { ESPTotalsAndDiscounts } from './ESPTotalsAndDiscounts';

export interface ESPDoEpliCoverageRateItem extends ESPDoEpliCoverageQuoteOptionsItem {
    readonly premium: Money;
}

export const ESPDoEpliCoverageRateItemSchema = ESPDoEpliCoverageQuoteOptionsItemSchema.append({
    premium: Money.schema.required(),
});

export interface ESPFiduciaryCoverageRateItem extends ESPFiduciaryCoverageQuoteOptionsItem {
    readonly premium: Money;
}

export const ESPFiduciaryCoverageRateItemSchema = ESPFiduciaryCoverageQuoteOptionsItemSchema.append(
    {
        premium: Money.schema.required(),
    },
);

export interface ESPEoCyberCoverageRateItem extends ESPEoCyberCoverageQuoteOptionsItem {
    readonly premium: Money;
}

export interface ESPTechCyberCoverageRateItem extends ESPTechCyberCoverageQuoteOptionsItem {
    readonly premium: Money;
}

export const ESPTechCyberCoverageRateItemSchema = ESPTechCyberCoverageQuoteOptionsItemSchema.append(
    {
        premium: Money.schema.required(),
    },
);

export interface ESPCyberCoverageRateItem extends ESPCyberCoverageQuoteOptionsItem {
    readonly premium: Money;
}

export const ESPEoCyberCoverageRateItemSchema = ESPEoCyberCoverageQuoteOptionsItemSchema.append({
    premium: Money.schema.required(),
});

export type ESPCoverageRateItem =
    | ESPDoEpliCoverageRateItem
    | ESPFiduciaryCoverageRateItem
    | ESPEoCyberCoverageRateItem
    | ESPTechCyberCoverageRateItem;

export interface ESPRate extends ESPQuoteOptions {
    readonly coverages: Immutable<Array<ESPCoverageRateItem>>;
    readonly rates: ESPTotalsAndDiscounts;
}

export const ESPRate = {
    ...defineValidator<ESPRate>(
        ESPQuoteOptions.schema.keys({
            coverages: Joi.array()
                .unique((a: ESPCoverageRateItem, b: ESPCoverageRateItem): boolean => {
                    return a.coverageType === b.coverageType;
                })
                .items(
                    ESPDoEpliCoverageRateItemSchema,
                    ESPFiduciaryCoverageRateItemSchema,
                    ESPEoCyberCoverageRateItemSchema,
                    ESPTechCyberCoverageRateItemSchema,
                )
                .min(1)
                .max(5),
            rates: ESPTotalsAndDiscounts.schema.required(),
        }),
    ),
    create(espRate: ESPRate) {
        return ESPRate.validate(espRate);
    },
};
