import React from 'react';
import {
    BorderStyle,
    Card,
    CheckBox,
    ColumnLayout,
    Icon,
    IconName,
    StackLayout,
    StatusType,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';

export enum RecommendationType {
    none = 'none',
    essential = 'essential',
    popular = 'popular',
}

interface RecommendationTypeStyle {
    borderStyle: BorderStyle;
    statusLabelText: string;
    statusLabelType: StatusType;
}

const RECOMMENDATION_TYPE_STYLES: Record<RecommendationType, RecommendationTypeStyle | null> = {
    none: null,
    essential: { borderStyle: 'brand-400', statusLabelText: 'ESSENTIAL', statusLabelType: 'blue' },
    popular: {
        borderStyle: 'accent-500',
        statusLabelText: 'POPULAR',
        statusLabelType: 'purple-dark',
    },
};

interface CoverageRecommendationCardProps {
    recommendationType: RecommendationType;
    iconName: IconName;
    title: string;
    children: JSX.Element;
    className?: string;
    isRequired?: boolean;
    isDisabled?: boolean;
    disabledText?: string;
    isSelected?: boolean;
    showToggle?: boolean;
    onTitleTooltipClick?: () => void;
    onCoverageSelectionChange?: () => void;
}

export function CoverageRecommendationCard({
    recommendationType,
    iconName,
    title,
    children,
    isDisabled = false,
    disabledText,
    isSelected,
    isRequired,
    showToggle = true,
    className,
    onTitleTooltipClick,
    onCoverageSelectionChange,
}: CoverageRecommendationCardProps) {
    const recommendationTypeStyle = RECOMMENDATION_TYPE_STYLES[recommendationType];
    const canToggle = showToggle && !isDisabled && !isRequired;
    return (
        <Card
            appearance="box"
            borderRadius="16"
            borderStyle={recommendationTypeStyle?.borderStyle ?? 'none'}
            statusLabel={recommendationTypeStyle?.statusLabelText}
            statusLabelPosition="center"
            statusLabelType={recommendationTypeStyle?.statusLabelType}
            className={className}
        >
            <StackLayout gap="24">
                <StackLayout gap="16">
                    <StackLayout gap="8">
                        <ColumnLayout
                            top
                            split={canToggle || (isDisabled && disabledText) ? '-1' : undefined}
                        >
                            <Icon
                                size="large"
                                color={isDisabled ? 'ui-300' : 'accent-400'}
                                name={iconName}
                            />
                            {isDisabled && disabledText && (
                                <Text color="ui-400" style="body 1">
                                    {disabledText}
                                </Text>
                            )}
                            {canToggle && (
                                <CheckBox
                                    checked={isSelected}
                                    appearance="toggle"
                                    onChange={onCoverageSelectionChange}
                                    data-e2e="coverage-toggle"
                                    inline
                                />
                            )}
                        </ColumnLayout>
                        <ColumnLayout center>
                            <Text color={isDisabled ? 'ui-400' : 'brand-400'} style="heading 5">
                                {title}
                            </Text>
                            {!!onTitleTooltipClick && (
                                <TextButton icon="info-outline" onClick={onTitleTooltipClick} />
                            )}
                        </ColumnLayout>
                    </StackLayout>
                    {children}
                </StackLayout>
            </StackLayout>
        </Card>
    );
}
