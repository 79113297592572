import React, { SyntheticEvent } from 'react';
import { FormFieldView } from './FormFieldView.view';
import { Button, ButtonBar, Form, StackLayout, Text, TextButton } from '@embroker/ui-toolkit/v2';
import { FormFieldViewDefinition } from './DataDrivenForm';
import { FieldSetView } from './FieldSetView.view';
import { getQuestionsForFieldSet, isFieldSet, isStandAloneQuestion } from '../types/FieldSetType';

export interface FormViewProps {
    submitText: string;
    currentPageTitle?: string;
    currentPageQuestions: FormFieldViewDefinition[];
    hasPrevious: boolean;
    handleSubmit: (e: SyntheticEvent) => void;
    handleBack: (e: SyntheticEvent) => void;
    handleComplexFieldChange: (questionKey: string, value: unknown) => void;
}

export function FormView(props: FormViewProps) {
    const {
        currentPageTitle,
        currentPageQuestions: questions,
        hasPrevious,
        submitText,
        handleComplexFieldChange,
        handleBack,
        handleSubmit,
    } = props;
    return (
        <Form noValidate onSubmit={handleSubmit}>
            <StackLayout gap="48">
                <Text style="heading 3">{currentPageTitle}</Text>
                <StackLayout gap="32">
                    {questions.map((formFieldView: FormFieldViewDefinition) => {
                        const { key, staticOptions } = formFieldView.questionProps;
                        const fieldSetProps = staticOptions?.fieldSetProps;

                        if (isFieldSet(formFieldView) && fieldSetProps) {
                            return (
                                <FieldSetView
                                    {...fieldSetProps}
                                    questions={getQuestionsForFieldSet(formFieldView, questions)}
                                    handleComplexFieldChange={handleComplexFieldChange}
                                />
                            );
                        }

                        if (!isStandAloneQuestion(key, questions)) {
                            // If the question is part of a field set, it will be rendered within FieldSetView, not as a stand-alone question
                            return null;
                        }

                        return (
                            <FormFieldView
                                key={key}
                                {...formFieldView}
                                onComplexFieldChange={handleComplexFieldChange}
                            />
                        );
                    })}
                </StackLayout>
                <ButtonBar
                    responsive={{ containerWidth: { smallerThan: 'large-mobile' } }}
                    split={'-1'}
                    center
                >
                    {hasPrevious && (
                        <TextButton
                            icon="thin-arrow-left"
                            iconPosition="left"
                            iconSize="medium"
                            disabled={false}
                            onClick={handleBack}
                        >
                            Back
                        </TextButton>
                    )}
                    <Button
                        icon="thin-arrow-right"
                        iconPosition="right"
                        iconSize="medium"
                        type="submit"
                        disabled={false}
                    >
                        {submitText}
                    </Button>
                </ButtonBar>
            </StackLayout>
        </Form>
    );
}
