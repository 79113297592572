import {
    PublishQuoteUserClickEvent,
    QuoteUserEventEnum,
} from '@app/quote/useCases/PublishQuoteUserClickEvent';
import { ModalLayout } from '@app/view/components/ModalLayout.view';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Modal, ModalActions, ModalState, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React, { useEffect } from 'react';

interface ConfirmSocialEngineeringCoverageModalProps {
    modal: ModalState & ModalActions;
}

export function ConfirmSocialEngineeringCoverageModal({
    modal,
}: ConfirmSocialEngineeringCoverageModalProps) {
    useEffect(() => {
        execute(PublishQuoteUserClickEvent, {
            clickEventName: QuoteUserEventEnum.pageLoaded,
        });
    }, []);

    const handlePrimaryAction = () => {
        execute(PublishQuoteUserClickEvent, {
            clickEventName: QuoteUserEventEnum.addCyberAfterCheckoutClicked,
        });
        modal.hide();
    };

    const handleSecondaryAction = () => {
        execute(PublishQuoteUserClickEvent, {
            clickEventName: QuoteUserEventEnum.noThanksClicked,
        });
        modal.hide();
    };
    const handleDismiss = () => {
        execute(PublishQuoteUserClickEvent, {
            clickEventName: QuoteUserEventEnum.outsideModalClicked,
        });
    };
    const handleClose = () => {
        execute(PublishQuoteUserClickEvent, {
            clickEventName: QuoteUserEventEnum.exitCtaClicked,
        });
    };

    return (
        <Modal {...modal} dismissable onDismiss={handleDismiss} onClose={handleClose}>
            <ModalLayout
                title="Protect your firm against social engineering fraud."
                primaryAction={{
                    label: 'Add Cyber After Checkout',
                    onClick: handlePrimaryAction,
                }}
                secondaryAction={{
                    label: 'No, Thanks',
                    onClick: handleSecondaryAction,
                }}
            >
                <StackLayout>
                    <Text>
                        Your Lawyers Professional Liability (LPL) policy does not cover losses from
                        social engineering scams, in which an employee is deceived into sending
                        money or data to fraudsters posing as trusted contacts.
                    </Text>
                    <Text>
                        Cyber insurance can protect you against these scams and other risks.
                    </Text>
                    <Text>
                        Would you like to add Cyber insurance after purchasing your LPL policy? It's
                        fast and easy to get comprehensive coverage today!
                    </Text>
                </StackLayout>
            </ModalLayout>
        </Modal>
    );
}
