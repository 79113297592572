//
// InterValidation definition
// Refine back-end data contract.
// https://embroker.atlassian.net/browse/EM-43445
import { Joi } from '@embroker/shotwell/core/validation/schema';

export interface InterValidation {
    external_question_key: string;
    external_answer_field: string;
    internal_answer_field: string;
    operator: string;
    error_message: string;
}
export const InterValidationSchema = Joi.object({
    error_message: Joi.string().allow('').required(),
    external_answer_field: Joi.string().allow('').required(),
    external_question_key: Joi.string().allow('').required(),
    internal_answer_field: Joi.string().allow('').required(),
    operator: Joi.string().allow('').required(),
});
