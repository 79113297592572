import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    BoxLayout,
    Card,
    ColumnLayout,
    Form,
    Icon,
    Modal,
    ReactProps,
    StackLayout,
    Text,
    TextButton,
    useModal,
    RadioGroup,
} from '@embroker/ui-toolkit/v2';
import React, { useCallback, useContext } from 'react';
import { AppTypeLabelMap } from '../../../../shopping/types/enums';
import { hasRole } from '../../../../userOrg/entities/Session';
import { AppContext } from '../../../../view/AppContext';
import { WizardForm } from '../../../../view/hooks/useWizardForm';
import { EffectiveDate } from '../../../types/EffectiveDate';
import { CyberQuote } from '../../entities/CyberQuote';
import { useDevModeToday } from '../hooks/useDevModeToday';
import { CyberQuoteOptionsFormData } from './createCyberQuoteOptionsForm';
import { getLimitRetentionOptions, getCyberRetentionOptions } from './limitRentetionOptions';
import { CyberDifferenceModal } from './CyberDifferenceModal';
import { CyberLevel } from '../../types/CyberQuoteOptions';
import { Money } from '@embroker/shotwell/core/types/Money';

interface CyberQuoteStepProps extends ReactProps<'div'> {
    quote: CyberQuote;
    fields: WizardForm<OpaqueForm<CyberQuoteOptionsFormData>>['fields'];
    trigger: WizardForm<OpaqueForm<CyberQuoteOptionsFormData>>['trigger'];
    setValue: WizardForm<OpaqueForm<CyberQuoteOptionsFormData>>['setValue'];
    value: WizardForm<OpaqueForm<CyberQuoteOptionsFormData>>['value'];
    isSubmitting: boolean;
}

const DATE_FORMAT = 'MM/dd/yyyy';
const MAX_FUTURE_DAYS_ALLOWED = 60;

export function CyberCoverage({
    quote,
    trigger,
    setValue,
    fields,
    value,
    isSubmitting,
    className,
}: CyberQuoteStepProps) {
    const { activeSession } = useContext(AppContext);
    const isAdmin = hasRole(activeSession, 'admin');
    const isRenewal = quote.isRenewal;
    const cyberLimitOptions = getLimitRetentionOptions(quote.options.previousLimit).limits;
    const cyberRetentionOptions = getCyberRetentionOptions();
    const cyberDifferenceModal = useModal();
    const previuosPremium = quote.details.previousPremium;

    const { result: devModeToday, isLoading: isLoadingDevModeToday } = useDevModeToday();

    const isEffectiveDateSelectable = useCallback(
        (effectiveDate: Date) =>
            EffectiveDate.isSelectedEffectiveDateValid(
                effectiveDate,
                devModeToday,
                MAX_FUTURE_DAYS_ALLOWED,
                isAdmin,
            ),
        [isAdmin, devModeToday],
    );

    if (isLoadingDevModeToday) {
        return null;
    }

    const handleDateChange = (event: { target: { value: string; date: Date } }) => {
        setValue({
            ...value,
            effectiveDate: event.target.date,
        });
    };

    const handleLevelChange = (event: any) => {
        const newLevelValue = event.target.value as CyberLevel;
        setValue({
            ...value,
            level: newLevelValue,
        });
    };

    const endDate = EffectiveDate.calculateCoverageEndDate(value.effectiveDate);

    const limitField = quote.options.limit;
    const previousLimitField = quote.options.previousLimit;
    const retentionField = quote.options.retention;
    const previousRetentionField = quote.options.previousRetention;

    if (!limitField || !retentionField) {
        return null;
    }

    return (
        <StackLayout gap="32" className={className}>
            <Text style="heading 4">When do you want your coverage to begin?</Text>
            <ColumnLayout gap="none" center>
                <Form.Field
                    className="u-1/3@desktop u-1/2@tablet u-1/2"
                    type={fields.effectiveDate.type}
                    inputProps={{
                        ...fields.effectiveDate.props,
                        onChange: handleDateChange,
                        disabled: isSubmitting || isRenewal,
                        isSelectable: isEffectiveDateSelectable,
                    }}
                    messages={fields.effectiveDate.messages}
                />
                <BoxLayout>
                    <Text style="heading 5" data-e2e="end-date">
                        &ndash;{' '}
                        {endDate ? <DateDisplay format={DATE_FORMAT} value={endDate} /> : null}
                    </Text>
                </BoxLayout>
            </ColumnLayout>
            <Card isSelected={false} locked={false} data-e2e="cyber-card">
                <Card.Header data-e2e="cyber-card-header">
                    <Icon name="thief" />
                    <Text style="heading 5">{AppTypeLabelMap['AppTypeCodeListEmbrokerCyber']}</Text>
                </Card.Header>
                <Card.Body data-e2e="cyber-card-body">
                    <StackLayout>
                        <Text>
                            Covers the loss of money, securities, or tangible property, directly
                            caused by cybers, such as employee theft, robbery, forgery, and certain
                            types of computer fraud.
                        </Text>
                        <ColumnLayout
                            gap="16"
                            responsive={{ screenWidth: { smallerThan: 'tablet' } }}
                        >
                            <Form.Field
                                type={fields.limit.type}
                                inputProps={{
                                    ...fields.limit.props,
                                    filterable: false,
                                    items: cyberLimitOptions,
                                    label: 'Limit',
                                    disabled: isSubmitting,
                                }}
                                data-e2e="cyber-limit"
                            />
                            <Form.Field
                                type={fields.retention.type}
                                inputProps={{
                                    ...fields.retention.props,
                                    filterable: false,
                                    items: cyberRetentionOptions,
                                    label: 'Retention',
                                    disabled: isSubmitting,
                                }}
                                data-e2e="cyber-retention"
                            />
                        </ColumnLayout>
                        {isRenewal && (
                            <ColumnLayout>
                                <BoxLayout gap="12" className="u-1/2">
                                    <StackLayout gap="none">
                                        <Text style="microcopy">Previous Limit:</Text>
                                        <Text style="body 1" color="brand-500">
                                            {previousLimitField && (
                                                <MoneyDisplay
                                                    value={Money.tryFromFloat(previousLimitField)}
                                                    fractionDigits={0}
                                                />
                                            )}
                                        </Text>
                                    </StackLayout>
                                </BoxLayout>
                                <BoxLayout gap="12" className="u-1/2">
                                    <StackLayout gap="none">
                                        <Text style="microcopy">Previous Retention</Text>
                                        <Text style="body 1" color="brand-500">
                                            {previousRetentionField && (
                                                <MoneyDisplay
                                                    value={Money.tryFromFloat(
                                                        previousRetentionField,
                                                    )}
                                                    fractionDigits={0}
                                                />
                                            )}
                                        </Text>
                                    </StackLayout>
                                </BoxLayout>
                            </ColumnLayout>
                        )}
                        <React.Fragment>
                            <ColumnLayout split="-1">
                                <Text style="heading 5">Select your coverage </Text>
                                <TextButton onClick={cyberDifferenceModal.show}>
                                    What's the difference?
                                </TextButton>
                            </ColumnLayout>
                            <RadioGroup
                                id="level-id"
                                disabled={isSubmitting}
                                value={value.level}
                                items={[
                                    {
                                        value: 'plus',
                                        title: 'Plus',
                                    },
                                    {
                                        value: 'standard',
                                        title: 'Standard',
                                    },
                                ]}
                                onChange={handleLevelChange}
                            ></RadioGroup>
                        </React.Fragment>
                    </StackLayout>
                </Card.Body>
                <Card.Footer>
                    <Text as="span" style="body 1" color="brand-500">
                        Premium:{' '}
                        <Text as="span" style="heading 5">
                            <MoneyDisplay value={quote.details.totalBasePremium} />
                        </Text>
                        {previuosPremium && (
                            <Text style="body 1" color="brand-500">
                                Previous Premium:{' '}
                                <Text as="span" style="heading 5">
                                    <MoneyDisplay value={previuosPremium} />
                                </Text>
                            </Text>
                        )}
                    </Text>
                </Card.Footer>
                <Modal size="large" {...cyberDifferenceModal}>
                    <CyberDifferenceModal />
                </Modal>
            </Card>
        </StackLayout>
    );
}
