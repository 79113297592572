import { Loader, Modal, ModalActions, ModalState } from '@embroker/ui-toolkit/v2';
import React, { useState } from 'react';
import { ModalLayout } from '../../../view/components/ModalLayout.view';

interface ExitConfirmationModalProps {
    onExitApplication: () => void;
    modal: ModalState & ModalActions;
}

export function ExitConfirmationModal(props: ExitConfirmationModalProps) {
    const [isWaiting, setIsWaiting] = useState(false);

    const handleSaveAndExit = () => (setIsWaiting(true), props.onExitApplication());

    const leavingApplicationModal = (
        <Modal dismissable={false} {...props.modal}>
            <ModalLayout
                title="Are you sure you want to leave?"
                primaryAction={{
                    label: 'Save and Exit',
                    onClick: handleSaveAndExit,
                }}
                secondaryAction={{
                    label: 'Cancel',
                    onClick: props.modal.hide,
                }}
            >
                We know that sometimes you just don't have the time. Exit will save your progress so
                you can easily pick up right where you left off.
            </ModalLayout>
        </Modal>
    );

    return isWaiting ? <Loader variant="finalizing" /> : leavingApplicationModal;
}
