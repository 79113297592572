import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Immutable } from '@embroker/shotwell/core/types';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import {
    BoxLayout,
    CardLayout,
    CenterLayout,
    ColumnLayout,
    CoverLayout,
    InvoiceTable,
    StackLayout,
    Text,
    TextButton,
    Button,
    ButtonBar,
} from '@embroker/ui-toolkit/v2';
import React, { useContext } from 'react';
import { Invoice } from '../../../entities/Invoice';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { GetConfig } from '../../../useCases/GetConfig';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { ContinueToAscendButton } from '../PaymentsDue';
import { AppContext } from '../../../../view/AppContext';
import { hasRole } from '../../../../userOrg/entities/Session';

interface InvoiceInfoData {
    invoice: Immutable<EntityProps<Invoice>>;
    showACHModal(): void;
    showCCModal(): void;
}

export function InvoiceInfo(props: InvoiceInfoData) {
    const invoice = props.invoice;
    const { result: config } = useUseCase(GetConfig);

    const { activeSession } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');

    let useAscend = false;
    if (activeSession.isAuthenticated && !isBroker && config !== undefined && isOK(config)) {
        useAscend = config.value?.useAscend;
    }

    return (
        <CoverLayout>
            <CardLayout>
                <BoxLayout gap="24">
                    <Text style="heading 4">
                        Amount due for {invoice.billingName}:{' '}
                        <MoneyDisplay value={invoice.balance} />
                    </Text>
                </BoxLayout>
                <InvoiceTable.Separator />
                <BoxLayout gap="24">
                    <ColumnLayout gap="64">
                        <StackLayout gap="none">
                            <Text style="overline" color="brand-500">
                                Policy type
                            </Text>
                            <Text style="default" color="brand-500">
                                {invoice.lineOfBusiness}
                            </Text>
                        </StackLayout>
                        <StackLayout gap="none">
                            <Text style="overline" color="brand-500">
                                Policy No.
                            </Text>
                            <Text style="default" color="brand-500">
                                {invoice.policyNumber}
                            </Text>
                        </StackLayout>
                        <StackLayout gap="none">
                            <Text style="overline" color="brand-500">
                                Effective
                            </Text>
                            <Text style="default" color="brand-500">
                                <DateDisplay
                                    format="MMMM d, yyyy"
                                    value={invoice.policyEffectiveDate}
                                />
                            </Text>
                        </StackLayout>
                    </ColumnLayout>
                </BoxLayout>
                <BoxLayout gap="24">
                    <ColumnLayout gap="64">
                        <StackLayout gap="none">
                            <Text style="overline" color="brand-500">
                                Description
                            </Text>
                            <Text style="default" color="brand-500">
                                {invoice.description}
                            </Text>
                        </StackLayout>
                        <StackLayout gap="none">
                            <Text style="overline" color="brand-500">
                                Amount
                            </Text>
                            <Text style="default" color="brand-500">
                                <MoneyDisplay value={invoice.balance} />
                            </Text>
                        </StackLayout>
                    </ColumnLayout>
                </BoxLayout>
                <BoxLayout gap="24">
                    <Text style="microcopy">
                        Need help?{' '}
                        <TextButton size="small" href="mailto:support@embroker.com">
                            Get in touch.
                        </TextButton>
                    </Text>
                </BoxLayout>
                <InvoiceTable.Separator />
                <BoxLayout gap="24">
                    <CenterLayout>
                        {!useAscend && (
                            <ButtonBar>
                                <Button appearance="primary" onClick={props.showACHModal}>
                                    Pay With ACH
                                </Button>
                                <Button appearance="primary" onClick={props.showCCModal}>
                                    Pay With Credit Card
                                </Button>
                            </ButtonBar>
                        )}
                        {useAscend && (
                            <ContinueToAscendButton
                                invoiceIds={[invoice.id]}
                                organizationId={invoice.organizationId}
                            />
                        )}
                    </CenterLayout>
                </BoxLayout>
                <BoxLayout gap="24">
                    <CenterLayout>
                        <TextButton></TextButton>
                    </CenterLayout>
                </BoxLayout>
            </CardLayout>
        </CoverLayout>
    );
}
