import React, { useEffect, useState } from 'react';
import {
    ColumnLayout,
    Form,
    FormFieldProps as FormFieldPropsToolkit,
    StackLayout,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import { FormFieldView, FormFieldViewProps } from './FormFieldView.view';
import { LIST_INPUT_DELIMITER } from '../types/validationObject';

const DEFAULT_EMPTY_VALUE = '';

interface FieldMessageMap {
    [key: string]: string[] | null;
}

const getFieldMessageMap = (
    messages: FormFieldPropsToolkit['messages'],
): { [key: string]: string[] } => {
    return (messages || []).reduce((acc, message) => {
        const splitMessage = typeof message === 'string' ? message.split(LIST_INPUT_DELIMITER) : [];

        const [index, ...rest] = splitMessage;
        const isListItemMessage = !isNaN(parseInt(index, 10));

        // if the current message is not related to an index then return acc
        if (!isListItemMessage) {
            return acc;
        }

        if (!acc[index]) {
            acc[index] = [];
        }
        acc[index].push(rest.join(LIST_INPUT_DELIMITER));

        return acc;
    }, {} as { [key: string]: string[] });
};

const reorderErrors = (errorObj: FieldMessageMap, removedIndex: number): FieldMessageMap => {
    const newErrorObj: FieldMessageMap = {};

    for (const key in errorObj) {
        if (errorObj.hasOwnProperty(key)) {
            const index = parseInt(key, 10);
            if (index < removedIndex) {
                newErrorObj[index] = errorObj[key];
            } else if (index > removedIndex) {
                newErrorObj[index - 1] = errorObj[key];
            }
        }
    }

    return newErrorObj;
};

export interface MultipleFormFieldViewProps
    extends Omit<FormFieldViewProps, 'onComplexFieldChange'> {
    onFieldChange: (questionKey: string, value: unknown) => void;
}

export function assertInputValueAsUnknownArray(input: unknown): input is any[] {
    return Array.isArray(input);
}

export function MultipleFormFieldView(formFieldViewProps: MultipleFormFieldViewProps) {
    const { questionProps, inputFieldProps, onFieldChange } = formFieldViewProps;
    const { messages } = inputFieldProps;

    const [fieldMessageMap, setFieldMessageMap] = useState<FieldMessageMap>({});
    useEffect(() => {
        setFieldMessageMap(getFieldMessageMap(messages || []));
    }, [messages]);

    const listValues: unknown[] = assertInputValueAsUnknownArray(inputFieldProps.inputProps.value)
        ? inputFieldProps.inputProps.value
        : [DEFAULT_EMPTY_VALUE];

    const listGroupMessages = (messages || []).filter((message) => {
        return !(typeof message === 'string' && message.includes(LIST_INPUT_DELIMITER));
    });

    const formFieldProps = {
        title: questionProps.title,
        tooltip: questionProps.tooltip,
    };

    const handleChange = (value: unknown, index: number) => {
        const updatedFieldValue = [...listValues];
        updatedFieldValue[index] = value;
        onFieldChange(questionProps.key, updatedFieldValue);
    };

    const handleAddItem = () => {
        const updatedFieldValue = [...listValues];
        updatedFieldValue.push(DEFAULT_EMPTY_VALUE);
        onFieldChange(questionProps.key, updatedFieldValue);

        // Update fieldMessageMap to keep messages inline with inputs
        const newFieldMessageMap = { ...fieldMessageMap, [updatedFieldValue.length - 1]: null };
        setFieldMessageMap(newFieldMessageMap);
    };

    const handleRemoveItem = (index: number) => {
        const updatedFieldValue = [...listValues.slice(0, index), ...listValues.slice(index + 1)];
        onFieldChange(questionProps.key, updatedFieldValue);

        // Update fieldMessageMap to keep messages inline with inputs
        const newFieldMessageMap = reorderErrors(fieldMessageMap, index);
        setFieldMessageMap(newFieldMessageMap);
    };

    return (
        <Form.Field {...formFieldProps}>
            <StackLayout gap="16">
                {listValues.map((listItemValue: unknown, index: number) => {
                    const messageAtIndex = fieldMessageMap[index];
                    // if messageAtIndex is explicilty set as null, then do not show any messages
                    const groupMessages = messageAtIndex === null ? [] : listGroupMessages;
                    const itemMessages = messageAtIndex || groupMessages;
                    return (
                        <StackLayout key={index} gap="none">
                            <StackLayout gap="20">
                                {listValues.length > 1 && (
                                    <ColumnLayout split="-1">
                                        <TextButton onClick={() => handleRemoveItem(index)}>
                                            Remove
                                        </TextButton>
                                    </ColumnLayout>
                                )}
                                <FormFieldView
                                    questionProps={{
                                        ...formFieldViewProps.questionProps,
                                        isMultiple: false, // This ensures we do not enter a continuios loop of rendering MultipleFormFieldViews
                                        title: undefined, // This ensures we do not render a title for each list item
                                        tooltip: undefined,
                                    }}
                                    inputFieldProps={{
                                        ...formFieldViewProps.inputFieldProps,
                                        inputProps: {
                                            ...inputFieldProps.inputProps,
                                            items: questionProps.selectOptions,
                                            value: listItemValue,
                                            hasErrors: itemMessages.length > 0,
                                            onChange: (e) => handleChange(e.target.value, index),
                                        },
                                        messages: itemMessages,
                                    }}
                                    onComplexFieldChange={(key, e) => handleChange(e, index)}
                                />
                                <span />
                            </StackLayout>
                            <div className="c-list-view__item--border-solid-ui-300" />
                        </StackLayout>
                    );
                })}
                <TextButton onClick={handleAddItem}>+ Add another</TextButton>
            </StackLayout>
        </Form.Field>
    );
}
