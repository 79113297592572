import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { keysOf } from '@embroker/shotwell/core/object';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import {
    ActionHandler,
    CreateFormParams,
    FormAction,
    OpaqueForm,
} from '@embroker/shotwell/view/hooks/useForm';
import { GetDocumentUrl } from '../../../documents/useCases/GetDocumentUrl';
import { LPLQuote } from '../../../quote/lpl/entities/LPLQuote';
import { mapFromApiLimit } from '../../../quote/lpl/repositories/LPLQuoteRepository/APILPLQuoteRepository';
import { LPLQuoteOptions, PerClaimLimit } from '../../../quote/lpl/types/LPLQuoteOptions';
import {
    validateAggregateLimit,
    validatePerClaimLimit,
} from '../../../quote/lpl/view/components/createLPLQuoteForm';
import { LimitType } from '../../../quote/types/Limits';
import { QuotingEngineLPLEverest } from '../../../shopping/types/enums';
import { WizardForm } from '../../../view/hooks/useWizardForm';
import { BundleQuote } from '../../entities/BundleQuote';
import {
    BundleCoverageType,
    BundleCoverageTypeEnum,
    BundleQuoteCoverage,
} from '../../types/BundleQuoteCoverage';
import { DocumentType } from '../../types/BundleQuoteDocument';
import { BuildBundleDocumentListProps } from '../../view/components/buildBundleDocumentList';
import { DocumentsItem } from '../../view/components/BundleQuoteLandingPage';
import { GenerateDocumentButton } from '../../view/components/GenerateDocumentButton';
import { getDocumentUrl } from '../bundleMappingFunctions';
import {
    CoverageDefinition,
    distributedPrefix,
    GetDocumentDownloadMetadataResponse,
    LplDocumentTitle,
    LPLEverest,
    LplProductName,
    LplProductTitle,
    ProductFormData,
} from '../coverageDefinition';
import { buildLPLCoverage } from './buildLPLCoverage';
import { LPLBundleCoverageOptions } from './components/LPLBundleCoverageOptions';
import { LPLFooter } from './components/LPLBundleFooter';
import { LPLQuoteSummary } from './components/LPLBundleSummary';
import { LPLModalCoverageSummary } from './components/LPLCoverageModalSummary';
import { LPLQuestionnaireData } from './LPLQuestionnaireData';
import {
    formDataToLPLQuoteOptions,
    isLplFormData,
    isLplQuoteOptions,
    toApiLplQuoteOptions,
} from './mappingFunctions';
import { getRecommendedQuotingOptionsLpl } from './recommendedQuotingOptions';

interface LPLCoverageDefinitionType extends CoverageDefinition<LPLQuote> {
    isHigherLimit: (bundle: BundleQuote) => boolean;
}

export const LPLCoverageDefinition: LPLCoverageDefinitionType = {
    productName: LplProductName,
    productTitle: LplProductTitle,
    documentTitle: LplDocumentTitle,
    type: BundleCoverageTypeEnum.LPLBundleCoverage,
    quotingEngine: QuotingEngineLPLEverest,
    buildCoverage: buildLPLCoverage,
    mapFormDataToQuoteOptions: formDataToLPLQuoteOptions,
    getRecomendedQuoteOptions: getRecommendedQuotingOptionsLpl,
    apiProductDesignation: LPLEverest,
    mapQuoteOptionsToAPI: toApiLplQuoteOptions,
    coverageOptionsComponent: LPLBundleCoverageOptions,
    summaryComponent: LPLQuoteSummary,
    modalSummaryComponent: LPLModalCoverageSummary,
    footerComponent: LPLFooter,
    isFieldsValidType(
        input: any,
    ): input is WizardForm<OpaqueForm<distributedPrefix<LPLQuoteOptions>>>['fields'] {
        return isLplFormData(input);
    },
    isOptionsValidType(input: any): input is LPLQuoteOptions {
        return isLplQuoteOptions(input);
    },
    getProductFields(
        bundleQuote: BundleQuote,
    ): CreateFormParams<ProductFormData<distributedPrefix<LPLQuoteOptions>>>['fields'] {
        const lplCoverage = bundleQuote.coverageList.find(
            (coverage) => coverage.type === this.type,
        ) as BundleQuoteCoverage<LPLQuote> | undefined;
        const questionnaireData = lplCoverage?.questionnaireData;

        const state =
            questionnaireData?.location_with_largest_number?.state ??
            questionnaireData?.state ??
            '';

        return {
            lplPerClaimLimit: {
                type: 'select',
                validator: Joi.number().custom((value, helpers) => {
                    return validatePerClaimLimit(
                        value,
                        state,
                        helpers,
                        mapFromApiLimit(
                            bundleQuote.getCoverageHigherLimit(
                                BundleCoverageTypeEnum.LPLBundleCoverage,
                            ),
                        ),
                    );
                }),
            },
            lplAggregateLimit: {
                type: 'select',
                validator: Joi.number().custom((value, helpers) => {
                    return helpers.prefs.context !== undefined
                        ? validateAggregateLimit(
                              value,
                              state,
                              helpers.prefs.context['lplPerClaimLimit'],
                              helpers,
                          )
                        : value;
                }),
            },
            // TODO - add all missing validators
            lplPerClaimDeductible: {
                type: 'select',
                validator: Joi.number(),
            },
            lplDeductibleType: {
                type: 'select',
                validator: Joi.string().required(),
            },
            lplClaimsExpenseType: {
                type: 'select',
                validator: Joi.string(),
            },
            lplSeparateClaimExpenseLimit: {
                type: 'select',
                validator: Joi.number().allow(null),
            },
            lplSelected: {
                type: 'checkbox',
                validator: Joi.boolean(),
            },
        };
    },
    getInitialValues(bundleQuote): Record<string, unknown> {
        const lplCoverage = bundleQuote.coverageList.find(
            (coverage) => coverage.type === this.type,
        );

        // TODO: improve error handling
        if (
            lplCoverage === undefined ||
            lplCoverage.quote === undefined ||
            !this.isOptionsValidType(lplCoverage.quote.options)
        ) {
            return {};
        }

        const lplOptions = lplCoverage.quote.options;
        return {
            lplPerClaimLimit: lplOptions.perClaimLimit,
            lplAggregateLimit: lplOptions.aggregateLimit,
            lplClaimsExpenseType: lplOptions.claimsExpenseType,
            lplDeductibleType: lplOptions.deductibleType,
            lplPerClaimDeductible: lplOptions.perClaimDeductible,
            lplSeparateClaimExpenseLimit: lplOptions.separateClaimExpenseLimit,
            lplSelected: !lplOptions.isDeselected,
        };
    },
    getProductActions(
        bundleQuote: BundleQuote,
        abortSignal: AbortSignal,
    ): Record<
        FormAction,
        | {
              action: ActionHandler<LPLQuoteOptions>;
              fields: (keyof distributedPrefix<LPLQuoteOptions>)[];
          }
        | ActionHandler<ProductFormData<LPLQuoteOptions>>
    > {
        const formFields = this.getProductFields(bundleQuote);
        return {
            generateLplQuoteDocument: {
                action: async () => {
                    return getDocumentUrl(
                        bundleQuote,
                        BundleCoverageTypeEnum.LPLBundleCoverage,
                        DocumentType.QuoteDocument,
                        abortSignal,
                    );
                },
                fields: ['effectiveDate', ...keysOf(formFields)],
            },
            generateLplSpecimenPolicy: {
                action: async () => {
                    return getDocumentUrl(
                        bundleQuote,
                        BundleCoverageTypeEnum.LPLBundleCoverage,
                        DocumentType.SpecimenPolicy,
                        abortSignal,
                    );
                },
                fields: ['effectiveDate', ...keysOf(formFields)],
            },
        };
    },
    getCoverageDocuments({
        bundleQuote,
        documents,
        trigger,
        isDirty,
        value,
    }: BuildBundleDocumentListProps): DocumentsItem[] | undefined {
        const limitHigherThenAllowed =
            value.perClaimLimit >
            (mapFromApiLimit(
                bundleQuote.getCoverageHigherLimit(BundleCoverageTypeEnum.LPLBundleCoverage),
            )?.per_claim_limit ?? 2000000);

        const shouldIncludeLPLDocs =
            !bundleQuote.isCoverageReferred(BundleCoverageTypeEnum.LPLBundleCoverage) &&
            !bundleQuote.isCoverageReferredAwaitingReview(
                BundleCoverageTypeEnum.LPLBundleCoverage,
            ) &&
            bundleQuote.isCoverageEnabled(BundleCoverageTypeEnum.LPLBundleCoverage) &&
            !bundleQuote.isCoverageIndication(BundleCoverageTypeEnum.LPLBundleCoverage) &&
            !limitHigherThenAllowed &&
            value.lplSelected;

        if (!shouldIncludeLPLDocs) {
            return undefined;
        }

        const LPLQuoteDocument: DocumentsItem = {
            component: GenerateDocumentButton,
            isDisabled: isDirty,
            documentType: DocumentType.QuoteDocument,
            fileUrl: documents.find(
                (document) =>
                    document.documentType === DocumentType.QuoteDocument &&
                    document.coverageType === BundleCoverageTypeEnum.LPLBundleCoverage,
            )?.url,
            handleTrigger: () => trigger('generateLplQuoteDocument'),
            coverageType: BundleCoverageTypeEnum.LPLBundleCoverage,
        };
        const LPLSpecimenPolicy: DocumentsItem = {
            component: GenerateDocumentButton,
            isDisabled: isDirty,
            fileUrl: documents.find(
                (document) =>
                    document.documentType === DocumentType.SpecimenPolicy &&
                    document.coverageType === BundleCoverageTypeEnum.LPLBundleCoverage,
            )?.url,
            documentType: DocumentType.SpecimenPolicy,
            handleTrigger: () => trigger('generateLplSpecimenPolicy'),
            coverageType: BundleCoverageTypeEnum.LPLBundleCoverage,
        };
        return [LPLQuoteDocument, LPLSpecimenPolicy];
    },
    toggleSelected(value: boolean): Record<string, unknown> {
        return { lplSelected: value };
    },
    getMaxFutureDaysAllowed(): number {
        return 90;
    },
    onSuccessHandler(
        value: any,
        action: string,
        setDocumentUrl: (
            url: URI,
            documentType: DocumentType,
            bundleCoverageType: BundleCoverageType,
        ) => void,
        onGenerateDocument: () => void,
    ) {
        switch (action) {
            case 'generateLplQuoteDocument':
                setDocumentUrl(
                    value.fileUrl,
                    DocumentType.QuoteDocument,
                    BundleCoverageTypeEnum.LPLBundleCoverage,
                );
                onGenerateDocument();
                break;
            case 'generateLplSpecimenPolicy':
                setDocumentUrl(
                    value.fileUrl,
                    DocumentType.SpecimenPolicy,
                    BundleCoverageTypeEnum.LPLBundleCoverage,
                );
                onGenerateDocument();
                break;
        }
    },
    async getDocumentDownloadMetadata(
        documentType: DocumentType,
        lplQuote?: LPLQuote,
    ): AsyncResult<GetDocumentDownloadMetadataResponse, OperationFailed | InvalidArgument> {
        let fileKey;
        if (lplQuote === undefined) {
            return Failure(InvalidArgument({ argument: 'lplQuote', value: documentType }));
        }
        switch (documentType) {
            case DocumentType.QuoteDocument:
                fileKey = lplQuote.fileKey;
                break;
            case DocumentType.SpecimenPolicy:
                fileKey = lplQuote.details.specimenPolicyFileKey;
                break;
            default:
                break;
        }
        if (fileKey === undefined) {
            return Failure(InvalidArgument({ argument: 'fileKey', value: documentType }));
        }
        const downloadUrlResult = await execute(GetDocumentUrl, {
            fileKey: fileKey,
        });
        if (isErr(downloadUrlResult)) {
            return downloadUrlResult;
        }
        return Success({
            fileKey: fileKey,
            downloadUrl: downloadUrlResult.value.downloadUrl,
        });
    },
    isLimitHigherThenAllowed(
        formValue: distributedPrefix<LPLQuoteOptions>,
        coverageSelected: boolean,
        _?: LPLQuestionnaireData,
        higherLimit?: Record<LimitType, number>,
    ): boolean {
        const maxRegularPerClaimLimit: PerClaimLimit = 2000000;
        const coverageHigherLimit = mapFromApiLimit(higherLimit);
        return (
            coverageSelected &&
            formValue.lplPerClaimLimit >
                (coverageHigherLimit?.per_claim_limit ?? maxRegularPerClaimLimit)
        );
    },
    isHigherLimit(bundle: BundleQuote): boolean {
        return bundle.coverageList.some(
            (coverage) =>
                coverage.type === 'LPLBundleCoverage' &&
                (coverage.quote?.options as LPLQuoteOptions).perClaimLimit > 2000000,
        );
    },
    getPremiumRange(quote: LPLQuote) {
        if (!quote || !quote.details) {
            return undefined;
        }
        const min = quote.premiumRange?.min;
        const max = quote.premiumRange?.max;
        if (!min || !max) {
            return undefined;
        }
        return { min, max };
    },
    getHLCoverageSpecificText(): string {
        return 'Selecting a limit that is $3,000,000 or more means that your application for this policy will need to be reviewed by an underwriter.';
    },
};
