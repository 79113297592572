import { QuoteExtended } from '@embroker/shotwell-api/app';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, Failure, isErr } from '@embroker/shotwell/core/types/Result';
import { startOfToday } from 'date-fns';
import { QuoteExpiration } from '../../../quote/types/QuoteExpiration';
import { QuotingEngineBOPChubb } from '../../../shopping/types/enums';
import {
    BundleCoverageTypeEnum,
    BundleQuoteCoverage,
    BundleQuoteCoverageMetadata,
} from '../../types/BundleQuoteCoverage';
import { toBundleQuoteCoverage } from '../bundleMappingFunctions';
import { BOPChubbQuote } from './entities/BOPChubbQuote';
import { toBOPChubbQuoteDetails, toBOPChubbQuoteOptions } from './mappingFunctions';
import { JSONSerdes } from '@embroker/shotwell/core/encoding';
import { BOPChubbQuestionnaireData } from './types/BOPChubbQuestionnaireData';

export async function buildBOPChubbCoverage(
    bopChubbQuoteExtended: Immutable<QuoteExtended>,
    isBroker: boolean,
): AsyncResult<BundleQuoteCoverage<BOPChubbQuote>, OperationFailed | InvalidArgument> {
    if (!bopChubbQuoteExtended) {
        return Failure(OperationFailed({ message: 'bopChubbQuoteExtended is null or undefined' }));
    }
    const bopChubbCoverageMetadata = getBopCoverageMetadata();

    const questionnaireDataResult = JSONSerdes.deserialize(
        bopChubbQuoteExtended.questionnaire_data,
    );
    if (isErr(questionnaireDataResult)) {
        return Failure(
            InvalidArgument({
                argument: 'questionnaire_data',
                value: bopChubbQuoteExtended.questionnaire_data,
            }),
        );
    }

    const bopChubbQuestionnaireDataResult = BOPChubbQuestionnaireData.create(
        questionnaireDataResult.value as BOPChubbQuestionnaireData,
    );
    if (isErr(bopChubbQuestionnaireDataResult)) {
        return Failure(
            InvalidArgument({
                argument: 'Bop Chubb questionnaire data',
                value: bopChubbQuestionnaireDataResult.errors,
            }),
        );
    }

    if (!bopChubbQuoteExtended.quote) {
        return toBundleQuoteCoverage<BOPChubbQuote>(
            BundleCoverageTypeEnum.BOPChubbBundleCoverage,
            bopChubbQuoteExtended.app_status,
            bopChubbCoverageMetadata,
            bopChubbQuestionnaireDataResult.value,
            bopChubbQuoteExtended.app_valid_until,
        );
    }
    if (!bopChubbQuoteExtended.quote.details.bop_chubb) {
        return Failure(OperationFailed({ message: 'BOPChubbQuoteDetails is null or undefined' }));
    }
    if (!bopChubbQuoteExtended.quote.options.bop_chubb) {
        return Failure(OperationFailed({ message: 'BOPChubbQuoteOptions is null or undefined' }));
    }

    const bopChubbCoverage = await BOPChubbQuote.create({
        isIndication: bopChubbQuoteExtended.quote.is_indication,
        id: bopChubbQuoteExtended.quote.id,
        applicationId: bopChubbQuoteExtended.quote.app_id,
        details: toBOPChubbQuoteDetails(bopChubbQuoteExtended.quote.details.bop_chubb),
        options: toBOPChubbQuoteOptions(bopChubbQuoteExtended.quote.options.bop_chubb),
        status: 'draft',
        totalPayable: bopChubbQuoteExtended.quote.total_payable,
        totalPremium: bopChubbQuoteExtended.quote.total_premium,
        annualTechnologyFee: bopChubbQuoteExtended.quote.annual_technology_fee,
        daysToExpire: QuoteExpiration.getDaysLeftUntilExpiration({
            quotingEngine: QuotingEngineBOPChubb,
            applicationStatus: bopChubbQuoteExtended.app_status,
            isBroker: isBroker,
            quoteEffectiveDate: bopChubbQuoteExtended.quote.options.bop_chubb.effective_date,
            today: startOfToday(),
            validUntil: bopChubbQuoteExtended.app_valid_until || null,
        }),
        quoteNumber: bopChubbQuoteExtended.quote.quote_number,
        fees: bopChubbQuoteExtended.quote.fees || undefined,
        fileKey: bopChubbQuoteExtended.quote.file_key || undefined,
    });

    if (isErr(bopChubbCoverage)) {
        return Failure(
            InvalidArgument({ argument: 'BOP Chubb Coverage', value: bopChubbCoverage.errors }),
        );
    }

    return toBundleQuoteCoverage<BOPChubbQuote>(
        BundleCoverageTypeEnum.BOPChubbBundleCoverage,
        bopChubbQuoteExtended.app_status,
        bopChubbCoverageMetadata,
        bopChubbQuestionnaireDataResult.value,
        bopChubbQuoteExtended.app_valid_until,
        bopChubbCoverage.value,
    );
}

export const getBopCoverageMetadata = (): BundleQuoteCoverageMetadata => {
    return {
        title: 'Business Owners Policy',
        description:
            'Get peace of mind with a single, cost-effective policy. A BOP combines multiple coverages to protect your business, your office and its contents.',
        icon: 'user-shield',
        name: 'bop',
    };
};
