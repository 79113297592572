import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { execute } from '@embroker/shotwell/core/UseCase';
import { UpdateUserOnboardingQuestionnaireData } from '../useCases/UpdateUserOnboardingQuestionnaireData';
import { RemoveUserOnboardingQuestionnaireData } from '../useCases/RemoveUserOnboardingQuestionnaireData';

export interface OnboardingPrefillQuestionnaireData {
    tech_consulting_services?: boolean;
    lawVerticalRefinementData?: {
        hasEmployees: boolean;
        locationType: string;
        usesEmployeeOrRentalVehicles: boolean;
    };
    staffDetailsData?: {
        hasRaisedFounding: boolean;
        totalRevenue: number;
        workspace: 'homeoffice' | 'rent' | 'own';
        employeesCount: number;
    };
}

export const OnboardingPrefillQuestionnaireData = {
    ...defineValidator<OnboardingPrefillQuestionnaireData>({
        tech_consulting_services: Joi.boolean().optional(),
    }),
    create(userOnboardingDetails: OnboardingPrefillQuestionnaireData) {
        return OnboardingPrefillQuestionnaireData.validate(userOnboardingDetails);
    },
    update(onboardingPrefillQuestionnaireData: OnboardingPrefillQuestionnaireData): void {
        execute(UpdateUserOnboardingQuestionnaireData, onboardingPrefillQuestionnaireData);
    },
    clear(): void {
        execute(RemoveUserOnboardingQuestionnaireData);
    },
};
