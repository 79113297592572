import { execute } from '@embroker/shotwell/core/UseCase';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { Button, Nullable } from '@embroker/ui-toolkit/v2';
import { route } from 'navi';
import React, { SyntheticEvent, useContext } from 'react';
import { EndorsementIntake } from '../../../../endorsement/intake/view/components/EndorsementIntake';
import { PCoMLEndorsementPolicy } from '../../../../endorsement/pcoml/types/PCoMLEndorsementPolicy';
import { GetPCoMLEndorsementConfig } from '../../../../endorsement/pcoml/useCases/GetPCoMLEndorsementConfig';
import { GetPCoMLEndorsementPolicy } from '../../../../endorsement/pcoml/useCases/GetPCoMLEndorsementPolicy';
import { GetPCoMLEndorsementUserData } from '../../../../endorsement/pcoml/useCases/GetPCoMLEndorsementUserData';
import { PCoMLEndorsementPage } from '../../../../endorsement/pcoml/view/components/PCoMLEndorsementPage';
import { QuotingEngine, QuotingEnginePCoML } from '../../../../shopping/types/enums';
import { AppContext } from '../../../../view/AppContext';
import { useNavigation } from '../../../../view/hooks/useNavigation';

export interface ModifyPolicyButtonProps {
    disabled?: boolean;
    policyId: UUID;
    solartisPolicyNumber: Nullable<string>;
    quotingEngine?: Nullable<QuotingEngine>;
    referred?: boolean;
}

export const ModifyPolicyButton = ({
    policyId,
    solartisPolicyNumber,
    disabled,
    quotingEngine,
    referred,
}: ModifyPolicyButtonProps) => {
    function isEligibleMlPolicy() {
        return solartisPolicyNumber !== '';
    }
    const navigation = useNavigation();
    const { setSlideout } = useContext(AppContext);

    function handleEndorsementsDismissed() {
        setSlideout(null);
    }

    async function handleRequestAChangeClicked(event: SyntheticEvent) {
        event.stopPropagation();

        if (isEligibleMlPolicy()) {
            navigation.navigate(URI.build('/policies/endorsement/esp', { policyId }));
        } else {
            const getConfigResult = await execute(GetPCoMLEndorsementConfig);

            const pcomlEndorsementEnabled = isOK(getConfigResult)
                ? getConfigResult.value.pcomlEndorsementEnabled
                : false;

            if (pcomlEndorsementEnabled && quotingEngine === QuotingEnginePCoML) {
                const getPCoMLEndorsementPolicyResult = await execute(GetPCoMLEndorsementPolicy, {
                    policyId,
                });
                if (isErr(getPCoMLEndorsementPolicyResult)) {
                    return route({
                        view: <ErrorPage errors={getPCoMLEndorsementPolicyResult.errors} />,
                    });
                }
                const getPCoMLEndorsementUserDataResult = await execute(
                    GetPCoMLEndorsementUserData,
                );
                if (isErr(getPCoMLEndorsementUserDataResult)) {
                    return route({
                        view: <ErrorPage errors={getPCoMLEndorsementUserDataResult.errors} />,
                    });
                }
                {
                    setSlideout(
                        <PCoMLEndorsementPage
                            policyId={policyId}
                            initialPolicy={
                                getPCoMLEndorsementPolicyResult.value as PCoMLEndorsementPolicy
                            }
                            userData={getPCoMLEndorsementUserDataResult.value}
                            onDismiss={handleEndorsementsDismissed}
                        />,
                        { isDismissable: false },
                    );
                }
            } else {
                {
                    setSlideout(
                        <EndorsementIntake
                            policyId={policyId}
                            isPolicyReferred={referred}
                            onDismiss={handleEndorsementsDismissed}
                        />,
                        { isDismissable: true },
                    );
                }
            }
        }
    }
    return (
        <Button
            appearance="secondary"
            disabled={disabled}
            onClick={(event: SyntheticEvent) => handleRequestAChangeClicked(event)}
        >
            Modify Policy
        </Button>
    );
};
