import React from 'react';
import { Money } from '@embroker/shotwell/core/types/Money';
import {
    ColumnLayout,
    GridLayout,
    PageLayout,
    StackLayout,
    Text,
    usePageLayout,
    useResponsive,
} from '@embroker/ui-toolkit/v2';
import { UnderlyingApp } from '../../../useCases/GetLawBundleApplications';
import { IndicativePremiumCoverageCard } from './IndicativePremiumCoverageCard.view';
import { SideContent } from './SideContent.view';
import { BundleCoverageInfo } from './IndicativePremiumPage';
import { HeaderView } from '../../../../view/components/Header.view';

interface CoverageSelectionLayoutProps {
    radioItems: UnderlyingApp[];
    bundleCoverageInfoList: BundleCoverageInfo[];
    quoteEstimate: Money;
    handleGetStarted: () => void;
    navigateBackToQuestions: () => void;
    changeApplicationSelection: (value: string) => void;
}

export function CoverageSelectionLayout({
    bundleCoverageInfoList,
    quoteEstimate,
    radioItems,
    handleGetStarted,
    navigateBackToQuestions,
    changeApplicationSelection,
}: CoverageSelectionLayoutProps) {
    // TODO: Due to the set up of LawBundleWizard we have to do some hacky this to accomplisht he designs
    // The outcome of this SPIKE will address these issues longer term
    // https://embroker.atlassian.net/browse/EM-37307
    const pageLayout = usePageLayout({
        header: <HeaderView />,
    });
    const eligibleRadioItems = radioItems.filter(
        ({ ineligibilityReasons = [] }) => ineligibilityReasons.length == 0,
    );
    const { smallerThanDesktop, greaterThanLargeTablet } = useResponsive({
        smallerThanDesktop: { screenWidth: { smallerThan: 'desktop' } },
        greaterThanLargeTablet: { screenWidth: { greaterThan: 'large-tablet' } },
    });

    const isTablet = smallerThanDesktop && greaterThanLargeTablet;
    const sideContainerClasses = smallerThanDesktop
        ? 'u-grid-size-4'
        : 'u-grid-size-4 u-grid-column-start-9 u-grid-row-start-1 u-grid-row-end-9';

    return (
        <PageLayout {...pageLayout}>
            <PageLayout.Section>
                <StackLayout gap="32">
                    <GridLayout>
                        <StackLayout gap="8" className="u-grid-size-12">
                            <Text color="brand-400" style="heading 1">
                                Every law firm needs financial protection against unique business
                                risks.
                            </Text>
                            <Text color="brand-400" style="body 1">
                                Start covering your bases with some of our most popular coverages
                                for law firms.
                            </Text>
                            <span></span>
                        </StackLayout>
                    </GridLayout>
                    <GridLayout>
                        {eligibleRadioItems.map((item) => {
                            const isSelected =
                                bundleCoverageInfoList.find(
                                    (bundle) => bundle.coverage === item.value,
                                )?.isSelected ?? false;
                            return (
                                <IndicativePremiumCoverageCard
                                    key={item.value}
                                    icon={item.icon}
                                    title={item.title}
                                    description={item.note}
                                    isSelected={isSelected}
                                    onCoverageSelectionChange={() =>
                                        changeApplicationSelection(item.value)
                                    }
                                />
                            );
                        })}
                        {isTablet ? (
                            <SideContent
                                radioItems={radioItems}
                                handleGetStarted={handleGetStarted}
                            />
                        ) : (
                            <ColumnLayout
                                responsive={{ containerHeight: { smallerThan: 'large-desktop' } }}
                                reverse={smallerThanDesktop}
                                gap="24"
                                className={sideContainerClasses}
                            >
                                <SideContent
                                    radioItems={radioItems}
                                    handleGetStarted={handleGetStarted}
                                />
                            </ColumnLayout>
                        )}
                    </GridLayout>
                </StackLayout>
            </PageLayout.Section>
        </PageLayout>
    );
}
