import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { isDomainEventLocal } from '@embroker/shotwell/core/event/DomainEvent';
import { Immutable } from '@embroker/shotwell/core/types';
import { USD } from '@embroker/shotwell/core/types/Money';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { useDomainEvent } from '@embroker/shotwell/view/hooks/useDomainEvent';
import { ColumnLayout, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import { isPast } from 'date-fns';
import React, { useContext, useState } from 'react';
import { hasRole } from '../../../../userOrg/entities/Session';
import { AppContext } from '../../../../view/AppContext';
import { Policy, PolicyTransfered } from '../../../entities/Policy';
import { BorStatusIsBor, BorStatusPendingBor } from '../../../types/BorStatus';
import { ModifyPolicyButton } from '../PolicyTileComponents/ModifyPolicyButton';
import { TransferToEmbrokerButton } from '../PolicyTileComponents/TransferToEmbrokerButton';
import { DownloadDocument } from './DownloadDocument';
import { NamedInsuredSection } from './NamedInsuredSection';
import { PolicyDetailsCard } from './PolicyDetailsCard.view';

interface PolicyInfoProps {
    policy: Immutable<EntityProps<Policy>>;
}

export const PolicyInfo = ({ policy }: PolicyInfoProps) => {
    const { activeSession } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');
    const hideActionButton = isBroker && policy.solartisPolicyNumber === '';
    const [borStatus, setBorStatus] = useState(policy.borStatus);

    useDomainEvent<PolicyTransfered>('Policy', 'Transferred', async (event) => {
        if (!isDomainEventLocal(event)) {
            return;
        }
        setBorStatus(BorStatusPendingBor);
    });

    const policyDocuments = policy.documents.filter(
        (item) => item.typeCode === 'DocumentTypeCodeListFullPolicyDocument',
    );

    const isCrimeApp = policy.subLineOfBusiness === 'LineOfBusinessSubtypeCodeListCrimeDigital';
    const isPcomlApp = policy.lineOfBusiness === 'LineOfBusinessCodeListPCoML';
    const shouldDisplayBasePremiumWithEmbrokerAccessFee = isBroker && (isCrimeApp || isPcomlApp);

    const premiumToDisplay = shouldDisplayBasePremiumWithEmbrokerAccessFee
        ? policy.basePremiumWithEmbrokerAccessFee
        : policy.premiumPerYear;

    const downloadComponent =
        policyDocuments.length > 0 ? (
            <DownloadDocument
                currentDocumentFileKey={policyDocuments[0].storageLocation}
                currentDocumentTypeCode={policyDocuments[0].typeCode}
                isDownload
                buttonText="Download Policy PDF"
            />
        ) : null;

    const namedInsuredParties = policy.insuredPartiesList.filter(
        (insuredParty) =>
            insuredParty.typeCode === 'InsuredTypeCodeListFirstNamed' ||
            insuredParty.typeCode === 'InsuredTypeCodeListNamed',
    );

    const actionButton =
        borStatus === BorStatusIsBor ? (
            <ModifyPolicyButton
                disabled={isPast(new Date(policy.endDate)) || policy.cancellationDate !== null}
                policyId={policy.id}
                solartisPolicyNumber={policy.solartisPolicyNumber}
                quotingEngine={policy.quotingEngine}
                referred={policy.isReferred}
            />
        ) : (
            <TransferToEmbrokerButton
                insurerId={policy.insurerId}
                disabled={isPast(new Date(policy.endDate)) || policy.cancellationDate !== null}
                transferRequested={borStatus === BorStatusPendingBor}
                policyId={policy.id}
            />
        );

    return (
        <PolicyDetailsCard
            title={
                <ColumnLayout responsive={{ containerWidth: { smallerThan: 'tablet' } }}>
                    <StackLayout gap="8">
                        <Text style="heading 5">{policy.insurerName}</Text>
                        <Text color="brand-500">
                            <MoneyDisplay value={premiumToDisplay ? premiumToDisplay : USD(0)} />
                            {' Premium · '}
                            <DateDisplay
                                format="MMM dd, yyyy"
                                value={new Date(policy.startDate)}
                            />{' '}
                            - <DateDisplay format="MMM dd, yyyy" value={new Date(policy.endDate)} />
                        </Text>
                        <ColumnLayout gap="64">
                            <Text color="ui-500" data-e2e="policy-number">
                                {'Policy no. ' + policy.policyNumber}
                            </Text>
                            {downloadComponent}
                        </ColumnLayout>
                    </StackLayout>
                    {!hideActionButton ? actionButton : null}
                </ColumnLayout>
            }
        >
            <NamedInsuredSection insuredPartiesList={namedInsuredParties} />
        </PolicyDetailsCard>
    );
};
