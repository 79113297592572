import { Modal, useModal, Text, TextStyle } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { Nullable } from '@embroker/shotwell/core/types';
import { State } from '@embroker/shotwell/core/types/StateList';
import { AgreementToConductModalContent } from '../../../../quote/view/components/signature/AgreementToConductModalContent';
import { StateDisclosureModalContent } from '../../../../quote/view/components/signature/StateDisclosureModalContent';
import { Link } from '../../../../view/components/Link/Link';

const CompensationDisclosureURL = 'https://www.embroker.com/disclosure/';

export interface SignInsuranceApplicationSignatureProps {
    company: string;
    fullName: string;
    usaState?: Nullable<State>;
    style?: TextStyle;
    includeSurplusLinesDisclosure?: boolean;
}

export function SignInsuranceApplicationSignature({
    company,
    fullName,
    usaState,
    style,
    includeSurplusLinesDisclosure = true,
}: SignInsuranceApplicationSignatureProps) {
    const agreementModal = useModal();
    const stateDisclosureModal = useModal();

    return (
        <React.Fragment>
            <Text as="span" style={style}>
                By checking this box, you are electronically signing an insurance application and
                confirm that you have read and consent to our{' '}
                <Link href="" display="inline" onClick={agreementModal.show}>
                    Agreement to Conduct Electronic Transfer
                </Link>
                {includeSurplusLinesDisclosure ? ', ' : ' and '}
                <Link
                    href={CompensationDisclosureURL}
                    display="inline"
                    rel="noreferrer"
                    target="_blank"
                >
                    Compensation Disclosure
                </Link>
                {includeSurplusLinesDisclosure && (
                    <React.Fragment>
                        {' and '}
                        <Link href="" display="inline" onClick={stateDisclosureModal.show}>
                            Surplus Lines Disclosure
                        </Link>
                    </React.Fragment>
                )}
                .
            </Text>
            <Modal {...agreementModal} size="medium">
                <AgreementToConductModalContent />
            </Modal>
            <Modal {...stateDisclosureModal} size="medium">
                <StateDisclosureModalContent
                    companyName={company}
                    fullName={fullName}
                    usaState={usaState ?? ''}
                />
            </Modal>
        </React.Fragment>
    );
}
