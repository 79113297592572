import { isDateAfter, isDateBefore } from '@embroker/service-app-engine';
import { container } from '@embroker/shotwell/core/di';
import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { Money, USD } from '@embroker/shotwell/core/types/Money';
import { cast } from '@embroker/shotwell/core/types/Nominal';
import {
    Failure,
    handleOperationFailure,
    isErr,
    isOK,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Joi, Schema } from '@embroker/shotwell/core/validation/schema';
import { createForm, OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { useModal } from '@embroker/ui-toolkit/v2';
import { startOfToday } from 'date-fns';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CalculateSKU } from '../../../../analytics/useCases/CalculateSKU';
import { EditApplication } from '../../../../brokerDashboard/useCases/EditApplication';
import { QuotingEngineESP } from '../../../../shopping/types/enums';
import {
    hasAnyUWReferralReasons,
    hasExistingCoverage,
} from '../../../../shopping/view/components/applicationFlags';
import { ManualQuotingConfirmationModal } from '../../../../shopping/view/components/ManualQuotingConfirmationModal';
import { PurchasedAppType } from '../../../../summary/types/PurchasedAppType';
import { hasRole } from '../../../../userOrg/entities/Session';
import { AppContext } from '../../../../view/AppContext';
import { NavigateFunction, useNavigation } from '../../../../view/hooks/useNavigation';
import { useWizardForm, WizardPages } from '../../../../view/hooks/useWizardForm';
import { GetSignaturePacketFileUrl } from '../../../brokerage/useCases/GetSignaturePacketFileUrl';
import { BrokerSignaturePage } from '../../../brokerage/view/components/BrokerSignaturePage';
import { QuoteBindCoverage } from '../../../entities/Quote';
import {
    ESPGeneralTermsAndConditionsPlusAfterTechEOCyberSplitURL,
    ESPGeneralTermsAndConditionsPlusNewInsurerURL,
    ESPGeneralTermsAndConditionsPlusPostIPT2024URL,
    ESPGeneralTermsAndConditionsPlusURL,
    ESPGeneralTermsAndConditionsStandardAfterTechEOCyberSplitURL,
    ESPGeneralTermsAndConditionsStandardNewInsurerURL,
    ESPGeneralTermsAndConditionsStandardPostIPT2024URL,
    ESPGeneralTermsAndConditionsStandardURL,
} from '../../../espUtils/constants';
import { DraftQuote } from '../../../useCases/DraftQuote';
import { ReferQuote } from '../../../useCases/ReferQuote';
import { SignQuote } from '../../../useCases/SignQuote';
import { UnsignQuote } from '../../../useCases/UnsignQuote';
import { DownloadQuoteButton } from '../../../view/components/DownloadQuoteButton';
import { DownloadSignaturePacketButton } from '../../../view/components/DownloadSignaturePacketButton';
import { EditApplicationButton } from '../../../view/components/EditApplicationButton';
import { validateEffectiveDate } from '../../../view/components/formValidators';
import {
    MenuItem,
    Page,
    PageNavigationComponent,
    QuoteLandingPage,
} from '../../../view/components/QuoteLandingPage';
import { QuoteOnDemandNavigation } from '../../../view/components/QuoteOnDemandNavigation';
import { ReferredQuoteOnDemandNavigation } from '../../../view/components/ReferredQuoteOnDemandNavigation';
import { withExtraProps } from '../../../view/components/WithExtraProps';
import { ESPQuote } from '../../entities/ESPQuote';
import { CoverageType, ESPCoverageTypes } from '../../types/CoverageRestriction';
import { ESPQuoteOptions } from '../../types/ESPQuoteOptions';
import {
    ESPCoverageRateItem,
    ESPDoEpliCoverageRateItem,
    ESPEoCyberCoverageRateItem,
    ESPTechCyberCoverageRateItem,
} from '../../types/ESPRate';
import { InsuranceApplicationRestriction } from '../../types/InsuranceApplicationRestriction';
import { QuoteOptionsExtensionRequest } from '../../types/QuoteOptionsExtensionRequest';
import { DownloadESPQuoteDocument } from '../../useCases/DownloadESPQuoteDocument';
import { ESPRequestHigherLimits } from '../../useCases/ESPRequestHigherLimits';
import { GetHigherLimitRequestList } from '../../useCases/GetHigherLimitRequests';
import { GetInitialESPRate } from '../../useCases/GetInitialESPRate';
import { GetRestriction } from '../../useCases/GetRestriction';
import { PurchaseESP } from '../../useCases/PurchaseESP';
import { RequoteESP } from '../../useCases/RequoteESP';
import { DownloadESPGTCButton } from './DownloadESPGTCButton';
import { ESPCoveragesPage } from './ESPCoveragesPage/ESPCoveragesPage';
import { HigherLimitModal } from './ESPHigherLimitModal';
import { ESPQuoteBreakdown } from './ESPQuoteBreakdown';
import { ESPQuoteSummary } from './ESPQuoteSummary';
import { EspReferredNavigation } from './EspReferredNavigation';
import { QuoteSignature } from './Signature/QuoteSignature';
import { BrokerESPReferredNavigation } from './BrokerESPReferredNavigation';
import { GlobalConfig } from '../../../../config/types/GlobalConfig';
import { EPLIStandaloneInCaliforniaModal } from '../../../view/components/EPLIStandaloneInCaliforniaModal';
import { SubmitSupplementalQuestionnaire } from '../../../../higherLimitsSupplementalQuestionnaire/useCases/SubmitSupplementalQuestionnaire';

export interface ESPQuoteOptionsFormData {
    // Used for cross-fields validation with it's validation message displayed
    // in specific location of the page.
    // We need any type, other than undefined, to allow custom cross-fields
    // validation to kick in.
    coverage: 'any but undefined';

    isDnoSelected: boolean;
    dnoLevel?: 'standard' | 'plus';
    dnoLimit: number;
    dnoRetention: number;

    isEplSelected: boolean;
    eplLevel?: 'standard' | 'plus';
    eplLimit: number;
    eplRetention: number;

    fiduciaryLimit: number;
    fiduciaryRetention: number;
    isFiduciarySelected: boolean;

    isEoSelected: boolean;
    eoLevel: 'standard' | 'plus';
    eoLimit: number;
    eoRetention: number;

    isTechSelected?: boolean;
    techLevel: 'standard' | 'plus';
    techLimit?: number;
    techRetention?: number;

    isCyberSelected?: boolean;
    cyberLimit?: number;
    cyberRetention?: number;

    agreementToConductSignature: boolean;
    warrantyAndFraudSignature: boolean;

    brokerSignature: boolean;

    startDate?: Date;
}

const extractCoverage = (
    coverages: Immutable<Array<ESPCoverageRateItem>> | undefined,
    coverageType: string,
) => {
    if (!coverages) {
        return undefined;
    }
    return coverages.find((coverage) => {
        return coverage.coverageType == coverageType;
    });
};

export const mapToCoverageRestrictionCoverageType = (coverageType: string): CoverageType => {
    switch (coverageType) {
        case 'do':
            return 'ShoppingCoverageCodeListDirectorsAndOfficers';
        case 'epli':
            return 'ShoppingCoverageCodeListEmploymentPractices';
        case 'fiduciary':
            return 'ShoppingCoverageCodeListFiduciary';
        case 'techCyber':
            return 'ShoppingCoverageCodeListTechSplit';
        default:
            return 'ShoppingCoverageCodeListCyber';
    }
};

const checkHigherLimits = (
    quote: ESPQuote,
    restrictions?: Immutable<InsuranceApplicationRestriction>,
) => {
    if (restrictions === undefined) {
        return false;
    }

    return quote.details.coverages.some((coverage) => {
        const coverageType = mapToCoverageRestrictionCoverageType(coverage.coverageType);

        const isCoverageSelected = coverage.selected;
        const selectedLimit =
            coverage.coverageType !== 'techCyber'
                ? coverage.limit
                : coverage.techCoverageQuoteOptionsItem.limit || 0;
        return (
            isCoverageSelected && isHigherLimitRequested(coverageType, selectedLimit, restrictions)
        );
    });
};

const fromQuoteToQuoteOptions = (quote: ESPQuote): ESPQuoteOptions => ({
    effectiveDate: quote.options.effectiveDate,
    coverages: quote.details.coverages,
});

const formDataToQuoteOptions = (formData: ESPQuoteOptionsFormData): ESPQuoteOptions => ({
    effectiveDate: formData.startDate as Date, // We know the form will set a date
    coverages: [
        {
            coverageType: 'do',
            limit: formData.dnoLimit,
            retention: formData.dnoRetention,
            selected: formData.isDnoSelected,
            level: formData.dnoLevel,
        },
        {
            coverageType: 'epli',
            limit: formData.eplLimit,
            retention: formData.eplRetention,
            selected: formData.isEplSelected,
            level: formData.eplLevel,
        },
        {
            coverageType: 'fiduciary',
            selected: formData.isFiduciarySelected,
            limit: 1000000,
            retention: 0,
        },
        {
            coverageType: 'eoCyber',
            selected: formData.isEoSelected,
            limit: formData.eoLimit,
            retention: formData.eoRetention,
            level: formData.eoLevel,
        },
        {
            coverageType: 'techCyber',
            selected: formData.isTechSelected ?? false,
            level: formData.techLevel,
            techCoverageQuoteOptionsItem: {
                limit: formData.techLimit,
                retention: formData.techRetention,
            },
            cyberCoverageQuoteOptionsItem: {
                limit: formData.cyberLimit,
                retention: formData.techRetention,
            },
        },
    ],
});

export const MAX_FUTURE_DAYS_ALLOWED = 90;

const maxLimitForCoverageRestriction = (
    restrictions: Immutable<InsuranceApplicationRestriction>,
    coverageType: CoverageType,
): Money => {
    const maxLimit = restrictions.coverageRestrictions.find(
        (coverageRestriction) => coverageRestriction.coverageType === coverageType,
    )?.maxLimit;
    return maxLimit ? maxLimit : USD(0);
};

const findHigherLimitRequest = (
    coverageType: CoverageType,
    higherLimitRequests: Immutable<QuoteOptionsExtensionRequest[]>,
): number | undefined => {
    const higherLimitRequest = higherLimitRequests.find((hlr) => {
        const limit = hlr.coverageQuoteOptionsExtensionMap[coverageType];
        return limit ?? 0 > 0;
    });

    return higherLimitRequest !== undefined
        ? higherLimitRequest.coverageQuoteOptionsExtensionMap[coverageType]
        : undefined;
};

const mapAlreadyRequestedHigherLimits = (
    higherLimitRequests: Immutable<QuoteOptionsExtensionRequest[]>,
): Record<CoverageType, number> => {
    const result: Record<string, number> = {};
    [...ESPCoverageTypes].forEach((coverageType) => {
        const limit = findHigherLimitRequest(coverageType, higherLimitRequests);
        if (limit !== undefined) {
            result[coverageType] = limit;
        }
    });

    return result;
};

const isHigherLimitRequested = (
    coverageType: CoverageType,
    selectedLimit: number,
    restriction: Immutable<InsuranceApplicationRestriction>,
): boolean => {
    const maxLimit = maxLimitForCoverageRestriction(restriction, coverageType);
    return Money.isGreaterThan(USD(selectedLimit * 100), maxLimit);
};

const isSupplementalQuestionnaireNeeded = (
    formData: ESPQuoteOptionsFormData,
    restriction: Immutable<InsuranceApplicationRestriction>,
    isCompanyFunded: boolean,
    isBroker: boolean,
): boolean => {
    if (
        formData.isDnoSelected &&
        isHigherLimitRequested(
            'ShoppingCoverageCodeListDirectorsAndOfficers',
            formData.dnoLimit,
            restriction,
        )
    ) {
        return true;
    }

    if (isBroker && !isCompanyFunded) {
        return false;
    }

    if (
        formData.isTechSelected &&
        isHigherLimitRequested(
            'ShoppingCoverageCodeListTechSplit',
            formData.techLimit ?? 0,
            restriction,
        )
    ) {
        return true;
    }

    if (
        formData.isCyberSelected &&
        isHigherLimitRequested(
            'ShoppingCoverageCodeListCyberSplit',
            formData.cyberLimit ?? 0,
            restriction,
        )
    ) {
        return true;
    }

    if (
        formData.isEoSelected &&
        isHigherLimitRequested('ShoppingCoverageCodeListCyber', formData.eoLimit, restriction)
    ) {
        return true;
    }

    return false;
};

const createESPQuoteOptionsForm = (
    quote: ESPQuote | undefined,
    abortSignal: AbortSignal,
    isBroker: boolean,
    isAdmin: boolean,
    setQuote: (quote: ESPQuote) => void,
    navigate: NavigateFunction,
    restriction?: Immutable<InsuranceApplicationRestriction>,
    globalConfig?: GlobalConfig,
) => {
    return createForm<ESPQuoteOptionsFormData>({
        fields: {
            coverage: {
                type: 'hidden',
                validator: Joi.string()
                    .custom((value, { prefs: { context }, error }) => {
                        if (
                            !(
                                context === undefined ||
                                context.isDnoSelected ||
                                context.isEoSelected ||
                                context.isEplSelected ||
                                context.isFiduciarySelected ||
                                context.isTechSelected ||
                                context.isCyberSelected
                            )
                        ) {
                            return error('custom.noCoverageSelected');
                        }

                        if (
                            context !== undefined &&
                            !context.isDnoSelected &&
                            !context.isEoSelected &&
                            !context.isFiduciarySelected &&
                            !context.isTechSelected &&
                            !context.isCyberSelected &&
                            context.isEplSelected &&
                            quote?.quoteInfo?.userInfo?.usaState === 'CA' &&
                            globalConfig?.espIptChangesEnabled
                        ) {
                            return error('custom.noOnlyEplCoverageSelectedCA');
                        }

                        return value;
                    })
                    .required(),
                formatValidationError: (error) => {
                    switch (error.details.validator) {
                        case 'custom.noCoverageSelected':
                            return 'Please select your coverage.';
                        case 'custom.noOnlyEplCoverageSelectedCA':
                            return 'Please select one more coverage.';
                        default:
                            return error.message;
                    }
                },
            },
            isDnoSelected: {
                type: 'hidden',
                validator: Joi.boolean().required(),
            },
            dnoLimit: {
                type: 'select',
                validator: Joi.number().required(),
            },
            dnoRetention: {
                type: 'select',
                validator: Joi.number().required(),
            },
            dnoLevel: {
                type: 'radioGroup',
                validator: Joi.string().valid('standard', 'plus').optional(),
            },
            isEoSelected: {
                type: 'hidden',
                validator: Joi.boolean().required(),
            },
            eoLimit: {
                type: 'select',
                validator: Joi.number().required(),
            },
            eoRetention: {
                type: 'select',
                validator: Joi.number().required(),
            },
            eoLevel: {
                type: 'radioGroup',
                validator: Joi.string().valid('standard', 'plus').required(),
            },
            isTechSelected: {
                type: 'hidden',
                validator: Joi.boolean().optional(),
            },
            techLimit: {
                type: 'select',
                validator: Joi.number().optional(),
            },
            techRetention: {
                type: 'select',
                validator: Joi.number().optional(),
            },
            techLevel: {
                type: 'radioGroup',
                validator: Joi.string().valid('standard', 'plus').required(),
            },
            isCyberSelected: {
                type: 'hidden',
                validator: Joi.boolean().optional(),
            },
            cyberLimit: {
                type: 'select',
                validator: Joi.number().optional(),
            },
            cyberRetention: {
                type: 'select',
                validator: Joi.number().optional(),
            },
            fiduciaryLimit: {
                type: 'select',
                validator: Joi.number().required(),
            },
            fiduciaryRetention: {
                type: 'select',
                validator: Joi.number().required(),
            },
            isFiduciarySelected: {
                type: 'hidden',
                validator: Joi.boolean().required(),
            },
            isEplSelected: {
                type: 'hidden',
                validator: Joi.boolean().required(),
            },
            eplLimit: {
                type: 'select',
                validator: Joi.number().required(),
            },
            eplRetention: {
                type: 'select',
                validator: Joi.number().required(),
            },
            eplLevel: {
                type: 'radioGroup',
                validator: Joi.string().valid('standard', 'plus').optional(),
            },
            startDate: {
                type: 'date',
                validator: Joi.date()
                    .custom((value, helpers) => {
                        return validateStartDate(value, helpers, isAdmin, globalConfig);
                    })
                    .required(),
                formatValidationError: (error) => {
                    switch (error.details.validator) {
                        case 'date.beforeESPIPTChangesReleaseDate':
                            return (
                                'Due to rating changes, backdating is not supported for effective dates prior to: ' +
                                globalConfig?.espIptChangesReleaseDate.toDateString() +
                                '.'
                            );
                        case 'date.beforeCBToEverspanSwitchDate':
                            return (
                                'Everspan carrier not supported on effective dates prior to: ' +
                                globalConfig?.espClearBlueEverspanSwitchDate.toDateString() +
                                '.'
                            );
                        case 'date.min':
                            return 'Effective date cannot be in the past.';
                        case 'date.max':
                            return 'Effective date cannot be more than ninety days in the future.';
                        default:
                            return error.message;
                    }
                },
            },
            agreementToConductSignature: {
                type: 'checkbox',
                validator: Joi.boolean().custom((value, helpers) => {
                    return (
                        isBroker || value || helpers.error('agreementToConductSignature.invalid')
                    );
                }),
            },
            warrantyAndFraudSignature: {
                type: 'checkbox',
                validator: Joi.boolean().custom((value, helpers) => {
                    return isBroker || value || helpers.error('warrantyAndFraudSignature.invalid');
                }),
            },
            brokerSignature: {
                type: 'hidden',
                validator: Joi.boolean().custom((value, helpers) => {
                    return !isBroker || value || helpers.error('brokerSignature.invalid');
                }),
            },
        },
        actions: {
            downloadSignaturePacket: {
                action: async () => {
                    if (!quote) {
                        return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                    }

                    return await execute(GetSignaturePacketFileUrl, {
                        applicationId: quote.applicationId,
                        quoteId: quote.id,
                        abortSignal,
                    });
                },
                fields: ['startDate'],
            },
            requestHigherLimits: {
                action: async (formData: ESPQuoteOptionsFormData) => {
                    if (!quote) {
                        return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                    }

                    if (restriction === undefined) {
                        return Failure(
                            InvalidArgument({ argument: 'restriction', value: undefined }),
                        );
                    }
                    const isCompanyFunded = quote.quoteInfo?.isFunded || false;
                    const showSupplementalQuestionnaire = isSupplementalQuestionnaireNeeded(
                        formData,
                        restriction,
                        isCompanyFunded,
                        isBroker,
                    );

                    const espRequestHigherLimitsResult = await execute(ESPRequestHigherLimits, {
                        applicationId: quote.applicationId,
                        isSubmit: !showSupplementalQuestionnaire,
                    });

                    if (isErr(espRequestHigherLimitsResult)) {
                        return espRequestHigherLimitsResult;
                    }

                    return Success({ showSupplementalQuestionnaire });
                },
                fields: ['startDate'],
            },
            update: {
                action: async (formData: ESPQuoteOptionsFormData) => {
                    if (!quote) {
                        return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                    }

                    const quoteOptions = formDataToQuoteOptions(formData);

                    return await execute(RequoteESP, {
                        applicationId: quote.applicationId,
                        espQuoteOptions: quoteOptions,
                        abortSignal,
                    });
                },
                fields: [
                    'coverage',
                    'startDate',
                    'dnoLimit',
                    'dnoRetention',
                    'dnoLevel',
                    'isDnoSelected',
                    'eplLimit',
                    'eplRetention',
                    'eplLevel',
                    'isEplSelected',
                    'isFiduciarySelected',
                    'fiduciaryLimit',
                    'fiduciaryRetention',
                    'isEoSelected',
                    'eoLimit',
                    'eoRetention',
                    'eoLevel',
                    'isTechSelected',
                    'techLimit',
                    'techRetention',
                    'techLevel',
                    'isCyberSelected',
                    'cyberLimit',
                    'cyberRetention',
                ],
            },
            sign: {
                action: async (formData: ESPQuoteOptionsFormData) => {
                    if (!quote) {
                        return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                    }

                    if (
                        (isBroker && formData.brokerSignature) ||
                        (formData.agreementToConductSignature && formData.warrantyAndFraudSignature)
                    ) {
                        return execute(SignQuote, { quote });
                    }

                    if (quote.status === 'signed') {
                        return await execute(UnsignQuote, { quote });
                    }

                    return Success({ quote });
                },
                fields: [
                    'coverage',
                    'startDate',
                    'dnoLimit',
                    'dnoRetention',
                    'dnoLevel',
                    'isDnoSelected',
                    'eplLimit',
                    'eplRetention',
                    'eplLevel',
                    'isEplSelected',
                    'isFiduciarySelected',
                    'fiduciaryLimit',
                    'fiduciaryRetention',
                    'isEoSelected',
                    'eoLimit',
                    'eoRetention',
                    'eoLevel',
                    'isTechSelected',
                    'techLimit',
                    'techRetention',
                    'techLevel',
                    'isCyberSelected',
                    'cyberLimit',
                    'cyberRetention',
                ],
            },
            downloadQuote: {
                action: async () => {
                    if (!quote) {
                        return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                    }

                    const quoteOptions = fromQuoteToQuoteOptions(quote);

                    return await execute(DownloadESPQuoteDocument, {
                        applicationId: quote.applicationId,
                        quoteId: quote.id,
                        espQuoteOptions: quoteOptions,
                        abortSignal: abortSignal,
                        fileKey: quote.fileKey,
                    });
                },
                fields: [
                    'coverage',
                    'startDate',
                    'dnoLimit',
                    'dnoRetention',
                    'dnoLevel',
                    'isDnoSelected',
                    'eplLimit',
                    'eplRetention',
                    'eplLevel',
                    'isEplSelected',
                    'isFiduciarySelected',
                    'fiduciaryLimit',
                    'fiduciaryRetention',
                    'isEoSelected',
                    'eoLimit',
                    'eoRetention',
                    'eoLevel',
                    'isTechSelected',
                    'techLimit',
                    'techRetention',
                    'techLevel',
                    'isCyberSelected',
                    'cyberLimit',
                    'cyberRetention',
                ],
            },
            downloadGTC: {
                action: async () => {
                    if (!quote) {
                        return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                    }

                    const documentDownload = fromQuoteToESPDownloadDocument(quote, globalConfig);
                    const tempLink: HTMLElement = document.createElement('a');
                    tempLink.setAttribute('href', documentDownload.url);
                    tempLink.setAttribute('download', documentDownload.fileName);
                    tempLink.setAttribute('target', '_blank');
                    tempLink.click();
                    return Success();
                },
                fields: ['dnoLevel', 'eplLevel', 'eoLevel', 'techLevel'],
            },
            editApplication: {
                action: async () => {
                    if (!quote) {
                        return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                    }

                    return await execute(EditApplication, {
                        applicationId: quote.applicationId,
                        isRenewal: false,
                    });
                },
                fields: ['startDate'],
            },
            default: async () => {
                if (!quote) {
                    return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                }

                return await execute(PurchaseESP, {
                    quote,
                    abortSignal,
                });
            },
        },
        onSuccess: (value, action) => {
            switch (action) {
                case 'downloadSignaturePacket':
                    window.open(value.documentUrl as string, '_blank');
                    break;
                case 'update':
                    setQuote(value);
                    break;
                case 'requestHigherLimits': {
                    const espIptChangesEnabled = globalConfig?.espIptChangesEnabled;
                    const releaseDate = globalConfig?.espIptChangesReleaseDate;
                    if (
                        espIptChangesEnabled &&
                        releaseDate &&
                        quote?.quoteInfo?.submittedAt &&
                        !isDateBefore(quote.quoteInfo.submittedAt, releaseDate)
                    ) {
                        const defaultSupplementalQuestionnaireData = {
                            esp_what_dno_limit_currently_purchase: 'NotPurchased',
                            esp_directors_or_officers_company_percentage: 100,
                            'esp_company_owned_parent_organization.value': false,
                            esp_company_own_controlling_interest: false,
                            esp_has_company_merger_reorganization_downsizing: ['None'],
                            esp_what_eo_limit_currently_purchase: 'NotPurchased',
                            esp_how_frequently_securely_back_up_data: ['Daily'],
                        };

                        const applicationId = quote?.applicationId;
                        if (applicationId === undefined) {
                            return Failure(
                                InvalidArgument({ argument: 'applicationId', value: undefined }),
                            );
                        }
                        execute(SubmitSupplementalQuestionnaire, {
                            applicationId: applicationId,
                            questionnaireData: JSON.stringify(defaultSupplementalQuestionnaireData),
                        }).then((submitSupplementalResponse) => {
                            if (isErr(submitSupplementalResponse)) {
                                return handleOperationFailure(submitSupplementalResponse);
                            }
                            if (isBroker) {
                                navigate(URI.build('/broker/dashboard'));
                            } else {
                                navigate(URI.build('/summary'));
                            }
                        });
                    } else {
                        if (value.showSupplementalQuestionnaire) {
                            return navigate(
                                URI.build('/shopping/application/supplemental-questionnaire/esp', {
                                    applicationId: quote?.applicationId,
                                }),
                            );
                        } else if (isBroker) {
                            navigate(URI.build('/broker/dashboard'));
                        } else {
                            navigate(URI.build('/summary'));
                        }
                    }
                    break;
                }
                case 'sign':
                    setQuote(value.quote as ESPQuote);
                    break;
                case 'editApplication':
                    navigate(
                        URI.build('/shopping/application/basic-info', {
                            applicationId: quote?.applicationId,
                        }),
                    );
                    break;
                case 'default': {
                    if (isBroker) {
                        navigate(
                            URI.build('/broker/dashboard', {
                                applicationId: quote?.applicationId,
                            }),
                        );
                    } else {
                        const selectedCoverages: Array<string> = [];
                        quote?.details.coverages
                            .filter((coverage) => coverage.selected)
                            .forEach((coverage) => {
                                if (coverage.coverageType == 'do') {
                                    selectedCoverages.push('Directors and Officers');
                                }
                                if (coverage.coverageType == 'epli') {
                                    selectedCoverages.push('Employment Practices Liability');
                                }
                                if (coverage.coverageType == 'fiduciary') {
                                    selectedCoverages.push('Fiduciary');
                                }
                                if (
                                    coverage.coverageType == 'eoCyber' ||
                                    coverage.coverageType == 'techCyber'
                                ) {
                                    selectedCoverages.push(
                                        'Professional Liability / Errors and Omissions',
                                    );
                                }
                            });

                        const purchasedAppType = PurchasedAppType.create({
                            appTypes: selectedCoverages,
                        });

                        let appTypes: string | undefined;

                        if (isOK(purchasedAppType)) {
                            const result = PurchasedAppType.encode(purchasedAppType.value);
                            if (isOK(result)) {
                                appTypes = result.value;
                            }
                        }

                        navigate(
                            URI.build('/summary', {
                                applicationId: quote?.applicationId,
                                quotingEngine: QuotingEngineESP,
                                appTypes,
                                ...value,
                            }),
                        );
                    }
                    break;
                }
                default:
                    break;
            }
        },
    });
};

const isPlus = (quote: ESPQuote | undefined) => {
    return !!quote?.details.coverages.find(
        (coverage) => coverage.level === 'plus' && coverage.selected,
    );
};

const fromQuoteToESPDownloadDocument = (
    quote: ESPQuote,
    globalConfig?: GlobalConfig,
): { url: string; fileName: string } => {
    const url = getUrlBasedOnSubmittedDate(quote, globalConfig);
    const { [0]: fileName } = url.split('/').slice(-1);

    return {
        url,
        fileName,
    };
};

const getUrlBasedOnSubmittedDate = (quote: ESPQuote, globalConfig?: GlobalConfig): string => {
    const espDocumentsAfterEoCyberSplitReleaseDate =
        quote.quoteInfo?.espDocumentsAfterEoCyberSplitReleaseDate;
    const newInsurerDocumentsReleaseDate = quote.quoteInfo?.newInsurerDocumentsReleaseDate;
    const submittedAt = quote.quoteInfo?.submittedAt;
    const isPlusSelected = isPlus(quote);

    const espIptChangesEnabled = globalConfig?.espIptChangesEnabled;
    const releaseDate = globalConfig?.espIptChangesReleaseDate;
    if (
        espIptChangesEnabled &&
        releaseDate &&
        quote?.options.effectiveDate &&
        !isDateBefore(quote?.options.effectiveDate, releaseDate)
    ) {
        return isPlusSelected
            ? ESPGeneralTermsAndConditionsPlusPostIPT2024URL
            : ESPGeneralTermsAndConditionsStandardPostIPT2024URL;
    }

    if (
        espDocumentsAfterEoCyberSplitReleaseDate &&
        submittedAt &&
        isDateAfter(submittedAt, espDocumentsAfterEoCyberSplitReleaseDate)
    ) {
        return isPlusSelected
            ? ESPGeneralTermsAndConditionsPlusAfterTechEOCyberSplitURL
            : ESPGeneralTermsAndConditionsStandardAfterTechEOCyberSplitURL;
    }
    if (newInsurerDocumentsReleaseDate !== undefined && submittedAt !== undefined) {
        if (isDateAfter(submittedAt, newInsurerDocumentsReleaseDate)) {
            return isPlusSelected
                ? ESPGeneralTermsAndConditionsPlusNewInsurerURL
                : ESPGeneralTermsAndConditionsStandardNewInsurerURL;
        }
    }
    return isPlusSelected
        ? ESPGeneralTermsAndConditionsPlusURL
        : ESPGeneralTermsAndConditionsStandardURL;
};

export interface ESPQuoteLandingPageProps {
    applicationId: UUID;
}

function validateStartDate(
    effectiveDate: Date,
    helpers: Schema.CustomHelpers,
    isAdmin: boolean,
    globalConfig?: GlobalConfig,
): Date | Schema.ErrorReport {
    if (globalConfig?.espIptChangesEnabled && globalConfig?.espIptChangesReleaseDate) {
        const isBackdateAllowed = isDateBefore(
            effectiveDate,
            globalConfig.espIptChangesReleaseDate,
        );

        if (isBackdateAllowed) {
            return helpers.error('date.beforeESPIPTChangesReleaseDate');
        }
    }

    if (globalConfig?.espClearBlueEverspanSwitchDate) {
        const isSwitchActive = !isDateBefore(
            startOfToday(),
            globalConfig.espClearBlueEverspanSwitchDate,
        );

        const isBackdatedBeforeSwitchDate = isDateBefore(
            effectiveDate,
            globalConfig.espClearBlueEverspanSwitchDate,
        );

        if (isSwitchActive && isBackdatedBeforeSwitchDate) {
            return helpers.error('date.beforeCBToEverspanSwitchDate');
        }
    }

    return validateEffectiveDate(
        effectiveDate,
        startOfToday(),
        MAX_FUTURE_DAYS_ALLOWED,
        helpers,
        isAdmin,
    );
}

export function ESPQuoteLandingPage({ applicationId }: ESPQuoteLandingPageProps) {
    const [quote, setQuote] = useState<ESPQuote>();
    const { activeSession, globalConfig } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');
    const isAdmin = hasRole(activeSession, 'admin');
    const eventBus = useMemo(() => container.get<DomainEventBus>(DomainEventBus), []);

    const [restriction, setRestriction] = useState<Immutable<InsuranceApplicationRestriction>>();
    const [higherLimitRequests, setHigherLimitRequests] = useState<
        Immutable<QuoteOptionsExtensionRequest[]>
    >([]);
    const { navigate } = useNavigation();
    const espIptChangesEnabled = globalConfig?.espIptChangesEnabled;

    const abortController = useMemo(() => {
        return new AbortController();
    }, []);
    const higherLimitModal = useModal();
    const referredManualModal = useModal();
    const epliStandaloneInCaliforniaModal = useModal();

    const handleBindCoverageEvent = useCallback(() => {
        if (!quote) {
            return;
        }

        execute(CalculateSKU, {
            event: 'quote',
            applicationId: quote.applicationId,
        }).then((skuResult) => {
            const event: QuoteBindCoverage = {
                origin: 'Quote',
                name: 'BindCoverage',
                totalPremium: quote?.totalPayable,
                applicationId,
                createdAt: new Date(Date.now()),
                id: UUID.create(),
                sku: isOK(skuResult) ? skuResult.value : undefined,
                isRenewal: false,
            };
            eventBus.publish(event);
        });
    }, [applicationId, eventBus, quote]);

    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, [abortController]);

    useEffect(() => {
        let isCanceled = false;
        execute(GetRestriction, {
            insuranceApplicationId: applicationId,
        })
            .then((result) => {
                if (isCanceled) {
                    return;
                }

                if (isOK(result)) {
                    setRestriction(result.value.restriction);
                } else {
                    setRestriction(undefined);
                }
            })
            .catch(() => {
                if (isCanceled) {
                    return;
                }
            });
        return () => {
            isCanceled = true;
        };
    }, [applicationId, quote]);

    useEffect(() => {
        let isCanceled = false;
        execute(GetHigherLimitRequestList, {
            applicationId: applicationId,
        })
            .then((result) => {
                if (isCanceled) {
                    return;
                }

                if (isOK(result)) {
                    setHigherLimitRequests(result.value);
                } else {
                    setHigherLimitRequests([]);
                }
            })
            .catch(() => {
                if (isCanceled) {
                    return;
                }
            });

        return () => {
            isCanceled = true;
        };
    }, [applicationId]);

    const { result: getQuoteResult } = useUseCase(GetInitialESPRate, {
        applicationId,
    });

    useEffect(() => {
        if (getQuoteResult !== undefined && isOK(getQuoteResult)) {
            setQuote(getQuoteResult.value);
        }
    }, [getQuoteResult]);

    useEffect(() => {
        let isCanceled = false;
        if (quote && quote.status === 'draft') {
            if (checkHigherLimits(quote, restriction)) {
                execute(ReferQuote, { quote }).then((result) => {
                    if (isCanceled) {
                        return;
                    }
                    if (isOK(result)) {
                        setQuote(result.value.quote as ESPQuote);
                    }
                });
            }
        }
        return () => {
            isCanceled = true;
        };
    }, [quote, restriction]);

    const optionsForm = useMemo(
        () =>
            createESPQuoteOptionsForm(
                quote,
                abortController.signal,
                isBroker,
                isAdmin,
                setQuote,
                navigate,
                restriction,
                globalConfig,
            ),
        [quote, navigate, isBroker, isAdmin, abortController.signal, restriction, globalConfig],
    );

    const dnoCoverage = useMemo(
        () => extractCoverage(quote?.details.coverages, 'do'),
        [quote],
    ) as ESPDoEpliCoverageRateItem;
    const eplCoverage = useMemo(
        () => extractCoverage(quote?.details.coverages, 'epli'),
        [quote],
    ) as ESPDoEpliCoverageRateItem;
    const eoCoverage = useMemo(
        () => extractCoverage(quote?.details.coverages, 'eoCyber') as ESPEoCyberCoverageRateItem,
        [quote],
    );
    const techCyberCoverage = useMemo(
        () =>
            extractCoverage(quote?.details.coverages, 'techCyber') as ESPTechCyberCoverageRateItem,
        [quote],
    );
    const fiduciaryCoverage = useMemo(
        () => extractCoverage(quote?.details.coverages, 'fiduciary'),
        [quote],
    );

    const initialValue: ESPQuoteOptionsFormData = {
        coverage: 'any but undefined',
        isDnoSelected: dnoCoverage?.selected ?? false,
        dnoLimit: dnoCoverage?.limit ?? 1000000,
        dnoRetention: dnoCoverage?.retention ?? 10000,
        dnoLevel: dnoCoverage?.level,
        isEplSelected: eplCoverage?.selected ?? false,
        eplLimit: eplCoverage?.limit ?? 1000000,
        eplRetention: eplCoverage?.retention ?? 10000,
        eplLevel: eplCoverage?.level,
        isEoSelected: eoCoverage?.selected ?? false,
        eoLimit: eoCoverage?.limit ?? 1000000,
        eoRetention: eoCoverage?.retention ?? 2500,
        eoLevel: eoCoverage?.level ?? 'plus',
        isTechSelected: techCyberCoverage?.selected ?? false,
        techLimit: techCyberCoverage?.techCoverageQuoteOptionsItem.limit ?? 1000000,
        techRetention: techCyberCoverage?.techCoverageQuoteOptionsItem.retention ?? 2500,
        techLevel: techCyberCoverage?.level ?? 'plus',
        isCyberSelected: techCyberCoverage?.selected ?? false,
        cyberLimit: techCyberCoverage?.cyberCoverageQuoteOptionsItem.limit ?? 1000000,
        cyberRetention: techCyberCoverage?.cyberCoverageQuoteOptionsItem.retention ?? 2500,
        isFiduciarySelected: fiduciaryCoverage?.selected ?? false,
        fiduciaryLimit: 1000000,
        fiduciaryRetention: 0,
        startDate: quote?.details.effectiveDate,
        agreementToConductSignature: false,
        warrantyAndFraudSignature: false,
        brokerSignature: false,
    };

    const quotePage = {
        name: 'coverage',
        fields: [
            'coverage',
            'startDate',
            'dnoLimit',
            'dnoRetention',
            'dnoLevel',
            'isDnoSelected',
            'eplLimit',
            'eplRetention',
            'eplLevel',
            'isEplSelected',
            'isFiduciarySelected',
            'fiduciaryLimit',
            'fiduciaryRetention',
            'isEoSelected',
            'eoLimit',
            'eoRetention',
            'eoLevel',
            'isTechSelected',
            'techLimit',
            'techRetention',
            'techLevel',
            'isCyberSelected',
            'cyberLimit',
            'cyberRetention',
        ],
    };
    const signPage = {
        name: 'signature',
        fields: isBroker
            ? ['brokerSignature']
            : ['agreementToConductSignature', 'warrantyAndFraudSignature'],
    };

    const isQuoteExpired = quote?.daysToExpire === -1 && !isAdmin;
    const isQuoteBindable = !isQuoteExpired;
    const formPages = isQuoteBindable ? [quotePage, signPage] : [quotePage];

    const optionsWizardForm = useWizardForm(optionsForm, {
        pages: formPages as WizardPages<OpaqueForm<ESPQuoteOptionsFormData>>,
        initialValue: initialValue,
    });

    const isFormDirty = optionsWizardForm.status === 'dirty';
    const isQuoteReferred = quote?.status === 'referred';
    useEffect(() => {
        let isCanceled = false;
        if (quote && isFormDirty && isQuoteReferred) {
            execute(DraftQuote, { quote }).then((result) => {
                if (isCanceled) {
                    return;
                }
                if (isOK(result)) {
                    setQuote(result.value.quote as ESPQuote);
                }
            });
        }
        return () => {
            isCanceled = true;
        };
    }, [quote, isFormDirty, isQuoteReferred]);

    const quoteStepPage: Page<ESPQuoteOptionsFormData, ESPQuote> = {
        name: 'coverage',
        component: ESPCoveragesPage,
    };
    const quoteSignPage: Page<ESPQuoteOptionsFormData, ESPQuote> = {
        name: 'signature',
        component: isBroker ? BrokerSignaturePage : QuoteSignature,
    };
    const pages: Page<ESPQuoteOptionsFormData, ESPQuote>[] = isQuoteBindable
        ? [quoteStepPage, quoteSignPage]
        : [quoteStepPage];

    const DownloadQuoteItem: MenuItem<ESPQuoteOptionsFormData> = {
        component: DownloadQuoteButton,
        isDisabled: isFormDirty || isQuoteReferred,
    };

    const DownloadSignaturePacket: MenuItem<ESPQuoteOptionsFormData> = {
        component: DownloadSignaturePacketButton,
        isDisabled: isFormDirty || isQuoteReferred,
    };

    const EditApplicationItem: MenuItem<ESPQuoteOptionsFormData> = {
        component: EditApplicationButton,
    };

    const DownloadESPGTCItem: MenuItem<ESPQuoteOptionsFormData> = {
        component: DownloadESPGTCButton(isPlus(quote)),
        isDisabled: isFormDirty,
    };

    const shouldDisableEditApplication =
        !isAdmin &&
        isBroker &&
        quote &&
        quote.quoteInfo &&
        quote.quoteInfo.espDocumentsAfterEoCyberSplitReleaseDate &&
        quote.quoteInfo.espDocumentsAfterEoCyberSplitReleaseDate < new Date(Date.now()) &&
        quote.details.coverages.find((cov) => cov.coverageType === 'eoCyber');
    const menuButtons = shouldDisableEditApplication
        ? !isQuoteReferred
            ? [DownloadESPGTCItem, DownloadSignaturePacket, DownloadQuoteItem]
            : [DownloadESPGTCItem]
        : isBroker
        ? !isQuoteReferred
            ? [DownloadESPGTCItem, DownloadSignaturePacket, DownloadQuoteItem, EditApplicationItem]
            : [DownloadESPGTCItem, EditApplicationItem]
        : [DownloadESPGTCItem, DownloadQuoteItem];

    const isHigherLimitRequested = quote && checkHigherLimits(quote, restriction);
    const handleReferredCTAButton = () => {
        if (
            !isHigherLimitRequested &&
            isBroker &&
            hasAnyUWReferralReasons(quote?.referralReasons)
        ) {
            navigate(URI.build('/broker/dashboard', { modal: 'quoteReferred' }));
            return;
        }
        if (!isHigherLimitRequested && !hasExistingCoverage(quote?.referralReasons)) {
            if (isBroker) {
                navigate(resolveBrokerReferral(quote?.referralReasons));
                return;
            }
            const clientReferralUri = resolveClientReferral(quote?.referralReasons);
            if (clientReferralUri) {
                navigate(clientReferralUri);
            } else {
                referredManualModal.show();
            }
            return;
        }
        isHigherLimitRequested ? higherLimitModal.show() : referredManualModal.show();
    };
    const navigation =
        !isQuoteReferred || isFormDirty
            ? QuoteOnDemandNavigation
            : withExtraProps(EspReferredNavigation, {
                  onReferredCTAButtonClick: handleReferredCTAButton,
                  referredCTAButtonLabel: isHigherLimitRequested
                      ? 'Request Higher Limit'
                      : 'Submit for Review',
              });
    const shouldShowLossRunsReferredNavigation =
        isBroker &&
        !isHigherLimitRequested &&
        quote?.quoteInfo?.isReferredBecauseExistingCoverage &&
        quote?.referralReasons !== undefined &&
        quote.referralReasons.length == 1 &&
        quote.referralReasons[0] == 'Submission referred for underwrite review of loss runs';
    const referredNavigation: PageNavigationComponent<ESPQuoteOptionsFormData, ESPQuote> =
        shouldShowLossRunsReferredNavigation
            ? BrokerESPReferredNavigation
            : ReferredQuoteOnDemandNavigation;

    if (globalConfig === undefined) {
        return null;
    }

    const releaseDate = globalConfig?.espIptChangesReleaseDate;
    const shouldHaveIptChanges =
        espIptChangesEnabled &&
        releaseDate &&
        quote?.quoteInfo?.submittedAt &&
        !isDateBefore(quote.quoteInfo.submittedAt, releaseDate);

    return (
        <React.Fragment>
            <EPLIStandaloneInCaliforniaModal
                modal={epliStandaloneInCaliforniaModal}
                appId={applicationId}
            />
            <HigherLimitModal
                modal={higherLimitModal}
                trigger={optionsWizardForm.trigger}
                shouldHaveIptChanges={shouldHaveIptChanges}
            />
            <ManualQuotingConfirmationModal modal={referredManualModal} />
            <QuoteLandingPage
                title="Startup Package"
                applicationId={applicationId}
                quote={quote}
                optionsWizardForm={optionsWizardForm}
                pages={pages}
                quoteBreakdown={ESPQuoteBreakdown}
                quoteSummary={ESPQuoteSummary}
                exitUrl={cast<URI>(isBroker ? '/broker/dashboard' : '/summary')}
                menuItems={menuButtons}
                daysToExpire={quote?.daysToExpire}
                pageNavigation={
                    isQuoteBindable && !shouldShowLossRunsReferredNavigation
                        ? navigation
                        : referredNavigation
                }
                handleBindCoverage={handleBindCoverageEvent}
                restriction={restriction}
                higherLimitRequests={mapAlreadyRequestedHigherLimits(
                    higherLimitRequests.filter((request) => {
                        return request.status === 'QuoteOptionsExtensionRequestStatusProcessed';
                    }),
                )}
                showEpliStandaloneInCalifornia={epliStandaloneInCaliforniaModal.show}
            />
        </React.Fragment>
    );
}

function resolveClientReferral(referralReasons?: Readonly<Array<string>>): Nullable<URI> {
    if (referralReasons !== undefined) {
        if (referralReasons.some((reason) => reason == 'Client reservation')) {
            return URI.build('/summary', { modal: 'clientReservation' });
        }
    }

    return null;
}

export function resolveBrokerReferral(referralReasons?: Readonly<Array<string>>): URI {
    if (referralReasons !== undefined) {
        if (referralReasons.some((reason) => reason == 'Broker not licensed in state')) {
            return URI.build('/broker/dashboard', { modal: 'stateUnlicensed' });
        } else if (referralReasons.some((reason) => reason.includes('Client reservation'))) {
            return URI.build('/broker/dashboard', { modal: 'clientReservation' });
        }
    }

    return URI.build('/broker/dashboard', { modal: 'quoteReferred' });
}
