import { LineOfBusinessCodeListItem } from '@embroker/shotwell-api/enums';
import { container } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { isDomainEventLocal } from '@embroker/shotwell/core/event/DomainEvent';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { useDomainEvent } from '@embroker/shotwell/view/hooks/useDomainEvent';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    ColumnLayout,
    Filter,
    FilterOption,
    PageLayout,
    Spinner,
    StackLayout,
    TabNavigation,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import { isFuture, isPast } from 'date-fns';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { OrganizationUpdated } from '../../../userOrg/entities/Organization';
import { hasRole } from '../../../userOrg/entities/Session';
import { GetActiveOrganizationProfile } from '../../../userOrg/useCases/GetActiveOrganizationProfile';
import { GetOrganizationProfile } from '../../../userOrg/useCases/GetOrganizationProfile';
import { AppContext } from '../../../view/AppContext';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { Policy } from '../../entities/Policy';
import {
    PolicyStatusInSeries,
    PolicyStatusInSeriesCurrent,
    PolicyStatusInSeriesExpired,
    PolicyStatusInSeriesRenewal,
} from '../../types/PolicyStatusInSeries';
import { GetPolicySeries } from '../../useCases/GetPolicySeries';
import { PolicyTab } from './PolicyDetailsComponents/PolicyTab';

const PolicyDescriptionByLineOfBusiness: Map<LineOfBusinessCodeListItem, string> = new Map([
    [
        'LineOfBusinessCodeListWorkersCompensation',
        'A mandatory policy that provides benefits to your employees for injuries and illnesses arising out of and in the course of employment. Also known as Workers Comp.',
    ],
    [
        'LineOfBusinessCodeListAuto',
        'Protects against auto-related losses such as bodily injury and property damage caused by company vehicles, personal autos used on company business, or rental vehicles.',
    ],
    [
        'LineOfBusinessCodeListBOP',
        'Bundled insurance that includes General Liability and Property, and can also include Hired and Non-Owned Auto Liability, Employee Benefits Liability, and Umbrella coverage. Also known as BOP.',
    ],
    [
        'LineOfBusinessCodeListGeneralLiability',
        'Covers bodily injury, personal injury, and property damage caused by operations, products, or injury that occurs on company premises.',
    ],
    [
        'LineOfBusinessCodeListUmbrella',
        'Provides additional limits of liability when underlying insurance limits, such as General Liability or Auto Liability, are exhausted by claim payments.',
    ],
    [
        'LineOfBusinessCodeListDirectorsAndOfficers',
        "Covers defense costs and damages (awards and settlements) from wrongful acts, allegations, and lawsuits brought against your company's board of directors and/or officers. Also known as D&O.",
    ],
    [
        'LineOfBusinessCodeListProperty',
        "Protects your company's assets from numerous types of losses, including theft and fire.",
    ],
    [
        'LineOfBusinessCodeListEmploymentPractices',
        'Covers against claims and allegations by employees that their legal rights as employees of your company have been violated. Example claims include wrongful termination, sexual harassment, and discrimination.',
    ],
    [
        'LineOfBusinessCodeListProfessionalLiability',
        'Professional Liability / Errors and Omissions (E&O) insurance protects from lawsuits brought by a client claiming financial injury from a service or product you provide and for which you are held legally liable.',
    ],
    [
        'LineOfBusinessCodeListPackage',
        'Bundled insurance that includes General Liability and Property, and can also include Business Auto coverage, Employee Benefits Liability, and Umbrella coverage.',
    ],
    [
        'LineOfBusinessCodeListExcess',
        'Provides additional limits of liability when underlying insurance limits, such as General Liability or Auto Liability, are exhausted by claim payments.',
    ],
    ['LineOfBusinessCodeListOther', ''],
    [
        'LineOfBusinessCodeListCyber',
        'A modern business must-have, Cyber covers first and third party claims resulting from security/data breaches and cybercrimes like ransomware, phishing, and more.',
    ],
    [
        'LineOfBusinessCodeListOwnersAndContractors',
        "A stand-alone policy that covers owner and contractor liability for bodily injury and property damage caused by an independent contractor's work for the insured.",
    ],
    [
        'LineOfBusinessCodeListCrime',
        'Commercial Crime protects your business from losses due to crime-related issues such as petty theft, fraud, and burglary committed by both your employees and outside entities.',
    ],
    [
        'LineOfBusinessCodeListFiduciary',
        'Fiduciary liability insurance protects both your business and employees from claims related to the mismanagement of benefit plans and the legal liability arising out of their role as fiduciaries.',
    ],
    [
        'LineOfBusinessCodeListProductLiability',
        'Product liability insurance transfers the risk of defects, including expenses related to product lawsuits and other claims related to faulty products.',
    ],
    [
        'LineOfBusinessCodeListTravel',
        "This policy is designed to dove-tail with core domestic insurance lines and typically includes coverage for foreign General Liability, Contingent Automobile Liability, Foreign Voluntary Workers' Compensation and Employers Liability along with Property coverage, thereby providing a more global insurance solution.",
    ],
    [
        'LineOfBusinessCodeListCannabis',
        'Covers bodily injury, personal injury and property damage to 3rd parties caused by operations on company premises.',
    ],
    ['LineOfBusinessCodeListESP', 'ESP'],
    ['LineOfBusinessCodeListPCoML', 'PCoML'],
    [
        'LineOfBusinessCodeListExcessTechEOCyber',
        "Embroker's Excess Tech E&O/Cyber helps you get additional limits for your business. This policy provides an added layer of coverage that kicks in when the limits on your underlying policy or policies have been exhausted.",
    ],
    [
        'LineOfBusinessCodeListAccountantsProfessionalLiability',
        "Protects you and your business if you're sued for negligence, common mistakes, and more. Also known as Errors and Omissions (E&O).",
    ],
    [
        'LineOfBusinessCodeListNonTechnologyBusinessAndManagementConsultantProfessionalLiability',
        "Protects Non-Technology Business and Management Consultants if you're sued for negligence, common mistakes, and more. Also known as Errors and Omissions (E&O).",
    ],
    [
        'LineOfBusinessCodeListTaxPreparersAndBookkeepersProfessionalLiability',
        "Protects you and your business if you're sued for negligence, common mistakes, and more. Also known as Errors and Omissions (E&O).",
    ],
    [
        'LineOfBusinessCodeListRealEstateAgentsProfessionalLiability',
        "Protects you and your business if you're sued for negligence, common mistakes, and more. Also known as Errors and Omissions (E&O).",
    ],
    [
        'LineOfBusinessCodeListHomeInspectorsProfessionalLiability',
        "Protects you and your business if you're sued for negligence, common mistakes, and more. Also known as Errors and Omissions (E&O).",
    ],
]);

function policiesExistForTabName(
    policyMap: Immutable<Map<PolicyStatusInSeries, Immutable<EntityProps<Policy>[]>>>,
    tabName: PolicyStatusInSeries,
): boolean {
    return !(
        policyMap.get(tabName) === undefined ||
        policyMap.get(tabName) === null ||
        policyMap.get(tabName)?.length === 0
    );
}
interface PolicyDetailsProps {
    policyId: UUID;
    initialTabToOpen?: PolicyStatusInSeries;
    referrer?: string;
}

export const PolicyDetails = ({
    policyId,
    initialTabToOpen = PolicyStatusInSeriesCurrent,
    referrer,
}: PolicyDetailsProps) => {
    const { result: policyResult } = useUseCase(GetPolicySeries, {
        policyId,
    });

    const [organizationName, setOrganizationName] = useState('');

    useDomainEvent<OrganizationUpdated>('Organization', 'Updated', async (event) => {
        if (!isDomainEventLocal(event)) {
            return;
        }
        const result = await execute(GetOrganizationProfile, { organizationId: event.id });
        if (isOK(result)) {
            setOrganizationName(result.value.organization.companyLegalName);
        } else {
            container.get<Logger>(Log).error(result.errors);
        }
    });

    const { result: organizationProfileResult } = useUseCase(GetActiveOrganizationProfile);
    useEffect(() => {
        if (organizationProfileResult == undefined) {
            return;
        }
        if (isErr(organizationProfileResult)) {
            container.get<Logger>(Log).error(organizationProfileResult.errors);
        } else {
            setOrganizationName(organizationProfileResult.value.organization.companyLegalName);
        }
    }, [organizationProfileResult]);

    const tabToOpen = useMemo(() => {
        if (!policyResult) {
            return PolicyStatusInSeriesRenewal;
        }
        if (isOK(policyResult)) {
            const policyMap = policyResult.value.policyMap;
            if (policiesExistForTabName(policyMap, initialTabToOpen)) {
                return initialTabToOpen;
            }
            if (policiesExistForTabName(policyMap, PolicyStatusInSeriesCurrent)) {
                return PolicyStatusInSeriesCurrent;
            }
            if (policiesExistForTabName(policyMap, PolicyStatusInSeriesExpired)) {
                return PolicyStatusInSeriesExpired;
            }
        } else {
            container.get<Logger>(Log).error(policyResult.errors);
        }
        return PolicyStatusInSeriesRenewal;
    }, [initialTabToOpen, policyResult]);

    if (
        organizationName === '' ||
        policyResult === undefined ||
        organizationProfileResult === undefined ||
        tabToOpen === undefined
    ) {
        return <Spinner />;
    }

    if (isErr(policyResult)) {
        return <ErrorPage errors={policyResult.errors} />;
    }

    if (isErr(organizationProfileResult)) {
        return <ErrorPage errors={organizationProfileResult.errors} />;
    }

    return (
        <PolicyDetailsView
            policyMap={policyResult.value.policyMap}
            organizationName={organizationName}
            tabToOpen={tabToOpen}
            referrer={referrer}
        />
    );
};

interface PolicyDetailsViewProps {
    policyMap: Immutable<Map<PolicyStatusInSeries, Immutable<EntityProps<Policy>[]>>>;
    organizationName: string;
    tabToOpen: PolicyStatusInSeries;
    referrer?: string;
}

function PolicyDetailsView({
    policyMap,
    organizationName,
    tabToOpen,
    referrer,
}: PolicyDetailsViewProps) {
    const { activeSession } = useContext(AppContext);
    const navigation = useNavigation();
    const isBroker = hasRole(activeSession, 'broker');
    const [activeTabName, setActiveTabName] = useState<PolicyStatusInSeries>(tabToOpen);
    const [selectedPolicyWithStatusInSeries, setSelectedPolicyWithStatusInSeries] =
        useState<FilterOption<Immutable<EntityProps<Policy>>>>();

    const policiesWithStatusInSeries = useMemo(() => {
        return (
            policyMap
                .get(activeTabName)
                ?.map((policy) => {
                    return {
                        value: policy,
                        title: new Date(policy.startDate).getFullYear(),
                    };
                })
                .sort((a, b) => {
                    if (a.title < b.title) {
                        return 1;
                    }
                    if (a.title > b.title) {
                        return -1;
                    }

                    return 0;
                }) ?? []
        );
    }, [activeTabName, policyMap]);

    const tabNavigationItems = useMemo(() => {
        const tabNavigationItems: JSX.Element[] = [];
        const tabNavigationTitleNameMap = new Map<PolicyStatusInSeries, string>([
            [PolicyStatusInSeriesCurrent, 'CURRENT POLICY DETAILS'],
            [PolicyStatusInSeriesRenewal, 'RENEWAL POLICY (UPCOMING)'],
            [PolicyStatusInSeriesExpired, 'EXPIRED POLICIES DETAILS'],
        ]);
        policyMap.forEach((policyList, tabName) => {
            if (policyList.length > 0) {
                tabNavigationItems.push(
                    <TabNavigation.Item
                        as="a"
                        active={activeTabName === tabName}
                        onClick={() => {
                            setActiveTabName(tabName);
                        }}
                        key={tabName}
                    >
                        {tabNavigationTitleNameMap.get(tabName)}
                    </TabNavigation.Item>,
                );
            }
        });
        return tabNavigationItems;
    }, [activeTabName, policyMap]);

    useEffect(() => {
        if (policiesWithStatusInSeries !== undefined && policiesWithStatusInSeries.length > 0) {
            setSelectedPolicyWithStatusInSeries(policiesWithStatusInSeries[0]);
            return;
        }
        setSelectedPolicyWithStatusInSeries(undefined);
    }, [policiesWithStatusInSeries]);

    function handleBackToPoliciesClicked() {
        const url = isBroker
            ? referrer === 'client'
                ? '/broker/organization-info'
                : '/broker/dashboard'
            : '/policies';
        navigation.navigate(url);
    }

    function getDropDownTitle(selectedPolicy: Nullable<Immutable<EntityProps<Policy>>>): string {
        if (selectedPolicy === null) {
            return '';
        }
        if (isPast(new Date(selectedPolicy.endDate))) {
            return 'View expired policy terms for';
        }
        if (isFuture(new Date(selectedPolicy.startDate))) {
            return 'View renewal policy terms for';
        }
        return 'View policy terms for';
    }

    const selectedPolicy = selectedPolicyWithStatusInSeries?.value;

    function getRenewalStartYear(): number | undefined {
        let renewalStartYear;
        const policyRenewals = policyMap.get('renewal');
        if (policyRenewals && policyRenewals.length > 0 && activeTabName == 'current') {
            renewalStartYear = new Date(policyRenewals[0].startDate).getFullYear();
        }
        return renewalStartYear;
    }

    function isPolicyForRenew(): boolean {
        const policyRenewals = policyMap.get('renewal');
        if (
            (activeTabName == 'expired' && policyRenewals?.length == 0) ||
            activeTabName == 'current'
        ) {
            return true;
        }
        return false;
    }

    function generatePolicyDescriptionByLoB(lob: LineOfBusinessCodeListItem): string {
        const mappedDescription = PolicyDescriptionByLineOfBusiness.get(lob);
        if (mappedDescription != undefined) {
            return mappedDescription;
        }
        return '';
    }

    const policyTab = selectedPolicy ? (
        <PolicyTab
            policy={selectedPolicy}
            renewalStartYear={getRenewalStartYear()}
            onSeeRenewalClicked={() => setActiveTabName('renewal')}
            isPolicyForRenew={isPolicyForRenew()}
        />
    ) : null;

    const brokerTitle = isBroker ? (
        <Text style="heading 4" color="ui-500">{`Policy details for ${organizationName}`}</Text>
    ) : null;

    return (
        <PageLayout.Section>
            <StackLayout gap="24">
                <TextButton
                    size="small"
                    onClick={handleBackToPoliciesClicked}
                    icon="bold-caret-left"
                    data-e2e={`back-to-${
                        isBroker ? (referrer === 'client' ? 'client' : 'dashboard') : 'policies'
                    }`}
                >
                    Back to{' '}
                    {isBroker ? (referrer === 'client' ? 'client' : 'dashboard') : 'policies'}
                </TextButton>
                {selectedPolicyWithStatusInSeries ? (
                    <StackLayout gap="12">
                        <Text style="heading 3">{selectedPolicyWithStatusInSeries.value.name}</Text>
                        {brokerTitle}
                        <Text>
                            {generatePolicyDescriptionByLoB(
                                selectedPolicyWithStatusInSeries.value.lineOfBusiness,
                            )}
                        </Text>
                    </StackLayout>
                ) : null}
                {tabNavigationItems.length > 1 ? (
                    <TabNavigation>{tabNavigationItems}</TabNavigation>
                ) : null}
                <ColumnLayout split="-1">
                    {policiesWithStatusInSeries && policiesWithStatusInSeries.length > 1 ? (
                        <Filter
                            value={selectedPolicyWithStatusInSeries?.value}
                            onChange={(event) => {
                                const selectedItem = policiesWithStatusInSeries.find(
                                    (policyItem) => {
                                        return policyItem.value === event.target.value;
                                    },
                                );
                                if (selectedItem !== undefined) {
                                    setSelectedPolicyWithStatusInSeries(selectedItem);
                                }
                            }}
                            title={getDropDownTitle(selectedPolicy ?? null)}
                            options={policiesWithStatusInSeries}
                        />
                    ) : null}
                </ColumnLayout>
                {policyTab}
            </StackLayout>
        </PageLayout.Section>
    );
}
