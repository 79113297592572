import React, { useContext, useEffect } from 'react';
import { NaicsConfirmation } from './NaicsConfirmation';
import { PrimaryAddress } from './PrimaryAddress';
import { NaicsCodeRefinement } from './NaicsCodeRefinement';
import { ReactProps } from '@embroker/ui-toolkit/v2';
import { AppContext } from '../../../../view/AppContext';
import { HeaderView } from '../../../../view/components';
import { GetUserOnboardingEntrypointPayload } from '../../../useCases/GetUserOnboardingEntrypoint';
import { OnboardingPrefillQuestionnaireData } from '../../../types/OnboardingPrefillQuestionnaireData';
import { useUserOnboardingNavigation } from '../../hooks/useUserOnboardingNavigation';
import { LawVerticalRefinmentQuestion } from './LawVerticalRefinmentQuestion';
import { UserOnboardingStepType } from '../../../types/UserOnboardingDetails';
import { StaffDetailsPage } from './StaffDetailsPage';

export interface OnboardingPageProps extends ReactProps<'div'> {
    onCompleteOnboardingStep: (questionnaireData?: OnboardingPrefillQuestionnaireData) => void;
    userOnboadingEntrypoint: NonNullable<GetUserOnboardingEntrypointPayload>;
}

const primaryAddressPage: Page<OnboardingPageProps> = {
    name: 'Primary Address',
    component: PrimaryAddress,
};

const naicsConfirmationPage: Page<OnboardingPageProps> = {
    name: 'Naics Confirmation',
    component: NaicsConfirmation,
};

const naicsCodeRefinementPage: Page<OnboardingPageProps> = {
    name: 'Naics Code Refinement',
    component: NaicsCodeRefinement,
};

const lawVerticalRefinmentPage: Page<OnboardingPageProps> = {
    name: 'Law Vertical Onboarding',
    component: LawVerticalRefinmentQuestion,
};

const staffDetailsPage: Page<OnboardingPageProps> = {
    name: 'Staff Details Onboarding',
    component: StaffDetailsPage,
};

export interface Page<OnboardingPageProps> {
    name: string;
    component: React.FC<OnboardingPageProps>;
}

export const onboardingStepComponentMap: {
    [key in NonNullable<UserOnboardingStepType>]: Page<OnboardingPageProps>;
} = {
    businessLocation: primaryAddressPage,
    naicsConfirmation: naicsConfirmationPage,
    naicsRefinement: naicsCodeRefinementPage,
    lawVerticalRefinement: lawVerticalRefinmentPage,
    staffDetailsPage: staffDetailsPage,
} as const;

export function OnboardingFlowWrapper({
    userOnboadingEntrypoint,
}: {
    userOnboadingEntrypoint: NonNullable<GetUserOnboardingEntrypointPayload>;
}) {
    const { setHeader } = useContext(AppContext);
    useEffect(() => {
        setHeader(<HeaderView />);
    }, [setHeader]);

    const { onCompleteOnboardingStep, currentOnboardingStep } =
        useUserOnboardingNavigation(userOnboadingEntrypoint);

    const Component = onboardingStepComponentMap[currentOnboardingStep.stepType].component;

    return (
        <Component
            onCompleteOnboardingStep={onCompleteOnboardingStep}
            userOnboadingEntrypoint={currentOnboardingStep}
        />
    );
}
