import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { Application } from '../../entities/Application';
import * as enums from '../../types/enums';
import { QuotingEngine, QuotingEngineLPLEverest, Referred } from '../../types/enums';

export interface ApplicationFlags {
    readonly isPristine: boolean;
    readonly isESPRenewal: boolean;
    readonly hasESPQuotes: boolean;
    readonly showNewBusinessESPQuoteLink: boolean;
    readonly showRenewalESPQuoteLink: boolean;
    readonly showWCGAQuoteLink: boolean;
    readonly showLplQuoteLink: boolean;
    readonly showLplViewQuestionnaireLink: boolean;
    readonly showLplReferredEstimateLink: boolean;
    readonly showCnaBopQuoteLink: boolean;
    readonly showPCoMLQuoteLink: boolean;
    readonly showExcessQuoteLink: boolean;
    readonly showCrimeQuoteLink: boolean;
    readonly showCyberQuoteLink: boolean;
    readonly showBundleQuoteLink: boolean;
    readonly showCNABOPQuoteLink: boolean;
    readonly showCyberCowbellQuoteLink: boolean;
    readonly isAppPurchasedESPRenewal: boolean;
    readonly isAppNotStarted: boolean;
    readonly isAppStartedESPRenewal: boolean;
}

export function hasExistingCoverage(appReferralReasons?: readonly string[]): boolean {
    const referralReasons = ['Submission referred for underwrite review of loss runs'];
    return (
        appReferralReasons != undefined &&
        appReferralReasons.length == 1 &&
        referralReasons.includes(appReferralReasons[0])
    );
}

export function hasAnyUWReferralReasons(appReferralReasons?: readonly string[]): boolean {
    const UWReferralReasons = [
        'Submission referred for having only Crowdfunding',
        'Submission referred for having only Private Equity funding',
        'Submission referred for having more than 500 Employees (EPLI)',
        'Submission referred for having non renewed or cancelled policy',
        'Submission referred for having policy in referred status',
        'Submission referred for having five years passed since funding',
        'Submission referred for having a specific area of focus',
        'Submission referred for company not using written contracts',
        'Submission referred for company not storing data on regular basis',
        'Submission referred for having policy in referred industry',
        'Submission referred for having over 50 employees in California',
        'Submission referred for having or planning a merger, consolidation or acquisition',
        'Submission referred for play to earn operations',
        'Submission referred for company subject to oversight by the SEC or any state or local gambling commission, gaming board, or casino control board',
        'Submission referred for company making credit worthiness or lending acceptability decision',
        'Submission referred for payment processing services or operations',
        'Submission referred for in-person, telephonic, or web-based medical services',
        'Submission referred for operating as an MGA or Insurance Carrier',
        'Submission referred for not enough assets to maintain operations and debt obligations over the next 18 months',
        'Submission referred for not being protected with Multifactor Authentication',
        'Submission referred for cryptocurrency, blockchain or distributed ledger operations or exposure',
        'Submission referred for having over $2m in revenue in Specific Area of Focus (Gaming/Healthcare/Fintech/Insurance)',
        'Submission referred for having over $5m in funding in Specific Area of Focus (Gaming/Healthcare/Fintech/Insurance)',
        'Submission referred for having over $4m in revenue in Specific Area of Focus (Gaming/Healthcare/Fintech/Insurance)',
        'Submission referred for having over $10m in funding in Specific Area of Focus (Gaming/Healthcare/Fintech/Insurance)',
        'Submission referred as Company is owned by an entity with over 50% voting rights',
        'Submission referred due to Company having a recent or future major reorganization',
        'Submission referred for having subsidiaries with different operations than the parent company',
        'Submission referred due to Company has been involved in breach of privacy in past three years (Technology ENO/Cyber)',
        'Submission referred for having more than $250m in funding',
        'Submission referred for having more than $100m in revenue',
        'Submission referred due to company legal issues in the past 3 years',
        'Submission referred for having revenue between $10m and $25m (TechEO unfunded)',
        'Submission referred for having revenue between $25m and $300m (TechEO unfunded)',
        'Submission referred for number of PII records 3M or more',
        'Submission referred for having SecurityScorecard Grade D or F',
        'Investors besides Private Equity and Venture Capital with more or equal to 50%',
    ];

    // Used for checking against the whole group of referral reasons that are in a form of "Flagged in 'bad word' search - <list of bad words that were found>"
    const BadWordReferralReasonSubstring = "Flagged in 'bad word' search";

    if (appReferralReasons && appReferralReasons.length > 0) {
        return appReferralReasons.some(
            (reason) =>
                UWReferralReasons.includes(reason) ||
                reason.includes(BadWordReferralReasonSubstring),
        );
    }

    return false;
}

export function isLPLIndication(app: Immutable<EntityProps<Application>>): boolean {
    //TODO tech debt: migrate activities with the old ReferralReasonNumberOfLawyers message
    const referralReasons = ['Firm has 11-50.5 lawyers', 'Firm has 11-20.5 lawyers'];
    return (
        isAppQuotingEngine(app.quotingEngine, QuotingEngineLPLEverest) &&
        app.status === Referred &&
        app.ineligibilityReasons != null &&
        app.ineligibilityReasons.referralReasons.length == 1 &&
        referralReasons.includes(app.ineligibilityReasons.referralReasons[0])
    );
}

export function isAppQuotingEngine(
    applicationQuotingEngine: Nullable<QuotingEngine>,
    quotingEngine: enums.QuotingEngine,
): boolean {
    return applicationQuotingEngine === quotingEngine;
}

export function isAppStatus(
    application: Immutable<EntityProps<Application>>,
    status: enums.InsuranceApplicationStatusCode,
): boolean {
    return application.status === status;
}

export function isPreviousAppQuotingEngine(
    application: EntityProps<Application>,
    quotingEngine: enums.QuotingEngine,
): boolean {
    return (
        application.previousApplicationsQuotingEngineList.length == 1 &&
        application.previousApplicationsQuotingEngineList[0] === quotingEngine
    );
}

export function getApplicationFlags(
    app: EntityProps<Application> | Application,
    flags: {
        canViewCnaBopQuotes: boolean;
        canViewLplQuotes: boolean;
        canViewPCoMLQuotes: boolean;
        canViewCrimeQuotes: boolean;
        canViewCyberQuotes: boolean;
        canViewExcessQuotes: boolean;
    },
): ApplicationFlags {
    const hasQuotes = app.hasQuotes;
    const isPristine = app.isPristine;
    const isESPRenewal = isPreviousAppQuotingEngine(app, enums.QuotingEngineESP);
    const hasESPQuotes = isAppQuotingEngine(app.quotingEngine, enums.QuotingEngineESP) && hasQuotes;
    const showNewBusinessESPQuoteLink = hasESPQuotes && !isESPRenewal;
    const showRenewalESPQuoteLink = hasESPQuotes && isESPRenewal;
    const showWCGAQuoteLink =
        isAppQuotingEngine(app.quotingEngine, enums.QuotingEngineWCGA) && hasQuotes;
    const showCrimeQuoteLink =
        flags.canViewCrimeQuotes &&
        isAppQuotingEngine(app.quotingEngine, enums.QuotingEngineCrime) &&
        hasQuotes;
    const showCyberQuoteLink =
        flags.canViewCyberQuotes &&
        isAppQuotingEngine(app.quotingEngine, enums.QuotingEngineCyber) &&
        hasQuotes;
    const showLplQuoteLink =
        flags.canViewLplQuotes &&
        isAppQuotingEngine(app.quotingEngine, enums.QuotingEngineLPLEverest);
    const showLplReferredEstimateLink = showLplQuoteLink && isLPLIndication(app);
    const showLplViewQuestionnaireLink =
        flags.canViewLplQuotes &&
        isAppQuotingEngine(app.quotingEngine, enums.QuotingEngineLPLEverest);
    const showCnaBopQuoteLink =
        flags.canViewCnaBopQuotes &&
        isAppQuotingEngine(app.quotingEngine, enums.QuotingEngineCNABOP);
    const showPCoMLQuoteLink =
        flags.canViewPCoMLQuotes && isAppQuotingEngine(app.quotingEngine, enums.QuotingEnginePCoML);
    const showExcessQuoteLink =
        flags.canViewExcessQuotes &&
        isAppQuotingEngine(app.quotingEngine, enums.QuotingEngineExcess) &&
        hasQuotes;
    const isAppPurchasedESPRenewal =
        isAppStatus(app, enums.Purchased) &&
        isPreviousAppQuotingEngine(app, enums.QuotingEngineESP);
    const isAppNotStarted = isPristine && isAppStatus(app, enums.QuestionnaireInProgress);
    const isAppStartedESPRenewal =
        !isAppStatus(app, enums.Purchased) &&
        !isAppStatus(app, enums.CanceledByAdmin) &&
        isPreviousAppQuotingEngine(app, enums.QuotingEngineESP);
    const showBundleQuoteLink =
        app.appType == 'AppTypeCodeListLawBundle' || app.appType == 'AppTypeCodeListMPLBundle';
    const showCNABOPQuoteLink = isAppQuotingEngine(app.quotingEngine, enums.QuotingEngineCNABOP);
    const showCyberCowbellQuoteLink = isAppQuotingEngine(
        app.quotingEngine,
        enums.QuotingEngineCyberCowbell,
    );

    return {
        isPristine,
        isESPRenewal,
        hasESPQuotes,
        showNewBusinessESPQuoteLink,
        showRenewalESPQuoteLink,
        showWCGAQuoteLink,
        showLplQuoteLink,
        showLplViewQuestionnaireLink,
        showLplReferredEstimateLink,
        showCnaBopQuoteLink,
        showPCoMLQuoteLink,
        showExcessQuoteLink,
        showCrimeQuoteLink,
        showCyberQuoteLink,
        showBundleQuoteLink,
        showCNABOPQuoteLink,
        showCyberCowbellQuoteLink,
        isAppPurchasedESPRenewal,
        isAppNotStarted,
        isAppStartedESPRenewal,
    };
}
