import {
    Button,
    ButtonBar,
    DetailsSidebar,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React from 'react';

export interface ComparisonQuoteBundleQuoteHLSlideoutProps {
    handleDismiss(): void;
    handleClose(): void;
    handleCTAClick(): void;
}

export const ComparisonQuoteBundleQuoteHLSlideout = ({
    handleDismiss,
    handleClose,
    handleCTAClick,
}: ComparisonQuoteBundleQuoteHLSlideoutProps) => {
    return (
        <DetailsSidebar>
            <DetailsSidebar.Header onDismiss={handleDismiss}>
                <Text style="heading 2" color="ui-50">
                    You’ve selected higher limits
                </Text>
                <Text style="body 1" color="ui-50">
                    Selecting a limit that is $3,000,000 or more means that your application for
                    this policy will need to be reviewed by an underwriter.
                </Text>
            </DetailsSidebar.Header>
            <DetailsSidebar.Body>
                <Text style="heading 4" color="brand-400">
                    How higher limits work
                </Text>
                <Text style="body 1" color="ui-500">
                    Applications with higher limits requests will be submitted for review. An
                    Embroker Team Member will be in touch via email within 1-2 business days.
                </Text>
                <Text style="body 1" color="ui-500">
                    Once you submit it, you will not be able to proceed with your application or
                    purchase your coverage until an underwriter has reviewed it.
                </Text>
                <StackLayout gap="64">
                    <Text style="body 1" color="ui-500">
                        After your application is reviewed and processed, we will recalculate your
                        policy’s Estimated Premium accordingly.
                    </Text>
                    <ButtonBar split="-2">
                        <TextButton onClick={handleClose}>Cancel</TextButton>
                        <Button onClick={handleCTAClick}>Proceed with Higher Limits</Button>
                    </ButtonBar>
                </StackLayout>
            </DetailsSidebar.Body>
        </DetailsSidebar>
    );
};
