import { Immutable } from '@embroker/shotwell/core/types';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { WizardForm } from '../../../view/hooks/useWizardForm';
import { CoverageCatalog } from '../../CoverageCatalog';
import {
    BundleCoverageAppStatusEnum,
    BundleCoverageType,
    BundleQuoteCoverageType,
} from '../../types/BundleQuoteCoverage';
import { BundleQuoteFormData } from '../../types/BundleQuoteFormData';
import { BundleQuoteOrganization } from '../../types/BundleQuoteOrganization';
import { BundleQuoteCardLayout } from './BundleQuoteCardLayout.view';
import { BundleQuoteCoverageHeader } from './BundleQuoteCoverageHeader';

export interface BundleQuoteCoverageProps<T extends BundleQuoteFormData> {
    coverage: Immutable<BundleQuoteCoverageType>;
    organizationInfo: BundleQuoteOrganization;
    fields: WizardForm<OpaqueForm<T>>['fields'];
    value: T;
    isDirty?: boolean;
    quoteHasExpired: boolean;
    appHasExpired: boolean;
    trigger: WizardForm<OpaqueForm<T>>['trigger'];
    setValue: (value: any) => void;
    handleCoverageSelectToggle: (checked: boolean, coverageType: BundleCoverageType) => void;
    isSelected: boolean;
    isReferred: boolean;
    isNotEligible: boolean;
}

export function BundleQuoteCoverage<T extends BundleQuoteFormData>({
    coverage,
    organizationInfo,
    fields,
    value,
    isDirty = false,
    quoteHasExpired,
    appHasExpired,
    trigger,
    setValue,
    handleCoverageSelectToggle,
    isSelected,
    isReferred,
    isNotEligible,
}: BundleQuoteCoverageProps<T>) {
    const { quote, type, status, coverageMetadata, questionnaireData } = coverage;
    const isReferredAwaitingReview = status === BundleCoverageAppStatusEnum.ReferredAwaitingReview;

    const isCoverageDisabled =
        !isSelected ||
        quoteHasExpired ||
        isReferredAwaitingReview ||
        appHasExpired ||
        isNotEligible;

    const CoverageOptionsComponent = CoverageCatalog.getCoverageOptionsComponent(type);
    const CoverageFooterComponent = CoverageCatalog.getCoverageFooterComponent(type);
    const isCoverageOptionsComponentHidden =
        !CoverageOptionsComponent ||
        !quote ||
        !questionnaireData ||
        !CoverageCatalog.isValueValidType(value, coverage.type) ||
        !CoverageCatalog.isQuoteValidType(quote, coverage.type) ||
        !CoverageCatalog.isQuestionnaireDataValidType(questionnaireData, coverage.type) ||
        !CoverageCatalog.isFieldsValidType(fields, coverage.type);
    return (
        <BundleQuoteCardLayout gap="24" boxShadow={!isNotEligible} data-e2e={coverage.type}>
            <StackLayout gap="8">
                <BundleQuoteCoverageHeader
                    coverage={coverage}
                    appHasExpired={appHasExpired}
                    handleCoverageSelectToggle={handleCoverageSelectToggle}
                    isNotEligible={isNotEligible}
                    isReferred={isReferred}
                    isSelected={isSelected}
                    quoteHasExpired={quoteHasExpired}
                    isDirty={isDirty}
                />
                <Text style="heading 5" color="brand-400" data-e2e="coverage-name">
                    {coverageMetadata.title}
                </Text>
                <StackLayout gap="4">
                    <div />
                    <StackLayout gap="24">
                        <Text style="body 2" data-e2e={`${coverageMetadata.name}-description`}>
                            {coverageMetadata.description}
                        </Text>
                        {!isCoverageOptionsComponentHidden ? (
                            <StackLayout gap="12">
                                <CoverageOptionsComponent
                                    quote={quote}
                                    questionnaireData={questionnaireData}
                                    fields={fields}
                                    setValue={setValue}
                                    value={value}
                                    organizationInfo={organizationInfo}
                                    trigger={trigger}
                                    disabled={isCoverageDisabled}
                                />
                                {CoverageFooterComponent ? (
                                    <CoverageFooterComponent
                                        questionnaireData={questionnaireData}
                                    />
                                ) : null}
                            </StackLayout>
                        ) : null}
                    </StackLayout>
                </StackLayout>
            </StackLayout>
        </BundleQuoteCardLayout>
    );
}
