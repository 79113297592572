import { OpaqueForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    PageLayout,
    Text,
    StackLayout,
    Form,
    RadioGroup,
    StatusMessage,
} from '@embroker/ui-toolkit/v2';

import React, { useMemo } from 'react';
import { StaffDetailsInput } from './StaffDetailsPage';
import { FormViewLayout } from '@app/view/components/FormViewLayout.view';

export interface StaffDetailsProps {
    address: string;
    form: OpaqueForm<StaffDetailsInput>;
    handleBack: () => void;
    hasPrevious: boolean;
}

export const StaffDetails = ({ address, form, handleBack, hasPrevious }: StaffDetailsProps) => {
    const { fields, submit } = useForm(form);
    const disableSubmit = useMemo(
        () =>
            !(
                fields.employeesCount.props.value &&
                fields.hasRaisedFounding.props.value !== undefined &&
                fields.workspace.props.value &&
                fields.totalRevenue.props.value
            ),
        [fields],
    );

    return (
        <PageLayout.Section>
            <FormViewLayout
                submitText="Next"
                onFormSubmit={submit}
                title="Tell us a few more details about your business so we can help you find the
                        right protection."
                hasPrevious={hasPrevious}
                handleBack={handleBack}
                disableSubmit={disableSubmit}
            >
                <StackLayout>
                    <Text color="ui-500">Has the company raised funding?</Text>
                    <RadioGroup
                        id={fields.hasRaisedFounding.props.name}
                        items={[
                            {
                                title: 'Yes',
                                value: true,
                            },
                            {
                                title: 'No',
                                value: false,
                            },
                        ]}
                        status={fields.hasRaisedFounding.errors.length ? 'error' : 'default'}
                        {...fields.hasRaisedFounding.props}
                    />
                </StackLayout>
                <StackLayout gap="12">
                    <Text color="ui-500">
                        What’s the total revenue your business expects to generate in the current
                        fiscal year?
                    </Text>
                    <Form.Field
                        inputProps={{
                            ...fields.totalRevenue.props,
                        }}
                        label="Current year annual revenue (projected)"
                        messages={fields.totalRevenue.messages}
                        type={fields.totalRevenue.type}
                    />
                    <StatusMessage status="helptext">
                        This refers to the revenue before any deductions, such as commission splits,
                        revenue sharing, expenses, etc.
                    </StatusMessage>
                </StackLayout>
                <StackLayout>
                    <Text color="ui-500">Which best describes your workspace at {address}?</Text>
                    <RadioGroup
                        id={fields.workspace.props.name}
                        items={[
                            {
                                title: 'This is my home office location',
                                value: 'homeoffice',
                            },
                            {
                                title: 'I rent an office at this location',
                                value: 'rent',
                            },
                            {
                                title: 'I own this location',
                                value: 'own',
                            },
                        ]}
                        status={fields.workspace.errors.length ? 'error' : 'default'}
                        {...fields.workspace.props}
                    />
                </StackLayout>
                <StackLayout gap="12">
                    <Text color="ui-500">How many total employees does your company have?</Text>
                    <Form.Field
                        inputProps={{
                            ...fields.employeesCount.props,
                        }}
                        label="Total employee number"
                        messages={fields.employeesCount.messages}
                        type={fields.employeesCount.type}
                    />
                    <StatusMessage status="helptext">
                        {`"Employee" `} refers to any staff on payroll, any independent contractors
                        you hire, and the company’s owners.
                    </StatusMessage>
                </StackLayout>
            </FormViewLayout>
        </PageLayout.Section>
    );
};
