import React, { useMemo } from 'react';
import { OnboardingPageProps } from './OnboardingFlowWrapper';
import { StaffDetails } from './StaffDetails.view';
import { createForm } from '@embroker/shotwell/view/hooks/useForm';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { Success } from '@embroker/shotwell/core/types/Result';
import { OnboardingPrefillQuestionnaireData } from '@app/userOrg/types/OnboardingPrefillQuestionnaireData';
import { GetActiveOrganizationProfile } from '@app/userOrg/useCases/GetActiveOrganizationProfile';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';

export interface StaffDetailsInput {
    readonly hasRaisedFounding: boolean;
    readonly totalRevenue: number;
    readonly workspace: 'homeoffice' | 'rent' | 'own';
    readonly employeesCount: number;
}

export const staffDetailsForm = ({
    onCompleteOnboardingStep,
}: {
    onCompleteOnboardingStep: (questionnaireData: OnboardingPrefillQuestionnaireData) => void;
}) => {
    return createForm<StaffDetailsInput>({
        fields: {
            hasRaisedFounding: {
                type: 'radioGroup',
                validator: Joi.boolean().required(),
            },
            totalRevenue: {
                type: 'currency',
                validator: Joi.number().min(1).required(),
                formatValidationError(error) {
                    if (error.details.validator === 'number.min') {
                        return 'The revenue must be more than 0.';
                    }
                    if (error.details.validator === 'any.required') {
                        return 'Please enter a revenue.';
                    }
                    return error.message;
                },
            },
            workspace: {
                type: 'radioGroup',
                validator: Joi.string().valid('homeoffice', 'rent', 'own').required(),
            },
            employeesCount: {
                type: 'number',
                validator: Joi.number().min(1).required(),
                formatValidationError(error) {
                    if (error.details.validator === 'number.min') {
                        return 'Please enter a minimum of 1 employees.';
                    }
                    if (error.details.validator === 'any.required') {
                        return 'Please enter number of employees.';
                    }
                    return error.message;
                },
            },
        },
        submit: async (input) => {
            // Placeholder. To do: Implement submit.
            onCompleteOnboardingStep({
                staffDetailsData: {
                    hasRaisedFounding: input.hasRaisedFounding,
                    totalRevenue: input.totalRevenue,
                    workspace: input.workspace,
                    employeesCount: input.employeesCount,
                },
            });
            return Success();
        },
    });
};

export const StaffDetailsPage = ({ onCompleteOnboardingStep }: OnboardingPageProps) => {
    const staffDetailsFormMemo = useMemo(
        () => staffDetailsForm({ onCompleteOnboardingStep }),
        [onCompleteOnboardingStep],
    );

    const { result: getActiveOrganizationProfile } = useUseCase(GetActiveOrganizationProfile);
    const address = useMemo(
        () =>
            getActiveOrganizationProfile?.status === 'OK'
                ? getActiveOrganizationProfile.value.organization.headquarters.addressLine1 || ''
                : '',
        [getActiveOrganizationProfile],
    );

    const hasPrevious = true; // Placeholder. To do: Implement back condition.

    const handleBack = () => {
        // Placeholder. To do: Implement back behavior.
    };

    return (
        <StaffDetails
            address={address}
            form={staffDetailsFormMemo}
            hasPrevious={hasPrevious}
            handleBack={handleBack}
        />
    );
};
