import { inject } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Success, isErr, Failure, AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import {
    APIQuestionerRepository,
    GetQuestionerFormRepositoryResponse,
    GetQuestionnaireParams,
} from '../repositories/APIQuestionerRepository';
import { SessionRepository } from '../../userOrg/repositories/SessionRepository';
import { isAuthenticated } from '../../userOrg/entities/Session';
import { Unauthenticated } from '../../userOrg/errors';
import { FailedToGetQuestionerForm } from '../errors';
import { QuestionerPageDefinition } from '../types/QuestionerPageDefinition';
import { FormQuestionDefinition } from '@app/view/components/DataDrivenForm/hooks/useDataDrivenForm';
import { QuestionerQuestion } from '../types/QuestionerQuestionType';
import { Section } from '../types/QuestionerSectionType';

export type GetQuestionerFormRequest = Pick<GetQuestionnaireParams, 'questionnaireIds'>;

export interface GetQuestionerFormResponse {
    questionerPageDefinitions?: QuestionerPageDefinition[];
    formQuestionDefinitions: FormQuestionDefinition[];
    questionerQuestions: QuestionerQuestion[];
}
export interface GetQuestionerForm extends UseCase {
    execute(
        request: GetQuestionerFormRequest,
    ): AsyncResult<GetQuestionerFormResponse, Unauthenticated | FailedToGetQuestionerForm>;
}

class GetQuestionerFormUseCase extends UseCase implements GetQuestionerForm {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Questioner/GetQuestionerFormUseCase');
    /**
     * Constructor for GetQuestionerFormUseCase use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param notificationRepository is notification repository used to store user Questioner
     */
    constructor(
        @inject(APIQuestionerRepository) private questionerRepository: APIQuestionerRepository,
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(SessionRepository) private sessionRepository: SessionRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: GetQuestionerFormRequest,
    ): AsyncResult<GetQuestionerFormResponse, Unauthenticated | FailedToGetQuestionerForm> {
        const { questionnaireIds } = request;
        const activeSesion = await this.sessionRepository.getActiveSession();
        if (!isAuthenticated(activeSesion.value)) {
            return Failure(Unauthenticated());
        }

        const getQuestionerFormResp = await this.questionerRepository.getQuestionerForm({
            questionnaireIds,
            includeAnswers: true,
        });

        if (isErr(getQuestionerFormResp)) {
            return getQuestionerFormResp;
        }

        const { sections, questions, initialPage } =
            getQuestionerFormResp.value as GetQuestionerFormRepositoryResponse;

        const questionerPageDefinitions = Section.getPageDefinitionsForQuestionnaire(
            questions,
            sections,
            initialPage,
        );

        const fieldSets = Section.getFieldSetDefinitions(questions, sections);
        const formQuestionDefinitions = QuestionerQuestion.getDataDrivenFormQuestions(questions);

        const formElements = [...fieldSets, ...formQuestionDefinitions];

        return Success<GetQuestionerFormResponse>({
            formQuestionDefinitions: formElements,
            questionerQuestions: questions,
            questionerPageDefinitions,
        });
    }
}

export const GetQuestionerForm: UseCaseClass<GetQuestionerForm> = GetQuestionerFormUseCase;
