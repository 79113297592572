import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { InvalidAnnualTechFee, QuoteOptionsApprovalNeeded } from '../../../errors';
import { DocumentType } from '../../../types/Document';
import { LPLConfig } from '../../types/LPLConfig';
import { LPLQuoteExtended } from '../../types/LPLQuoteExtended';
import { LPLQuoteOptions } from '../../types/LPLQuoteOptions';

export interface EnqueueTaskResponse {
    taskId: UUID;
}

export interface LPLQuoteRepository {
    getLastLPLQuote(
        applicationId: UUID,
    ): AsyncResult<LPLQuoteExtended, InvalidArgument | OperationFailed>;

    getLPLApplicationDocument(
        applicationId: UUID,
    ): AsyncResult<string, InvalidArgument | OperationFailed>;

    enqueueReQuoteLPLTask(
        applicationId: UUID,
        lplQuoteOptions: LPLQuoteOptions,
    ): AsyncResult<EnqueueTaskResponse, InvalidArgument | OperationFailed>;

    purchaseLPL(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<
        void,
        InvalidArgument | OperationFailed | InvalidAnnualTechFee | QuoteOptionsApprovalNeeded
    >;

    enqueueIsQuoteConsistentTask(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<EnqueueTaskResponse, InvalidArgument | OperationFailed>;

    enqueueCreateLPLDocumentTask(
        applicationId: UUID,
        quoteId: UUID,
        documentType: DocumentType,
    ): AsyncResult<EnqueueTaskResponse, InvalidArgument | OperationFailed>;

    getLPLConfig(): AsyncResult<LPLConfig, InvalidArgument | OperationFailed>;

    hasDocGenTaskInProcess(
        applicationId: UUID,
    ): AsyncResult<boolean, InvalidArgument | OperationFailed>;

    requestHigherLimit(applicationId: UUID): AsyncResult<void, InvalidArgument | OperationFailed>;
}

export const LPLQuoteRepository = Symbol('LPLQuoteRepository');
