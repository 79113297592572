import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import {
    CardLayout,
    ColumnLayout,
    Pagination,
    StackLayout,
    Table,
    Text,
    TextButton,
    useStaticTable,
} from '@embroker/ui-toolkit/v2';
import React, { useContext } from 'react';
import { hasRole } from '../../../userOrg/entities/Session';
import { AppContext } from '../../../view/AppContext';
import { PaymentData } from './PaymentsHistory';

export interface ChargedTableProps {
    data: PaymentData[];
}

export function Charged(props: ChargedTableProps) {
    const context = useContext(AppContext);
    const isBroker = hasRole(context.activeSession, 'broker');
    const itemsPerPage = 10;
    const { visibleItems, pagination } = useStaticTable<PaymentData>({
        items: props.data,
        itemsPerPage,
    });
    if (props.data.length === 0) {
        return null;
    }
    return (
        <StackLayout gap="24">
            <ColumnLayout split="1">
                <Text style="heading 4">History</Text>
                {Math.ceil(props.data.length / itemsPerPage) > 1 ? (
                    <Pagination {...pagination} maximumVisibleButtons={4} />
                ) : (
                    <div />
                )}
            </ColumnLayout>
            <CardLayout>
                <CardLayout.Body>
                    <Table>
                        <Table.Header>
                            <Table.Column>POLICY</Table.Column>
                            <Table.Column width="1/7">INVOICE NO.</Table.Column>
                            <Table.Column width="1/7">POLICY NO.</Table.Column>
                            <Table.Column width="1/7">EFFECTIVE DATE</Table.Column>
                            <Table.Column width="1/9">AMOUNT</Table.Column>
                            <Table.Column width="1/10"></Table.Column>
                        </Table.Header>
                        <Table.Body>
                            {visibleItems.map((item) => (
                                <React.Fragment key={item.invoiceNumber}>
                                    <Table.Row initiallyExpanded={!!item.bundlePolicyInfo?.length}>
                                        <Table.Cell>
                                            <Text style="label 1">{item.lineOfBusiness}</Text>
                                        </Table.Cell>
                                        <Table.Cell>{item.invoiceNumber}</Table.Cell>
                                        <Table.Cell>{item.policyNumber}</Table.Cell>
                                        <Table.Cell>
                                            <DateDisplay value={item.effectiveDate} />
                                        </Table.Cell>
                                        <Table.Cell>
                                            <MoneyDisplay value={item.total} />
                                        </Table.Cell>
                                        <Table.Cell>{item.detailsLink}</Table.Cell>
                                    </Table.Row>
                                    {item.bundlePolicyInfo !== undefined && (
                                        <Table.Row expandable>
                                            <Table style="compact">
                                                <Table.Header>
                                                    <Table.Column>Policy</Table.Column>
                                                    <Table.Column width="1/7" responsive="tablet">
                                                        Invoice No.
                                                    </Table.Column>
                                                    <Table.Column width="1/7">
                                                        Policy No.
                                                    </Table.Column>
                                                    <Table.Column width="1/7">
                                                        Effective Date
                                                    </Table.Column>
                                                    <Table.Column width="1/9" responsive="mobile">
                                                        Amount
                                                    </Table.Column>
                                                    <Table.Column width="1/10" />
                                                </Table.Header>
                                                <Table.Body>
                                                    {item.bundlePolicyInfo.map((item, index) => (
                                                        <Table.Row key={index}>
                                                            <Table.Cell truncate>
                                                                {item.lineOfBusiness}
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                {item.invoiceNumber}
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                {item.policyNumber}
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                <DateDisplay
                                                                    value={item.effectiveDate}
                                                                />
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                <MoneyDisplay value={item.total} />
                                                            </Table.Cell>
                                                            <Table.Cell />
                                                        </Table.Row>
                                                    ))}
                                                </Table.Body>
                                            </Table>
                                        </Table.Row>
                                    )}
                                </React.Fragment>
                            ))}
                        </Table.Body>
                    </Table>
                </CardLayout.Body>
            </CardLayout>
            {!isBroker && (
                <CardLayout>
                    <CardLayout.Body>
                        <Text color="brand-500">
                            Don't see your invoice? If it was paid before 10/01/2018 we will not
                            have a record of it here.{' '}
                            <TextButton href="/support/contact">Contact us</TextButton> for the
                            record or for more information.
                        </Text>
                    </CardLayout.Body>
                </CardLayout>
            )}
        </StackLayout>
    );
}
