import { execute } from '@embroker/shotwell/core/UseCase';
import { container } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Immutable } from '@embroker/shotwell/core/types';
import { cast } from '@embroker/shotwell/core/types/Nominal';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { FormData, OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    ColumnLayout,
    HeroBanner,
    IconName,
    Loader,
    PageLayout,
    ReactProps,
    SidebarLayout,
    SidebarLayoutContext,
    Spinner,
    Stack,
    StackLayout,
    StatusMessage,
    StickyLayout,
    Text,
    TextButton,
    useModal,
    usePrevious,
} from '@embroker/ui-toolkit/v2';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Quote as PremiumFinanceQuote } from '../../../payments/entities/Quote';
import { GetPremiumFinanceQuoteIndication } from '../../../payments/useCases/GetPremiumFinanceQuoteIndication';
import { QuoteIndicationModal } from '../../../payments/view/components/modals/QuotesIndicationModal';
import { hasRole } from '../../../userOrg/entities/Session';
import { AppContext } from '../../../view/AppContext';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { WizardForm } from '../../../view/hooks/useWizardForm';
import { Quote } from '../../entities/Quote';
import { InsuranceApplicationRestriction } from '../../esp/types/InsuranceApplicationRestriction';
import { DocumentType } from '../../types/Document';
import { BrokerOrganizationName } from '../brokerQuotePages/BrokerOrganizationNameSection';
import { DefaultPageNavigation } from './DefaultPageNavigation';
import { Errors } from './Errors';
import { LeavingConfirmationModal } from './LeavingConfirmationModal';
import { PurchaseConfirmationModal } from './PurchaseConfirmationModal';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { GetConfig } from '../../../payments/useCases/GetConfig';

export interface PageComponentProps<T extends FormData, Q extends Quote> extends ReactProps<'div'> {
    applicationId: UUID;
    isSubmitting: boolean;
    quote: Q;
    status: WizardForm<OpaqueForm<T>>['status'];
    fields: WizardForm<OpaqueForm<T>>['fields'];
    value: WizardForm<OpaqueForm<T>>['value'];
    setValue: WizardForm<OpaqueForm<T>>['setValue'];
    setActiveStep: WizardForm<OpaqueForm<T>>['setActiveStep'];
    trigger: WizardForm<OpaqueForm<T>>['trigger'];
    validate: WizardForm<OpaqueForm<T>>['validate'];
    activePageName?: string;
    restriction?: Immutable<InsuranceApplicationRestriction>;
    higherLimitRequests?: Record<string, number>;
    showEpliStandaloneInCalifornia?: () => void;
}

export interface Page<T extends FormData, Q extends Quote> {
    name: string;
    component: React.FC<PageComponentProps<T, Q>>;
}

export interface QuoteBreakdownComponentProps<T extends FormData, Q extends Quote> {
    quote: Q;
    activePageName: string;
    status: WizardForm<OpaqueForm<T>>['status'];
    value: WizardForm<OpaqueForm<T>>['value'];
}

export type QuoteBreakdownComponent<T extends FormData, Q extends Quote> = React.FC<
    QuoteBreakdownComponentProps<T, Q>
>;

export type QuoteSummaryComponentProps<Q extends Quote> = {
    quote: Q;
};

export type QuoteSummaryComponent<Q extends Quote> = React.FC<QuoteSummaryComponentProps<Q>>;

export interface PageNavigationComponentProps<T extends FormData, Q extends Quote> {
    quote: Q;
    isSubmitting: boolean;
    hasNext: boolean;
    next: WizardForm<OpaqueForm<T>>['next'];
    hasPrevious: boolean;
    previous: WizardForm<OpaqueForm<T>>['previous'];
    handlePurchase(): void;
    trigger: WizardForm<OpaqueForm<T>>['trigger'];
    value: WizardForm<OpaqueForm<T>>['value'];
    status: WizardForm<OpaqueForm<T>>['status'];
    nextButtonLabel: string;
    disableNextButton?: boolean;
    action: WizardForm<OpaqueForm<T>>['action'];
}

export type PageNavigationComponent<T extends FormData, Q extends Quote> = React.FC<
    PageNavigationComponentProps<T, Q>
>;

export interface MenuItemComponentProp<T extends FormData> {
    isSubmitting: boolean;
    trigger: WizardForm<OpaqueForm<T>>['trigger'];
    isDisabled?: boolean;
    fileUrl?: URI;
    documentType: DocumentType;
    onGenerateFileClick: () => void;
}

export type MenuItemComponent<T extends FormData> = React.FC<MenuItemComponentProp<T>>;

export interface MenuItem<T extends FormData> {
    component: MenuItemComponent<T>;
    hiddenOnPages?: string[];
    isDisabled?: boolean;
    fileUrl?: URI;
    documentType?: DocumentType;
    onGenerateFileClick?: () => void;
}

const resolveNextButtonLabel = (hasNext: boolean, bindCoverageButtonLabel: string) => {
    if (hasNext) {
        return 'Continue';
    }
    return bindCoverageButtonLabel;
};

const getQuoteExpirationLabel = (daysToExpire: number): string => {
    return daysToExpire === -1
        ? 'Quote expired'
        : daysToExpire === 0
        ? 'Quote expires today'
        : daysToExpire === 1
        ? 'Quote expires in ' + daysToExpire + ' day'
        : 'Quote expires in ' + daysToExpire + ' days';
};

export interface QuoteLandingPageProps<T extends FormData, Q extends Quote> {
    title: string;
    brandName?: string;
    icon?: IconName;
    applicationId: UUID;
    quote?: Q;
    optionsWizardForm: WizardForm<OpaqueForm<T>>;
    pages: Page<T, Q>[];
    quoteBreakdown: QuoteBreakdownComponent<T, Q>;
    quoteSummary?: QuoteSummaryComponent<Q>;
    pageNavigation?: PageNavigationComponent<T, Q>;
    menuItems?: MenuItem<T>[];
    exitUrl?: URI;
    daysToExpire?: number;
    hasReferredPolicies?: boolean;
    restriction?: Immutable<InsuranceApplicationRestriction>;
    higherLimitRequests?: Immutable<Record<string, number>>;
    handleBindCoverage: () => void;
    disablePremiumFinancing?: boolean;
    bindCoverageButtonLabel?: string;
    showPurchaseConfirmationModal?: boolean;
    purchaseConfirmationTitle?: string;
    purchaseConfirmationText?: string;
    submitAction?: WizardForm<OpaqueForm<T>>['action'];
    showEpliStandaloneInCalifornia?: () => void;
}

export function QuoteLandingPage<T extends FormData, Q extends Quote>({
    title,
    brandName = 'Quote',
    icon = 'rocket',
    applicationId,
    quote,
    optionsWizardForm,
    pages,
    quoteBreakdown: QuoteBreakdown,
    quoteSummary,
    pageNavigation: PageNavigation = DefaultPageNavigation,
    menuItems = [],
    exitUrl = cast<URI>('/summary'),
    daysToExpire,
    handleBindCoverage,
    restriction,
    higherLimitRequests,
    disablePremiumFinancing = false,
    bindCoverageButtonLabel = 'Bind coverage',
    showPurchaseConfirmationModal = true,
    purchaseConfirmationTitle,
    purchaseConfirmationText,
    submitAction = 'default',
    showEpliStandaloneInCalifornia,
}: QuoteLandingPageProps<T, Q>) {
    const {
        hasNext,
        hasPrevious,
        setValue,
        value,
        next,
        previous,
        activePageIndex,
        activePageName,
        setActiveStep,
        trigger,
        fields,
        validate,
        status,
        messages,
        action,
    } = optionsWizardForm;

    const { activeSession } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');

    const nextButtonLabel = useMemo(
        () => resolveNextButtonLabel(hasNext, bindCoverageButtonLabel),
        [hasNext, bindCoverageButtonLabel],
    );

    const purchaseConfirmationModal = useModal();

    const sidebarLayoutContextRef = useRef<SidebarLayoutContext>(null);

    const closeSidebar = () => {
        if (sidebarLayoutContextRef.current) {
            sidebarLayoutContextRef.current.activatePane(0);
        }
    };

    const handleNext = () => {
        closeSidebar();
        next();
    };

    const handlePrevious = () => {
        closeSidebar();
        previous();
    };

    const handleConfirmPurchase = async () => {
        trigger(submitAction);
    };

    const handlePurchase = () => {
        handleBindCoverage();
        if (showPurchaseConfirmationModal === true) {
            purchaseConfirmationModal.show();
        } else {
            trigger(submitAction);
        }
    };

    const leavingConfirmationModal = useModal();

    const handleConfirmLeaving = () => {
        leavingConfirmationModal.hide();
        navigate(exitUrl);
    };

    const handleLeaving = () => {
        leavingConfirmationModal.show();
    };

    const { navigate } = useNavigation();

    const isSubmitting = status === 'submitting';

    const quoteIndicationModal = useModal();

    const previousQuote = usePrevious(quote);

    const [premiumFinanceIndication3MonthQuote, setPremiumFinanceIndication3MonthQuote] = useState<
        undefined | Immutable<EntityProps<PremiumFinanceQuote>>
    >();

    const [premiumFinanceIndication10MonthQuote, setPremiumFinanceIndication10MonthQuote] =
        useState<undefined | Immutable<EntityProps<PremiumFinanceQuote>>>();

    const [premiumFinanceIndicationError, setPremiumFinanceIndicationError] =
        useState<boolean>(false);

    const { result: config } = useUseCase(GetConfig);
    const useAscend = !isBroker && config && isOK(config) ? config.value.useAscend : false;

    const quarterlyInstallments = 3;
    const monthlyInstallments = 10;

    const abortController = useMemo(() => {
        return new AbortController();
    }, []);

    const getPremiumFinanceIndicationQuotes = useCallback(
        (quote: Quote) => {
            if (isBroker) {
                return;
            }
            abortController.abort();
            setPremiumFinanceIndication3MonthQuote(undefined);
            setPremiumFinanceIndication10MonthQuote(undefined);
            setPremiumFinanceIndicationError(false);

            execute(GetPremiumFinanceQuoteIndication, {
                coverageListInfo: [{ applicationId: quote.applicationId, quoteId: quote.id }],
                numberOfInstallments: quarterlyInstallments,
                startDate: quote.options.effectiveDate,
                abortSignal: abortController.signal,
            })
                .then((result) => {
                    if (isErr(result)) {
                        container.get<Logger>(Log).error(result.errors);
                        setPremiumFinanceIndicationError(true);
                        return;
                    }
                    setPremiumFinanceIndication3MonthQuote(result.value.quote);
                })
                .catch((err) => {
                    container.get<Logger>(Log).error(err);
                    setPremiumFinanceIndicationError(true);
                });

            execute(GetPremiumFinanceQuoteIndication, {
                coverageListInfo: [{ applicationId: quote.applicationId, quoteId: quote.id }],
                numberOfInstallments: monthlyInstallments,
                startDate: quote.options.effectiveDate,
                abortSignal: abortController.signal,
            })
                .then((result) => {
                    if (isErr(result)) {
                        container.get<Logger>(Log).error(result.errors);
                        setPremiumFinanceIndicationError(true);
                        return;
                    }
                    setPremiumFinanceIndication10MonthQuote(result.value.quote);
                })
                .catch((err) => {
                    container.get<Logger>(Log).error(err);
                    setPremiumFinanceIndicationError(true);
                });
        },
        [abortController, isBroker],
    );

    useEffect(() => {
        if (isBroker || !quote || quote == previousQuote || quote.totalPremium.amount === 0) {
            return;
        }
        getPremiumFinanceIndicationQuotes(quote);
    }, [quote, previousQuote, getPremiumFinanceIndicationQuotes, isBroker]);

    if (!quote) {
        return <Spinner />;
    }

    const defaultDocumentType = DocumentType.QuoteSummary;
    const defaultOnGenerateFileClick = () => trigger('downloadQuote');

    const howToPayMesage = (
        <React.Fragment>
            {useAscend ? (
                <Text>
                    You can pay your total premium with your bank debit card or a credit card, or
                    you can apply for premium financing.
                </Text>
            ) : (
                <Text>
                    You can pay your total premium with your bank debit card or a credit card, or
                    you can apply for premium financing. Preview a financing quote{' '}
                    <TextButton
                        onClick={() => {
                            quoteIndicationModal.show();
                            if (premiumFinanceIndicationError) {
                                getPremiumFinanceIndicationQuotes(quote);
                            }
                        }}
                    >
                        here
                    </TextButton>
                    .
                </Text>
            )}
            {premiumFinanceIndicationError && (
                <StatusMessage status={'error'}>
                    Sorry for inconvenience, Premium Finance Indication is not available at the
                    moment.
                </StatusMessage>
            )}
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <HeroBanner
                icon={icon}
                data-e2e="hero-banner"
                links={menuItems.map(
                    (
                        {
                            component: Component,
                            hiddenOnPages = [],
                            isDisabled = false,
                            fileUrl = undefined,
                            documentType = defaultDocumentType,
                            onGenerateFileClick = defaultOnGenerateFileClick,
                        },
                        index,
                    ) =>
                        hiddenOnPages.includes(activePageName) ? null : (
                            <Component
                                key={index}
                                isSubmitting={isSubmitting}
                                trigger={trigger}
                                isDisabled={isDisabled}
                                fileUrl={fileUrl}
                                documentType={documentType}
                                onGenerateFileClick={onGenerateFileClick}
                            />
                        ),
                )}
                onDismiss={handleLeaving}
            >
                <h1>
                    <Text as="span" style="heading 1" color="ui-50">
                        Your {title} {brandName}
                    </Text>
                    {isBroker && <BrokerOrganizationName />}
                </h1>
                {daysToExpire !== undefined && (
                    <h5>
                        <Text as="span" style="heading 5" color="ui-50" data-e2e="quote-expiration">
                            {getQuoteExpirationLabel(daysToExpire)}
                        </Text>
                    </h5>
                )}
            </HeroBanner>
            <PageLayout.Section>
                <SidebarLayout contextRef={sidebarLayoutContextRef} sidebar="right" gap="32">
                    <React.Fragment>
                        <Stack activeIndex={activePageIndex}>
                            {pages.map(({ component: Component }, index) => (
                                <Component
                                    key={index}
                                    applicationId={applicationId}
                                    isSubmitting={isSubmitting}
                                    quote={quote}
                                    value={value}
                                    status={status}
                                    fields={fields}
                                    setValue={setValue}
                                    setActiveStep={setActiveStep}
                                    trigger={trigger}
                                    validate={validate}
                                    activePageName={activePageName}
                                    restriction={restriction}
                                    higherLimitRequests={higherLimitRequests}
                                    showEpliStandaloneInCalifornia={showEpliStandaloneInCalifornia}
                                />
                            ))}
                        </Stack>
                        <SidebarLayout.MobileFooter>
                            <ColumnLayout>
                                <StackLayout gap="8">
                                    <Text>Total amount:</Text>
                                    <Text style="heading 5">
                                        <MoneyDisplay value={quote.totalPayable} />
                                    </Text>
                                </StackLayout>
                                <SidebarLayout.Link panelIndex={1}>Show Quote</SidebarLayout.Link>
                            </ColumnLayout>
                        </SidebarLayout.MobileFooter>
                    </React.Fragment>
                    <StickyLayout gap="32">
                        <StackLayout gap="32">
                            <QuoteBreakdown
                                quote={quote}
                                activePageName={activePageName}
                                status={status}
                                value={value}
                            />
                            <Errors messages={messages} action={action} trigger={trigger} />
                            <PageNavigation
                                quote={quote}
                                isSubmitting={isSubmitting}
                                hasNext={hasNext}
                                next={handleNext}
                                hasPrevious={hasPrevious}
                                previous={handlePrevious}
                                nextButtonLabel={nextButtonLabel}
                                handlePurchase={handlePurchase}
                                trigger={trigger}
                                value={value}
                                status={status}
                                action={action}
                            />
                            {!isBroker &&
                            quote.totalPremium.amount > 0 &&
                            !disablePremiumFinancing &&
                            quote?.status !== 'referred'
                                ? howToPayMesage
                                : null}
                        </StackLayout>
                    </StickyLayout>
                </SidebarLayout>
            </PageLayout.Section>
            {isSubmitting ? (
                <Loader heading={<Text style="heading 4">Finalizing your request</Text>} />
            ) : null}
            {!isSubmitting && (
                <PurchaseConfirmationModal
                    modal={purchaseConfirmationModal}
                    onConfirmPurchase={handleConfirmPurchase}
                    messages={messages}
                    trigger={trigger}
                    quote={quote}
                    quoteSummary={quoteSummary}
                    confirmationTitle={purchaseConfirmationTitle}
                    confirmationText={purchaseConfirmationText}
                />
            )}
            <LeavingConfirmationModal
                modal={leavingConfirmationModal}
                onConfirmLeaving={handleConfirmLeaving}
            />
            {!isBroker && quote.totalPremium.amount > 0 && !premiumFinanceIndicationError ? (
                <QuoteIndicationModal
                    modal={quoteIndicationModal}
                    minEffectiveDate={quote.options.effectiveDate}
                    quotes={{
                        quote10Months: premiumFinanceIndication10MonthQuote,
                        quote3Months: premiumFinanceIndication3MonthQuote,
                    }}
                />
            ) : null}
        </React.Fragment>
    );
}
