import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { OnboardingPrefillQuestionnaireData } from '../types/OnboardingPrefillQuestionnaireData';
import { UserOnboardingRepository } from '../repositories/UserOnboardingRepository';

/**
 * Request data for UpdateUserOnboardingQuestionnaireData use case
 * @param onboardingPrefillQuestionnaireData is the updated data to store
 */

export interface UpdateUserOnboardingQuestionnaireData extends UseCase {
    execute(request: OnboardingPrefillQuestionnaireData): AsyncResult<void, never>;
}

@injectable()
export class UpdateUserOnboardingQuestionnaireDataUseCase
    extends UseCase
    implements UpdateUserOnboardingQuestionnaireData
{
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/UpdateUserOnboardingQuestionnaireData');
    /**
     * Constructor for UpdateUserOnboardingQuestionnaireData use case class instance
     * @param eventBus An event bus this Use Case will publish events to.
     * @param userOnboardingRepository is repository used to update UserOnboardingDetails
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(UserOnboardingRepository)
        private userOnboardingRepository: UserOnboardingRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes UpdateUserOnboardingQuestionnaireData use case
     * Input is of type UpdateUserOnboardingQuestionnaireDataRequest
     * @returns Nothing if execution was successful
     */
    public async execute(
        onboardingPrefillQuestionnaireData: OnboardingPrefillQuestionnaireData,
    ): AsyncResult<void, never> {
        return await this.userOnboardingRepository.setUserOnboardingQuestionnaireData(
            onboardingPrefillQuestionnaireData,
        );
    }
}

export const UpdateUserOnboardingQuestionnaireData: UseCaseClass<UpdateUserOnboardingQuestionnaireData> =
    UpdateUserOnboardingQuestionnaireDataUseCase;
