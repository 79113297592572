import {
    API,
    CurrencyMarshaller,
    EspQuoteDetails,
    EspQuoteOptions,
    Policy,
    ShoppingEspQuoteInfoFromPolicyResponse,
} from '@embroker/shotwell-api/app';
import {
    InsuranceApplicationStatusCodeListItem,
    InsuranceApplicationStatusCodeListMap,
} from '@embroker/shotwell-api/enums';
import { isAPIError } from '@embroker/shotwell-api/errors';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { Money, USD } from '@embroker/shotwell/core/types/Money';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    isOK,
    Result,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { State } from '@embroker/shotwell/core/types/StateList';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { isAfter, isSameDay, isValid, parseISO, startOfDay } from 'date-fns';
import { QuotingEngineESP } from '../../../../shopping/types/enums';
import { ApplicationFetchFailed } from '../../../errors';
import { QuoteIdMap, QuoteList } from '../../../types/QuoteList';
import { ESPRenewalQuote } from '../../entities/ESPRenewalQuote';
import { ESPRenewalBeforeLastEndorsement, ESPRenewalConfigFetchFailed } from '../../errors';
import { Coverage } from '../../types/Coverage';
import { ESPRenewalConfig } from '../../types/ESPRenewalConfig';
import { UserInfo } from '../../types/UserInfo';
import { PurchaseESPRenewalResponse, QuoteRepository } from './index';

@injectable()
export class APIQuoteRepository implements QuoteRepository {
    public async getQuoteByApplicationId(
        applicationId: UUID,
    ): AsyncResult<
        ESPRenewalQuote,
        InvalidArgument | OperationFailed | ESPRenewalConfigFetchFailed
    > {
        const applicationResponse = await API.request('shopping/application', {
            id: applicationId,
        });
        if (isErr(applicationResponse)) {
            return handleOperationFailure(applicationResponse);
        }

        const application = applicationResponse.value;
        const appStatus = application.status as InsuranceApplicationStatusCodeListItem;

        const lastQuote = QuoteList.getLastQuote(application.quote_list);
        if (lastQuote == null) {
            return Failure(OperationFailed({ message: 'Empty quote list' }));
        }
        const espQuote = lastQuote.details.esp;
        const espQuoteOptions = lastQuote.options.esp;

        if (espQuote === null || espQuote === undefined) {
            return Failure(InvalidArgument({ argument: 'espQuote', value: espQuote }));
        }

        if (espQuoteOptions === null || espQuoteOptions === undefined) {
            return Failure(
                InvalidArgument({ argument: 'espQuoteOptions', value: espQuoteOptions }),
            );
        }

        const prevPolicyId =
            application.renewed_policy_id_list.length > 0
                ? application.renewed_policy_id_list[0]
                : null;

        if (!prevPolicyId) {
            return Failure(InvalidArgument({ argument: 'prevPolicyId', value: prevPolicyId }));
        }

        const prevPolicy = await API.request('shopping/esp_quote_info_from_policy', {
            policy_id: prevPolicyId,
        });

        if (isErr(prevPolicy)) {
            return handleOperationFailure(prevPolicy);
        }
        const quotableShoppingCoverageList = application.quotable_shopping_coverage_list ?? [];

        let questionnaireDataParsed: QuestionnaireData;
        try {
            questionnaireDataParsed = JSON.parse(application.questionnaire_data ?? '');
        } catch (e) {
            return Failure(OperationFailed({ message: 'Failed parsing questionnaire data' }));
        }
        const userInfo = getUserInfoFromQuestionnaireData(questionnaireDataParsed);
        if (isErr(userInfo)) {
            return Failure(InvalidArgument({ argument: 'userInfo', value: userInfo }));
        }

        const revenueInfo = getRevenueFromQuestionnaireRevenueList(
            questionnaireDataParsed.revenue_list,
        );
        if (isErr(revenueInfo)) {
            return revenueInfo;
        }

        const existingLiabilities = await this.getExistingLiabilities(prevPolicyId);
        if (isErr(existingLiabilities)) {
            return handleOperationFailure(existingLiabilities);
        }
        const coverageDictionary = await toCoverageDictionary(
            espQuote,
            espQuoteOptions,
            prevPolicy.value,
            existingLiabilities.value,
            quotableShoppingCoverageList,
        );

        if (isErr(coverageDictionary)) {
            return handleOperationFailure(coverageDictionary);
        }

        const coverageSelected = isCoverageSelected(espQuoteOptions);

        const configResponse = await this.getESPRenewalConfig();
        if (isErr(configResponse)) {
            return Failure(ESPRenewalConfigFetchFailed());
        }

        const resultQuote = await ESPRenewalQuote.create({
            isIndication: lastQuote.is_indication,
            id: lastQuote.id,
            fileKey: lastQuote.file_key ?? undefined,
            applicationId,
            status:
                InsuranceApplicationStatusCodeListMap[appStatus] !== 'Purchased'
                    ? InsuranceApplicationStatusCodeListMap[appStatus] === 'Referred'
                        ? 'referred'
                        : 'draft'
                    : 'accepted',
            options: {
                effectiveDate: prevPolicy.value.effective_period_end,
                coverage: coverageDictionary.value,
            },
            details: {
                totalCoverages: coverageSelected
                    ? CurrencyMarshaller.unmarshal(parseFloat(espQuote.lob_sum), 'lob_sum')
                    : USD(0),
                fees: coverageSelected && lastQuote.fees ? lastQuote.fees : USD(0),
                taxes: coverageSelected && lastQuote.taxes ? lastQuote.taxes : USD(0),
                policyFee: coverageSelected
                    ? CurrencyMarshaller.unmarshal(parseFloat(espQuote.policy_fee), 'policy_fee')
                    : USD(0),
                subtotal: coverageSelected
                    ? CurrencyMarshaller.unmarshal(parseFloat(espQuote.subtotal), 'subtotal')
                    : USD(0),
                renewalDiscount: coverageSelected
                    ? CurrencyMarshaller.unmarshal(
                          parseFloat(espQuote.renewal_discount ?? '0'),
                          'renewal_discount',
                      )
                    : USD(0),
                mwuaFee: espQuote.mwua_fee,
            },
            userInfo: userInfo.value as UserInfo,
            previousPolicyEndDate: prevPolicy.value.effective_period_end,
            revenue: CurrencyMarshaller.unmarshal(revenueInfo.value, 'revenue'),
            totalPremium:
                coverageSelected && lastQuote.total_premium ? lastQuote.total_premium : USD(0),
            annualTechnologyFee: coverageSelected ? lastQuote.annual_technology_fee : USD(0),
            totalPayable: coverageSelected ? lastQuote.total_payable : USD(0),
            submittedAt:
                application.submitted_at !== null ? parseISO(application.submitted_at) : undefined,
            newInsurerDocumentsReleaseDate: configResponse.value.newInsurerDocumentsReleaseDate,
            fiduciaryDocumentReleaseDate: configResponse.value.fiduciaryDocumentReleaseDate,
            referralReasons: application.ineligibility_reasons?.referral_reasons,
            espDocumentsAfterEoCyberSplitReleaseDate:
                configResponse.value.espDocumentsAfterEoCyberSplitReleaseDate,
        });

        if (isErr(resultQuote)) {
            return handleOperationFailure(resultQuote);
        }

        return Success(resultQuote.value);
    }

    public async purchaseESPRenewal(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<
        PurchaseESPRenewalResponse,
        InvalidArgument | OperationFailed | ESPRenewalBeforeLastEndorsement
    > {
        const quoteIdMap: QuoteIdMap = { [QuotingEngineESP]: quoteId };
        const purchaseResponse = await API.request('shopping/purchase', {
            id: applicationId,
            quote_id_map: quoteIdMap,
        });

        if (isErr(purchaseResponse)) {
            for (const error of purchaseResponse.errors) {
                if (isAPIError(error)) {
                    if (error.details.name === 'renewal_before_last_endorsement_date_not_allowed') {
                        return Failure(ESPRenewalBeforeLastEndorsement());
                    }
                }
            }

            return handleOperationFailure(purchaseResponse);
        }

        return Success({
            policyId: purchaseResponse.value.policyId,
            policyDoneTaskId: purchaseResponse.value.policy_issuance_task_id,
        });
    }

    public async updateQuote(
        applicationId: UUID,
        quoteOptions: EspQuoteOptions,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed> {
        const createQuoteResponse = await API.request('shopping/create_quote_task', {
            application_id: applicationId,
            quote_options: {
                esp: quoteOptions,
            },
        });
        if (isErr(createQuoteResponse)) {
            return handleOperationFailure(createQuoteResponse);
        }

        return Success(createQuoteResponse.value.task_id);
    }

    async getESPRenewalConfig(): AsyncResult<ESPRenewalConfig, InvalidArgument | OperationFailed> {
        const getConfigResponse = await API.request('global/get_config');

        if (isErr(getConfigResponse)) {
            return handleOperationFailure(getConfigResponse);
        }

        return ESPRenewalConfig.create({
            newInsurerDocumentsReleaseDate:
                getConfigResponse.value.esp_new_insurer_documents_release_date ?? undefined,
            fiduciaryDocumentReleaseDate:
                getConfigResponse.value.fiduciary_documents_release_date ?? undefined,
            espDocumentsAfterEoCyberSplitReleaseDate:
                getConfigResponse.value.esp_documents_after_eo_cyber_split_release_date ??
                undefined,
        });
    }

    async getESPQuestionnaireData(
        applicationId: UUID,
    ): AsyncResult<string, InvalidArgument | ApplicationFetchFailed> {
        const applicationResponse = await API.request('shopping/application', {
            id: applicationId,
        });
        if (isErr(applicationResponse)) {
            return Failure(ApplicationFetchFailed(applicationId));
        }
        const questionnaireData = applicationResponse.value.questionnaire_data;
        if (questionnaireData == null) {
            return Failure(
                InvalidArgument({ argument: 'questionnaire_data', value: questionnaireData }),
            );
        }

        return Success(questionnaireData);
    }

    async getExistingLiabilities(prevPolicyId: UUID): AsyncResult<string[]> {
        const isEligibleEspPolicy = (policy: Immutable<Policy>, serverTime: Date) => {
            return (
                policy.bor_status === 'b_o_r' &&
                policy.solartis_policy_number !== '' &&
                policy.view_mode === 'PolicyViewStatusCodeListPublished' &&
                (isSameDay(policy.effective_period_end, serverTime) ||
                    isAfter(policy.effective_period_end, serverTime)) &&
                policy.cancellation_date == null
            );
        };
        const getPolicyLiabilityList = (policyData: Immutable<Policy>): string[] => {
            if (
                !policyData ||
                !policyData.lob_other_liability ||
                !policyData.lob_other_liability.liability_section ||
                !policyData.lob_other_liability.liability_section.liability_list
            ) {
                return [];
            }

            return policyData.lob_other_liability.liability_section.liability_list.map(
                (liabilityDto) => liabilityDto.type_code,
            );
        };
        const userPoliciesResponse = await API.request('policy/user_policies_full');
        if (isErr(userPoliciesResponse)) {
            return handleOperationFailure(userPoliciesResponse);
        }
        const serverTimeResponse = await API.request('global/get_server_time');
        if (isErr(serverTimeResponse)) {
            return handleOperationFailure(serverTimeResponse);
        }
        const serverDay = startOfDay(serverTimeResponse.value.time);
        if (!isValid(serverDay)) {
            return Failure(
                OperationFailed({
                    message: 'Invalid server time',
                }),
            );
        }
        const espPolicies = userPoliciesResponse.value.filter(
            (policy) => isEligibleEspPolicy(policy, serverDay) && policy.id != prevPolicyId,
        );
        const espLiabilitiesList = espPolicies
            .flatMap((espPolicy) => getPolicyLiabilityList(espPolicy)) // deduplicate
            .reduce((acc: string[], cur: string) => {
                if (!acc.includes(cur)) {
                    acc.push(cur);
                }
                return acc;
            }, []);
        return Success(espLiabilitiesList);
    }
}

function isDNOIncludedInExistingLiabilities(existingLiabilities: readonly string[]) {
    return existingLiabilities.includes(
        'LiabilityCoverageCodeListIndemnifiableDirectorsAndOfficersLiability',
    );
}

function isEPLIIncludedInExistingLiabilities(existingLiabilities: readonly string[]) {
    return existingLiabilities.includes('LiabilityCoverageCodeListEmploymentPracticesLiability');
}

function isFiduciaryIncludedInExistingLiabilities(existingLiabilities: readonly string[]) {
    return existingLiabilities.includes('LiabilityCoverageCodeListFiduciaryLiability');
}

function isEoIncludedInExistingLiabilities(existingLiabilities: readonly string[]) {
    return existingLiabilities.includes(
        'LiabilityCoverageCodeListTechnologyAndMediaErrorsAndOmissions',
    );
}

function isTechCyberIncludedInExistingLiabilities(existingLiabilities: readonly string[]) {
    return existingLiabilities.includes(
        'LiabilityCoverageCodeListTechnologyAndMediaErrorsAndOmissions',
    );
}

function isDNOShoppingCoverageIncluded(shoppingCoverageList: readonly string[]) {
    return shoppingCoverageList.includes('ShoppingCoverageCodeListDirectorsAndOfficers');
}

function isEPLIShoppingCoverageIncluded(shoppingCoverageList: readonly string[]) {
    return shoppingCoverageList.includes('ShoppingCoverageCodeListEmploymentPractices');
}

function isFiduciaryShoppingCoverageIncluded(shoppingCoverageList: readonly string[]) {
    return shoppingCoverageList.includes('ShoppingCoverageCodeListFiduciary');
}

function isEoShoppingCoverageIncluded(shoppingCoverageList: readonly string[]) {
    return (
        shoppingCoverageList.includes('ShoppingCoverageCodeListProfessionalLiability') ||
        shoppingCoverageList.includes('ShoppingCoverageCodeListCyber')
    );
}

function isTechCyberShoppingCoverageIncluded(shoppingCoverageList: readonly string[]) {
    return (
        shoppingCoverageList.includes('ShoppingCoverageCodeListProfessionalLiability') ||
        shoppingCoverageList.includes('ShoppingCoverageCodeListCyber')
    );
}

interface QuestionnaireData {
    revenue_list: Array<RevenueListItem>;
    first_name: string;
    last_name: string;
    full_name?: string;
    title: string;
    state: string;
    company_name: string;
    raised_venture_funding?: boolean;
}

interface RevenueListItem {
    gross_revenue_total: number;
    fiscal_year: string;
}

function getUserInfoFromQuestionnaireData(
    questionnaireData: QuestionnaireData,
): Result<UserInfo, InvalidArgument> {
    let stateCode: Nullable<State> = null;
    const fullName =
        questionnaireData.full_name ||
        `${questionnaireData.first_name} ${questionnaireData.last_name}`;

    if (typeof questionnaireData.state === 'string' && questionnaireData.state.length > 0) {
        const result = State.validate(questionnaireData.state);
        if (isOK(result)) {
            stateCode = result.value;
        }
    }

    return Success({
        fullName: fullName,
        title: questionnaireData.title,
        usaState: stateCode,
        company: questionnaireData.company_name,
        isFunded: !!questionnaireData.raised_venture_funding,
    });
}

// TODO: This exists in multiple places, move this to a common quote helper. (EM-36041)
function getRevenueFromQuestionnaireRevenueList(
    revenue_list: Array<RevenueListItem>,
): Result<number, InvalidArgument> {
    if (revenue_list === null || revenue_list === undefined) {
        return Failure(InvalidArgument({ argument: 'revenue_list', value: revenue_list }));
    }

    const DECEMBER = 11;
    const currentTime = new Date(Date.now());
    const currentYear = currentTime.getFullYear();
    const currentMonth = currentTime.getMonth();
    const referenceYear = (currentMonth === DECEMBER ? currentYear : currentYear - 1).toString();

    const revenueListItem = revenue_list.find((item) => item.fiscal_year === referenceYear);

    if (revenueListItem === null || revenueListItem === undefined) {
        return Failure(InvalidArgument({ argument: 'revenueListItem', value: revenueListItem }));
    }

    const revenueDefined = Number.isFinite(revenueListItem.gross_revenue_total);

    if (!revenueDefined) {
        return Failure(InvalidArgument({ argument: 'grossRevenue', value: revenueDefined }));
    }

    return Success(revenueListItem.gross_revenue_total);
}

function enhancedFlagToCoverageLevel(
    enhancedFlag: Nullable<boolean>,
): 'plus' | 'standard' | undefined {
    switch (enhancedFlag) {
        case true:
            return 'plus';
        case false:
            return 'standard';
        default:
            return undefined;
    }
}

function isCoverageSelected(espQuoteOptions: EspQuoteOptions): boolean {
    if (espQuoteOptions?.directors_and_officers.selected) {
        return true;
    }
    if (espQuoteOptions?.employment_practices_liability.selected) {
        return true;
    }
    if (espQuoteOptions?.errors_and_omissions?.selected || espQuoteOptions?.technology?.selected) {
        return true;
    }
    if (espQuoteOptions?.fiduciary.selected) {
        return true;
    }
    return false;
}

function isCoverageOffered(
    quotable: boolean,
    existingLiability: boolean,
    selected: boolean | undefined,
) {
    // Offer coverage only if it's quotable.
    if (quotable) {
        if (selected) {
            return true;
        } else {
            return !existingLiability; // recommend coverage if it's not existing liability
        }
    }
    return false;
}

const toCoverageDictionary = async (
    espQuote: EspQuoteDetails,
    espQuoteOptions: EspQuoteOptions,
    prevPolicy: ShoppingEspQuoteInfoFromPolicyResponse,
    existingLiabilities: readonly string[],
    quotableShoppingCoverageList: readonly string[],
): AsyncResult<{ [key: string]: Coverage }, InvalidArgument | OperationFailed> => {
    const coverageDictionary: { [key: string]: Coverage } = {};

    if (
        isCoverageOffered(
            isDNOShoppingCoverageIncluded(quotableShoppingCoverageList),
            isDNOIncludedInExistingLiabilities(existingLiabilities),
            espQuoteOptions.directors_and_officers.selected,
        )
    ) {
        const dnoCoverage = Coverage.create({
            isSelected: espQuoteOptions.directors_and_officers.selected,
            previousLimit: CurrencyMarshaller.unmarshal(
                prevPolicy.directors_and_officers_limit,
                'directors_and_officers_previous_limit',
            ),
            previousPremium: CurrencyMarshaller.unmarshal(
                prevPolicy.directors_and_officers_premium,
                'directors_and_officers_previous_premium',
            ),
            previousRetention: CurrencyMarshaller.unmarshal(
                prevPolicy.directors_and_officers_retention,
                'directors_and_officers_previous_retention',
            ),
            type: 'dno',
            premium: CurrencyMarshaller.unmarshal(
                parseFloat(espQuote.directors_and_officers_premium),
                'directors_and_officers_premium',
            ),
            limit: CurrencyMarshaller.unmarshal(
                espQuoteOptions.directors_and_officers.limit,
                'directors_and_officers_limit',
            ),
            retention: CurrencyMarshaller.unmarshal(
                espQuoteOptions.directors_and_officers.retention,
                'directors_and_officers_retention',
            ),
            level: enhancedFlagToCoverageLevel(espQuoteOptions.directors_and_officers.enhanced),
        });
        if (isErr(dnoCoverage)) {
            return handleOperationFailure(dnoCoverage);
        }
        coverageDictionary['dno'] = dnoCoverage.value;
    }

    if (
        isCoverageOffered(
            isEPLIShoppingCoverageIncluded(quotableShoppingCoverageList),
            isEPLIIncludedInExistingLiabilities(existingLiabilities),
            espQuoteOptions.employment_practices_liability.selected,
        )
    ) {
        const epliCoverage = Coverage.create({
            isSelected: espQuoteOptions.employment_practices_liability.selected,
            previousLimit: CurrencyMarshaller.unmarshal(
                prevPolicy.employment_practices_liability_limit,
                'employment_practices_liability_previous_limit',
            ),
            previousPremium: CurrencyMarshaller.unmarshal(
                prevPolicy.employment_practices_liability_premium,
                'employment_practices_liability_previous_premium',
            ),
            previousRetention: CurrencyMarshaller.unmarshal(
                prevPolicy.employment_practices_liability_retention,
                'employment_practices_liability_previous_retention',
            ),
            type: 'epli',
            premium: CurrencyMarshaller.unmarshal(
                parseFloat(espQuote.employment_practices_liability_premium),
                'employment_practices_liability_premium',
            ),
            limit: CurrencyMarshaller.unmarshal(
                espQuoteOptions.employment_practices_liability.limit,
                'employment_practices_liability_limit',
            ),
            retention: CurrencyMarshaller.unmarshal(
                espQuoteOptions.employment_practices_liability.retention,
                'employment_practices_liability_retention',
            ),
            level: enhancedFlagToCoverageLevel(
                espQuoteOptions.employment_practices_liability.enhanced,
            ),
        });
        if (isErr(epliCoverage)) {
            return handleOperationFailure(epliCoverage);
        }
        coverageDictionary['epli'] = epliCoverage.value;
    }

    if (
        isCoverageOffered(
            isFiduciaryShoppingCoverageIncluded(quotableShoppingCoverageList),
            isFiduciaryIncludedInExistingLiabilities(existingLiabilities),
            espQuoteOptions.fiduciary.selected,
        )
    ) {
        const fiduciaryCoverage = Coverage.create({
            isSelected: espQuoteOptions.fiduciary.selected,
            previousLimit: CurrencyMarshaller.unmarshal(
                prevPolicy.fiduciary_limit,
                'fiduciary_previous_limit',
            ),
            previousPremium: CurrencyMarshaller.unmarshal(
                prevPolicy.fiduciary_premium,
                'fiduciary_previous_premium',
            ),
            previousRetention: CurrencyMarshaller.unmarshal(
                prevPolicy.fiduciary_retention,
                'fiduciary_previous_retention',
            ),
            type: 'fiduciary',
            premium: CurrencyMarshaller.unmarshal(
                parseFloat(espQuote.fiduciary_premium),
                'fiduciary_premium',
            ),
            limit: CurrencyMarshaller.unmarshal(1000000, 'fiduciary_premium'),
            retention: CurrencyMarshaller.unmarshal(0, 'fiduciary_retention'),
        });
        if (isErr(fiduciaryCoverage)) {
            return handleOperationFailure(fiduciaryCoverage);
        }
        coverageDictionary['fiduciary'] = fiduciaryCoverage.value;
    }

    if (
        espQuoteOptions.errors_and_omissions != undefined &&
        isCoverageOffered(
            isEoShoppingCoverageIncluded(quotableShoppingCoverageList),
            isEoIncludedInExistingLiabilities(existingLiabilities),
            espQuoteOptions.errors_and_omissions?.selected,
        )
    ) {
        const eoCoverage = Coverage.create({
            isSelected: espQuoteOptions.errors_and_omissions?.selected ?? false,
            previousLimit: CurrencyMarshaller.unmarshal(
                prevPolicy.errors_and_omissions_limit,
                'errors_and_omissions_previous_limit',
            ),
            previousPremium: CurrencyMarshaller.unmarshal(
                prevPolicy.errors_and_omissions_premium,
                'errors_and_omissions_previous_premium',
            ),
            previousRetention: CurrencyMarshaller.unmarshal(
                prevPolicy.errors_and_omissions_retention,
                'errors_and_omissions_previous_retention',
            ),
            type: 'eo',
            premium: CurrencyMarshaller.unmarshal(
                parseFloat(espQuote.errors_and_omissions_premium),
                'errors_and_omissions_premium',
            ),
            limit: CurrencyMarshaller.unmarshal(
                espQuoteOptions.errors_and_omissions?.limit,
                'errors_and_omissions_limit',
            ),
            retention: CurrencyMarshaller.unmarshal(
                espQuoteOptions.errors_and_omissions?.retention,
                'errors_and_omissions_retention',
            ),
            level: espQuoteOptions.errors_and_omissions?.enhanced ? 'plus' : 'standard',
        });
        if (isErr(eoCoverage)) {
            return handleOperationFailure(eoCoverage);
        }
        coverageDictionary['eo'] = eoCoverage.value;
    } else if (
        espQuoteOptions.technology != undefined &&
        espQuoteOptions.cyber != undefined &&
        isCoverageOffered(
            isTechCyberShoppingCoverageIncluded(quotableShoppingCoverageList),
            isTechCyberIncludedInExistingLiabilities(existingLiabilities),
            espQuoteOptions.technology.selected,
        )
    ) {
        const type = 'techCyber';
        const techPremium = CurrencyMarshaller.unmarshal(espQuote.technology_premium);
        const cyberPremium = CurrencyMarshaller.unmarshal(espQuote.cyber_premium);
        const premiumSumResult = Money.sum([techPremium, cyberPremium]);
        const premium = isOK(premiumSumResult) ? premiumSumResult.value : techPremium;
        const wasPreviousOldEOCyber = prevPolicy.technology_premium === undefined;
        if (wasPreviousOldEOCyber) {
            const techCyberCoverage = Coverage.create({
                type,
                isSelected: espQuoteOptions.technology.selected,
                previousLimit: CurrencyMarshaller.unmarshal(prevPolicy.errors_and_omissions_limit),
                previousSecondLimit: CurrencyMarshaller.unmarshal(
                    prevPolicy.errors_and_omissions_limit,
                ),
                previousPremium: CurrencyMarshaller.unmarshal(
                    prevPolicy.errors_and_omissions_premium,
                ),
                previousRetention: CurrencyMarshaller.unmarshal(
                    prevPolicy.errors_and_omissions_retention,
                ),
                previousSecondRetention: CurrencyMarshaller.unmarshal(
                    prevPolicy.errors_and_omissions_retention,
                ),
                premium: premium,
                limit: CurrencyMarshaller.unmarshal(espQuoteOptions.technology.limit),
                secondLimit: CurrencyMarshaller.unmarshal(espQuoteOptions.cyber.limit),
                retention: CurrencyMarshaller.unmarshal(espQuoteOptions.technology.retention),
                secondRetention: CurrencyMarshaller.unmarshal(espQuoteOptions.cyber.retention),
                level: espQuoteOptions.technology.enhanced ? 'plus' : 'standard',
            });
            if (isErr(techCyberCoverage)) {
                return handleOperationFailure(techCyberCoverage);
            }
            coverageDictionary[type] = techCyberCoverage.value;
        } else {
            const previousTechPremium = CurrencyMarshaller.unmarshal(prevPolicy.technology_premium);
            const previousCyberPremium = prevPolicy.cyber_premium
                ? CurrencyMarshaller.unmarshal(prevPolicy.cyber_premium)
                : USD(0);
            const previousPremiumSumResult = Money.sum([previousTechPremium, previousCyberPremium]);
            const previousPremium = isOK(previousPremiumSumResult)
                ? previousPremiumSumResult.value
                : previousTechPremium;

            const techCyberCoverage = Coverage.create({
                type,
                isSelected: espQuoteOptions.technology.selected,
                previousLimit: CurrencyMarshaller.unmarshal(prevPolicy.technology_limit),
                previousSecondLimit: prevPolicy.cyber_limit
                    ? CurrencyMarshaller.unmarshal(prevPolicy.cyber_limit)
                    : USD(0),
                previousPremium: previousPremium,
                previousRetention: CurrencyMarshaller.unmarshal(prevPolicy.technology_retention),
                previousSecondRetention: prevPolicy.cyber_retention
                    ? CurrencyMarshaller.unmarshal(prevPolicy.cyber_retention)
                    : USD(0),
                premium: premium,
                limit: CurrencyMarshaller.unmarshal(espQuoteOptions.technology.limit),
                secondLimit: CurrencyMarshaller.unmarshal(espQuoteOptions.cyber.limit),
                retention: CurrencyMarshaller.unmarshal(espQuoteOptions.technology.retention),
                secondRetention: CurrencyMarshaller.unmarshal(espQuoteOptions.cyber.retention),
                level: espQuoteOptions.technology.enhanced ? 'plus' : 'standard',
            });
            if (isErr(techCyberCoverage)) {
                return handleOperationFailure(techCyberCoverage);
            }
            coverageDictionary[type] = techCyberCoverage.value;
        }
    }

    return Success(coverageDictionary);
};
