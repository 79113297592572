import React, { useEffect } from 'react';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Icon, Modal, ModalActions, ModalState, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import { ModalLayout, ModalLayoutProps } from '@app/view/components/ModalLayout.view';
import { PublishShoppingUserClickEvent } from '@app/shopping/useCases/PublishShoppingUserClickEvent';

export interface LPLDeselectedModalProps extends ModalLayoutProps {
    modal: ModalState & ModalActions;
    onClose?: () => void;
    onDismiss?: () => void;
}

export function LPLDeselectedModal({
    modal,
    primaryAction,
    secondaryAction,
    onClose,
    onDismiss,
}: LPLDeselectedModalProps) {
    const handlePrimaryCTAClick = () => {
        modal.hide();
        if (primaryAction?.onClick) {
            primaryAction.onClick();
        }
    };

    const handleSecondaryCTAClick = () => {
        modal.hide();
        if (secondaryAction?.onClick) {
            secondaryAction.onClick();
        }
    };

    useEffect(() => {
        execute(PublishShoppingUserClickEvent, {
            clickEventName: 'LPL Deselected Pop up Displayed',
        });
    }, []);

    return (
        <Modal {...modal} onDismiss={onDismiss} onClose={onClose} size="small" dismissable>
            <ModalLayout
                primaryAction={{
                    ...primaryAction,
                    onClick: () => handlePrimaryCTAClick(),
                }}
                secondaryAction={
                    secondaryAction && {
                        ...secondaryAction,
                        onClick: () => handleSecondaryCTAClick(),
                    }
                }
            >
                <StackLayout gap="24">
                    <Icon color="accent-300" size="large" name="lpl" />
                    <StackLayout gap="4">
                        <Text color="brand-500" style="heading 5">
                            Lawyers Professional Liability
                        </Text>
                        <Text style="body 1">
                            We noticed you removed Lawyers Professional Liability (LPL) coverage.
                            Don't let uncertainties jeopardize your future. Protect your firm
                            against potential costly legal claims and unforeseen liabilities today.
                        </Text>
                    </StackLayout>
                </StackLayout>
            </ModalLayout>
        </Modal>
    );
}
