import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface ESPRenewalConfig {
    readonly newInsurerDocumentsReleaseDate?: Date;
    readonly fiduciaryDocumentReleaseDate?: Date;
    readonly espDocumentsAfterEoCyberSplitReleaseDate?: Date;
}

export const ESPRenewalConfig = {
    ...defineValidator<ESPRenewalConfig>({
        newInsurerDocumentsReleaseDate: Joi.date().optional(),
        fiduciaryDocumentReleaseDate: Joi.date().optional(),
        espDocumentsAfterEoCyberSplitReleaseDate: Joi.date().optional(),
    }),
    create(espRenewalConfig: ESPRenewalConfig) {
        return ESPRenewalConfig.validate(espRenewalConfig);
    },
};
