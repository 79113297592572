import { Joi, SchemaType } from '@embroker/shotwell/core/validation/schema';
import { MutuallyExclusiveProps } from '@embroker/ui-toolkit/v2';
import { FormQuestionDefinition, findDependencyChain } from '../hooks/useDataDrivenForm';
import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { ErrorMsgObject } from './validationObject';
import { ComplexFormFieldDefinitionMap, isComplexQuestionType } from './ComplexFieldTypes';

export type ValidationTypes = 'boolean' | 'string' | 'number' | 'conditional';

export type BooleanValidationDefinition = { isTrue: boolean };

export type NumberValidationDefinition = {
    lessThan?: number;
    greaterThan?: number;
    equal?: number;
};

export type StringValidationDefinition = {
    hasLengthGreaterThan?: number;
    hasLengthLessThan?: number;
    pattern?: RegExp;
    equal?: string;
};

export type ValidationKeys =
    | keyof StringValidationDefinition
    | keyof NumberValidationDefinition
    | keyof BooleanValidationDefinition;

export type ValidationDefinitionTypes =
    | BooleanValidationDefinition
    | StringValidationDefinition
    | NumberValidationDefinition;

export type ConditionalValidationDefinition = {
    [questionKey: string]: {
        match: Omit<ValidationTypeProps, 'conditional'>;
        validation: Omit<ValidationTypeProps, 'conditional'>;
    };
};

export type ValidationTypeProps = MutuallyExclusiveProps<{
    boolean: BooleanValidationDefinition;
    string: StringValidationDefinition;
    number: NumberValidationDefinition;
    conditional: ConditionalValidationDefinition;
}>;

export const buildBooleanValidator = (
    validationProps: BooleanValidationDefinition,
): SchemaType<any> => {
    let booleanValidator = Joi.boolean();

    const { isTrue } = validationProps;

    booleanValidator = isTrue ? booleanValidator.valid(true) : booleanValidator.valid(false);
    booleanValidator = booleanValidator.custom((value, { prefs: { context }, error }) =>
        error(`boolean.${isTrue}`),
    );

    return booleanValidator;
};

export const buildStringValidator = (
    validationProps: StringValidationDefinition,
): SchemaType<any> => {
    let stringValidator = Joi.string();

    const { hasLengthGreaterThan, hasLengthLessThan, pattern, equal } = validationProps;
    if (hasLengthGreaterThan) {
        const minLength = hasLengthGreaterThan + 1;
        stringValidator = stringValidator.min(minLength);
    }

    if (hasLengthLessThan) {
        const maxLength = hasLengthLessThan - 1;
        stringValidator = stringValidator.max(maxLength);
    }

    if (pattern) {
        stringValidator = stringValidator.pattern(pattern);
    }

    if (equal) {
        stringValidator = stringValidator.valid(equal);
    }

    return stringValidator;
};

export const buildNumberValidator = (
    validationProps: NumberValidationDefinition,
): SchemaType<any> => {
    let numberValidator = Joi.number();

    const { lessThan, greaterThan, equal } = validationProps;

    if (lessThan) {
        numberValidator = numberValidator.max(lessThan - 1);
    }

    if (greaterThan) {
        numberValidator = numberValidator.min(greaterThan + 1);
    }

    if (equal) {
        numberValidator = numberValidator.valid(equal);
    }

    return numberValidator;
};

export const buildConditionalValidator = (
    validationProps: ConditionalValidationDefinition,
): SchemaType<any> => {
    let validator: SchemaType<any> = Joi.any();

    for (const questionKey in validationProps) {
        if (validationProps.hasOwnProperty(questionKey)) {
            const { match, validation } = validationProps[questionKey];

            const conditionalFieldValidator = buildFieldValidator(match);
            const dependentFieldValidator = buildFieldValidator(validation);

            validator = validator.when(Joi.ref(`$${questionKey}`), {
                is: conditionalFieldValidator,
                then: dependentFieldValidator,
            });
        }
    }

    return validator;
};

export const buildFieldValidator = (props: Omit<ValidationTypeProps, 'conditional'>) => {
    let validator = Joi.any();
    const { string, number, boolean } = props;

    if (string) {
        validator = validator.concat(buildStringValidator(string));
    }
    if (number) {
        validator = validator.concat(buildNumberValidator(number));
    }
    if (boolean) {
        validator = validator.concat(buildBooleanValidator(boolean));
    }

    return validator;
};

export const buildValidator = (
    questionKey: string,
    formQuestionDefinitions: FormQuestionDefinition[],
): {
    validator: SchemaType<any>;
    formatValidationError: (error: InvalidArgument) => string;
} => {
    const defaultValidator = Joi.any();
    const defaultFormatValidationError = (error: InvalidArgument) =>
        ErrorMsgObject.getValidationMessage(error, validate);

    const question = formQuestionDefinitions.find(
        (questionDefinition) => questionDefinition.key === questionKey,
    );

    // Since the .find method above will return FormQuestionDefinition | undefined we need a fallback here, in reality this should never be undefined
    if (!question) {
        return {
            validator: Joi.any().optional(),
            formatValidationError: defaultFormatValidationError,
        };
    }

    const { validate, isRequired, isMultiple, questionType } = question;

    let validator = defaultValidator;
    let formatValidationError = defaultFormatValidationError;

    if (isComplexQuestionType(questionType)) {
        validator = ComplexFormFieldDefinitionMap[questionType].validator;
        formatValidationError = ComplexFormFieldDefinitionMap[questionType].formatValidationError;
    } else if (validate && Array.isArray(validate)) {
        for (const definition of validate) {
            const { conditional } = definition;
            if (conditional) {
                const conditionalValidator = buildConditionalValidator(conditional);
                validator = validator.concat(conditionalValidator);
            } else {
                const fieldValidator = buildFieldValidator(definition);
                validator = validator.concat(fieldValidator);
            }
        }
    }

    if (isMultiple) {
        validator = getValidatorAsArrayValidation(validator, isRequired);
    }

    if (isRequired) {
        validator = getValidatorAsRequired(validator);
    } else {
        validator = validator.optional();
    }

    return { validator, formatValidationError };
};

export const getValidatorAsArrayValidation = (
    validator: SchemaType<unknown>,
    isRequired?: boolean,
): SchemaType<unknown> => {
    return Joi.array().items(isRequired ? getValidatorAsRequired(validator) : validator);
};

export const getValidatorAsRequired = (validator: SchemaType<unknown>): SchemaType<unknown> => {
    return validator.invalid(null, '').required();
};

export const getFieldValidation = (
    questionKey: string,
    formQuestionDefinitions: FormQuestionDefinition[],
): {
    validator: SchemaType<any>;
    formatValidationError: (error: InvalidArgument) => string;
} => {
    const { validator: baseValidator, formatValidationError } = buildValidator(
        questionKey,
        formQuestionDefinitions,
    );

    const displayDependencyChain = findDependencyChain(formQuestionDefinitions, questionKey);

    let conditionalDisplayValidator = baseValidator;
    // Iterate over the display dependency chain from the last to the first
    for (let i = displayDependencyChain.length - 1; i >= 0; i--) {
        const { questionKey, condition } = displayDependencyChain[i].displayWhen;

        conditionalDisplayValidator = Joi.any().when(Joi.ref(`$${questionKey}`), {
            is: buildFieldValidator(condition),
            then: conditionalDisplayValidator, // using the previously constructed validator
            otherwise: Joi.optional(),
        });
    }

    return {
        validator: conditionalDisplayValidator,
        formatValidationError,
    };
};
