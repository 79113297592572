import { isDateAfter, isDateBefore } from '@embroker/service-app-engine';
import { Data, Nullable } from '@embroker/shotwell/core/types';
import { Money, USD } from '@embroker/shotwell/core/types/Money';
import { State } from '@embroker/shotwell/core/types/StateList';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    BoxLayout,
    Button,
    Card,
    ColumnLayout,
    Form,
    Icon,
    Modal,
    ModalActions,
    ModalState,
    StackLayout,
    Text,
    TextButton,
    Tooltip,
    useModal,
} from '@embroker/ui-toolkit/v2';
import { addDays } from 'date-fns';
import React, { useContext } from 'react';
import { WizardForm } from '../../../../../view/hooks/useWizardForm';
import { ESPDoDifferenceModal } from '../../../../esp/view/components/ESPCoveragesPage/ESPDoDifferenceModal';
import { ESPEplDifferenceModal } from '../../../../esp/view/components/ESPCoveragesPage/ESPEplDifferenceModal';
import {
    fiduciaryQuoteDocumentsPublishDetails,
    renewalQuoteDocumentsPublishDetails,
} from '../../../../espUtils/quoteDocumentUtils';
import { Coverage } from '../../../types/Coverage';
import { CoverageType, espRenewalCoverageDetailsMap, getLimitRetentionOptions } from '../../config';
import { ESPRenewalQuoteOptionsFormData } from '../ESPRenewalQuoteLandingPage';
import { ESPEoCyberDifferenceModal } from './ESPEoCyberDifferenceModal';
import {
    coverageIsSelectedField,
    coverageLevelField,
    coverageLimitField,
    coverageRetentionField,
} from './ESPRenewalCoveragePage';
import { AppContext } from '../../../../../view/AppContext';

interface ESPRenewalRecommendedCoverageProps {
    coverage: Coverage;
    isSubmitting: boolean;
    revenue: Money;
    fields: WizardForm<OpaqueForm<ESPRenewalQuoteOptionsFormData>>['fields'];
    newInsurerDocumentsReleaseDate?: Date;
    fiduciaryDocumentsReleaseDate?: Date;
    submittedAt?: Date;
    state?: Nullable<State>;
    isQuoteReferred: boolean;
    effectiveDate?: Date;
}

export const ESPRenewalRecommendedCoverage = ({
    coverage,
    fields,
    revenue,
    isSubmitting,
    newInsurerDocumentsReleaseDate,
    fiduciaryDocumentsReleaseDate,
    submittedAt,
    state,
    isQuoteReferred,
    effectiveDate,
}: ESPRenewalRecommendedCoverageProps) => {
    const limitField = coverageLimitField(coverage.type, fields);
    const retentionField = coverageRetentionField(coverage.type, fields);
    const levelField = coverage.level && coverageLevelField(coverage.type, fields);
    const isSelectedField = coverageIsSelectedField(coverage.type, fields);
    const doModal = useModal();
    const epliModal = useModal();
    const eoCyberModal = useModal();
    const modals: Data<ModalState & ModalActions> = {
        dno: doModal,
        epli: epliModal,
        eo: eoCyberModal,
        techCyber: eoCyberModal,
    };

    const { globalConfig } = useContext(AppContext);

    if (!limitField || !retentionField) {
        return null;
    }

    const getUrlBasedOnSubmittedDate = (
        coverageType: CoverageType,
        newInsurerDocumentsReleaseDate?: Date,
        fiduciaryDocumentsReleaseDate?: Date,
        submittedAt?: Date,
        state?: Nullable<State>,
        effectiveDate?: Date,
    ): string => {
        const espIptChangesEnabled = globalConfig?.espIptChangesEnabled;
        const releaseDate = globalConfig?.espIptChangesReleaseDate;
        if (
            espIptChangesEnabled &&
            releaseDate &&
            effectiveDate &&
            !isDateBefore(effectiveDate, releaseDate)
        ) {
            switch (coverageType) {
                case 'dno':
                case 'epli':
                case 'techCyber':
                    return espRenewalCoverageDetailsMap[coverageType].document[
                        coverage.level == 'standard'
                            ? 'post_ipt_2024_standard'
                            : 'post_ipt_2024_plus'
                    ];
            }
        }

        if (coverageType == 'fiduciary') {
            if (fiduciaryDocumentsReleaseDate !== undefined && submittedAt !== undefined) {
                if (
                    isSubmissionDateAfterPublishDate(
                        submittedAt,
                        fiduciaryDocumentsReleaseDate,
                        state,
                    )
                ) {
                    return espRenewalCoverageDetailsMap['fiduciary'].document.new_standard;
                }
            }
        }

        if (coverageType == 'dno' || coverageType == 'epli') {
            if (newInsurerDocumentsReleaseDate !== undefined && submittedAt !== undefined) {
                if (
                    isSubmissionDateAfterPublishDate(
                        submittedAt,
                        newInsurerDocumentsReleaseDate,
                        state,
                    )
                ) {
                    return espRenewalCoverageDetailsMap[coverageType].document[
                        coverage.level == 'standard' ? 'new_standard' : 'new_plus'
                    ];
                }
            }
        }

        return coverageType != 'fiduciary'
            ? espRenewalCoverageDetailsMap[coverageType].document[coverage.level ?? 'old']
            : espRenewalCoverageDetailsMap['fiduciary'].document.standard;
    };

    const isSubmissionDateAfterPublishDate = (
        submittedAt: Date,
        documentReleaseDate: Date,
        state?: Nullable<State>,
    ): boolean => {
        let daysToPublishByState = 0;

        if (state !== undefined && state !== null) {
            daysToPublishByState =
                coverage.type == 'fiduciary'
                    ? fiduciaryQuoteDocumentsPublishDetails[state].daysToPublish
                    : renewalQuoteDocumentsPublishDetails[state].daysToPublish;
        }

        return isDateAfter(submittedAt, addDays(documentReleaseDate, daysToPublishByState));
    };

    function updateRecommendedCoverages() {
        isSelectedField?.props?.onChange?.({
            target: {
                value: true,
            },
        });
    }

    if (globalConfig === undefined) {
        return null;
    }

    if (coverage.type == 'techCyber') {
        return (
            <Card
                menuItems={[
                    <TextButton
                        target="_blank"
                        key={coverage.type}
                        href={getUrlBasedOnSubmittedDate(
                            coverage.type,
                            newInsurerDocumentsReleaseDate,
                            fiduciaryDocumentsReleaseDate,
                            submittedAt,
                            state,
                            effectiveDate,
                        )}
                    >
                        Download coverage details
                    </TextButton>,
                ]}
            >
                <Card.Header>
                    <Icon name={espRenewalCoverageDetailsMap[coverage.type].icon} />
                    <Text style="heading 5">
                        {espRenewalCoverageDetailsMap[coverage.type].title}
                    </Text>
                </Card.Header>
                <Card.Body>
                    <StackLayout>
                        <Text>{espRenewalCoverageDetailsMap[coverage.type].text}</Text>
                        <Text style="heading 5">
                            Tech E&O
                            <Tooltip
                                iconSize="small"
                                trigger="click"
                                text={
                                    levelField?.props.value === 'plus'
                                        ? 'The Tech E&O limits are for the Liability coverages, including Insuring Agreements 1, 2, 3, and 4 on the "PLUS" form.'
                                        : 'The Tech E&O limits are for the Liability coverages, including Insuring Agreements 1a, 1b, and 2a on the "Standard" form.'
                                }
                            />
                        </Text>
                        <ColumnLayout
                            gap="16"
                            responsive={{ screenWidth: { smallerThan: 'tablet' } }}
                        >
                            <Form.Field
                                type={fields.techLimit.type}
                                inputProps={{
                                    ...fields.techLimit.props,
                                    filterable: false,
                                    items: getLimitRetentionOptions(
                                        coverage.type,
                                        coverage.previousLimit,
                                        undefined,
                                        revenue,
                                    ).limits,
                                    label: 'Limit',
                                    disabled: true,
                                }}
                            />
                            <Form.Field
                                type={fields.techRetention.type}
                                inputProps={{
                                    ...fields.techRetention.props,
                                    filterable: false,
                                    items: getLimitRetentionOptions(
                                        coverage.type,
                                        coverage.previousLimit,
                                        undefined,
                                        revenue,
                                    ).retentions,
                                    label: 'Retention',
                                    disabled: true,
                                }}
                            />
                        </ColumnLayout>
                        <ColumnLayout>
                            <BoxLayout gap="12" className="u-1/2">
                                <StackLayout gap="none">
                                    <Text style="microcopy">Previous Limit:</Text>
                                    <Text style="body 1" color="brand-500">
                                        {!Money.isEqual(coverage.previousLimit, USD(0)) ? (
                                            <MoneyDisplay
                                                value={coverage.previousLimit}
                                                fractionDigits={0}
                                            />
                                        ) : (
                                            <Text style="microcopy">Not included</Text>
                                        )}
                                    </Text>
                                </StackLayout>
                            </BoxLayout>
                            <BoxLayout gap="12" className="u-1/2">
                                <StackLayout gap="none">
                                    <Text style="microcopy">Previous Retention:</Text>
                                    <Text style="body 1" color="brand-500">
                                        {!Money.isEqual(coverage.previousRetention, USD(0)) ? (
                                            <MoneyDisplay
                                                value={coverage.previousLimit}
                                                fractionDigits={0}
                                            />
                                        ) : (
                                            <Text style="microcopy">Not included</Text>
                                        )}
                                    </Text>
                                </StackLayout>
                            </BoxLayout>
                        </ColumnLayout>
                        <Text style="heading 5">
                            1st Party Expenses
                            <Tooltip
                                trigger="click"
                                iconSize="small"
                                text="These cover 1st party expenses after a network security event. These limits are rarely contractually required, so you’re unlikely to need higher coverage limits — plus, lowering the limit is a good way to reduce your premium!"
                            />
                        </Text>
                        <ColumnLayout
                            gap="16"
                            responsive={{ screenWidth: { smallerThan: 'tablet' } }}
                        >
                            <Form.Field
                                type={fields.cyberLimit.type}
                                inputProps={{
                                    ...fields.cyberLimit.props,
                                    filterable: false,
                                    items: getLimitRetentionOptions(
                                        coverage.type,
                                        coverage.previousSecondLimit ?? USD(0),
                                        undefined,
                                        revenue,
                                    ).limits,
                                    label: 'Limit',
                                    disabled: true,
                                }}
                            />
                            <Form.Field
                                type={fields.cyberRetention.type}
                                inputProps={{
                                    ...fields.cyberRetention.props,
                                    filterable: false,
                                    items: getLimitRetentionOptions(
                                        coverage.type,
                                        coverage.previousSecondRetention ?? USD(0),
                                        undefined,
                                        revenue,
                                    ).retentions,
                                    label: 'Retention',
                                    disabled: true,
                                }}
                            />
                        </ColumnLayout>
                        <ColumnLayout>
                            <BoxLayout gap="12" className="u-1/2">
                                <StackLayout gap="none">
                                    <Text style="microcopy">Previous Limit:</Text>
                                    <Text style="body 1" color="brand-500">
                                        {!Money.isEqual(
                                            coverage.previousSecondLimit ?? USD(0),
                                            USD(0),
                                        ) ? (
                                            <MoneyDisplay
                                                value={coverage.previousSecondLimit ?? USD(0)}
                                                fractionDigits={0}
                                            />
                                        ) : (
                                            <Text style="microcopy">Not included</Text>
                                        )}
                                    </Text>
                                </StackLayout>
                            </BoxLayout>
                            <BoxLayout gap="12" className="u-1/2">
                                <StackLayout gap="none">
                                    <Text style="microcopy">Previous Retention:</Text>
                                    <Text style="body 1" color="brand-500">
                                        {!Money.isEqual(
                                            coverage.previousSecondRetention ?? USD(0),
                                            USD(0),
                                        ) ? (
                                            <MoneyDisplay
                                                value={coverage.previousSecondRetention ?? USD(0)}
                                                fractionDigits={0}
                                            />
                                        ) : (
                                            <Text style="microcopy">Not included</Text>
                                        )}
                                    </Text>
                                </StackLayout>
                            </BoxLayout>
                        </ColumnLayout>
                        {levelField && !isQuoteReferred ? (
                            <React.Fragment>
                                <ColumnLayout split="-1">
                                    <Text style="heading 5">Select your coverage </Text>
                                    <TextButton onClick={modals[coverage.type].show}>
                                        What's the difference?
                                    </TextButton>
                                </ColumnLayout>
                                <Form.Field
                                    type={levelField.type}
                                    inputProps={{
                                        ...levelField.props,
                                        items: [
                                            {
                                                value: 'plus',
                                                title: 'Plus',
                                            },
                                            {
                                                value: 'standard',
                                                title: 'Standard',
                                            },
                                        ],
                                        disabled: true,
                                    }}
                                />
                            </React.Fragment>
                        ) : null}
                    </StackLayout>
                </Card.Body>
                <Card.Footer>
                    <Button
                        appearance="primary"
                        onClick={updateRecommendedCoverages}
                        disabled={isSubmitting}
                    >
                        Add Coverage
                    </Button>
                </Card.Footer>
                <Modal size="large" {...eoCyberModal}>
                    <ESPEoCyberDifferenceModal />
                </Modal>
            </Card>
        );
    }

    return (
        <Card
            menuItems={[
                <TextButton
                    target="_blank"
                    key={coverage.type}
                    href={getUrlBasedOnSubmittedDate(
                        coverage.type,
                        newInsurerDocumentsReleaseDate,
                        fiduciaryDocumentsReleaseDate,
                        submittedAt,
                        state,
                        effectiveDate,
                    )}
                >
                    Download coverage details
                </TextButton>,
            ]}
        >
            <Card.Header>
                <Icon name={espRenewalCoverageDetailsMap[coverage.type].icon} />
                <Text style="heading 5">{espRenewalCoverageDetailsMap[coverage.type].title}</Text>
            </Card.Header>
            <Card.Body>
                <StackLayout>
                    <Text>{espRenewalCoverageDetailsMap[coverage.type].text}</Text>
                    <ColumnLayout gap="16" responsive={{ screenWidth: { smallerThan: 'tablet' } }}>
                        <Form.Field
                            type={limitField.type}
                            inputProps={{
                                ...limitField.props,
                                filterable: false,
                                items: getLimitRetentionOptions(
                                    coverage.type,
                                    coverage.previousLimit,
                                    undefined,
                                    revenue,
                                ).limits,
                                label: 'Limit',
                                disabled: true,
                            }}
                        />
                        <Form.Field
                            type={retentionField.type}
                            inputProps={{
                                ...retentionField.props,
                                filterable: false,
                                items: getLimitRetentionOptions(
                                    coverage.type,
                                    coverage.previousLimit,
                                    undefined,
                                    revenue,
                                ).retentions,
                                label: 'Retention',
                                disabled: true,
                            }}
                        />
                    </ColumnLayout>
                    <ColumnLayout>
                        <BoxLayout gap="12" className="u-1/2">
                            <StackLayout gap="none">
                                <Text style="microcopy">Previous Limit:</Text>
                                <Text style="body 1" color="brand-500">
                                    {!Money.isEqual(coverage.previousLimit, USD(0)) ? (
                                        <MoneyDisplay
                                            value={coverage.previousLimit}
                                            fractionDigits={0}
                                        />
                                    ) : (
                                        <Text style="microcopy">Not included</Text>
                                    )}
                                </Text>
                            </StackLayout>
                        </BoxLayout>
                        <BoxLayout gap="12" className="u-1/2">
                            <StackLayout gap="none">
                                <Text style="microcopy">Previous Retention:</Text>
                                <Text style="body 1" color="brand-500">
                                    {!Money.isEqual(coverage.previousRetention, USD(0)) ? (
                                        <MoneyDisplay
                                            value={coverage.previousLimit}
                                            fractionDigits={0}
                                        />
                                    ) : (
                                        <Text style="microcopy">Not included</Text>
                                    )}
                                </Text>
                            </StackLayout>
                        </BoxLayout>
                    </ColumnLayout>
                    {levelField && !isQuoteReferred ? (
                        <React.Fragment>
                            <ColumnLayout split="-1">
                                <Text style="heading 5">Select your coverage </Text>
                                <TextButton onClick={modals[coverage.type].show}>
                                    What's the difference?
                                </TextButton>
                            </ColumnLayout>
                            <Form.Field
                                type={levelField.type}
                                inputProps={{
                                    ...levelField.props,
                                    items: [
                                        {
                                            value: 'plus',
                                            title: 'Plus',
                                        },
                                        {
                                            value: 'standard',
                                            title: 'Standard',
                                        },
                                    ],
                                    disabled: true,
                                }}
                                messages={levelField.messages}
                            />
                        </React.Fragment>
                    ) : null}
                </StackLayout>
            </Card.Body>
            <Card.Footer>
                <Button
                    appearance="primary"
                    onClick={updateRecommendedCoverages}
                    disabled={isSubmitting}
                >
                    Add Coverage
                </Button>
            </Card.Footer>
            <Modal size="large" {...eoCyberModal}>
                <ESPEoCyberDifferenceModal />
            </Modal>
            <Modal size="large" {...doModal}>
                <ESPDoDifferenceModal />
            </Modal>
            <Modal size="large" {...epliModal}>
                <ESPEplDifferenceModal />
            </Modal>
        </Card>
    );
};
