import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Button, ColumnLayout, Nullable } from '@embroker/ui-toolkit/v2';
import React, { useState } from 'react';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { OrganizationSearch } from '../organizations/OrganizationSearch';

function isValidSearch(input: string) {
    return input.length > 0;
}

function createLabel(input: string) {
    return `Search for "${input}"`;
}

interface ClientPageHeaderProps {
    onQuoteStart: () => void;
}

export function ClientPageHeader({ onQuoteStart }: ClientPageHeaderProps) {
    const [input, setInput] = useState('');
    const { navigate } = useNavigation();

    const handleSelectOrganization = (item: { value: Nullable<UUID>; label: string }) => {
        if (item.value != null) {
            navigate(
                URI.build('/broker/organization-info', {
                    organizationId: item.value,
                }),
            );
        } else {
            navigate(
                URI.build('/broker/companies', {
                    filter: input,
                }),
            );
        }
    };

    const handleOnSearch = (input: string) => {
        navigate(
            URI.build('/broker/companies', {
                filter: input,
            }),
        );
    };

    return (
        <ColumnLayout gap="48" split="-1">
            <OrganizationSearch
                placeholder="Search by client name"
                className="u-1/1"
                onSelect={handleSelectOrganization}
                onSearch={handleOnSearch}
                isValidSearch={isValidSearch}
                searchLabel={createLabel}
                inputValue={input}
                onInputChange={(value) => setInput(value)}
                minimumTextLength={3}
                count={5}
            />
            <Button appearance="action" onClick={onQuoteStart}>
                Get a Quote
            </Button>
        </ColumnLayout>
    );
}
