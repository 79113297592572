import { isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { map, redirect, route, withData } from 'navi';
import React from 'react';
import { GetGlobalConfig } from '../../../config/useCases/GetGlobalConfigUseCase';
import { redirectToWhoopsPage } from '../../../view/errors';
import { GetLastBundleQuote } from '../../useCases/GetLastBundleQuote';
import { BundleQuoteLandingPage } from '../components/BundleQuoteLandingPage';
import { BundleQuoteThankYou } from '../components/BundleQuoteThankYou';
import { BundleQuoteHigherLimitAndReferralThankYou } from '../components/BundleQuoteHigherLimitAndReferralThankYou';
import { GetApplication } from '../../../shopping/useCases/GetApplication';
import { Application } from '../../../shopping/entities/Application';
import { container } from '@embroker/shotwell/core/di';
import { GrowthBookExperimentationService } from '@app/experimentation/services/GrowthBookExperimentationService';
import { URI } from '@embroker/shotwell/core/types/URI';
import { QuoteComparison } from '@app/shopping/view/components/QuoteComparison';

const bundleQuoteWizardRouteHandler = map(async (request) => {
    const { applicationId } = request.query;
    const validatedApplicationIdResult = UUID.validate(applicationId);

    if (isErr(validatedApplicationIdResult)) {
        return redirectToWhoopsPage(validatedApplicationIdResult.errors);
    }

    const getLastQuoteResult = await execute(GetLastBundleQuote, {
        applicationId: validatedApplicationIdResult.value,
    });

    if (isErr(getLastQuoteResult)) {
        return redirectToWhoopsPage(getLastQuoteResult.errors);
    }

    const getApplicationResult = await execute(GetApplication, {
        applicationId: validatedApplicationIdResult.value,
    });

    if (isErr(getApplicationResult)) {
        return redirectToWhoopsPage(getApplicationResult.errors);
    }

    const globalConfigResult = await execute(GetGlobalConfig);
    if (isErr(globalConfigResult)) {
        return redirectToWhoopsPage(globalConfigResult.errors);
    }

    // TODO: https://embroker.atlassian.net/browse/EM-44651
    // This logic is needed to prevent a user from re-entering the quote comparison page
    // This is not a robust patern. Re-doing the wizard flow to accomodate the quote comparison page
    // Since this is an AB test we can look into a this if/when the test is successful
    const isQuoteCoveragePage = request.mountpath === '/shopping/bundle/coverage';

    const isLawBundleApplication =
        getApplicationResult.value.application.appType === 'AppTypeCodeListLawBundle';

    const hasReferral =
        getLastQuoteResult.value.isAnyCoverageReferred() ||
        getLastQuoteResult.value.isAnyCoverageReferredAwaitingReview();

    const isQuoteComparisonEligible =
        isLawBundleApplication &&
        !hasReferral &&
        !isQuoteCoveragePage &&
        !getLastQuoteResult.value.areAllCoveragesNotEligible();

    if (isQuoteComparisonEligible) {
        if (
            container
                .get<GrowthBookExperimentationService>(GrowthBookExperimentationService)
                .getFeatureValue('quote-comparison', false)
        ) {
            const url = URI.build('/shopping/bundle/quote-comparison', {
                applicationId: getApplicationResult.value.application.id,
            });
            return redirect(url);
        }
    }

    return route({
        title: 'Your Customizable Quote',
        view: (
            <BundleQuoteLandingPage
                initialBundleQuote={getLastQuoteResult.value}
                globalConfig={globalConfigResult.value.config}
                application={getApplicationResult.value.application as unknown as Application}
            />
        ),
    });
});

const bundleThankyouWizardRouteHandler = map(async (request) => {
    const { applicationId } = request.query;
    const validatedApplicationIdResult = UUID.validate(applicationId);

    if (isErr(validatedApplicationIdResult)) {
        return redirectToWhoopsPage(validatedApplicationIdResult.errors);
    }

    const getLastQuoteResult = await execute(GetLastBundleQuote, {
        applicationId: validatedApplicationIdResult.value,
    });

    if (isErr(getLastQuoteResult)) {
        return redirectToWhoopsPage(getLastQuoteResult.errors);
    }

    const isReferred =
        getLastQuoteResult.value.isAnyCoverageReferred() ||
        getLastQuoteResult.value.isAnyCoverageReferredAwaitingReview();
    if (isReferred) {
        return route({
            view: <BundleQuoteHigherLimitAndReferralThankYou />,
        });
    }

    return route({
        view: <BundleQuoteThankYou />,
    });
});

export const bundleRoutes = {
    '/': map((request) =>
        withData(
            { url: request.mountpath, page: undefined, fullscreen: true },
            bundleQuoteWizardRouteHandler,
        ),
    ),
    '/:page': map((request) =>
        withData(
            { url: request.mountpath, page: request.params.page, fullscreen: true },
            bundleQuoteWizardRouteHandler,
        ),
    ),
    '/thank-you': map((request) =>
        withData(
            { url: request.mountpath, page: undefined, fullscreen: true },
            bundleThankyouWizardRouteHandler,
        ),
    ),
    '/quote-comparison': map(async (request) => {
        const { applicationId } = request.query;
        const validatedApplicationIdResult = UUID.validate(applicationId);

        if (isErr(validatedApplicationIdResult)) {
            return redirectToWhoopsPage(validatedApplicationIdResult.errors);
        }

        const getLastQuoteResult = await execute(GetLastBundleQuote, {
            applicationId: validatedApplicationIdResult.value,
        });

        if (isErr(getLastQuoteResult)) {
            return redirectToWhoopsPage(getLastQuoteResult.errors);
        }

        return route({
            title: 'Quote Comparison',
            data: { fullscreen: true },
            view: <QuoteComparison bundleQuote={getLastQuoteResult.value} />,
        });
    }),
};
