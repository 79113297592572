import { CoverLayout, Placeholder, StackLayout, Text, Tooltip } from '@embroker/ui-toolkit/v2';
import React from 'react';

export function CustomizeCertificateStepGuidelines() {
    return (
        <CoverLayout>
            <StackLayout split="-1" gap="24">
                <StackLayout gap="4">
                    <Text style="overline" color={'brand-500'}>
                        Step 3
                    </Text>
                    <Text style={'heading 3'}>Build for you, by you</Text>
                </StackLayout>
                <Text style="body 1">
                    You may not need to send information for all of your policies to one recipient.
                    So, if you want to customize, you can.
                    <Tooltip
                        iconSize="small"
                        text={
                            <React.Fragment>
                                Customizing your certificate means including or omitting select
                                insurance policies and policy provisions. Policy provisions
                                typically contained in the certificate are Additional Insured and
                                Waiver of Subrogation.
                                <br />
                                <br />
                                <b>Additional Insured</b> provisions simply add another insured
                                party to the policy. Simple as that.
                                <br />
                                <br />
                                <b>A Waiver of Subrogation</b> prevents your insurer (Embroker in
                                this case) from seeking compensation for damages paid by suing third
                                parties that caused them.
                            </React.Fragment>
                        }
                    />
                </Text>
                <Placeholder
                    backgroundColor="ui-50"
                    appearance="compact"
                    imageMaxWidth={270}
                    imageName="illustration-hips"
                />
            </StackLayout>
        </CoverLayout>
    );
}
