import { BundleCoverageType } from '@app/bundle/types/BundleQuoteCoverage';
import { GetGlobalConfig } from '@app/config/useCases/GetGlobalConfigUseCase';
import { AppTypeCode } from '@app/shopping/types/enums';
import { ErrorCode } from '@app/userOrg/errors';
import { isReturnUserFunnelNBRVOneEnabled } from '@app/userOrg/useCases/GetReturnUserLoginRedirect';
import { execute } from '@embroker/shotwell/core/UseCase';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ExpiredLinkPage } from '@embroker/shotwell/view/components/ExpiredLinkPage';
import { PageNotFound } from '@embroker/shotwell/view/components/PageNotFound';
import { NaviRequest, map, redirect, route } from 'navi';
import React from 'react';
import { BrokerDeclinedPage } from '../../../brokerDashboard/view/components/BrokerDeclinedPage';
import { BrokerRenewalKickoff } from '../../../brokerDashboard/view/components/BrokerRenewalKickoff';
import { BrokerClientPage } from '../../../brokerDashboard/view/components/brokerClientPage/BrokerClientPage';
import { ClientPaymentHistory } from '../../../brokerDashboard/view/components/brokerClientPage/ClientPaymentHistory';
import { BrokerDashboard } from '../../../brokerDashboard/view/components/brokerDashboard/BrokerDashboard';
import { BrokerKickoutModalTypes } from '../../../brokerDashboard/view/components/brokerDashboard/BrokerKickOutModals';
import { LossRunsRequest } from '../../../brokerDashboard/view/components/lossRunsRequest/LossRunsRequest';
import { BrokerOrganizations } from '../../../brokerDashboard/view/components/organizations/BrokerOrganizations';
import { BrokerUnpaidBalances } from '../../../brokerDashboard/view/components/payments/BrokerUnpaidBalances';
import { BrokerPolicies } from '../../../brokerDashboard/view/components/policies/BrokerPolicies';
import { BrokerUpdate } from '../../../brokerDashboard/view/components/profile/BrokerUpdate';
import { BrokerQuotes } from '../../../brokerDashboard/view/components/quotes/BrokerQuotes';
import { BrokerRenewals } from '../../../brokerDashboard/view/components/renewals/BrokerRenewals';
import { AppContext } from '../../../view/AppContext';
import { ErrorPage } from '../../../view/components/ErrorPage.view';
import { RouteDefinitions, group } from '../../../view/routes/Route';
import { redirectToLegacy } from '../../../view/routes/redirect';
import { hasRole } from '../../entities/Session';
import { Token } from '../../types/Token';
import { UserOnboardingDetails } from '../../types/UserOnboardingDetails';
import { GetOnboardingRedirect } from '../../useCases/GetOnboardingRedirect';
import { GetUserOnboardingEntrypoint } from '../../useCases/GetUserOnboardingEntrypoint';
import { LoginByAdmin } from '../../useCases/LoginByAdmin';
import { Logout } from '../../useCases/Logout';
import { ProspectUserLoginByToken } from '../../useCases/ProspectUserLoginByToken';
import { StreamlineRenewalProspectLogin } from '../../useCases/StreamlineRenewalProspectLogin';
import { AcceptInvite } from '../components/AcceptInvite';
import { UpdateBusinessProfile } from '../components/BusinessProfile';
import { ChangePasswordEditor } from '../components/ChangePassword';
import { InviteMembers } from '../components/InviteMembers';
import { NBRVRedirectGate, NBRVRedirectGateUseCase } from '../components/NBRVRedirectGate';
import { RecoverPasswordPage } from '../components/RecoverPasswordPage';
import { ResetPasswordPage } from '../components/ResetPasswordPage';
import { SignInPage } from '../components/SignInPage/SignInPage';
import { SwitchCompanies } from '../components/SwitchCompanies';
import { UpdateProfile } from '../components/UpdateProfile';
import { SignUpForInvitedUsers } from '../components/register/SignUpForInvitedUsers';
import {
    OnboardingFlowWrapper,
    onboardingStepComponentMap,
} from '../components/userOnboardingFlow/OnboardingFlowWrapper';
import { SignUpPage } from '../components/userOnboardingFlow/SignUpPage';
import { SetNBRVFunnelStatus } from '@app/userOrg/useCases/SetNBRVFunnelStatus';

export const routes: RouteDefinitions<AppContext> = {
    '/': {
        auth: 'any',
        isOrganizationSpecific: false,
        handler: map(async (request, context: AppContext) => {
            const { activeSession } = context;

            if (activeSession.isAuthenticated) {
                const isBroker = hasRole(activeSession, 'broker');
                const redirectUrl = isBroker ? '/broker/dashboard' : '/summary';
                return redirect(URI.build(redirectUrl, request.query), { exact: false });
            }
            return redirect(URI.build('/signup', request.query), { exact: false });
        }),
    },
    '/login': {
        auth: 'none',
        isOrganizationSpecific: false,
        handler: map(async (request) => {
            const emailParam = request.params.emailAddress;

            let emailAddress: EmailAddress | undefined;
            const validationResult = EmailAddress.validate(emailParam);
            if (isOK(validationResult)) {
                emailAddress = validationResult.value;
            }
            return route({
                title: 'Log In',
                view: <SignInPage userEmail={emailAddress} />,
            });
        }),
    },
    '/law-bundle/:token': {
        auth: 'any',
        isOrganizationSpecific: false,
        handler: map(async (request) => {
            const loginByTokenResult = await execute(
                ProspectUserLoginByToken,
                request.params.token,
            );
            if (isErr(loginByTokenResult)) {
                return route({
                    title: 'Error',
                    view: <ErrorPage errors={loginByTokenResult.errors} />,
                });
            }
            return redirect(
                URI.build('/shopping/law-bundle', {
                    applicationId: loginByTokenResult.value.bundleApplicationId,
                }),
            );
        }),
    },
    '/streamline-renewal-prospect': {
        auth: 'any',
        isOrganizationSpecific: false,
        handler: map(async (request) => {
            const {
                organizationId: queryOrganizationId,
                applicantId: queryApplicantId,
                applicationId,
                token,
            } = request.params;

            if (token) {
                const loginByTokenResult = await execute(StreamlineRenewalProspectLogin, token);
                if (isErr(loginByTokenResult)) {
                    const isTokenExpiredError = loginByTokenResult.errors.some(
                        (err) => err.code === ErrorCode.ExpiredToken,
                    );

                    if (!isTokenExpiredError) {
                        return route({
                            title: 'Error',
                            view: <ErrorPage errors={loginByTokenResult.errors} />,
                        });
                    }
                }
            }

            // organizationId is a legacy param, replaced with applicantId in order to avoid
            // it being removed when redirecting to login page
            const applicantId = queryApplicantId ? queryApplicantId : queryOrganizationId;
            return redirect(
                URI.build('/shopping/select-applicant-redirect', {
                    next: '/shopping/submit-streamline-renewal',
                    applicationId,
                    applicantId,
                }),
            );
        }),
    },
    '/streamline-renewal-reject': {
        auth: 'any',
        isOrganizationSpecific: false,
        handler: map(async (request) => {
            const {
                token,
                applicationId,
                applicantId: queryApplicantId,
                organizationId: queryOrganizationId,
            } = request.params;

            if (token) {
                const loginByTokenResult = await execute(StreamlineRenewalProspectLogin, token);
                if (isErr(loginByTokenResult)) {
                    const isTokenExpiredError = loginByTokenResult.errors.some(
                        (err) => err.code === ErrorCode.ExpiredToken,
                    );

                    if (!isTokenExpiredError) {
                        return route({
                            title: 'Error',
                            view: <ErrorPage errors={loginByTokenResult.errors} />,
                        });
                    }
                }
            }

            // organizationId is a legacy param, replaced with applicantId in order to avoid
            // it being removed when redirecting to login page
            const applicantId = queryApplicantId ? queryApplicantId : queryOrganizationId;
            return redirect(
                URI.build('/shopping/select-applicant-redirect', {
                    next: '/shopping/opt-out-streamline-renewal',
                    applicationId,
                    applicantId,
                }),
            );
        }),
    },
    '/logout': {
        auth: 'default',
        isOrganizationSpecific: false,
        handler: map(async (request: NaviRequest<AppContext>, context: AppContext) => {
            if (isReturnUserFunnelNBRVOneEnabled()) {
                await execute(SetNBRVFunnelStatus, null);
            }
            await execute(Logout);
            context.resetActiveSession();
            return redirect('/login');
        }),
    },
    '/login-by-admin': {
        auth: 'default',
        isOrganizationSpecific: false,
        handler: map(async () => {
            const loginByAdminResult = await execute(LoginByAdmin);
            if (isErr(loginByAdminResult)) {
                return route({
                    title: 'Error',
                    view: <ErrorPage errors={loginByAdminResult.errors} />,
                });
            }
            return redirect('/');
        }),
    },
    '/signup': {
        auth: 'none',
        isOrganizationSpecific: false,
        handler: map(async (request) => {
            const { redirectTo, emailAddress, organizationName } = request.query;
            const redirectUrl = redirectTo ? decodeURIComponent(redirectTo) : undefined;

            const validatedEmailResult = EmailAddress.validate(emailAddress);
            const validatedEmail = isOK(validatedEmailResult)
                ? validatedEmailResult.value
                : undefined;

            return route({
                title: 'Sign Up',
                view: (
                    <SignUpPage
                        userEmail={validatedEmail}
                        organizationName={organizationName}
                        redirectUrl={redirectUrl}
                    />
                ),
            });
        }),
    },
    '/recover-password': {
        auth: 'any',
        isOrganizationSpecific: false,
        handler: route({
            title: 'Recover password',
            view: <RecoverPasswordPage />,
        }),
    },
    '/reset-password': {
        auth: 'none',
        isOrganizationSpecific: false,
        handler: map(async (request) => {
            const token = request.params.token;
            if (!Token.check(token)) {
                return redirect('/recover-password');
            }
            return route({
                title: 'Reset password',
                view: <ResetPasswordPage token={token} />,
            });
        }),
    },
    '/set-password': {
        auth: 'any',
        isOrganizationSpecific: false,
        handler: map(async (request, context: AppContext) => {
            const { activeSession } = context;
            const token = request.params.token;
            if (activeSession.isAuthenticated) {
                return redirect('/user/change-password');
            } else if (!Token.check(token)) {
                return redirect('/recover-password');
            }
            return route({
                title: 'Reset password',
                view: <ResetPasswordPage token={token} />,
            });
        }),
    },
    '/admin': {
        auth: 'any',
        isOrganizationSpecific: false,
        handler: route(async (request) => {
            redirectToLegacy('/admin');
            return {
                title: 'Support',
                view: null,
            };
        }),
    },
    '/error': map((request) => {
        const { errorLogId } = request.query;
        return route({
            title: 'Error',
            view: <ErrorPage errorLogId={errorLogId} />,
        });
    }),
    '/expired-link': {
        auth: 'any',
        isOrganizationSpecific: false,
        handler: route({
            title: 'Expired Link',
            view: <ExpiredLinkPage />,
        }),
    },
    '/broker-declined': route({
        title: 'Error',
        view: <BrokerDeclinedPage />,
    }),
    '/company-profile': route({
        title: 'Company Profile',
        view: <UpdateBusinessProfile />,
    }),
    '/user': group(
        {
            auth: 'default',
            isOrganizationSpecific: false,
        },
        {
            '/change-password': route({
                title: 'Change password',
                view: <ChangePasswordEditor />,
            }),
            '/profile': route({
                title: 'Update Profile',
                view: <UpdateProfile />,
            }),
            '/accept-invite': map(async (request: NaviRequest<AppContext>) => {
                if (
                    typeof request.query.invitationId === 'string' &&
                    !UUID.check(request.query.invitationId)
                ) {
                    const { errorLogId } = request.query;
                    return route({ view: <ErrorPage errorLogId={errorLogId} /> });
                }
                return route({
                    title: 'Accept Invite',
                    view: <AcceptInvite invitationId={request.query.invitationId} />,
                    data: { hideHeader: true },
                });
            }),
            '/switch-companies': map(async () => {
                return route({
                    title: 'Switch company',
                    view: <SwitchCompanies />,
                });
            }),
            '/nbrv-redirect-gate': map(async (request: NaviRequest<AppContext>) => {
                return route({
                    view: (
                        <NBRVRedirectGate
                            useCase={request.query.useCase as NBRVRedirectGateUseCase}
                            redirectTo={request.query.redirectTo}
                            coverageName={request.query.coverageName}
                            appType={request.query.appType as BundleCoverageType | AppTypeCode}
                        />
                    ),
                });
            }),
            '/onboarding': map(async (request: NaviRequest<AppContext>) => {
                const userOnboadingEntrypoint = await execute(GetUserOnboardingEntrypoint);

                if (isOK(userOnboadingEntrypoint) && userOnboadingEntrypoint.value) {
                    const { name } =
                        onboardingStepComponentMap[userOnboadingEntrypoint.value.stepType];
                    return route({
                        title: `User onboarding | ${name}`,
                        view: (
                            <OnboardingFlowWrapper
                                userOnboadingEntrypoint={userOnboadingEntrypoint.value}
                            />
                        ),
                    });
                }

                const redirectEndpoint = await execute(GetOnboardingRedirect);
                UserOnboardingDetails.clearUserOnboardingContext();
                if (isOK(redirectEndpoint)) {
                    return redirect(redirectEndpoint.value, request.query);
                }

                return redirect('/', request.query);
            }),
        },
    ),
    '/user/manage-users': {
        auth: 'default',
        isOrganizationSpecific: true,
        handler: route({
            title: 'Invite members',
            view: <InviteMembers />,
        }),
    },
    '/user/signup-invitation': {
        auth: 'none',
        isOrganizationSpecific: false,
        handler: map(async (request) => {
            const { userToken: userInvitationToken } = request.query;
            const { certificateToken: certificateInviteToken } = request.query;
            if (!userInvitationToken && !certificateInviteToken) {
                return redirect('/signup');
            }
            if (userInvitationToken && !Token.check(userInvitationToken)) {
                return redirect('/signup');
            }
            if (certificateInviteToken && !Token.check(certificateInviteToken)) {
                return redirect('/signup');
            }
            return route({
                title: 'Signup invitation',
                view: (
                    <SignUpForInvitedUsers
                        certificateInviteToken={certificateInviteToken as Token}
                        userInvitationToken={userInvitationToken as Token}
                    />
                ),
            });
        }),
    },
    '/broker': group(
        {
            auth: 'default',
            isOrganizationSpecific: false,
        },
        {
            '/change-password': map(async (request: NaviRequest<AppContext>) => {
                return route({
                    title: 'Change password',
                    view: <ChangePasswordEditor />,
                });
            }),
            '/profile': route({
                title: 'Update Profile',
                view: <BrokerUpdate />,
            }),
            '/companies': {
                auth: 'default',
                isOrganizationSpecific: false,
                handler: map(async (request) => {
                    const { filter } = request.params;

                    return route({
                        title: 'Brokerage Companies',
                        view: <BrokerOrganizations filter={filter} />,
                        data: { hideHeader: true },
                    });
                }),
            },
            '/dashboard': map(async (req) => {
                const { applicationId, modal } = req.query;
                return route({
                    title: 'Broker Dashboard',
                    view: (
                        <BrokerDashboard
                            applicationId={applicationId as UUID}
                            modalType={modal as BrokerKickoutModalTypes}
                        />
                    ),
                    data: { hideHeader: true },
                });
            }),
            '/renewal-kickoff': map(async (req) => {
                const { applicationId, organizationId } = req.query;
                if (UUID.check(applicationId) && UUID.check(organizationId)) {
                    return route({
                        title: 'Broker Renewal Kickoff',
                        view: (
                            <BrokerRenewalKickoff
                                applicationId={applicationId}
                                organizationId={organizationId}
                            />
                        ),
                    });
                }
                return route({
                    view: <PageNotFound navigateToLink={'dashboard'} />,
                });
            }),
            '/quotes': {
                auth: 'default',
                isOrganizationSpecific: false,
                handler: map(async () => {
                    const globalConfigResult = await execute(GetGlobalConfig);
                    const showEditApplicationForExcess =
                        isOK(globalConfigResult) &&
                        globalConfigResult.value.config.excessRenewalsEnabled;

                    return route({
                        title: 'Broker Quotes',
                        view: (
                            <BrokerQuotes
                                showEditApplicationForExcess={showEditApplicationForExcess}
                            />
                        ),
                        data: { hideHeader: true },
                    });
                }),
            },
            '/renewals': route({
                title: 'Broker Renewals',
                view: <BrokerRenewals />,
                data: { hideHeader: true },
            }),
            '/policies': route({
                title: 'Broker Policies',
                view: <BrokerPolicies />,
                data: { hideHeader: true },
            }),
            '/organization-info': {
                auth: 'default',
                handler: map(async (request) => {
                    const { organizationId } = request.params;
                    if (typeof organizationId === 'string' && UUID.check(organizationId)) {
                        return route({
                            title: 'Company info',
                            view: <BrokerClientPage organizationId={organizationId} />,
                            data: { hideHeader: true },
                        });
                    } else {
                        return route({
                            view: <PageNotFound navigateToLink={'dashboard'} />,
                        });
                    }
                }),
            },
            '/client-payment-history': {
                auth: 'default',
                handler: map(async () => {
                    return route({
                        title: 'Payment History',
                        view: <ClientPaymentHistory />,
                        data: { hideHeader: true },
                    });
                }),
            },
            '/unpaid-balances': route({
                title: 'Broker Unpaid Balances',
                view: <BrokerUnpaidBalances />,
                data: { hideHeader: true },
            }),
            '/request-loss-runs': {
                auth: 'default',
                handler: map(async () => {
                    return route({
                        title: 'Request Loss Runs',
                        view: <LossRunsRequest />,
                        data: { hideHeader: true },
                    });
                }),
            },
        },
    ),
};
