import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    Button,
    List,
    Modal,
    ModalActions,
    ModalState,
    Spinner,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { useCurrentRoute } from 'react-navi';
import * as enums from '../../../shopping/types/enums';
import { GetApplicant } from '../../../shopping/useCases/GetApplicant';
import { isAppQuotingEngine } from '../../../shopping/view/components/applicationFlags';
import { PurchasedAppType } from '../../../summary/types/PurchasedAppType';
import { useNavigation } from '../../../view/hooks/useNavigation';

interface ThankYouModalProps {
    modal: ModalState & ModalActions;
    applicationQuotingEngine: enums.QuotingEngine;
}

export function ThankYouModal({ modal, applicationQuotingEngine }: ThankYouModalProps) {
    const { navigate } = useNavigation();
    const route = useCurrentRoute();

    const { result: applicant } = useUseCase(GetApplicant);

    if (applicant === undefined) {
        return <Spinner />;
    }

    if (isErr(applicant)) {
        return <ErrorPage errors={applicant.errors} />;
    }

    const handleGetCertificatesOfInsurance = () => navigate('/certificates');

    const handleGoToPayments = () => navigate('/payments');

    const handleReviewYourPolicy = () => navigate('/policies');

    const handleCompleteAnotherApp = () => navigate('/summary');

    function buildProgramTitle() {
        switch (applicationQuotingEngine) {
            case 'QuotingEngineLPLEverest':
                return 'Lawyers Professional Liability Policy';
            case 'QuotingEngineESP':
                return 'Startup Package';
            case 'QuotingEngineCNABOP':
                return 'Business Owners Policy';
            case 'QuotingEngineWCGA':
                return 'Workers Compensation Policy';
            case 'QuotingEnginePCoML':
                return 'Management Liability Insurance';
            case 'QuotingEngineCrime':
                return 'Commercial Crime Policy';
            case 'QuotingEngineCyber':
            case 'QuotingEngineCyberCowbell':
                return 'Cyber Insurance';
            default:
                container.get<Logger>(Log).error('Unexpected quoting engine');
        }
    }

    function buildCoverageTitle(appType: string): string {
        if (applicationQuotingEngine === 'QuotingEngineESP') {
            switch (appType) {
                case 'Professional Liability / Errors and Omissions':
                    return 'Technology E&O/Cyber';
            }
        }

        return appType;
    }

    function getCoverages() {
        const coverages: Array<string> = [];
        const purchasedAppType = PurchasedAppType.decode(route.url.query.appTypes);
        if (isOK(purchasedAppType)) {
            return purchasedAppType.value.appTypes.map(buildCoverageTitle);
        }
        return coverages;
    }

    function isCertificatesOfInsuranceUrlAvailable() {
        return !isAppQuotingEngine(applicationQuotingEngine, enums.QuotingEngineCrime);
    }

    return (
        <Modal {...modal}>
            <StackLayout>
                <Text as="h3" style="heading 3">
                    You’re covered!
                </Text>
                <List
                    title={
                        applicant.value.applicant.organizationName +
                        ' now has the ' +
                        buildProgramTitle() +
                        ' which includes:'
                    }
                >
                    {getCoverages().map((item) => (
                        <List.Item key={item}>{item}</List.Item>
                    ))}
                </List>
                <Text as="p" style="label 1">
                    <Text style="label" color="brand-500">
                        What’s next?
                    </Text>{' '}
                    You can make a payment now, or select any of the other options below.
                </Text>
                <TextButton onClick={handleReviewYourPolicy}>Review your policy</TextButton>
                {isCertificatesOfInsuranceUrlAvailable() && (
                    <TextButton onClick={handleGetCertificatesOfInsurance}>
                        Get certificates of insurance
                    </TextButton>
                )}
                <TextButton onClick={handleCompleteAnotherApp}>
                    Complete another application
                </TextButton>

                <Button onClick={handleGoToPayments}>Go to Payments</Button>
            </StackLayout>
        </Modal>
    );
}
