import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { URI } from '@embroker/shotwell/core/types/URI';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { execute } from '@embroker/shotwell/core/UseCase';
import {
    ColumnLayout,
    HeroBanner,
    Loader,
    PageLayout,
    StackLayout,
    Text,
    TextButton,
    useModal,
} from '@embroker/ui-toolkit/v2';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { LeavingConfirmationModal } from '../../../view/components/LeavingConfirmationModal';
import { calculateTotalUnderlyingLimit, CarrierLayer } from '../../types/CarrierLayer';
import { ExcessQuoteBreakdown } from './ExcessQuoteBreakdown';
import { QuoteExpiration } from './QuoteExpiration';
import { DocumentModal } from './DocumentModal';
import { GenerateQuoteDocumentUrl } from '../../useCases/GenerateQuoteDocumentUrl';
import { TowerStructure } from './TowerStructure';
import { ExcessQuote } from '../../entities/ExcessQuote';
import { InsuranceApplicationRestriction } from '../../types/InsuranceApplicationRestriction';
import { ExcessTechEOCyber, ExcessTowerLayer } from '../../types/ExcessRestriction';
import { Money } from '@embroker/shotwell/core/types/Money';
import { Immutable } from '@embroker/shotwell/core/types';
import { SignatureField } from './Signature/SignatureField';
import { AppContext } from '../../../../view/AppContext';
import { hasRole } from '../../../../userOrg/entities/Session';
import { State } from '@embroker/shotwell/core/types/StateList';
import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { EditApplication } from '@app/brokerDashboard/useCases/EditApplication';

export interface QuoteLandingPageProps {
    applicationId: UUID;
    initialQuote: ExcessQuote;
    restriction: Immutable<InsuranceApplicationRestriction>;
    isApplicationEditAllowed?: boolean;
}
interface QuoteLandingPageState {
    quote: ExcessQuote;
    quoteFileUrl?: URI;
}
const EXCESS_SPECIMEN_POLICY_URL =
    'https://embroker.s3.us-west-2.amazonaws.com/Specimen+Excess+Tech+Errors+and+Omissions+Cyber+Policy.pdf';

function mapToCarrierLayers(uwExcessTower: Immutable<ExcessTowerLayer[]>): CarrierLayer[] {
    const carrierLayers: CarrierLayer[] = [];

    for (const layer of uwExcessTower) {
        const carrierLayer = {
            layer_number: layer.layerNumber,
            carrier: { id: UUID.create(), name: layer.carrier },
            tech_eo_limit: 0,
            policy_aggregate: Money.toFloat(layer.policyAggregate),
            premium: Money.toFloat(layer.premium),
        };
        carrierLayers.push(carrierLayer);
    }

    return carrierLayers;
}

function mapToPrimaryCarrierLayer(techRestriction: Immutable<ExcessTechEOCyber>): CarrierLayer {
    return {
        layer_number: 0,
        carrier: {
            id: null,
            name: techRestriction.primaryCarrier,
        },
        tech_eo_limit: Money.toFloat(techRestriction.techEOLimit),
        policy_aggregate: Money.toFloat(techRestriction.policyAggregate),
        premium: Money.toFloat(techRestriction.primaryPremium),
    };
}

function isSigned(signatures: ('transfers' | 'warranty')[]): boolean {
    return signatures.includes('transfers') && signatures.includes('warranty');
}

export function QuoteLandingPage({
    applicationId,
    initialQuote,
    restriction,
    isApplicationEditAllowed = false,
}: QuoteLandingPageProps) {
    const { navigate } = useNavigation();
    const leavingConfirmationModal = useModal();
    const documentModal = useModal();
    const handleLeaving = useCallback(() => {
        leavingConfirmationModal.show();
    }, [leavingConfirmationModal]);

    const handleConfirmLeaving = useCallback(() => {
        leavingConfirmationModal.hide();
        navigate('/');
    }, [leavingConfirmationModal, navigate]);

    const abortController = useMemo(() => {
        return new AbortController();
    }, []);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const { activeSession } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');
    const [state, setState] = useState<QuoteLandingPageState>({
        quote: initialQuote,
        quoteFileUrl: undefined,
    });
    const [carrierLayers, setCarrierLayers] = useState<CarrierLayer[]>([]);
    const [limit, setLimit] = useState(state.quote.options.limit);
    const [isLimitDirty, setIsLimitDirty] = useState(false);
    const [signatures, setSignatures] = useState<('transfers' | 'warranty')[]>([]);
    const restrictedCarrierLayers = useMemo(() => {
        let restrictedCarriers: CarrierLayer[] = [];
        if (
            restriction?.additionalQuestions?.excessTechEOCyber?.excessTower &&
            restriction.additionalQuestions.excessTechEOCyber.excessTower.length > 0
        ) {
            restrictedCarriers = mapToCarrierLayers(
                restriction.additionalQuestions.excessTechEOCyber.excessTower,
            );
        }
        return restrictedCarriers;
    }, [restriction]);

    let primaryCarrierLayer = state.quote.primaryCarrierLayer;
    if (
        restriction?.additionalQuestions?.excessTechEOCyber &&
        restriction.areManualRestrictionsApplied
    ) {
        primaryCarrierLayer = mapToPrimaryCarrierLayer(
            restriction.additionalQuestions.excessTechEOCyber,
        );
    }
    const handleChangeLimit = (newLimit: number) => {
        setLimit(newLimit);
        setIsLimitDirty(newLimit != state.quote.options.limit);
    };

    const totalUnderlyingLimit = calculateTotalUnderlyingLimit(carrierLayers, primaryCarrierLayer);

    const calculateMaxLimit = (totalUnderlyingLimit: number): number => {
        if (totalUnderlyingLimit <= 1000000) {
            return 2000000;
        } else if (totalUnderlyingLimit > 1000000 && totalUnderlyingLimit <= 2000000) {
            return 3000000;
        } else {
            return 5000000;
        }
    };

    const maxLimit = restriction.areManualRestrictionsApplied
        ? restriction.maxLimit
        : calculateMaxLimit(totalUnderlyingLimit);

    useEffect(() => {
        const layers = [...state.quote.questionnaire.carrier_layers];
        if (restrictedCarrierLayers.length > 0 && restriction.areManualRestrictionsApplied) {
            setCarrierLayers(restrictedCarrierLayers);
        } else {
            setCarrierLayers(layers);
        }
    }, [
        state.quote.questionnaire.carrier_layers,
        restrictedCarrierLayers,
        restriction.areManualRestrictionsApplied,
    ]);

    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, [abortController]);

    const handleRequote = (requote: ExcessQuote) => {
        if (isSigned(signatures)) {
            requote.sign();
        }
        //by default requote is in 'draft' state, hence no need to unsign

        setIsLimitDirty(limit != requote.options.limit);
        setState({
            ...state,
            quote: requote,
        });
    };

    const handleQuoteDocumentDownload = async () => {
        setIsSubmitting(true);
        const documentUrl = await execute(GenerateQuoteDocumentUrl, {
            applicationId: state.quote.applicationId,
            quoteId: state.quote.id,
            abortSignal: abortController.signal,
        });

        if (isErr(documentUrl)) {
            setIsSubmitting(false);
            return;
        }

        setState({
            ...state,
            quoteFileUrl: documentUrl.value.quoteSummaryUrl,
        });
        setIsSubmitting(false);
        documentModal.show();
    };

    const handleSignatureChange = async (newSignaturesValue: ('transfers' | 'warranty')[]) => {
        const quoteToSignResult = await ExcessQuote.create(state.quote);
        if (isErr(quoteToSignResult)) {
            container.get<Logger>(Log).error(quoteToSignResult.errors);
            return;
        }
        const quoteToSign = quoteToSignResult.value as ExcessQuote;
        setSignatures(newSignaturesValue);
        if (isSigned(newSignaturesValue)) {
            quoteToSign.sign();
            setState({
                ...state,
                quote: quoteToSign,
            });
            return;
        }
        quoteToSign.unsign();
        setState({
            ...state,
            quote: quoteToSign,
        });
    };

    const handleEditApplication = async () => {
        setIsSubmitting(true);
        const editApplicationResult = await execute(EditApplication, {
            applicationId,
        });
        if (editApplicationResult && isOK(editApplicationResult)) {
            navigate(
                URI.build('/shopping/application/basic-info', {
                    applicationId,
                }),
            );
        }
    };

    return (
        <React.Fragment>
            <HeroBanner
                icon="excess"
                onDismiss={handleLeaving}
                links={menuItems(isApplicationEditAllowed)}
                message={
                    <QuoteExpiration daysToExpire={state.quote.daysToExpire}></QuoteExpiration>
                }
            >
                <h1>
                    <Text as="span" style="heading 1" color="ui-50">
                        Your Excess Tech E&O/Cyber Quote
                    </Text>
                </h1>
            </HeroBanner>
            <LeavingConfirmationModal
                modal={leavingConfirmationModal}
                onConfirmLeaving={handleConfirmLeaving}
            />
            {isSubmitting && <Loader variant="loading" />}
            <DocumentModal modal={documentModal} fileUrl={state.quoteFileUrl} />
            <PageLayout.Section>
                <ColumnLayout
                    center
                    top
                    gap="24"
                    responsive={{ containerWidth: { smallerThan: 588 } }}
                    split="-1"
                >
                    <TowerStructure
                        limit={limit}
                        carrierLayers={carrierLayers}
                        primaryCarrierLayer={primaryCarrierLayer}
                        premium={state.quote.totalPremium}
                        className="u-3/5"
                        handleChangeLimit={handleChangeLimit}
                        handleExit={handleLeaving}
                        maxLimit={maxLimit}
                    />
                    <StackLayout className="u-2/5" gap="32">
                        <ExcessQuoteBreakdown
                            quote={state.quote}
                            className="u-1/1"
                            limit={limit}
                            isLimitDirty={isLimitDirty}
                            isBroker={isBroker}
                            applicationId={applicationId}
                            abortController={abortController}
                            onRequote={handleRequote}
                        />
                        {!isBroker && (
                            <StackLayout gap="32">
                                <Text style="heading 5">Please agree to the statements below</Text>
                                <SignatureField
                                    readOnly={false}
                                    state={state.quote.questionnaire.state as State}
                                    fullName={`${state.quote.questionnaire.first_name} ${state.quote.questionnaire.last_name}`}
                                    titleValue={state.quote.questionnaire.title}
                                    companyName={state.quote.questionnaire.company_name}
                                    onChange={handleSignatureChange}
                                    value={signatures}
                                />
                            </StackLayout>
                        )}
                    </StackLayout>
                </ColumnLayout>
            </PageLayout.Section>
            <PageLayout.Section>
                {restriction?.endorsementList && restriction.endorsementList.length > 0 && (
                    <StackLayout>
                        <Text style="heading 4">Endorsements</Text>
                        <ul>
                            {restriction?.endorsementList
                                .map((endorsement) => ({
                                    id: endorsement.id,
                                    name: endorsement.name,
                                    formNumber: endorsement.formNumber,
                                }))
                                .sort((a, b) => (a.name > b.name ? 1 : -1))
                                .map((endorsement) => (
                                    <li key={endorsement.id}>
                                        <Text style="body 2">
                                            {endorsement.name} {endorsement.formNumber}
                                        </Text>
                                    </li>
                                ))}
                        </ul>
                    </StackLayout>
                )}
            </PageLayout.Section>
        </React.Fragment>
    );

    function menuItems(isApplicationEditAllowed: boolean): React.ReactNode {
        const editAppButton =
            isApplicationEditAllowed && isBroker ? (
                <TextButton icon="pencil" color="ui-50" onClick={handleEditApplication}>
                    <strong>Edit Application</strong>
                </TextButton>
            ) : null;
        return (
            <React.Fragment>
                <TextButton
                    as="a"
                    icon="download"
                    color="ui-50"
                    href={EXCESS_SPECIMEN_POLICY_URL}
                    target={'_blank'}
                >
                    <strong>Excess Specimen Policy</strong>
                </TextButton>
                <TextButton
                    icon="download"
                    color="ui-50"
                    key={1}
                    onClick={handleQuoteDocumentDownload}
                    disabled={isLimitDirty}
                >
                    <strong>Download Quote</strong>
                </TextButton>
                {editAppButton}
            </React.Fragment>
        );
    }
}
