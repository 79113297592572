import { Money, USD } from '@embroker/shotwell/core/types/Money';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { Immutable, Nullable, StackLayout, Table, Text, Tooltip } from '@embroker/ui-toolkit/v2';
import React, { useMemo } from 'react';
import { useEnumGroup } from '../../../../serverEnums/view/hooks/useEnumGroup';
import { PropertyCoverage } from '../../../types/PropertyCoverage';
import { SubjectOfInsurance } from '../../../types/SubjectOfInsurance';

interface DisplayCoverage {
    coverage: string;
    deductible?: Money;
    limit: Nullable<Money>;
    description: Nullable<string>;
}

const DisplayCoverage = {
    ...defineValidator<DisplayCoverage>({
        coverage: Joi.string(),
        deductible: Money.schema.optional(),
        limit: Money.schema.allow(null),
        description: Joi.string().allow(null, ''),
    }),
    create(displayCoverage: DisplayCoverage) {
        return DisplayCoverage.validate(displayCoverage);
    },
};

interface CoveragesArray {
    [index: string]: DisplayCoverage;
}

export interface PropertyCoverageLocationCoverageTableProps {
    subjectOfInsurance: Immutable<SubjectOfInsurance>;
}

export const PropertyCoverageLocationCoverageTable = ({
    subjectOfInsurance,
}: PropertyCoverageLocationCoverageTableProps) => {
    const { result: propertyCoverageCodeList } = useEnumGroup('PropertyCoverageCodeList');

    const CoverageList = useMemo(() => {
        // Change introduced in EM-36261. Instead of assuming that some base property
        // coverages will always be present, simply loop over what the backend returns.
        const result: CoveragesArray = {};

        subjectOfInsurance.propertyCoverageList.forEach((item: PropertyCoverage) => {
            result[item.typeCode] = {
                limit: item.limit,
                coverage: propertyCoverageCodeList?.get(item.typeCode) ?? item.typeCode,
                deductible: item.deductible,
                description: item.description,
            };
        });

        return result;
    }, [subjectOfInsurance, propertyCoverageCodeList]);

    function displayLocationDeductible(
        deductible: Money | undefined,
        locationDeductible: Nullable<Money>,
    ): JSX.Element {
        if (deductible && Money.isGreaterThan(deductible, USD(0))) {
            return <MoneyDisplay fractionDigits={0} value={deductible} />;
        }

        if (locationDeductible && Money.isGreaterThan(locationDeductible, USD(0))) {
            return <MoneyDisplay fractionDigits={0} value={locationDeductible} />;
        }

        return <MoneyDisplay fractionDigits={0} value={USD(0)} />;
    }

    function displayLocationLimit(
        coverage: Nullable<string>,
        limit: Nullable<Money>,
        description: Nullable<string>,
    ): JSX.Element {
        if (!limit) {
            return (
                <Text style="default" color="brand-500" as="span">
                    not included
                </Text>
            );
        }

        if (limit.amount > 0) {
            return (
                <Text style="default" color="brand-500" as="span">
                    <MoneyDisplay fractionDigits={0} value={limit} />
                    {description && description.length > 0
                        ? coverage &&
                          coverage !== 'PropertyCoverageCodeListEquipmentBreakdownCoverage' && (
                              <Tooltip iconSize="small" text={description} />
                          )
                        : null}
                </Text>
            );
        }

        return (
            <Text style="default" color="brand-500" as="span">
                included
                {description && description.length > 0 ? (
                    <Tooltip iconSize="small" text={description} />
                ) : null}
            </Text>
        );
    }

    function getLocationCoverageTableBody(): JSX.Element[] {
        const result: JSX.Element[] = [];
        for (const coverage in CoverageList) {
            const deductible = CoverageList[coverage].deductible;
            const limit = CoverageList[coverage].limit;
            const locationDeductible = subjectOfInsurance.deductible;
            const description = CoverageList[coverage].description;
            result.push(
                <Table.Row key={coverage}>
                    <Table.Cell>
                        <Text style="label 1">{CoverageList[coverage].coverage}</Text>
                    </Table.Cell>
                    <Table.Cell>
                        {displayLocationDeductible(deductible, locationDeductible)}
                    </Table.Cell>
                    <Table.Cell>{displayLocationLimit(coverage, limit, description)}</Table.Cell>
                </Table.Row>,
            );
        }
        return result;
    }

    const LocationCoverageTableBody = getLocationCoverageTableBody();

    return (
        <StackLayout gap="none">
            <Text style="heading 5">Location coverage details</Text>
            <Table>
                <Table.Header>
                    <Table.Column>LOCATION COVERAGE</Table.Column>
                    <Table.Column>DEDUCTIBLE</Table.Column>
                    <Table.Column>LIMITS</Table.Column>
                </Table.Header>
                <Table.Body>{LocationCoverageTableBody}</Table.Body>
            </Table>
        </StackLayout>
    );
};
