import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Immutable } from '@embroker/shotwell/core/types';
import { URI } from '@embroker/shotwell/core/types/URI';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import {
    BoxLayout,
    Button,
    CardLayout,
    ColumnLayout,
    CoverLayout,
    FormLayout,
    ScrollBox,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { Invoice } from '../../../entities/Invoice';
import { BundlePayment } from '../../../types/Payment';

interface BundleInvoiceInfoData {
    bundlePayment: Immutable<BundlePayment>;
    showACHModal(): void;
    showCCModal(): void;
}

export function BundleInvoiceInfo(props: BundleInvoiceInfoData) {
    const bundlePayment = props.bundlePayment;

    return (
        <CoverLayout>
            <FormLayout>
                <StackLayout gap="32">
                    <CardLayout>
                        <BoxLayout gap="48">
                            <ScrollBox>
                                <StackLayout gap="24">
                                    <Text style="heading 3">
                                        Make a payment for {bundlePayment.billingName}
                                    </Text>
                                    <StackLayout gap="16">
                                        <StackLayout gap="4">
                                            <Text style="body 2" color={'brand-500'}>
                                                <b>EFFECTIVE</b>
                                            </Text>
                                            <Text style="body 2" color={'brand-500'}>
                                                <DateDisplay
                                                    value={bundlePayment.policyEffectiveDate}
                                                />
                                            </Text>
                                        </StackLayout>
                                        <StackLayout gap="4">
                                            <Text style="heading 4">Lawyer's Bundle</Text>
                                            <Text style="body 2" color="ui-500">
                                                Contains:
                                            </Text>
                                        </StackLayout>
                                        <StackLayout gap={'24'}>
                                            <StackLayout gap={'16'}>
                                                {bundlePayment.invoiceList.map((invoice) =>
                                                    buildInvoicePolicySection(invoice),
                                                )}
                                            </StackLayout>
                                            <hr />
                                            <ColumnLayout>
                                                <StackLayout>
                                                    <Text style={'heading 4'}>Total Amount</Text>
                                                </StackLayout>
                                                <Text style={'heading 4'}>
                                                    <MoneyDisplay value={bundlePayment.total} />
                                                </Text>
                                            </ColumnLayout>
                                            <hr />
                                        </StackLayout>
                                        <Text data-e2e="invoice-details-total-amount-disclaimer">
                                            Total may be different if paid by credit card.
                                        </Text>

                                        <br />
                                        <StackLayout gap="24">
                                            <ColumnLayout gap="32">
                                                <Button
                                                    appearance="primary"
                                                    onClick={props.showACHModal}
                                                >
                                                    Pay via Your Bank
                                                </Button>
                                                <TextButton onClick={props.showCCModal}>
                                                    <b>Pay with Credit Card</b>
                                                </TextButton>
                                            </ColumnLayout>
                                            <ColumnLayout>
                                                <Text>
                                                    Want to pay in instalments?{' '}
                                                    <TextButton
                                                        data-e2e="customer-public-header-login-link"
                                                        href={URI.build('/login')}
                                                    >
                                                        Log into your account
                                                    </TextButton>
                                                    .
                                                </Text>
                                            </ColumnLayout>
                                        </StackLayout>
                                    </StackLayout>
                                </StackLayout>
                            </ScrollBox>
                        </BoxLayout>
                    </CardLayout>
                    <Text style="microcopy">
                        Need help?{' '}
                        <TextButton href="mailto:support@embroker.com" size="small">
                            Get in touch.
                        </TextButton>
                    </Text>
                </StackLayout>
            </FormLayout>
        </CoverLayout>
    );
}

function buildInvoicePolicySection(invoice: Immutable<EntityProps<Invoice>>): JSX.Element | null {
    return (
        <StackLayout gap={'16'}>
            <ColumnLayout>
                <StackLayout gap={'8'}>
                    <Text color={'brand-500'}>{invoice.lineOfBusiness}</Text>
                    <StackLayout gap={'8'}>
                        <Text style="label 2" color="ui-400">
                            POLICY NO: {invoice.policyNumber}
                        </Text>
                        <Text style="label 2" color="ui-400">
                            POLICY PROVIDER: {invoice.carrierName}
                        </Text>
                        <Text style="label 2" color="ui-400">
                            INVOICE NO: {invoice.invoiceNumber}
                        </Text>
                    </StackLayout>
                </StackLayout>
            </ColumnLayout>
        </StackLayout>
    );
}
