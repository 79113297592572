import {
    LineOfBusinessCodeList,
    LineOfBusinessCodeListItem,
    PolicyViewStatusCodeList,
    PolicyViewStatusCodeListItem,
    PolicyBookingType,
    PolicyBookingTypeItem,
} from '@embroker/shotwell-api/enums';
import { defineEntityValidator, Entity, entity } from '@embroker/shotwell/core/entity/Entity';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { isPast, isFuture } from 'date-fns';
import { QuotingEngine, QuotingEngineList } from '../../shopping/types/enums';
import { BorStatus } from '../types/BorStatus';
import { Document } from '../types/Document';
import { InsuredParty } from '../types/InsuredParty';
import {
    Lob,
    LobAuto,
    LobExcessTechEOCyber,
    LobProperty,
    LobUmbrellaExcess,
    LobWorkersCompensation,
} from '../types/Lob';
import { PolicyFilter } from '../types/PolicyFilter';
import { PolicyStatus } from '../types/PolicyStatus';
import { Payment } from '../../payments/types/Payment';
import { PhoneNumber } from '@embroker/shotwell/core/types/PhoneNumber';
import { BindFactors } from '../types/BindFactors';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import { State } from '@embroker/shotwell/core/types/StateList';

export interface PolicyEndorsed extends DomainEvent {
    readonly origin: 'Policy';
    readonly name: 'Endorsed';
    readonly policyName: string;
    readonly policyNumber: string;
}

export interface PolicyTransfered extends DomainEvent {
    readonly origin: 'Policy';
    readonly name: 'Transferred';
}

export interface CardPayNowClicked extends DomainEvent {
    readonly origin: 'Policy';
    readonly name: 'CardPayNowClicked';
    readonly policyNumber: string;
    readonly isRenewal: boolean;
}

export interface Policy extends Entity {
    /**
     * Policy number
     */
    policyNumber: string;
    /**
     * Application the policy was requested with
     */
    insuranceApplicationId: Nullable<UUID>;
    /**
     * Bundle Id
     */
    bundleId: Nullable<UUID>;
    /**
     * Status ('current', 'renewal', 'future' , 'expired', 'cancelled' or 'active')
     *
     * NOTE: calculated on frontend based on policy start/end time and current time
     */
    status: PolicyStatus;
    /**
     * Policy name calculated on frontend side base on fixed lob list
     */
    name: string;
    /**
     * Date when policy was canceled (if canceled)
     */
    cancellationDate: Nullable<Date>;
    /**
     * List of policy documents
     *
     * NOTE: Embroker 1.0 uses getDocumentTypeName function
     * to get document label based on documents typeCode
     */
    documents: Document[];
    /**
     * Insurer name
     */
    insurerName: string;
    /**
     * Insurer Id
     */
    insurerId: UUID;
    /**
     * Insurer billing phone
     */
    insurerBillingPhone?: string;
    /**
     * Insurer billing URL
     */
    insurerBillingURL?: string;
    /**
     * List of insured parties
     */
    insuredPartiesList: InsuredParty[];
    /**
     * Premium amount per year
     */
    premiumPerYear: Nullable<Money>;
    /**
     * Base premium with embroker access fee included
     */
    basePremiumWithEmbrokerAccessFee: Nullable<Money>;
    /**
     * Time of policy start
     */
    startDate: Date;
    /**
     * Time when policy expires
     */
    endDate: Date;
    /**
     * Policy line of business.
     * Also used to calculate policy name
     */
    lineOfBusiness: LineOfBusinessCodeListItem;
    /**
     * Policy sub line of business.
     */
    subLineOfBusiness: Nullable<string>;
    /**
     * Policy bor status
     */
    borStatus: BorStatus;
    /**
     * Policy referred
     */
    isReferred?: boolean;
    /**
     * Policy is direct bill
     */
    isDirectBill: boolean;
    /**
     * Policy view mode
     */
    viewMode: PolicyViewStatusCodeListItem;
    /**
     * Series policy ID
     */
    renewalSeriesId: UUID;
    /**
     * Policies for 'Embroker supported' line of businesses
     */
    lobList: Lob[];
    /**
     * Solartis policy number
     */
    solartisPolicyNumber: string;
    /**
     * Checks if policy matches filter
     * @param filter policy filter
     */
    matchFilter(filter: PolicyFilter): boolean;

    /**
     * Endorse a policy
     */
    endorse(): void;
    /*
     *  Update needed policies with a transfer request status
     */
    transferRequest(): void;
    /*
     *  Quoting Engine
     */
    quotingEngine?: QuotingEngine;
    /**
     * Policy booking type.
     */
    bookingType: PolicyBookingTypeItem;
    /*
     * Invoice due for given policy
     */
    invoiceDue?: Payment;
    /*
     * Set invoice due for given policy
     */
    setInvoiceDue(invoideDue: Payment): void;
    /**
     * Claim website for policy.
     */
    claimWebsite: Nullable<string>;
    /**
     * Claim email for policy.
     */
    claimEmail: Nullable<string>;
    /**
     * Claim phone number for policy.
     */
    claimPhoneNumber: Nullable<PhoneNumber>;
    /**
     * Claim phone number extenison for policy.
     */
    claimPhoneExtension: Nullable<number>;
    /**
     * Bind factors
     */
    bindFactors?: BindFactors;
    /**
     * Insured address information
     */
    address: Nullable<string>;
    city: Nullable<string>;
    zip: Nullable<ZipCode>;
    state: Nullable<State>;
    /**
     * Is policy in Runoff state
     */
    inRunoff: boolean;
}

export const Policy = entity<Policy>({
    validator: defineEntityValidator<Policy>({
        policyNumber: Joi.string().allow(''), // we allow empty string because purchased policies can have empty policy number while their processing is still ongoing
        insuranceApplicationId: UUID.schema.allow(null),
        bundleId: UUID.schema.allow(null),
        status: PolicyStatus.schema,
        name: Joi.string().allow(null, ''),
        cancellationDate: Joi.date().allow(null),
        documents: Joi.array().items(Document.schema),
        insurerName: Joi.string(),
        insurerBillingPhone: Joi.string().optional().allow(''),
        insurerBillingURL: Joi.string().optional().allow(''),
        insurerId: UUID.schema,
        insuredPartiesList: Joi.array().items(InsuredParty.schema),
        premiumPerYear: Money.schema.allow(null),
        basePremiumWithEmbrokerAccessFee: Money.schema.allow(null),
        startDate: Joi.date(),
        endDate: Joi.date(),
        lineOfBusiness: Joi.string().valid(...LineOfBusinessCodeList),
        subLineOfBusiness: Joi.string().allow(null, ''),
        renewalSeriesId: Joi.string(),
        lobList: Joi.array().items(
            Lob.schema,
            LobAuto.schema,
            LobWorkersCompensation.schema,
            LobUmbrellaExcess.schema,
            LobExcessTechEOCyber.schema,
            LobProperty.schema,
        ),
        borStatus: BorStatus.schema,
        isReferred: Joi.boolean().optional(),
        isDirectBill: Joi.boolean().required(),
        viewMode: Joi.string().valid(...PolicyViewStatusCodeList),
        solartisPolicyNumber: Joi.string().allow(null, ''),
        quotingEngine: Joi.string()
            .valid(...QuotingEngineList)
            .optional(),
        bookingType: Joi.string().valid(...PolicyBookingType),
        claimWebsite: Joi.string().allow('', null),
        claimEmail: Joi.string().allow('', null),
        claimPhoneNumber: Joi.string().allow(null),
        claimPhoneExtension: Joi.number().allow(null),
        bindFactors: BindFactors.schema.optional(),
        address: Joi.string().allow(null),
        city: Joi.string().allow(null),
        zip: ZipCode.schema.allow(null),
        state: State.schema.allow(null),
        inRunoff: Joi.boolean(),
    }),
    matchFilter(filter: PolicyFilter): boolean {
        if (
            this.viewMode != 'PolicyViewStatusCodeListDraft' &&
            ((this.cancellationDate != null && !isFuture(this.cancellationDate)) ||
                isPast(this.endDate))
        ) {
            return filter.showInactive;
        }

        return filter.showActive;
    },
    endorse() {
        this.createEvent<PolicyEndorsed>('Endorsed', {
            policyName: this.name,
            policyNumber: this.policyNumber,
        });
    },
    transferRequest() {
        this.createEvent<PolicyTransfered>('Transferred');
    },
    setInvoiceDue(invoiceDue: Payment): void {
        this.props.invoiceDue = invoiceDue;
    },
});

export function isPolicyLPL(policyNumber: string): boolean {
    return /^EML/.test(policyNumber);
}
