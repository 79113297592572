import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { execute } from '@embroker/shotwell/core/UseCase';
import { UpdateUserOnboardingDetails } from '../useCases/UpdateUserOnboardingDetails';
import { RemoveUserOnboardingDetails } from '../useCases/RemoveUserOnboardingDetails';
import { Nullable } from '@embroker/ui-toolkit/v2';

export const UserOnboardingStepTypeList = [
    'naicsConfirmation',
    'naicsRefinement',
    'businessLocation',
    'lawVerticalRefinement',
    'staffDetailsPage',
] as const;

export type UserOnboardingStepTypeListType = typeof UserOnboardingStepTypeList;
export type UserOnboardingStepType = Nullable<UserOnboardingStepTypeListType[number]>;

export interface UserOnboardingDetails {
    /**
     * Where a user will be redirected to after onboarding is complete.
     */
    redirectUrl?: string;
    /**
     * Store the Naics Code returned by DnB to be used across the onboarding flow.
     */
    dnbNaicsCode?: string;
    /**
     * Store the Naics Code confirmed by a user through the refinment page.
     */
    refinedNaicsCode?: string;
}

export const UserOnboardingDetails = {
    ...defineValidator<UserOnboardingDetails>({
        redirectUrl: Joi.string().optional(),
        dnbNaicsCode: Joi.string().optional(),
        refinedNaicsCode: Joi.string().optional(),
    }),
    create(userOnboardingDetails: UserOnboardingDetails) {
        return UserOnboardingDetails.validate(userOnboardingDetails);
    },
    initializeUserOnboardingContext(redirectUrl?: string, dnbNaicsCode?: string): void {
        execute(UpdateUserOnboardingDetails, { redirectUrl, dnbNaicsCode });
    },
    updateUserOnboardingContext({
        redirectUrl,
        dnbNaicsCode,
        refinedNaicsCode,
    }: {
        redirectUrl?: string;
        dnbNaicsCode?: string;
        refinedNaicsCode?: string;
    }): void {
        execute(UpdateUserOnboardingDetails, { redirectUrl, dnbNaicsCode, refinedNaicsCode });
    },
    clearUserOnboardingContext(): void {
        execute(RemoveUserOnboardingDetails);
    },
};
