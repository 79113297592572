import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { ColumnLayout, Form, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { EffectiveDate } from '../../../quote/types/EffectiveDate';
import { WizardForm } from '../../../view/hooks/useWizardForm';
import { BundleQuoteCardLayout } from './BundleQuoteCardLayout.view';

const DATE_FORMAT = 'MM/dd/yyyy';

export interface BundleQuoteEffectiveDateProps {
    fields: WizardForm<OpaqueForm<any>>['fields'];
    effectiveDate: Date;
    disabled: boolean;
    onDateChange: (event: { target: { value: string; date: Date } }) => void;
    isEffectiveDateSelectable: (effectiveDate: Date) => boolean;
}

export function BundleQuoteEffectiveDate({
    fields,
    effectiveDate,
    disabled = false,
    onDateChange,
    isEffectiveDateSelectable,
}: BundleQuoteEffectiveDateProps) {
    const endDate = EffectiveDate.calculateCoverageEndDate(effectiveDate);

    return (
        <BundleQuoteCardLayout>
            <StackLayout gap="8">
                <Text style="heading 5" color="brand-400">
                    Coverage Effective Date
                </Text>
                <ColumnLayout center>
                    <Form.Field
                        aria-label="Coverage Effective Date"
                        className="u-1/2"
                        type={fields.effectiveDate.type}
                        inputProps={{
                            ...fields.effectiveDate.props,
                            onChange: onDateChange,
                            isSelectable: isEffectiveDateSelectable,
                            disabled,
                        }}
                        messages={fields.effectiveDate.messages}
                    />
                    {endDate ? (
                        <Text style="heading 5" as="p" color="brand-400" data-e2e="end-date">
                            &ndash;&nbsp;
                            <DateDisplay format={DATE_FORMAT} value={endDate} />
                        </Text>
                    ) : null}
                </ColumnLayout>
            </StackLayout>
        </BundleQuoteCardLayout>
    );
}
