import { defineValidator, Joi, SchemaType } from '@embroker/shotwell/core/validation/schema';
import {
    QuestionType as DataDrivenFormQuestionType,
    FormQuestionDefinition,
    SelectOption,
} from '@app/view/components/DataDrivenForm/hooks/useDataDrivenForm';
import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { RequireAtLeastOne } from '@embroker/ui-toolkit/v2';
import { locationFieldValidator } from '@app/userOrg/view/components/LocationFieldSet.view';
import { RevenueDefinition } from './OracleAnswerTypes/revenue';
import { InterValidation, InterValidationSchema } from './InterValidation';
import { getIntraValidations, IntraValidation, IntraValidationSchema } from './IntraValidation';
import { ValidationTypeProps } from '@app/view/components/DataDrivenForm/types/fieldValidationFactory';

export type OracleAnswerType = RequireAtLeastOne<{
    [key in AnswerKeysType]: unknown[];
}>;
export const OracleAnswerTypeSchema = Joi.object().pattern(
    Joi.string(),
    Joi.array().items(Joi.any()),
);

export const fundraisingRoundFormatValidator = Joi.object({
    fundraiseDate: Joi.string().required(),
    moneyRaised: Joi.string().required(),
    leadInvestor: Joi.string().required(),
});

// For reference: Oracle answer value types are defined here
// https://github.com/embroker/oracle/blob/98100c961a7f2347c58aeec87c2c8d38c5f31c65/proto/oracle_model.proto#L41
export const AnswerKeysTypes = [
    'text',
    'number',
    'date',
    'boolean',
    'integer',
    'address',
    'funding_round',
    'revenue',
] as const;
export type AnswerKeysType = (typeof AnswerKeysTypes)[number];

export type OracleAnswerTypeDefinition = {
    answerKeyType: AnswerKeysType;
    schemaFunctions: {
        validator: SchemaType<any>;
        serializeAnswer?: (formData: unknown) => unknown;
        deserializeAnswer?: (formData: unknown) => unknown[] | undefined;
    };
};

export const answerTypeToKeyTypeMap: Record<AnswerType, OracleAnswerTypeDefinition> = {
    TEXT: { answerKeyType: 'text', schemaFunctions: { validator: Joi.string().allow('') } },
    NUMBER: {
        answerKeyType: 'number',
        schemaFunctions: { validator: Joi.number() },
    },
    DATE: { answerKeyType: 'date', schemaFunctions: { validator: Joi.string() } },
    BOOLEAN: { answerKeyType: 'boolean', schemaFunctions: { validator: Joi.boolean() } },
    INTEGER: { answerKeyType: 'integer', schemaFunctions: { validator: Joi.number() } },
    ADDRESS: { answerKeyType: 'address', schemaFunctions: { validator: locationFieldValidator } },
    FUNDRAISING_ROUND: {
        answerKeyType: 'funding_round',
        schemaFunctions: { validator: fundraisingRoundFormatValidator },
    },
    REVENUE_PREVIOUS_YEAR: RevenueDefinition,
};

//
// QuestionTypes definition
export const QuestionTypes = [
    'TEXT',
    'TEXTAREA',
    'NUMBER',
    'DATE',
    'BOOLEAN',
    'SELECT',
    'FILE',
    'ADDRESS',
    'NAICS',
    'MULTISELECT',
    'PERCENTAGE',
    'CURRENCY',
    'YEAR',
    'ADDRESS',
    'FUNDRAISING_ROUND',
    'REVENUE_PREVIOUS_YEAR',
] as const;
export type QuestionType = (typeof QuestionTypes)[number];

export const TextTypes = ['WORDING', 'TOOLTIP', 'TITLE', 'LABEL'] as const;
export type TextType = (typeof TextTypes)[number];

//
// Text definition
export interface Text {
    type: TextType;
    value: string;
}
export const TextSchema = Joi.object({
    type: Joi.string()
        .required()
        .valid(...TextTypes),
    value: Joi.string().required(),
});

//
// AnswerSource definition
export interface AnswerSource {
    type: string;
    id: string;
}
const AnswerSourceSchema = Joi.object({
    type: Joi.string().required(),
    id: Joi.string().required(),
});

//
// AnswerTypes definition
export const AnswerTypes = [
    'TEXT',
    'NUMBER',
    'DATE',
    'BOOLEAN',
    'INTEGER',
    'ADDRESS',
    'FUNDRAISING_ROUND',
    'REVENUE_PREVIOUS_YEAR',
] as const;
export type AnswerType = (typeof AnswerTypes)[number];

//
// Answer definition
export interface Answer {
    key: string;
    source: AnswerSource;
    provided_at: string;
    received_at: string;
    type: AnswerType;
    multiplicity: number;
    value: OracleAnswerType;
}
const AnswerSchema = Joi.object({
    key: Joi.string().required(),
    source: AnswerSourceSchema.required(),
    provided_at: Joi.string().isoDate().required(),
    received_at: Joi.string().isoDate().required(),
    type: Joi.string()
        .required()
        .valid(...AnswerTypes),
    multiplicity: Joi.number().integer().required(),
    value: OracleAnswerTypeSchema.required(),
});

export interface AnswerOptions {
    [key: string]: OracleAnswerType;
}
const AnswerOptionsSchema = Joi.object().pattern(Joi.string(), OracleAnswerTypeSchema.required());

//
// OperatorTypes definition
export const OperatorTypes = ['AND', 'OR'] as const;
export type OperatorType = (typeof OperatorTypes)[number];

//
// Question definition
const QuestionerQuestionSchema = Joi.object({
    key: Joi.string().required(),
    type: Joi.string().required(), //.valid(...QuestionTypes)
    multiplicity: Joi.number().integer().required(),
    text: Joi.array().items(TextSchema).required(),
    answer_type: Joi.string().required(),
    current_answer: AnswerSchema.optional(),
    placeholder: Joi.object().pattern(Joi.string(), Joi.any()).optional(),
    intra_validation: IntraValidationSchema,
    inter_validation: Joi.array().items(Joi.array().items(InterValidationSchema)).optional(),
    answer_options: AnswerOptionsSchema,
    section_key: Joi.string().optional(),
});
export interface QuestionerQuestion {
    key: string;
    section_key?: string;
    type: QuestionType;
    multiplicity: number;
    text: Text[];
    answer_type: AnswerType;
    current_answer?: Answer;
    placeholder?: { [key: string]: any };
    intra_validation: IntraValidation;
    inter_validation: InterValidation[][];
    answer_options?: { [key: string]: OracleAnswerType };
}

export const QuestionerQuestion = {
    ...defineValidator<QuestionerQuestion>(QuestionerQuestionSchema),
    create(question: unknown) {
        return QuestionerQuestion.validate(question);
    },
    getDataDrivenFormQuestion(question: QuestionerQuestion): FormQuestionDefinition {
        return {
            key: question.key,
            questionType: getQuestionType(question),
            title: getTextValueByType(question, 'TITLE'),
            tooltip: getTextValueByType(question, 'TOOLTIP'),
            label: getTextValueByType(question, 'LABEL'),
            placeholder: getPlaceholderValue(question),
            initialValue: getInitialValue(question),
            isRequired: getIsRequired(question),
            validate: getValidation(question),
            isMultiple: getIsMultiple(question),
            selectOptions: getSelectOptions(question),
            conditionalDisplay: undefined,
            staticOptions: undefined,
        };
    },
    getDataDrivenFormQuestions(questions: QuestionerQuestion[]): FormQuestionDefinition[] {
        return questions.map((question) => QuestionerQuestion.getDataDrivenFormQuestion(question));
    },
};

const answerTypeFallbackQuestion: Record<AnswerType, DataDrivenFormQuestionType> = {
    TEXT: 'text',
    NUMBER: 'number',
    DATE: 'date',
    BOOLEAN: 'radioGroup',
    INTEGER: 'number',
    ADDRESS: 'addressField',
    FUNDRAISING_ROUND: 'fundraisingRound',
    REVENUE_PREVIOUS_YEAR: 'previousYearRevenue',
};

const questionTypeToFormFieldType: Record<QuestionType, DataDrivenFormQuestionType> = {
    TEXT: 'text',
    NUMBER: 'number',
    DATE: 'date',
    BOOLEAN: 'radioGroup',
    SELECT: 'select',
    FILE: 'text',
    ADDRESS: 'addressField',
    FUNDRAISING_ROUND: 'fundraisingRound',
    NAICS: 'naicsCode',
    TEXTAREA: 'textarea',
    MULTISELECT: 'multiSelect',
    PERCENTAGE: 'percentage',
    CURRENCY: 'currency',
    YEAR: 'year',
    REVENUE_PREVIOUS_YEAR: 'previousYearRevenue',
};

// Refine back-end data contract.
// https://embroker.atlassian.net/browse/EM-43445
export const getIsMultiple = (question: QuestionerQuestion): boolean => {
    return question.multiplicity === -1;
};

export const getTextValueByType = (
    question: QuestionerQuestion,
    type: TextType,
): string | undefined => {
    return question.text.find((text) => text.type === type)?.value;
};

export const getInitialValue = (question: QuestionerQuestion): unknown => {
    if (question.current_answer) {
        const { type, multiplicity, value } = question.current_answer;
        const {
            answerKeyType,
            schemaFunctions: { deserializeAnswer },
        } = answerTypeToKeyTypeMap[type];

        const answerValue = deserializeAnswer
            ? deserializeAnswer(value[answerKeyType])
            : value[answerKeyType];

        if (answerValue && multiplicity === 1) {
            // answerValue will always be an array
            // If multiplicity === 1 then this is a single value input, so return the 0th element
            return answerValue[0];
        }
        return answerValue;
    }
    return undefined;
};

export const getValueFromAnswer = (answer: Answer): unknown => {
    return undefined;
};

// TODO - logic to extract placeholder
// Key is unknown at the moment
// Refine back-end data contract.
// https://embroker.atlassian.net/browse/EM-43445
export const PLACEHOLDER_KEY = 'PLACEHOLDER_KEY';
export const getPlaceholderValue = (question: QuestionerQuestion): string | undefined => {
    if (question.placeholder) {
        return question.placeholder[PLACEHOLDER_KEY]
            ? question.placeholder[PLACEHOLDER_KEY].value
            : undefined;
    }
    return undefined;
};

export const getQuestionType = (question: QuestionerQuestion): DataDrivenFormQuestionType => {
    const questionType = questionTypeToFormFieldType[question.type];
    if (!questionType) {
        const errorMsg = `Data driven questionnaire question type:${questionType} not found. answer_type: ${question.answer_type}.`;
        if (process.env.NODE_ENV === 'production') {
            // This scenario should never happen we are being very defensive here
            // If question type does not return a questionType then use fallback based on `answer_type`
            //
            // We still want to surface an error in the logs to catch this scenario
            container.get<Logger>(Log).error(errorMsg);
        } else {
            throw new Error(errorMsg);
        }

        const fallback = answerTypeFallbackQuestion[question.answer_type];
        if (fallback) {
            // If answer_type also does not return a questionType then return `textarea` as catch all fall back
            return fallback;
        }
        return 'textarea';
    }
    return questionType;
};

export const getIsRequired = (question: QuestionerQuestion): boolean => {
    return Boolean(question.intra_validation?.required);
};

// Currently we are just returning the key but there is scope for this to change separating the logic here
const getTitleForSelectOption = (key: string) => key;

type SelectOptionsType = SelectOption<string | number | boolean>[];
export const getSelectOptions = (question: QuestionerQuestion): SelectOptionsType | undefined => {
    if (question.type === 'BOOLEAN') {
        return [
            { title: 'Yes', value: true },
            { title: 'No', value: false },
        ];
    }
    if (question.answer_options) {
        const answerOptions = question.answer_options;

        const selectOptions: SelectOptionsType = Object.keys(answerOptions).reduce((acc, key) => {
            const { answer_type } = question;
            const option = answerOptions[key];
            const optionValue = option[answerTypeToKeyTypeMap[answer_type].answerKeyType];

            if (isStringNumberOrBooleanArray(optionValue)) {
                const element = {
                    title: getTitleForSelectOption(key),
                    value: optionValue.join(', '), // We know optionValue will be an array of one. Using the .join() method here to be extra defensive
                };
                if (element.value !== undefined) {
                    acc.push(element);
                }
            }

            return acc;
        }, [] as SelectOptionsType);
        return selectOptions;
    }
};

function isStringNumberOrBoolean(variable: unknown): variable is (string | number | boolean)[] {
    return ['string', 'number', 'boolean'].includes(typeof variable);
}

function isStringNumberOrBooleanArray(arr: unknown): arr is (string | number | boolean)[] {
    return Array.isArray(arr) && arr.every((item) => isStringNumberOrBoolean(item));
}

export function getValidation(question: QuestionerQuestion): ValidationTypeProps[] | undefined {
    const intraValidations: ValidationTypeProps[] = getIntraValidations(question) || [];

    if (intraValidations.length) {
        return intraValidations;
    }
    return;
}
