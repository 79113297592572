import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import {
    BoxLayout,
    CardLayout,
    ColumnLayout,
    StackLayout,
    Text,
    TextButton,
    UploadFile,
    UploadFileState,
    useModal,
} from '@embroker/ui-toolkit/v2';
import React, { useContext, useState } from 'react';
import { UploadFiles, UploadFilesRequest } from '../../../documents/useCases/UploadFiles';
import { FileUpload } from '../../../documents/view/FileUpload';
import { AppContext } from '../../../view/AppContext';
import { UploadPolicy, UploadPolicyRequest } from '../../useCases/UploadPolicy';
import { AnalyzingDataModal } from '../modals/AnalyzingData';
import { PolicyInTheWorksModal } from '../modals/PolicyInTheWorks';
import { TransferPolicySlideout } from './TransferPolicySlideout';

export interface PolicyUploadProps {
    onProcessingSuccess: Function;
    onPolicyUploaded: Function;
}

export function PolicyUpload(props: PolicyUploadProps) {
    const timeErrorMessageShouldBeDisplayed = 3000;

    const analyzingDataModal = useModal();
    const policyInTheWorksModal = useModal();

    const [uploadPercent, setUploadPercent] = useState(0);
    const [dropBoxContainerState, setDropBoxContainerState] = useState<UploadFileState>('default');
    const { setSlideout } = useContext(AppContext);

    function onUploaded(newFiles: FileUpload[]) {
        props.onPolicyUploaded();
        newFiles.forEach((file) => {
            if (file.s3FileKey === null) {
                return;
            }

            analyzingDataModal.show();
            execute(UploadPolicy, {
                fileKey: file.s3FileKey,
                unprocessedPolicyName: file.file.name,
                agreementId: null,
                isFromOnboarding: null,
                lineOfBusiness: null,
            } as UploadPolicyRequest).then((result) => {
                analyzingDataModal.hide();
                if (isOK(result)) {
                    props.onProcessingSuccess();
                } else {
                    container.get<Logger>(Log).error(result.errors);
                    policyInTheWorksModal.show();
                }
            });
        });
    }

    const uploadFile = (file: FileUpload) => {
        const files = [file];
        execute(UploadFiles, {
            files: files.map((item) => item.file),
            onFileUploadProgress: (uploaded, totalSize) => {
                setUploadPercent((uploaded / totalSize) * 100);
            },
        } as UploadFilesRequest).then((result) => {
            setUploadPercent(100);

            setTimeout(() => {
                if (isOK(result)) {
                    for (let i = 0; i < files.length; i++) {
                        files[i].s3FileKey = result.value.uploadedFiles[i].fileKey;
                    }
                } else {
                    container.get<Logger>(Log).error(result.errors);
                }
                onUploaded(files);
                setUploadPercent(0);
            }, 1800);
        });
    };

    const isSingleFile = (files: File[]) => {
        return files.length === 1;
    };

    const isFilePDF = (file: File) => {
        return file.type === 'application/pdf';
    };

    const handleNewFilesAdded = (files: File[]) => {
        if (!isSingleFile(files)) {
            setDropBoxContainerState('numberOfFilesError');
            setTimeout(() => {
                setDropBoxContainerState('default');
            }, timeErrorMessageShouldBeDisplayed);
            return;
        }

        const newPolicyFile = files[0];

        if (!isFilePDF(newPolicyFile)) {
            setDropBoxContainerState('fileTypeError');
            setTimeout(() => {
                setDropBoxContainerState('default');
            }, timeErrorMessageShouldBeDisplayed);
            return;
        }

        const uploadId = UUID.create();
        const fileToUpload: FileUpload = {
            id: uploadId,
            file: newPolicyFile,
            s3FileKey: null,
        };

        uploadFile(fileToUpload);
    };

    return (
        <CardLayout>
            <BoxLayout gap="24">
                <StackLayout gap="24">
                    <AnalyzingDataModal
                        hideModal={analyzingDataModal.hide}
                        modal={analyzingDataModal}
                    />
                    <PolicyInTheWorksModal
                        hideModal={policyInTheWorksModal.hide}
                        modal={policyInTheWorksModal}
                    />
                    <ColumnLayout gap="4" responsive={{ containerWidth: { smallerThan: 500 } }}>
                        <Text as="span" color="brand-500" style="label 1">
                            Have a non-Embroker policy?
                        </Text>
                        <Text as="span" style="body 2">
                            Upload it here to manage all your policies in one place or transfer it
                            to Embroker.{' '}
                            <TextButton
                                onClick={() => {
                                    setSlideout(<TransferPolicySlideout />);
                                }}
                            >
                                Learn more
                            </TextButton>
                        </Text>
                    </ColumnLayout>
                    <UploadFile
                        buttonText="Choose file"
                        placeholder="or drag file here."
                        fileTypeFilters={['application/pdf']}
                        isMultiple={false}
                        onFilesAdded={handleNewFilesAdded}
                        uploadPercent={uploadPercent}
                        showButton
                        onUploadAbort={() => {
                            //empty
                        }}
                        state={dropBoxContainerState}
                    />
                </StackLayout>
            </BoxLayout>
        </CardLayout>
    );
}
