import React, { useContext, useEffect, useState } from 'react';

import {
    Banner,
    Button,
    List,
    MarketingHeroBanner,
    PageLayout,
    StackLayout,
    StatusMessage,
    Text,
} from '@embroker/ui-toolkit/v2';
import {
    CoverageRecommendationCard,
    RecommendationType,
} from '@app/shopping/view/components/lawBundle/CoverageRecommendationCard.view';
import { useNavigation } from '@app/view/hooks/useNavigation';
import {
    CyberCrossSellEventType,
    PublishCyberCrossSellEvent,
} from '../useCases/PublishCyberCrossSellEvent';
import { execute } from '@embroker/shotwell/core/UseCase';
import { useStartApplication } from '@app/shopping/view/hooks/useStartApplication';
import { AppTypeCodeListCyberCowbell } from '@app/shopping/types/enums';
import { GetActiveOrganizationProfile } from '@app/userOrg/useCases/GetActiveOrganizationProfile';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { GetBillingInfo } from '@app/payments/useCases/GetBillingInfo';
import { AppContext } from '@app/view/AppContext';
import { UUID } from '@embroker/shotwell/core/types/UUID';

interface CrossSellLandingPageProps {
    coverageName: string;
    effectiveDate: string;
}

export const CrossSellLandingPage = ({
    coverageName,
    effectiveDate,
}: CrossSellLandingPageProps) => {
    const { activeSession } = useContext(AppContext);
    const [organizationName, setOrganizationName] = useState('');
    const [organizationEmail, setOrganizationEmail] = useState('');
    const { navigate } = useNavigation();
    const { startApplication, isLoading: isLoadingStartApplication } = useStartApplication();
    const { result: orgInfoResult } = useUseCase(GetActiveOrganizationProfile);
    const organizationId: UUID = activeSession.organizationId as UUID;
    const { result: billingInfo } = useUseCase(GetBillingInfo, {
        organizationId: organizationId,
    });

    useEffect(() => {
        if (orgInfoResult && isOK(orgInfoResult)) {
            setOrganizationName(orgInfoResult.value.organization.companyLegalName ?? '');
        }
    }, [orgInfoResult]);

    useEffect(() => {
        if (billingInfo && isOK(billingInfo)) {
            setOrganizationEmail(billingInfo.value.billingEmailAddress || '');
        } else if (orgInfoResult && isOK(orgInfoResult)) {
            setOrganizationEmail(orgInfoResult.value.organization.email || '');
        }
    }, [billingInfo, orgInfoResult]);

    useEffect(() => {
        execute(PublishCyberCrossSellEvent, {
            eventType: CyberCrossSellEventType.pageLoad,
        });
    }, []);

    const handleGoToDashboard = () => {
        execute(PublishCyberCrossSellEvent, {
            eventType: CyberCrossSellEventType.goToDashboardClicked,
        });
        navigate('/');
    };

    const onHyperlinkClick = () => {
        execute(PublishCyberCrossSellEvent, {
            eventType: CyberCrossSellEventType.updateYourBillingEmailAddressClicked,
        });
    };

    const onStartQuote = () => {
        execute(PublishCyberCrossSellEvent, {
            eventType: CyberCrossSellEventType.startQuote,
        });
        startApplication({ selectedAppTypes: [AppTypeCodeListCyberCowbell] });
    };

    return (
        <StackLayout gap="64">
            <MarketingHeroBanner imageName="illustration-tablet">
                <StackLayout gap="32">
                    <Text style="hero light" color="brand-400">
                        <strong>You’re covered!</strong>
                    </Text>
                    <Text style="body 1" color="brand-400">
                        {`Thanks, we’ve received your payment. Your ${coverageName} will be in effect as
                    of ${effectiveDate}.`}
                    </Text>
                    <Text style="body 1" color="brand-400">
                        {`You’ll receive an email from Ascend with a receipt for your
                    payment at ${organizationEmail} in 1-2 business days. You can update your `}
                        <a href="/payments/history" onClick={onHyperlinkClick} target="_blank">
                            <strong>billing email address here.</strong>
                        </a>
                    </Text>
                </StackLayout>
            </MarketingHeroBanner>
            <PageLayout.Section as={StackLayout} gap="32">
                <StackLayout gap="8" className="u-3/4@tablet u-1/1">
                    <Text
                        style="heading 2"
                        color="brand-400"
                    >{`${organizationName}, get more protection today.`}</Text>
                    <Text style="body 1" color="ui-500">
                        Check Point reports that cyber attacks on law firms increased 13% in 2023.
                        Don’t be a statistic. Cyber insurance covers financial and legal costs
                        following a cybercrime.
                    </Text>
                </StackLayout>
                <CoverageRecommendationCard
                    iconName="cyber"
                    title="Cyber Insurance"
                    className="u-1/3@large-tablet u-1/1"
                    recommendationType={RecommendationType.popular}
                    showToggle={false}
                >
                    <StackLayout gap="24">
                        <List>
                            <List.Item icon="check">
                                <Text style="body 1" as="div">
                                    Phishing scams
                                </Text>
                            </List.Item>
                            <List.Item icon="check">
                                <Text style="body 1" as="div">
                                    Computer / funds transfer fraud
                                </Text>
                            </List.Item>
                            <List.Item icon="check">
                                <Text style="body 1" as="div">
                                    Ransomware / extortion
                                </Text>
                            </List.Item>
                            <List.Item icon="check">
                                <Text style="body 1" as="div">
                                    Privacy breaches
                                </Text>
                            </List.Item>
                            <List.Item icon="check">
                                <Text style="body 1" as="div">
                                    Hard copy records
                                </Text>
                            </List.Item>
                        </List>
                        <StatusMessage status="success">
                            Includes complimentary information security training.
                        </StatusMessage>
                        <Button onClick={onStartQuote} disabled={isLoadingStartApplication}>
                            Start a Quote
                        </Button>
                    </StackLayout>
                </CoverageRecommendationCard>
                <Banner
                    color="ui-200"
                    action={
                        <Button appearance="light" onClick={handleGoToDashboard}>
                            Go to Dashboard
                        </Button>
                    }
                >
                    <strong>Done for now?</strong> Don’t worry, you can shop all of our coverages at
                    any time through your dashboard.
                </Banner>
            </PageLayout.Section>
        </StackLayout>
    );
};
