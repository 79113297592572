import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Element, FormElement, PageElement, getFieldMachine } from '@embroker/service-app-engine';
import { execute } from '@embroker/shotwell/core/UseCase';
import { container } from '@embroker/shotwell/core/di';
import { JSONSerdes } from '@embroker/shotwell/core/encoding';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { equal } from '@embroker/shotwell/core/object';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { Spinner, FloatingLayout, useModal, useResponsive } from '@embroker/ui-toolkit/v2';
import { GetGlobalConfig } from '../../../config/useCases/GetGlobalConfigUseCase';
import { hasRole } from '../../../userOrg/entities/Session';
import { AppContext } from '../../../view/AppContext';
import { FormEngine } from '../../../view/components';
import { ApplicationQuestionnaireTitle } from '../../../view/components/ApplicationQuestionnaireTitle.view';
import { ProgressBarRangeType } from '../../../view/components/FormEngine/components/ProgressBar';
import { useFormEngineEvent } from '../../../view/components/FormEngine/hooks/useFormEngineEvent';
import { navigateToErrorPage } from '../../../view/errors';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { ErrorCode } from '../../errors';
import { Place } from '../../types/Place';
import { AppTypeCode, Bundle, ShoppingCoverage } from '../../types/enums';
import { appendSignatureData } from '../../useCases/CreateApplications';
import { CreateWCEmployeeDataTable } from '../../useCases/CreateWCEmployeeDataTable';
import { GetApplicant } from '../../useCases/GetApplicant';
import { ExpandedApplicationView, GetApplication } from '../../useCases/GetApplication';
import { GetApplicationLastPage } from '../../useCases/GetApplicationLastPage';
import { GetApplicationQuestionnaire } from '../../useCases/GetApplicationQuestionnaire';
import { GetApplicationStatus } from '../../useCases/GetApplicationStatus';
import { FormEventName, PublishFormEvents } from '../../useCases/PublishFormEvents';
import { SaveApplicationLastPage } from '../../useCases/SaveApplicationLastPage';
import { SubmitApplication } from '../../useCases/SubmitApplication';
import { UpdateApplicantFromBusinessProfile } from '../../useCases/UpdateApplicantFromBusinessProfile';
import { UpdateApplicationQuestionnaire } from '../../useCases/UpdateApplicationQuestionnaire';
import { ExitConfirmationModal } from './ExitConfirmationModal';
import { MissingInformationModal } from './MissingInformationModal';
import { BrokerApplicationInfoBar } from '../../../brokerDashboard/view/components/ApplicationInCreation/BrokerApplicationInfoBar';
import { OnboardingPrefillQuestionnaireData } from '../../../userOrg/types/OnboardingPrefillQuestionnaireData';
import { lawBundleWizardRoutes } from '../routes/lawBundleWizardRoutes';
import { isDataDrivenFormApplicationQuestionnaire } from '../../../shoppingQuestioner/types/QuestionerApplicationQuestionnaire';
import { QuestionerApplicationQuestionnaire } from '../../../shoppingQuestioner/view/components/QuestionerApplicationQuestionnaire';

const RISK_PROFILE_PAGE_ID = 'risk_profile';

interface ApplicationQuestionnaireProps {
    applicationId: UUID;
    page?: string;
}

export function Application(props: ApplicationQuestionnaireProps): JSX.Element {
    const { navigate } = useNavigation();
    const { activeSession } = useContext(AppContext);
    const { isLoading: isStatusLoading, result: applicationStatusResult } = useUseCase(
        GetApplicationStatus,
        {
            applicationId: props.applicationId,
        },
    );
    const { isLoading: isApplicationLoading, result: getApplicationResult } = useUseCase(
        GetApplication,
        {
            applicationId: props.applicationId,
        },
    );

    const { isLoading: isConfigLoading, result: globalConfigResult } = useUseCase(GetGlobalConfig);

    useEffect(() => {
        return () => OnboardingPrefillQuestionnaireData.clear();
    }, []);

    useEffect(() => {
        if (!isStatusLoading && applicationStatusResult && isOK(applicationStatusResult)) {
            const statusResult = applicationStatusResult.value;
            const applicationInProgress =
                statusResult.status === 'InsuranceApplicationStatusCodeListQuestionnaireInProgress';
            const isBroker = hasRole(activeSession, 'broker');
            if (!applicationInProgress) {
                navigate(isBroker ? '/broker/dashboard' : '/summary');
            }
        }
    }, [isStatusLoading, applicationStatusResult, activeSession, navigate]);

    if (
        isStatusLoading ||
        applicationStatusResult === undefined ||
        isApplicationLoading ||
        getApplicationResult === undefined ||
        isConfigLoading ||
        globalConfigResult === undefined
    ) {
        return <Spinner />;
    }

    if (isErr(applicationStatusResult)) {
        return <ErrorPage errors={applicationStatusResult.errors} />;
    }
    if (isErr(getApplicationResult)) {
        return <ErrorPage errors={getApplicationResult.errors} />;
    }
    if (isErr(globalConfigResult)) {
        return <ErrorPage errors={globalConfigResult.errors} />;
    }

    const applicationInProgress =
        applicationStatusResult.value.status ===
        'InsuranceApplicationStatusCodeListQuestionnaireInProgress';

    if (!applicationInProgress) {
        return <Spinner />;
    }

    if (isDataDrivenFormApplicationQuestionnaire()) {
        return (
            <QuestionerApplicationQuestionnaire
                application={getApplicationResult.value.application}
            />
        );
    }

    return <ApplicationQuestionnaire {...props} />;
}

const PROGRESS_BAR_RANGE: ProgressBarRangeType = { min: 10 };

const ApplicationQuestionnaire = React.memo(function ApplicationQuestionnaire({
    applicationId,
    page,
}: ApplicationQuestionnaireProps) {
    const { navigate } = useNavigation();
    const { activeSession, globalConfig } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');
    // TO DO: Remove useResponsive hook when toolkit update comes with support for sidebar layout content mobile
    const isMobile = useResponsive({ screenWidth: { smallerThan: 'large-tablet' } });
    const [formEngine, setFormEngine] = useState<Nullable<FormElement>>(null);
    const [firstInvalidPageId, setFirstInvalidPageId] = useState<Nullable<string>>(null);
    const missingInformationModal = useModal();
    const [submissionTaskId, setSubmissionTaskId] = useState<Nullable<UUID>>();
    const [currentApplication, setCurrentApplication] = useState<
        Immutable<ExpandedApplicationView> | undefined
    >(undefined);
    const [lastCompletedPage, setLastCompletedPage] = useState<string>('');
    const { result: getApplicationResult } = useUseCase(GetApplication, { applicationId });
    const defaultPage = 'additional_company_details';

    useEffect(() => {
        const lawBundleWizardSubRouteUrls: string[] = Object.keys(lawBundleWizardRoutes)
            .map((key) => URI.parse(key).pathname)
            .filter(Boolean);

        if (lawBundleWizardSubRouteUrls.includes(lastCompletedPage)) {
            const redirectUrl = `/shopping/law-bundle/${lastCompletedPage}`;
            navigate(URI.build(redirectUrl, { applicationId }));
        }
    }, [lastCompletedPage, applicationId, navigate]);

    useEffect(() => {
        if (getApplicationResult !== undefined && isOK(getApplicationResult)) {
            setCurrentApplication(getApplicationResult.value.application);
        }
    }, [getApplicationResult]);
    //
    // NB: we want a single instance of AbortController to be used for a single
    // request so we have to memoize it by using the request data as dependencies.
    // Since we don't use said data inside the useMemo(), ESLint complaints saying
    // it should be removed. But in this case we know better :)
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const abortController = useMemo(() => new AbortController(), [submissionTaskId]);
    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, [abortController]);

    const pageAfterLastCompleted = useMemo(() => {
        return formEngine && lastCompletedPage && getNextPage(formEngine, lastCompletedPage);
    }, [formEngine, lastCompletedPage]);

    // onShow events are not triggered for initial pages
    // This resolves the scenario when 'risk_profile' is the initial page
    useEffect(() => {
        const currentPage = page || pageAfterLastCompleted || defaultPage;
        if (formEngine && currentPage === RISK_PROFILE_PAGE_ID) {
            navigate(
                URI.build('/shopping/risk-assessment', {
                    applicationId: applicationId,
                    organizationId: activeSession.organizationId as UUID,
                    completed: isPageCompleted(formEngine, RISK_PROFILE_PAGE_ID),
                }),
            );
        }
    }, [
        activeSession.organizationId,
        applicationId,
        navigate,
        page,
        formEngine,
        pageAfterLastCompleted,
    ]);

    useEffect(() => {
        let isMounted = true;
        execute(GetApplicationLastPage, {
            userId: activeSession.userId as UUID,
            applicationId,
        }).then((result) => {
            if (isMounted && isOK(result)) {
                setLastCompletedPage(result.value.lastPage);
            }
        });
        return () => {
            isMounted = false;
        };
    }, [activeSession.userId, applicationId]);
    const { result: formEngineResult } = useUseCase(GetApplicationQuestionnaire, {
        applicationId,
        globalConfig,
    });

    useEffect(() => {
        setFormEngine(null);
    }, [applicationId]);

    useEffect(() => {
        setSubmissionTaskId(null);
    }, [page]);

    const exitConfirmationModal = useModal();
    const { show: showExitConfirmationModal } = exitConfirmationModal;

    const onExitFullScreen = useCallback(() => {
        if (!formEngine) {
            return;
        }
        publishFormEvent(
            FormEventName.SaveAndExitClicked,
            formEngine,
            applicationId,
            currentApplication?.appType,
            currentApplication?.shoppingCoverageList,
            currentApplication?.isRenewal,
        );
        showExitConfirmationModal();
    }, [formEngine, applicationId, currentApplication, showExitConfirmationModal]);

    const onConfirmExitApplication = async () => {
        if (!formEngine) {
            return;
        }
        publishFormEvent(
            FormEventName.SaveAndExitModalClicked,
            formEngine,
            applicationId,
            currentApplication?.appType,
            currentApplication?.shoppingCoverageList,
            currentApplication?.isRenewal,
        );
        const questionnaireData = formEngine.getSnapshot().value;
        await execute(UpdateApplicationQuestionnaire, {
            applicationId,
            questionnaireData: JSON.stringify(questionnaireData),
        });
        if (isBroker) {
            navigate('/broker/dashboard');
            return;
        }
        navigate('/summary');
    };

    useEffect(() => {
        if (formEngineResult && isOK(formEngineResult)) {
            const formEngine = formEngineResult.value.formEngine;
            setFormEngine(formEngine);
        }
    }, [formEngineResult]);

    useEffect(() => {
        if (formEngine) {
            publishFormEvent(
                FormEventName.Viewed,
                formEngine,
                applicationId,
                currentApplication?.appType,
                currentApplication?.shoppingCoverageList,
                currentApplication?.isRenewal,
            );
            return () => {
                formEngine.dispose();
            };
        }
    }, [formEngine, applicationId, currentApplication]);

    useFormEngineEvent(formEngine as FormElement, 'pagecompleted', (ev) => {
        const pageId = ev.pageId;

        if (pageId) {
            execute(SaveApplicationLastPage, {
                applicationId,
                userId: activeSession.userId as UUID,
                lastPage: pageId,
            });
        }
    });

    useFormEngineEvent(formEngine as FormElement, 'show', () => {
        if (!formEngine) {
            return;
        }
        publishFormEvent(
            FormEventName.Viewed,
            formEngine,
            applicationId,
            currentApplication?.appType,
            currentApplication?.shoppingCoverageList,
            currentApplication?.isRenewal,
        );
    });

    useFormEngineEvent(formEngine as FormElement, 'hide', () => {
        if (!formEngine) {
            return;
        }
        publishFormEvent(
            FormEventName.Saved,
            formEngine,
            applicationId,
            currentApplication?.appType,
            currentApplication?.shoppingCoverageList,
            currentApplication?.isRenewal,
        );
    });

    const [savedQuestionnaireData, setSavedQuestionnaireData] = useState<any | undefined>(
        undefined,
    );

    useFormEngineEvent(formEngine as FormElement, 'show', () => {
        async function showHandler() {
            if (!formEngine) {
                return;
            }
            const questionnaireData = formEngine.getSnapshot().value;

            if (equal(savedQuestionnaireData, questionnaireData)) {
                return;
            }

            const updateApplicationResponse = await execute(UpdateApplicationQuestionnaire, {
                applicationId,
                questionnaireData: JSON.stringify(questionnaireData),
            });
            if (isErr(updateApplicationResponse)) {
                return;
            }

            setSavedQuestionnaireData(questionnaireData);

            const updateApplicant = await execute(UpdateApplicantFromBusinessProfile, {
                questionnaireData,
            });
            if (
                isErr(updateApplicant) &&
                updateApplicant.errors.some((error) => error.code !== ErrorCode.AnswerMissing)
            ) {
                navigateToErrorPage(navigate, updateApplicant.errors);
                return;
            }

            if (currentApplication?.appType == 'AppTypeCodeListGAWorkersCompensation') {
                // Acquire ids for new locations and update the questionnaire field that
                // holds them

                const getApplicantResponse = await execute(GetApplicant);
                if (isErr(getApplicantResponse)) {
                    return;
                }
                const {
                    applicant: { locations },
                } = getApplicantResponse.value;

                // Field location_data from the wcga app wants these ids, but for some
                // unexplained reason, it doesn't want to read them from the location
                // field. It also, without explaining, extends its data manipulation to
                // here, instead of keeping it in the spec.
                if (questionnaireData.naics_code) {
                    const createWCTableResponse = await execute(CreateWCEmployeeDataTable, {
                        locations: locations as Place[],
                        naicsCode: questionnaireData.naics_code as string,
                    });
                    if (isErr(createWCTableResponse)) {
                        return;
                    }
                }

                getFieldMachine(formEngine.form, 'locations')?.change?.({
                    location: locations?.map((l) => ({
                        id: l.id,
                        mailing_address: l.addressLine1,
                        suite: l.addressLine2,
                        city: l.city,
                        county: l.county,
                        state: l.state,
                        zip: l.zip,
                    })),
                });
            }
        }
        showHandler().catch((error) => {
            navigateToErrorPage(navigate, error);
        });
    });

    useFormEngineEvent(formEngine as FormElement, 'onbeforeprevpage', (event) => {
        if (event.futurePage?.id === RISK_PROFILE_PAGE_ID && formEngine) {
            event.preventDefault();
            navigateToRiskProfile(formEngine);
        }
    });

    useFormEngineEvent(formEngine as FormElement, 'onbeforenextpage', (event) => {
        if (event.futurePage?.id === RISK_PROFILE_PAGE_ID && formEngine) {
            // The following is a work-around for the MPL edge case when a user
            // is about to navigate to the risk assessment page. We need to manually commit
            // the formEngine page here, or else the `event.preventDefault()` call inhibits
            // the naturally occuring commit on `next` click. Without the commit, the user
            // can effectively continue the questionnaire without entering values in the fields, which is a no-no.
            //
            // The `event.preventDefault()` is needed to remove a graphical glitch
            // where the user would see an empty questionnaire page for a split second (due to the inferred transition)
            const currentPageIdx = formEngine.machine.getPrevPageIndex() + 1;
            formEngine.machine.state.pageList[currentPageIdx].machine.commit();
            if (!formEngine.machine.state.pageList[currentPageIdx].isValid()) {
                return;
            }
            event.preventDefault();
            navigateToRiskProfile(formEngine);
        }
    });

    const navigateToRiskProfile = async (formEngine: FormElement) => {
        const questionnaireData = formEngine.getSnapshot().value;
        const updateApplicationResponse = await execute(UpdateApplicationQuestionnaire, {
            applicationId,
            questionnaireData: JSON.stringify(questionnaireData),
        });
        if (isErr(updateApplicationResponse)) {
            container.get<Logger>(Log).error(updateApplicationResponse);
            return;
        }

        const updateApplicant = await execute(UpdateApplicantFromBusinessProfile, {
            questionnaireData,
        });
        if (
            isErr(updateApplicant) &&
            updateApplicant.errors.some((error) => error.code !== ErrorCode.AnswerMissing)
        ) {
            navigateToErrorPage(navigate, updateApplicant.errors);
            return;
        }

        navigate(
            URI.build('/shopping/risk-assessment', {
                applicationId: applicationId,
                organizationId: activeSession.organizationId as UUID,
                completed: isPageCompleted(formEngine, RISK_PROFILE_PAGE_ID),
            }),
        );
    };

    const navigateAfterModalConformation = () => {
        if (!firstInvalidPageId || !formEngine) {
            return;
        }
        formEngine.gotoPage(firstInvalidPageId);
    };

    useFormEngineEvent(formEngine as FormElement, 'onbeforesubmit', () => {
        if (!formEngine) {
            return;
        }
        const formPagesInvalidIdList = getFormPagesInvalidIdList(formEngine);
        if (formPagesInvalidIdList.length === 0) {
            return;
        }
        const invalidPageId = formPagesInvalidIdList[0];
        setFirstInvalidPageId(invalidPageId);
        missingInformationModal.show();
    });

    useFormEngineEvent(formEngine as FormElement, 'submit', () => {
        async function submitHandler() {
            if (!formEngine) {
                return;
            }
            const questionnaireData = formEngine.getSnapshot().value;

            appendSignatureData(questionnaireData);

            const updateApplicationQuestionnaireResponse = await execute(
                UpdateApplicationQuestionnaire,
                {
                    applicationId,
                    questionnaireData: JSON.stringify(questionnaireData),
                },
            );
            if (isErr(updateApplicationQuestionnaireResponse)) {
                navigateToErrorPage(navigate, updateApplicationQuestionnaireResponse.errors);
                return;
            }

            const updateApplicant = await execute(UpdateApplicantFromBusinessProfile, {
                questionnaireData,
            });
            if (isErr(updateApplicant)) {
                navigateToErrorPage(navigate, updateApplicant.errors);
                return;
            }
            publishFormEvent(
                FormEventName.Saved,
                formEngine,
                applicationId,
                currentApplication?.appType,
                currentApplication?.shoppingCoverageList,
                currentApplication?.isRenewal,
            );
            if (currentApplication?.creationType === Bundle) {
                publishFormEvent(
                    FormEventName.TierIIICompleted,
                    formEngine,
                    applicationId,
                    currentApplication?.appType,
                    currentApplication?.shoppingCoverageList,
                    currentApplication?.isRenewal,
                );
            }
            publishFormEvent(
                FormEventName.Submitted,
                formEngine,
                applicationId,
                currentApplication?.appType,
                currentApplication?.shoppingCoverageList,
                currentApplication?.isRenewal,
            );

            const submitApplicationResponse = await execute(SubmitApplication, {
                applicationId,
            });
            if (isErr(submitApplicationResponse)) {
                navigateToErrorPage(navigate, submitApplicationResponse.errors);
                return;
            }
            const { taskId } = submitApplicationResponse.value;
            setSubmissionTaskId(taskId);

            const url = URI.build('/shopping/application/submission', {
                taskId: taskId,
            });
            navigate(url);
        }
        submitHandler().catch((error) => {
            navigateToErrorPage(navigate, error);
        });
    });

    const deserializedQuestionnaireData = JSONSerdes.deserialize(
        currentApplication?.questionnaireData ?? '{}',
    );

    if (isErr(deserializedQuestionnaireData)) {
        return <ErrorPage errors={deserializedQuestionnaireData.errors} />;
    }

    if (!formEngine) {
        return <Spinner />;
    }

    return (
        <React.Fragment>
            <ExitConfirmationModal
                modal={exitConfirmationModal}
                onExitApplication={onConfirmExitApplication}
            />
            <MissingInformationModal
                modal={missingInformationModal}
                navigateAfterModalConformation={navigateAfterModalConformation}
            />
            <FormEngine
                instance={formEngine}
                navigation
                progressBarTitleRenderer={ApplicationQuestionnaireTitle}
                lastCompletedPageId={lastCompletedPage}
                page={page || pageAfterLastCompleted || defaultPage}
                onExitFullScreen={onExitFullScreen}
                progressBarRange={PROGRESS_BAR_RANGE}
                dismissAppearance="save-and-exit"
            />
            {isBroker && !isMobile && (
                <FloatingLayout position="bottom">
                    <BrokerApplicationInfoBar application={currentApplication} />
                </FloatingLayout>
            )}
        </React.Fragment>
    );
});

function getFormPagesInvalidIdList(formEngine: FormElement) {
    const { pageList } = formEngine.machine.state;

    return pageList
        .filter((page: PageElement) => {
            const canNavigateTo = page.canNavigateTo();
            const isPageInvalid = !page.isValid();

            return canNavigateTo && isPageInvalid;
        })
        .map((page: PageElement) => page.id);
}

export function publishFormEvent(
    eventName: FormEventName,
    formEngine: FormElement,
    applicationId: UUID,
    appType?: AppTypeCode,
    shoppingCoverageList?: readonly ShoppingCoverage[],
    isRenewal?: boolean,
    pageId?: string,
) {
    const visiblePage = formEngine.machine.state.visiblePage;
    const pageList = formEngine.machine.state.pageList;
    const page: PageElement = pageId
        ? pageList.find((pageElement: PageElement) => pageElement.machine.state.id === pageId)
        : pageList[visiblePage];

    const parentPage = findTopLevelPageParent(page);
    const snapshot = formEngine.getSnapshot();
    const questionnaireData = snapshot.value;
    const pageParentName = parentPage?.machine.state.title ?? page?.machine.state.title;

    const serializedQuestionnaireData = JSONSerdes.serialize(questionnaireData);
    const formName = page?.machine.state.title ?? page?.machine.state.id;

    // Law Bundle specific event logic - Tier II completed
    if (
        appType === 'AppTypeCodeListLawBundle' &&
        page?.machine.state.id === 'law_bundle_additional_company_information' &&
        eventName === FormEventName.Viewed
    ) {
        execute(PublishFormEvents, {
            eventName: FormEventName.TierIICompleted,
            formStage: 'Law Bundle Tier II',
            formName: formName,
            applicationId,
            appTypeList: appType ? [appType] : [],
            shoppingCoverageList,
            questionnaireData: isOK(serializedQuestionnaireData)
                ? serializedQuestionnaireData.value
                : undefined,
            isRenewal: isRenewal ?? false,
        }).then((publishResult) => {
            if (isErr(publishResult)) {
                container.get<Logger>(Log).error(publishResult.errors);
                return;
            }
        });
    }

    execute(PublishFormEvents, {
        eventName,
        formStage: pageParentName,
        formName: formName,
        applicationId,
        appTypeList: appType ? [appType] : [],
        shoppingCoverageList,
        questionnaireData: isOK(serializedQuestionnaireData)
            ? serializedQuestionnaireData.value
            : undefined,
        isRenewal: isRenewal ?? false,
    }).then((publishResult) => {
        if (isErr(publishResult)) {
            container.get<Logger>(Log).error(publishResult.errors);
            return;
        }
    });
}

function findTopLevelPageParent(page: Nullable<Element>): Nullable<Element> {
    return page?.parent?.id ? findTopLevelPageParent(page?.parent) : page;
}

function getNextPage(formEngine: Element, currentPage: string): string {
    const pageList = formEngine.machine.state.pageList as PageElement[];
    const firstPage = pageList[0].id;
    const currentPageIndex = pageList.findIndex((page: PageElement) => page.id === currentPage);
    if (currentPageIndex === -1) {
        return firstPage;
    }
    for (let i = currentPageIndex + 1; i < pageList.length; i++) {
        if (pageList[i].canNavigateTo()) {
            return pageList[i].id;
        }
    }

    return firstPage;
}

function isPageCompleted(formEngine: Element, page: string): boolean {
    const pageList = formEngine.machine.state.pageList as PageElement[];
    const foundPage = pageList.find((pageElement: PageElement) => pageElement.id === page);
    return Boolean(foundPage && foundPage.machine.state.completed);
}
