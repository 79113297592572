import { container } from '@embroker/shotwell/core/di';
import { GrowthBookExperimentationService } from '../../experimentation/services/GrowthBookExperimentationService';
import {
    isControlExperimentTest,
    isVariantExperimentTest,
} from '../../experimentation/types/ExperimentationTest';
import { execute } from '@embroker/shotwell/core/UseCase';
import { FormEventName, PublishFormEvents } from '../useCases/PublishFormEvents';
import { AppTypeCodeListLawBundle } from '@app/shopping/types/enums';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { UUID } from '@embroker/shotwell/core/types/UUID';

export const showCoverageRecommendation = () => {
    const experimentationService = container.get<GrowthBookExperimentationService>(
        GrowthBookExperimentationService,
    );
    const premiumQuoteTest = experimentationService.getExperimentationTest(
        'law-vertical-coverage-recomendation-test',
    );
    return isVariantExperimentTest(premiumQuoteTest);
};

export const showIndicativePremium = () => {
    const experimentationService = container.get<GrowthBookExperimentationService>(
        GrowthBookExperimentationService,
    );
    const premiumQuoteTest = experimentationService.getExperimentationTest(
        'law-bundle-indicative-premium-test',
    );
    return isControlExperimentTest(premiumQuoteTest);
};

interface PublishFormEventInput {
    eventName: FormEventName;
    formName: string;
    applicationId?: UUID;
    questionnaireData?: string;
}

export async function publishFormEvents(input: PublishFormEventInput) {
    const { eventName, formName, applicationId, questionnaireData } = input;
    execute(PublishFormEvents, {
        eventName,
        formStage: 'Law Bundle Tier I',
        formName: formName,
        applicationId: applicationId ?? null,
        appTypeList: [AppTypeCodeListLawBundle],
        isRenewal: false,
        questionnaireData,
    }).then((publishResult) => {
        if (isErr(publishResult)) {
            container.get<Logger>(Log).error(publishResult.errors);
            return;
        }
    });
}
