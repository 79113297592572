import { TextButton } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { CoverageCatalog } from '../../CoverageCatalog';
import { DocumentType } from '../../types/BundleQuoteDocument';
import { DocumentsItemComponentProp } from './BundleQuoteLandingPage';

export function DownloadDocumentButton({
    isSubmitting,
    documentType,
    handleTrigger,
    isDisabled,
    coverageType,
}: DocumentsItemComponentProp) {
    const documentTitle = coverageType ? CoverageCatalog.getDocumentTitle(coverageType) : undefined;
    const toDisplayName =
        documentType && documentTitle
            ? documentTitle + ' ' + DocumentType.toDisplayName(documentType)
            : DocumentType.toDisplayName(documentType);
    return (
        <TextButton
            as="button"
            disabled={isDisabled || isSubmitting}
            icon="download"
            color="primary-500"
            onClick={handleTrigger}
            data-e2e="document-button"
        >
            {toDisplayName}
        </TextButton>
    );
}
