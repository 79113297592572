import React from 'react';
import { Text, HeroBanner } from '@embroker/ui-toolkit/v2';

type WelcomeHeroBannerProps = {
    title: string;
    subtitle: string;
};
export function WelcomeHeroBanner({ title, subtitle }: WelcomeHeroBannerProps) {
    return (
        <HeroBanner>
            <h1>
                <Text as="span" style="heading light 1" color="ui-50">
                    {title}
                </Text>
                <br />
                <Text as="span" style="heading 1" color="ui-50">
                    {subtitle}
                </Text>
            </h1>
        </HeroBanner>
    );
}
