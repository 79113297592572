import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { container } from '@embroker/shotwell/core/di';
import { JSONSerdes } from '@embroker/shotwell/core/encoding';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { deepClone } from '@embroker/shotwell/core/object';
import { Money, USD } from '@embroker/shotwell/core/types/Money';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { useUseCaseTrigger } from '@embroker/shotwell/view/hooks/useUseCaseTrigger';
import { Spinner, useModal } from '@embroker/ui-toolkit/v2';
import { CalculateSKU } from '../../../../analytics/useCases/CalculateSKU';
import { navigateToErrorPage } from '../../../../view/errors';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { IndicativePremiumCreated } from '../../../entities/IndicativePremium';
import { AppTypeCode } from '../../../types/enums';
import { LawBundleQuestionnaireData } from '../../../types/LawBundleQuestionnaireData';
import { Quote } from '../../../types/Quote';
import { GetApplication } from '../../../useCases/GetApplication';
import { GetIndicativePremiumList } from '../../../useCases/GetIndicativePremiumList';
import { FormEventName } from '../../../useCases/PublishFormEvents';
import { UpdateApplicationQuestionnaire } from '../../../useCases/UpdateApplicationQuestionnaire';
import {
    GetLawBundleApplications,
    UnderlyingApp,
} from '../../../useCases/GetLawBundleApplications';
import { URI } from '@embroker/shotwell/core/types/URI';
import { SaveApplicationLastPage } from '../../../useCases/SaveApplicationLastPage';
import { AppContext } from '../../../../view/AppContext';
import { publishFormEvents, showCoverageRecommendation } from '../../../types/lawBundleWizard';
import { CoverageType } from '../../../types/CoverageType';
import { CoverageSelectionLayout } from './CoverageSelectionLayout.view';
import { CoverageRecommendationLayout } from './CoverageRecommendationLayout.view';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import {
    PublishShoppingUserClickEvent,
    ShoppingUserClickEventName,
} from '@app/shopping/useCases/PublishShoppingUserClickEvent';
import { GrowthBookExperimentationService } from '@app/experimentation/services/GrowthBookExperimentationService';
import { LPLDeselectedModal } from './LPLDeselectedModal.view';

interface IndicativePremiumPageProps {
    applicationId: UUID;
}

export interface BundleCoverageInfo {
    coverage: CoverageType;
    indicativePremium: Money;
    isRequired: boolean;
    isSelected: boolean;
    quote?: Quote;
}

const appTypeToCoverageToggleMap = new Map<AppTypeCode, CoverageType>([
    ['AppTypeCodeListEverestLawyersProfessionalLiability', CoverageType.LPL],
    ['AppTypeCodeListCyberCowbell', CoverageType.Cyber],
    ['AppTypeCodeListBOPChubb', CoverageType.BOP],
    ['AppTypeCodeListWCChubb', CoverageType.WC],
]);

function publishIndicativePremiumEvent(
    bundleCoverageInfoList: BundleCoverageInfo[],
    applicationId: UUID,
    eventBus: DomainEventBus,
    indicativePremiumAmount: Money,
) {
    const coverageToggleList: CoverageType[] = bundleCoverageInfoList
        .filter((info) => info.isSelected)
        .map((info) => info.coverage);

    const calculateSKUuseCase = container.get<CalculateSKU>(CalculateSKU.type);
    calculateSKUuseCase
        .execute({
            appType: 'AppTypeCodeListLawBundle',
            event: 'questionnaire_changed',
            shoppingCoverageList: [],
            questionnaireData: JSON.stringify({ coverage_toggles: coverageToggleList }),
        })
        .then((result) => {
            const event: IndicativePremiumCreated = {
                origin: 'IndicativePremium',
                name: 'Created',
                createdAt: new Date(Date.now()),
                applicationId,
                indicativePremiumAmount,
                id: UUID.create(),
                isRenewal: false,
                sku: isOK(result) ? result.value : undefined,
            };
            eventBus.publish(event);
        });
}

export function IndicativePremiumPage({ applicationId }: IndicativePremiumPageProps) {
    const { navigate } = useNavigation();
    const [bundleCoverageInfoList, setBundleCoverageInfoList] = useState<BundleCoverageInfo[]>([]);
    const [quoteEstimate, setQuoteEstimate] = useState<Money>(USD(0));
    const [questionnaireData, setQuestionnaireData] = useState<LawBundleQuestionnaireData>();
    const eventBus = useMemo(() => container.get<DomainEventBus>(DomainEventBus), []);
    const [radioItems, setRadioItems] = useState<UnderlyingApp[]>([]);
    const { activeSession } = useContext(AppContext);

    const selectedCoverageToggles: CoverageType[] = bundleCoverageInfoList
        .filter((info) => info.isSelected)
        .map((info) => info.coverage);

    const lplDeselectedModal = useModal({ visible: false });

    const {
        result: indicativePremiumListResult,
        trigger: recalculatePremium,
        isLoading,
    } = useUseCaseTrigger(GetIndicativePremiumList, {
        applicationId: applicationId,
    });

    const { result: getApplicationResult, trigger: getApplication } = useUseCaseTrigger(
        GetApplication,
        {
            applicationId: applicationId,
        },
    );

    const { result: getLawBundleApplicationsResult, trigger: getLawBundleApplications } =
        useUseCaseTrigger(GetLawBundleApplications, { questionnaireData });

    useEffect(() => {
        // GetLawBundleApplications returns a static list of all available covergaes
        // GetApplication returns data related to the curent application
        const underlyingApps =
            getLawBundleApplicationsResult && isOK(getLawBundleApplicationsResult)
                ? getLawBundleApplicationsResult.value.underlyingApps
                : undefined;
        const appDetails =
            getApplicationResult && isOK(getApplicationResult)
                ? getApplicationResult.value.application.bundleDetails?.appDetails
                : undefined;

        if (appDetails && underlyingApps) {
            // underlyingApps uses toggle values so map appTypes to coverga toggles
            const bundleAppTypes = appDetails.map((app) =>
                appTypeToCoverageToggleMap.get(app.appType),
            );
            // Only use coverages that are included in the current application.bundleDetails?.appDetails object
            const radioItems = underlyingApps.filter(({ value }) =>
                bundleAppTypes.includes(value),
            ) as UnderlyingApp[];
            // Since the value returned by getLawBundleApplicationsResult is of readonly, 'as UnderlyingApp[]' is needed here to satisfy TS
            setRadioItems(radioItems);
        }
    }, [getLawBundleApplicationsResult, getApplicationResult]);

    useEffect(() => {
        if (questionnaireData) {
            getLawBundleApplications();
        }
    }, [questionnaireData, getLawBundleApplications]);

    useEffect(() => {
        // Fire the below useUseCaseTriggers on component mount
        recalculatePremium();
        getApplication();
    }, [recalculatePremium, getApplication]);

    useEffect(() => {
        if (applicationId && activeSession.userId) {
            execute(SaveApplicationLastPage, {
                applicationId,
                userId: activeSession.userId,
                lastPage: 'coverage-selection',
            });
        }
    }, [applicationId, activeSession]);

    useEffect(() => {
        publishFormEvents({
            eventName: FormEventName.Viewed,
            formName: 'Indicative Premium',
            applicationId,
        });
    }, [applicationId]);

    const updateApplicationQuestionnaire = useCallback(
        async (
            coverageToggleList: CoverageType[],
            additionalQuestionnaireData?: LawBundleQuestionnaireData,
        ) => {
            if (!questionnaireData) {
                return;
            }

            const newQuestionnaireData = {
                ...deepClone(questionnaireData),
                ...additionalQuestionnaireData,
            };
            newQuestionnaireData.coverage_toggles = coverageToggleList;
            const serializedQuestionnaireData = JSONSerdes.serialize(newQuestionnaireData);

            await publishFormEvents({
                eventName: FormEventName.Saved,
                formName: 'Indicative Premium',
                applicationId,
                questionnaireData: isOK(serializedQuestionnaireData)
                    ? serializedQuestionnaireData.value
                    : undefined,
            });

            const updateApplicationQuestionnaireResponse = await execute(
                UpdateApplicationQuestionnaire,
                {
                    applicationId,
                    questionnaireData: JSON.stringify(newQuestionnaireData),
                },
            );
            if (isErr(updateApplicationQuestionnaireResponse)) {
                throw updateApplicationQuestionnaireResponse.errors;
            }

            if (activeSession.userId === null) {
                container.get<Logger>(Log).error('User ID is missing from the active session');
            } else {
                const result = await execute(SaveApplicationLastPage, {
                    applicationId,
                    userId: activeSession.userId,
                    lastPage: '#',
                });

                if (isErr(result)) {
                    container.get<Logger>(Log).error(result.errors);
                }
            }
        },
        [applicationId, questionnaireData, activeSession.userId],
    );

    const startApplication = useCallback(
        (
            coverageToggles: CoverageType[],
            additionalQuestionnaireData: LawBundleQuestionnaireData = {},
            clickEventName?: ShoppingUserClickEventName,
        ) => {
            if (clickEventName) {
                execute(PublishShoppingUserClickEvent, { clickEventName });
            }

            updateApplicationQuestionnaire(coverageToggles, additionalQuestionnaireData)
                .then(() => {
                    navigate(
                        URI.build('/shopping/application', {
                            applicationId,
                        }),
                    );
                })
                .catch((error) => {
                    navigateToErrorPage(navigate, error);
                });
        },
        [applicationId, navigate, updateApplicationQuestionnaire],
    );

    const handleGetStarted = useCallback(
        (coverageToggles: CoverageType[]) => {
            if (
                container
                    .get<GrowthBookExperimentationService>(GrowthBookExperimentationService)
                    .getFeatureValue('lpl-deselected-popup', false) &&
                !coverageToggles.includes(CoverageType.LPL)
            ) {
                lplDeselectedModal.show();
                return;
            }

            startApplication(coverageToggles);
        },
        [lplDeselectedModal, startApplication],
    );

    const confirmApplicationWithoutLPLGetAQuote = useCallback(() => {
        startApplication(
            selectedCoverageToggles.concat(CoverageType.LPL),
            {},
            'LPL Deselected Pop up _ get a quote clicked',
        );
    }, [startApplication, selectedCoverageToggles]);

    const confirmApplicationWithoutLPLAlreadyHaveCoverage = useCallback(() => {
        startApplication(
            selectedCoverageToggles.filter((coverageType) => coverageType !== CoverageType.LPL),
            { current_pl: true },
            'LPL Deselected Pop up _ I have this coverage clicked',
        );
    }, [startApplication, selectedCoverageToggles]);

    const confirmApplicationWithoutLPLClosePopup = useCallback(() => {
        startApplication(
            selectedCoverageToggles.filter((coverageType) => coverageType !== CoverageType.LPL),
            { current_pl: null },
            'LPL Deselected Pop up closed',
        );
    }, [startApplication, selectedCoverageToggles]);

    const changeApplicationSelection = useCallback(
        (value: string) => {
            const coverageInfoList = bundleCoverageInfoList.map((coverageInfo) => {
                if (coverageInfo.coverage.toString() === value) {
                    return {
                        ...coverageInfo,
                        isSelected: !coverageInfo.isSelected,
                    };
                }
                return coverageInfo;
            });
            const updatedRadioItems = radioItems.map((item) => {
                if (item.value === value) {
                    return {
                        ...item,
                        isSelected: !item.isSelected,
                    };
                }
                return item;
            });
            setRadioItems(updatedRadioItems);
            setBundleCoverageInfoList(coverageInfoList);
        },
        [bundleCoverageInfoList, radioItems],
    );

    useEffect(() => {
        if (getApplicationResult !== undefined && isOK(getApplicationResult)) {
            const application = getApplicationResult.value.application;
            if (application.questionnaireData === null) {
                return;
            }

            const parsedQuestionnaireData = JSON.parse(application.questionnaireData);
            const lawBundleQuestionnaireDataResult =
                LawBundleQuestionnaireData.create(parsedQuestionnaireData);
            if (isOK(lawBundleQuestionnaireDataResult)) {
                setQuestionnaireData(
                    lawBundleQuestionnaireDataResult.value as LawBundleQuestionnaireData,
                );
            }
        }
    }, [getApplicationResult]);

    const stateWithMostAttorneys = questionnaireData?.state_with_most_attorneys || null;
    useEffect(() => {
        if (indicativePremiumListResult !== undefined && isOK(indicativePremiumListResult)) {
            const bundleCoverageList = indicativePremiumListResult.value.indicativePremiumList
                .map((indicativePremium) => {
                    const coverageType = appTypeToCoverageToggleMap.get(indicativePremium.appType);
                    const isSelected = Boolean(
                        radioItems.find(({ value }) => value === coverageType)?.isSelected,
                    );
                    if (coverageType) {
                        return {
                            coverage: coverageType,
                            indicativePremium: indicativePremium.basePremium,
                            isRequired:
                                indicativePremium.appType ===
                                'AppTypeCodeListEverestLawyersProfessionalLiability',
                            isSelected: isSelected,
                            quote: indicativePremium.quote,
                        };
                    }
                    return null;
                })
                .filter((info) => info !== null) as BundleCoverageInfo[];
            setBundleCoverageInfoList(bundleCoverageList);
        }
    }, [indicativePremiumListResult, stateWithMostAttorneys, radioItems]);

    useEffect(() => {
        if (bundleCoverageInfoList.length > 0) {
            const quoteEstimateSum = Money.sum(
                bundleCoverageInfoList.map((info) =>
                    info.isSelected ? info.indicativePremium : USD(0),
                ),
            );
            if (isOK(quoteEstimateSum)) {
                setQuoteEstimate(quoteEstimateSum.value);
                publishIndicativePremiumEvent(
                    bundleCoverageInfoList,
                    applicationId,
                    eventBus,
                    quoteEstimateSum.value,
                );
            }
        }
    }, [applicationId, bundleCoverageInfoList, eventBus]);

    if (indicativePremiumListResult === undefined || isLoading) {
        return <Spinner />;
    }

    if (isErr(indicativePremiumListResult)) {
        return (
            <div>
                <ErrorPage errors={indicativePremiumListResult.errors} />
            </div>
        );
    }

    const navigateBackToQuestions = () => {
        navigate(URI.build(`/shopping/law-bundle/tier-1`, { applicationId }));
    };

    return (
        <React.Fragment>
            {showCoverageRecommendation() ? (
                <CoverageRecommendationLayout
                    radioItems={radioItems}
                    handleGetStarted={() => handleGetStarted(selectedCoverageToggles)}
                    changeApplicationSelection={changeApplicationSelection}
                />
            ) : (
                <CoverageSelectionLayout
                    bundleCoverageInfoList={bundleCoverageInfoList}
                    quoteEstimate={quoteEstimate}
                    radioItems={radioItems}
                    handleGetStarted={() => handleGetStarted(selectedCoverageToggles)}
                    navigateBackToQuestions={navigateBackToQuestions}
                    changeApplicationSelection={changeApplicationSelection}
                />
            )}

            <LPLDeselectedModal
                modal={lplDeselectedModal}
                primaryAction={{
                    label: 'Get a Quote',
                    onClick: confirmApplicationWithoutLPLGetAQuote,
                }}
                secondaryAction={{
                    label: 'I Already Have Coverage',
                    onClick: confirmApplicationWithoutLPLAlreadyHaveCoverage,
                }}
                onClose={confirmApplicationWithoutLPLClosePopup}
                onDismiss={confirmApplicationWithoutLPLClosePopup}
            />
        </React.Fragment>
    );
}
