import { useCallback, useMemo } from 'react';
import { OpaqueForm, createForm } from '@embroker/shotwell/view/hooks/useForm';
import { FormFieldViewDefinition } from '../components/DataDrivenForm';
import { InputType, StatusMessageProps } from '@embroker/ui-toolkit/v2';
import { Success } from '@embroker/shotwell/core/types/Result';
import { WizardForm, WizardPages, useWizardForm } from '../../../hooks/useWizardForm';
import { FormFieldDefinition, FormFields, formFieldsFactory } from '../types/formFieldsFactory';
import { ComplexFormFieldType } from '../types/ComplexFieldTypes';
import { ValidationTypeProps, buildFieldValidator } from '../types/fieldValidationFactory';

type InitialValueType = unknown;

export type FormPageDefinition = {
    name: string;
    title?: string;
    fields: string[];
    isInitial?: boolean;
};

export interface DataDrivenFormProps {
    formQuestions: FormQuestionDefinition[];
    formPages?: FormPageDefinition[];
    onFormSubmit: (formValue: { [key: string]: unknown }) => void;
    onPageComplete: (formValue: { [key: string]: unknown }) => void;
}

export type QuestionType = InputType | ComplexFormFieldType;

export interface SelectOption<T> {
    title: string;
    value: T;
}

export type ConditionalDisplayOptions = {
    displayWhen: { questionKey: string; condition: Omit<ValidationTypeProps, 'conditional'> };
};

export interface DdFormFieldSetProps {
    title: React.ReactNode;
    questions: string[];
}

export type StaticFieldOptions = {
    statusMessageProps?: StatusMessageProps;
    fieldSetProps?: DdFormFieldSetProps;
};

export interface FormQuestionDefinition {
    key: string;
    questionType: QuestionType;
    title?: string | React.ReactNode;
    tooltip?: string;
    label?: string;
    placeholder?: string;
    initialValue?: InitialValueType;
    isRequired?: boolean;
    validate?: ValidationTypeProps[];
    selectOptions?: SelectOption<string | boolean | number>[];
    conditionalDisplay?: ConditionalDisplayOptions;
    staticOptions?: StaticFieldOptions;
    isMultiple?: boolean;
    statusMessage?: { content: string; status: StatusMessageProps['status'] };
}

export const getInitialData = (
    formQuestionDefinitions: FormFieldDefinition[],
): { [key: string]: unknown } => {
    return formQuestionDefinitions.reduce((acc, { key, initialValue }) => {
        acc[key] = initialValue;
        return acc;
    }, {} as { [key: string]: unknown });
};

export function findDependencyChain(
    questions: FormQuestionDefinition[],
    questionKey: string,
): ConditionalDisplayOptions[] {
    const question = questions.find((_question) => _question.key === questionKey);
    if (!question || !question.conditionalDisplay) {
        return [];
    }

    const dependencyKey = question.conditionalDisplay.displayWhen.questionKey;
    // Check if there is an actual question with the dependencyKey.
    const dependencyQuestion = questions.find((_question) => _question.key === dependencyKey);
    if (!dependencyQuestion) {
        return [];
    }

    // Recursively find the chain for the dependency and add the current question's key
    const dependencyChain = findDependencyChain(questions, dependencyKey);
    dependencyChain.push(question.conditionalDisplay);

    return dependencyChain;
}
// onFormSubmit: (formValue: unknown) => void
export const createDataDrivenForm = (
    fields: FormFields,
    onFormSubmit: (formValue: { [key: string]: unknown }) => void,
) => {
    return createForm({
        fields,
        formatSubmitErrors(errors: any) {
            return ['Sorry, something went wrong. Please try again later.'];
        },
        submit: async (formData: { [key: string]: unknown }) => {
            onFormSubmit(formData);
            return Success({ formData });
        },
    });
};

export const isFieldHidden = (
    values: { readonly [x: string]: unknown },
    conditionalDisplayDependencyChain: ConditionalDisplayOptions[] = [],
): boolean => {
    return (
        conditionalDisplayDependencyChain.filter((conditionalDisplay) => {
            const {
                displayWhen: { questionKey, condition },
            } = conditionalDisplay;

            const dependentFieldPresent = Object.keys(values).some((key) => questionKey === key);
            let validator = buildFieldValidator(condition);
            if (dependentFieldPresent) {
                validator = validator.required();
            } else {
                validator = validator.optional();
            }

            return Boolean(validator.validate(values[questionKey]).error);
        }).length > 0
    );
};

export const resolvePages = (
    formQuestions: FormQuestionDefinition[],
    formPages?: FormPageDefinition[],
): WizardPages<OpaqueForm<{ [key: string]: any }>> => {
    if (formPages && formPages.length) {
        return formPages;
    }
    return [{ name: 'Form', fields: formQuestions.map(({ key }) => key) }];
};
export interface GetOnCompletePageValuesProps {
    pages: WizardPages<OpaqueForm<{ [key: string]: any }>>;
    activePageIndex: number;
    formQuestionDefinitions: FormFieldDefinition[];
    value: WizardForm<OpaqueForm<{ [key: string]: unknown }>>['value'];
}
export const getOnCompletePageValues = (props: GetOnCompletePageValuesProps) => {
    const { pages, activePageIndex, formQuestionDefinitions, value } = props;

    const initPageValues: { [key: string | number]: unknown } = {};

    return pages[activePageIndex].fields.reduce((acc, field) => {
        const questionDefinition = formQuestionDefinitions.find(({ key }) => field === key);
        if (!questionDefinition) {
            return acc;
        }

        if (isFieldHidden(value, questionDefinition.conditionalDisplayDependencyChain)) {
            return acc;
        }

        acc[field] = value[field];

        return acc;
    }, initPageValues);
};

export const getOnSubmitPageValues = (
    formQuestionDefinitions: FormFieldDefinition[],
    value: WizardForm<OpaqueForm<{ [key: string]: unknown }>>['value'],
) => {
    const initPageValues: { [key: string | number]: unknown } = {};
    return Object.keys(value).reduce((acc, fieldKey) => {
        const questionDefinition = formQuestionDefinitions.find(({ key }) => fieldKey === key);
        if (!questionDefinition) {
            return acc;
        }

        if (isFieldHidden(value, questionDefinition.conditionalDisplayDependencyChain)) {
            return acc;
        }

        acc[fieldKey] = value[fieldKey];

        return acc;
    }, initPageValues);
};

export function useDataDrivenForm({
    formQuestions,
    formPages,
    onPageComplete,
    onFormSubmit,
}: DataDrivenFormProps) {
    const pages = resolvePages(formQuestions, formPages);

    const formQuestionDefinitions: FormFieldDefinition[] = useMemo(() => {
        return formQuestions.map((question) => ({
            ...question,
            conditionalDisplayDependencyChain: findDependencyChain(formQuestions, question.key),
        }));
    }, [formQuestions]);

    const handleOnFormSubmit = useCallback(
        (formValue: { [key: string]: unknown }) => {
            const onSubmitValues = getOnSubmitPageValues(formQuestionDefinitions, formValue);
            onFormSubmit(onSubmitValues);
        },
        [formQuestionDefinitions, onFormSubmit],
    );

    const { formInstance, initialData } = useMemo(() => {
        const formFields = formFieldsFactory(formQuestionDefinitions);
        const formInstance = createDataDrivenForm(formFields, handleOnFormSubmit);
        const initialData = getInitialData(formQuestionDefinitions);

        return { formInstance, initialData };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formQuestionDefinitions]);

    const handleBeforeNext = useCallback(
        async ({
            activePageIndex,
            value,
        }: {
            activePageIndex: WizardForm<typeof formInstance>['activePageIndex'];
            value: WizardForm<typeof formInstance>['value'];
        }) => {
            const onCompletePageValues = getOnCompletePageValues({
                pages,
                activePageIndex,
                formQuestionDefinitions,
                value,
            });

            onPageComplete(onCompletePageValues);
        },
        [pages, formQuestionDefinitions, onPageComplete],
    );

    const FormProps = useWizardForm(formInstance, {
        pages,
        initialValue: initialData,
        beforeNext: handleBeforeNext,
    });

    const {
        activePageIndex,
        activePageName,
        hasNext,
        hasPrevious,
        fields,
        value,
        next,
        previous,
        setActiveStep,
        setFieldValue,
    } = FormProps;

    const currentPage = pages[activePageIndex];

    const currentPageQuestions: FormFieldViewDefinition[] = formQuestionDefinitions
        .filter(
            (formQuestion: FormFieldDefinition) =>
                !isFieldHidden(value, formQuestion.conditionalDisplayDependencyChain) &&
                currentPage.fields.includes(formQuestion.key),
        )
        .map((formQuestion: FormQuestionDefinition) => {
            return {
                questionProps: {
                    key: formQuestion.key,
                    questionType: formQuestion.questionType,
                    title: formQuestion.title,
                    tooltip: formQuestion.tooltip,
                    label: formQuestion.label,
                    selectOptions: formQuestion.selectOptions,
                    staticOptions: formQuestion.staticOptions,
                    isMultiple: formQuestion.isMultiple,
                },
                inputFieldProps: {
                    type: fields[formQuestion.key].type,
                    inputProps: {
                        ...fields[formQuestion.key].props,
                        placeholder: formQuestion.placeholder,
                    },
                    messages: fields[formQuestion.key].messages,
                },
            };
        });

    return {
        hasNext,
        hasPrevious,
        currentPage: {
            name: activePageName,
            questions: currentPageQuestions,
            title: pages[activePageIndex].title,
        },
        next,
        previous,
        setFieldValue,
        setActiveStep,
    };
}
