import * as APIType from '@embroker/shotwell-api/app';
import {
    API,
    InsuranceApplication,
    InsuranceApplicationSignaturePacket,
    ListOptions,
    ShoppingApplicationListRequest,
} from '@embroker/shotwell-api/app';
import { AppTypeCodeList, BundleCreationTypeItem } from '@embroker/shotwell-api/enums';
import { isAPIError } from '@embroker/shotwell-api/errors';
import {
    Aborted,
    ErrorCode,
    InvalidArgument,
    OperationFailed,
    UnknownEntity,
} from '@embroker/shotwell/core/Error';
import { injectable } from '@embroker/shotwell/core/di';
import { Data, Immutable, Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import {
    AsyncResult,
    Failure,
    Success,
    handleOperationFailure,
    isErr,
    isOK,
} from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { startOfToday } from 'date-fns';
import {
    ApplicationRepository,
    CreateApplicationIntakeTaskRequest,
    CreateApplicationIntakeTaskResponse,
    CreateApplicationsRequest,
    CreateApplicationsResponse,
    GetApplicationLastPageRepoRequest,
    GetApplicationListResponse,
    GetApplicationWithQuoteDetailsResponse,
    GetClientApplicationResponse,
    GetConfigResponse,
    IntakeAdditionalInfoRequest,
    PageInfo,
    PrecreateApplicationRequest,
    PrecreateApplicationResponse,
    SaveApplicationLastPageRepoRequest,
    SignatureData,
    UpdateQuestionnaireRequest,
} from '.';
import { toBOPChubbQuoteOptions } from '../../../bundle/coverageDefinition/bopChubb/mappingFunctions';
import { toCowbellCyberQuoteOptions } from '../../../bundle/coverageDefinition/cowbellCyber/mappingFunctions';
import { toLawCyberQuoteOptions } from '../../../bundle/coverageDefinition/lawCyber/mappingFunctions';
import { toWCChubbQuoteOptions } from '../../../bundle/coverageDefinition/wcChubb/mappingFunctions';
import { toESPQuoteOptions } from '../../../quote/esp/repositories/ESPQuoteRepository/APIESPQuoteRepository';
import { toLplQuoteOptions } from '../../../quote/lpl/repositories/LPLQuoteRepository/APILPLQuoteRepository';
import { toPCoMLQuoteOptions } from '../../../quote/pcoml/repositories/QuoteRepository/APIQuoteRepository';
import { QuoteExpiration } from '../../../quote/types/QuoteExpiration';
import { QuoteList } from '../../../quote/types/QuoteList';
import { Application } from '../../entities/Application';
import {
    AppTypeNotAllowedForBroker,
    AppTypeNotSupported,
    ApplicationNotFound,
    EnricherAPIError,
    NameClearanceError,
    NoEligibleAppTypeFound,
    OperationNotAllowed,
    OrganizationNotFound,
    PrecreateApplicationError,
    ShoppingCoverageNotProvided,
    ShoppingCoverageNotSupportedForAppType,
    StreamlineRenewalNotEligible,
} from '../../errors';
import { EnrichmentData } from '../../types/EnrichmentData';
import { IneligibilityReasons } from '../../types/IneligibilityReasons';
import { Quote } from '../../types/Quote';
import { SignaturePacketDocument } from '../../types/SignaturePacketDocument';
import {
    AppTypeCode,
    InsuranceApplicationCreationTypeCode,
    InsuranceApplicationStatusCode,
    QuestionnaireInProgress,
    QuoteExpired,
    QuotingEngine,
    QuotingEngineManual,
    ShoppingCoverage,
    SubmissionInProgress,
} from '../../types/enums';

@injectable()
export class APIApplicationRepository implements ApplicationRepository {
    private config: Nullable<Immutable<GetConfigResponse>>;

    constructor() {
        this.config = null;
    }

    async getApplication(id: UUID): AsyncResult<Application, InvalidArgument | OperationFailed> {
        const appResultData = await this.getRawApiAndParsedApplicationData(id);
        if (isErr(appResultData)) {
            return appResultData;
        }

        return Success(appResultData.value.application);
    }

    async getApplicationList(
        listOptions?: ListOptions,
        abortSignal?: AbortSignal,
        sectionType?: string,
    ): AsyncResult<
        GetApplicationListResponse,
        InvalidArgument | OperationFailed | UnknownEntity | Aborted
    > {
        const applicationResponse = await API.request('shopping/application_list', {
            pristine_only: false,
            excluded_status_list: [
                'InsuranceApplicationStatusCodeListCanceledByAdmin',
                'InsuranceApplicationStatusCodeListAutoCanceled',
            ],
            list_options: listOptions ?? null,
            section_type: (sectionType ?? null) as ShoppingApplicationListRequest['section_type'],
        }).withAbortSignal(abortSignal);
        if (isErr(applicationResponse)) {
            if (applicationResponse.errors[0].code === ErrorCode.Aborted) {
                return Failure(Aborted('Get application list', 'Request aborted by user'));
            }
            return handleOperationFailure(applicationResponse);
        }

        const { application_list, list_page } = applicationResponse.value;

        const applicationEntitiesResult = await APIApplicationRepository.toApplicationList(
            application_list,
        );

        if (isErr(applicationEntitiesResult)) {
            return Failure(
                OperationFailed({
                    message: 'Failed to convert applications',
                    errors: applicationEntitiesResult.errors,
                }),
            );
        }
        const pageInfo: PageInfo = {
            totalItems: list_page.total_count,
            size: list_page.page_size,
            index: list_page.page_index,
        };

        return Success({
            applicationList: applicationEntitiesResult.value,
            pageInfo,
        });
    }

    async deleteApplication(
        id: UUID,
    ): AsyncResult<void, InvalidArgument | OperationFailed | UnknownEntity> {
        const applicationResponse = await API.request('shopping/delete_application', {
            id,
        });
        if (isErr(applicationResponse)) {
            return handleOperationFailure(applicationResponse);
        }
        return Success();
    }

    async submitApplication(
        id: UUID,
        userTzOffsetMinutes: number,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed> {
        const applicationResponse = await API.request('shopping/submit_application', {
            id: id,
            user_tz_offset_minutes: userTzOffsetMinutes,
        });
        if (isErr(applicationResponse)) {
            return handleOperationFailure(applicationResponse);
        }
        const applicationEntityResult = await APIApplicationRepository.toApplication(
            applicationResponse.value.application,
        );
        if (isErr(applicationEntityResult)) {
            return handleOperationFailure(applicationEntityResult);
        }
        if (!applicationEntityResult.value.submissionTaskId) {
            return Failure(
                OperationFailed({
                    message: 'Submission task id not provided.',
                }),
            );
        }
        return Success(applicationEntityResult.value.submissionTaskId);
    }

    async submitStreamlineRenewal(
        id: UUID,
        userTzOffsetMinutes: number,
        signatureData: SignatureData,
    ): AsyncResult<
        Nullable<UUID>,
        StreamlineRenewalNotEligible | InvalidArgument | OperationFailed
    > {
        const response = await API.request('shopping/submit_streamline_renewal', {
            application_id: id,
            user_tz_offset_minutes: userTzOffsetMinutes,
            signature_local_utc_offset: signatureData.signatureLocalUtcOffset,
            signature_date_now: signatureData.signatureDateNow,
            signature_date: signatureData.signatureDate,
        });
        if (isErr(response)) {
            return handleOperationFailure(response);
        }

        if (!response.value.is_still_eligible) {
            return Failure(StreamlineRenewalNotEligible());
        }

        return Success(response.value.task_id);
    }

    async getPrefill(): AsyncResult<Data, InvalidArgument | OperationFailed | UnknownEntity> {
        const prefillQuestionnaireResponse = await API.request('shopping/prefill_questionnaire');
        if (isErr(prefillQuestionnaireResponse)) {
            return handleOperationFailure(prefillQuestionnaireResponse);
        }
        const prefillResult = prefillQuestionnaireResponse.value;
        let prefill = {};
        try {
            prefill = JSON.parse(prefillResult.prefill_questionnaire || '{}');
        } catch (e) {
            return Failure(
                OperationFailed({ message: 'Failed to parse application questionnaire prefill.' }),
            );
        }
        return Success(prefill);
    }

    async createApplications(
        request: CreateApplicationsRequest,
    ): AsyncResult<
        CreateApplicationsResponse,
        | InvalidArgument
        | OperationFailed
        | UnknownEntity
        | AppTypeNotAllowedForBroker
        | NoEligibleAppTypeFound
    > {
        const userTzOffsetMinutes = new Date(Date.now()).getTimezoneOffset();
        const createApplicationsResponse = await API.request('shopping/create_applications', {
            app_type_list: [...request.appTypeList],
            questionnaire_data: request.questionnaireData,
            default_esp_partner_code: null,
            // getTimezoneOffset function returns a negative number i.e. for timezone GMT+1 this function will return -60
            // therefore we need to add minus before userTzOffsetMinutes
            user_tz_offset_minutes: -userTzOffsetMinutes,
        });
        if (isErr(createApplicationsResponse)) {
            const error = createApplicationsResponse.errors[0];
            if (isAPIError(error)) {
                switch (error.details.name) {
                    case 'app_type_not_allowed_for_broker': {
                        return Failure(AppTypeNotAllowedForBroker());
                    }
                    case 'no_eligible_app_type_found': {
                        return Failure(NoEligibleAppTypeFound());
                    }
                }
            }
            return handleOperationFailure(createApplicationsResponse);
        }

        const { task_id, application_list, name_cleared } = createApplicationsResponse.value;

        const applicationEntitiesResult = await APIApplicationRepository.toApplicationList(
            application_list,
        );
        if (isErr(applicationEntitiesResult)) {
            return Failure(
                OperationFailed({
                    message: 'Failed to convert applications',
                    errors: applicationEntitiesResult.errors,
                }),
            );
        }
        return Success({
            taskId: task_id,
            applicationList: applicationEntitiesResult.value,
            nameCleared: name_cleared,
        });
    }

    async getConfig(): AsyncResult<
        GetConfigResponse,
        InvalidArgument | OperationFailed | UnknownEntity
    > {
        if (this.config !== null) {
            return Success(this.config);
        }
        const requestResponse = await API.request('global/get_config');
        if (isErr(requestResponse)) {
            return handleOperationFailure(requestResponse);
        }

        const response = requestResponse.value;
        const result = {
            isBOPEnabled: response.is_bop_enabled,
            isBOPChubbEnabled: response.is_bop_chubb_enabled,
            isWCChubbEnabled: response.is_wc_chubb_enabled,
            isWCGAEnabled: response.is_wcga_enabled,
            isPCOMLEnabled: response.is_pcoml_enabled,
            isLPLEnabled: response.is_lpl_enabled,
            isEmbrokerCrimeEnabled: response.is_embroker_crime_enabled,
            isEmbrokerCyberEnabled: response.is_embroker_cyber_enabled,
            isEmbrokerExcessEnabled: response.is_embroker_excess_enabled,
            isMPLEnabled: response.is_mpl_enabled,
            isHomeInspectorsMPLVerticalEnabled: response.is_home_inspectors_mpl_vertical_enabled,
            isRealEstateAgentsMPLVerticalEnabled:
                response.is_real_estate_agents_mpl_vertical_enabled,
            isMPLWCEnabled: response.is_mpl_wc_chubb_enabled,
            isCowbellCyberEnabled: response.is_cyber_cowbell_enabled,
        };

        this.config = result;
        return Success(result);
    }

    async updateQuestionnaireData(
        request: UpdateQuestionnaireRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed> {
        const userTzOffsetMinutes = new Date(Date.now()).getTimezoneOffset();
        const results = await API.request('shopping/save_questionnaire', {
            application_id: request.applicationId,
            is_prefill: false,
            questionnaire_data: request.questionnaireData,
            user_tz_offset_minutes: -userTzOffsetMinutes,
        });
        if (isErr(results)) {
            return handleOperationFailure(results);
        }

        return Success();
    }

    async startPrinting(id: UUID): AsyncResult<UUID, UnknownEntity> {
        const printAppResponse = await API.request('shopping/print_application_in_progress', {
            application_id: id,
        });

        if (isErr(printAppResponse)) {
            return Failure(UnknownEntity('Application', id));
        }

        return Success(printAppResponse.value.task_id);
    }

    async getApplicationWithQuoteDetails(
        id: UUID,
    ): AsyncResult<
        GetApplicationWithQuoteDetailsResponse,
        InvalidArgument | OperationFailed | UnknownEntity
    > {
        const appResultData = await this.getRawApiAndParsedApplicationData(id);
        if (isErr(appResultData)) {
            return appResultData;
        }

        const { apiApplicationResponse, application } = appResultData.value;

        const totalPremium =
            this.extractInitialPremiumFromApplicationApiResponse(apiApplicationResponse);

        return Success({ application, totalPremium });
    }

    async getApplicationLastPage(requestBody: GetApplicationLastPageRepoRequest) {
        const response = await API.request('shopping/get_application_last_page', requestBody);
        if (isErr(response)) {
            return handleOperationFailure(response);
        }

        return Success(response.value);
    }

    async saveApplicationLastPage(requestBody: SaveApplicationLastPageRepoRequest) {
        const response = await API.request('shopping/save_application_last_page', requestBody);
        if (isErr(response)) {
            return handleOperationFailure(response);
        }

        return Success();
    }

    async intakeAdditionalInfo(requestBody: IntakeAdditionalInfoRequest): AsyncResult<void> {
        const additionalData: APIType.IntakeAdditionalData = {
            is_do_selected: requestBody.additionalData.isDnoSelected,
            do_limit: requestBody.additionalData.dnoLimit,
            do_retention: requestBody.additionalData.dnoRetention,
            is_epl_selected: requestBody.additionalData.isEplSelected,
            epl_limit: requestBody.additionalData.eplLimit,
            epl_retention: requestBody.additionalData.eplRetention,
            is_fiduciary_selected: requestBody.additionalData.isFiduciarySelected,
            fiduciary_limit: requestBody.additionalData.fiduciaryLimit,
            fiduciary_retention: requestBody.additionalData.fiduciaryRetention,
            is_eo_selected: requestBody.additionalData.isEnoSelected,
            eo_limit: requestBody.additionalData.enoLimit,
            eo_retention: requestBody.additionalData.enoRetention,
            cyber_crime_limit: requestBody.additionalData.cyberCrimeLimit,
            cyber_crime_retention: requestBody.additionalData.cyberCrimeRetention,
            lpl_limit: requestBody.additionalData.lplLimit,
            lpl_aggregate_limit: requestBody.additionalData.lplAggregateLimit,
            lpl_deductible: requestBody.additionalData.lplDeductible,
            is_lpl_first_dollar_deductible: requestBody.additionalData.isLplFirstDollarDeductible,
            lpl_additional_claims_option: requestBody.additionalData.lplAdditionalClaimsOption,
            lpl_retroactive_date: requestBody.additionalData.lplRetroactiveDate,
            effective_date: requestBody.additionalData.effectiveDate,
            additional_info: requestBody.additionalData.additionalInfo,
        };
        const apiResult = await API.request('shopping/set_additional_intake_info', {
            additional_data: additionalData,
            app_type: requestBody.appType,
            file_list: requestBody.additionalFiles,
            application_id: requestBody.applicationId,
        });

        if (isErr(apiResult)) {
            return handleOperationFailure(apiResult);
        }
        return Success();
    }

    async getEnricherData(appId: UUID): AsyncResult<EnrichmentData, EnricherAPIError> {
        const enricherDataResult = await API.request('shopping/get_enricher_data', {
            application_id: appId,
        });
        if (isErr(enricherDataResult)) {
            return Failure(EnricherAPIError());
        }

        return Success({
            totalFunding: enricherDataResult.value.total_funding?.value,
            totalFundingCrunchbaseHandle: enricherDataResult.value.total_funding?.crunchbase_handle,
            glassdoorRating: enricherDataResult.value.glassdoor_rating,
        });
    }

    private static async toApplicationList(
        applicationList: Immutable<InsuranceApplication[]>,
    ): AsyncResult<Array<Application>, InvalidArgument | OperationFailed> {
        const result: Array<Immutable<Application>> = [];

        for (const app of applicationList) {
            const applicationResult = await APIApplicationRepository.toApplication(app);
            if (isErr(applicationResult)) {
                return handleOperationFailure(applicationResult);
            }
            result.push(applicationResult.value);
        }

        return Success(result);
    }

    private extractInitialPremiumFromApplicationApiResponse(
        application: Immutable<InsuranceApplication>,
    ): Nullable<Money> {
        const quotingEngine = application.quoting_engine;
        if (quotingEngine == QuotingEngineManual) {
            return null;
        }
        if (application.quote_list.length !== 1) {
            return null;
        }
        return QuoteList.getLastPremium(application.quote_list) ?? null;
    }

    private async getRawApiAndParsedApplicationData(id: UUID): AsyncResult<
        {
            application: Application;
            apiApplicationResponse: InsuranceApplication;
        },
        InvalidArgument | OperationFailed
    > {
        const applicationResponse = await API.request('shopping/application', { id });
        if (isErr(applicationResponse)) {
            return handleOperationFailure(applicationResponse);
        }
        const { value: apiApplication } = applicationResponse;

        const toApplicationResult = await APIApplicationRepository.toApplication(apiApplication);

        if (isErr(toApplicationResult)) {
            return Failure(
                OperationFailed({
                    message: 'Failed to convert applications',
                    errors: toApplicationResult.errors,
                }),
            );
        }
        const { value: application } = toApplicationResult;

        return Success({
            apiApplicationResponse: apiApplication,
            application: application,
        });
    }

    private static async toApplication(app: Immutable<InsuranceApplication>) {
        const hasQuotes = app.quote_list.length > 0;
        const lastApiQuote = QuoteList.getLastQuote(app.quote_list);
        const hasSocius: boolean = APIApplicationRepository.hasSocius(app.quote_list);
        const quoteEffectiveDate = getQuoteEffectiveDate(lastApiQuote);
        const daysToExpiration = quoteEffectiveDate
            ? QuoteExpiration.getDaysLeftUntilExpiration({
                  quotingEngine: app.quoting_engine || undefined,
                  applicationStatus: app.status,
                  validUntil: app.valid_until,
                  quoteEffectiveDate: quoteEffectiveDate,
                  today: startOfToday(),
                  isBroker: app.brokerage_id !== null,
              })
            : undefined;

        return Application.create({
            id: app.id,
            startedDate: app.started_at ? new Date(app.started_at) : null,
            submittedDate: app.submitted_at ? new Date(app.submitted_at) : null,
            status: app.status as InsuranceApplicationStatusCode,
            creationType: app.creation_type as InsuranceApplicationCreationTypeCode,
            isPristine: app.is_questionnaire_pristine,
            previousApplicationsQuotingEngineList:
                app.previous_app_quoting_engine_list as Array<QuotingEngine>,
            quotingEngine: app.quoting_engine as Nullable<QuotingEngine>,
            appType: app.app_type as AppTypeCode,
            shoppingCoverageList: (app.shopping_coverage_list ?? []) as Array<ShoppingCoverage>,
            isStreamline: app.is_streamline,
            quotableShoppingCoverageList: (app?.quotable_shopping_coverage_list ?? undefined) as
                | Array<ShoppingCoverage>
                | undefined,
            hasQuotes: hasQuotes,
            hasSocius: hasSocius,
            questionnaireData: app.questionnaire_data,
            supplementalQuestionnaireData: app.supplemental_questionnaire_data,
            renewedPolicyIdList: app.renewed_policy_id_list as UUID[],
            intakeTaskId: app.intake_task_id,
            submissionTaskId: app.submission_task_id,
            isOFACRejected: app.is_on_ofac_sanction_list,
            redirectToApplicationList: app.redirect_to_application_list as Nullable<UUID[]>,
            isCNAQuoteReferred: isCnaQuoteReferred(app),
            signaturePacketDocuments: toSignaturePacketDocuments(app.signature_packet_documents),
            ineligibilityReasons:
                app.ineligibility_reasons === null
                    ? null
                    : (toIneligibilityReasons(
                          app.ineligibility_reasons,
                      ) as Nullable<IneligibilityReasons>),
            isDeletable: [QuestionnaireInProgress, SubmissionInProgress, QuoteExpired].includes(
                app.status,
            ),
            policyExpirationDate: null,
            lastQuote: lastApiQuote ? toQuote(lastApiQuote) : null,
            daysToQuoteExpiration: daysToExpiration || null,
            isSubmittedExternally: app.is_submitted_externally,
            bundleDetails: toBundleDetails(app.bundle_details),
            hasClientReviewRequest: app.has_client_review_request,
        });
    }

    async createApplicationIntakeTask(
        request: CreateApplicationIntakeTaskRequest,
    ): AsyncResult<CreateApplicationIntakeTaskResponse, InvalidArgument | OperationFailed> {
        const applicationResponse = await API.request('shopping/create_application_intake', {
            app_type: request.appType,
            shopping_coverage_list: request.shoppingCoverageList,
            file_url: request.fileUrl,
            application_id: request.applicationId,
        });

        if (isErr(applicationResponse)) {
            return handleOperationFailure(applicationResponse);
        }

        if (!applicationResponse.value.task_id) {
            return Failure(
                OperationFailed({
                    message: 'Submission task id not provided.',
                }),
            );
        }
        return Success({
            taskId: applicationResponse.value.task_id,
            applicationId: applicationResponse.value.application_id,
        });
    }

    async getApplicationShareToken(
        id: UUID,
    ): AsyncResult<string, InvalidArgument | OperationFailed> {
        const response = await API.request('shopping/get_shareable_application_token', {
            app_id: id,
        });

        if (isErr(response)) {
            return handleOperationFailure(response);
        }

        return Success(response.value.token);
    }

    async getClientApplication(
        token: string,
    ): AsyncResult<
        GetClientApplicationResponse,
        ApplicationNotFound | OperationNotAllowed | OperationFailed
    > {
        const clientApplicationResponse = await API.request('shopping/get_client_application', {
            token,
        });
        if (isErr(clientApplicationResponse)) {
            const error = clientApplicationResponse.errors[0];
            if (isAPIError(error)) {
                if (error.details.name === 'not_found') {
                    return Failure(ApplicationNotFound());
                } else if (error.details.name === 'not_allowed') {
                    return Failure(OperationNotAllowed());
                }
            }

            return Failure(
                OperationFailed({
                    message: 'Failed to fetch a client application for the given token.',
                    errors: clientApplicationResponse.errors,
                }),
            );
        }

        const { value: clientApplicationEntities } = clientApplicationResponse;

        const currentApplicationResult = await APIApplicationRepository.toApplication(
            clientApplicationEntities.current_application,
        );
        if (isErr(currentApplicationResult)) {
            return Failure(
                OperationFailed({
                    message: 'Failed to convert a client application.',
                    errors: currentApplicationResult.errors,
                }),
            );
        }
        const { value: currentApplication } = currentApplicationResult;

        let previousApplication: Immutable<Application> | undefined = undefined;
        if (clientApplicationEntities.previous_application) {
            const previousApplicationResult = await APIApplicationRepository.toApplication(
                clientApplicationEntities.previous_application,
            );
            if (isErr(previousApplicationResult)) {
                return Failure(
                    OperationFailed({
                        message: 'Failed to convert a client application.',
                        errors: previousApplicationResult.errors,
                    }),
                );
            }
            previousApplication = previousApplicationResult.value;
        }

        return Success({ currentApplication, previousApplication });
    }

    async updateClientApplication(
        token: string,
        questionnaireData: string,
    ): AsyncResult<void, ApplicationNotFound | OperationNotAllowed | OperationFailed> {
        const result = await API.request('shopping/update_client_application', {
            token,
            questionnaire_data: questionnaireData,
        });
        if (isErr(result)) {
            const error = result.errors[0];
            if (isAPIError(error)) {
                if (error.details.name === 'not_found') {
                    return Failure(ApplicationNotFound());
                } else if (error.details.name === 'not_allowed') {
                    return Failure(OperationNotAllowed());
                }
            }

            return Failure(
                OperationFailed({
                    message: 'Failed to update the application.',
                    errors: result.errors,
                }),
            );
        }

        return Success();
    }

    async editClientApplication(
        token: string,
        questionnaireData: string,
    ): AsyncResult<void, ApplicationNotFound | OperationNotAllowed | OperationFailed> {
        const result = await API.request('shopping/edit_client_application', {
            token,
            questionnaire_data: questionnaireData,
        });
        if (isErr(result)) {
            const error = result.errors[0];
            if (isAPIError(error)) {
                if (error.details.name === 'not_found') {
                    return Failure(ApplicationNotFound());
                } else if (error.details.name === 'not_allowed') {
                    return Failure(OperationNotAllowed());
                }
            }

            return Failure(
                OperationFailed({
                    message: 'Failed to edit the application.',
                    errors: result.errors,
                }),
            );
        }

        return Success();
    }

    async getClientQuestionnairePrefill(
        token: string,
    ): AsyncResult<Data, ApplicationNotFound | InvalidArgument | OperationFailed> {
        const response = await API.request('shopping/get_client_questionnaire_prefill', { token });
        if (isErr(response)) {
            const error = response.errors[0];
            if (isAPIError(error)) {
                if (error.details.name === 'not_found') {
                    return Failure(ApplicationNotFound());
                }
            }

            return handleOperationFailure(response);
        }

        const prefillResponse = response.value;

        let questionnaire = {};
        try {
            questionnaire = JSON.parse(prefillResponse.questionnaire || '{}');
        } catch (e) {
            return Failure(
                OperationFailed({ message: 'Failed to parse application questionnaire prefill.' }),
            );
        }

        return Success(questionnaire);
    }

    async completeShareableApplication(
        token: string,
    ): AsyncResult<void, ApplicationNotFound | OperationNotAllowed | OperationFailed> {
        const result = await API.request('shopping/complete_shareable_application', {
            token,
        });
        if (isErr(result)) {
            const error = result.errors[0];
            if (isAPIError(error)) {
                if (error.details.name === 'not_found') {
                    return Failure(ApplicationNotFound());
                } else if (error.details.name === 'not_allowed') {
                    return Failure(OperationNotAllowed());
                }
            }

            return Failure(
                OperationFailed({
                    message: 'Failed to complete the shareable application.',
                    errors: result.errors,
                }),
            );
        }

        return Success();
    }

    async promoteShareableApplication(
        token: string,
        questionnaireData: string,
    ): AsyncResult<
        void,
        OperationFailed | ApplicationNotFound | OperationNotAllowed | NameClearanceError
    > {
        const userTzOffsetMinutes = new Date(Date.now()).getTimezoneOffset();
        const applicationResponse = await API.request('shopping/promote_application', {
            token,
            questionnaire_data: questionnaireData,
            user_tz_offset_minutes: -userTzOffsetMinutes,
        });
        if (isErr(applicationResponse)) {
            const error = applicationResponse.errors[0];
            if (isAPIError(error) && error.details.name === 'application_not_found') {
                return Failure(ApplicationNotFound());
            } else if (isAPIError(error) && error.details.name === 'operation_not_allowed') {
                return Failure(OperationNotAllowed());
            } else if (isAPIError(error) && error.details.name === 'name_clearance_failed') {
                return Failure(NameClearanceError());
            }
            return Failure(
                OperationFailed({
                    message: 'Promotion failed',
                    errors: applicationResponse.errors,
                }),
            );
        }
        return Success();
    }
    async promoteApplication(
        id: UUID,
        questionnaireData: string,
    ): AsyncResult<
        void,
        | OperationFailed
        | ApplicationNotFound
        | OperationNotAllowed
        | NameClearanceError
        | AppTypeNotAllowedForBroker
    > {
        const userTzOffsetMinutes = new Date(Date.now()).getTimezoneOffset();
        const applicationResponse = await API.request('shopping/promote_application', {
            application_id: id,
            questionnaire_data: questionnaireData,
            user_tz_offset_minutes: -userTzOffsetMinutes,
        });
        if (isErr(applicationResponse)) {
            const error = applicationResponse.errors[0];
            if (isAPIError(error) && error.details.name === 'application_not_found') {
                return Failure(ApplicationNotFound());
            } else if (isAPIError(error) && error.details.name === 'operation_not_allowed') {
                return Failure(OperationNotAllowed());
            } else if (isAPIError(error) && error.details.name === 'name_clearance_failed') {
                return Failure(NameClearanceError());
            } else if (
                isAPIError(error) &&
                (error.details.name === 'app_type_mismatch' ||
                    error.details.name === 'coverage_mismatch')
            ) {
                return Failure(AppTypeNotAllowedForBroker());
            }
            return Failure(
                OperationFailed({
                    message: 'Promotion failed',
                    errors: applicationResponse.errors,
                }),
            );
        }
        return Success();
    }

    private static hasSocius(quoteList: Immutable<APIType.Quote[]>): boolean {
        if (quoteList.length > 0) {
            const lastAPIQuote = quoteList[quoteList.length - 1];
            return (
                (!!lastAPIQuote.details.esp?.socius_endorsement_premium &&
                    lastAPIQuote.details.esp?.socius_endorsement_premium.amount > 0) ||
                (!!lastAPIQuote.details.pcoml?.socius_endorsement_premium &&
                    lastAPIQuote.details.pcoml?.socius_endorsement_premium.amount > 0)
            );
        }

        return false;
    }

    async precreateApplication(
        request: PrecreateApplicationRequest,
    ): AsyncResult<
        PrecreateApplicationResponse,
        | PrecreateApplicationError
        | ShoppingCoverageNotSupportedForAppType
        | OrganizationNotFound
        | AppTypeNotSupported
        | ShoppingCoverageNotProvided
    > {
        const precreateApplicationResponse = await API.request('shopping/precreate_application', {
            app_type: request.appType,
            shopping_coverage_list: request.shoppingCoverageCodeList as string[],
            questionnaire_data: request.questionnaireData,
        });
        if (isErr(precreateApplicationResponse)) {
            const error = precreateApplicationResponse.errors[0];
            if (isAPIError(error)) {
                if (error.details.name === 'organization_not_found') {
                    return Failure(OrganizationNotFound());
                } else if (error.details.name === 'shopping_coverage_not_allowed_for_app_type') {
                    return Failure(
                        ShoppingCoverageNotSupportedForAppType(
                            (error.details.responseBody as any)?.data ||
                                'Unknown shopping coverage',
                        ),
                    );
                } else if (error.details.name === 'shopping_coverage_not_provided') {
                    return Failure(ShoppingCoverageNotProvided());
                } else if (error.details.name === 'app_type_not_supported') {
                    return Failure(AppTypeNotSupported());
                }
            }

            return Failure(
                PrecreateApplicationError(request.appType, precreateApplicationResponse.errors),
            );
        }
        const applicationId = precreateApplicationResponse.value.application_id;

        return Success({ applicationId });
    }

    async printBinder(appId: UUID): AsyncResult<URI, OperationFailed> {
        const printBinderResponse = await API.request('shopping/print_binder', {
            application_id: appId,
        });

        if (isErr(printBinderResponse)) {
            return Failure(
                OperationFailed({
                    message: 'Print Binder failed',
                    errors: printBinderResponse.errors,
                }),
            );
        }

        return Success(URI.build(printBinderResponse.value.document_url));
    }

    async optOutFromStreamlineRenewal(
        appId: UUID,
    ): AsyncResult<void, ApplicationNotFound | OperationFailed> {
        const optOutOfStreamlineRenewalResponse = await API.request(
            'shopping/disable_streamline_renewal',
            { application_id: appId },
        );
        if (isOK(optOutOfStreamlineRenewalResponse)) {
            return Success();
        }
        const error = optOutOfStreamlineRenewalResponse.errors[0];
        if (isAPIError(error) && error.details.name === 'application_not_found') {
            return Failure(ApplicationNotFound());
        }

        return Failure(OperationFailed({ errors: optOutOfStreamlineRenewalResponse.errors }));
    }

    async getPartialEligibility(
        applicationId: UUID,
    ): AsyncResult<AppTypeCodeList, OperationFailed> {
        const partialEligibilityResult = await API.request('shopping/partial_eligibility', {
            application_id: applicationId,
        });
        if (isErr(partialEligibilityResult)) {
            return Failure(
                OperationFailed({
                    message: 'Partial eligibility failed',
                    errors: partialEligibilityResult.errors,
                }),
            );
        }

        return Success(partialEligibilityResult.value.eligible_app_type_list as AppTypeCodeList);
    }

    async recreateRenewal(
        applicationId: UUID,
    ): AsyncResult<UUID, ApplicationNotFound | OperationFailed> {
        const recreateRenewalResult = await API.request('shopping/recreate_renewal_application', {
            application_id: applicationId,
        });
        if (isErr(recreateRenewalResult)) {
            const error = recreateRenewalResult.errors[0];
            if (isAPIError(error) && error.details.name === 'application_not_found') {
                return Failure(ApplicationNotFound());
            }
            return Failure(
                OperationFailed({
                    message: 'Recreate renewal failed',
                    errors: recreateRenewalResult.errors,
                }),
            );
        }

        return Success(recreateRenewalResult.value.application_id);
    }
}

export function toQuote(apiQuote: Immutable<APIType.Quote>): Quote {
    const lplEverestQuoteOptions = apiQuote.options.lpl_everest
        ? toLplQuoteOptions(apiQuote.options.lpl_everest)
        : undefined;

    const espQuoteOptions = apiQuote.options.esp
        ? toESPQuoteOptions(apiQuote.options.esp)
        : undefined;

    const pcomlQuoteOptions = apiQuote.options.pcoml
        ? toPCoMLQuoteOptions(apiQuote.options.pcoml)
        : undefined;

    const lawCyberQuoteOptions = apiQuote.options.law_cyber
        ? toLawCyberQuoteOptions(apiQuote.options.law_cyber)
        : undefined;

    const cowbellCyberQuoteOptions = apiQuote.options.cyber_cowbell
        ? toCowbellCyberQuoteOptions(apiQuote.options.cyber_cowbell)
        : undefined;

    const bopChubbQuoteOptions = apiQuote.options.bop_chubb
        ? toBOPChubbQuoteOptions(apiQuote.options.bop_chubb)
        : undefined;

    const wvChubbQuoteOptions = apiQuote.options.wc_chubb
        ? toWCChubbQuoteOptions(apiQuote.options.wc_chubb)
        : undefined;

    return {
        accepted: apiQuote?.accepted_at !== null,
        totalPremium: apiQuote?.total_premium,
        options: {
            lplEverest: lplEverestQuoteOptions,
            esp: espQuoteOptions,
            pcoml: pcomlQuoteOptions,
            lawCyber: lawCyberQuoteOptions,
            cowbellCyber: cowbellCyberQuoteOptions,
            bopChubb: bopChubbQuoteOptions,
            wcChubb: wvChubbQuoteOptions,
        },
    };
}

function toIneligibilityReasons(
    apiReasons: Immutable<{
        referral_reasons: string[];
        declined_reasons: string[];
        investigation_needed_reasons: string[];
    }>,
): Immutable<IneligibilityReasons> {
    return {
        declinedReasons: apiReasons.declined_reasons,
        referralReasons: apiReasons.referral_reasons,
        investigationNeededReasons: apiReasons.investigation_needed_reasons,
    };
}

function toBundleDetails(bundleDetails?: Immutable<APIType.BundleDetails>) {
    if (!bundleDetails) {
        return bundleDetails;
    }
    return {
        appDetails: toBundleAppDetails(bundleDetails.bundle_app_details),
        creationType: bundleDetails.creation_type as BundleCreationTypeItem,
    };
}

function toBundleAppDetails(details: Immutable<APIType.BundleAppDetails[]>) {
    return details.map((item) => {
        return {
            appId: item.id,
            appType: item.app_type as AppTypeCode,
            creationType: item.creation_type as InsuranceApplicationCreationTypeCode,
            appStatus: item.app_status as InsuranceApplicationStatusCode,
        };
    });
}

export function toSignaturePacketDocuments(
    signaturePacketDocuments: Immutable<InsuranceApplicationSignaturePacket[]>,
): Array<SignaturePacketDocument> {
    return signaturePacketDocuments.map((signaturePacketDocument) => ({
        id: signaturePacketDocument.id,
        fileName: signaturePacketDocument.file_name,
        storageLocation: signaturePacketDocument.storage_location,
        isDigitallySigned: signaturePacketDocument.is_digitally_signed,
    }));
}

function isCnaQuoteReferred(app: Immutable<InsuranceApplication>): Nullable<boolean> {
    const lastQuote = QuoteList.getLastQuote(app.quote_list);
    return lastQuote?.details.cna_bop?.referred ?? null;
}

function getQuoteEffectiveDate(quote: Nullable<Immutable<APIType.Quote>>): Date | undefined {
    return (
        quote?.options?.cyber?.effective_date ||
        quote?.options?.esp?.effective_period_start ||
        quote?.options?.crime?.effective_date ||
        quote?.options?.pcoml?.effective_date ||
        quote?.options?.lpl_everest?.effective_date ||
        quote?.options?.cna_bop?.effective_date ||
        quote?.options?.wcga?.start_date
    );
}
