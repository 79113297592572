import { inject, injectable } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { Money } from '@embroker/shotwell/core/types/Money';
import { PhoneNumber } from '@embroker/shotwell/core/types/PhoneNumber';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { Revenue } from '@embroker/shotwell/core/types/Revenue';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Year } from '@embroker/shotwell/core/types/Year';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { Location as LocationType } from '../../locations/entities/Location';
import { Organization } from '../entities/Organization';
import { OrganizationRepository } from '../repositories/OrganizationRepository';
import { MailingAddress as MailingAddressType } from '../types/MailingAddress';
/**
 * Request data for UpdateOrganizationProfile use case
 * @param organization is the organization entity with updated properties
 */
export interface UpdateOrganizationProfileRequest {
    id: UUID;
    naics?: Nullable<string>;
    companyLegalName?: string;
    website?: string;
    entityType?: Nullable<UUID>;
    totalNumberOfEmployees?: Nullable<number>;
    totalAnnualPayroll?: Nullable<Money>;
    yearStarted?: Nullable<Year>;
    headquarters?: MailingAddressType;
    otherLocations?: Immutable<EntityProps<LocationType>>[];
    revenues?: Revenue[];
    howDoesYourCompanyGenerateRevenue?: Nullable<string>;
    raisedFunding?: Nullable<boolean>;
    techAreaOfFocus?: Nullable<string>;
    email?: Nullable<EmailAddress>;
    phoneNumber?: PhoneNumber;
}

/**
 * UpdateOrganizationProfile use case is used to save organization entity with updated properties
 */

export interface UpdateOrganizationProfile extends UseCase {
    execute(
        request: UpdateOrganizationProfileRequest,
    ): AsyncResult<void, UnknownEntity | InvalidArgument | OperationFailed>;
}

@injectable()
export class UpdateOrganizationProfileUseCase extends UseCase implements UpdateOrganizationProfile {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/UpdateOrganizationProfile');
    /**
     * Constructor for UpdateOrganizationProfile use case class instance
     * @param eventBus An event bus this Use Case will publish events to.
     * @param organizationRepo is organization repository used to save organization entity with updated properties
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(OrganizationRepository) private organizationRepo: OrganizationRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes UpdateOrganizationProfile use case
     * Input is of type UpdateOrganizationProfileRequest
     * @returns Nothing if execution was successful
     * @returns UnknownEntity error if organization does not exist on the platform
     * @returns InvalidArgument error if provided organization entity has an invalid property
     */
    public async execute(
        organization: UpdateOrganizationProfileRequest,
    ): AsyncResult<void, UnknownEntity | InvalidArgument | OperationFailed> {
        const organizationResult = await this.organizationRepo.getOrganization(organization.id);

        if (isErr(organizationResult)) {
            return organizationResult;
        }

        organizationResult.value.update(organization);

        const result = await this.organizationRepo.save(organizationResult.value as Organization);

        if (isErr(result)) {
            return result;
        }

        this.eventBus.publishEntityEvents(result.value);
        return Success();
    }
}

export const UpdateOrganizationProfile: UseCaseClass<UpdateOrganizationProfile> =
    UpdateOrganizationProfileUseCase;
