import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { keysOf } from '@embroker/shotwell/core/object';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import {
    ActionHandler,
    CreateFormParams,
    FormAction,
    OpaqueForm,
} from '@embroker/shotwell/view/hooks/useForm';
import { GetApplicationDocumentFileKey } from '../../../documents/useCases/GetApplicationDocumentFileKey';
import { GetDocumentUrl } from '../../../documents/useCases/GetDocumentUrl';
import { QuotingEngineCyberCowbell } from '../../../shopping/types/enums';
import { WizardForm } from '../../../view/hooks/useWizardForm';
import { BundleQuote } from '../../entities/BundleQuote';
import { BundleCoverageType, BundleCoverageTypeEnum } from '../../types/BundleQuoteCoverage';
import { DocumentType } from '../../types/BundleQuoteDocument';
import { BuildBundleDocumentListProps } from '../../view/components/buildBundleDocumentList';
import { DocumentsItem } from '../../view/components/BundleQuoteLandingPage';
import { DownloadDocumentButton } from '../../view/components/DownloadDocumentButton';
import { GenerateDocumentButton } from '../../view/components/GenerateDocumentButton';
import { getDocumentUrl, getStaticDocumentUrl } from '../bundleMappingFunctions';
import {
    CoverageDefinition,
    CowbellCyber,
    CyberDocumentTitle,
    CyberProductName,
    CyberProductTitle,
    distributedPrefix,
    GetDocumentDownloadMetadataResponse,
    ProductFormData,
} from '../coverageDefinition';
import { buildCowbellCyberCoverage } from './buildCowbellCyberCoverage';
import { CowbellCyberBundleCoverageOptions } from './components/CowbellCyberBundleCoverageOptions';
import { CowbellCyberFooter } from './components/CowbellCyberBundleFooter';
import { CowbellCyberQuoteSummary } from './components/CowbellCyberBundleSummary';
import { CowbellCyberModalCoverageSummary } from './components/CowbellCyberModalCoverageSummary';
import { SignInsuranceCowbellAttestationSignatureWrapper } from './components/SignInsuranceCowbellAttestationSignatureWrapper';
import { SignInsuranceCowbellNoKnownLossesSignatureWrapper } from './components/SignInsuranceCowbellNoKnownLossesSignatureWrapper';
import { CowbellCyberQuote } from './entities/CowbellCyberQuote';
import {
    formDataToCowbellCyberQuoteOptions,
    isCowbellCyberFormData,
    isCowbellCyberQuoteOptions,
    toApiCowbellCyberQuoteOptions,
} from './mappingFunctions';
import { CowbellCyberQuoteOptions } from './types/CowbellCyberQuoteOptions';
import { getRecommendedQuotingOptionsCowbellCyber } from './recommendedQuotingOptions';

export const LawCyberCoverageDefinitionCowbell: CoverageDefinition<CowbellCyberQuote> = {
    productName: CyberProductName,
    productTitle: CyberProductTitle,
    documentTitle: CyberDocumentTitle,
    type: BundleCoverageTypeEnum.LawCyberBundleCoverageCowbell,
    quotingEngine: QuotingEngineCyberCowbell,
    buildCoverage: buildCowbellCyberCoverage,
    mapFormDataToQuoteOptions: formDataToCowbellCyberQuoteOptions,
    getRecomendedQuoteOptions: getRecommendedQuotingOptionsCowbellCyber,
    apiProductDesignation: CowbellCyber,
    mapQuoteOptionsToAPI: toApiCowbellCyberQuoteOptions,
    coverageOptionsComponent: CowbellCyberBundleCoverageOptions,
    summaryComponent: CowbellCyberQuoteSummary,
    modalSummaryComponent: CowbellCyberModalCoverageSummary,
    footerComponent: CowbellCyberFooter,
    isFieldsValidType(
        input: any,
    ): input is WizardForm<OpaqueForm<distributedPrefix<CowbellCyberQuoteOptions>>>['fields'] {
        return isCowbellCyberFormData(input);
    },
    isOptionsValidType(input: any): input is CowbellCyberQuoteOptions {
        return isCowbellCyberQuoteOptions(input);
    },
    getProductFields(
        bundleQuote: BundleQuote,
    ): CreateFormParams<ProductFormData<distributedPrefix<CowbellCyberQuoteOptions>>>['fields'] {
        return {
            cyberLimit: {
                type: 'select',
                validator: Joi.number(),
            },
            cyberRetention: {
                type: 'select',
                validator: Joi.number(),
            },
            cyberSelected: {
                type: 'checkbox',
                validator: Joi.boolean(),
            },
        };
    },
    getProductActions(
        bundleQuote: BundleQuote,
        abortSignal: AbortSignal,
    ): Record<
        FormAction,
        | {
              action: ActionHandler<CowbellCyberQuoteOptions>;
              fields: (keyof distributedPrefix<CowbellCyberQuoteOptions>)[];
          }
        | ActionHandler<ProductFormData<CowbellCyberQuoteOptions>>
    > {
        const formFields = this.getProductFields(bundleQuote);
        return {
            generateCowbellCyberQuoteDocument: {
                action: async () => {
                    return getDocumentUrl(
                        bundleQuote,
                        BundleCoverageTypeEnum.LawCyberBundleCoverageCowbell,
                        DocumentType.QuoteDocument,
                        abortSignal,
                    );
                },
                fields: ['effectiveDate', ...keysOf(formFields)],
            },
            generateCowbellCyberApplicationAttestation: {
                action: async () => {
                    return getDocumentUrl(
                        bundleQuote,
                        BundleCoverageTypeEnum.LawCyberBundleCoverageCowbell,
                        DocumentType.ApplicationAttestation,
                        abortSignal,
                    );
                },
                fields: ['effectiveDate', ...keysOf(formFields)],
            },
            downloadCowbellCyberGeneralTerms: {
                action: async () => {
                    return getStaticDocumentUrl(
                        DocumentType.GeneralTerms,
                        BundleCoverageTypeEnum.LawCyberBundleCoverageCowbell,
                        bundleQuote,
                    );
                },
                fields: ['effectiveDate', ...keysOf(formFields)],
            },
        };
    },
    getCoverageDocuments({
        bundleQuote,
        documents,
        trigger,
        isDirty,
        value,
    }: BuildBundleDocumentListProps): DocumentsItem[] | undefined {
        const shouldIncludeCowbellCyberDocs =
            !bundleQuote.isCoverageReferred(BundleCoverageTypeEnum.LawCyberBundleCoverageCowbell) &&
            bundleQuote.isCoverageEnabled(BundleCoverageTypeEnum.LawCyberBundleCoverageCowbell) &&
            value.cyberSelected;

        if (!shouldIncludeCowbellCyberDocs) {
            return undefined;
        }

        const CyberQuoteDocument: DocumentsItem = {
            component: GenerateDocumentButton,
            isDisabled: isDirty,
            fileUrl: documents.find(
                (document) =>
                    document.documentType === DocumentType.QuoteDocument &&
                    document.coverageType === BundleCoverageTypeEnum.LawCyberBundleCoverageCowbell,
            )?.url,
            documentType: DocumentType.QuoteDocument,
            handleTrigger: () => trigger('generateCowbellCyberQuoteDocument'),
            coverageType: BundleCoverageTypeEnum.LawCyberBundleCoverageCowbell,
        };
        const CyberGeneralTerms: DocumentsItem = {
            component: DownloadDocumentButton,
            isDisabled: isDirty,
            documentType: DocumentType.GeneralTerms,
            handleTrigger: () => trigger('downloadCowbellCyberGeneralTerms'),
            coverageType: BundleCoverageTypeEnum.LawCyberBundleCoverageCowbell,
        };
        const CyberApplicationAttestation: DocumentsItem = {
            component: GenerateDocumentButton,
            isDisabled: isDirty,
            fileUrl: documents.find(
                (document) =>
                    document.documentType === DocumentType.ApplicationAttestation &&
                    document.coverageType === BundleCoverageTypeEnum.LawCyberBundleCoverageCowbell,
            )?.url,
            documentType: DocumentType.ApplicationAttestation,
            handleTrigger: () => trigger('generateCowbellCyberApplicationAttestation'),
            coverageType: BundleCoverageTypeEnum.LawCyberBundleCoverageCowbell,
        };
        return [CyberQuoteDocument, CyberGeneralTerms, CyberApplicationAttestation];
    },
    getInitialValues(bundleQuote): Record<string, unknown> {
        const cowbellCyberCoverage = bundleQuote.coverageList.find(
            (coverage) => coverage.type === this.type,
        );

        // TODO: improve error handling
        if (
            cowbellCyberCoverage === undefined ||
            cowbellCyberCoverage.quote === undefined ||
            !this.isOptionsValidType(cowbellCyberCoverage.quote.options)
        ) {
            return {};
        }

        const cowbellCyberOptions = cowbellCyberCoverage.quote.options;
        return {
            cyberLimit: cowbellCyberOptions.limit,
            cyberRetention: cowbellCyberOptions.retention,
            cyberSelected: !cowbellCyberOptions.isDeselected,
        };
    },
    toggleSelected(value: boolean): Record<string, unknown> {
        return { cyberSelected: value };
    },
    getMaxFutureDaysAllowed(): number {
        return 60;
    },
    async getDocumentDownloadMetadata(
        documentType: DocumentType,
        cowbellQuote?: CowbellCyberQuote,
    ): AsyncResult<GetDocumentDownloadMetadataResponse, OperationFailed | InvalidArgument> {
        const GTC_CYBER_COWBELL_URL =
            'https://embroker.s3.us-west-2.amazonaws.com/Cowbell_Cyber_Standard_Specimen.pdf';
        let fileKeyResult;
        let downloadUrlResult;

        async function createDocumentUrl(fileKey: string) {
            downloadUrlResult = await execute(GetDocumentUrl, {
                fileKey: fileKey,
            });
            if (isErr(downloadUrlResult)) {
                return downloadUrlResult;
            }
            return Success({
                fileKey: fileKey,
                downloadUrl: downloadUrlResult.value.downloadUrl,
            });
        }

        switch (documentType) {
            case DocumentType.GeneralTerms:
                return Success({ fileKey: '', downloadUrl: GTC_CYBER_COWBELL_URL });
            case DocumentType.QuoteDocument:
                if (cowbellQuote?.fileKey === undefined) {
                    return Failure(InvalidArgument({ argument: 'fileKey', value: documentType }));
                }
                return await createDocumentUrl(cowbellQuote.fileKey);
            case DocumentType.ApplicationAttestation:
                if (cowbellQuote === undefined) {
                    return Failure(
                        InvalidArgument({ argument: 'cowbellQuote', value: documentType }),
                    );
                }
                fileKeyResult = await execute(GetApplicationDocumentFileKey, {
                    applicationId: cowbellQuote.applicationId,
                    fileName: 'Cyber Cowbell Application.pdf',
                });
                if (isErr(fileKeyResult)) {
                    return Failure(InvalidArgument({ argument: 'fileKey', value: documentType }));
                }
                return await createDocumentUrl(fileKeyResult.value);
            default:
                return Failure(InvalidArgument({ argument: 'fileKey', value: documentType }));
        }
    },
    onSuccessHandler(
        value: any,
        action: string,
        setDocumentUrl: (
            url: URI,
            documentType: DocumentType,
            bundleCoverageType: BundleCoverageType,
        ) => void,
        onGenerateDocument: () => void,
    ) {
        switch (action) {
            case 'downloadCowbellCyberGeneralTerms':
                window.open(value.fileUrl as string, '_blank');
                break;
            case 'generateCowbellCyberQuoteDocument':
                setDocumentUrl(
                    value.fileUrl,
                    DocumentType.QuoteDocument,
                    BundleCoverageTypeEnum.LawCyberBundleCoverageCowbell,
                );
                onGenerateDocument();
                break;
            case 'generateCowbellCyberApplicationAttestation':
                setDocumentUrl(
                    value.fileUrl,
                    DocumentType.ApplicationAttestation,
                    BundleCoverageTypeEnum.LawCyberBundleCoverageCowbell,
                );
                onGenerateDocument();
                break;
        }
    },
    requiresApplicationAttestation: true,
    surplusLinesDiscDisabled: true,
    isLimitHigherThenAllowed(
        formValue: distributedPrefix<CowbellCyberQuoteOptions>,
        coverageSelected: boolean,
    ): boolean {
        return false;
    },
    attestationComponentList: [
        SignInsuranceCowbellAttestationSignatureWrapper,
        SignInsuranceCowbellNoKnownLossesSignatureWrapper,
    ],
    getPremiumRange() {
        return undefined;
    },
};
