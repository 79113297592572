import React, { Fragment } from 'react';

import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import {
    Button,
    CardLayout,
    ColumnLayout,
    Icon,
    IconColor,
    IconName,
    StackLayout,
    Text,
    TextButton,
    TextProps,
    useResponsive,
} from '@embroker/ui-toolkit/v2';
import { BundleQuote } from '@app/bundle/entities/BundleQuote';
import { Money } from '@embroker/shotwell/core/types/Money';

type BackgroundColorProps = Parameters<typeof CardLayout.Header>['0']['backgroundColor'];

type Variants = 'starter' | 'enhaced' | 'deluxe';

type VariantStyles = {
    backgroundColor: BackgroundColorProps;
    title: string;
    subtitle: string;
    icon: IconName;
    titleColor?: TextProps['color'];
    subtitleColor?: TextProps['color'];
    priceColor?: TextProps['color'];
    iconColor?: IconColor;
};

const variantStyle: Record<Variants, VariantStyles> = {
    starter: {
        backgroundColor: 'ui-200',
        title: 'Starter',
        subtitle: 'For firms seeking foundational protection at an affordable price.',
        icon: 'policies',
        titleColor: 'brand-400',
        subtitleColor: 'ui-500',
        priceColor: 'brand-400',
    },
    enhaced: {
        backgroundColor: 'brand-400',
        title: 'Enhanced',
        subtitle: 'A higher level of protection and a top pick for firms with risks like yours.',
        icon: 'shield-check',
        iconColor: 'ui-300',
        titleColor: 'ui-200',
        subtitleColor: 'ui-200',
        priceColor: 'ui-200',
    },
    deluxe: {
        backgroundColor: 'primary-100',
        title: 'Deluxe',
        subtitle: 'Top-tier coverage for firms looking to maximize their risk protection.',
        icon: 'multicoverage',
        titleColor: 'brand-400',
        subtitleColor: 'ui-500',
        priceColor: 'brand-400',
    },
};

interface BundleQuotePackageCardProps {
    variant: Variants;
    pricePerYear: ReturnType<BundleQuote['getQuoteTotalValue']>;
    onPrimaryClick: () => void;
    onSecondaryClick: () => void;
    children: React.ReactNode;
}

export const BundleQuotePackageCard = ({
    variant,
    pricePerYear,
    onPrimaryClick,
    onSecondaryClick,
    children,
}: BundleQuotePackageCardProps) => {
    const isMobile = useResponsive({ screenWidth: { smallerThan: 'desktop' } });
    const className = isMobile ? 'u-1/1' : 'u-1/3';
    const hasRangedPricePerYear = pricePerYear && 'min' in pricePerYear;
    return (
        <CardLayout
            radius="16"
            borderStyle="none"
            statusLabel={variant === 'enhaced' ? 'Recommended for your business' : undefined}
            statusLabelPosition="center"
            statusLabelColor="accent-500"
            className={className}
        >
            <StackLayout gap="none" split="1 -1">
                <CardLayout.Header backgroundColor={variantStyle[variant].backgroundColor}>
                    <StackLayout>
                        <ColumnLayout center gap="8">
                            <Icon
                                name={variantStyle[variant].icon}
                                size="medium"
                                color={variantStyle[variant].iconColor}
                            />
                            <Text style="heading 4" color={variantStyle[variant].titleColor}>
                                {variantStyle[variant].title}
                            </Text>
                        </ColumnLayout>
                        <Text style="body 2" color={variantStyle[variant].subtitleColor}>
                            {variantStyle[variant].subtitle}
                        </Text>
                        <Text style="heading 4" color={variantStyle[variant].priceColor}>
                            {hasRangedPricePerYear ? (
                                <Fragment>
                                    <MoneyDisplay value={pricePerYear.min} fractionDigits={0} />
                                    {` - ${Money.toString(pricePerYear.max, {
                                        fractionDigits: 0,
                                        roundingMode: 'HALF_AWAY_FROM_ZERO',
                                    }).replace('$', '')} est. per year**`}
                                </Fragment>
                            ) : (
                                pricePerYear && (
                                    <Fragment>
                                        <MoneyDisplay value={pricePerYear} fractionDigits={0} />{' '}
                                        {` per year*`}
                                    </Fragment>
                                )
                            )}
                        </Text>
                    </StackLayout>
                </CardLayout.Header>
                <CardLayout.Body>{children}</CardLayout.Body>
                <CardLayout.Footer>
                    <StackLayout center>
                        <Button
                            appearance={variant === 'enhaced' ? 'primary' : 'secondary'}
                            onClick={onPrimaryClick}
                        >
                            Continue
                        </Button>
                        <TextButton onClick={onSecondaryClick}>View and Customize</TextButton>
                    </StackLayout>
                </CardLayout.Footer>
            </StackLayout>
        </CardLayout>
    );
};
