import {
    BoxLayout,
    Button,
    ColumnLayout,
    Header,
    Image,
    PageLayout,
    StackLayout,
    Text,
    usePageLayout,
} from '@embroker/ui-toolkit/v2';
import React, { useCallback, useContext } from 'react';
import { hasRole } from '../../../../userOrg/entities/Session';
import { AppContext } from '../../../../view/AppContext';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { NavigationalLogo } from '../../../../view/components/NavigationalLogo';

export function ExcessThankYou() {
    const { navigate } = useNavigation();
    const { activeSession } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');

    const handleBackToDashboard = useCallback(() => {
        if (isBroker) {
            return navigate('/broker/dashboard');
        }
        return navigate('/summary');
    }, [navigate, isBroker]);

    const state = usePageLayout({
        header: (
            <Header>
                <NavigationalLogo />
            </Header>
        ),
    });

    return (
        <PageLayout {...state}>
            <PageLayout.Section>
                <ColumnLayout responsive={{ containerWidth: { smallerThan: 'tablet' } }}>
                    <BoxLayout className="u-1/2@large-tablet u-1/1">
                        <StackLayout gap="32">
                            <Text style="heading 1" color="brand-400" data-e2e="thank-you">
                                Thank you!
                            </Text>
                            <StackLayout>
                                <Text style="body 1" data-e2e="confirmation-text">
                                    We've received your Application. One of our underwriters will
                                    review it and will be in touch with you soon via email.
                                </Text>
                            </StackLayout>
                            <Button
                                onClick={handleBackToDashboard}
                                appearance="primary"
                                type="submit"
                                data-e2e="go-to-dashboard"
                            >
                                Go to Dashboard
                            </Button>
                        </StackLayout>
                    </BoxLayout>

                    <BoxLayout className="u-1/2@large-tablet u-1/1">
                        <Image width="100%" name="illustration-thank-you" />
                    </BoxLayout>
                </ColumnLayout>
            </PageLayout.Section>
        </PageLayout>
    );
}
