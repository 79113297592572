import React from 'react';
import {
    BoxLayout,
    Button,
    ButtonBar,
    CardLayout,
    ColumnLayout,
    StackLayout,
    Text,
    StatusMessage,
} from '@embroker/ui-toolkit/v2';
import { UnderlyingApp } from '../../../useCases/GetLawBundleApplications';

interface IndicativePremiumSideContentProps {
    radioItems: readonly UnderlyingApp[];
    handleGetStarted: () => void;
}

export const SideContent = ({
    radioItems,
    handleGetStarted,
}: IndicativePremiumSideContentProps) => {
    const hasSelectedCoverages = Boolean(
        radioItems.find((underlyingApp: UnderlyingApp) => underlyingApp.isSelected),
    );
    return (
        <CardLayout
            className="u-grid-column-start-1 u-grid-column-end-4"
            borderStyle="none"
            boxShadow={false}
            radius="24"
        >
            <BoxLayout gap="16">
                <StackLayout gap="32">
                    <StackLayout gap="16">
                        <ColumnLayout gap="8" center>
                            <Text color="brand-400" style="heading 4" as="span">
                                Your Custom Law Program
                            </Text>
                        </ColumnLayout>
                        {!hasSelectedCoverages ? (
                            <StatusMessage status="warning">
                                Please select at least one coverage to continue.
                            </StatusMessage>
                        ) : (
                            <StackLayout gap="12">
                                {radioItems
                                    .filter((item) => item.isSelected)
                                    .map((item) => {
                                        return (
                                            <ColumnLayout center key={item.value}>
                                                <Text
                                                    className="u-8/8"
                                                    color="brand-400"
                                                    style="body 1"
                                                >
                                                    {item.title}
                                                </Text>
                                            </ColumnLayout>
                                        );
                                    })}
                            </StackLayout>
                        )}
                    </StackLayout>
                    <ButtonBar
                        responsive={{
                            screenHeight: { greaterThan: 'mobile' },
                        }}
                        gap="16"
                    >
                        <Button
                            onClick={handleGetStarted}
                            disabled={!hasSelectedCoverages}
                            type="submit"
                        >
                            Start the Application
                        </Button>
                    </ButtonBar>
                </StackLayout>
                <BoxLayout gap="12" />
            </BoxLayout>
        </CardLayout>
    );
};
