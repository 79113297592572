import React from 'react';
import { Nullable, RadioGroup } from '@embroker/ui-toolkit/v2';
import { RefinementQuestionType } from '../../../useCases/GetUserOnboardingEntrypoint';

export type NAICsOptionValueType = Nullable<string>;

interface RefinementQuestionSet {
    title: string;
    question?: string;
    refinementQuestionOptions: { title: string; value: NAICsOptionValueType }[];
}

export const ACCOUNTING_QUESTION_SET: RefinementQuestionSet = {
    title: 'Which of the below best describes your day-to-day tasks?',
    refinementQuestionOptions: [
        {
            value: '541211',
            title: 'Accounting services, including auditing, payroll and budget analysis',
        },
        {
            value: '541213',
            title: 'Tax preparation or bookkeeping services only',
        },
    ],
};

export const NON_TECH_CONSULTANT_QUESTION_SET: RefinementQuestionSet = {
    title: 'Tell us more about the services you provide.',
    question:
        'Does your business primarily provide consulting services related to computers, electronics, software, and networking and information technology?',
    refinementQuestionOptions: [
        {
            value: '541512',
            title: 'Yes',
        },
        {
            value: null,
            title: 'No',
        },
    ],
};

export const REFINEMENT_QUESTION_SETS: { [key in RefinementQuestionType]: RefinementQuestionSet } =
    {
        accounting: ACCOUNTING_QUESTION_SET,
        nonTechConsultant: NON_TECH_CONSULTANT_QUESTION_SET,
    };

export function NaicsCodeRefinementField({
    refinementQuestionType,
    refinedNaicsCode,
    onChange,
}: {
    refinementQuestionType: RefinementQuestionType;
    refinedNaicsCode?: NAICsOptionValueType;
    onChange: (niacsCode: NAICsOptionValueType) => void;
}) {
    const { refinementQuestionOptions } = REFINEMENT_QUESTION_SETS[refinementQuestionType];
    return (
        <RadioGroup
            onChange={(e) => onChange(e.target.value)}
            items={refinementQuestionOptions}
            value={refinedNaicsCode}
        />
    );
}
