import { container } from '@embroker/shotwell/core/di';
import { GrowthBookExperimentationService } from '../../experimentation/services/GrowthBookExperimentationService';
import { isVariantExperimentTest } from '../../experimentation/types/ExperimentationTest';

export const isDataDrivenFormApplicationQuestionnaire = () => {
    const experimentationService = container.get<GrowthBookExperimentationService>(
        GrowthBookExperimentationService,
    );
    const isDataDrivenQuestionnaire = experimentationService.getExperimentationTest(
        'data-driven-form-application-questionnaire',
    );
    return isVariantExperimentTest(isDataDrivenQuestionnaire);
};
