import { container } from '@embroker/shotwell/core/di';
import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { equal, keysOf } from '@embroker/shotwell/core/object';
import { Immutable } from '@embroker/shotwell/core/types';
import { ErrorLike, Failure, isErr, Result, Success } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { createForm } from '@embroker/shotwell/view/hooks/useForm';
import { isBefore, isSameDay, startOfDay, startOfToday } from 'date-fns';
import { GenerateAppFileUrl } from '../../../documents/useCases/GenerateAppFileUrl';
import { isDateFeb29 } from '../../../quote/lpl/view/components/createLPLQuoteForm';
import { Signature } from '../../../quote/types/Signature';
import { validateEffectiveDate } from '../../../quote/view/components/formValidators';
import { navigateToErrorPage } from '../../../view/errors';
import { NavigateFunction } from '../../../view/hooks/useNavigation';
import { CoverageCatalog } from '../../CoverageCatalog';
import { BundleQuote } from '../../entities/BundleQuote';
import { BundleCoverageType, BundleWarningStatus } from '../../types/BundleQuoteCoverage';
import { DocumentType } from '../../types/BundleQuoteDocument';
import { BundleQuoteFormData } from '../../types/BundleQuoteFormData';
import { BundleQuoteOptions } from '../../types/BundleQuoteOptions';
import { FinalizeBundleQuote } from '../../useCases/FinalizeBundleQuote';
import { ReQuoteBundle } from '../../useCases/ReQuoteBundle';

export interface BundleQuoteFormProps {
    closeSlideout: () => void;
    bundleQuote: BundleQuote;
    abortSignal: AbortSignal;
    handleQuoteUpdate: (newBundleQuote: BundleQuote, warnings: BundleWarningStatus[]) => void;
    handleSetSignature: (signature: Signature) => void;
    navigate: NavigateFunction;
    setDocumentUrl: (
        url: URI,
        documentType: DocumentType,
        bundleCoverageType: BundleCoverageType,
    ) => void;
    onGenerateDocument: () => void;
}

const MAX_FUTURE_DAYS_ALLOWED = 90;

export function createBundleQuoteForm({
    closeSlideout,
    bundleQuote,
    abortSignal,
    handleQuoteUpdate,
    handleSetSignature,
    navigate,
    setDocumentUrl,
    onGenerateDocument,
}: BundleQuoteFormProps) {
    const applicationAttestationSignatureRequired =
        checkIsRequiredApplicationAttestation(bundleQuote);
    const noKnownLossesAttestationRequired = checkIsRequiredNoKnownLossesAttestation(
        bundleQuote,
        applicationAttestationSignatureRequired,
    );
    const formFields = CoverageCatalog.getSelectedProductFormFields(bundleQuote);
    const formActions = bundleQuote.coverageList.reduce((agg, curr) => {
        const coverageType = curr.type;
        const coverageActions = CoverageCatalog.getProductFormActions(
            coverageType,
            bundleQuote,
            abortSignal,
        );
        return {
            ...agg,
            ...coverageActions,
        };
    }, {});

    const updateAction = async (formData: BundleQuoteFormData & typeof formFields) => {
        const quoteOptionsMap = formDataToQuoteOptionsMap(formData, bundleQuote);

        if (isErr(quoteOptionsMap)) {
            return quoteOptionsMap;
        }
        return await execute(ReQuoteBundle, {
            applicationId: bundleQuote.applicationId,
            quoteOptionsMap: quoteOptionsMap.value,
            abortSignal: abortSignal,
        });
    };

    return createForm<BundleQuoteFormData & typeof formFields>({
        fields: {
            ...formFields,
            effectiveDate: {
                type: 'date',
                validator: Joi.date()
                    .custom((value, helpers) => {
                        if (isDateFeb29(value)) {
                            return helpers.error('date.feb29');
                        }
                        return validateEffectiveDate(
                            value,
                            startOfToday(),
                            MAX_FUTURE_DAYS_ALLOWED,
                            helpers,
                            false,
                        );
                    })
                    .required(),
                formatValidationError: (error) => {
                    switch (error.details.validator) {
                        case 'date.min':
                            return 'Effective date cannot be in the past.';
                        case 'date.max':
                            return `Effective date cannot be more than ${MAX_FUTURE_DAYS_ALLOWED} days in the future.`;
                        case 'date.feb29':
                            return 'Effective date can not be 29th of February.';
                        default:
                            return 'Invalid date format';
                    }
                },
            },
            agreementToConductSignature: {
                type: 'checkbox',
                validator: Joi.boolean().valid(true),
                formatValidationError: (error) => {
                    if (error.details.validator === 'any.only') {
                        return 'You must sign to proceed with purchase.';
                    }
                    return error.message;
                },
            },
            warrantyAndFraudSignature: {
                type: 'checkbox',
                validator: Joi.boolean().valid(true),
                formatValidationError: (error) => {
                    if (error.details.validator === 'any.only') {
                        return 'You must sign to proceed with purchase.';
                    }
                    return error.message;
                },
            },
            applicationAttestationSignature: {
                type: 'checkbox',
                validator: applicationAttestationSignatureRequired
                    ? Joi.boolean().valid(true)
                    : Joi.boolean().optional(),
                formatValidationError: (error) => {
                    if (error.details.validator === 'any.only') {
                        return 'You must sign to proceed with purchase.';
                    }
                    return error.message;
                },
            },
            applicationNoKnownLossesAttestation: {
                type: 'checkbox',
                validator: noKnownLossesAttestationRequired
                    ? Joi.boolean().valid(true)
                    : Joi.boolean().optional(),
                formatValidationError: (error) => {
                    if (error.details.validator === 'any.only') {
                        return 'You must sign to proceed with purchase.';
                    }
                    return error.message;
                },
            },
        },
        actions: {
            ...formActions,
            update: {
                action: updateAction,
                fields: ['effectiveDate', ...keysOf(formFields)],
            },
            updateSelected: {
                action: updateAction,
                fields: ['effectiveDate', ...keysOf(formFields)],
            },
            updateAndCloseSlideout: {
                action: updateAction,
                fields: ['effectiveDate', ...keysOf(formFields)],
            },
            sign: {
                action: async (formData: any) => {
                    handleSetSignature({
                        transfers: formData.agreementToConductSignature,
                        warranty: formData.warrantyAndFraudSignature,
                        applicationAttestation: formData.applicationAttestationSignature,
                        applicationNoKnownLossesAttestation:
                            formData.applicationNoKnownLossesAttestation,
                    });

                    return Success({ bundleQuote });
                },
                fields: ['effectiveDate', ...keysOf(formFields)],
            },
            downloadBundleApp: {
                action: async () => {
                    return await execute(GenerateAppFileUrl, {
                        applicationId: bundleQuote.applicationId,
                        abortSignal: abortSignal,
                    });
                },
                fields: ['effectiveDate', ...keysOf(formFields)],
            },
            requestReview: {
                action: async () => {
                    return await execute(FinalizeBundleQuote, {
                        bundleQuote,
                    });
                },
                fields: ['effectiveDate', ...keysOf(formFields)],
            },
            default: async () => {
                return await execute(FinalizeBundleQuote, {
                    bundleQuote,
                });
            },
        },
        onSuccess: (value, action) => {
            // call coverage specific onSuccessHandlers
            CoverageCatalog.coverages.map((coverage) => {
                coverage.onSuccessHandler
                    ? coverage.onSuccessHandler(value, action, setDocumentUrl, onGenerateDocument)
                    : null;
            });

            const updateOnSuccess = (value: any) => {
                handleQuoteUpdate(value.bundleQuote.value, value.warnings);
                handleSetSignature({
                    transfers: false,
                    warranty: false,
                    applicationAttestation: false,
                    applicationNoKnownLossesAttestation: false,
                });
            };

            // handle common actions
            switch (action) {
                case 'update':
                case 'updateSelected':
                    updateOnSuccess(value);
                    break;
                case 'updateAndCloseSlideout':
                    updateOnSuccess(value);
                    closeSlideout();
                    break;
                case 'downloadBundleApp':
                    window.open(value.documentUrl as string, '_blank');
                    break;
                case 'requestReview':
                    navigate(
                        URI.build('/shopping/bundle/thank-you', {
                            applicationId: bundleQuote.applicationId,
                        }),
                    );
                    break;
                case 'default':
                    navigate(
                        URI.build('/shopping/bundle/thank-you', {
                            applicationId: bundleQuote.applicationId,
                        }),
                    );
                    break;
                default:
                    break;
            }
        },
        onFailure: (errors: Immutable<ErrorLike[]>) => {
            container.get<Logger>(Log).error(errors);
            return navigateToErrorPage(navigate, errors);
        },
    });
}

const formDataToQuoteOptionsMap = (
    formData: any,
    bundleQuote: BundleQuote,
    selectedOnly?: boolean,
): Result<Map<BundleCoverageType, BundleQuoteOptions>, InvalidArgument> => {
    const quoteOptionsMap = new Map<BundleCoverageType, BundleQuoteOptions>();

    for (const coverage of bundleQuote.getEnabledCoverages()) {
        const coverageDefinition = CoverageCatalog.findCoverageDefinitionByType(coverage.type);
        if (!coverageDefinition) {
            return Failure(
                InvalidArgument({ argument: 'Coverage definition', value: coverage.type }),
            );
        }

        const currentQuoteOptions = bundleQuote.getCoverageQuoteOptions(coverage.type);
        if (!currentQuoteOptions) {
            continue;
        }
        const newQuoteOptions = coverageDefinition.mapFormDataToQuoteOptions(formData);
        if (!newQuoteOptions) {
            continue;
        }

        const selectedOnlyQuoteOptions = {
            ...currentQuoteOptions,
            isDeselected: newQuoteOptions.isDeselected,
        } as BundleQuoteOptions;

        const quoteOptions: BundleQuoteOptions = selectedOnly
            ? selectedOnlyQuoteOptions
            : newQuoteOptions;
        if (!equal(quoteOptions, currentQuoteOptions)) {
            quoteOptionsMap.set(coverage.type, quoteOptions);
        }
    }
    return Success(quoteOptionsMap);
};

export const checkIsRequiredNoKnownLossesAttestation = (
    bundleQuote: BundleQuote,
    isRequiredApplicationAttestation: boolean,
): boolean => {
    const bindingDate = startOfToday();
    const startOfDayEffectiveDate = startOfDay(bundleQuote.effectiveDate);
    const requiresNoKnownLossesAttestation =
        isSameDay(bindingDate, startOfDayEffectiveDate) ||
        isBefore(startOfDayEffectiveDate, bindingDate);
    return requiresNoKnownLossesAttestation && isRequiredApplicationAttestation;
};

export const checkIsRequiredApplicationAttestation = (bundleQuote: BundleQuote): boolean => {
    return bundleQuote.coverageList.reduce<boolean>((acc, current) => {
        const isCoverageRequireApplicationAttestation =
            CoverageCatalog.requiresApplicationAttestation(current.type);
        if (
            bundleQuote.isCoverageEnabled(current.type) &&
            bundleQuote.isCoverageSelected(current.type) &&
            isCoverageRequireApplicationAttestation
        ) {
            acc = isCoverageRequireApplicationAttestation;
        }
        return acc;
    }, false);
};

export const checkIsSurplusLinesDiscDisabled = (bundleQuote: BundleQuote): boolean => {
    return bundleQuote.coverageList.reduce<boolean>((acc, current) => {
        const isSurplusLinesDiscDisabled = CoverageCatalog.isSurplusLinesDiscDisabled(current.type);
        if (
            bundleQuote.isCoverageEnabled(current.type) &&
            bundleQuote.isCoverageSelected(current.type) &&
            isSurplusLinesDiscDisabled
        ) {
            acc = isSurplusLinesDiscDisabled;
        }
        return acc;
    }, false);
};
