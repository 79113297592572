import { StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { DocumentsItem } from './BundleQuoteLandingPage';
import { BundleQuoteCardLayout } from './BundleQuoteCardLayout.view';

export interface BundleQuoteDocumentsListProps {
    documentsList: DocumentsItem[];
}
export function BundleQuoteDocumentsList({ documentsList }: BundleQuoteDocumentsListProps) {
    return (
        <BundleQuoteCardLayout>
            <StackLayout gap="8">
                <Text style="heading 4" color="brand-400" data-e2e="documents">
                    Documents
                </Text>
                {documentsList.map(
                    ({
                        component: Component,
                        isDisabled,
                        fileUrl,
                        documentType,
                        handleTrigger,
                        coverageType,
                    }) => {
                        return (
                            <Component
                                key={documentType + coverageType}
                                isDisabled={isDisabled}
                                fileUrl={fileUrl}
                                isSubmitting={false}
                                documentType={documentType}
                                handleTrigger={handleTrigger}
                                coverageType={coverageType}
                            />
                        );
                    },
                )}
            </StackLayout>
        </BundleQuoteCardLayout>
    );
}
