import {
    LiabilityCoverageCodeListItem,
    LiabilityCoverageCodeListMap,
    LimitAppliesToCodeListItem,
    LimitAppliesToCodeListMap,
    LineOfBusinessCodeListItem,
    LineOfBusinessSubtypeCodeListItem,
    StructuralComponentTypeCodeListItem,
} from '@embroker/shotwell-api/enums';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import {
    Card,
    CheckBox,
    ColumnLayout,
    Immutable,
    StackLayout,
    Text,
    Tooltip,
} from '@embroker/ui-toolkit/v2';
import React, { ChangeEvent, useState } from 'react';
import { CoverageIcon } from '../CoverageIcon';
import { CertificateCoverageLimit } from '../../../types/CertificateCoverageLimit';

export interface CoverageIconDataProps {
    policyLineOfBusiness?: LineOfBusinessCodeListItem;
    policyLineOfBusinessSubtype?: LineOfBusinessSubtypeCodeListItem;
    structuralComponentTypeCode?: StructuralComponentTypeCodeListItem;
}

interface SelfServingCoveragePolicyCardProps {
    id: UUID;
    selectedIdList: UUID[];
    coverageName?: string;
    policyName?: string;
    carrierName?: string;
    policyNumber?: string;
    policyEffectiveDate?: Date;
    policyEndDate?: Date;
    limits?: Immutable<CertificateCoverageLimit[]>;
    coverageIconData: CoverageIconDataProps;
    onSelectedIdListChange(selectedId: UUID, addToList: boolean): void;
}

export function SelfServingCoveragePolicyCard({
    coverageName,
    policyName,
    carrierName,
    policyNumber,
    policyEffectiveDate,
    policyEndDate,
    limits,
    id,
    selectedIdList,
    coverageIconData,
    onSelectedIdListChange,
}: SelfServingCoveragePolicyCardProps) {
    const isInSelectedIdList = selectedIdList.find((item) => item == id);
    const [isSelected, setIsSelected] = useState<boolean>(!!isInSelectedIdList);

    const limitList = limits?.map((limit) => {
        if (
            (limit.amount == undefined && limit.description == undefined) ||
            limit.liabilityTypeCode == undefined ||
            limit.limitTypeCode == undefined
        ) {
            return null;
        }
        const limitName =
            LiabilityCoverageCodeListMap[limit.liabilityTypeCode as LiabilityCoverageCodeListItem];
        const limitAppliesTo =
            LimitAppliesToCodeListMap[limit.limitTypeCode as LimitAppliesToCodeListItem];
        return (
            <StackLayout key={UUID.create()}>
                <ColumnLayout>
                    <Text color="ui-50">
                        {limitName} / {limitAppliesTo}:
                        {limit.amount != undefined ? (
                            <MoneyDisplay value={limit.amount} />
                        ) : (
                            limit.description
                        )}
                    </Text>
                </ColumnLayout>
            </StackLayout>
        );
    });

    const onCardSelected = (event: ChangeEvent<HTMLInputElement>) => {
        const addToList = !isSelected;
        onSelectedIdListChange(id, addToList);
        setIsSelected(!isSelected);
    };

    return (
        <Card input={<CheckBox checked={isSelected} onChange={(event) => onCardSelected(event)} />}>
            <Card.Header>
                <CoverageIcon
                    lineOfBusiness={
                        coverageIconData.policyLineOfBusiness ?? 'LineOfBusinessCodeListOther'
                    }
                    subLineOfBusiness={coverageIconData.policyLineOfBusinessSubtype}
                    structuralComponentTypeCode={coverageIconData.structuralComponentTypeCode}
                />
                <Text style="heading 5">
                    {truncateCoverageTitle(coverageName ? coverageName : '')}
                </Text>
            </Card.Header>
            <Card.Body>
                <StackLayout gap="16">
                    <div />
                    <Text color="brand-500">{policyName}</Text>
                    <div>
                        <ColumnLayout gap="4">
                            <Text nowrap color="ui-400">
                                Carrier Name:
                            </Text>
                            <Text>{carrierName}</Text>
                        </ColumnLayout>
                        <ColumnLayout gap="4">
                            <Text color="ui-400">Number:</Text>
                            <Text>{policyNumber}</Text>
                        </ColumnLayout>
                    </div>

                    <div>
                        <ColumnLayout gap="4">
                            <Text color="ui-400">Term:</Text>
                            <Text>
                                {policyEffectiveDate !== undefined && (
                                    <DateDisplay value={policyEffectiveDate} />
                                )}{' '}
                                -
                                {policyEndDate !== undefined && (
                                    <DateDisplay value={policyEndDate} />
                                )}
                            </Text>
                        </ColumnLayout>
                        <Text color="ui-400">
                            Limits <Tooltip iconSize="small" text={limitList} />
                        </Text>
                    </div>
                </StackLayout>
            </Card.Body>
        </Card>
    );
}

function truncateCoverageTitle(coverageName: string): string {
    return coverageName && coverageName?.length > 35
        ? coverageName?.slice(0, 32) + '...'
        : coverageName;
}
