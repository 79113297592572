import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { UserOnboardingStepVisited } from '../entities/User';
import { UserOnboardingStepType } from '../types/UserOnboardingDetails';

export interface PublishOnboardingStepVisitedEvent extends UseCase {
    execute(request: PublishOnboardingStepVisitedEventRequest): AsyncResult<void>;
}

export interface PublishOnboardingStepVisitedEventRequest {
    step: NonNullable<UserOnboardingStepType>;
}

@injectable()
class PublishOnboardingStepVisitedEventUseCase
    extends UseCase
    implements PublishOnboardingStepVisitedEvent
{
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/PublishOnboardingStepVisitedEventUseCase');

    /**
     * Constructor for PublishOnboardingStepVisitedEvent class instance
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    /**
     * Executes the PublishOnboardingStepVisitedEvent use case.
     */
    public async execute({ step }: PublishOnboardingStepVisitedEventRequest): AsyncResult<void> {
        const userOnboardingStepVisitedEvent: UserOnboardingStepVisited = {
            origin: 'User',
            name: 'OnboardingStepVisited',
            createdAt: new Date(Date.now()),
            id: UUID.create(),
            step,
        };

        await this.eventBus.publish(userOnboardingStepVisitedEvent);

        return Success();
    }
}

export const PublishOnboardingStepVisitedEvent: UseCaseClass<PublishOnboardingStepVisitedEvent> =
    PublishOnboardingStepVisitedEventUseCase;
