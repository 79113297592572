import { Money } from '@embroker/shotwell/core/types/Money';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { Revenue } from '@embroker/shotwell/core/types/Revenue';
import { Year } from '@embroker/shotwell/core/types/Year';
import { OracleAnswerTypeDefinition } from '../QuestionerQuestionType';
import { Joi } from '@embroker/shotwell/core/validation/schema';

export type OracleCurrency = {
    currency_code: string;
    unit_amount: number;
};

export type OracleRevenueType = {
    fiscal_year: number;
    gross_revenue_total: OracleCurrency;
};
export const serializeRevenueAnswer = (
    formData: unknown,
): { fiscal_year: number; gross_revenue_total: Money } | undefined => {
    const revenue = Revenue.create(formData as Revenue);
    if (isOK(revenue)) {
        return {
            fiscal_year: revenue.value.fiscalYear,
            gross_revenue_total: revenue.value.grossTotal as Money,
        };
    }
};
export function assertOracleRevenueType(input: any): input is OracleRevenueType {
    return 'fiscal_year' in input && 'gross_revenue_total' in input;
}
export const deserializeRevenueAnswers = (currentValue: any): unknown[] | undefined => {
    if (!Array.isArray(currentValue)) {
        return undefined;
    }

    return (currentValue as Revenue[]).reduce((accumulator, current) => {
        if (assertOracleRevenueType(current)) {
            const revenue = Revenue.create({
                fiscalYear: current.fiscal_year as Year,
                grossTotal: {
                    amount: current.gross_revenue_total.unit_amount,
                    currency: current.gross_revenue_total.currency_code,
                    precision: 2,
                },
            } as Revenue);

            if (isOK(revenue)) {
                accumulator.push(revenue.value);
            }
        }
        return accumulator;
    }, [] as Revenue[]);
};

export const oracleRevenueSchema = Joi.object({
    fiscal_year: Joi.number().integer().min(0).required(),
    gross_revenue_total: Money.schema.required(),
});

export const RevenueDefinition: OracleAnswerTypeDefinition = {
    answerKeyType: 'revenue',
    schemaFunctions: {
        validator: oracleRevenueSchema,
        serializeAnswer: serializeRevenueAnswer,
        deserializeAnswer: deserializeRevenueAnswers,
    },
};
