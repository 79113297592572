import {
    StackLayout,
    InsetBox,
    ComparisonTable,
    Text,
    CenterLayout,
    BoxLayout,
    Icon,
} from '@embroker/ui-toolkit/v2';
import React from 'react';

export const CyberDifferenceModal = function CyberDifferenceModal() {
    return (
        <StackLayout>
            <Text style="heading 3">Standard vs Plus on Platform</Text>
            <InsetBox>
                <ComparisonTable
                    comparisonData={[
                        {
                            topic: (
                                <React.Fragment>
                                    <Text style="heading 4">
                                        $1,000,000 limit for Social Engineering Fraud Coverage
                                    </Text>
                                    <Text style="body 2">
                                        Social Engineering Fraud is when an employee is misled into
                                        believing he or she is communicating with a vendor or senior
                                        executive at the company (most often CFO). The employee is
                                        tricked into sending money or sensitive data to the person
                                        pretending to be the vendor or executive. Both our Standard
                                        and Plus policies reimburse lost funds in this scenario. Our
                                        Standard policy provides $100,000 of coverage and our Plus
                                        policy provides an industry leading $1,000,000 of coverage.
                                    </Text>
                                </React.Fragment>
                            ),
                            plus: (
                                <CenterLayout gap="12" centerText>
                                    <BoxLayout gap="8" as={Text} style="overline" color="brand-500">
                                        Plus
                                    </BoxLayout>

                                    <BoxLayout gap="8">
                                        <Icon name="status-success" size="medium" />
                                    </BoxLayout>

                                    <Text color="brand-500">Limit $1,000,000</Text>
                                </CenterLayout>
                            ),
                            standard: (
                                <CenterLayout gap="12" centerText>
                                    <BoxLayout gap="8" as={Text} style="overline" color="brand-500">
                                        Standard
                                    </BoxLayout>

                                    <BoxLayout gap="8">
                                        <Icon name="status-success" size="medium" />
                                    </BoxLayout>

                                    <Text color="brand-500">Limit $100,000</Text>
                                </CenterLayout>
                            ),
                        },
                        {
                            topic: (
                                <React.Fragment>
                                    <Text style="heading 4">
                                        $1,000,000 limit for Telecommunications Fraud
                                    </Text>
                                    <Text style="body 2">
                                        Both our Standard and Plus policies provide coverage for
                                        unauthorized calls or metered data usage by a hacker or
                                        other third party. Our Plus policy provides $1,000,000 of
                                        coverage, while our Standard policy provides $500,000 of
                                        coverage.
                                    </Text>
                                </React.Fragment>
                            ),
                            plus: (
                                <CenterLayout gap="12" centerText>
                                    <BoxLayout gap="8" as={Text} style="overline" color="brand-500">
                                        Plus
                                    </BoxLayout>

                                    <BoxLayout gap="8">
                                        <Icon name="status-success" size="medium" />
                                    </BoxLayout>

                                    <Text color="brand-500">Limit $1,000,000</Text>
                                </CenterLayout>
                            ),
                            standard: (
                                <CenterLayout gap="12" centerText>
                                    <BoxLayout gap="8" as={Text} style="overline" color="brand-500">
                                        Standard
                                    </BoxLayout>

                                    <BoxLayout gap="8">
                                        <Icon name="status-success" size="medium" />
                                    </BoxLayout>

                                    <Text color="brand-500">Limit $500,000</Text>
                                </CenterLayout>
                            ),
                        },
                        {
                            topic: (
                                <React.Fragment>
                                    <Text style="heading 4">
                                        5-Hour Waiting Period for Business Interruption
                                    </Text>
                                    <Text style="body 2">
                                        Both our Plus and Standard policies provide coverage for the
                                        loss of net profit when a cyber attack on your computer
                                        system causes a business interruption. A Waiting Period is
                                        the time after the network security event that needs to pass
                                        before this coverage is triggered. Our Standard policy has a
                                        market competitive 8-hour Waiting Period. Our Plus policy is
                                        even better, with a 5-hour Waiting Period. Note, the 8-hour
                                        Waiting Period applies for Plus’ Dependent Business
                                        Interruption coverage.
                                    </Text>
                                </React.Fragment>
                            ),
                            plus: (
                                <CenterLayout gap="12" centerText>
                                    <BoxLayout gap="8" as={Text} style="overline" color="brand-500">
                                        Plus
                                    </BoxLayout>

                                    <BoxLayout gap="8">
                                        <Icon name="status-success" size="medium" />
                                    </BoxLayout>

                                    <Text color="brand-500">5-hour waiting</Text>
                                </CenterLayout>
                            ),
                            standard: (
                                <CenterLayout gap="12" centerText>
                                    <BoxLayout gap="8" as={Text} style="overline" color="brand-500">
                                        Standard
                                    </BoxLayout>

                                    <BoxLayout gap="8">
                                        <Icon name="status-success" size="medium" />
                                    </BoxLayout>

                                    <Text color="brand-500">8-hour waiting</Text>
                                </CenterLayout>
                            ),
                        },
                        {
                            topic: (
                                <React.Fragment>
                                    <Text style="heading 4">Invoice Fraud Coverage</Text>
                                    <Text style="body 2">
                                        A cyber attack on your network can give hackers the needed
                                        information to fraudulently produce an invoice (that looks
                                        like your invoice). Invoice Fraud is when this fraudulent
                                        invoice is used to trick your client into transferring money
                                        to the hacker’s account. Our Plus policy provides $250,000
                                        for net cost (excluding profit) of delivered products or
                                        services, when you are unable to collect from a client that
                                        is tricked into transferring money to a hacker instead of
                                        you.
                                    </Text>
                                </React.Fragment>
                            ),
                            plus: true,
                            standard: false,
                        },
                        {
                            topic: (
                                <React.Fragment>
                                    <Text style="heading 4">
                                        Contingent Bodily Injury and Property Damage Coverage
                                    </Text>
                                    <Text style="body 2">
                                        The intent of Technology E&O and Cyber liability policies is
                                        to cover financial injuries and will therefore exclude
                                        claims alleging bodily injury or property damage. This is
                                        because the intent of General Liability is to cover such
                                        claims. However, for technology companies, the lion’s share
                                        of General Liability policies exclude claims related to
                                        their service creating a gap in coverage. Our Plus policy
                                        provides coverage for these circumstances.
                                    </Text>
                                </React.Fragment>
                            ),
                            plus: true,
                            standard: false,
                        },
                        {
                            topic: (
                                <React.Fragment>
                                    <Text style="heading 4">Dependent Business Interruption</Text>
                                    <Text style="body 2">
                                        If an outsourced service provider that your company depends
                                        on gets hit by a cyber attack, our Plus policy provides
                                        coverage for the loss of net profit that may be attributed
                                        to the interruption of services.
                                    </Text>
                                </React.Fragment>
                            ),
                            plus: true,
                            standard: false,
                        },
                        {
                            topic: (
                                <React.Fragment>
                                    <Text style="heading 4">Liquidated Damages</Text>
                                    <Text style="body 2">
                                        Typically, damages covered under cyber policies will not
                                        include liquidated damages. Our Standard and Plus policies
                                        do not include liquidated damages. However, our Plus policy
                                        includes the amount for which your company would still have
                                        been liable, in the absence of a liquidated damages
                                        agreement.
                                    </Text>
                                </React.Fragment>
                            ),
                            plus: true,
                            standard: false,
                        },
                        {
                            topic: (
                                <React.Fragment>
                                    <Text style="heading 4">Replacing or Repairing Hardware</Text>
                                    <Text style="body 2">
                                        Both our Standard and Plus policies provide coverage to
                                        repair and recover data and applications after a cyber
                                        attack. Our Plus policy goes one step further and gives
                                        $500,000 of coverage to replace or repair hardware that
                                        becomes nonfunctional after an attack.
                                    </Text>
                                </React.Fragment>
                            ),
                            plus: true,
                            standard: false,
                        },
                    ]}
                />
            </InsetBox>
        </StackLayout>
    );
};
