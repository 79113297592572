import React, { useRef } from 'react';
import {
    HeroBanner,
    CoverLayout,
    CenterLayout,
    Text,
    StackLayout,
    useResponsive,
} from '@embroker/ui-toolkit/v2';

interface ResponsiveCoverLayoutProps {
    readonly children: React.ReactNode;
}

function StackCoverLayout({ children }: ResponsiveCoverLayoutProps) {
    const isSmallHeight = useResponsive({ screenHeight: { smallerThan: 750 } });
    const verticalStackGap = isSmallHeight ? '16' : '64';

    return (
        <StackLayout gap={verticalStackGap}>
            <div />
            <StackLayout gap={verticalStackGap}>
                <div />
                {children}
            </StackLayout>
        </StackLayout>
    );
}

export function MarketingSideBanner() {
    const heroBannerContainerRef = useRef<HTMLDivElement>(null);
    const isCondensedHeight = useResponsive(
        { containerHeight: { greaterThan: window.innerHeight } },
        heroBannerContainerRef,
    );

    const ResponsiveCoverLayout = isCondensedHeight ? StackCoverLayout : CoverLayout;

    return (
        <HeroBanner ref={heroBannerContainerRef} background="ui-300" appearance="sidebar">
            <ResponsiveCoverLayout>
                <CenterLayout gap="32">
                    <StackLayout gap="4">
                        <Text style="hero light" color="brand-400">
                            Business Insurance
                        </Text>
                        <StackLayout gap="32">
                            <Text style="hero" color="brand-400">
                                Radically Simple
                            </Text>
                            <Text style="heading 5" color="brand-400">
                                Industry-tailored commercial <br /> insurance lives here
                            </Text>
                        </StackLayout>
                    </StackLayout>
                </CenterLayout>
            </ResponsiveCoverLayout>
        </HeroBanner>
    );
}
