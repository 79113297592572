import {
    Element,
    formatDate,
    getComponent,
    registerComponent,
    registerComponentSection,
    useStateMachine,
} from '@embroker/service-app-engine';
import { container } from '@embroker/shotwell/core/di';
import { Sanitizer } from '@embroker/shotwell/core/sanitization/Sanitizer';
import { Money } from '@embroker/shotwell/core/types/Money';
import { CurrencyInput } from '@embroker/shotwell/view/components/CurrencyInput';
import {
    CheckBoxGroup,
    ElementProps,
    Input,
    RadioGroup,
    StatusMessage,
    Text,
    SelectWithPercentageGroup,
    SelectPercentageGroupValue,
} from '@embroker/ui-toolkit/v2';
import { isValid, parseISO } from 'date-fns';
import React, { useMemo } from 'react';
import { Naics } from '../../../industries/view/components/Naics';
import { Location } from '../../../locations/view/components/Location';
import { LocationWithMenu } from '../../../locations/view/components/LocationWithMenu';
import { CheckboxWithTooltip } from './components/CheckboxWithTooltip';
import { DateField } from './components/DateField';
import { DropdownWithTooltip } from './components/DropdownWithTooltip';
import { FeinField } from './components/FeinField.view';
import { GroupField } from './components/GroupField';
import { HiddenField } from './components/HiddenField';
import { SignatureField } from './components/SignatureField';
import { TableField } from './components/TableField';
import { TextWithTooltip } from './components/TextWithTooltip';
import { UploadField } from './components/UploadField';
import { wrapFormField } from './wrapFormField';
import { WorkerTypesByLocation } from '../../../industries/view/components/WorkerTypesByLocation';

const MAX_SAFE_INTEGER = 2 ** 53 - 1;

let componentsRegistered = false;

export function registerFormComponents() {
    if (componentsRegistered) {
        return;
    }
    componentsRegistered = true;

    // [field]
    // type = "component"
    registerComponent(
        'component',
        React.forwardRef(function ComponentField({ instance }: any, ref) {
            const [{ component, field, ...props }] = useStateMachine(instance.machine);
            const fields = useMemo(() => {
                if (!field) {
                    return null;
                }
                return field.map((instance: any) => {
                    const Component = getComponent(instance);
                    return <Component key={instance.id} instance={instance} />;
                });
            }, [field]);

            if (!component) {
                return null;
            }

            if (typeof component === 'string') {
                const invalidProps = [
                    'isValid',
                    'isPristine',
                    'enabled',
                    'currentState',
                    'styleHints',
                    'initialValue',
                    'nooutput',
                    'suppressEventPropagation',
                    'prefillValue',
                ];
                for (const prop of invalidProps) {
                    delete props[prop];
                }
            }

            return React.createElement(
                component,
                props,
                props.dangerouslySetInnerHTML ? undefined : fields,
            );
        }),
        { default: true },
    );

    // [field]
    // type = "currency"
    registerComponent(
        'currency',
        wrapFormField(CurrencyInput, {
            fromModelValue: (value) => Money.tryFromFloat(value),
            toModelValue: (value) => Money.toFloat(value),
        }),
        { default: true },
    );
    registerComponentSection('Field.PreviousValue', 'currency', ({ value }: any) => (
        <CurrencyInput readOnly value={value} />
    ));

    // [field]
    // type = "date"
    //
    // optionally:
    // format = "MM/YYYY"
    // or:
    // format = "YYYY"
    registerComponent(
        'date',
        wrapFormField(DateField, {
            toModelValue: (value, { serializeAs }) => {
                return isValid(value) ? formatDate(value, serializeAs) : undefined;
            },
            toModelValueInputs: ['serializeAs'],
            fromModelValue: (value) => {
                // Components don't work well with Invalid Dates, so we send them
                // undefined, which should have the same effect -- no changes
                // will be reported until there's an actual change to the input.
                const date = parseISO(value);
                return isValid(date) ? date : undefined;
            },
            select: ({ format, tooltip }) => ({ format, tooltip }),
        }),
        { default: true },
    );

    // [field]
    // type = "ref"
    registerComponent(
        'ref',
        wrapFormField(GroupField, {
            select: ({ fields }) => ({
                fields,
            }),
        }),
        { default: true },
    );

    // [field]
    // type = "email"
    registerComponent(
        'email',
        wrapFormField(Input.Email, {
            fromModelValue: formatStringViewValue,
            select: () => ({}),
        }),
        { default: true },
    );

    // [field]
    // type = "group"
    registerComponent(
        {
            element: 'GroupField',
            attribute: ({ type }: any) => type === 'group' || type === 'section',
        },
        wrapFormField(GroupField, {
            select: ({ fields, styleHints = [] }) => ({
                fields,
                className: Array.isArray(styleHints) ? styleHints.concat('em-group').join(' ') : '',
            }),
        }),
        { default: true },
    );

    // [field]
    // type = "hidden"
    registerComponent(
        'hidden',
        wrapFormField(HiddenField, {
            fromModelValue: formatStringViewValue,
            select: () => ({}),
        }),
        { default: true },
    );

    // [field]
    // type = "section"
    // renderingComponent = "WorkerTypesByLocation"
    registerComponent(
        '@Field[renderingComponent=WorkerTypesByLocation]',
        wrapFormField(WorkerTypesByLocation, {
            toModelValue: (value) => {
                return {
                    location: value?.location,
                    worker_types: value?.worker_types,
                    show_location_header: value?.show_location_header,
                };
            },
            fromModelValue: (value = {}) => {
                const resolvedLocation = value?.location?.location
                    ? value?.location?.location
                    : value?.location;
                return {
                    location: resolvedLocation,
                    worker_types: value?.worker_types,
                    show_location_header: value?.show_location_header,
                };
            },
            select: ({ value = {}, fields }) => {
                const formEngineErrors = ((fields ?? [])[1]?.machine?.state?.fields ?? []).map(
                    (field: Element) => field.machine.state.childrenErrors,
                );

                const resolvedLocation = value?.location?.location
                    ? value?.location?.location
                    : value?.location;

                return {
                    location: resolvedLocation,
                    worker_types: value?.worker_types,
                    show_location_header: value?.show_location_header,
                    errors: (() => {
                        // Here we have an array of records -> 1 item in the array
                        // represents the errors for the various worker types that can
                        // be appended by the user by clicking '+ Add another type'.
                        const errors: Record<string, string[]>[] = [];

                        if (!formEngineErrors || formEngineErrors.length == 0) {
                            return errors;
                        }

                        const propMap: Record<string, string> = {
                            worker_class: 'worker_class',
                            full_time_count: 'full_time_count',
                            part_time_count: 'part_time_count',
                            payroll: 'payroll',
                        };

                        for (const workerTypeErrors of formEngineErrors) {
                            const errorsForThisWorkerType: Record<string, string[]> = {};
                            for (const [field, errorsDict] of workerTypeErrors?.entries()) {
                                const id = field.split('.').pop();
                                const prop = propMap[id];

                                if (prop) {
                                    errorsForThisWorkerType[prop] = [];
                                    for (const error of errorsDict.values()) {
                                        errorsForThisWorkerType[prop].push(error);
                                    }
                                }
                            }
                            errors.push(errorsForThisWorkerType);
                        }

                        return errors;
                    })(),
                };
            },
        }),
    );

    // [field]
    // type = "section"
    // renderingComponent = "Location"
    registerComponent(
        '@Field[renderingComponent=Location]',
        wrapFormField(Location, {
            toModelValue: (value) => {
                return {
                    id: value.id,
                    mailing_address: value.addressLine1,
                    suite: value.addressLine2,
                    city: value.city,
                    county: value.county,
                    state: value.state,
                    zip: value.zip,
                    square_footage: value.squareFootageOccupied,
                };
            },
            fromModelValue: (value = {}) => {
                return {
                    id: value.id,
                    addressLine1: value.mailing_address,
                    addressLine2: value.suite,
                    city: value.city,
                    county: value.county,
                    state: value.state,
                    zip: value.zip,
                    squareFootageOccupied: value.square_footage,
                };
            },
            select: ({ readOnly, value = {}, displaySquareFootage, childrenErrors }) => {
                return {
                    readOnly,
                    data: {
                        id: value.id,
                        addressLine1: value.mailing_address,
                        addressLine2: value.suite,
                        city: value.city,
                        county: value.county,
                        state: value.state,
                        zip: value.zip,
                        squareFootageOccupied: value.square_footage,
                    },
                    displaySquareFootage,
                    errors: (() => {
                        const errors: Record<string, string[]> = {};

                        if (!childrenErrors) {
                            return errors;
                        }

                        const propMap: Record<string, string> = {
                            mailing_address: 'addressLine1',
                            suite: 'addressLine2',
                            state: 'state',
                            city: 'city',
                            zip: 'zip',
                            square_footage: 'squareFootageOccupied',
                        };

                        for (const [field, errorsDict] of childrenErrors.entries()) {
                            const id = field.split('.').pop();
                            const prop = propMap[id];

                            if (prop) {
                                errors[prop] = [];
                                for (const error of errorsDict.values()) {
                                    errors[prop].push(error);
                                }
                            }
                        }

                        return errors;
                    })(),
                };
            },
        }),
    );

    // [field]
    // type = "section"
    // renderingComponent = "AreasOfPracticeGroup"
    // legacy_data_model = false
    registerComponent(
        {
            element: 'Field',
            attribute: ({ renderingComponent, legacy_data_model }: any) =>
                renderingComponent === 'AreasOfPracticeGroup' &&
                Boolean(legacy_data_model) === false,
        },
        wrapFormField(SelectWithPercentageGroup, {
            toModelValue: (value) => {
                return {
                    areas_of_practice_rows: value.map(
                        (areaOfPractice: { value: string; percentage: string }) => ({
                            area: areaOfPractice.value,
                            percentage: Number(areaOfPractice.percentage) || '',
                        }),
                    ),
                };
            },
            fromModelValue: (value = {}) => {
                return value?.areas_of_practice_rows?.map(
                    (areaOfPractice: { area: string; percentage: string }) => ({
                        value: areaOfPractice.area,
                        percentage: areaOfPractice.percentage + '' || '',
                    }),
                );
            },
            select: (input) => {
                const { items = [], errors } = input;
                return {
                    items,
                    error: Object.values(errors?.data ?? {}).length > 0,
                };
            },
        }),
    );

    // [field]
    // type = "section"
    // renderingComponent = "AreasOfPracticeGroup"
    // legacy_data_model = true
    type AreasOfPracticeLegacyValue = { [value: string]: { percentage: number | string } };
    registerComponent(
        {
            element: 'Field',
            attribute: ({ renderingComponent, legacy_data_model }: any) =>
                renderingComponent === 'AreasOfPracticeGroup' &&
                Boolean(legacy_data_model) === true,
        },
        wrapFormField(SelectWithPercentageGroup, {
            toModelValue: (value) => {
                const result = value.reduce(
                    (
                        acc: AreasOfPracticeLegacyValue,
                        areaOfPractice: { value: string; percentage: string },
                    ) => {
                        if (areaOfPractice.value) {
                            acc[areaOfPractice.value] = {
                                percentage: Number(areaOfPractice.percentage) || '',
                            };
                        }

                        return acc;
                    },
                    {} as AreasOfPracticeLegacyValue,
                );

                return result;
            },
            fromModelValue: (value: AreasOfPracticeLegacyValue = {}) => {
                const result = Object.keys(value).reduce((acc, key) => {
                    acc.push({
                        value: key ?? '',
                        percentage: value[key].percentage + '' || '',
                    });

                    return acc;
                }, [] as SelectPercentageGroupValue[]);

                return result;
            },
            select: ({ fields, errors }) => ({
                items: (() => {
                    const items: any = [];
                    if (!fields) {
                        return items;
                    }

                    for (const field of fields) {
                        const id = field.id.split('.').pop();
                        const title = field.machine.state.fields.find((field: any) =>
                            field.id.endsWith('title'),
                        ).machine.state.title;
                        items.push({
                            value: id,
                            title: title,
                        });
                    }
                    return items;
                })(),
                error: Object.values(errors?.data ?? {}).length > 0,
            }),
        }),
    );

    // [field]
    // type = "section"
    // renderingComponent = "LocationWithMenu"
    registerComponent(
        '@Field[renderingComponent=LocationWithMenu]',
        wrapFormField(LocationWithMenu, {
            toModelValue: (value) => {
                return {
                    id: value.id,
                    mailing_address: value.addressLine1 ?? null,
                    suite: value.addressLine2,
                    city: value.city ?? null,
                    county: value.county ?? null,
                    state: value.state ?? null,
                    zip: value.zip,
                    square_footage: value.squareFootageOccupied,
                };
            },
            fromModelValue: (value = {}) => {
                return {
                    id: value.id,
                    addressLine1: value.mailing_address ?? null,
                    addressLine2: value.suite,
                    city: value.city ?? null,
                    county: value.county ?? null,
                    state: value.state ?? null,
                    zip: value.zip,
                    squareFootageOccupied: value.square_footage,
                };
            },
            select: ({ readOnly, value = {}, displaySquareFootage, childrenErrors }) => {
                return {
                    readOnly,
                    data: {
                        id: value.id,
                        addressLine1: value.mailing_address,
                        addressLine2: value.suite,
                        city: value.city ?? null,
                        county: value.county ?? null,
                        state: value.state ?? null,
                        zip: value.zip,
                        squareFootageOccupied: value.square_footage,
                    },
                    displaySquareFootage,
                    errors: (() => {
                        const errors: Record<string, string[]> = {};

                        if (!childrenErrors) {
                            return errors;
                        }

                        const propMap: Record<string, string> = {
                            mailing_address: 'addressLine1',
                            suite: 'addressLine2',
                            state: 'state',
                            city: 'city',
                            zip: 'zip',
                            square_footage: 'squareFootageOccupied',
                        };

                        for (const [field, errorsDict] of childrenErrors.entries()) {
                            const id = field.split('.').pop();
                            const prop = propMap[id];

                            if (prop) {
                                errors[prop] = [];
                                for (const error of errorsDict.values()) {
                                    errors[prop].push(error);
                                }
                            }
                        }

                        return errors;
                    })(),
                };
            },
        }),
    );

    // [field]
    // type = "checkbox"
    registerComponent(
        'checkbox',
        wrapFormField(CheckboxWithTooltip, {
            fromModelValue: Boolean,
            select: ({ title, value, tooltip, appearance, readOnly, note, innerTooltip }) => ({
                children: title,
                checked: value,
                appearance,
                tooltip,
                disabled: readOnly,
                note,
                tooltipText: innerTooltip,
            }),
        }),
        { default: true },
    );

    registerComponentSection('Field.Title', 'checkbox', null);

    // [field]
    // type = "multiselect"
    // displayAs = "checklist" // or "auto" with less than or 4 items
    registerComponent(
        {
            element: 'MultiselectField',
            attribute: ({ displayAs, item, type }: any) =>
                type === 'multiselect' &&
                (displayAs === 'checklist' || (displayAs === 'auto' && item.length <= 4)),
        },
        wrapFormField(CheckBoxGroup, {
            select: ({ item, readOnly }) => ({
                items: item,
                appearance: 'border',
                className: 'multiselect-option-list em-checklist',
                readOnly,
            }),
        }),
        { default: true },
    );
    // [field]
    // type = "multiselect"
    // displayAs = "select" // or "auto" with more than 4 items
    registerComponent(
        {
            element: 'MultiselectField',
            attribute: ({ displayAs, item, searchable, type }: any) =>
                type === 'multiselect' &&
                (displayAs === 'select' || searchable || (displayAs === 'auto' && item.length > 4)),
        },
        wrapFormField(Input.Select, {
            select: ({ item, searchable }) => ({
                searchable,
                items: item,
                multiple: true,
            }),
        }),
        { default: true },
    );

    // [field]
    // type = "number"
    registerComponent(
        'number',
        wrapFormField(Input.Number, {
            fromModelValue: formatNumberViewValue,
            toModelValue: (value) => {
                return parseNumberModelValue(String(value));
            },
            select: ({ allowDecimal }) => ({
                includeThousandsSeparator: false,
                allowDecimal,
            }),
        }),
        { default: true },
    );

    // [field]
    // type = "percentage"
    registerComponent(
        'percentage',
        wrapFormField(Input.Number, {
            fromModelValue: formatNumberViewValue,
            toModelValue: (value) => parseNumberModelValue(value.replace(/[^0-9.-]/g, '')),
            select: () => ({
                suffix: '%',
                allowDecimal: true,
                allowNegative: false,
                placeholder: '%',
                guided: true,
            }),
        }),
        { default: true },
    );

    // [field]
    // type = "select"
    // displayAs = "select" // or "auto" with more than 4 items
    registerComponent(
        {
            element: 'SelectField',
            attribute: ({ allowAdding, displayAs, item, itemSource, type }: any) =>
                type === 'select' &&
                (displayAs === 'select' ||
                    (displayAs === 'auto' && (item.length > 4 || allowAdding || itemSource))),
        },
        wrapFormField(Input.Select, {
            select: (props) => {
                return {
                    items: props.item,
                };
            },
        }),
        { default: true },
    );

    // [field]
    // type = "select"
    // allowAdding = true
    registerComponent(
        {
            element: 'SelectField',
            attribute: ({ itemSource, allowAdding }: any) => !itemSource && allowAdding,
        },
        wrapFormField(Input.ComboBox, {
            select: ({ item = [], newItemLabel, value }) => {
                const items = [...item];
                if (!items.find((i) => i.value === value)) {
                    items.push({ value, title: value });
                }
                return {
                    items: items,
                    formatCreateLabel: newItemLabel,
                };
            },
        }),
    );

    // [field]
    // type = "select"
    // itemSource = ...
    registerComponent(
        {
            element: 'SelectField',
            attribute: ({ itemSource, allowAdding }: any) => itemSource && !allowAdding,
        },
        wrapFormField(Input.SelectAsync, {
            select: ({ itemSource, noOptionsMessage, value }) => ({
                // SelectAsync wants label as well as value. Accidentally lead
                // investors have same value and label. This may easily not work
                // for other fields of this type that we'll want to add.
                options: value ? [{ value, label: value }] : [],
                request: itemSource,
                noOptionsText: noOptionsMessage,
            }),
            toModelValue: (value) => value?.value,
        }),
    );

    // [field]
    // type = "select"
    // itemSource = ...
    // allowAdding = true
    registerComponent(
        {
            element: 'SelectField',
            attribute: ({ itemSource, allowAdding, carrierSearch }: any) =>
                itemSource && allowAdding && !carrierSearch,
        },
        wrapFormField(Input.ComboBoxAsync, {
            select: ({ itemSource, noOptionsMessage, newItemLabel, value }) => ({
                // Same restriction as for SelectAsync
                options: value ? [{ value, label: value }] : [],
                request: itemSource,
                noOptionsText: noOptionsMessage,
                formatCreateLabel: newItemLabel,
            }),
            toModelValue: (value) => value?.value,
        }),
    );

    // [field]
    // type = "select"
    registerComponent(
        {
            element: 'SelectField',
            attribute: ({ carrierSearch }: any) => carrierSearch,
        },
        wrapFormField(Input.ComboBoxAsync, {
            select: ({ itemSource, noOptionsMessage, newItemLabel, value }) => ({
                // When searching for carriers we don't want to have a restriction as the components above.
                // Having a different value and label enables saving both carrier id and name.
                // When a new carrier is created the component sets the same value for the name and label,
                // but we save an id as null in that case in favor of a simpler code later.
                options: value ? [{ value: value?.id ?? value?.name, label: value?.name }] : [],
                request: itemSource,
                noOptionsText: noOptionsMessage,
                formatCreateLabel: newItemLabel,
            }),
            toModelValue: (value) => ({
                id: value?.value === value?.label ? null : value?.value,
                name: value?.label,
            }),
            fromModelValue: (carrier) => carrier?.id ?? carrier?.name,
        }),
    );

    // [field]
    // type = "select"
    // renderingComponent = "DropdownWithTooltip"
    registerComponent(
        '@SelectField[renderingComponent=DropdownWithTooltip]',
        wrapFormField(DropdownWithTooltip, {
            select: (props) => ({
                ...extract(props, [
                    'allowAdding',
                    'cacheOptions',
                    'createNewItem',
                    'itemSource',
                    'loadingLabel',
                    'newItemLabel',
                    'noOptionsMessage',
                    'searchable',
                    'defaultTooltip',
                ]),
                items: props.item,
                className: 'em-select-field',
                classNamePrefix: 'em-select-field',
            }),
        }),
    );

    // [field]
    // type = "static"
    // renderingComponent = "StatusMessage"
    registerComponent(
        '@StaticField[renderingComponent=StatusMessage]',
        wrapFormField(StatusMessage, {
            select: (props) => {
                const titleSanitized = (
                    <div
                        className={
                            Array.isArray(props.styleHints) ? props.styleHints.join(' ') : ''
                        }
                        dangerouslySetInnerHTML={{
                            __html: container
                                .get<Sanitizer>(Sanitizer)
                                .innerHTMLString(props.title),
                        }}
                    />
                );
                return {
                    status: props.status ? props.status : 'warning',
                    children: titleSanitized,
                };
            },
        }),
        { default: true },
    );

    // [field]
    // type = "static"
    // renderingComponent = "TextWithTooltip"
    registerComponent(
        '@StaticField[renderingComponent=TextWithTooltip]',
        wrapFormField(TextWithTooltip, {
            select: ({ title, tooltip }) => {
                return {
                    title: title,
                    style: 'body 1',
                    tooltip: tooltip,
                };
            },
        }),
        { default: true },
    );

    // [field]
    // type = "text"
    // renderingComponent = "NAICSSearchBox"
    registerComponent(
        '@TextField[renderingComponent=NAICSSearchBox]',
        wrapFormField(Naics, {
            select: ({ value, readOnly }) => ({
                initialValue: value,
                readOnly,
            }),
            toModelValue: (value) => {
                let result = undefined;
                if (typeof value === 'string') {
                    result = value;
                } else if (typeof value?.value === 'string') {
                    result = value?.value;
                }
                return result;
            },
        }),
    );

    // [field]
    // type = "select"
    // displayAs = "radio" // or "auto" with less than or 4 items
    registerComponent(
        {
            element: 'SelectField',
            attribute: ({ displayAs, item, type }: any) =>
                type === 'select' &&
                (displayAs === 'radio' || (displayAs === 'auto' && item.length <= 4)),
        },
        wrapFormField(RadioGroup, {
            select: ({ item }) => ({
                items: item,
                className: 'select-field-option-wrapper em-radio-button',
            }),
        }),
        { default: true },
    );

    // [field]
    // type = "static"
    //
    // or simply:
    //
    // [Label]
    registerComponent(
        'static',
        wrapFormField(Text, {
            select: ({ title, value }) => {
                return {
                    children: formatLabelInput(title, value),
                    className: 'em-field-label',
                    style: 'label-1',
                };
            },
        }),
        { default: true },
    );
    registerComponent(
        {
            element: 'StaticField',
            attribute: ({ styleHints = [] }: any) => styleHints.includes('em-h1'),
        },
        // Use same styles as for page heading. Registering component for Page
        // field should allow us to use <Heading level="3">{children}</Heading>
        // instead.
        wrapFormField(
            React.forwardRef(function Heading(
                { children }: ElementProps<'h1'>,
                ref: React.Ref<HTMLInputElement>,
            ) {
                return (
                    <div ref={ref} className="em-h1">
                        {children}
                    </div>
                );
            }),
            {
                select: ({ title, value }) => {
                    return {
                        children: formatLabelInput(title, value),
                    };
                },
            },
        ),
    );
    registerComponentSection('Field.Title', 'static', null);

    // [field]
    // type = "table"
    registerComponent(
        'table',
        wrapFormField(TableField, {
            select: (props, { addRow, removeRow }) => ({
                ...extract(props, [
                    'fields',
                    'addAllowed',
                    'addLabel',
                    'removeAllowed',
                    'removeLabel',
                    'beforeRemovingRow',
                    'minimum',
                    'maximum',
                    'dynamicRows',
                    'sortDynamicRowsBy',
                    'sortDynamicRowsOrder',
                    'styleHints',
                ]),
                className: (props.styleHints || []).concat('em-group em-table').join(' '),
                addRow,
                removeRow,
            }),
        }),
        { default: true },
    );

    registerComponent(
        'upload',
        wrapFormField(UploadField, {
            select: ({ value }) => ({ value }),
        }),
        { default: true },
    );

    // [field]
    // type = "tel"
    registerComponent(
        'tel',
        wrapFormField(Input.Tel, {
            toModelValue: (value) => parseNumberModelValue(value.replace(/[^0-9]/g, '')),
            fromModelValue: formatStringViewValue,
            select: () => ({}),
        }),
        { default: true },
    );

    // [field]
    // type = "text"
    // multiline = false // default
    registerComponent(
        {
            element: 'TextField',
            attribute: ({ multiline }: any) => multiline === false,
        },
        wrapFormField(Input.Text, {
            fromModelValue: formatStringViewValue,
            select: ({ readOnly }) => ({ readOnly }),
        }),
        { default: true },
    );
    // [field]
    // type = "text"
    // multiline = true
    registerComponent(
        {
            element: 'TextField',
            attribute: ({ multiline }: any) => multiline === true,
        },
        wrapFormField(Input.TextArea, {
            fromModelValue: formatStringViewValue,
            select: () => ({}),
        }),
        { default: true },
    );

    // [field]
    // type = "zipcode"
    registerComponent(
        'zipcode',
        wrapFormField(Input.Zip, {
            toModelValue: (value) => value.replace(/[^0-9]/g, ''),
            fromModelValue: formatStringViewValue,
            select: () => ({}),
        }),
        { default: true },
    );

    // [field "signature_field"]
    registerComponent(
        '@Field[id=signature_field]',
        wrapFormField(SignatureField, {
            select: (props) => {
                return {
                    ...extract(props, [
                        'readOnly',
                        'fullName',
                        'titleValue',
                        'state',
                        'showSignatureValue',
                    ]),
                    items: props.item,
                };
            },
        }),
    );

    // [field]
    // type = "fein"
    registerComponent(
        'fein',
        wrapFormField(FeinField, {
            toModelValue: (value) => value.replace('-', ''),
            fromModelValue: formatStringViewValue,
        }),
        { default: true },
    );
}

/**
 * Extract a subset of properties from an object.
 *
 * @param {object}      object        An object to extract props from
 * @param {string[]}    propNames     Names of properties to extract
 * @returns object
 */
function extract(object: Record<string, any>, propNames: string[]) {
    const extracted: Record<string, any> = {};
    for (const prop of propNames) {
        extracted[prop] = object[prop];
    }
    return extracted;
}

/**
 * Parses number view value for use in the model.
 *
 * @param {string} viewValue
 * @returns number|undefined
 */
export function parseNumberModelValue(viewValue: string) {
    const number = Number.parseFloat(viewValue);

    if (Number.isNaN(number)) {
        return undefined; // eslint-disable-line no-undefined
    }

    if (!Number.isFinite(number) || Math.abs(number) > MAX_SAFE_INTEGER) {
        return number < 0 ? -MAX_SAFE_INTEGER : MAX_SAFE_INTEGER;
    }

    return number;
}

/**
 * Formats number model value for use in the view.
 *
 * @param {number?} modelValue
 * @returns string
 */
export function formatNumberViewValue(modelValue?: number) {
    if (
        typeof modelValue !== 'number' ||
        !Number.isFinite(modelValue) ||
        Number.isNaN(modelValue)
    ) {
        return '';
    }
    return String(modelValue);
}

/**
 * Formats Label input
 */
function formatLabelInput(title: string, value: any) {
    function toString(param: any) {
        // intentionally using == instead of === to match both null and undefined
        return param == null ? '' : String(param); // eslint-disable-line eqeqeq
    }
    return toString(title).replace(/%s/, toString(value)).replace('%%', '%');
}

/**
 * Formats text model value for use in the view.
 *
 * @param {string?} modelValue
 * @returns string
 */
function formatStringViewValue(modelValue?: string) {
    return modelValue == null ? '' : String(modelValue); // eslint-disable-line eqeqeq
}
