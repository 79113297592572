import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Result,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { Invoice } from '../entities/Invoice';
import { InvoiceRepository } from '../repositories/InvoiceRepository';
import { BundlePayment, SinglePayment, Payment } from '../types/Payment';
import { buildPaymentUI } from './GetSelectedInvoiceList';

const lineOfBusinessBundleTypeMap = new Map<string, string>([
    ['Professional Liability', 'Law Firm Program'],
    ['Accountants Professional Liability', 'Accountants Program'],
    [
        'Non Technology Business and Management Consultant Professional Liability',
        'Non-Technology Consultants Program',
    ],
    ['Tax Preparers and Bookkeepers Professional Liability', 'Tax Preparers Program'],
    ['Real Estate Agents Professional Liability', 'Real Estate Program'],
    ['Home Inspectors Professional Liability', 'Home Inspectors Program'],
]);

const bundleCoverageDisplayNameByLOB = new Map<string, string>([
    [
        'Non Technology Business and Management Consultant Professional Liability',
        'Non-Technology Business and Management Consultants Professional Liability',
    ],
]);

export interface GetInvoicesRequest {
    organizationId: UUID;
}

export interface GetInvoicesResponse {
    invoiceDueList: Immutable<Payment[]>;
}

export interface GetPendingInvoices extends UseCase {
    execute(
        request: GetInvoicesRequest,
    ): AsyncResult<GetInvoicesResponse, InvalidArgument | OperationFailed>;
}

function compareByPurchaseType(item1: Immutable<Payment>, item2: Immutable<Payment>) {
    if (item1.purchaseType == undefined && item2.purchaseType == undefined) {
        if (item1.createdDate != null && item2.createdDate != null) {
            return item1.createdDate
                .toLocaleDateString()
                .localeCompare(item2.createdDate.toLocaleDateString());
        }
        return -1;
    }
    if (item1.purchaseType == undefined) {
        return 1;
    }
    if (item2.purchaseType == undefined) {
        return -1;
    }
    return item2.purchaseType.localeCompare(item1.purchaseType);
}

export function isPaymentInBundleMap(
    bundleInvoicesMap: Map<UUID, Immutable<Invoice>[]>,
    bundleId: UUID,
): boolean {
    return bundleInvoicesMap.get(bundleId) !== undefined;
}

@injectable()
class GetPendingInvoicesUseCase extends UseCase {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Payments/GetPendingInvoices');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(InvoiceRepository) private invoiceRepo: InvoiceRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        data: GetInvoicesRequest,
    ): AsyncResult<GetInvoicesResponse, InvalidArgument | OperationFailed> {
        if (data.organizationId === null) {
            return Failure(InvalidArgument({ argument: 'Bad org ID', value: data.organizationId }));
        }
        const filteredInvoices = await this.invoiceRepo.getFiltered(data.organizationId);
        if (isErr(filteredInvoices)) {
            return filteredInvoices;
        }

        const { singleInvoiceUIList, bundleInvoiceUIList } = buildPaymentUI(
            filteredInvoices.value.duePayments,
        );

        const sortedInvoiceDueList = [...singleInvoiceUIList, ...bundleInvoiceUIList].sort(
            compareByPurchaseType,
        );

        const response: GetInvoicesResponse = {
            invoiceDueList: sortedInvoiceDueList,
        };

        return Success(response);
    }
}

export function buildSinglePaymentUI(invoice: Immutable<Invoice>): SinglePayment {
    const lineOfBusiness =
        bundleCoverageDisplayNameByLOB.get(invoice.lineOfBusiness) ?? invoice.lineOfBusiness;

    return {
        id: invoice.id,
        carrierName: invoice.carrierName,
        organizationId: invoice.organizationId,
        policyId: invoice.policyId,
        policyNumber: invoice.policyNumber,
        purchaseType: invoice.purchaseType,
        balance: invoice.balance,
        billingName: invoice.billingName,
        createdDate: invoice.createdDate,
        description: invoice.description,
        invoiceItemList: invoice.invoiceItemList,
        invoiceNumber: invoice.invoiceNumber,
        isEndorsement: invoice.isEndorsement,
        isNotEligibleForFinancing: invoice.isNotEligibleForFinancing,
        lineOfBusiness: lineOfBusiness,
        policyEffectiveDate: invoice.policyEffectiveDate,
        status: invoice.status,
        total: invoice.total,
    } as SinglePayment;
}

export function buildBundlePaymentUI(
    bundleId: UUID,
    invoiceList: Immutable<Invoice[]>,
): Result<BundlePayment, InvalidArgument | OperationFailed> {
    let policyLineOfBusiness = '';
    const isNotEligibleForFinancing = !!invoiceList.find(
        (invoice) => invoice.isNotEligibleForFinancing,
    );
    const paymentList: SinglePayment[] = [];
    for (const invoice of invoiceList) {
        const uiPayment = buildSinglePaymentUI(invoice);

        const bundleName = lineOfBusinessBundleTypeMap.get(invoice.lineOfBusiness);
        if (bundleName !== undefined) {
            policyLineOfBusiness = bundleName;
        }
        paymentList.push(uiPayment);
    }

    const totalSum = Money.sum(invoiceList.map((invoice) => invoice.total));
    const balanceSum = Money.sum(invoiceList.map((invoice) => invoice.balance));

    if (isErr(totalSum)) {
        return handleOperationFailure(totalSum);
    }

    if (isErr(balanceSum)) {
        return handleOperationFailure(balanceSum);
    }

    return Success({
        balance: balanceSum.value,
        createdDate: invoiceList[0].createdDate,
        id: bundleId,
        lineOfBusiness: policyLineOfBusiness,
        billingName: invoiceList[0].billingName,
        invoiceList: paymentList,
        total: totalSum.value,
        isEndorsement: false,
        isNotEligibleForFinancing: isNotEligibleForFinancing,
        policyEffectiveDate: invoiceList[0].policyEffectiveDate,
    } as BundlePayment);
}

export const GetPendingInvoices: UseCaseClass<GetPendingInvoices> = GetPendingInvoicesUseCase;
