import { FieldDefinition } from '@embroker/shotwell/view/hooks/useForm';
import { ConditionalDisplayOptions, FormQuestionDefinition } from '../hooks/useDataDrivenForm';
import { getFieldValidation } from './fieldValidationFactory';
import { ComplexFormFieldDefinitionMap, isComplexQuestionType } from './ComplexFieldTypes';

export interface FormFieldDefinition extends FormQuestionDefinition {
    conditionalDisplayDependencyChain?: ConditionalDisplayOptions[];
}

export interface FormFields {
    [x: string]: FieldDefinition<any, never>;
}

export const formFieldsFactory = (formQuestionDefinitions: FormFieldDefinition[]): FormFields => {
    return formQuestionDefinitions.reduce((acc, { key }) => {
        acc[key] = getFieldDefinition(key, formQuestionDefinitions);
        return acc;
    }, {} as FormFields);
};

export const getFieldDefinition = (
    questionKey: string,
    formQuestionDefinitions: FormQuestionDefinition[],
): FieldDefinition<any, never> => {
    const { validator, formatValidationError } = getFieldValidation(
        questionKey,
        formQuestionDefinitions,
    );

    const question = formQuestionDefinitions.find(
        (questionDefinition) => questionDefinition.key === questionKey,
    );

    // Since the .find method above will return FormQuestionDefinition | undefined we need a fallback here, in reality this should never be undefined
    const questionType = question?.questionType ?? 'static';
    const type = isComplexQuestionType(questionType)
        ? ComplexFormFieldDefinitionMap[questionType].type
        : questionType;

    return {
        type,
        validator,
        formatValidationError,
    };
};
