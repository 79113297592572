import { execute } from '@embroker/shotwell/core/UseCase';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { UserOnboardingDetails } from '../../types/UserOnboardingDetails';
import { GetOnboardingRedirect } from '../../useCases/GetOnboardingRedirect';
import { useCallback, useState } from 'react';
import { useNavigation } from 'react-navi';
import {
    GetUserOnboardingEntrypoint,
    GetUserOnboardingEntrypointPayload,
} from '../../useCases/GetUserOnboardingEntrypoint';
import { OnboardingPrefillQuestionnaireData } from '../../types/OnboardingPrefillQuestionnaireData';
import { UpdateUserOnboardingQuestionnaireData } from '../../useCases/UpdateUserOnboardingQuestionnaireData';

export const USER_ONBOARDING_BASE_ROUTE = '/user/onboarding';

/**
 * A hook for exiting the user onboarding flow.
 * Will return a function called 'exitOnboardingFlow' that can be called to exit the onboarding flow.
 *
 * This hook determines where a user will enter the app using the GetOnboardingRedirect use case
 * This hook performs the clearUserOnboardingContext() action
 */
export function useUserOnboardingNavigation(
    userOnboadingEntrypoint: NonNullable<GetUserOnboardingEntrypointPayload>,
) {
    const { navigate } = useNavigation();
    const [currentOnboardingStep, setUserOnboardingStepType] = useState(userOnboadingEntrypoint);

    const onExitOnboardingFlow = useCallback(async () => {
        const redirectEndpoint = await execute(GetOnboardingRedirect);
        if (isOK(redirectEndpoint)) {
            UserOnboardingDetails.clearUserOnboardingContext();
            navigate(redirectEndpoint.value);
        }
    }, [navigate]);

    const onCompleteOnboardingStep = useCallback(
        async (questionnaireData?: OnboardingPrefillQuestionnaireData) => {
            if (questionnaireData) {
                await execute(UpdateUserOnboardingQuestionnaireData, questionnaireData);
            }
            const userOnboadingEntrypoint = await execute(GetUserOnboardingEntrypoint);
            const nextOnboardingStep = isOK(userOnboadingEntrypoint)
                ? userOnboadingEntrypoint.value
                : null;

            if (nextOnboardingStep) {
                setUserOnboardingStepType(nextOnboardingStep);
            } else {
                onExitOnboardingFlow();
            }
        },
        [onExitOnboardingFlow],
    );

    return { onExitOnboardingFlow, onCompleteOnboardingStep, currentOnboardingStep };
}

export type ExitOnboardingFlowFunction = ReturnType<
    typeof useUserOnboardingNavigation
>['onExitOnboardingFlow'];

export type nextOnboardingStepFunction = ReturnType<
    typeof useUserOnboardingNavigation
>['onCompleteOnboardingStep'];
