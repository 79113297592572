import React from 'react';
import { InvoiceTable } from '@embroker/ui-toolkit/v2';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { USD } from '@embroker/shotwell/core/types/Money';

interface BundleQuoteComparisonContentCompontentProps {
    content: { title: string; value: number | string }[];
}

export const BundleQuoteComparisonContentComponent = ({
    content,
}: BundleQuoteComparisonContentCompontentProps) => {
    return (
        <InvoiceTable.Section>
            {content.map(({ title, value }) => (
                <InvoiceTable.Item key={title} textColor="ui-500" title={title}>
                    {typeof value === 'number' ? <MoneyDisplay value={USD(value * 100)} /> : value}
                </InvoiceTable.Item>
            ))}
        </InvoiceTable.Section>
    );
};
