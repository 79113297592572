import React, { useMemo } from 'react';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import { LocationFieldDefinition, LocationFieldSet } from '../LocationFieldSet.view';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { MailingAddress as MailingAddressType } from '../../../types/MailingAddress';
import { FormViewLayout } from '../../../../view/components/FormViewLayout.view';
import { hasEmptyRequiredField } from './SignUpPage';
import { execute } from '@embroker/shotwell/core/UseCase';
import { UpdateOrganizationProfile } from '../../../useCases/UpdateOrganizationProfile';
import { GetActiveOrganizationProfile } from '../../../useCases/GetActiveOrganizationProfile';
import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { OnboardingPageProps } from './OnboardingFlowWrapper';
import { PageLayout } from '@embroker/ui-toolkit/v2';

export interface OnboardingPageLayoutProps {
    children: React.ReactNode;
}

interface UserOnboardingFormData {
    headquarters: MailingAddressType;
}

const createPrimaryAddressForm = (onCompleteOnboardingStep: () => void) => {
    return createForm<UserOnboardingFormData>({
        fields: {
            headquarters: LocationFieldDefinition,
        },
        formatSubmitErrors(errors) {
            container
                .get<Logger>(Log)
                .warn(`User onboarding - Primary adress page formatSubmitErrors: ${errors}`);

            return ['Sorry, something went wrong. Please try again later.'];
        },
        submit: async ({ headquarters }) => {
            const getActiveOrganizationProfileResponse = await execute(
                GetActiveOrganizationProfile,
            );
            if (isOK(getActiveOrganizationProfileResponse)) {
                const { id } = getActiveOrganizationProfileResponse.value.organization;
                const emailAvailabilityResponse = await execute(UpdateOrganizationProfile, {
                    id,
                    headquarters,
                });
                onCompleteOnboardingStep();
                return emailAvailabilityResponse;
            }
            return getActiveOrganizationProfileResponse;
        },
    });
};

const initialHeadquarters: MailingAddressType = {
    addressLine1: null,
    addressLine2: null,
    city: null,
    state: null,
    zip: null,
};

export function PrimaryAddress({ onCompleteOnboardingStep }: OnboardingPageProps) {
    const PrimaryAddressForm = useMemo(
        () => createPrimaryAddressForm(onCompleteOnboardingStep),
        [onCompleteOnboardingStep],
    );
    const { fields, value, status, messages, submit, setValue, validate } = useForm(
        PrimaryAddressForm,
        {
            headquarters: initialHeadquarters,
        },
    );

    const handleHeadquartersChange = (headquarters: Partial<MailingAddressType>) => {
        setValue({
            ...value,
            headquarters: headquarters as MailingAddressType, // This value will be validated by the form on submission, typecasting to satisy TS lint.
        });
        if (status === 'invalid') {
            validate();
        }
    };

    const helperText = `Please provide a physical address and not a P.O. box, mailing address, or your registered agent's address. If the company is fully remote, enter the CEO's or Founder's address.`;
    const disableButton = hasEmptyRequiredField(value, ['addressLine2', 'county']);

    return (
        <PageLayout.Section>
            <FormViewLayout
                title="What's your main business location?"
                errorMessages={messages}
                helperText={helperText}
                disableSubmit={disableButton}
                onFormSubmit={submit}
                data-e2e-submit="user-org-public-submit-btn"
            >
                <LocationFieldSet
                    fieldValue={fields.headquarters.props.value}
                    messages={fields.headquarters.messages}
                    onChange={handleHeadquartersChange}
                />
            </FormViewLayout>
        </PageLayout.Section>
    );
}
