import React from 'react';
import { StatusMessage, Text } from '@embroker/ui-toolkit/v2';

interface EndorsementSubmittedStatusProps {
    policyName: string;
    policyNumber: string;
}

export const EndorsementSubmittedStatus = ({
    policyName,
    policyNumber,
}: EndorsementSubmittedStatusProps) => {
    return (
        <StatusMessage status="success">
            Your requested change to{' '}
            <Text as="span" color="brand-500">
                {policyName}, Policy no. {policyNumber}
            </Text>{' '}
            has been submitted. We will contact you after we've finished processing it
        </StatusMessage>
    );
};
