import { Money, USD } from '@embroker/shotwell/core/types/Money';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import {
    Collapsible,
    Icon,
    InvoiceTable,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React, { useState } from 'react';
import { BundleSummaryComponentProps } from '../../../coverageDefinition/coverageDefinition';
import { LawCyberQuote } from '../../../coverageDefinition/lawCyber/entities/LawCyberQuote';
import { CowbellCyberQuote } from '../../../coverageDefinition/cowbellCyber/entities/CowbellCyberQuote';
import { CoverageBreakdownList } from '../CoverageBreakdownList.view';

const TEXT_PRIMARY_COLOR = 'brand-400';

interface CyberInvoiceSummaryTableProps
    extends BundleSummaryComponentProps<LawCyberQuote | CowbellCyberQuote> {
    insurerName: string;
    feeTitleText?: string;
    taxesTitleText?: string;
}

export const CyberInvoiceSummaryTable: React.FC<CyberInvoiceSummaryTableProps> = ({
    insurerName,
    quote,
    feeTitleText = 'Fees',
    taxesTitleText = 'Surplus Lines Tax',
    questionnaireData,
}) => {
    const [showCoverageItems, setShowCoverageItems] = useState<boolean>(false);
    const caretName = !showCoverageItems ? 'caret-down' : 'caret-up';

    const lineItems: { label: string; value: number }[] = [
        { label: 'Limit', value: quote.options.limit },
        { label: 'Retention', value: quote.options.retention },
    ];

    const state = questionnaireData?.location_with_largest_number?.state ?? questionnaireData.state;

    return (
        <StackLayout gap="16">
            <InvoiceTable.Section>
                <InvoiceTable.Item
                    title={
                        <React.Fragment>
                            <Text
                                style="body 1"
                                color={TEXT_PRIMARY_COLOR}
                                as="span"
                                data-e2e="coverage-list-cyber-title"
                            >
                                Cyber Insurance
                                <TextButton
                                    as={Icon}
                                    name={caretName}
                                    onClick={() => setShowCoverageItems(!showCoverageItems)}
                                />
                            </Text>
                            <br />
                            <Text style="body 2" as="span">
                                {insurerName}
                            </Text>
                        </React.Fragment>
                    }
                >
                    <Text
                        style="body 1"
                        color={TEXT_PRIMARY_COLOR}
                        as="span"
                        data-e2e="cyber-base-premium"
                    >
                        <MoneyDisplay value={quote.details.basePremium} />
                    </Text>
                </InvoiceTable.Item>
                <Collapsible state={showCoverageItems ? 'open' : 'closed'}>
                    <CoverageBreakdownList coverageItems={lineItems} />
                </Collapsible>
                <InvoiceTable.Item />
                {quote.details.taxes && !Money.isEqual(quote.details.taxes, USD(0)) ? (
                    <InvoiceTable.Item
                        title={
                            <Text
                                style="body 1"
                                color={TEXT_PRIMARY_COLOR}
                                as="span"
                                data-e2e="surplus-line-taxes"
                            >
                                {taxesTitleText}
                            </Text>
                        }
                    >
                        <Text
                            style="body 1"
                            color={TEXT_PRIMARY_COLOR}
                            as="span"
                            data-e2e="cyber-taxes-value"
                        >
                            <MoneyDisplay value={quote.details.taxes} />
                        </Text>
                    </InvoiceTable.Item>
                ) : null}
                {quote.fees && !Money.isEqual(quote.fees, USD(0)) ? (
                    <InvoiceTable.Item
                        title={
                            <Text style="body 1" color={TEXT_PRIMARY_COLOR} as="span">
                                {feeTitleText}
                            </Text>
                        }
                    >
                        <Text
                            style="body 1"
                            color={TEXT_PRIMARY_COLOR}
                            as="span"
                            data-e2e="cyber-fees-value"
                        >
                            <MoneyDisplay value={quote.fees} />
                        </Text>
                    </InvoiceTable.Item>
                ) : null}
                {isLawCyberStateWithSpecialSurcharge(state) ? (
                    <InvoiceTable.Item
                        title={
                            <Text style="body 1" color="brand-500" as="span">
                                {getLawCyberStateWithSpecialSurchargeTitle(state)}
                            </Text>
                        }
                    >
                        <Text
                            style="body 1"
                            color="brand-500"
                            as="span"
                            data-e2e="cyber-special-surcharge"
                        >
                            <MoneyDisplay value={quote.details.specialSurcharge} />
                        </Text>
                    </InvoiceTable.Item>
                ) : null}
            </InvoiceTable.Section>
            <InvoiceTable.Item
                title={
                    <Text
                        style="heading 5"
                        color={TEXT_PRIMARY_COLOR}
                        as="span"
                        data-e2e="cyber-total"
                    >
                        Total Cyber Insurance
                    </Text>
                }
            >
                <Text
                    style="heading 5"
                    color={TEXT_PRIMARY_COLOR}
                    as="span"
                    data-e2e="cyber-total-value"
                >
                    <MoneyDisplay value={quote.totalPayable} />
                </Text>
            </InvoiceTable.Item>
        </StackLayout>
    );
};

const lawCyberStateWithSpecialSurchargeMap: { [key: string]: string } = {
    MS: 'Mississippi Windstorm Underwriting Fee',
    TN: 'Tennessee Clearinghouse Fee',
    SD: 'South Dakota Clearinghouse Fee',
    WY: 'Wyoming Clearinghouse Fee',
    OR: 'Fire Marshall Tax',
};

function isLawCyberStateWithSpecialSurcharge(state: string): boolean {
    return state in lawCyberStateWithSpecialSurchargeMap;
}

function getLawCyberStateWithSpecialSurchargeTitle(state: string): string {
    return lawCyberStateWithSpecialSurchargeMap[state];
}
