import { keysOf } from '@embroker/shotwell/core/object';
import { URI } from '@embroker/shotwell/core/types/URI';
import { FormDataType } from '@embroker/shotwell/view/hooks/useForm';
import {
    Button,
    CenterLayout,
    ColumnLayout,
    FloatingLayout,
    Icon,
    Loader,
    PageLayout,
    Stack,
    StackLayout,
    Text,
    TextButton,
    WizardLayout,
    useModal,
    useResponsive,
} from '@embroker/ui-toolkit/v2';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { GlobalConfig } from '../../../config/types/GlobalConfig';
import { Signature } from '../../../quote/types/Signature';
import { LeavingConfirmationModal } from '../../../quote/view/components/LeavingConfirmationModal';
import { AppContext } from '../../../view/AppContext';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { WizardPageDefinition, useWizardForm } from '../../../view/hooks/useWizardForm';
import { CoverageCatalog } from '../../CoverageCatalog';
import { BundleQuote } from '../../entities/BundleQuote';
import { BundleCoverageType, BundleWarningStatus } from '../../types/BundleQuoteCoverage';
import { DocumentType } from '../../types/BundleQuoteDocument';
import { BundleQuoteFormData } from '../../types/BundleQuoteFormData';
import { BundleCards } from './BundleQuoteCards';
import { BundleQuoteCoverageListProps, BundleQuoteCoverageList } from './BundleQuoteCoverageList';
import { BundleQuoteFooter } from './BundleQuoteFooter';
import { BundleQuoteSignature } from './BundleQuoteSignature';
import { DocumentModal } from './DocumentModal';
import { buildBundleDocumentList } from './buildBundleDocumentList';
import { createBundleQuoteForm } from './createBundleQuoteForm';
import { BundleHeroBanner } from './BundleHeroBanner.view';
import { execute } from '@embroker/shotwell/core/UseCase';
import { PublishBundleUserClickEvent } from '../../useCases/PublishBundleUserClickEvent';
import { Application } from '../../../shopping/entities/Application';
import { differenceInDays } from '@embroker/service-app-engine';
import { startOfToday } from 'date-fns';

export const MAX_APPLICATION_DAYS = 90;

export type Documents = {
    url: URI;
    documentType: DocumentType;
    coverageType: BundleCoverageType;
}[];

export interface DocumentsItemComponentProp {
    isSubmitting: boolean;
    isDisabled?: boolean;
    fileUrl?: URI;
    documentType: DocumentType;
    handleTrigger: () => void;
    coverageType?: BundleCoverageType;
}

export type DocumentsItemComponent = React.FC<DocumentsItemComponentProp>;

export interface DocumentsItem {
    component: DocumentsItemComponent;
    isDisabled?: boolean;
    fileUrl?: URI;
    documentType: DocumentType;
    handleTrigger: () => void;
    coverageType?: BundleCoverageType;
}

export interface Page<T extends BundleQuoteFormData> {
    name: string;
    component: React.FC<BundleQuoteCoverageListProps<T>>;
}

export interface BundleQuoteLandingPageProps {
    initialBundleQuote: BundleQuote;
    globalConfig: GlobalConfig;
    application: Application;
}

export interface BundleQuoteLandingPageState {
    bundleQuote: BundleQuote;
    lastGeneratedDocumentUrl?: URI;
    lastGeneratedDocumentType?: DocumentType;
    lastGeneratedDocumentCoverageType?: BundleCoverageType;
    documents: Documents;
    warnings: BundleWarningStatus[];
}

export const BundleQuoteLandingPage = ({
    initialBundleQuote,
    globalConfig,
    application,
}: BundleQuoteLandingPageProps) => {
    const [state, setState] = useState<BundleQuoteLandingPageState>({
        bundleQuote: initialBundleQuote,
        lastGeneratedDocumentUrl: undefined,
        lastGeneratedDocumentType: undefined,
        lastGeneratedDocumentCoverageType: undefined,
        documents: [],
        warnings: [],
    });

    const [signature, setSignature] = useState<Signature>({
        transfers: false,
        warranty: false,
        applicationAttestation: false,
        applicationNoKnownLossesAttestation: false,
    });

    const { navigate } = useNavigation();
    const documentModal = useModal();
    const leavingConfirmationModal = useModal();

    const abortController = useMemo(() => {
        return new AbortController();
    }, []);

    const isBundleQuoteBindable = state.bundleQuote.isBundleQuoteBindable();
    const differenceBetweenTodayAndApplicationCreationDate = differenceInDays(
        startOfToday(),
        application.submittedDate as Date,
    );
    const appHasExpired =
        state.bundleQuote.isAnyCoverageAppExpired() &&
        differenceBetweenTodayAndApplicationCreationDate > MAX_APPLICATION_DAYS;

    const quoteDaysToExpire = appHasExpired
        ? undefined
        : state.bundleQuote.getQuoteValidityInDays();
    const quoteHasExpired = quoteDaysToExpire !== undefined && quoteDaysToExpire < 0;

    const handleQuoteUpdate = useCallback(
        (newBundleQuote: BundleQuote, warnings: BundleWarningStatus[]) => {
            setState((prevState: BundleQuoteLandingPageState) => ({
                ...prevState,
                bundleQuote: newBundleQuote,
                documents: [],
                warnings: warnings,
            }));
        },
        [],
    );

    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, [abortController]);

    const handleDocumentUrl = useCallback(
        (url: URI, documentType: DocumentType, bundleCoverageType: BundleCoverageType) => {
            setState((prevState: BundleQuoteLandingPageState) => ({
                ...prevState,
                lastGeneratedDocumentUrl: url,
                lastGeneratedDocumentType: documentType,
                lastGeneratedDocumentCoverageType: bundleCoverageType,
                documents: [
                    ...prevState.documents,
                    { url, documentType, coverageType: bundleCoverageType },
                ],
            }));
        },
        [],
    );
    const { setSlideout, closeSlideout } = useContext(AppContext);

    function renderHeroBannerTitle(
        bundleQuote: BundleQuote,
        activePageName: string,
    ): React.ReactNode {
        if (bundleQuote.isAnyCoverageReferredAwaitingReview()) {
            return 'Your Estimated Premium';
        }

        if (globalConfig.newBundleFlowEnabled) {
            if (!bundleQuote.isBundleQuoteBindable()) {
                return 'Your Estimated Premium';
            }
        } else {
            if (bundleQuote.isAnyCoverageReferred() || bundleQuote.isAnyCoverageIndication()) {
                return 'Your Estimated Premium';
            }
        }

        if (activePageName === 'coverage') {
            return 'Customize your quote';
        }
        if (activePageName === 'signature') {
            return 'Review your final details';
        }

        return 'Your Customizable Quote';
    }

    function renderHeroBannerSubtitle(activePageName: string): React.ReactNode {
        if (activePageName === 'coverage') {
            return "Set your policy's effective start date and make any adjustments before securing your business protection.";
        } else {
            return undefined;
        }
    }

    const bundleQuoteForm = useMemo(
        () =>
            createBundleQuoteForm({
                closeSlideout: closeSlideout,
                bundleQuote: state.bundleQuote,
                abortSignal: abortController.signal,
                handleQuoteUpdate: handleQuoteUpdate,
                handleSetSignature: setSignature,
                navigate,
                setDocumentUrl: handleDocumentUrl,
                onGenerateDocument: documentModal.show,
            }),
        [
            closeSlideout,
            state.bundleQuote,
            abortController.signal,
            handleQuoteUpdate,
            setSignature,
            navigate,
            handleDocumentUrl,
            documentModal.show,
        ],
    );

    const initialValue: FormDataType<typeof bundleQuoteForm> = {
        ...CoverageCatalog.getAllProductInitialValues(state.bundleQuote),
        effectiveDate: state.bundleQuote.effectiveDate,
        agreementToConductSignature: signature.transfers,
        warrantyAndFraudSignature: signature.warranty,
        applicationAttestationSignature: signature.applicationAttestation ?? false,
        applicationNoKnownLossesAttestation: signature.applicationNoKnownLossesAttestation ?? false,
    };

    const productFormFields = CoverageCatalog.getSelectedProductFormFields(state.bundleQuote);
    const productFormFieldKeys = keysOf(productFormFields);
    const quotePage: WizardPageDefinition<typeof bundleQuoteForm> = {
        name: 'coverage',
        fields: ['effectiveDate', ...productFormFieldKeys],
    };
    const signPage: WizardPageDefinition<typeof bundleQuoteForm> = {
        name: 'signature',
        fields: [
            'agreementToConductSignature',
            'warrantyAndFraudSignature',
            'applicationAttestationSignature',
            'applicationNoKnownLossesAttestation',
        ],
    };
    const formPages = [quotePage, signPage];
    const optionsWizardForm = useWizardForm(bundleQuoteForm, {
        pages: formPages,
        initialValue: initialValue,
    });

    const {
        reset,
        hasPrevious,
        setValue,
        value,
        next,
        previous,
        activePageIndex,
        trigger,
        fields,
        messages,
        submit,
        status,
        activePageName,
    } = optionsWizardForm;

    const isFormDirty = status === 'dirty';
    const isNonProductFormFieldsDirty = keysOf(fields)
        .filter((fieldKey) => !productFormFieldKeys.includes(fieldKey))
        .map((fieldKey) => fields[fieldKey])
        .some((field) => field?.status === 'dirty');

    const isSubmitting = status === 'submitting';

    const quoteStepPage: Page<typeof value> = {
        name: 'coverage',
        component: BundleQuoteCoverageList,
    };
    const quoteSignPage: Page<typeof value> = {
        name: 'signature',
        component: BundleQuoteSignature,
    };
    const pages: Page<typeof value>[] = isBundleQuoteBindable
        ? [quoteStepPage, quoteSignPage]
        : [quoteStepPage];

    const handleDateChange = (event: { target: { value: string; date: Date } }) => {
        setValue({
            ...value,
            effectiveDate: event.target.date,
        });
    };

    const handleCoverageSelectToggle = (checked: boolean, coverageType: BundleCoverageType) => {
        setValue({ ...value, ...CoverageCatalog.toggleCoverage(coverageType, checked) });
        trigger('updateSelected');
    };

    const handleSubmitReferredQuote = () => {
        execute(PublishBundleUserClickEvent, {
            clickEventName: 'Referral Submit For Review Clicked',
        });
        trigger('requestReview');
        return;
    };

    const bundleDocumentList: DocumentsItem[] = buildBundleDocumentList({
        bundleQuote: state.bundleQuote,
        documents: state.documents,
        trigger,
        isDirty: isFormDirty,
        value,
        reset,
    });

    const handleLeaving = useCallback(() => {
        leavingConfirmationModal.show();
    }, [leavingConfirmationModal]);

    const handleConfirmLeaving = useCallback(() => {
        leavingConfirmationModal.hide();
        navigate('/');
    }, [leavingConfirmationModal, navigate]);

    const cards = (
        <BundleCards
            reset={reset}
            formValue={value}
            bundleQuote={state.bundleQuote}
            status={status}
            hasPrevious={hasPrevious}
            effectiveDate={value.effectiveDate}
            next={next}
            fields={fields}
            trigger={trigger}
            bundleDocumentList={bundleDocumentList}
            onDateChange={handleDateChange}
            newFlowEnabled={globalConfig.newBundleFlowEnabled}
            warnings={state.warnings}
            handleSubmitForReview={handleSubmitReferredQuote}
            appHasExpired={appHasExpired}
        />
    );

    const handleShowBundleSlideoutClick = () => {
        setSlideout(
            <WizardLayout>
                <StackLayout gap="24">
                    <TextButton
                        onClick={() => setSlideout(null)}
                        icon="light-caret-left"
                        iconPosition="left"
                    >
                        Back
                    </TextButton>
                    {cards}
                </StackLayout>
            </WizardLayout>,
        );
    };

    // Manual responsive rule for conditional display until we can further investigate a siscint pattern to provide this functionality through the toolkit library.
    // Reference: https://embroker.atlassian.net/browse/EM-29870
    const isMobileView = useResponsive({ screenWidth: { smallerThan: 'large-tablet' } });

    // In responsive mode, close slideout if growing screen
    useEffect(() => {
        setSlideout(null);
    }, [setSlideout, isMobileView]);

    // On change of Stack view, reset slideout
    useEffect(() => {
        setSlideout(null);
    }, [setSlideout, activePageIndex]);

    const showCardSlideout = isMobileView && !hasPrevious;
    const showFooter = !hasPrevious;

    return (
        <React.Fragment>
            <BundleHeroBanner
                onDismiss={handleLeaving}
                title={renderHeroBannerTitle(state.bundleQuote, activePageName)}
                subtitle={renderHeroBannerSubtitle(activePageName)}
            />
            <PageLayout.Section>
                <StackLayout gap={showCardSlideout ? '64' : 'none'}>
                    <ColumnLayout
                        top
                        gap="24"
                        responsive={{ containerWidth: { smallerThan: 588 } }}
                    >
                        <DocumentModal
                            modal={documentModal}
                            fileUrl={state.lastGeneratedDocumentUrl}
                            documentType={state.lastGeneratedDocumentType}
                            coverageType={state.lastGeneratedDocumentCoverageType}
                        />
                        <LeavingConfirmationModal
                            modal={leavingConfirmationModal}
                            onConfirmLeaving={handleConfirmLeaving}
                        />
                        {isSubmitting ? (
                            <Loader
                                heading={<Text style="heading 4">Finalizing your request</Text>}
                            />
                        ) : null}
                        <StackLayout gap="56" className="u-1/1">
                            <Stack activeIndex={activePageIndex}>
                                {pages.map(({ component: Component }, index) => (
                                    <div key={index}>
                                        <Component
                                            documents={state.documents}
                                            bundleQuote={state.bundleQuote}
                                            fields={fields}
                                            handleCoverageSelectToggle={handleCoverageSelectToggle}
                                            setValue={setValue}
                                            value={value}
                                            quoteHasExpired={quoteHasExpired}
                                            appHasExpired={appHasExpired}
                                            trigger={trigger}
                                            submit={submit}
                                            hasPrevious={hasPrevious}
                                            previous={previous}
                                            messages={messages}
                                            newFlowEnabled={globalConfig.newBundleFlowEnabled}
                                            isDirty={isNonProductFormFieldsDirty}
                                        />
                                    </div>
                                ))}
                            </Stack>
                            {showFooter ? (
                                <BundleQuoteFooter bundleQuote={state.bundleQuote} />
                            ) : null}
                        </StackLayout>
                        {!showCardSlideout && (
                            <StackLayout gap="24" className="u-1/1 u-3/6@tablet">
                                {cards}
                            </StackLayout>
                        )}
                    </ColumnLayout>

                    <div />
                </StackLayout>
            </PageLayout.Section>
            {showCardSlideout && (
                <FloatingLayout>
                    <CenterLayout>
                        <Button onClick={handleShowBundleSlideoutClick}>
                            <ColumnLayout>
                                <Text style="label 1" color="ui-50" data-e2e="view-quote">
                                    View Quote
                                </Text>
                                <Icon size="medium" name="light-caret-right" color="ui-50" />
                            </ColumnLayout>
                        </Button>
                    </CenterLayout>
                </FloatingLayout>
            )}
        </React.Fragment>
    );
};
