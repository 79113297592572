import { Immutable } from '@embroker/shotwell/core/types';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { ESPCoverageRateItem } from './ESPRate';

export interface ESPDoEpliCoverageQuoteOptionsItem {
    readonly coverageType: 'do' | 'epli';
    readonly selected: boolean;
    readonly limit: number;
    readonly retention: number;
    readonly level?: 'standard' | 'plus';
}

export const ESPDoEpliCoverageQuoteOptionsItemSchema = Joi.object({
    coverageType: Joi.string().valid('do', 'epli').required(),
    selected: Joi.bool().required(),
    limit: Joi.number().valid(1000000, 2000000, 3000000, 4000000, 5000000).required(),
    retention: Joi.number().positive().integer().required(),
    level: Joi.string().valid('standard', 'plus').optional(),
});

export interface ESPFiduciaryCoverageQuoteOptionsItem {
    readonly coverageType: 'fiduciary';
    readonly selected: boolean;
    readonly limit: 1000000;
    readonly retention: 0;
    readonly level?: undefined;
}

export const ESPFiduciaryCoverageQuoteOptionsItemSchema = Joi.object({
    coverageType: Joi.string().valid('fiduciary').required(),
    selected: Joi.bool().required(),
    limit: Joi.number().valid(1000000).required(),
    retention: Joi.number().valid(0).required(),
});

export interface ESPEoCyberCoverageQuoteOptionsItem {
    readonly coverageType: 'eoCyber';
    readonly selected: boolean;
    readonly limit: number;
    readonly retention: number;
    readonly level: 'standard' | 'plus';
}

export const ESPEoCyberCoverageQuoteOptionsItemSchema = Joi.object({
    coverageType: Joi.string().valid('eoCyber').required(),
    selected: Joi.bool().required(),
    limit: Joi.number().valid(1000000, 2000000, 3000000, 4000000, 5000000).required(),
    retention: Joi.number().valid(2500, 5000, 10000, 25000, 50000, 75000, 100000).required(),
    level: Joi.string().valid('standard', 'plus').required(),
});

export interface ESPTechCyberCoverageQuoteOptionsItem {
    readonly coverageType: 'techCyber';
    readonly selected: boolean;
    readonly level: 'standard' | 'plus';
    techCoverageQuoteOptionsItem: ESPTechCoverageQuoteOptionsItem;
    cyberCoverageQuoteOptionsItem: ESPCyberCoverageQuoteOptionsItem;
}

export interface ESPTechCoverageQuoteOptionsItem {
    readonly limit?: number;
    readonly retention?: number;
}

export const ESPTechCoverageQuoteOptionsItemSchema = Joi.object({
    limit: Joi.number().valid(1000000, 2000000, 3000000, 4000000, 5000000).optional(),
    retention: Joi.number().valid(2500, 5000, 10000, 25000, 50000, 75000, 100000).optional(),
});

export interface ESPCyberCoverageQuoteOptionsItem {
    readonly limit?: number;
    readonly retention?: number;
}

export const ESPCyberCoverageQuoteOptionsItemSchema = Joi.object({
    limit: Joi.number().valid(0, 1000000, 2000000, 3000000, 4000000, 5000000).optional(),
    retention: Joi.number().valid(2500, 5000, 10000, 25000, 50000, 75000, 100000).optional(),
});

export const ESPCyberCoverageQuoteOptionsItem = {
    ...defineValidator<ESPCyberCoverageQuoteOptionsItem>({
        limit: Joi.number().valid(0, 1000000, 2000000, 3000000, 4000000, 5000000).optional(),
        retention: Joi.number().valid(2500, 5000, 10000, 25000, 50000, 75000, 100000).optional(),
    }),
    create(cyberCoverageOptionsItem: ESPCyberCoverageQuoteOptionsItem) {
        return ESPCyberCoverageQuoteOptionsItemSchema.validate(cyberCoverageOptionsItem);
    },
};

export const ESPTechCoverageQuoteOptionsItem = {
    ...defineValidator<ESPTechCoverageQuoteOptionsItem>({
        limit: Joi.number().valid(1000000, 2000000, 3000000, 4000000, 5000000).optional(),
        retention: Joi.number().valid(2500, 5000, 10000, 25000, 50000, 75000, 100000).optional(),
    }),
    create(techCoverageOptionsItem: ESPTechCoverageQuoteOptionsItem) {
        return ESPTechCoverageQuoteOptionsItem.validate(techCoverageOptionsItem);
    },
};

export const ESPTechCyberCoverageQuoteOptionsItemSchema = Joi.object({
    coverageType: Joi.string().valid('techCyber').required(),
    selected: Joi.bool().required(),
    level: Joi.string().valid('standard', 'plus').required(),
    techCoverageQuoteOptionsItem: ESPTechCoverageQuoteOptionsItem.schema,
    cyberCoverageQuoteOptionsItem: ESPCyberCoverageQuoteOptionsItem.schema,
});

export type ESPCoverageQuoteOptionsItem =
    | ESPDoEpliCoverageQuoteOptionsItem
    | ESPFiduciaryCoverageQuoteOptionsItem
    | ESPEoCyberCoverageQuoteOptionsItem
    | ESPTechCyberCoverageQuoteOptionsItem;

export interface ESPQuoteOptions {
    readonly effectiveDate: Date;
    readonly coverages: Immutable<Array<ESPCoverageQuoteOptionsItem>>;
}

export const ESPQuoteOptions = {
    ...defineValidator<ESPQuoteOptions>({
        effectiveDate: Joi.date().required(),
        coverages: Joi.array()
            .unique((a: ESPCoverageRateItem, b: ESPCoverageRateItem): boolean => {
                return a?.coverageType === b?.coverageType;
            })
            .items(
                ESPDoEpliCoverageQuoteOptionsItemSchema,
                ESPFiduciaryCoverageQuoteOptionsItemSchema,
                ESPEoCyberCoverageQuoteOptionsItemSchema,
                ESPTechCyberCoverageQuoteOptionsItemSchema,
            )
            .min(1)
            .max(5),
    }),
    create(espQuoteOptions: ESPQuoteOptions) {
        return ESPQuoteOptions.validate(espQuoteOptions);
    },
};
