import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Nullable } from '@embroker/shotwell/core/types';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { PolicyFilterRepository } from '../../policy/repositories/PolicyFilterRepository';
import { isAuthenticated } from '../entities/Session';
import { InactiveAccount, WrongUsernamePasswordPair } from '../errors';
import { SessionRepository } from '../repositories/SessionRepository';
import { isReturnUserFunnelNBRVOneEnabled } from './GetReturnUserLoginRedirect';
import { SetNBRVFunnelStatus } from './SetNBRVFunnelStatus';

/**
 * Request data for login use case
 * @param username represents user's name used to uniquely identify the user. Currently we use email for this.
 * @param password is user's password
 */
export interface LoginRequest {
    username: string;
    password: string;
}

/**
 * Response data for login use case
 */
export interface LoginResponse {
    userId: UUID;
    organizationId: Nullable<UUID>;
}
/**
 * Login use case is used to authenticate user to the platform
 */
export interface Login extends UseCase {
    execute(
        request: LoginRequest,
    ): AsyncResult<
        LoginResponse,
        InvalidArgument | OperationFailed | WrongUsernamePasswordPair | InactiveAccount
    >;
}

@injectable()
class LoginUseCase extends UseCase implements Login {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/Login');
    /**
     * Constructor for Login use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param sessionRepository
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(SessionRepository) private sessionRepository: SessionRepository,
        @inject(PolicyFilterRepository) private policyFilterRepository: PolicyFilterRepository,
        @inject(SetNBRVFunnelStatus.type) private setNBRVFunnelStatus: SetNBRVFunnelStatus,
    ) {
        super(eventBus);
    }

    /**
     * Executes Login use case
     * Input is of LoginRequest type
     * @returns data of LoginResponse type if execution was successful
     * @returns InvalidArgument if provided username did not match email of any user on the platform.
     * @returns InvalidArgument if provided user's password was incorrect
     */
    public async execute(
        request: LoginRequest,
    ): AsyncResult<
        LoginResponse,
        InvalidArgument | OperationFailed | WrongUsernamePasswordPair | InactiveAccount
    > {
        const result = await this.sessionRepository.create(request);

        if (isErr(result)) {
            return result;
        }

        const session = result.value;

        if (!isAuthenticated(session)) {
            return Failure(InvalidArgument({ argument: 'request', value: request }));
        }

        // reset policy filter to default
        const defaultFilter = this.policyFilterRepository.getDefault();
        this.policyFilterRepository.save(defaultFilter);

        session.onLoginByUser();

        await this.eventBus.publishEntityEvents(session);

        if (isReturnUserFunnelNBRVOneEnabled()) {
            await this.setNBRVFunnelStatus.execute({
                shown: false,
                forceShow: true, // ensures that the funnel is triggered on next check
                initialSessionId: session.id,
            });
        }

        return Success<LoginResponse>({
            userId: session.userId,
            organizationId: session.organizationId,
        });
    }
}

export const Login: UseCaseClass<Login> = LoginUseCase;
