import {
    Aborted,
    InvalidArgument,
    OperationFailed,
    Timeout,
    UnknownEntity,
} from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { cast } from '@embroker/shotwell/core/types/Nominal';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { inject } from '@embroker/shotwell/core/di';
import { GetDocumentUrl } from '../../../documents/useCases/GetDocumentUrl';
import { UpdateQuote } from './UpdateQuote';
import { ESPRenewalQuote } from '../entities/ESPRenewalQuote';
import { ESPRenewalQuoteOptions } from '../types/ESPRenewalQuoteOptions';
import { UseCaseClass, UseCase } from '@embroker/shotwell/core/UseCase';
import { ESPRenewalConfigFetchFailed } from '../errors';
import { ApplicationFetchFailed, QuestionnaireParsingFailed } from '../../errors';

export interface DownloadESPRenewalQuoteDocumentRequest {
    quote: ESPRenewalQuote;
    quoteOptions: ESPRenewalQuoteOptions;
    abortSignal: AbortSignal;
}

export interface DownloadESPRenewalQuoteDocumentResponse {
    documentUrl: URI;
}

export interface DownloadESPRenewalQuoteDocument extends UseCase {
    execute(
        request: DownloadESPRenewalQuoteDocumentRequest,
    ): AsyncResult<
        DownloadESPRenewalQuoteDocumentResponse,
        | UnknownEntity
        | InvalidArgument
        | OperationFailed
        | ESPRenewalConfigFetchFailed
        | QuestionnaireParsingFailed
        | ApplicationFetchFailed
        | Timeout
        | Aborted
    >;
}

class DownloadESPRenewalQuoteDocumentUseCase
    extends UseCase
    implements DownloadESPRenewalQuoteDocument
{
    public static type = Symbol('ESPRenewalQuote/GetQuoteFileUrl');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(UpdateQuote.type) private updateQuote: UpdateQuote,
        @inject(GetDocumentUrl.type) private getDocumentUrl: GetDocumentUrl,
    ) {
        super(eventBus);
    }

    public async execute({
        quote,
        quoteOptions,
        abortSignal,
    }: DownloadESPRenewalQuoteDocumentRequest): AsyncResult<
        DownloadESPRenewalQuoteDocumentResponse,
        | UnknownEntity
        | InvalidArgument
        | OperationFailed
        | ESPRenewalConfigFetchFailed
        | QuestionnaireParsingFailed
        | ApplicationFetchFailed
        | Timeout
        | Aborted
    > {
        let fileKey = quote.fileKey;
        if (!fileKey) {
            const updateQuoteResult = await this.updateQuote.execute({
                quote: quote,
                quoteOptions: quoteOptions,
                abortSignal,
            });
            if (isErr(updateQuoteResult)) {
                return updateQuoteResult;
            }
            fileKey = updateQuoteResult.value.quote.fileKey;
        }

        const getDocumentUrlResult = await this.getDocumentUrl.execute({
            fileKey: fileKey ?? '',
        });

        if (isErr(getDocumentUrlResult)) {
            return getDocumentUrlResult;
        }

        return Success<DownloadESPRenewalQuoteDocumentResponse>({
            documentUrl: cast<URI>(getDocumentUrlResult.value.downloadUrl),
        });
    }
}

export const DownloadESPRenewalQuoteDocument: UseCaseClass<DownloadESPRenewalQuoteDocument> =
    DownloadESPRenewalQuoteDocumentUseCase;
