import { ContainerModule } from '@embroker/shotwell/core/di';
import { Module } from '../Module';
import { GetUserOnboardingQuestionnaireData } from '../userOrg/useCases/GetUserOnboardingQuestionnaireData';
import { ApplicantRepository } from './repositories/ApplicantRepository';
import { APIApplicantRepository } from './repositories/ApplicantRepository/APIApplicantRepository';
import { ApplicationRepository } from './repositories/ApplicationRepository';
import { APIApplicationRepository } from './repositories/ApplicationRepository/APIApplicationRepository';
import { CoverageRepository } from './repositories/CoverageRepository';
import { APICoverageRepository } from './repositories/CoverageRepository/APICoverageRepository';
import { IndicativePremiumRepository } from './repositories/IndicativePremiumRepository';
import { APIIndicativePremiumRepository } from './repositories/IndicativePremiumRepository/APIIndicativePremiumRepository';
import { InvestorsRepository } from './repositories/InvestorsRepository';
import { APIInvestorsRepository } from './repositories/InvestorsRepository/APIInvestorsRepository';
import { QuestionnaireRepository } from './repositories/QuestionnaireRepository';
import { APIQuestionnaireRepository } from './repositories/QuestionnaireRepository/APIQuestionnaireRepository';
import { WCDataRepository } from './repositories/WCDataRepository';
import { APIWCDataRepository } from './repositories/WCDataRepository/APIWCDataRepository';
import { CheckApplicationPrintStatus } from './useCases/CheckApplicationPrintStatus';
import { CreateApplicationIntake } from './useCases/CreateApplicationIntake';
import { CreateApplications } from './useCases/CreateApplications';
import { CreateLawBundleApplication } from './useCases/CreateLawBundleApplication';
import { CreateMplBundleApplication } from './useCases/CreateMplBundleApplication';
import { CreateWCEmployeeDataTable } from './useCases/CreateWCEmployeeDataTable';
import { DeleteApplication } from './useCases/DeleteApplication';
import { GetAllCoverages } from './useCases/GetAllCoverages';
import { GetApplicant } from './useCases/GetApplicant';
import { GetApplication } from './useCases/GetApplication';
import { GetQuoteSummaryByPaymentIds } from './useCases/GetQuoteSummaryByPaymentIds';
import { GetApplicationCreationQuestionnaire } from './useCases/GetApplicationCreationQuestionnaire';
import { GetApplicationIntakeTaskStatus } from './useCases/GetApplicationIntakeTaskStatus';
import { GetApplicationLastPage } from './useCases/GetApplicationLastPage';
import { GetApplicationList } from './useCases/GetApplicationList';
import { GetApplicationQuestionnaire } from './useCases/GetApplicationQuestionnaire';
import { GetApplicationShareToken } from './useCases/GetApplicationShareToken';
import { GetApplicationStatus } from './useCases/GetApplicationStatus';
import { GetFormEngineContext } from './useCases/GetFormEngineContext';
import { GetFormEngineInstance } from './useCases/GetFormEngineInstance';
import { GetIndicativePremiumList } from './useCases/GetIndicativePremiumList';
import { GetLawBundleApplications } from './useCases/GetLawBundleApplications';
import { GetLawBundlePrefill } from './useCases/GetLawBundlePrefill';
import { GetPartialEligibility } from './useCases/GetPartialEligibility';
import { GetSubmittedApplication } from './useCases/GetSubmittedApplication';
import { GetTaskStatus } from './useCases/GetTaskStatus';
import { OptOutFromStreamlineRenewal } from './useCases/OptOutFromStreamlineRenewal';
import { OverrideQuestionnaireData } from './useCases/OverrideQuestionnaireData';
import { PrintBinder } from './useCases/PrintBinder';
import { PrintStartedApplication } from './useCases/PrintStartedApplication';
import { PublishFormEvents } from './useCases/PublishFormEvents';
import { PublishPurchaseIntentEvent } from './useCases/PublishPurchaseIntentEvent';
import { PublishShoppingUserClickEvent } from './useCases/PublishShoppingUserClickEvent';
import { SaveApplicationLastPage } from './useCases/SaveApplicationLastPage';
import { StartApplication } from './useCases/StartApplication';
import { StartApplicationIsRenewal } from './useCases/StartApplicationIsRenewal';
import { SubmitApplication } from './useCases/SubmitApplication';
import { SubmitStreamlineRenewal } from './useCases/SubmitStreamlineRenewal';
import { UpdateApplicantFromBusinessProfile } from './useCases/UpdateApplicantFromBusinessProfile';
import { UpdateApplicantFromTier1 } from './useCases/UpdateApplicantFromTier1';
import { UpdateApplicantInfo } from './useCases/UpdateApplicantInfo';
import { UpdateApplicationQuestionnaire } from './useCases/UpdateApplicationQuestionnaire';
import { UpdateApplicationQuestionnaireFromTier1 } from './useCases/UpdateApplicationQuestionnaireFromTier1';
import { RecreateRenewal } from './useCases/RecreateRenewal';
import { routes } from './view/routes';
import { GetInvestors } from './useCases/GetInvestors';

export const ShoppingModule: Module = {
    routes,
    container: new ContainerModule((bind) => {
        bind<InvestorsRepository>(InvestorsRepository)
            .to(APIInvestorsRepository)
            .inSingletonScope();
        bind<WCDataRepository>(WCDataRepository).to(APIWCDataRepository).inSingletonScope();
        bind<ApplicantRepository>(ApplicantRepository)
            .to(APIApplicantRepository)
            .inSingletonScope();
        bind<GetApplicant>(GetApplicant.type).to(GetApplicant).inSingletonScope();
        bind<UpdateApplicantInfo>(UpdateApplicantInfo.type)
            .to(UpdateApplicantInfo)
            .inSingletonScope();
        bind<ApplicationRepository>(ApplicationRepository)
            .to(APIApplicationRepository)
            .inSingletonScope();
        bind<GetApplicationList>(GetApplicationList.type).to(GetApplicationList).inSingletonScope();
        bind<GetQuoteSummaryByPaymentIds>(GetQuoteSummaryByPaymentIds.type)
            .to(GetQuoteSummaryByPaymentIds)
            .inSingletonScope();
        bind<StartApplication>(StartApplication.type).to(StartApplication).inSingletonScope();
        bind<StartApplicationIsRenewal>(StartApplicationIsRenewal.type)
            .to(StartApplicationIsRenewal)
            .inSingletonScope();
        bind<GetLawBundleApplications>(GetLawBundleApplications.type)
            .to(GetLawBundleApplications)
            .inSingletonScope();
        bind<DeleteApplication>(DeleteApplication.type).to(DeleteApplication).inSingletonScope();
        bind<GetSubmittedApplication>(GetSubmittedApplication.type)
            .to(GetSubmittedApplication)
            .inSingletonScope();
        bind<CoverageRepository>(CoverageRepository).to(APICoverageRepository).inSingletonScope();
        bind<OverrideQuestionnaireData>(OverrideQuestionnaireData.type)
            .to(OverrideQuestionnaireData)
            .inSingletonScope();
        bind<GetApplicationCreationQuestionnaire>(GetApplicationCreationQuestionnaire.type)
            .to(GetApplicationCreationQuestionnaire)
            .inSingletonScope();
        bind<GetApplicationQuestionnaire>(GetApplicationQuestionnaire.type)
            .to(GetApplicationQuestionnaire)
            .inSingletonScope();
        bind<GetFormEngineContext>(GetFormEngineContext.type)
            .to(GetFormEngineContext)
            .inSingletonScope();
        bind<CreateApplications>(CreateApplications.type).to(CreateApplications).inSingletonScope();
        bind<UpdateApplicantFromBusinessProfile>(UpdateApplicantFromBusinessProfile.type)
            .to(UpdateApplicantFromBusinessProfile)
            .inSingletonScope();
        bind<CreateWCEmployeeDataTable>(CreateWCEmployeeDataTable.type)
            .to(CreateWCEmployeeDataTable)
            .inSingletonScope();
        bind<GetAllCoverages>(GetAllCoverages.type).to(GetAllCoverages).inSingletonScope();
        bind<SubmitApplication>(SubmitApplication.type).to(SubmitApplication).inSingletonScope();
        bind<GetTaskStatus>(GetTaskStatus.type).to(GetTaskStatus).inSingletonScope();
        bind<UpdateApplicationQuestionnaire>(UpdateApplicationQuestionnaire.type)
            .to(UpdateApplicationQuestionnaire)
            .inSingletonScope();
        bind<CheckApplicationPrintStatus>(CheckApplicationPrintStatus.type)
            .to(CheckApplicationPrintStatus)
            .inSingletonScope();
        bind<PrintStartedApplication>(PrintStartedApplication.type)
            .to(PrintStartedApplication)
            .inSingletonScope();
        bind<PrintBinder>(PrintBinder.type).to(PrintBinder).inSingletonScope();
        bind<GetApplicationLastPage>(GetApplicationLastPage.type)
            .to(GetApplicationLastPage)
            .inSingletonScope();
        bind<SaveApplicationLastPage>(SaveApplicationLastPage.type)
            .to(SaveApplicationLastPage)
            .inSingletonScope();
        bind<GetApplicationStatus>(GetApplicationStatus.type).to(GetApplicationStatus);
        bind<GetFormEngineInstance>(GetFormEngineInstance.type)
            .to(GetFormEngineInstance)
            .inSingletonScope();
        bind<GetUserOnboardingQuestionnaireData>(GetUserOnboardingQuestionnaireData.type)
            .to(GetUserOnboardingQuestionnaireData)
            .inSingletonScope();
        bind<GetApplication>(GetApplication.type).to(GetApplication).inSingletonScope();
        bind<CreateApplicationIntake>(CreateApplicationIntake.type)
            .to(CreateApplicationIntake)
            .inSingletonScope();
        bind<GetApplicationIntakeTaskStatus>(GetApplicationIntakeTaskStatus.type)
            .to(GetApplicationIntakeTaskStatus)
            .inSingletonScope();
        bind<GetApplicationShareToken>(GetApplicationShareToken.type)
            .to(GetApplicationShareToken)
            .inSingletonScope();
        bind<PublishPurchaseIntentEvent>(PublishPurchaseIntentEvent.type)
            .to(PublishPurchaseIntentEvent)
            .inSingletonScope();
        bind<PublishFormEvents>(PublishFormEvents.type).to(PublishFormEvents).inSingletonScope();
        bind<QuestionnaireRepository>(QuestionnaireRepository)
            .to(APIQuestionnaireRepository)
            .inSingletonScope();
        bind<IndicativePremiumRepository>(IndicativePremiumRepository)
            .to(APIIndicativePremiumRepository)
            .inSingletonScope();
        bind<GetIndicativePremiumList>(GetIndicativePremiumList.type)
            .to(GetIndicativePremiumList)
            .inSingletonScope();
        bind<CreateLawBundleApplication>(CreateLawBundleApplication.type)
            .to(CreateLawBundleApplication)
            .inSingletonScope();
        bind<CreateMplBundleApplication>(CreateMplBundleApplication.type)
            .to(CreateMplBundleApplication)
            .inSingletonScope();
        bind<UpdateApplicantFromTier1>(UpdateApplicantFromTier1.type)
            .to(UpdateApplicantFromTier1)
            .inSingletonScope();
        bind<GetLawBundlePrefill>(GetLawBundlePrefill.type)
            .to(GetLawBundlePrefill)
            .inSingletonScope();
        bind<UpdateApplicationQuestionnaireFromTier1>(UpdateApplicationQuestionnaireFromTier1.type)
            .to(UpdateApplicationQuestionnaireFromTier1)
            .inSingletonScope();
        bind<PublishShoppingUserClickEvent>(PublishShoppingUserClickEvent.type)
            .to(PublishShoppingUserClickEvent)
            .inSingletonScope();
        bind<OptOutFromStreamlineRenewal>(OptOutFromStreamlineRenewal.type)
            .to(OptOutFromStreamlineRenewal)
            .inSingletonScope();
        bind<GetPartialEligibility>(GetPartialEligibility.type)
            .to(GetPartialEligibility)
            .inSingletonScope();
        bind<SubmitStreamlineRenewal>(SubmitStreamlineRenewal.type)
            .to(SubmitStreamlineRenewal)
            .inSingletonScope();
        bind<RecreateRenewal>(RecreateRenewal.type).to(RecreateRenewal).inSingletonScope();
        bind<GetInvestors>(GetInvestors.type).to(GetInvestors).inSingletonScope();
    }),
};
