import React, { useRef, useState } from 'react';
import {
    SidebarLayout,
    WizardLayout,
    CenterLayout,
    StickyLayout,
    FormLayout,
} from '@embroker/ui-toolkit/v2';
import { FormSideNavigation } from './FormSideNavigation';
import {
    DataDrivenForm,
    DataDrivenFormRefrence,
} from '../../../view/components/DataDrivenForm/components/DataDrivenForm';
import { FormQuestionDefinition } from '../../../view/components/DataDrivenForm/hooks/useDataDrivenForm';
import {
    QuestionerPageDefinition,
    extractFormPages,
} from '@app/shoppingQuestioner/types/QuestionerPageDefinition';

const calculateProgress = (
    formPages: QuestionerPageDefinition[],
    activePage: string | undefined,
): number => {
    const pageIndex = formPages.findIndex((page) => page.name === activePage);
    return ((pageIndex + 1) / formPages.length) * 100;
};

interface QuestionerRendererProps {
    pages?: QuestionerPageDefinition[];
    questions: FormQuestionDefinition[];
    onSaveAndExit: () => void;
    onPageComplete: (formValue: { [key: string]: unknown }) => void;
    onFormSubmit: (formValue: { [key: string]: unknown }) => void;
}
export function QuestionerRenderer({
    questions,
    pages = [],
    onSaveAndExit,
    onPageComplete,
    onFormSubmit,
}: QuestionerRendererProps) {
    const [formPages] = useState(extractFormPages(pages));

    const dataDrivenFormRef = useRef<DataDrivenFormRefrence>(null);
    const activePage = dataDrivenFormRef.current?.activePage;

    const handleClickNavigationItem = (pageName: string) => {
        dataDrivenFormRef.current?.setActiveStep(pageName);
    };

    const progress = calculateProgress(formPages, activePage);

    return (
        <WizardLayout
            progress={progress}
            title="Application Questionnaire"
            onDismiss={onSaveAndExit}
            dismissAppearance="save-and-exit"
        >
            <SidebarLayout appearance="compact">
                <StickyLayout gap="64">
                    <FormSideNavigation
                        activePage={activePage}
                        formPages={pages}
                        onClickNavigationItem={handleClickNavigationItem}
                    />
                </StickyLayout>
                <CenterLayout>
                    <FormLayout appearance="wide">
                        <DataDrivenForm
                            formQuestions={questions}
                            formPages={formPages}
                            onPageComplete={onPageComplete}
                            onFormSubmit={onFormSubmit}
                            ref={dataDrivenFormRef}
                        />
                    </FormLayout>
                </CenterLayout>
            </SidebarLayout>
        </WizardLayout>
    );
}
