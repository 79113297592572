import {
    Button,
    DetailsSidebar,
    Hyperlink,
    List,
    StackLayout,
    Text,
} from '@embroker/ui-toolkit/v2';
import React, { useContext } from 'react';
import { AppContext } from '../../../view/AppContext';
import { Coverage } from '../../types/Coverage';
import { AppTypeCode } from '../../types/enums';
import { getCoverageDetails } from './coverageDetails';

interface LearnMoreSlideoutProps {
    /**
     * Insurance coverage application code for which "learn more" provides description
     */
    appTypeCode: AppTypeCode;
    /**
     * Applicant's naics code to which coverage is being applied for
     */
    naicsCode?: string;
    /**
     * Indicator if coverage is selected. Affects "select" button text
     */
    isSelected: boolean;
    /**
     * Callback when coverage is selected/unselected
     */
    onCoverageSelect: (code: AppTypeCode, isSelected: boolean) => void;
    /**
     * Slideout variants. The standard version is set by default.
     */
    variant?: 'standard' | 'new';
}

export function LearnMoreSlideout({
    appTypeCode,
    naicsCode,
    isSelected,
    onCoverageSelect,
    variant = 'standard',
}: LearnMoreSlideoutProps) {
    Coverage;
    const { closeSlideout } = useContext(AppContext);

    const {
        sliderTitle,
        sliderDescription,
        sliderWhoNeedsTitle,
        sliderWhoNeedsDescription,
        sliderWhatIsTitle,
        sliderWhatIsDescription,
        sliderWhyNeedItTitle,
        sliderWhyNeedItDescription,
        coverages,
        coveragesTitle,
        nonIncludedCoverages,
        icon,
    } = getCoverageDetails(appTypeCode, naicsCode);

    return (
        <DetailsSidebar>
            <DetailsSidebar.Header
                icon={icon}
                iconColor={variant === 'new' ? 'ui-50' : undefined}
                onDismiss={closeSlideout}
            >
                <Text style={variant === 'new' ? 'heading 3' : 'heading 1'} color="ui-50">
                    {sliderTitle}
                </Text>
                {variant === 'standard' && (
                    <StackLayout gap="24">
                        <Text color="ui-50">{sliderDescription}</Text>
                        <Button
                            appearance="light"
                            size="large"
                            onClick={() => {
                                onCoverageSelect(appTypeCode, !isSelected);
                            }}
                        >
                            {isSelected ? 'Remove' : 'Get a Quote'}
                        </Button>
                    </StackLayout>
                )}
            </DetailsSidebar.Header>
            <DetailsSidebar.Body gap={variant === 'new' ? '32' : undefined}>
                {sliderWhatIsDescription && (
                    <StackLayout gap={variant === 'new' ? '12' : '16'}>
                        <Text style="heading 4" color="brand-400">
                            {sliderWhatIsTitle}
                        </Text>
                        <Text style="body 1" whiteSpace={variant === 'new'}>
                            {sliderWhatIsDescription}
                        </Text>
                    </StackLayout>
                )}
                {coverages.length > 0 && (
                    <List
                        title={variant === 'new' ? coveragesTitle : 'This includes coverage for:'}
                    >
                        {coverages.map((coverage, index) => (
                            <List.Item key={index}>
                                <Text style="body 1">{coverage}</Text>
                            </List.Item>
                        ))}
                    </List>
                )}
                {nonIncludedCoverages.length > 0 && variant === 'new' && (
                    <List title={'This doesn’t includes coverage for:'}>
                        {nonIncludedCoverages.map((coverage) => (
                            <List.Item icon="status-error" iconColor="red" key={coverage}>
                                <Text style="body 1">{coverage}</Text>
                            </List.Item>
                        ))}
                    </List>
                )}
                {sliderWhoNeedsDescription && (
                    <React.Fragment>
                        <Text style="heading 4" color="brand-400">
                            {sliderWhoNeedsTitle}
                        </Text>
                        <Text style="body 1">{sliderWhoNeedsDescription}</Text>
                    </React.Fragment>
                )}
                {sliderWhyNeedItDescription ? (
                    variant === 'new' ? (
                        <StackLayout gap="24">
                            <Text style="heading 4" color="brand-400">
                                {sliderWhyNeedItTitle}
                            </Text>
                            <Text>{sliderWhyNeedItDescription}</Text>
                            <Text style="body 1">
                                A.M. Best ratings are believed to be current, however they are
                                subject to change and subject to{' '}
                                <Hyperlink href="http://www.embroker.com/terms" target="_blank">
                                    Embroker's terms
                                </Hyperlink>
                                <br />
                                The above summaries are not guarantees of coverage or a quote.
                                Coverage is subject to the terms, conditions and exclusions of
                                issued policies only.
                            </Text>
                        </StackLayout>
                    ) : (
                        <React.Fragment>
                            <Text style="heading 4" color="brand-400">
                                {sliderWhyNeedItTitle}
                            </Text>
                            <Text>{sliderWhyNeedItDescription}</Text>
                        </React.Fragment>
                    )
                ) : null}
            </DetailsSidebar.Body>
        </DetailsSidebar>
    );
}
