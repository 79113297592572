import React from 'react';
import { FormFieldViewDefinition } from './DataDrivenForm';
import { DdFormFieldSetProps } from '../hooks/useDataDrivenForm';
import { StackLayout, Text } from '@embroker/ui-toolkit/v2';
import { FormViewProps } from './FormView.view';
import { FormFieldView } from './FormFieldView.view';

export interface FieldSetViewProps extends Omit<DdFormFieldSetProps, 'questions'> {
    title: React.ReactNode;
    questions: FormFieldViewDefinition[];
    handleComplexFieldChange: FormViewProps['handleComplexFieldChange'];
}

export function FieldSetView({ title, questions, handleComplexFieldChange }: FieldSetViewProps) {
    if (!questions.length) {
        // if the FieldSetView is empty, we do not render anything
        return null;
    }
    const titleElement = typeof title === 'string' ? <Text style="body 1">{title}</Text> : title;
    return (
        <StackLayout gap="12">
            {titleElement}
            {questions.map((formFieldView: FormFieldViewDefinition) => {
                return (
                    <FormFieldView
                        key={formFieldView.questionProps.key}
                        {...formFieldView}
                        onComplexFieldChange={handleComplexFieldChange}
                    />
                );
            })}
        </StackLayout>
    );
}
