/**
 * NOTE: Every new export should also be copied into appTypes.js in /client
 */

// TODO - this is already on the server side in enums.go, should refactor to unify it
export const AppTypeCodeListESP = 'AppTypeCodeListESP';
export const AppTypeCodeListTechEO = 'AppTypeCodeListTechEO';
export const AppTypeCodeListPCoML = 'AppTypeCodeListPCoML';
export const AppTypeCodeListManualAuto = 'AppTypeCodeListManualAuto';
export const AppTypeCodeListEmbrokerCrime = 'AppTypeCodeListEmbrokerCrime';
export const AppTypeCodeListManualCrime = 'AppTypeCodeListManualCrime';
export const AppTypeCodeListManualCyber = 'AppTypeCodeListManualCyber';
export const AppTypeCodeListEmbrokerCyber = 'AppTypeCodeListEmbrokerCyber';
export const AppTypeCodeListManualDirectorsAndOfficers =
    'AppTypeCodeListManualDirectorsAndOfficers';
export const AppTypeCodeListManualEmploymentPractices = 'AppTypeCodeListManualEmploymentPractices';
export const AppTypeCodeListManualFiduciary = 'AppTypeCodeListManualFiduciary';
export const AppTypeCodeListManualConstructionGL = 'AppTypeCodeListManualConstructionGL';
export const AppTypeCodeListManualGL = 'AppTypeCodeListManualGL';
export const AppTypeCodeListManualCannabis = 'AppTypeCodeListManualCannabis';
export const AppTypeCodeListManualDefaultPL = 'AppTypeCodeListManualDefaultPL';
export const AppTypeCodeListManualAccountantsPL = 'AppTypeCodeListManualAccountantsPL';
export const AppTypeCodeListManualTechEO = 'AppTypeCodeListManualTechEO';
export const AppTypeCodeListManualConstructionPL = 'AppTypeCodeListManualConstructionPL';
export const AppTypeCodeListManualLawyersProfessionalLiability =
    'AppTypeCodeListManualLawyersProfessionalLiability';
export const AppTypeCodeListEverestLawyersProfessionalLiability =
    'AppTypeCodeListEverestLawyersProfessionalLiability';
export const AppTypeCodeListManualProductLiability = 'AppTypeCodeListManualProductLiability';
export const AppTypeCodeListManualProperty = 'AppTypeCodeListManualProperty';
export const AppTypeCodeListManualUmbrella = 'AppTypeCodeListManualUmbrella';
export const AppTypeCodeListManualWorkersCompensation = 'AppTypeCodeListManualWorkersCompensation';
export const AppTypeCodeListGAWorkersCompensation = 'AppTypeCodeListGAWorkersCompensation';
export const AppTypeCodeListOther = 'AppTypeCodeListOther';
export const AppTypeCodeListManualTravel = 'AppTypeCodeListManualTravel';
export const AppTypeCodeListManualVentureCapitalAssetProtection =
    'AppTypeCodeListManualVentureCapitalAssetProtection';
export const AppTypeCodeListManualHomeownersAssociation =
    'AppTypeCodeListManualHomeownersAssociation';
export const AppTypeCodeListCNABOP = 'AppTypeCodeListCNABOP';
export const AppTypeCodeListLawBundle = 'AppTypeCodeListLawBundle';
export const AppTypeCodeListLawBundleCyber = 'AppTypeCodeListLawBundleCyber';
export const AppTypeCodeListCyberCowbell = 'AppTypeCodeListCyberCowbell';
export const AppTypeCodeListEmbrokerExcess = 'AppTypeCodeListEmbrokerExcess';
export const AppTypeCodeListBOPChubb = 'AppTypeCodeListBOPChubb';
export const AppTypeCodeListMPLBundle = 'AppTypeCodeListMPLBundle';
export const AppTypeCodeListMPL = 'AppTypeCodeListMPL';
export const AppTypeCodeListWCChubb = 'AppTypeCodeListWCChubb';
export const AppTypeCodeListHartfordBOP = 'AppTypeCodeListHartfordBOP';

export const ShoppingCoverageCodeListDirectorsAndOfficers =
    'ShoppingCoverageCodeListDirectorsAndOfficers';
export const ShoppingCoverageCodeListEmploymentPractices =
    'ShoppingCoverageCodeListEmploymentPractices';
export const ShoppingCoverageCodeListFiduciary = 'ShoppingCoverageCodeListFiduciary';
export const ShoppingCoverageCodeListProfessionalLiability =
    'ShoppingCoverageCodeListProfessionalLiability';
export const ShoppingCoverageCodeListAuto = 'ShoppingCoverageCodeListAuto';
export const ShoppingCoverageCodeListCrime = 'ShoppingCoverageCodeListCrime';
export const ShoppingCoverageCodeListCyber = 'ShoppingCoverageCodeListCyber';
export const ShoppingCoverageCodeListWorkersCompensation =
    'ShoppingCoverageCodeListWorkersCompensation';
export const ShoppingCoverageCodeListGeneralLiability = 'ShoppingCoverageCodeListGeneralLiability';
export const ShoppingCoverageCodeListProperty = 'ShoppingCoverageCodeListProperty';
export const ShoppingCoverageCodeListProductLiability = 'ShoppingCoverageCodeListProductLiability';
export const ShoppingCoverageCodeListUmbrella = 'ShoppingCoverageCodeListUmbrella';
export const ShoppingCoverageCodeListOther = 'ShoppingCoverageCodeListOther';
export const ShoppingCoverageCodeListTravel = 'ShoppingCoverageCodeListTravel';
export const ShoppingCoverageCodeListVentureCapitalAssetProtection =
    'ShoppingCoverageCodeListVentureCapitalAssetProtection';
export const ShoppingCoverageCodeListHomeownersAssociation =
    'ShoppingCoverageCodeListHomeownersAssociation';

export const ShopppingCoverageCodeList = [
    ShoppingCoverageCodeListDirectorsAndOfficers,
    ShoppingCoverageCodeListEmploymentPractices,
    ShoppingCoverageCodeListFiduciary,
    ShoppingCoverageCodeListProfessionalLiability,
    ShoppingCoverageCodeListAuto,
    ShoppingCoverageCodeListCrime,
    ShoppingCoverageCodeListCyber,
    ShoppingCoverageCodeListWorkersCompensation,
    ShoppingCoverageCodeListGeneralLiability,
    ShoppingCoverageCodeListProperty,
    ShoppingCoverageCodeListProductLiability,
    ShoppingCoverageCodeListUmbrella,
    ShoppingCoverageCodeListOther,
    ShoppingCoverageCodeListTravel,
    ShoppingCoverageCodeListVentureCapitalAssetProtection,
    ShoppingCoverageCodeListHomeownersAssociation,
];
type ShopppingCoverageCodeList = typeof ShopppingCoverageCodeList;
export type ShopppingCoverageCode = ShopppingCoverageCodeList[number];

export const AppTypeCodeList = [
    AppTypeCodeListManualAuto,
    AppTypeCodeListEmbrokerCrime,
    AppTypeCodeListManualCrime,
    AppTypeCodeListManualCyber,
    AppTypeCodeListEmbrokerCyber,
    AppTypeCodeListManualConstructionGL,
    AppTypeCodeListManualGL,
    AppTypeCodeListManualCannabis,
    AppTypeCodeListManualLawyersProfessionalLiability,
    AppTypeCodeListEverestLawyersProfessionalLiability,
    AppTypeCodeListManualProperty,
    AppTypeCodeListManualProductLiability,
    AppTypeCodeListManualUmbrella,
    AppTypeCodeListManualWorkersCompensation,
    AppTypeCodeListGAWorkersCompensation,
    AppTypeCodeListOther,
    AppTypeCodeListManualTravel,
    AppTypeCodeListManualHomeownersAssociation,
    AppTypeCodeListCNABOP,
    AppTypeCodeListESP,
    AppTypeCodeListTechEO,
    AppTypeCodeListPCoML,
    AppTypeCodeListManualAccountantsPL,
    AppTypeCodeListManualTechEO,
    AppTypeCodeListManualDefaultPL,
    AppTypeCodeListManualConstructionPL,
    AppTypeCodeListManualDirectorsAndOfficers,
    AppTypeCodeListManualVentureCapitalAssetProtection,
    AppTypeCodeListManualEmploymentPractices,
    AppTypeCodeListManualFiduciary,
    AppTypeCodeListLawBundle,
    AppTypeCodeListLawBundleCyber,
    AppTypeCodeListEmbrokerExcess,
    AppTypeCodeListCyberCowbell,
    AppTypeCodeListBOPChubb,
    AppTypeCodeListMPL,
    AppTypeCodeListMPLBundle,
    AppTypeCodeListWCChubb,
    AppTypeCodeListHartfordBOP,
] as const;
type AppTypeCodeList = typeof AppTypeCodeList;
export type AppTypeCode = AppTypeCodeList[number];

export const isAppTypeCode = (x: any): x is AppTypeCode => AppTypeCodeList.includes(x);

export const AppTypeLabelMap = {
    AppTypeCodeListESP: 'Startup Package',
    AppTypeCodeListTechEO: 'Tech E&O/Cyber Insurance',
    AppTypeCodeListPCoML: 'Business Management Insurance',
    AppTypeCodeListManualAuto: 'Commercial Auto',
    AppTypeCodeListEmbrokerCrime: 'Commercial Crime',
    AppTypeCodeListManualCrime: 'Commercial Crime',
    AppTypeCodeListManualCyber: 'Cyber and Data Breach',
    AppTypeCodeListEmbrokerCyber: 'Cyber Insurance',
    AppTypeCodeListManualConstructionGL: 'General Liability',
    AppTypeCodeListManualGL: 'General Liability',
    AppTypeCodeListManualCannabis: 'Cannabis',
    AppTypeCodeListManualLawyersProfessionalLiability:
        'Lawyers Professional Liability / Errors and Omissions',
    AppTypeCodeListEverestLawyersProfessionalLiability:
        'Lawyers Professional Liability / Errors and Omissions',
    AppTypeCodeListManualProperty: 'Property',
    AppTypeCodeListManualProductLiability: 'Product Liability',
    AppTypeCodeListManualUmbrella: 'Umbrella',
    AppTypeCodeListManualWorkersCompensation: 'Workers Compensation',
    AppTypeCodeListGAWorkersCompensation: 'Workers Compensation',
    AppTypeCodeListManualTravel: 'International Package',
    AppTypeCodeListManualHomeownersAssociation: 'Homeowners Association',
    AppTypeCodeListCNABOP: 'Business Owners Policy',
    AppTypeCodeListOther: 'Other',
    AppTypeCodeListManualAccountantsPL: 'Accountants Professional Liability / Errors and Omissions',
    AppTypeCodeListManualConstructionPL:
        'Architects and Engineers Professional Liability / Errors and Omissions',
    AppTypeCodeListManualTechEO: 'Technology Errors and Omissions / Cyber',
    AppTypeCodeListManualDefaultPL: 'Professional Liability / Errors and Omissions',
    AppTypeCodeListManualDirectorsAndOfficers: 'Directors and Officers',
    AppTypeCodeListManualVentureCapitalAssetProtection: 'Venture Capital Asset Protection',
    AppTypeCodeListManualEmploymentPractices: 'Employment Practices Liability',
    AppTypeCodeListManualFiduciary: 'Fiduciary',
    AppTypeCodeListLawBundle: 'Law Firm Program',
    AppTypeCodeListLawBundleCyber: 'Cyber Insurance',
    AppTypeCodeListEmbrokerExcess: 'Excess Tech E&O/Cyber',
    AppTypeCodeListCyberCowbell: 'Cyber Insurance',
    AppTypeCodeListBOPChubb: 'Business Owners Policy',
    AppTypeCodeListMPL: 'Miscellaneous Professional Liability',
    AppTypeCodeListMPLBundle: 'Miscellaneous Professional Liability',
    AppTypeCodeListWCChubb: 'Workers Compensation',
    AppTypeCodeListHartfordBOP: 'Business Owners Policy',
} as const;

export const ShoppingCoverageCodeList = [
    ShoppingCoverageCodeListDirectorsAndOfficers,
    ShoppingCoverageCodeListEmploymentPractices,
    ShoppingCoverageCodeListFiduciary,
    ShoppingCoverageCodeListProfessionalLiability,
    ShoppingCoverageCodeListCyber,
    ShoppingCoverageCodeListAuto,
    ShoppingCoverageCodeListCrime,
    ShoppingCoverageCodeListGeneralLiability,
    ShoppingCoverageCodeListProperty,
    ShoppingCoverageCodeListProductLiability,
    ShoppingCoverageCodeListUmbrella,
    ShoppingCoverageCodeListWorkersCompensation,
    ShoppingCoverageCodeListOther,
    ShoppingCoverageCodeListTravel,
    ShoppingCoverageCodeListVentureCapitalAssetProtection,
    ShoppingCoverageCodeListHomeownersAssociation,
] as const;
export type ShoppingCoverage = (typeof ShoppingCoverageCodeList)[number];

export const ShoppingCoverageCodeToAppTypeCodeMap: {
    [coverage in ShoppingCoverage]: AppTypeCode;
} = {
    ShoppingCoverageCodeListDirectorsAndOfficers: 'AppTypeCodeListManualDirectorsAndOfficers',
    ShoppingCoverageCodeListEmploymentPractices: 'AppTypeCodeListManualEmploymentPractices',
    ShoppingCoverageCodeListFiduciary: 'AppTypeCodeListManualFiduciary',
    ShoppingCoverageCodeListProfessionalLiability: 'AppTypeCodeListManualDefaultPL',
    ShoppingCoverageCodeListAuto: 'AppTypeCodeListManualAuto',
    ShoppingCoverageCodeListCrime: 'AppTypeCodeListManualCrime',
    ShoppingCoverageCodeListGeneralLiability: 'AppTypeCodeListManualGL',
    ShoppingCoverageCodeListProperty: 'AppTypeCodeListManualProperty',
    ShoppingCoverageCodeListProductLiability: 'AppTypeCodeListManualProductLiability',
    ShoppingCoverageCodeListUmbrella: 'AppTypeCodeListManualUmbrella',
    ShoppingCoverageCodeListWorkersCompensation: 'AppTypeCodeListManualWorkersCompensation',
    ShoppingCoverageCodeListOther: 'AppTypeCodeListOther',
    ShoppingCoverageCodeListTravel: 'AppTypeCodeListManualTravel',
    ShoppingCoverageCodeListVentureCapitalAssetProtection:
        'AppTypeCodeListManualVentureCapitalAssetProtection',
    ShoppingCoverageCodeListHomeownersAssociation:
        'AppTypeCodeListManualVentureCapitalAssetProtection',
    ShoppingCoverageCodeListCyber: 'AppTypeCodeListManualCyber',
};

export const CoverageGroupTypeListStartups = 'startups';
export const CoverageGroupTypeLawFirms = 'lawFirms';
export const CoverageGroupTypeTechCompanies = 'techCompanies';

export const CoverageGroupTypeList = [
    CoverageGroupTypeListStartups,
    CoverageGroupTypeLawFirms,
    CoverageGroupTypeTechCompanies,
] as const;
type CoverageGroupTypeList = typeof CoverageGroupTypeList;
export type CoverageGroupType = CoverageGroupTypeList[number];

export const CoverageGroupLabelMap = new Map<CoverageGroupType, string>([
    [CoverageGroupTypeListStartups, 'Funded Startups'],
    [CoverageGroupTypeLawFirms, 'Law Firm'],
    [CoverageGroupTypeTechCompanies, 'Tech Companies'],
]);

export interface Config {
    isPCOMLEnabled: boolean;
    isWCGAEnabled: boolean;
    isBOPEnabled: boolean;
    isLPLEnabled: boolean;
    isEmbrokerCrimeEnabled: boolean;
    isEmbrokerCyberEnabled: boolean;
}

export const QuestionnaireInProgress = 'InsuranceApplicationStatusCodeListQuestionnaireInProgress';
export const AwaitingQuotes = 'InsuranceApplicationStatusCodeListAwaitingQuotes';
export const QuotesReady = 'InsuranceApplicationStatusCodeListQuotesReady';
export const Purchased = 'InsuranceApplicationStatusCodeListPurchased';
export const CanceledByAdmin = 'InsuranceApplicationStatusCodeListCanceledByAdmin';
export const Deleted = 'InsuranceApplicationStatusCodeListDeleted';
export const InProgressByAdmin = 'InsuranceApplicationStatusCodeListInProgressByAdmin';
export const QuoteExpired = 'InsuranceApplicationStatusCodeListQuoteExpired';
export const SubmissionInProgress = 'InsuranceApplicationStatusCodeListSubmissionInProgress';
export const Referred = 'InsuranceApplicationStatusCodeListReferred';
export const ClearanceFailed = 'InsuranceApplicationStatusCodeListClearanceFailed';
export const EarlyClearanceFailed = 'InsuranceApplicationStatusCodeListEarlyClearanceFailed';
export const ReferredAwaitingReview = 'InsuranceApplicationStatusCodeListReferredAwaitingReview';
export const OFACFailed = 'InsuranceApplicationStatusCodeListOFACFailed';
export const NotEligible = 'InsuranceApplicationStatusCodeListNotEligible';
export const DeclinedByCarrier = 'InsuranceApplicationStatusCodeListDeclinedByCarrier';
export const AutoCanceled = 'InsuranceApplicationStatusCodeListAutoCanceled';
export const InCreation = 'InsuranceApplicationStatusCodeListInCreation';
export const BundleSubmitted = 'InsuranceApplicationStatusCodeListBundleSubmitted';
export const AwaitingBind = 'InsuranceApplicationStatusCodeListAwaitingBind';
export const Bound = 'InsuranceApplicationStatusCodeListBound';
export const SupplementalInProgress = 'InsuranceApplicationStatusCodeListSupplementalInProgress';

export const InsuranceApplicationStatusCodeList = [
    QuestionnaireInProgress,
    AwaitingQuotes,
    QuotesReady,
    Purchased,
    CanceledByAdmin,
    Deleted,
    InProgressByAdmin,
    QuoteExpired,
    SubmissionInProgress,
    Referred,
    ClearanceFailed,
    EarlyClearanceFailed,
    ReferredAwaitingReview,
    OFACFailed,
    NotEligible,
    DeclinedByCarrier,
    AutoCanceled,
    InCreation,
    BundleSubmitted,
    AwaitingBind,
    Bound,
    SupplementalInProgress,
] as const;
type InsuranceApplicationStatusCodeList = typeof InsuranceApplicationStatusCodeList;
export type InsuranceApplicationStatusCode = InsuranceApplicationStatusCodeList[number];

export const InsuranceApplicationStatusCodeListMap = {
    InsuranceApplicationStatusCodeListQuestionnaireInProgress: 'Questionnaire In Progress',
    InsuranceApplicationStatusCodeListAwaitingQuotes: 'Awaiting Quotes',
    InsuranceApplicationStatusCodeListQuotesReady: 'Quotes Ready',
    InsuranceApplicationStatusCodeListPurchased: 'Purchased',
    InsuranceApplicationStatusCodeListCanceledByAdmin: 'Canceled By Admin',
    InsuranceApplicationStatusCodeListDeleted: 'Deleted',
    InsuranceApplicationStatusCodeListInProgressByAdmin: 'In Progress By Admin',
    InsuranceApplicationStatusCodeListQuoteExpired: 'Quote Expired',
    InsuranceApplicationStatusCodeListSubmissionInProgress: 'Submission In Progress',
    InsuranceApplicationStatusCodeListReferred: 'Referred',
    InsuranceApplicationStatusCodeListClearanceFailed: 'Clearance Failed',
    InsuranceApplicationStatusCodeListEarlyClearanceFailed: 'Early Clearance Failed',
    InsuranceApplicationStatusCodeListReferredAwaitingReview: 'Referred Awaiting Review',
    InsuranceApplicationStatusCodeListOFACFailed: 'OFAC Failed',
    InsuranceApplicationStatusCodeListNotEligible: 'Not Eligible',
    InsuranceApplicationStatusCodeListDeclinedByCarrier: 'Not Eligible',
    InsuranceApplicationStatusCodeListAutoCanceled: 'Auto Canceled',
    InsuranceApplicationStatusCodeListInCreation: 'In Creation',
    InsuranceApplicationStatusCodeListBundleSubmitted: 'Bundle Submitted',
    InsuranceApplicationStatusCodeListAwaitingBind: 'Awaiting Bind',
    InsuranceApplicationStatusCodeListBound: 'Bound',
    InsuranceApplicationStatusCodeListSupplementalInProgress: 'Supplemental in progress',
} as const;

export const Regular = 'InsuranceApplicationCreationTypeCodeListRegular';
export const Intake = 'InsuranceApplicationCreationTypeCodeListIntake';
export const ClientReservation = 'InsuranceApplicationCreationTypeCodeListClientReservation';
export const Bundle = 'InsuranceApplicationCreationTypeCodeListBundle';
export const Underlying = 'InsuranceApplicationCreationTypeCodeListUnderlying';

export const InsuranceApplicationCreationTypeCodeList = [
    Regular,
    Intake,
    ClientReservation,
    Bundle,
    Underlying,
] as const;
type InsuranceApplicationCreationTypeCodeList = typeof InsuranceApplicationCreationTypeCodeList;
export type InsuranceApplicationCreationTypeCode = InsuranceApplicationCreationTypeCodeList[number];

export const ESPAppTypesCodeList = [
    'AppTypeCodeListManualDirectorsAndOfficers',
    'AppTypeCodeListManualEmploymentPractices',
    'AppTypeCodeListManualFiduciary',
    'AppTypeCodeListManualDefaultPL',
    'AppTypeCodeListManualCyber',
] as const;

export const ESPShoppingCoverageCodeList = [
    'ShoppingCoverageCodeListDirectorsAndOfficers',
    'ShoppingCoverageCodeListEmploymentPractices',
    'ShoppingCoverageCodeListFiduciary',
    'ShoppingCoverageCodeListProfessionalLiability',
    'ShoppingCoverageCodeListCyber',
] as const;

export const QuotingEngineESP = 'QuotingEngineESP';
export const QuotingEngineManual = 'QuotingEngineManual';
export const QuotingEngineWCGA = 'QuotingEngineWCGA';
export const QuotingEngineLPLEverest = 'QuotingEngineLPLEverest';
export const QuotingEngineMPL = 'QuotingEngineMPL';
export const QuotingEngineCNABOP = 'QuotingEngineCNABOP';
export const QuotingEnginePCoML = 'QuotingEnginePCoML';
export const QuotingEngineCrime = 'QuotingEngineCrime';
export const QuotingEngineCyber = 'QuotingEngineCyber';
export const QuotingEngineLawCyber = 'QuotingEngineLawCyber';
export const QuotingEngineCyberCowbell = 'QuotingEngineCyberCowbell';
export const QuotingEngineExcess = 'QuotingEngineExcess';
export const QuotingEngineBOPChubb = 'QuotingEngineBOPChubb';
export const QuotingEngineWCChubb = 'QuotingEngineWCChubb';

export const QuotingEngineList = [
    QuotingEngineESP,
    QuotingEngineManual,
    QuotingEngineWCGA,
    QuotingEngineLPLEverest,
    QuotingEngineMPL,
    QuotingEngineCNABOP,
    QuotingEnginePCoML,
    QuotingEngineCrime,
    QuotingEngineCyber,
    QuotingEngineLawCyber,
    QuotingEngineCyberCowbell,
    QuotingEngineExcess,
    QuotingEngineBOPChubb,
    QuotingEngineWCChubb,
] as const;

type QuotingEngineList = typeof QuotingEngineList;
export type QuotingEngine = QuotingEngineList[number];

export type EspIneligibilityReason =
    | 'IndustryIneligible'
    | 'AreaOfFocusNotSupported'
    | 'AreaOfFocusNotAvailable'
    | 'NotRaisedVentureCapitalFunding'
    | 'RaisedMoreThan250MillionInFunding'
    | 'RaisedMoreThan300MillionInFunding'
    | 'InvolvedWithFraudLastFiveYears'
    | 'MoreThan2500Employees'
    | 'MoreThan100MillionInRevenue'
    | 'MoreThan300MillionInRevenue'
    | 'PossibleFutureClaim'
    | 'BankruptcyProceeding'
    | 'SaleLessThanTheAmountOfFoundingRaised'
    | 'InvolvedWithBreachOfPrivacyLastThreeYears'
    | 'NotEligibleSetByAdmin'
    | 'StateNotEligible'
    | 'TooManyLayoffs'
    | 'TypeOfFundingNotSupported';

export const NumberRangeOfW2EmployeesCodeList = [
    'NumberRangeOfW2EmployeesCodeList0',
    'NumberRangeOfW2EmployeesCodeList1To40',
    'NumberRangeOfW2EmployeesCodeList40To60',
    'NumberRangeOfW2EmployeesCodeList60To100',
    'NumberRangeOfW2EmployeesCodeListMoreThan100',
] as const;
type NumberRangeOfW2EmployeesCodeList = typeof NumberRangeOfW2EmployeesCodeList;
export type NumberRangeOfW2Employees = NumberRangeOfW2EmployeesCodeList[number];

export const DisplayPolicyStatusExpired = 'DisplayPolicyStatusExpired';
export const DisplayPolicyStatusCancelled = 'DisplayPolicyStatusCancelled';
export const DisplayPolicyStatusActive = 'DisplayPolicyStatusActive';
export const DisplayPolicyStatusCancelPending = 'DisplayPolicyStatusCancelPending';
export const DisplayPolicyStatusNonRenewed = 'DisplayPolicyStatusNonRenewed';
export const DisplayPolicyStatusList = [
    DisplayPolicyStatusExpired,
    DisplayPolicyStatusCancelled,
    DisplayPolicyStatusActive,
    DisplayPolicyStatusCancelPending,
    DisplayPolicyStatusNonRenewed,
] as const;
type DisplayPolicyStatusList = typeof DisplayPolicyStatusList;
export type DisplayPolicyStatus = DisplayPolicyStatusList[number];

export enum CoveragePriorityEnum {
    AppTypeCodeListMPL = 1,
    AppTypeCodeListESP,
    AppTypeCodeListEverestLawyersProfessionalLiability,
    AppTypeCodeListEmbrokerExcess,
    AppTypeCodeListPCoML,
    AppTypeCodeListCNABOP,
    AppTypeCodeListTechEO,
    AppTypeCodeListManualCyber,
    AppTypeCodeListEmbrokerCrime,
    AppTypeCodeListManualCrime,
    AppTypeCodeListGAWorkersCompensation,
    AppTypeCodeListManualWorkersCompensation,
    AppTypeCodeListEmbrokerCyber,
    AppTypeCodeListManualLawyersProfessionalLiability,
    AppTypeCodeListManualDirectorsAndOfficers,
    AppTypeCodeListManualEmploymentPractices,
    AppTypeCodeListManualFiduciary,
    AppTypeCodeListManualProperty,
    AppTypeCodeListManualConstructionGL,
    AppTypeCodeListManualGL,
    AppTypeCodeListManualUmbrella,
    AppTypeCodeListManualTravel,
    AppTypeCodeListManualAuto,
    AppTypeCodeListManualProductLiability,
    AppTypeCodeListManualHomeownersAssociation,
    AppTypeCodeListManualCannabis,
    AppTypeCodeListOther,
    AppTypeCodeListManualAccountantsPL,
    AppTypeCodeListManualTechEO,
    AppTypeCodeListManualDefaultPL,
    AppTypeCodeListManualConstructionPL,
    AppTypeCodeListManualVentureCapitalAssetProtection,
    AppTypeCodeListLawBundle,
    AppTypeCodeListLawBundleCyber,
    AppTypeCodeListCyberCowbell,
    AppTypeCodeListBOPChubb,
    AppTypeCodeListMPLBundle,
    AppTypeCodeListWCChubb,
    AppTypeCodeListHartfordBOP,
}

export const coverageGroupTypeMap = new Map<CoverageGroupType, AppTypeCode[]>([
    [
        CoverageGroupTypeListStartups,
        [
            'AppTypeCodeListESP',
            'AppTypeCodeListCNABOP',
            'AppTypeCodeListTechEO',
            'AppTypeCodeListEmbrokerCrime',
            'AppTypeCodeListGAWorkersCompensation',
            'AppTypeCodeListEmbrokerCyber',
        ],
    ],
    [
        CoverageGroupTypeLawFirms,
        [
            'AppTypeCodeListEverestLawyersProfessionalLiability',
            'AppTypeCodeListCNABOP',
            'AppTypeCodeListEmbrokerCrime',
            'AppTypeCodeListGAWorkersCompensation',
            'AppTypeCodeListManualEmploymentPractices',
            'AppTypeCodeListEmbrokerCyber',
            'AppTypeCodeListManualCyber',
        ],
    ],
    [
        CoverageGroupTypeTechCompanies,
        [
            'AppTypeCodeListTechEO',
            'AppTypeCodeListCNABOP',
            'AppTypeCodeListEmbrokerCrime',
            'AppTypeCodeListGAWorkersCompensation',
            'AppTypeCodeListEmbrokerCyber',
            'AppTypeCodeListManualEmploymentPractices',
        ],
    ],
]);

export interface CreateCoverageInput {
    readonly appType: AppTypeCode;
    readonly title: string;
}

export const ESP: CreateCoverageInput = {
    appType: 'AppTypeCodeListESP',
    title: 'Startup Package',
};
export const TechEO: CreateCoverageInput = {
    appType: 'AppTypeCodeListTechEO',
    title: 'Tech Errors and Omissions Insurance',
};
export const PCoML: CreateCoverageInput = {
    appType: 'AppTypeCodeListPCoML',
    title: 'Business Management Insurance',
};
export const CommercialAuto: CreateCoverageInput = {
    appType: 'AppTypeCodeListManualAuto',
    title: 'Commercial Auto',
};
export const EmbrokerCyber: CreateCoverageInput = {
    appType: 'AppTypeCodeListCyberCowbell',
    title: 'Cyber',
};
export const Cyber: CreateCoverageInput = {
    appType: 'AppTypeCodeListManualCyber',
    title: 'Cyber / Data Breach',
};
export const DO: CreateCoverageInput = {
    appType: 'AppTypeCodeListManualDirectorsAndOfficers',
    title: 'Directors and Officers',
};
export const EPL: CreateCoverageInput = {
    appType: 'AppTypeCodeListManualEmploymentPractices',
    title: 'Employment Practices Liability',
};
export const International: CreateCoverageInput = {
    appType: 'AppTypeCodeListManualTravel',
    title: 'International Package / Trip Travel',
};
export const GeneralLiability: CreateCoverageInput = {
    appType: 'AppTypeCodeListManualGL',
    title: 'General Liability',
};
export const EO: CreateCoverageInput = {
    appType: 'AppTypeCodeListManualDefaultPL',
    title: 'Professional Liability / Errors and Omissions',
};
export const LPL: CreateCoverageInput = {
    appType: 'AppTypeCodeListEverestLawyersProfessionalLiability',
    title: 'Lawyers Professional Liability / Errors and Omissions',
};
export const ProductLiability: CreateCoverageInput = {
    appType: 'AppTypeCodeListManualProductLiability',
    title: 'Product Liability',
};
export const Property: CreateCoverageInput = {
    appType: 'AppTypeCodeListManualProperty',
    title: 'Property',
};
export const GAWorkersComp: CreateCoverageInput = {
    appType: 'AppTypeCodeListGAWorkersCompensation',
    title: 'Workers Compensation',
};
export const WCChubb: CreateCoverageInput = {
    appType: 'AppTypeCodeListWCChubb',
    title: 'Workers Compensation',
};
export const VentureCapitalAssetProtection: CreateCoverageInput = {
    appType: 'AppTypeCodeListManualVentureCapitalAssetProtection',
    title: 'Venture Capital Asset Protection',
};
export const HOA: CreateCoverageInput = {
    appType: 'AppTypeCodeListManualHomeownersAssociation',
    title: 'Homeowners Association',
};
export const BOP: CreateCoverageInput = {
    appType: 'AppTypeCodeListCNABOP',
    title: 'Business Owners Policy',
};

export const BOPHartFord: CreateCoverageInput = {
    appType: 'AppTypeCodeListHartfordBOP',
    title: 'Business Owners Policy',
};

export const BOPChubb: CreateCoverageInput = {
    appType: 'AppTypeCodeListBOPChubb',
    title: 'Business Owners Policy',
};
export const Umbrella: CreateCoverageInput = {
    appType: 'AppTypeCodeListManualUmbrella',
    title: 'Umbrella',
};
export const EmbrokerCrime: CreateCoverageInput = {
    appType: 'AppTypeCodeListEmbrokerCrime',
    title: 'Commercial Crime',
};
export const Fiduciary: CreateCoverageInput = {
    appType: 'AppTypeCodeListManualFiduciary',
    title: 'Fiduciary',
};
export const ExcessTechEOCyber: CreateCoverageInput = {
    appType: 'AppTypeCodeListEmbrokerExcess',
    title: 'Excess Tech E&O/Cyber',
};
export const MPLBundle: CreateCoverageInput = {
    appType: 'AppTypeCodeListMPLBundle',
    title: 'Miscellaneous Professional Liability',
};

export const MPL: CreateCoverageInput = {
    appType: 'AppTypeCodeListMPL',
    title: 'Miscellaneous Professional Liability',
};

export const ManualWorkersComp: CreateCoverageInput = {
    appType: 'AppTypeCodeListManualWorkersCompensation',
    title: 'Workers Compensation',
};

export const LPLAreaOfPracticeCodeList = [
    'MergersAndAcquisitions',
    'MergersAndAcquisitionsLess',
    'AdministrativeLawGeneral',
    'AdmiraltyMaritime',
    'ArbitrationMediation',
    'Bankruptcy',
    'BipdDefense',
    'BipdPlaintiff',
    'Bonds',
    'GeneralCorporateBusiness',
    'CollectionRepossession',
    'ConstructionLaw',
    'ConsumerClaims',
    'CorporateOrganizationFormation',
    'CriminalLaw',
    'DiscriminationHarassmentDefense',
    'DiscriminationHarassmentPlaintiff',
    'FamilyLaw',
    'EntertainmentSportsWithMoneyManagement',
    'EnvironmentalLaw',
    'PensionAndEmployeeBenefits',
    'EstateTrustAndProbateLess',
    'EstateTrustAndProbate',
    'EstateTrustAndProbateMore',
    'FinancialInstitution',
    'PublicContractLaw',
    'ImmigrationAndNaturalization',
    'InsuranceDefenseLitigation',
    'IntellectualCopyright',
    'IntellectualTrademark',
    'IntellectualPatent',
    'InternationalLaw',
    'InvestmentCounselMoneyManagement',
    'JuvenileGuardian',
    'LaborRelation',
    'LandlordTenant',
    'CommercialLitigation',
    'CommercialLitigationPlaintiff',
    'MedicalMalpractice',
    'MunicipalLaw',
    'OilAndGas',
    'ProductsLiability',
    'PublicUtilities',
    'RealPropertyConveyanceResidential',
    'RealEstateCommercialLessThan5M',
    'RealEstateCommercialMoreThan5M',
    'RealEstateDevelopmentLandUse',
    'SecuritiesLawsIncludingMunicipalBonds',
    'SocialSecurity',
    'TaxOpinion',
    'TaxationOpinions',
    'WorkersCompensationLitigation',
    'WorkersCompensationLitigationPlaintiff',
] as const;
type LPLAreaOfPracticeCodeList = typeof LPLAreaOfPracticeCodeList;
export type LPLAreaOfPracticeCode = LPLAreaOfPracticeCodeList[number];

export const LPLAreasOfPractice = [
    { value: 'MergersAndAcquisitions', title: 'Acquisitions/Mergers > $3M' },
    { value: 'MergersAndAcquisitionsLess', title: 'Acquisitions/Mergers <= $3M' },
    { value: 'AdministrativeLawGeneral', title: 'Administrative Law' },
    { value: 'AdmiraltyMaritime', title: 'Admiralty/Maritime' },
    { value: 'ArbitrationMediation', title: 'Arbitration/Mediation' },
    { value: 'Bankruptcy', title: 'Bankruptcy' },
    { value: 'BipdDefense', title: 'BI/PD Defense (excluding Med Mal)' },
    { value: 'BipdPlaintiff', title: 'BI/PD Plaintiff (excluding Med Mal)' },
    { value: 'Bonds', title: 'Bonds' },
    { value: 'GeneralCorporateBusiness', title: 'Business/Corporation' },
    { value: 'CollectionRepossession', title: 'Collection/Repossession' },
    { value: 'ConstructionLaw', title: 'Construction/Building Contracts' },
    { value: 'ConsumerClaims', title: 'Consumer Claims' },
    { value: 'CorporateOrganizationFormation', title: 'Corporate Formation' },
    { value: 'CriminalLaw', title: 'Criminal' },
    { value: 'DiscriminationHarassmentDefense', title: 'Discrimination/Harassment Defense' },
    { value: 'DiscriminationHarassmentPlaintiff', title: 'Discrimination/Harassment Plaintiff' },
    { value: 'FamilyLaw', title: 'Domestic/Family' },
    { value: 'EntertainmentSportsWithMoneyManagement', title: 'Entertainment' },
    { value: 'EnvironmentalLaw', title: 'Environmental' },
    { value: 'PensionAndEmployeeBenefits', title: 'ERISA/EE Benefits' },
    { value: 'EstateTrustAndProbateLess', title: 'Estate/Trust/Probate - <$1M' },
    { value: 'EstateTrustAndProbate', title: 'Estate/Trust/Probate - $1M-$5M' },
    { value: 'EstateTrustAndProbateMore', title: 'Estate/Trust/Probate - >$5M' },
    { value: 'FinancialInstitution', title: 'Financial Institution' },
    { value: 'PublicContractLaw', title: 'Government' },
    { value: 'ImmigrationAndNaturalization', title: 'Immigration' },
    { value: 'InsuranceDefenseLitigation', title: 'Insurance Defense' },
    { value: 'IntellectualCopyright', title: 'Intellectual - Copyright' },
    { value: 'IntellectualTrademark', title: 'Intellectual - Trademark' },
    { value: 'IntellectualPatent', title: 'Intellectual - Patent' },
    { value: 'InternationalLaw', title: 'International Law' },
    { value: 'InvestmentCounselMoneyManagement', title: 'Investment Counsel/Money Management' },
    { value: 'JuvenileGuardian', title: 'Juvenile/Guardian' },
    { value: 'LaborRelation', title: 'Labor Relation' },
    { value: 'LandlordTenant', title: 'Landlord Tenant' },
    { value: 'CommercialLitigation', title: 'Litigation Defense' },
    { value: 'CommercialLitigationPlaintiff', title: 'Litigation Plaintiff' },
    { value: 'MedicalMalpractice', title: 'Medical Malpractice' },
    { value: 'MunicipalLaw', title: 'Municipal Law' },
    { value: 'OilAndGas', title: 'Oil/Gas' },
    { value: 'ProductsLiability', title: 'Products Liability' },
    { value: 'PublicUtilities', title: 'Public Utilities' },
    { value: 'RealPropertyConveyanceResidential', title: 'Real Estate - Residential' },
    { value: 'RealEstateCommercialLessThan5M', title: 'Real Estate - Commercial <$5M' },
    { value: 'RealEstateCommercialMoreThan5M', title: 'Real Estate - Commercial >$5M' },
    { value: 'RealEstateDevelopmentLandUse', title: 'Real Estate - Development & Land Use' },
    { value: 'SecuritiesLawsIncludingMunicipalBonds', title: 'Securities' },
    { value: 'SocialSecurity', title: 'Social Security' },
    { value: 'TaxOpinion', title: 'Tax Opinion' },
    { value: 'TaxationOpinions', title: 'Taxation' },
    { value: 'WorkersCompensationLitigation', title: "Worker's Compensation Defense" },
    { value: 'WorkersCompensationLitigationPlaintiff', title: "Worker's Compensation Plaintiff" },
];

export const MPLBundleUnderlyingAppTypeList = [
    'AppTypeCodeListMPL',
    'AppTypeCodeListCyberCowbell',
    'AppTypeCodeListBOPChubb',
    'AppTypeCodeListWCChubb',
];
type MPLBundleUnderlyingAppTypeList = typeof MPLBundleUnderlyingAppTypeList;
export type MPLBundleUnderlyingAppType = MPLBundleUnderlyingAppTypeList[number];
