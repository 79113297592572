import { container, inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, isOK, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass, execute } from '@embroker/shotwell/core/UseCase';
import { URI } from '@embroker/shotwell/core/types/URI';
import { EntityTypeRepository } from '../repositories/EntityTypeRepository';
import { GetActiveOrganizationProfile } from './GetActiveOrganizationProfile';
import { GetUserOnboardingDetails } from './GetUserOnboardingDetails';
import { MPLValidNaicsCodes } from '../types/MPLValidNaicsCodes';
import { State } from '@embroker/shotwell/core/types/StateList';
import { Nullable } from '@embroker/shotwell/core/types';
import { NAICS_CODE_TO_VERTICAL } from '../types/enums';
import {
    AppTypeCodeListESP,
    AppTypeCodeListManualWorkersCompensation,
    AppTypeCodeListTechEO,
} from '@app/shopping/types/enums';
import { isVariantExperimentTest } from '../../experimentation/types/ExperimentationTest';
import { GrowthBookExperimentationService } from '../../experimentation/services/GrowthBookExperimentationService';
import { OnboardingPrefillQuestionnaireData } from '../types/OnboardingPrefillQuestionnaireData';

export type GetOnboardingRedirectResponse = string;
export type GetOnboardingRedirectRequest = {
    questionnaireData?: OnboardingPrefillQuestionnaireData;
};

export interface GetOnboardingRedirect extends UseCase {
    execute(request?: GetOnboardingRedirectRequest): AsyncResult<string, never>;
}

const LAW_FIRM_NAICS = '541110';
export const LAW_BUNDLE_REDIRECT = '/shopping/law-bundle';
export const GUIDANCE_PAGE_REDIRECT = '/shopping/guidance-page';
export const DEFAULT_REDIRECT = '/shopping';

const validateDeeplink = (naicsCode: Nullable<string>, redirectUrl?: string): null | string => {
    const uri = URI.parse(redirectUrl ?? '');
    const isNewUser = uri.query.new;

    const shouldRedirectTechCompaniesToTechEO =
        redirectUrl?.includes('/shopping/cyber') &&
        naicsCode &&
        NAICS_CODE_TO_VERTICAL[naicsCode] === 'TechCompanies';

    const shouldOverrideDeeplink =
        isNewUser &&
        (redirectUrl?.includes('/shopping/cyber') ||
            redirectUrl?.includes('/shopping/bop') ||
            redirectUrl?.includes('/shopping/wc'));
    const shouldRedirectToLawBundle =
        shouldOverrideDeeplink && naicsCode && NAICS_CODE_TO_VERTICAL[naicsCode] === 'LawFirm';
    const shouldRedirectToMPLBundle =
        shouldOverrideDeeplink &&
        naicsCode &&
        (NAICS_CODE_TO_VERTICAL[naicsCode] === 'Accounting' ||
            NAICS_CODE_TO_VERTICAL[naicsCode] === 'TaxPreparation' ||
            NAICS_CODE_TO_VERTICAL[naicsCode] === 'RealEstateAgent' ||
            NAICS_CODE_TO_VERTICAL[naicsCode] === 'HomeInspectorAndBuildingInspectionServices' ||
            NAICS_CODE_TO_VERTICAL[naicsCode] === 'NonTechnologyConsultants');

    if (shouldRedirectToLawBundle || shouldRedirectToMPLBundle) {
        return null;
    }

    if (shouldRedirectTechCompaniesToTechEO) {
        const applicationUrl = URI.build('/shopping/questionnaire', {
            qd: JSON.stringify({
                app_type_list: [AppTypeCodeListTechEO],
            }),
        });
        return applicationUrl;
    }

    // If user is not LPL or MPL and trying to access worker's comp, we redirect him to the the manual worker's comp application
    if (redirectUrl?.includes('/shopping/wc')) {
        const applicationUrl = URI.build('/shopping/questionnaire', {
            qd: JSON.stringify({
                app_type_list: [AppTypeCodeListManualWorkersCompensation],
            }),
        });
        return applicationUrl;
    }

    return redirectUrl || null;
};

@injectable()
class GetOnboardingRedirectUseCase extends UseCase implements GetOnboardingRedirect {
    public static type = Symbol('Global/GetOnboardingRedirect');

    /**
     * constructor for the GetOnboardingRedirect use case
     * @param eventBus An event bus this Use Case will publish events to.
     * @param entityTypeRepository is the repository used to fetch globaly available data
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(EntityTypeRepository) private readonly entityTypeRepository: EntityTypeRepository,
    ) {
        super(eventBus);
    }

    private resolveOnboardingRedirectUrl(
        naicsCode: Nullable<string>,
        redirectUrl?: string,
    ): string {
        const validatedRedirectUrl = validateDeeplink(naicsCode, redirectUrl);
        if (validatedRedirectUrl) {
            return validatedRedirectUrl;
        }
        if (naicsCode === LAW_FIRM_NAICS) {
            return LAW_BUNDLE_REDIRECT;
        }
        if (naicsCode && NAICS_CODE_TO_VERTICAL[naicsCode] === 'TechCompanies') {
            const technologyVerticalOnboardingTest = container
                .get<GrowthBookExperimentationService>(GrowthBookExperimentationService)
                .getExperimentationTest('technology_vertical_onboarding');

            if (isVariantExperimentTest(technologyVerticalOnboardingTest)) {
                const applicationUrl = URI.build('/shopping/questionnaire', {
                    qd: JSON.stringify({
                        app_type_list: [AppTypeCodeListESP],
                    }),
                });
                return applicationUrl;
            }
        }

        const isMPLVertical = MPLValidNaicsCodes.isNaicCodeValid(naicsCode);
        const isMPLVerticalEnabled = MPLValidNaicsCodes.isMPLVerticalEnabled(naicsCode);

        if (isMPLVertical && isMPLVerticalEnabled) {
            return GUIDANCE_PAGE_REDIRECT;
        }
        return DEFAULT_REDIRECT;
    }

    private validateRedirectUrl(
        naicsCode: Nullable<string>,
        headquartersState: Nullable<State>,
        redirectUrl?: string,
    ): boolean {
        switch (naicsCode) {
            case LAW_FIRM_NAICS: {
                // Validate law firm NAICs and state combination
                const invalidLawStates: State[] = ['AK', 'OR'];
                const isRedirectToLawBundle = Boolean(
                    redirectUrl && redirectUrl.includes(LAW_BUNDLE_REDIRECT),
                );
                const isInvalidLawState = Boolean(
                    headquartersState && invalidLawStates.includes(headquartersState),
                );

                return !(isRedirectToLawBundle && isInvalidLawState);
            }
            default:
                return true;
        }
    }

    public async execute(
        request?: GetOnboardingRedirectRequest,
    ): AsyncResult<GetOnboardingRedirectResponse, never> {
        if (request?.questionnaireData) {
            OnboardingPrefillQuestionnaireData.update(request.questionnaireData);
        }

        const getActiveOrganizationProfileResponse = await execute(GetActiveOrganizationProfile);

        if (isErr(getActiveOrganizationProfileResponse)) {
            return Success<GetOnboardingRedirectResponse>('/');
        }

        const {
            value: { organization },
        } = getActiveOrganizationProfileResponse;
        const { naics, headquarters } = organization;

        const getUserOnboardingDetailsResp = await execute(GetUserOnboardingDetails);
        const redirectUrl = isOK(getUserOnboardingDetailsResp)
            ? getUserOnboardingDetailsResp.value?.redirectUrl
            : undefined;

        // TODO: As this redirect logic evolves, we will want to build out a more robust pattern.
        // It is tough to forsee the business logic right now, making it difficult to establish this patter at the moment.
        const onboardingRedirct = this.resolveOnboardingRedirectUrl(naics, redirectUrl);

        if (this.validateRedirectUrl(naics, headquarters.state, onboardingRedirct)) {
            return Success<GetOnboardingRedirectResponse>(onboardingRedirct);
        }

        return Success<GetOnboardingRedirectResponse>(DEFAULT_REDIRECT);
    }
}

export const GetOnboardingRedirect: UseCaseClass<GetOnboardingRedirect> =
    GetOnboardingRedirectUseCase;
