import { API, GlobalGetConfigResponse } from '@embroker/shotwell-api/app';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    mergeErrors,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { GetRecommendedRequest, RecommendRepository } from '.';
import { Coverage } from '../../../shopping/types/Coverage';
import {
    BOP,
    BOPChubb,
    CommercialAuto,
    CreateCoverageInput,
    Cyber,
    DO,
    EmbrokerCrime,
    EmbrokerCyber,
    EO,
    EPL,
    ESP,
    GAWorkersComp,
    GeneralLiability,
    LPL,
    NumberRangeOfW2Employees,
    PCoML,
    ProductLiability,
    Property,
    Umbrella,
    VentureCapitalAssetProtection,
    HOA,
    TechEO,
    MPL,
    WCChubb,
} from '../../../shopping/types/enums';
import { MPLValidNaicsCodes } from '../../../userOrg/types/MPLValidNaicsCodes';

type GetRecommendedInput = {
    readonly website?: string;
    readonly naicsGroupsFound: string[];
    readonly hasRaisedFunding?: boolean;
    readonly employeeCount?: number;
    readonly isTotalRevenueLargerThan20MillionDollars?: boolean;
    readonly hasAutomobiles?: boolean;
    readonly numberRangeOfW2Employees?: NumberRangeOfW2Employees;
};

@injectable()
export class APIRecommendRepository implements RecommendRepository {
    async getRecommended(
        input: GetRecommendedRequest,
    ): AsyncResult<Array<Coverage>, InvalidArgument | OperationFailed> {
        const configResponse = await API.request('global/get_config', {});
        if (isErr(configResponse)) {
            return handleOperationFailure(configResponse);
        }
        const config = configResponse.value as GlobalGetConfigResponse;

        const GetNaicsGroupsResponse = await API.request('global/get_naics_groups');

        if (isErr(GetNaicsGroupsResponse)) {
            return handleOperationFailure(GetNaicsGroupsResponse);
        }
        const naicsGroupsFound = [];
        for (const naicsGroupName in GetNaicsGroupsResponse.value) {
            if (GetNaicsGroupsResponse.value[naicsGroupName].includes(input.naicsCode)) {
                naicsGroupsFound.push(naicsGroupName);
            }
        }

        const getRecommendedInput: GetRecommendedInput = {
            ...input,
            naicsGroupsFound,
        };
        let list = getRecommendations(getRecommendedInput);
        if (!config.is_bop_enabled) {
            list = list.filter((item) => item.appType != BOP.appType);
        }
        if (!config.is_embroker_crime_enabled) {
            list = list.filter((item) => item.appType != EmbrokerCrime.appType);
        }
        if (!config.is_embroker_cyber_enabled) {
            list = list.filter((item) => item.appType != EmbrokerCyber.appType);
        } else {
            list = list.filter((item) => item.appType != Cyber.appType);
        }

        // TODO: https://embroker.atlassian.net/browse/EM-36785
        // The logic for recommended coverages seems to be diverging from the original/legacy implementation.
        // We should work on a plan or roadmap for how this logic will work and where it should live (FE/BE)
        if (config.is_mpl_enabled && MPLValidNaicsCodes.isNaicCodeValid(input.naicsCode)) {
            /**
             * If user industry is part of MPL, we should be displaying
             * BOPChubb instead of CNABOP
             * and
             * WCChubb instead of GAWorkersCompensation
             */
            list = list.filter((coverage) => coverage.appType !== BOP.appType);
            list.push(BOPChubb);
            list = list.filter((coverage) => coverage.appType !== GAWorkersComp.appType);
            list.push(WCChubb);

            list.push(MPL);
        }

        const promiseResultList = await Promise.all(list.map((item) => Coverage.create(item)));
        const result: Array<Coverage> = [];
        for (const coverageResult of promiseResultList) {
            if (isErr(coverageResult)) {
                return mergeErrors(promiseResultList);
            }
            result.push(coverageResult.value);
        }
        return Success(result);
    }
}

export function getRecommendations(input: GetRecommendedInput): Array<CreateCoverageInput> {
    const wcMinNumberOfEmployees = 0;
    const doMinNumberOfEmployees = 60;
    const bopMaxNumberOfEmployees = 40;
    const doEplMinNumberOfEmployees = 100;

    function mapEmployeeRangeToNumberOfEmployees(): number | undefined {
        if (input.numberRangeOfW2Employees === undefined) {
            return undefined;
        }
        const numberOfEmployeesMap: { [key in NumberRangeOfW2Employees]: number } = {
            NumberRangeOfW2EmployeesCodeList0: 0,
            NumberRangeOfW2EmployeesCodeList1To40: 20,
            NumberRangeOfW2EmployeesCodeList40To60: 50,
            NumberRangeOfW2EmployeesCodeList60To100: 80,
            NumberRangeOfW2EmployeesCodeListMoreThan100: 120,
        };

        return numberOfEmployeesMap[input.numberRangeOfW2Employees];
    }

    //TODO WHEN this logic is checked write unit test for each function
    function technologyRecommendations() {
        const result = [EmbrokerCyber];
        if (input.hasRaisedFunding === undefined || input.hasRaisedFunding) {
            result.push(ESP);
        } else {
            result.push(PCoML, DO, EPL, Cyber, EO, TechEO);
        }
        if (numberOfEmployees === undefined || numberOfEmployees > wcMinNumberOfEmployees) {
            result.push(EmbrokerCrime, GAWorkersComp);
        }
        if (input.isTotalRevenueLargerThan20MillionDollars) {
            result.push(GeneralLiability, Property, Umbrella);
        } else {
            result.push(BOP);
        }
        return result;
    }

    function accountingRecommendations() {
        const result = [EO, Cyber, EmbrokerCyber, BOP, GAWorkersComp, EmbrokerCrime];
        if (numberOfEmployees === undefined || numberOfEmployees > doMinNumberOfEmployees) {
            result.push(DO);
        }
        return result;
    }

    function lawFirmRecommendations() {
        const result = [LPL, Cyber, EmbrokerCyber, EPL, BOP, GAWorkersComp, EmbrokerCrime];
        if (numberOfEmployees === undefined || numberOfEmployees > doMinNumberOfEmployees) {
            result.push(PCoML, DO);
        }
        return result;
    }

    function VCPERecommendations() {
        const result = [
            VentureCapitalAssetProtection,
            Cyber,
            EmbrokerCyber,
            GAWorkersComp,
            EmbrokerCrime,
        ];
        if (numberOfEmployees && numberOfEmployees > bopMaxNumberOfEmployees) {
            result.push(GeneralLiability, Property, Umbrella);
        } else {
            result.push(BOP);
        }
        return result;
    }

    function financeRecommendations() {
        const financeExcludedIndustries = ['VentureCapital'];
        if (input.naicsGroupsFound.some((group) => financeExcludedIndustries.includes(group))) {
            return [];
        }
        const result = [GAWorkersComp, EmbrokerCrime, EmbrokerCyber];
        if (input.hasRaisedFunding || input.hasRaisedFunding === undefined) {
            result.push(ESP);
        } else {
            result.push(PCoML, Cyber, EO);
        }
        if (numberOfEmployees && numberOfEmployees > bopMaxNumberOfEmployees) {
            result.push(GeneralLiability, Property, Umbrella);
        } else {
            result.push(BOP);
        }
        return result;
    }

    function cannabisRecommendations() {
        const result = [GeneralLiability, ProductLiability, Property];
        if (input.hasRaisedFunding) {
            result.push(DO);
        }
        if (numberOfEmployees === undefined || numberOfEmployees > wcMinNumberOfEmployees) {
            result.push(GAWorkersComp, EPL);
        }
        return result;
    }

    function constructionRecommendations() {
        const result = [BOP, Umbrella];
        if (numberOfEmployees === undefined || numberOfEmployees > wcMinNumberOfEmployees) {
            result.push(EmbrokerCrime, GAWorkersComp);
        }
        if (numberOfEmployees && numberOfEmployees >= doEplMinNumberOfEmployees) {
            result.push(PCoML, DO, EPL);
        }
        if (input.hasAutomobiles === undefined || input.hasAutomobiles) {
            result.push(CommercialAuto);
        }
        return result;
    }

    function realEstateRecommendations() {
        const result = [GeneralLiability, Umbrella, Property];
        if (input.hasRaisedFunding) {
            result.push(ESP);
        } else {
            result.push(PCoML, DO, EPL);
        }
        if (numberOfEmployees === undefined || numberOfEmployees > wcMinNumberOfEmployees) {
            result.push(EmbrokerCrime, GAWorkersComp);
        }
        return result;
    }

    function retailRecommendations() {
        const result = [BOP, GAWorkersComp, EmbrokerCrime];
        if (input.hasRaisedFunding) {
            result.push(ESP);
        } else {
            result.push(Cyber, EmbrokerCyber);
        }
        return result;
    }

    function manufacturingRecommendations() {
        const result = [GeneralLiability, Umbrella, Property, ProductLiability];
        if (input.hasRaisedFunding === undefined || input.hasRaisedFunding) {
            result.push(ESP);
        } else {
            result.push(EPL);
        }
        if (numberOfEmployees === undefined || numberOfEmployees > wcMinNumberOfEmployees) {
            result.push(EmbrokerCrime, GAWorkersComp);
        }
        return result;
    }

    function FoodServiceRecommendations() {
        const result = [BOP, EmbrokerCrime, GAWorkersComp, Umbrella];
        if (input.hasRaisedFunding) {
            result.push(ESP);
        } else {
            result.push(EPL);
            if (numberOfEmployees && numberOfEmployees > doMinNumberOfEmployees) {
                result.push(PCoML, DO);
            }
        }
        if (input.hasAutomobiles === undefined || input.hasAutomobiles) {
            result.push(CommercialAuto);
        }
        return result;
    }

    function wholesaleRecommendations() {
        const result = [EmbrokerCrime, GAWorkersComp, GeneralLiability, Property];
        if (input.hasRaisedFunding || input.hasRaisedFunding === undefined) {
            result.push(ESP);
        } else {
            result.push(EPL);
        }
        if (input.hasAutomobiles === undefined || input.hasAutomobiles) {
            result.push(CommercialAuto);
        }
        return result;
    }

    function otherRecommendations() {
        const result = [];
        if (input.hasRaisedFunding === undefined || input.hasRaisedFunding) {
            result.push(ESP);
        }
        if (numberOfEmployees === undefined || numberOfEmployees > wcMinNumberOfEmployees) {
            result.push(EmbrokerCrime, GAWorkersComp);
        }
        if (
            input.isTotalRevenueLargerThan20MillionDollars === undefined ||
            input.isTotalRevenueLargerThan20MillionDollars
        ) {
            result.push(BOP);
        } else {
            result.push(GeneralLiability, Property, Umbrella);
        }
        if (input.hasAutomobiles === undefined || input.hasAutomobiles) {
            result.push(CommercialAuto);
        }
        return result;
    }

    function OfficeRecommendations() {
        const officeExcludedIndustries = ['LawFirm', 'HOA', 'Technology', 'Accounting', 'Finance'];
        if (input.naicsGroupsFound.some((group) => officeExcludedIndustries.includes(group))) {
            return [];
        }
        const result = [BOP, EmbrokerCrime, GAWorkersComp, EmbrokerCyber];
        if (input.hasRaisedFunding === undefined || input.hasRaisedFunding) {
            result.push(ESP);
        }
        return result;
    }

    function HOARecommendations() {
        const result = [HOA];
        return result;
    }

    const recommendationsMap: { [key: string]: () => Array<CreateCoverageInput> } = {
        Accounting: accountingRecommendations,
        CannabisHemp: cannabisRecommendations,
        Construction: constructionRecommendations,
        Finance: financeRecommendations,
        LawFirm: lawFirmRecommendations,
        Manufacturing: manufacturingRecommendations,
        Office: OfficeRecommendations,
        RealEstate: realEstateRecommendations,
        FoodServices: FoodServiceRecommendations,
        Retail: retailRecommendations,
        Technology: technologyRecommendations,
        VentureCapital: VCPERecommendations,
        Wholesale: wholesaleRecommendations,
        HOA: HOARecommendations,
        Other: otherRecommendations,
    };

    let numberOfEmployees = input.employeeCount;
    if (numberOfEmployees === undefined) {
        numberOfEmployees = mapEmployeeRangeToNumberOfEmployees();
    }

    const recommendedCoverages = [];
    for (const group of input.naicsGroupsFound) {
        const groupRecommendation = recommendationsMap[group];
        if (groupRecommendation) {
            recommendedCoverages.push(...groupRecommendation());
        }
    }
    const recommendedCoveragesDistinct = Array.from(new Set(recommendedCoverages));

    if (recommendedCoveragesDistinct.length == 0) {
        recommendedCoveragesDistinct.push(...otherRecommendations());
    }

    return recommendedCoveragesDistinct;
}
