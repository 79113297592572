import React from 'react';
import { HeroBanner, Text } from '@embroker/ui-toolkit/v2';

interface BundleHeroBannerProps {
    onDismiss: () => void;
    title: React.ReactNode;
    subtitle?: React.ReactNode;
}

export const BundleHeroBanner = ({
    onDismiss,
    title = 'Your Customizable Quote',
    subtitle,
}: BundleHeroBannerProps) => {
    return (
        <HeroBanner
            onDismiss={onDismiss}
            background="ui-300"
            dismissAppearance="save-and-exit"
            data-e2e="bundle-hero-banner"
        >
            <Text as="span" style="heading 1" color="brand-400">
                {title}
            </Text>
            {subtitle && (
                <Text as="span" style="heading 5" color="brand-400">
                    {subtitle}
                </Text>
            )}
        </HeroBanner>
    );
};
