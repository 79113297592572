import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { ErrorLike, isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    BoxLayout,
    Button,
    CardLayout,
    CenterLayout,
    ColumnLayout,
    Form,
    Image,
    InputStatus,
    Loader,
    PageLayout,
    Stack,
    StackLayout,
    Text,
    TextButton,
    useStableEventHandler,
    useStepper,
} from '@embroker/ui-toolkit/v2';
import { ErrorCode } from '../../../errors';
import { isAfter, isToday, startOfDay, startOfToday } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { GetConfig } from '../../../useCases/GetConfig';
import { GetSignatureDocumentUrl } from '../../../useCases/GetSignatureDocumentUrl';
import {
    SignSignatureRequest,
    SignSignatureRequestUseCaseResponse,
} from '../../../useCases/SignSignatureRequest';
import { navigateToErrorPage } from '../../../../view/errors';

interface SignSignatureRequestFormData {
    token: string;
    name: string | undefined;
    email: EmailAddress | undefined;
    title: string | undefined;
    date: Date;
    signature: boolean;
    acknowledgement: boolean;
}

function createSignSignatureRequestForm(setDocumentUrl: (url: string | undefined) => void) {
    return createForm<SignSignatureRequestFormData>({
        fields: {
            token: {
                type: 'text',
                validator: Joi.string().required(),
            },
            name: {
                type: 'text',
                validator: Joi.string().required(),
                formatValidationError() {
                    return 'Name is required.';
                },
            },
            email: {
                type: 'text',
                validator: EmailAddress.schema.required(),
                formatValidationError(error) {
                    switch (error.details.validator) {
                        case 'string.empty':
                            return 'E-mail is required.';
                        default:
                            return 'E-mail invalid.';
                    }
                },
            },
            title: {
                type: 'text',
                validator: Joi.string().required(),
                formatValidationError() {
                    return 'Title is required.';
                },
            },
            date: {
                type: 'date',
                validator: Joi.date().min(startOfToday()).required(),
                formatValidationError: (error) => {
                    switch (error.details.validator) {
                        case 'date.min':
                            return 'Date cannot be in the past.';
                        case 'any.required':
                            return 'You must enter a date.';
                        default:
                            return error.message;
                    }
                },
            },
            signature: {
                type: 'checkbox',
                validator: Joi.boolean().equal(true),
                formatValidationError() {
                    return 'Please mark the above checkbox in order to sign the packet digitally.';
                },
            },
            acknowledgement: {
                type: 'checkbox',
                validator: Joi.boolean().equal(true),
                formatValidationError() {
                    return 'Please mark the above checkbox in order to sign the packet digitally.';
                },
            },
        },
        formatSubmitErrors(errors) {
            if (errors.length > 0) {
                return errors.map((error) => error.message);
            }
            return [];
        },
        submit: async (request) => {
            return await execute(SignSignatureRequest, {
                signer_email: request.email || '',
                signer_name: request.name || '',
                signer_title: request.title || '',
                date: request.date,
                token: request.token,
            });
        },
        onSuccess: (response: SignSignatureRequestUseCaseResponse) => {
            setDocumentUrl(response.documentUrl);
        },
    });
}

interface DigitalSignatureProps {
    token: string;
    documentUrl?: string;
}

export function DigitalSignature({ token, documentUrl }: DigitalSignatureProps) {
    const { next, previous, activeStepIndex } = useStepper({
        steps: ['overview', 'form', 'success'],
        initialStep: 'overview',
    });
    const { navigate } = useNavigation();

    const [isEditApplicationEnabled, setIsEditApplicationEnabled] = useState<boolean>(false);
    const [isBeingSigned, setIsBeingSigned] = useState(false);
    const [docUrl, setDocUrl] = useState(documentUrl);
    const [signedDocUrl, setSignedDocUrl] = useState<string>();

    const abortController = useMemo(() => {
        return new AbortController();
    }, []);

    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, [abortController]);

    const { result } = useUseCase(GetSignatureDocumentUrl, {
        token: token,
        abortSignal: abortController.signal,
        existingDocumentUrl: documentUrl,
    });

    const { result: configResult } = useUseCase(GetConfig);

    useEffect(() => {
        if (configResult && isOK(configResult)) {
            setIsEditApplicationEnabled(configResult.value.isClientApplicationEditEnabled);
        }
    }, [configResult]);

    useEffect(() => {
        if (result) {
            if (isErr(result)) {
                const error = result.errors[0] as ErrorLike;
                if (error.code == ErrorCode.SignatureDocumentManagingBrokerNotFound) {
                    navigate('managing-broker-not-found');
                } else if (error.code == ErrorCode.SignatureDocumentAlreadySigned) {
                    navigate('already-signed');
                } else if (error.code == ErrorCode.SignatureDocumentNotFound) {
                    navigate('not-found');
                } else if (error.code == ErrorCode.SignatureDocumentNotAllowed) {
                    navigate('not-allowed');
                } else {
                    navigateToErrorPage(navigate, [error]);
                }
            }
            if (isOK(result)) {
                setDocUrl(result.value.documentUrl);
            }
        }
    }, [navigate, result]);

    const signSignatureRequestForm = useMemo(
        () => createSignSignatureRequestForm(setSignedDocUrl),
        [setSignedDocUrl],
    );

    const { fields, submit, messages, status, value, setValue } = useForm(
        signSignatureRequestForm,
        {
            token: token,
            date: startOfDay(Date.now()),
            signature: false,
            acknowledgement: false,
        },
    );

    useEffect(() => {
        if (status === 'submitted') {
            next();
        }
        setIsBeingSigned(status === 'submitting');
    }, [status, next, setIsBeingSigned]);

    const handleSignatureCheckBoxChange = useStableEventHandler(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            fields.signature.props.onChange({
                target: {
                    value: e.target.checked,
                },
            } as any);
        },
    );

    const handleAcknowledgementCheckBoxChange = useStableEventHandler(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            fields.acknowledgement.props.onChange({
                target: {
                    value: e.target.checked,
                },
            } as any);
        },
    );

    const handleDateChange = useCallback(
        (event: { target: { value: string; date: Date } }) => {
            const newDate = startOfDay(event.target.date);
            setValue({
                ...value,
                date: newDate,
            });
        },
        [setValue, value],
    );

    const editApplication = () => {
        navigate(
            URI.build('/review/application', {
                token,
            }),
        );
    };

    return (
        <PageLayout.Section>
            {!docUrl ? (
                <Loader heading="Analyzing" subheading="Your document is being prepared..." />
            ) : isBeingSigned ? (
                <Loader heading="Finalizing" subheading="Your document is being prepared..." />
            ) : (
                <Stack activeIndex={activeStepIndex}>
                    <StackLayout gap="32">
                        <Text style="heading 2">Signature request</Text>
                        {isEditApplicationEnabled ? (
                            <Text style="body 1">
                                Please review the below document and confirm all information is
                                correct.{' '}
                                <b>If any piece of information in the application is inaccurate</b>{' '}
                                please click the 'Edit Application' button. You will be presented
                                with an editable application, change the needed answer(s) and
                                finally click the 'Update application' button. Your broker will be
                                notified of the changes you have made.
                            </Text>
                        ) : (
                            <Text style="body 1">
                                Please review the below document and confirm all information is
                                correct. Contact your insurance representative if you have questions
                                or information is inaccurate.
                            </Text>
                        )}
                        <CardLayout>
                            <CardLayout.Header>
                                <ColumnLayout split="1">
                                    <Text style="heading 4">Insurance application</Text>
                                    <TextButton
                                        as="a"
                                        icon="download"
                                        target="_blank"
                                        href={docUrl}
                                    >
                                        Download PDF agreement
                                    </TextButton>
                                    {isEditApplicationEnabled ? (
                                        <TextButton
                                            as="button"
                                            icon="pencil"
                                            onClick={editApplication}
                                        >
                                            Edit Application
                                        </TextButton>
                                    ) : null}
                                </ColumnLayout>
                            </CardLayout.Header>
                            <CardLayout.Body>
                                <object
                                    type="application/pdf"
                                    data={docUrl}
                                    width="100%"
                                    height="500"
                                />
                            </CardLayout.Body>
                        </CardLayout>
                        <ColumnLayout split="-1">
                            <Button onClick={next}>Continue</Button>
                        </ColumnLayout>
                    </StackLayout>
                    <StackLayout gap="32">
                        <TextButton size="small" onClick={previous} icon="bold-caret-left">
                            Back
                        </TextButton>
                        <Text style="heading 2">Signature request</Text>
                        <InputStatus
                            messages={messages.map((text) => ({
                                text,
                                status: 'error',
                            }))}
                        />
                        <StackLayout gap="16" />
                        <ColumnLayout>
                            <Form.Field
                                data-e2e="signature-name"
                                className="u-1/2"
                                inputProps={{
                                    ...fields.name.props,
                                }}
                                label="Full Name"
                                messages={fields.name.messages}
                                type={fields.name.type}
                            />

                            <Form.Field
                                className="u-1/2"
                                inputProps={{
                                    value: fields.date.props.value,
                                    isSelectable: (date) =>
                                        isToday(date) || isAfter(date, startOfToday()),
                                    onChange: handleDateChange,
                                    disabled: true,
                                }}
                                label="Date"
                                messages={fields.date.messages}
                                type={fields.date.type}
                            />
                        </ColumnLayout>
                        <ColumnLayout>
                            <Form.Field
                                data-e2e="signature-title"
                                className="u-1/2"
                                inputProps={{
                                    ...fields.title.props,
                                }}
                                label="Title"
                                messages={fields.title.messages}
                                type={fields.title.type}
                            />

                            <Form.Field
                                data-e2e="signature-email"
                                className="u-1/2"
                                inputProps={{
                                    ...fields.email.props,
                                }}
                                label="Email"
                                messages={fields.email.messages}
                                type={fields.email.type}
                            />
                        </ColumnLayout>
                        <CardLayout>
                            <CardLayout.Header>
                                <Text style="heading 4">Signature</Text>
                            </CardLayout.Header>
                            <CardLayout.Body>
                                <StackLayout gap="32">
                                    <Form.Field
                                        data-e2e="signature-checkbox-one"
                                        inputProps={{
                                            checked: fields.signature.props.value,
                                            onChange: handleSignatureCheckBoxChange,
                                            children: (
                                                <Text>
                                                    By entering your name, you are electronically
                                                    signing an insurance application and confirm
                                                    that you have read and consent to Embroker's
                                                    Agreement to Conduct Electronic Transfers.
                                                </Text>
                                            ),
                                        }}
                                        messages={fields.signature.messages}
                                        type={fields.signature.type}
                                    />
                                    <Form.Field
                                        data-e2e="signature-checkbox-two"
                                        inputProps={{
                                            checked: fields.acknowledgement.props.value,
                                            onChange: handleAcknowledgementCheckBoxChange,
                                            children: (
                                                <Text>
                                                    I have reviewed the information in the insurance
                                                    application and attest that it is true and
                                                    correct and acknowledge that I have read and
                                                    agree to the fraud statement and any warranty
                                                    statements.
                                                </Text>
                                            ),
                                        }}
                                        messages={fields.acknowledgement.messages}
                                        type={fields.acknowledgement.type}
                                    />

                                    <Button type="submit" onClick={submit}>
                                        Submit
                                    </Button>
                                </StackLayout>
                            </CardLayout.Body>
                        </CardLayout>
                    </StackLayout>
                    <StackLayout split="1">
                        <BoxLayout gap="64">
                            <BoxLayout gap="64">
                                <StackLayout gap="32">
                                    <StackLayout gap="16">
                                        <CenterLayout>
                                            <Image inline={false} name="shotwell-business-2" />
                                        </CenterLayout>
                                        <CenterLayout centerText>
                                            <Text style="heading 1">Success!</Text>
                                            {signedDocUrl ? (
                                                <Text style="body 1">
                                                    You have successfully signed the document. You
                                                    can click the
                                                    <br />
                                                    button below to download the PDF copy of the
                                                    signature packet for
                                                    <br />
                                                    your archives.
                                                </Text>
                                            ) : (
                                                <Text style="body 1">
                                                    You have successfully signed the document.
                                                </Text>
                                            )}
                                        </CenterLayout>
                                    </StackLayout>
                                    {signedDocUrl ? (
                                        <CenterLayout>
                                            <TextButton
                                                as="a"
                                                icon="download"
                                                target="_blank"
                                                href={signedDocUrl}
                                            >
                                                Download Signature Packet
                                            </TextButton>
                                        </CenterLayout>
                                    ) : null}
                                </StackLayout>
                            </BoxLayout>
                        </BoxLayout>
                    </StackLayout>
                </Stack>
            )}
        </PageLayout.Section>
    );
}
