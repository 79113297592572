import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { ErrorLike } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { TextButton, Grid, Modal, ScrollBox, Text, useModal } from '@embroker/ui-toolkit/v2';
import React, { useCallback } from 'react';
import { CoverageRestriction } from '../../../../quote/esp/types/CoverageRestriction';
import { InsuranceApplicationRestriction } from '../../../../quote/esp/types/InsuranceApplicationRestriction';
import {
    AddESPEndorsementLiabilityCoverageType,
    AddESPEndorsementLiabilityCoverageTypesList,
    ESPEndorsementLiabilityCoverageType,
    ESPEndorsementLiabilityCoverageTypesList,
} from '../../types/ESPEndorsementLiabilityCoverageType';
import {
    ESPEndorsementLiabilityCoverage,
    ESPEndorsementPolicy,
} from '../../types/ESPEndorsementPolicy';
import { ESPEndorsementUserData } from '../../types/ESPEndorsementUserData';
import { ESPEndorsementAddCoverage } from './ESPEndorsementAddCoverage';
import { ESPEndorsementEditCoverage } from './ESPEndorsementEditCoverage';
import { ESPEndorsementEditESPTechSplitCoverage } from './ESPEndorsementEditESPTechSplitCoverage';
import { ESPEndorsementHigherLimitRequestModalContent } from './ESPEndorsementHigherLimitRequestModalContent.view';
import { getCoverageDisplayName } from './getCoverageDisplayName';
import { ESPEndorsementCardLayout } from '@app/endorsement/esp/view/components/ESPEndorsementCardLayout.view';
import {
    defaultDoLimitOptions,
    defaultEoCyberLimitOptions,
    defaultEpliLimitOptions,
} from './getESPLimitAndRetentionOptions';

interface ESPEndorsementEditCoverageRowProps {
    policyId: UUID;
    effectivePeriodStart: Date;
    effectivePeriodEnd: Date;
    coverage: ESPEndorsementLiabilityCoverage;
    userData: ESPEndorsementUserData;
    onPurchaseEndorsementFailure: (errors: Immutable<ErrorLike[]>) => void;
    onPurchaseEndorsementSuccess: (updatedPolicy: ESPEndorsementPolicy) => void;
    isEndorsementDisabled: boolean;
    restriction?: Immutable<CoverageRestriction>;
    cyberCoverage?: ESPEndorsementLiabilityCoverage;
    cyberRestriction?: Immutable<CoverageRestriction>;
    hasRenewalApplication?: boolean;
}

function ESPEndorsementEditCoverageRow({
    policyId,
    effectivePeriodStart,
    effectivePeriodEnd,
    coverage,
    userData,
    onPurchaseEndorsementFailure,
    onPurchaseEndorsementSuccess,
    isEndorsementDisabled,
    restriction,
    cyberCoverage,
    cyberRestriction,
    hasRenewalApplication,
}: ESPEndorsementEditCoverageRowProps) {
    const editCoverageModal = useModal();
    const higherLimitsModal = useModal();

    const handleOpenEditCoverageModal = useCallback(() => {
        editCoverageModal.show();
    }, [editCoverageModal]);

    const handleCloseEditCoverageModal = useCallback(() => {
        editCoverageModal.hide();
    }, [editCoverageModal]);

    const handleCloseHigherLimitsModal = useCallback(() => {
        higherLimitsModal.hide();
    }, [higherLimitsModal]);

    const handlePurchaseEndorsementSuccess = useCallback(
        (updatedPolicy: ESPEndorsementPolicy) => {
            editCoverageModal.hide();
            return onPurchaseEndorsementSuccess(updatedPolicy);
        },
        [editCoverageModal, onPurchaseEndorsementSuccess],
    );

    const handlePurchaseEndorsementFailure = useCallback(
        (errors: Immutable<ErrorLike[]>) => {
            editCoverageModal.hide();
            return onPurchaseEndorsementFailure(errors);
        },
        [editCoverageModal, onPurchaseEndorsementFailure],
    );

    const handleRequestHigherLimitSuccess = useCallback(() => {
        editCoverageModal.hide();
        higherLimitsModal.show();
    }, [editCoverageModal, higherLimitsModal]);

    return (
        <Grid.Row key={coverage.typeCode}>
            <Grid.Cell width="2/6">{getCoverageDisplayName(coverage.typeCode)}</Grid.Cell>
            <Grid.Cell width="1/6" data-e2e="coverage-limit">
                <MoneyDisplay value={coverage.limit} />
            </Grid.Cell>
            <Grid.Cell width="1/6" data-e2e="coverage-retention">
                <MoneyDisplay value={coverage.retention} />
            </Grid.Cell>
            <Grid.Cell width="1/6" data-e2e="coverage-premium">
                <MoneyDisplay value={coverage.premium} />
            </Grid.Cell>
            <Grid.Cell width="1/6">
                <TextButton
                    disabled={isEndorsementDisabled}
                    onClick={handleOpenEditCoverageModal}
                    data-e2e="edit-coverage"
                >
                    Edit
                </TextButton>
            </Grid.Cell>
            <br />
            <Modal {...editCoverageModal} size="medium">
                <ScrollBox>
                    {editCoverageModal.visible ? (
                        !!cyberCoverage &&
                        coverage.typeCode ===
                            'LiabilityCoverageCodeListTechnologyAndMediaErrorsAndOmissions' ? (
                            <ESPEndorsementEditESPTechSplitCoverage
                                policyId={policyId}
                                effectivePeriodStart={effectivePeriodStart}
                                effectivePeriodEnd={effectivePeriodEnd}
                                techCoverage={coverage}
                                cyberCoverage={cyberCoverage}
                                userData={userData}
                                onPurchaseEndorsementSuccess={handlePurchaseEndorsementSuccess}
                                onPurchaseEndorsementFailure={handlePurchaseEndorsementFailure}
                                onClose={handleCloseEditCoverageModal}
                                restriction={restriction}
                                onRequestHigherLimitSuccess={handleRequestHigherLimitSuccess}
                                cyberRestriction={cyberRestriction}
                                hasRenewalApplication={hasRenewalApplication}
                            />
                        ) : (
                            <ESPEndorsementEditCoverage
                                policyId={policyId}
                                effectivePeriodStart={effectivePeriodStart}
                                effectivePeriodEnd={effectivePeriodEnd}
                                coverage={coverage}
                                userData={userData}
                                onPurchaseEndorsementSuccess={handlePurchaseEndorsementSuccess}
                                onPurchaseEndorsementFailure={handlePurchaseEndorsementFailure}
                                onClose={handleCloseEditCoverageModal}
                                restriction={restriction}
                                onRequestHigherLimitSuccess={handleRequestHigherLimitSuccess}
                                hasRenewalApplication={hasRenewalApplication}
                            />
                        )
                    ) : (
                        ''
                    )}
                </ScrollBox>
            </Modal>
            <Modal {...higherLimitsModal} size="small">
                <ESPEndorsementHigherLimitRequestModalContent
                    onClose={handleCloseHigherLimitsModal}
                />
            </Modal>
        </Grid.Row>
    );
}

interface ESPEndorsementAddCoverageRowProps {
    policyId: UUID;
    effectivePeriodStart: Date;
    effectivePeriodEnd: Date;
    submittedAt: Nullable<string>;
    coverageTypeCode: AddESPEndorsementLiabilityCoverageType;
    userData: ESPEndorsementUserData;
    hasRenewalApplication?: boolean;
    onPurchaseEndorsementFailure: (errors: Immutable<ErrorLike[]>) => void;
    onPurchaseEndorsementSuccess: (updatedPolicy: ESPEndorsementPolicy) => void;
    isEndorsementDisabled: boolean;
    restriction?: Immutable<CoverageRestriction>;
}

function ESPEndorsementAddCoverageRow({
    policyId,
    effectivePeriodStart,
    effectivePeriodEnd,
    coverageTypeCode,
    submittedAt,
    userData,
    hasRenewalApplication,
    onPurchaseEndorsementFailure,
    onPurchaseEndorsementSuccess,
    isEndorsementDisabled,
    restriction,
}: ESPEndorsementAddCoverageRowProps) {
    const addCoverageModal = useModal();
    const higherLimitsModal = useModal();

    const handleOpenAddCoverageModal = useCallback(() => {
        addCoverageModal.show();
    }, [addCoverageModal]);

    const handleCloseAddCoverageModal = useCallback(() => {
        addCoverageModal.hide();
    }, [addCoverageModal]);

    const handleCloseHigherLimitsModal = useCallback(() => {
        higherLimitsModal.hide();
    }, [higherLimitsModal]);

    const handlePurchaseEndorsementSuccess = useCallback(
        (updatedPolicy: ESPEndorsementPolicy) => {
            addCoverageModal.hide();
            onPurchaseEndorsementSuccess(updatedPolicy);
        },
        [addCoverageModal, onPurchaseEndorsementSuccess],
    );

    const handlePurchaseEndorsementFailure = useCallback(
        (errors: Immutable<ErrorLike[]>) => {
            addCoverageModal.hide();
            return onPurchaseEndorsementFailure(errors);
        },
        [addCoverageModal, onPurchaseEndorsementFailure],
    );

    const handleRequestHigherLimitSuccess = useCallback(() => {
        addCoverageModal.hide();
        higherLimitsModal.show();
    }, [addCoverageModal, higherLimitsModal]);

    return (
        <Grid.Row key={coverageTypeCode}>
            <Grid.Cell width="2/6">{getCoverageDisplayName(coverageTypeCode)}</Grid.Cell>
            <Grid.Cell width="1/6">{'-'}</Grid.Cell>
            <Grid.Cell width="1/6">{'-'}</Grid.Cell>
            <Grid.Cell width="1/6">{'-'}</Grid.Cell>
            <Grid.Cell width="1/6">
                <TextButton disabled={isEndorsementDisabled} onClick={handleOpenAddCoverageModal}>
                    Add
                </TextButton>
            </Grid.Cell>
            <br />
            <Modal {...addCoverageModal} size="medium">
                <ScrollBox>
                    <ESPEndorsementAddCoverage
                        policyId={policyId}
                        effectivePeriodStart={effectivePeriodStart}
                        effectivePeriodEnd={effectivePeriodEnd}
                        submittedAt={submittedAt}
                        coverageTypeCode={coverageTypeCode}
                        userData={userData}
                        onPurchaseEndorsementSuccess={handlePurchaseEndorsementSuccess}
                        onPurchaseEndorsementFailure={handlePurchaseEndorsementFailure}
                        onClose={handleCloseAddCoverageModal}
                        restriction={restriction}
                        onRequestHigherLimitSuccess={handleRequestHigherLimitSuccess}
                        hasRenewalApplication={hasRenewalApplication}
                    />
                </ScrollBox>
            </Modal>
            <Modal {...higherLimitsModal} size="small">
                <ESPEndorsementHigherLimitRequestModalContent
                    onClose={handleCloseHigherLimitsModal}
                />
            </Modal>
        </Grid.Row>
    );
}

interface ESPEndorsementCoverageInfoRowProps {
    coverage: ESPEndorsementLiabilityCoverage;
}

function ESPEndorsementCoverageInfoRow({ coverage }: ESPEndorsementCoverageInfoRowProps) {
    return (
        <Grid.Row key={coverage.typeCode}>
            <Grid.Cell width="2/6">{getCoverageDisplayName(coverage.typeCode)}</Grid.Cell>
            <Grid.Cell width="1/6" data-e2e="coverage-limit">
                <MoneyDisplay value={coverage.limit} />{' '}
            </Grid.Cell>
            <Grid.Cell width="1/6" data-e2e="coverage-retention">
                <MoneyDisplay value={coverage.retention} />{' '}
            </Grid.Cell>
            <Grid.Cell width="1/6" data-e2e="coverage-premium">
                <MoneyDisplay value={coverage.premium} />{' '}
            </Grid.Cell>
            <br />
        </Grid.Row>
    );
}

interface ESPEndorsementNoCoverageInfoRowProps {
    coverageTypeCode: ESPEndorsementLiabilityCoverageType;
}

function ESPEndorsementNoCoverageInfoRow({
    coverageTypeCode,
}: ESPEndorsementNoCoverageInfoRowProps) {
    return (
        <Grid.Row key={coverageTypeCode}>
            <Grid.Cell width="2/6">{getCoverageDisplayName(coverageTypeCode)}</Grid.Cell>
            <Grid.Cell width="1/6">{'-'}</Grid.Cell>
            <Grid.Cell width="1/6">{'-'}</Grid.Cell>
            <Grid.Cell width="1/6">{'-'}</Grid.Cell>
            <br />
        </Grid.Row>
    );
}

/**
 * Function to get the last item in DefaultLimitOptions.
 *
 * TODO maybe some function could be useful here that will have like _.last(options), then this function could be replaced.
 *
 * @param options - type to pass is Array<{value: number, title: string}>
 * @returns Money for lastOption.value.
 */
function getMaxDefaultLimit(options: number[]): Money {
    return Money.tryFromFloat(options[options.length - 1]);
}

function isEditableCoverage(
    coverage: ESPEndorsementLiabilityCoverage,
    restriction?: Immutable<CoverageRestriction>,
) {
    const isFiduciary = coverage.typeCode === 'LiabilityCoverageCodeListFiduciaryLiability';
    const isDO =
        coverage.typeCode === 'LiabilityCoverageCodeListIndemnifiableDirectorsAndOfficersLiability';
    const isEO =
        coverage.typeCode === 'LiabilityCoverageCodeListTechnologyAndMediaErrorsAndOmissions';
    const isEPL = coverage.typeCode === 'LiabilityCoverageCodeListEmploymentPracticesLiability';

    const isMaxLimit =
        (isDO && Money.isEqual(getMaxDefaultLimit(defaultDoLimitOptions), coverage.limit)) ||
        (isEO && Money.isEqual(getMaxDefaultLimit(defaultEoCyberLimitOptions), coverage.limit)) ||
        (isEPL && Money.isEqual(getMaxDefaultLimit(defaultEpliLimitOptions), coverage.limit));

    const isMaxRestrictionLimit = restriction
        ? Money.isEqual(restriction.maxLimit, coverage.limit)
        : false;

    if (isEPL && isMaxRestrictionLimit) {
        return false;
    }

    return !isFiduciary && (!isMaxLimit || !isMaxRestrictionLimit);
}

function isAddableCoverageType(
    availableLiabilities: Immutable<Array<ESPEndorsementLiabilityCoverage>>,
    coverageTypeCode: ESPEndorsementLiabilityCoverageType,
    isEPLIEligible: boolean,
    isDNOEligible: boolean,
    isFiduciaryEligible: boolean,
    restriction?: Immutable<CoverageRestriction>,
) {
    if (restriction && !restriction.allowCoverage) {
        return false;
    }
    const addESPEndorsementLiabilityCoverageTypesList =
        AddESPEndorsementLiabilityCoverageTypesList as string[];
    if (
        coverageTypeCode === 'LiabilityCoverageCodeListEmploymentPracticesLiability' &&
        !isEPLIEligible
    ) {
        return false;
    }
    if (
        coverageTypeCode ===
            'LiabilityCoverageCodeListIndemnifiableDirectorsAndOfficersLiability' &&
        !isDNOEligible
    ) {
        return false;
    }
    if (
        coverageTypeCode === 'LiabilityCoverageCodeListFiduciaryLiability' &&
        !isFiduciaryEligible
    ) {
        return false;
    }
    if (addESPEndorsementLiabilityCoverageTypesList.includes(coverageTypeCode)) {
        return !availableLiabilities.map((al) => al.typeCode).includes(coverageTypeCode);
    }
    return false;
}

export function getCoverageRestriction(
    coverageType: ESPEndorsementLiabilityCoverageType,
    restriction?: Immutable<InsuranceApplicationRestriction>,
): CoverageRestriction | undefined {
    if (!restriction) {
        return;
    }

    const coverageRestriction = restriction.coverageRestrictions.find((cr) => {
        switch (coverageType) {
            case 'LiabilityCoverageCodeListIndemnifiableDirectorsAndOfficersLiability':
                return cr.coverageType === 'ShoppingCoverageCodeListDirectorsAndOfficers';
            case 'LiabilityCoverageCodeListEmploymentPracticesLiability':
                return cr.coverageType === 'ShoppingCoverageCodeListEmploymentPractices';
            case 'LiabilityCoverageCodeListFiduciaryLiability':
                return cr.coverageType === 'ShoppingCoverageCodeListFiduciary';
            case 'LiabilityCoverageCodeListTechnologyAndMediaErrorsAndOmissions':
                return (
                    cr.coverageType === 'ShoppingCoverageCodeListCyber' ||
                    cr.coverageType === 'ShoppingCoverageCodeListTechSplit'
                );
            case 'LiabilityCoverageCodeListCyberSplit':
                return cr.coverageType === 'ShoppingCoverageCodeListCyberSplit';
            default:
                return false;
        }
    });

    return coverageRestriction;
}

interface ESPEndorsementCoverageListSectionProps {
    policyId: UUID;
    restrictions?: Immutable<InsuranceApplicationRestriction>;
    effectivePeriodStart: Date;
    effectivePeriodEnd: Date;
    availableLiabilities: Immutable<Array<ESPEndorsementLiabilityCoverage>>;
    submittedAt: Nullable<string>;
    isEPLIEligible: boolean;
    isDNOEligible: boolean;
    isFiduciaryEligible: boolean;
    onPurchaseEndorsementSuccess: (updatedPolicy: ESPEndorsementPolicy) => void;
    onPurchaseEndorsementFailure: (errors: Immutable<ErrorLike[]>) => void;
    userData: ESPEndorsementUserData;
    isEndorsementDisabled: boolean;
    hasRenewalApplication?: boolean;
}

export function ESPEndorsementCoverageListSection({
    policyId,
    restrictions,
    effectivePeriodStart,
    effectivePeriodEnd,
    availableLiabilities,
    submittedAt,
    isEPLIEligible,
    isDNOEligible,
    isFiduciaryEligible,
    onPurchaseEndorsementSuccess,
    onPurchaseEndorsementFailure,
    userData,
    isEndorsementDisabled,
    hasRenewalApplication,
}: ESPEndorsementCoverageListSectionProps) {
    const cyberCoverage = availableLiabilities.find(
        (coverage) => coverage.typeCode === 'LiabilityCoverageCodeListCyberSplit',
    );
    return (
        <ESPEndorsementCardLayout>
            <Grid>
                <Grid.Row>
                    <Grid.Cell>
                        <Text style="heading 3">Coverages</Text>
                    </Grid.Cell>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Cell width="2/6" />
                    <Grid.Cell width="1/6">
                        <Text style="label 2" color="brand-500">
                            LIMIT
                        </Text>
                    </Grid.Cell>
                    <Grid.Cell width="1/6">
                        <Text style="label 2" color="brand-500">
                            RETENTION
                        </Text>
                    </Grid.Cell>
                    <Grid.Cell width="1/6">
                        <Text style="label 2" color="brand-500">
                            PREMIUM
                        </Text>
                    </Grid.Cell>
                    <Grid.Cell width="1/6" />
                </Grid.Row>
            </Grid>
            <hr />
            <Grid>
                <Grid.Row>
                    <Grid.Cell />
                </Grid.Row>
                {ESPEndorsementLiabilityCoverageTypesList.map((coverageTypeCode) => {
                    const coverage: ESPEndorsementLiabilityCoverage | undefined =
                        availableLiabilities.find((al) => al.typeCode === coverageTypeCode);
                    const coverageRestriction = getCoverageRestriction(
                        coverageTypeCode,
                        restrictions,
                    );
                    const cyberRestriction = getCoverageRestriction(
                        'LiabilityCoverageCodeListCyberSplit',
                        restrictions,
                    );
                    if (coverage && isEditableCoverage(coverage, coverageRestriction)) {
                        return (
                            <ESPEndorsementEditCoverageRow
                                key={coverageTypeCode}
                                policyId={policyId}
                                effectivePeriodStart={effectivePeriodStart}
                                effectivePeriodEnd={effectivePeriodEnd}
                                coverage={coverage}
                                userData={userData}
                                onPurchaseEndorsementFailure={onPurchaseEndorsementFailure}
                                onPurchaseEndorsementSuccess={onPurchaseEndorsementSuccess}
                                isEndorsementDisabled={isEndorsementDisabled}
                                restriction={coverageRestriction}
                                cyberCoverage={cyberCoverage}
                                cyberRestriction={cyberRestriction}
                                hasRenewalApplication={hasRenewalApplication}
                            />
                        );
                    }
                    if (
                        isAddableCoverageType(
                            availableLiabilities,
                            coverageTypeCode,
                            isEPLIEligible,
                            isDNOEligible,
                            isFiduciaryEligible,
                            coverageRestriction,
                        )
                    ) {
                        return (
                            <ESPEndorsementAddCoverageRow
                                key={coverageTypeCode}
                                policyId={policyId}
                                effectivePeriodStart={effectivePeriodStart}
                                effectivePeriodEnd={effectivePeriodEnd}
                                coverageTypeCode={
                                    coverageTypeCode as AddESPEndorsementLiabilityCoverageType
                                }
                                submittedAt={submittedAt}
                                userData={userData}
                                onPurchaseEndorsementFailure={onPurchaseEndorsementFailure}
                                onPurchaseEndorsementSuccess={onPurchaseEndorsementSuccess}
                                isEndorsementDisabled={isEndorsementDisabled}
                                restriction={coverageRestriction}
                                hasRenewalApplication={hasRenewalApplication}
                            />
                        );
                    }
                    if (coverage) {
                        return (
                            <ESPEndorsementCoverageInfoRow
                                key={coverageTypeCode}
                                coverage={coverage}
                            />
                        );
                    }
                    return (
                        <ESPEndorsementNoCoverageInfoRow
                            key={coverageTypeCode}
                            coverageTypeCode={coverageTypeCode}
                        />
                    );
                })}
            </Grid>
        </ESPEndorsementCardLayout>
    );
}
