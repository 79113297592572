import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { ColumnLayout, InsetBox, ScrollBox, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { ESPRenewalQuote } from '../../entities/ESPRenewalQuote';
import { espRenewalCoverageDetailsMap } from '../config';
import { Money } from '@embroker/shotwell/core/types/Money';

const lineStyle = { borderTop: '1px #d3dce7' };

export function ESPRenewalQuoteSummary({ quote }: { quote: ESPRenewalQuote }) {
    let isTechCyberSplit = false;
    const selectedCoverages = Object.keys(quote.options.coverage)
        .filter((key) => {
            if (quote.options.coverage[key].type === 'techCyber') {
                isTechCyberSplit = true;
            }
            return quote.options.coverage[key].isSelected;
        })
        .map((key) => quote.options.coverage[key]);

    const excludedCoverages = Object.keys(quote.options.coverage)
        .filter((key) => {
            if (isTechCyberSplit && quote.options.coverage[key].type === 'eo') {
                return false;
            }
            if (!isTechCyberSplit && quote.options.coverage[key].type === 'techCyber') {
                return false;
            }
            return quote.options.coverage[key].isSelected === false;
        })
        .map((key) => espRenewalCoverageDetailsMap[key].title);

    let message = null;
    if (excludedCoverages.length === 1) {
        message = (
            <Text>
                <b>{excludedCoverages[0]}</b> coverage was not included in this quote.
            </Text>
        );
    } else if (excludedCoverages.length === 2) {
        message = (
            <Text>
                <b>{excludedCoverages[0]}</b> and <b>{excludedCoverages[1]}</b> coverage were not
                included in this quote.
            </Text>
        );
    } else if (excludedCoverages.length === 3) {
        message = (
            <Text>
                <b>{excludedCoverages[0]}</b>, <b>{excludedCoverages[1]}</b> and{' '}
                <b>{excludedCoverages[2]}</b> coverage were not included in this quote.
            </Text>
        );
    }

    return (
        <StackLayout gap="24">
            <Text>
                You are about to bind a policy
                {quote.userInfo?.company ? ` for ${quote.userInfo.company}` : null}. Please confirm
                that the details listed below are correct:
            </Text>
            <ColumnLayout gap="8">
                <Text>Effective date: </Text>{' '}
                <Text style="heading 5" data-e2e="effective-date">
                    <DateDisplay value={quote.options.effectiveDate} />
                </Text>
            </ColumnLayout>
            <InsetBox>
                <ScrollBox>
                    <StackLayout gap="24">
                        {selectedCoverages.map((coverage, index) => (
                            <React.Fragment key={coverage.type}>
                                <Text style="heading 5" data-e2e="coverage-type-title">
                                    {espRenewalCoverageDetailsMap[coverage.type].title}
                                    {coverage.level
                                        ? coverage.level === 'standard'
                                            ? ' (Standard)'
                                            : ' (Plus)'
                                        : null}
                                </Text>
                                <ColumnLayout gap="24">
                                    {coverage.type != 'techCyber' && (
                                        <React.Fragment>
                                            <Text>
                                                <Text style="overline" color="brand-500">
                                                    LIMIT
                                                </Text>{' '}
                                                <MoneyDisplay
                                                    value={coverage.limit}
                                                    fractionDigits={0}
                                                />
                                            </Text>
                                            <Text>
                                                <Text style="overline" color="brand-500">
                                                    RETENTION
                                                </Text>{' '}
                                                <MoneyDisplay
                                                    value={coverage.retention}
                                                    fractionDigits={0}
                                                />
                                            </Text>
                                        </React.Fragment>
                                    )}
                                    {coverage.type == 'techCyber' && (
                                        <StackLayout>
                                            <Text style="overline" color="brand-500">
                                                TECHNOLOGY E&O
                                            </Text>{' '}
                                            <ColumnLayout gap="24">
                                                <Text>
                                                    <Text style="overline" color="brand-500">
                                                        LIMIT
                                                    </Text>{' '}
                                                    <MoneyDisplay
                                                        value={
                                                            coverage.limit ?? Money.tryFromFloat(0)
                                                        }
                                                        fractionDigits={0}
                                                    />
                                                </Text>
                                                <Text>
                                                    <Text style="overline" color="brand-500">
                                                        RETENTION
                                                    </Text>{' '}
                                                    <MoneyDisplay
                                                        value={
                                                            coverage.retention ??
                                                            Money.tryFromFloat(0)
                                                        }
                                                        fractionDigits={0}
                                                    />
                                                </Text>
                                            </ColumnLayout>
                                            <Text style="overline" color="brand-500">
                                                CYBER
                                            </Text>{' '}
                                            <ColumnLayout gap="24">
                                                <Text>
                                                    <Text style="overline" color="brand-500">
                                                        LIMIT
                                                    </Text>{' '}
                                                    <MoneyDisplay
                                                        value={
                                                            coverage.secondLimit ??
                                                            Money.tryFromFloat(0)
                                                        }
                                                        fractionDigits={0}
                                                    />
                                                </Text>
                                                <Text>
                                                    <Text style="overline" color="brand-500">
                                                        RETENTION
                                                    </Text>{' '}
                                                    <MoneyDisplay
                                                        value={
                                                            coverage.secondRetention ??
                                                            Money.tryFromFloat(0)
                                                        }
                                                        fractionDigits={0}
                                                    />
                                                </Text>
                                            </ColumnLayout>
                                        </StackLayout>
                                    )}
                                </ColumnLayout>
                                {index === selectedCoverages.length - 1 ? null : (
                                    <hr style={lineStyle} />
                                )}
                            </React.Fragment>
                        ))}
                    </StackLayout>
                </ScrollBox>
            </InsetBox>
            <ColumnLayout split="-1">
                <Text style="body 1" color="brand-500">
                    Total premium:
                </Text>
                <Text style="heading 5">
                    <MoneyDisplay value={quote.totalPayable} />
                </Text>
            </ColumnLayout>
            <Text style="microcopy">{message}</Text>
        </StackLayout>
    );
}
