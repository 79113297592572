import React from 'react';
import { AppTypeCode } from '../../types/enums';
import { getCoverageDetails } from './coverageDetails';
import { Icon } from '@embroker/ui-toolkit/v2';

interface ProductIconProps {
    type: AppTypeCode;
}

export function ProductIcon({ type }: ProductIconProps) {
    const { icon } = getCoverageDetails(type);

    return <Icon size="large" color="accent-400" name={icon} />;
}
