import React from 'react';
import { Icon, IconName } from '@embroker/ui-toolkit/v2';

interface CoverageCardIconProps {
    name: IconName;
}

export function CoverageCardIcon({ name }: CoverageCardIconProps) {
    return <Icon size="large" color="accent-400" name={name} />;
}
