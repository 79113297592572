import { execute } from '@embroker/shotwell/core/UseCase';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    Button,
    CheckBox,
    Form,
    Question,
    Spinner,
    StackLayout,
    StatusMessage,
    Text,
    WizardLayout,
} from '@embroker/ui-toolkit/v2';
import { isSameDay, isValid, startOfDay } from 'date-fns';
import React, { useCallback, useEffect } from 'react';
import {
    CreateOtherEndorsement,
    CreateOtherEndorsementRequest,
} from '../../useCases/CreateOtherEndorsement';

interface CreateOtherEndorsementRequestFormData {
    policyId: UUID;
    effectiveDate: Date;
    requestedChangeMessage: string;
    understandRequestConfirmation: boolean;
}

const endorsementRequestForm = createForm<CreateOtherEndorsementRequestFormData>({
    fields: {
        policyId: {
            type: 'hidden',
            validator: UUID.schema,
        },
        effectiveDate: {
            type: 'date',
            validator: Joi.date().required(),
            formatValidationError: (error) => {
                if (error.details.validator == 'any.required') {
                    return 'Effective date of change is required.';
                }
                return 'Effective date of change is invalid.';
            },
        },
        requestedChangeMessage: {
            type: 'textarea',
            validator: Joi.string().required(),
            formatValidationError: () => {
                return 'Change description is required';
            },
        },
        understandRequestConfirmation: {
            type: 'checkbox',
            validator: Joi.boolean().valid(true),
            formatValidationError: (error) => {
                if (error.details.validator == 'any.only') {
                    return 'Please agree with the statement above';
                }
                return error.message;
            },
        },
    },
    formatSubmitErrors(errors) {
        if (errors.length > 0) {
            return ['Oops! Something went wrong. Please try again later.'];
        }
        return [];
    },
    submit: async ({
        effectiveDate,
        requestedChangeMessage,
        policyId,
    }: CreateOtherEndorsementRequestFormData) => {
        const request: CreateOtherEndorsementRequest = {
            effectiveDate,
            requestedChangeMessage,
            policyId,
        };
        return await execute(CreateOtherEndorsement, request);
    },
});

interface OtherEndorsementPageProps {
    policyId: UUID;
    onDismiss: () => void;
}

export function OtherEndorsementPage({ policyId, onDismiss }: OtherEndorsementPageProps) {
    const { value, setValue, status, submit, fields } = useForm(endorsementRequestForm, {
        understandRequestConfirmation: false,
        policyId,
    });

    useEffect(() => {
        if (value.policyId !== policyId) {
            setValue({
                ...value,
                policyId,
            });
        }
    }, [policyId, setValue, value]);

    useEffect(() => {
        if (status === 'submitted') {
            onDismiss();
        }
    }, [status, onDismiss]);

    const handleEffectiveDateChange = useCallback(
        (event: { target: { value: Date; date: Date } }) => {
            const newDate = startOfDay(event.target.date);
            const isNotDateValid =
                !isValid(newDate) ||
                (value.effectiveDate !== null && isSameDay(value.effectiveDate, newDate));
            if (isNotDateValid) {
                return;
            }

            setValue({
                ...value,
                effectiveDate: newDate,
            });
        },
        [setValue, value],
    );

    const handleUnderstandRequestConfirmationChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setValue({
            ...value,
            understandRequestConfirmation: event.target.checked,
        });
    };

    const isLoading = status === 'submitting';
    if (isLoading) {
        return <Spinner />;
    }

    return (
        <React.Fragment>
            <Text style="heading 3" data-e2e="modify-policy-header">
                Make changes to your policy
            </Text>
            <Text style="body 1" data-e2e="modify-policy-body">
                Please answer a few questions for us so that we can process this request.
            </Text>
            <Form>
                <StackLayout gap="24">
                    <Question title="Effective date of change">
                        <Form.Field
                            inputProps={{
                                placeholder: 'Specify a date',
                                value: value.effectiveDate,
                                onChange: handleEffectiveDateChange,
                                disabled: isLoading,
                            }}
                            type={fields.effectiveDate.type}
                            messages={fields.effectiveDate.messages}
                            data-e2e="effective-date"
                        />
                    </Question>

                    <StatusMessage status="warning">
                        Please note that not all endorsements can be backdated, and we may need
                        additional information as we negotiate approval from the insurer.
                    </StatusMessage>

                    <Question title="Please state your requests">
                        <Form.Field
                            inputProps={{
                                ...fields.requestedChangeMessage.props,
                                placeholder: 'Description',
                                disabled: isLoading,
                            }}
                            type={fields.requestedChangeMessage.type}
                            messages={fields.requestedChangeMessage.messages}
                            data-e2e="request-message"
                        />
                    </Question>

                    <Form.Field messages={fields.understandRequestConfirmation.messages}>
                        <CheckBox
                            checked={fields.understandRequestConfirmation.props.value}
                            onChange={handleUnderstandRequestConfirmationChange}
                            data-e2e="understand-request-checkbox"
                        >
                            <span>
                                By checking this box, you understand that submitting a request does
                                not mean that the change is endorsed or bound - coverage has not
                                changed. The endorsement will be uploaded to your account once
                                complete, and you will be notified.
                            </span>
                        </CheckBox>
                    </Form.Field>
                    <WizardLayout.Actions>
                        <Button
                            disabled={isLoading}
                            onClick={() => {
                                submit();
                            }}
                            data-e2e="submit-request"
                        >
                            Submit request
                        </Button>
                    </WizardLayout.Actions>
                </StackLayout>
            </Form>
        </React.Fragment>
    );
}
