import { execute } from '@embroker/shotwell/core/UseCase';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    Button,
    Form,
    Question,
    Spinner,
    StackLayout,
    Text,
    TextButton,
    WizardLayout,
} from '@embroker/ui-toolkit/v2';
import { isBefore, isSameDay, isValid, startOfDay, startOfToday } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    RequestNameOrAddressChangeEndorsement,
    RequestNameOrAddressChangeEndorsementRequest,
} from '../../useCases/RequestNameOrAddressChangeEndorsement';
import {
    LocationFieldDefinition,
    LocationFieldSet,
} from '@app/userOrg/view/components/LocationFieldSet.view';
import { MailingAddress } from '@app/userOrg/types/MailingAddress';

interface NameOrAddressEndorsementRequestFormData {
    policyId: UUID;
    namedInsured: string;
    reasonForNameChange?: string;
    reasonForNameChangeAdditionalDetails?: string;
    headquarters: MailingAddress;
    effectiveDate: Date;
}

interface NameOrAddressChangeEndorsementProps {
    policyId: UUID;
    initialValue: {
        policyEffectiveDate: Date;
        policyExpirationDate: Date;
        namedInsured: string;
        headquarters: MailingAddress;
    };
    onDismiss: () => void;
}

export function NameOrAddressChangeEndorsement({
    policyId,
    initialValue,
    onDismiss,
}: NameOrAddressChangeEndorsementProps) {
    const endorsementRequestForm = useMemo(
        () =>
            createForm<NameOrAddressEndorsementRequestFormData>({
                fields: {
                    policyId: {
                        type: 'hidden',
                        validator: UUID.schema,
                    },
                    namedInsured: {
                        type: 'text',
                        validator: Joi.string().required(),
                    },
                    reasonForNameChange: {
                        type: 'select',
                        validator: Joi.when(Joi.ref('$namedInsured'), {
                            is: Joi.not(initialValue.namedInsured),
                            then: Joi.string().required(),
                            otherwise: Joi.string().optional(),
                        }),
                    },
                    reasonForNameChangeAdditionalDetails: {
                        type: 'textarea',
                        validator: Joi.when(Joi.ref('$reasonForNameChange'), {
                            switch: [
                                { is: 'Corporate Structure Change', then: Joi.required() },
                                { is: 'Other', then: Joi.required() },
                            ],
                            otherwise: Joi.optional(),
                        }),
                    },
                    headquarters: LocationFieldDefinition,
                    effectiveDate: {
                        type: 'date',
                        validator: Joi.date()
                            .required()
                            .min(
                                isBefore(startOfToday(), initialValue.policyEffectiveDate)
                                    ? initialValue.policyEffectiveDate
                                    : startOfToday(),
                            )
                            .max(initialValue.policyExpirationDate),
                        formatValidationError: (error) => {
                            if (error.details.validator == 'date.min') {
                                return 'Effective date of change cannot be in the past and prior the policy effective date.';
                            } else if (error.details.validator == 'date.max') {
                                return 'Effective date of change cannot be beyond policy expiration date.';
                            } else if (error.details.validator == 'any.required') {
                                return 'Effective date of change is required.';
                            }
                            return 'Effective date of change is invalid.';
                        },
                    },
                },
                formatSubmitErrors(errors) {
                    if (errors.length > 0) {
                        return ['Oops! Something went wrong. Please try again.'];
                    }
                    return [];
                },
                submit: async ({
                    effectiveDate,
                    policyId,
                    namedInsured,
                    reasonForNameChange,
                    reasonForNameChangeAdditionalDetails,
                    headquarters,
                }: NameOrAddressEndorsementRequestFormData) => {
                    const request: RequestNameOrAddressChangeEndorsementRequest = {
                        effectiveDate,
                        policyId,
                        insuredName: namedInsured,
                        reasonForChange: reasonForNameChange ?? '',
                        reasonForChangeAdditionalDetails: reasonForNameChangeAdditionalDetails,
                        address: headquarters.addressLine1 ?? '',
                        city: headquarters.city ?? '',
                        state: headquarters.state ?? '',
                        zip: headquarters.zip ?? '',
                    };

                    return await execute(RequestNameOrAddressChangeEndorsement, request);
                },
            }),
        [initialValue],
    );

    const { value, setValue, status, submit, fields } = useForm(endorsementRequestForm, {
        ...initialValue,
        policyId,
    });

    const [showAdditionalDetails, setShowAdditionalDetails] = useState<boolean>(false);

    const reasonsForNameChange = [
        { value: 'Corporate Structure Change', title: 'Corporate Structure Change' },
        { value: 'Correction', title: 'Correction' },
        { value: 'Other', title: 'Other' },
    ];

    useEffect(() => {
        if (value.policyId !== policyId) {
            setValue({
                ...value,
                policyId,
            });
        }
    }, [policyId, setValue, value]);

    useEffect(() => {
        if (status === 'submitted') {
            onDismiss();
        }
    }, [status, onDismiss]);

    const handleEffectiveDateChange = useCallback(
        (event: { target: { value: Date; date: Date } }) => {
            const newDate = startOfDay(event.target.date);
            const isInvalidDate =
                !isValid(newDate) ||
                (value.effectiveDate !== null && isSameDay(value.effectiveDate, newDate));
            if (isInvalidDate) {
                return;
            }

            setValue({
                ...value,
                effectiveDate: newDate,
            });
        },
        [setValue, value],
    );

    const handleReasonForNameChangeChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const reasonForNameChange = event.target.value;

            setValue({
                ...value,
                reasonForNameChange,
            });

            const reasonsWithAdditionalDetails = ['Corporate Structure Change', 'Other'];

            const shouldShowAdditionalDetails = reasonsWithAdditionalDetails.some(
                (r) => r === reasonForNameChange,
            );

            setShowAdditionalDetails(shouldShowAdditionalDetails);
        },
        [setValue, value],
    );

    const handleLocationChange = (headquarters: Partial<MailingAddress>) => {
        setValue({
            ...value,
            headquarters: headquarters as MailingAddress,
        });
    };

    const isLoading = status === 'submitting';
    if (isLoading) {
        return <Spinner />;
    }

    const displayStateChangeWarning =
        initialValue.headquarters.state !== null &&
        initialValue.headquarters.state !== value.headquarters.state;

    const displayReasonForNameChangeField =
        initialValue.namedInsured !== fields.namedInsured.props.value;

    return (
        <React.Fragment>
            <Text style="heading 3" data-e2e="modify-policy-header">
                Update name/address
            </Text>
            <Text style="body 1" data-e2e="modify-policy-body">
                We've pre-filled the fields below with your existing business name and address.
                Simply update where applicable and submit your changes for review. Once we've
                reviewed, we'll be in touch via email in 1-2 business days.
            </Text>
            <Form>
                <StackLayout gap="24">
                    <Question title="Named Insured">
                        <Text style="default">[Change if needed, if not leave it as is]</Text>
                        <Form.Field
                            inputProps={{
                                ...fields.namedInsured.props,
                                placeholder: 'Company name',
                                disabled: isLoading,
                            }}
                            type={fields.namedInsured.type}
                            messages={fields.namedInsured.messages}
                            data-e2e="named-insured-message"
                        />
                    </Question>

                    {displayReasonForNameChangeField ? (
                        <Question title="Reason for name change">
                            <Form.Field
                                inputProps={{
                                    ...fields.reasonForNameChange.props,
                                    placeholder: 'Select from the dropdown',
                                    items: reasonsForNameChange,
                                    onChange: handleReasonForNameChangeChange,
                                    disabled: isLoading,
                                }}
                                type={fields.reasonForNameChange.type}
                                messages={fields.reasonForNameChange.messages}
                                data-e2e="reason-for-name-change-message"
                            />
                            {showAdditionalDetails ? (
                                <Question>
                                    <Form.Field
                                        inputProps={{
                                            ...fields.reasonForNameChangeAdditionalDetails.props,
                                            placeholder: 'Additional details',
                                            disabled: isLoading,
                                        }}
                                        type={fields.reasonForNameChangeAdditionalDetails.type}
                                        messages={
                                            fields.reasonForNameChangeAdditionalDetails.messages
                                        }
                                        data-e2e="reason-for-name-change-additional-details-message"
                                    />
                                </Question>
                            ) : null}
                        </Question>
                    ) : null}

                    <Question title="Address">
                        <Text style="default">[Change if needed, if not leave it as is]</Text>
                        <LocationFieldSet
                            fieldValue={value.headquarters}
                            onChange={handleLocationChange}
                            messages={fields.headquarters.messages}
                            disableState
                        />
                    </Question>

                    {displayStateChangeWarning ? (
                        <Text style="body 2" data-e2e="contact-embroker-text">
                            <TextButton href="/support/contact" target="_blank">
                                Contact Embroker
                            </TextButton>{' '}
                            to make a change to your state
                        </Text>
                    ) : null}

                    <Question title="Effective date of change">
                        <Form.Field
                            inputProps={{
                                placeholder: 'Specify the effective date',
                                value: value.effectiveDate,
                                onChange: handleEffectiveDateChange,
                                disabled: isLoading,
                            }}
                            type={fields.effectiveDate.type}
                            messages={fields.effectiveDate.messages}
                            data-e2e="effective-date"
                        />
                    </Question>

                    <WizardLayout.Actions>
                        <Button
                            disabled={isLoading}
                            onClick={() => {
                                submit();
                            }}
                            data-e2e="submit-request"
                        >
                            Submit request
                        </Button>
                    </WizardLayout.Actions>
                </StackLayout>
            </Form>
        </React.Fragment>
    );
}
