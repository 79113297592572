import { useEffect, useRef, useState } from 'react';
import { execute } from '@embroker/shotwell/core/UseCase';
import { NBRVRedirectGateUseCase } from '../components/NBRVRedirectGate';
import { UserOnboardingStepType } from '@app/userOrg/types/UserOnboardingDetails';
import { GetUserOnboardingEntrypoint } from '@app/userOrg/useCases/GetUserOnboardingEntrypoint';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { GetActiveOrganizationProfile } from '@app/userOrg/useCases/GetActiveOrganizationProfile';
import {
    OnboardingWelcomeBackPopupEventType,
    PublishOnboardingWelcomeBackPopupEvent,
} from '@app/userOrg/useCases/PublishOnboardingWelcomeBackPopupEvent';
import { NBRVRedirectGateModalProps } from '../components/NBRVRedirectGateModal.view';
import { MPLVerticalList, NAICS_CODE_TO_VERTICAL } from '@app/userOrg/types/enums';
import {
    PublishSingleInProgressAppWelcomeBackPopupEvent,
    SingleInProgressAppWelcomeBackPopupEventType,
} from '@app/userOrg/useCases/PublishSingleInProgressAppWelcomeBackPopupEvent';
import {
    PublishSingleValidAndExpiredQuotePopupEvent,
    SingleValidAndExpiredQuotePopupEventType,
} from '@app/userOrg/useCases/PublishSingleValidAndExpiredQuotePopupEvent';
import { URI } from '@embroker/shotwell/core/types/URI';
import { useStartApplication } from '@app/shopping/view/hooks/useStartApplication';
import { BundleCoverageType } from '@app/bundle/types/BundleQuoteCoverage';
import { AppTypeCode, isAppTypeCode } from '@app/shopping/types/enums';
import { SetNBRVFunnelStatus } from '@app/userOrg/useCases/SetNBRVFunnelStatus';

export const DEFAULT_NB_RV_REDIRECTION = '/';

export const isContentURI = (
    content: ReturnType<typeof useNBRVRedirectGateModalContent>,
): content is URI => {
    return typeof content === 'string' && content !== 'loading';
};

const onStartOver = async (
    startApplication: ReturnType<typeof useStartApplication>['startApplication'],
    startMPLBundleApplication: ReturnType<typeof useStartApplication>['startMPLBundleApplication'],
    appType?: AppTypeCode | BundleCoverageType,
) => {
    if (appType !== undefined) {
        if (isAppTypeCode(appType)) {
            await execute(SetNBRVFunnelStatus, {
                shown: true,
                forceShow: false,
            });
            startApplication({ selectedAppTypes: [appType] });
        } else if (appType === 'MPLCoverage') {
            await execute(SetNBRVFunnelStatus, {
                shown: true,
                forceShow: false,
            });
            startMPLBundleApplication();
        } else if (
            appType === 'LPLBundleCoverage' ||
            appType === 'LawCyberBundleCoverageCowbell' ||
            appType === 'LawCyberBundleCoverage'
        ) {
            await execute(SetNBRVFunnelStatus, {
                shown: true,
                forceShow: false,
            });
            startApplication({
                selectedAppTypes: ['AppTypeCodeListLawBundle'],
            });
        }
    }
};

interface UseNBRVRedirectGateModalContent {
    useCase: NBRVRedirectGateUseCase;
    redirectTo: string;
    coverageName?: string;
    appType?: BundleCoverageType | AppTypeCode;
    onNavigate: (uri: URI | string) => void;
}

export function useNBRVRedirectGateModalContent({
    useCase,
    redirectTo,
    coverageName,
    appType,
    onNavigate,
}: UseNBRVRedirectGateModalContent): NBRVRedirectGateModalProps | URI | 'loading' | null {
    const { startApplication, startMPLBundleApplication } = useStartApplication();

    const [isCalculatingPopup, setIsCalculatingPopup] = useState(
        // use cases that require any (async) calculation
        // should be added to the array below
        ['noAppInProgressAndNoQuote'].includes(useCase),
    );

    const [naics, setNaics] = useState<string | null>();
    const [onboardingStepType, setOnboardingStepType] = useState<UserOnboardingStepType>();

    const alreadyFiredRef = useRef<{ [key: string]: boolean }>({});

    const fireAnalyticsEventOnce = (uniqueId: string, callback: () => void) => {
        if (alreadyFiredRef.current[uniqueId]) {
            return;
        }
        callback();
        alreadyFiredRef.current[uniqueId] = true;
    };

    useEffect(() => {
        if (useCase === 'noAppInProgressAndNoQuote') {
            (async () => {
                const userOnboadingEntrypoint = await execute(GetUserOnboardingEntrypoint);
                const nextOnboardingStep = isOK(userOnboadingEntrypoint)
                    ? userOnboadingEntrypoint.value
                    : null;

                const getActiveOrganizationProfileResponse = await execute(
                    GetActiveOrganizationProfile,
                );
                const naics = isOK(getActiveOrganizationProfileResponse)
                    ? getActiveOrganizationProfileResponse.value.organization.naics
                    : null;

                setNaics(naics);
                setOnboardingStepType(nextOnboardingStep?.stepType);
                setIsCalculatingPopup(false);
            })();
        }
    }, [useCase]);

    if (isCalculatingPopup) {
        return 'loading';
    }

    switch (useCase) {
        case 'singleValidQuote': {
            const onCloseOrDismiss = (eventType: SingleValidAndExpiredQuotePopupEventType) => {
                execute(PublishSingleValidAndExpiredQuotePopupEvent, {
                    eventType,
                });
                onNavigate(DEFAULT_NB_RV_REDIRECTION);
            };
            fireAnalyticsEventOnce(
                SingleValidAndExpiredQuotePopupEventType.singleValidQuotePopupDisplayed,
                () => {
                    execute(PublishSingleValidAndExpiredQuotePopupEvent, {
                        eventType:
                            SingleValidAndExpiredQuotePopupEventType.singleValidQuotePopupDisplayed,
                    });
                },
            );
            return {
                title: 'Your quote is ready!',
                children: `Your ${coverageName} quote is ready for review! 
                    Note: To purchase, make sure the effective date is either the current date or a future date.`,
                primaryAction: {
                    label: 'See Quote',
                    onClick: () => {
                        execute(PublishSingleValidAndExpiredQuotePopupEvent, {
                            eventType:
                                SingleValidAndExpiredQuotePopupEventType.singleValidQuotePopupContinueClicked,
                        });
                        onNavigate(redirectTo);
                    },
                },
                secondaryAction: {
                    label: 'Start Over',
                    onClick: () => {
                        execute(PublishSingleValidAndExpiredQuotePopupEvent, {
                            eventType:
                                SingleValidAndExpiredQuotePopupEventType.singleValidQuotePopupStartOver,
                        });
                        onStartOver(startApplication, startMPLBundleApplication, appType);
                    },
                },
                onClose: () =>
                    onCloseOrDismiss(
                        SingleValidAndExpiredQuotePopupEventType.singleValidQuotePopupClosed,
                    ),
                onDismiss: () =>
                    onCloseOrDismiss(
                        SingleValidAndExpiredQuotePopupEventType.singleValidQuotePopupClosed,
                    ),
            };
        }
        case 'singleExpiredQuote': {
            const onCloseOrDismiss = (eventType: SingleValidAndExpiredQuotePopupEventType) => {
                execute(PublishSingleValidAndExpiredQuotePopupEvent, {
                    eventType,
                });
                onNavigate(DEFAULT_NB_RV_REDIRECTION);
            };
            fireAnalyticsEventOnce(
                SingleValidAndExpiredQuotePopupEventType.singleExpiredQuotePopupDisplayed,
                () => {
                    execute(PublishSingleValidAndExpiredQuotePopupEvent, {
                        eventType:
                            SingleValidAndExpiredQuotePopupEventType.singleExpiredQuotePopupDisplayed,
                    });
                },
            );
            return {
                title: 'Welcome back!',
                children: `It looks like your ${coverageName} quote has expired since your last visit. 
                    Note: To get an updated quote, simply select a new effective date and click "Recalculate".`,
                primaryAction: {
                    label: 'See Quote',
                    onClick: () => {
                        execute(PublishSingleValidAndExpiredQuotePopupEvent, {
                            eventType:
                                SingleValidAndExpiredQuotePopupEventType.singleExpiredQuotePopupContinueClicked,
                        });
                        onNavigate(redirectTo);
                    },
                },
                secondaryAction: {
                    label: 'Start Over',
                    onClick: () => {
                        execute(PublishSingleValidAndExpiredQuotePopupEvent, {
                            eventType:
                                SingleValidAndExpiredQuotePopupEventType.singleExpiredQuotePopupStartOver,
                        });
                        onStartOver(startApplication, startMPLBundleApplication, appType);
                    },
                },
                onClose: () =>
                    onCloseOrDismiss(
                        SingleValidAndExpiredQuotePopupEventType.singleExpiredQuotePopupClosed,
                    ),
                onDismiss: () =>
                    onCloseOrDismiss(
                        SingleValidAndExpiredQuotePopupEventType.singleExpiredQuotePopupClosed,
                    ),
            };
        }
        case 'noAppInProgressAndNoQuote': {
            // check if user hasn't finished onboarding before leaving previously
            if (onboardingStepType) {
                // given businessLocation is the first step in the onboarding flow
                // we can assume that user is new to onboarding.
                // also, we want to memorize the initial value,
                // instead of tracking the changes
                const isNewToOnboarding = onboardingStepType === 'businessLocation';

                // left at onboarding without progress
                if (isNewToOnboarding) {
                    const onCloseOrDismiss = () => {
                        execute(PublishOnboardingWelcomeBackPopupEvent, {
                            eventType:
                                OnboardingWelcomeBackPopupEventType.startOnboardingPopupClosed,
                        });
                        onNavigate(DEFAULT_NB_RV_REDIRECTION);
                    };
                    fireAnalyticsEventOnce(
                        OnboardingWelcomeBackPopupEventType.startOnboardingPopupDisplayed,
                        () => {
                            execute(PublishOnboardingWelcomeBackPopupEvent, {
                                eventType:
                                    OnboardingWelcomeBackPopupEventType.startOnboardingPopupDisplayed,
                            });
                        },
                    );

                    return {
                        title: 'Welcome back!',
                        children:
                            "Need help figuring out your coverage needs? Just answer a few quick questions about your business and we'll help you find the right fit.",
                        primaryAction: {
                            label: 'Continue',
                            onClick: () => {
                                execute(PublishOnboardingWelcomeBackPopupEvent, {
                                    eventType:
                                        OnboardingWelcomeBackPopupEventType.startOnboardingPopupContinueClicked,
                                });
                                onNavigate(redirectTo);
                            },
                        },
                        secondaryAction: {
                            label: 'Cancel',
                            onClick: onCloseOrDismiss,
                        },
                        onClose: onCloseOrDismiss,
                        onDismiss: onCloseOrDismiss,
                    };
                }

                // left at onboarding with some progress
                const onCloseOrDismiss = () => {
                    execute(PublishOnboardingWelcomeBackPopupEvent, {
                        eventType:
                            OnboardingWelcomeBackPopupEventType.continueOnboardingPopupClosed,
                    });
                    onNavigate(DEFAULT_NB_RV_REDIRECTION);
                };

                fireAnalyticsEventOnce(
                    OnboardingWelcomeBackPopupEventType.continueOnboardingPopupDisplayed,
                    () => {
                        execute(PublishOnboardingWelcomeBackPopupEvent, {
                            eventType:
                                OnboardingWelcomeBackPopupEventType.continueOnboardingPopupDisplayed,
                        });
                    },
                );

                return {
                    title: 'Welcome back!',
                    children:
                        "Thanks for the info you've provided so far. We just need a few more details, then we can find you the best coverage for your business.",
                    primaryAction: {
                        label: 'Continue',
                        onClick: () => {
                            execute(PublishOnboardingWelcomeBackPopupEvent, {
                                eventType:
                                    OnboardingWelcomeBackPopupEventType.continueOnboardingPopupContinueClicked,
                            });
                            onNavigate(redirectTo);
                        },
                    },
                    secondaryAction: {
                        label: 'Cancel',
                        onClick: onCloseOrDismiss,
                    },
                    onClose: onCloseOrDismiss,
                    onDismiss: onCloseOrDismiss,
                };
            }

            // part of MPL or Law firm
            if (naics) {
                const vertical = NAICS_CODE_TO_VERTICAL[naics];
                if (MPLVerticalList.includes(vertical) || vertical === 'LawFirm') {
                    const onCloseOrDismiss = () => {
                        execute(PublishOnboardingWelcomeBackPopupEvent, {
                            eventType:
                                OnboardingWelcomeBackPopupEventType.productRecommendationPopupClosed,
                        });
                        onNavigate(DEFAULT_NB_RV_REDIRECTION);
                    };

                    fireAnalyticsEventOnce(
                        OnboardingWelcomeBackPopupEventType.productRecommendationPopupDisplayed,
                        () => {
                            execute(PublishOnboardingWelcomeBackPopupEvent, {
                                eventType:
                                    OnboardingWelcomeBackPopupEventType.productRecommendationPopupDisplayed,
                            });
                        },
                    );

                    return {
                        title: 'Welcome back!',
                        children:
                            "Great news — based on the info you gave us previously, we've identified some essential coverages for your business.",
                        primaryAction: {
                            label: 'Continue',
                            onClick: () => {
                                execute(PublishOnboardingWelcomeBackPopupEvent, {
                                    eventType:
                                        OnboardingWelcomeBackPopupEventType.productRecommendationPopupContinueClicked,
                                });
                                onNavigate(redirectTo);
                            },
                        },
                        secondaryAction: {
                            label: 'Cancel',
                            onClick: onCloseOrDismiss,
                        },
                        onClose: onCloseOrDismiss,
                        onDismiss: onCloseOrDismiss,
                    };
                }
            }

            // user finished onboarding and
            // is not part of neither MPL or Law firm
            return URI.build('/shopping');
        }

        case 'singleAppInProgress': {
            const onCloseOrDismiss = () => {
                execute(PublishSingleInProgressAppWelcomeBackPopupEvent, {
                    eventType:
                        SingleInProgressAppWelcomeBackPopupEventType.singleInProgressAppPopupClosed,
                });
                onNavigate(DEFAULT_NB_RV_REDIRECTION);
            };

            fireAnalyticsEventOnce(
                SingleInProgressAppWelcomeBackPopupEventType.singleInProgressAppPopupDisplayed,
                () => {
                    execute(PublishSingleInProgressAppWelcomeBackPopupEvent, {
                        eventType:
                            SingleInProgressAppWelcomeBackPopupEventType.singleInProgressAppPopupDisplayed,
                    });
                },
            );

            return {
                title: 'Welcome back!',
                children:
                    'Your insurance application is nearly complete! Simply provide a few more business details to get your quote',
                primaryAction: {
                    label: 'Continue',
                    onClick: () => {
                        execute(PublishSingleInProgressAppWelcomeBackPopupEvent, {
                            eventType:
                                SingleInProgressAppWelcomeBackPopupEventType.singleInProgressAppPopupContinueClicked,
                        });
                        onNavigate(redirectTo);
                    },
                },
                secondaryAction: {
                    label: 'Cancel',
                    onClick: onCloseOrDismiss,
                },
                onClose: onCloseOrDismiss,
                onDismiss: onCloseOrDismiss,
            };
        }
        case 'expiredAppExpiredQuote': {
            const onCloseOrDismiss = () => {
                execute(PublishSingleValidAndExpiredQuotePopupEvent, {
                    eventType:
                        SingleValidAndExpiredQuotePopupEventType.appExpiredSingleExpiredQuoteClosed,
                });
                onNavigate(DEFAULT_NB_RV_REDIRECTION);
            };

            fireAnalyticsEventOnce(
                SingleValidAndExpiredQuotePopupEventType.appExpiredSingleExpiredQuoteDisplayed,
                () => {
                    execute(PublishSingleValidAndExpiredQuotePopupEvent, {
                        eventType:
                            SingleValidAndExpiredQuotePopupEventType.appExpiredSingleExpiredQuoteDisplayed,
                    });
                },
            );

            return {
                title: 'Welcome back!',
                children: `It looks like both your quote and your application for ${coverageName} have expired 
                since your last visit, but your new quote is only a few clicks away!`,
                primaryAction: {
                    label: 'Continue',
                    onClick: () => {
                        execute(PublishSingleValidAndExpiredQuotePopupEvent, {
                            eventType:
                                SingleValidAndExpiredQuotePopupEventType.appExpiredSingleExpiredQuoteContinueClicked,
                        });
                        onNavigate(redirectTo);
                    },
                },
                secondaryAction: {
                    label: 'Cancel',
                    onClick: onCloseOrDismiss,
                },
                onClose: onCloseOrDismiss,
                onDismiss: onCloseOrDismiss,
            };
        }

        default:
            return null;
    }
}
