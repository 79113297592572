import { QuestionnaireData } from '../../../types/BundleQuoteQuestionnaireData';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface ESPQuestionnaireData extends QuestionnaireData {
    gross_revenue_total: number;
    raised_venture_funding: boolean;
}

export const ESPQuestionnaireData = {
    ...defineValidator<ESPQuestionnaireData>(
        QuestionnaireData.schema.keys({
            raised_venture_funding: Joi.boolean().required(),
        }),
    ),
    create(questionnaireData: ESPQuestionnaireData) {
        return ESPQuestionnaireData.validate(questionnaireData);
    },
};
