import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { execute } from '@embroker/shotwell/core/UseCase';
import { GetDocumentUrl } from '../../../documents/useCases/GetDocumentUrl';
import { QuotingEngineESP } from '../../../shopping/types/enums';
import { BundleCoverageType, BundleCoverageTypeEnum } from '../../types/BundleQuoteCoverage';
import { DocumentType } from '../../types/BundleQuoteDocument';
import { BuildBundleDocumentListProps } from '../../view/components/buildBundleDocumentList';
import { DocumentsItem } from '../../view/components/BundleQuoteLandingPage';
import {
    CoverageDefinition,
    ESPDocumentTitle,
    EspProductName,
    EspProductTitle,
    distributedPrefix,
    GetDocumentDownloadMetadataResponse,
    ESP,
    ProductFormData,
} from '../coverageDefinition';
import { ESPQuote } from './entities/ESPQuote';
import { buildESPCoverage } from './buildESPCoverage';
import { BundleQuote } from '@app/bundle/entities/BundleQuote';
import {
    ActionHandler,
    CreateFormParams,
    FormAction,
    OpaqueForm,
} from '@embroker/shotwell/view/hooks/useForm';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { keysOf } from '@embroker/shotwell/core/object';
import { getDocumentUrl } from '../bundleMappingFunctions';
import { WizardForm } from '@app/view/hooks/useWizardForm';
import { ESPBundleCoverageOptions } from './components/ESPBundleCoverageOptions';
import { ESPBundleSummary } from './components/ESPBundleSummary';
import { ESPModalCoverageSummary } from './components/ESPModalCoverageSummary';
import { ESPBundleFooter } from './components/ESPBundleFooter';
import {
    formDataToESPQuoteOptions,
    isESPFormData,
    isESPQuoteOptions,
    toApiESPQuoteOptions,
} from './mappingFunctions';
import { coverageOptionSchema, ESPQuoteOptions } from './types/ESPQuoteOptions';

export const ESPCoverageDefinition: CoverageDefinition<ESPQuote> = {
    productName: EspProductName,
    productTitle: EspProductTitle,
    documentTitle: ESPDocumentTitle,
    type: BundleCoverageTypeEnum.ESPCoverage,
    quotingEngine: QuotingEngineESP,
    buildCoverage: buildESPCoverage,
    mapFormDataToQuoteOptions: formDataToESPQuoteOptions,
    apiProductDesignation: ESP,
    mapQuoteOptionsToAPI: toApiESPQuoteOptions,
    coverageOptionsComponent: ESPBundleCoverageOptions,
    summaryComponent: ESPBundleSummary,
    modalSummaryComponent: ESPModalCoverageSummary,
    footerComponent: ESPBundleFooter,
    isFieldsValidType(
        input: any,
    ): input is WizardForm<OpaqueForm<distributedPrefix<ESPQuoteOptions>>>['fields'] {
        return isESPFormData(input);
    },
    isOptionsValidType(input: any): input is ESPQuoteOptions {
        return isESPQuoteOptions(input);
    },
    getProductFields(
        bundleQuote: BundleQuote,
    ): CreateFormParams<ProductFormData<distributedPrefix<ESPQuoteOptions>>>['fields'] {
        // TODO: https://embroker.atlassian.net/browse/EM-43848
        // These fileds are set up to be implemented upom in a follow up ticket
        // Can be considered as a placeholder for now
        return {
            espDirectorsAndOfficers: {
                type: 'text',
                validator: coverageOptionSchema.required(),
            },
            espEmploymentPracticesLiability: {
                type: 'text',
                validator: coverageOptionSchema.required(),
            },
            espFiduciary: {
                type: 'text',
                validator: Joi.object({
                    selected: Joi.boolean().required(),
                }).required(),
            },
            espTechnology: {
                type: 'text',
                validator: coverageOptionSchema.optional(),
            },
            espCyber: {
                type: 'text',
                validator: coverageOptionSchema.optional(),
            },
            espPartnerCode: {
                type: 'text',
                validator: Joi.string().allow('').required(),
            },
            espPolicyFee: {
                type: 'currency',
                validator: Joi.number().allow(null).required(),
            },
            espSelected: {
                type: 'checkbox',
                validator: Joi.boolean(),
            },
            espIsPolicyFeeTaxable: {
                type: 'checkbox',
                validator: Joi.boolean(),
            },
        };
    },
    getProductActions(
        bundleQuote: BundleQuote,
        abortSignal: AbortSignal,
    ): Record<
        FormAction,
        | {
              action: ActionHandler<ESPQuoteOptions>;
              fields: (keyof distributedPrefix<ESPQuoteOptions>)[];
          }
        | ActionHandler<ProductFormData<ESPQuoteOptions>>
    > {
        const formFields = this.getProductFields(bundleQuote);
        return {
            generateESPQuoteDocument: {
                action: async () => {
                    return getDocumentUrl(
                        bundleQuote,
                        BundleCoverageTypeEnum.ESPCoverage,
                        DocumentType.QuoteDocument,
                        abortSignal,
                    );
                },
                fields: ['effectiveDate', ...keysOf(formFields)],
            },
        };
    },
    getCoverageDocuments(props: BuildBundleDocumentListProps): DocumentsItem[] | undefined {
        return undefined;
    },
    getInitialValues(bundleQuote): Record<string, unknown> {
        const epsCoverage = bundleQuote.coverageList.find(
            (coverage) => coverage.type === this.type,
        );

        if (
            epsCoverage === undefined ||
            epsCoverage.quote === undefined ||
            !this.isOptionsValidType(epsCoverage.quote.options)
        ) {
            return {};
        }

        const { options } = epsCoverage.quote;
        return {
            espSelected: true,
            espIsPolicyFeeTaxable: false,
            espDirectorsAndOfficers: options.directorsAndOfficers,
            espEmploymentPracticesLiability: options.employmentPracticesLiability,
            espFiduciary: options.fiduciary,
            espTechnology: options.technology,
            espCyber: options.cyber,
            espPartnerCode: options.partnerCode,
            espPolicyFee: options.policyFee?.amount ?? 0,
        };
    },
    toggleSelected(value: boolean): Record<string, unknown> {
        return { espSelected: value };
    },
    getMaxFutureDaysAllowed(): number {
        return 90;
    },
    async getDocumentDownloadMetadata(
        documentType: DocumentType,
        espQuote?: ESPQuote,
    ): AsyncResult<GetDocumentDownloadMetadataResponse, OperationFailed | InvalidArgument> {
        let downloadUrlResult;
        switch (documentType) {
            case DocumentType.QuoteDocument:
                if (espQuote?.fileKey === undefined) {
                    return Failure(InvalidArgument({ argument: 'fileKey', value: documentType }));
                }
                downloadUrlResult = await execute(GetDocumentUrl, {
                    fileKey: espQuote.fileKey,
                });
                if (isErr(downloadUrlResult)) {
                    return downloadUrlResult;
                }
                return Success({
                    fileKey: espQuote.fileKey,
                    downloadUrl: downloadUrlResult.value.downloadUrl,
                });
            default:
                return Failure(InvalidArgument({ argument: 'fileKey', value: documentType }));
        }
    },
    onSuccessHandler(
        value: any,
        action: string,
        setDocumentUrl: (
            url: URI,
            documentType: DocumentType,
            bundleCoverageType: BundleCoverageType,
        ) => void,
        onGenerateDocument: () => void,
    ) {
        switch (action) {
            case 'generateESPQuoteDocument':
                setDocumentUrl(
                    value.fileUrl,
                    DocumentType.QuoteDocument,
                    BundleCoverageTypeEnum.ESPCoverage,
                );
                onGenerateDocument();
        }
    },
    isLimitHigherThenAllowed() {
        return false;
    },
    getPremiumRange() {
        return undefined;
    },
};
