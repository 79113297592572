import { StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';

export function ESPBundleFooter() {
    return (
        <StackLayout gap="none">
            <Text>ESP Bundle Footer</Text>
        </StackLayout>
    );
}
