import { injectable } from '@embroker/shotwell/core/di';
import {
    AsyncResult,
    Failure,
    Success,
    handleOperationFailure,
    isErr,
} from '@embroker/shotwell/core/types/Result';
import { QuestionerRepository } from '.';
import { FailedToGetQuestionerForm, FailedToSaveQuestionerAnswers } from '../errors';
import { QuestionerQuestion } from '../types/QuestionerQuestionType';
import { API } from '@embroker/shotwell-api/v2/app';
import { Section } from '../types/QuestionerSectionType';
import { OracleAnswer } from '../types/OracleAnswerType';

export interface SaveQuestionnaireAnswersRequest {
    answers: OracleAnswer[];
}
export type SaveQuestionnaireAnswersResponse = {};
export interface GetQuestionnaireParams {
    questionnaireIds: string[];
    includeAnswers: boolean;
}
export interface GetQuestionerFormRepositoryResponse {
    questions: QuestionerQuestion[];
    sections?: Section[];
    initialPage?: string;
}
@injectable()
export class APIQuestionerRepository implements QuestionerRepository {
    public async getQuestionerForm(
        request: GetQuestionnaireParams,
    ): AsyncResult<GetQuestionerFormRepositoryResponse, FailedToGetQuestionerForm> {
        const { includeAnswers, questionnaireIds } = request;

        const questionnaireIdsString = questionnaireIds.join('&questionnaire_ids=');
        const questionnaireResult = await API.get(
            `questions?includeAnswers=${includeAnswers}&questionnaire_ids=${questionnaireIdsString}`,
        );

        if (isErr(questionnaireResult)) {
            return Failure(FailedToGetQuestionerForm());
        }

        const respQuestions: QuestionerQuestion[] = [];
        const questionResults = await Promise.all(
            questionnaireResult.value.questions.map((question) => createQuestion(question)),
        );

        const respSections: Section[] = [];
        const sectionResults = await Promise.all(
            questionnaireResult.value.sections.map((section) => createSection(section)),
        );

        for (const result of questionResults) {
            if (isErr(result)) {
                // TODO: https://embroker.atlassian.net/browse/EM-43041
                // We want to be defensive in how we handle errors
                // In this scenario we may want to ensure there is a falback question to ensure a user can input something. For now we skip over it and remove from the questionnaire.
            } else {
                const questionerQuestion = result.value.value as QuestionerQuestion;
                respQuestions.push(questionerQuestion);
            }
        }
        for (const result of sectionResults) {
            if (isErr(result)) {
                // TODO: https://embroker.atlassian.net/browse/EM-43041
                // We want to be defensive in how we handle errors
                // In this scenario we may want to ensure that malformed sections do not blow upn the user experience. For now we skip over it and remove from the questionnaire.
            } else {
                const section = result.value.value as Section;
                respSections.push(section);
            }
        }

        // TODO: https://embroker.atlassian.net/browse/EM-43691
        // We will need to fetch the initial page from the BE, how we do this will depend on pending BE implementation
        const initialPage = undefined;

        return Success({ questions: respQuestions, sections: respSections, initialPage });
    }
    public async saveQuestionerAnswers(
        request: SaveQuestionnaireAnswersRequest,
    ): AsyncResult<SaveQuestionnaireAnswersResponse, FailedToSaveQuestionerAnswers> {
        const { answers } = request;
        const saveAnswersResult = await API.post(`answers`, answers);

        if (isErr(saveAnswersResult)) {
            return Failure(FailedToSaveQuestionerAnswers());
        }

        return Success({});
    }
}

export const createQuestion = async (questionObj: unknown) => {
    const question = QuestionerQuestion.create(questionObj);
    if (isErr(question)) {
        return handleOperationFailure(question);
    }
    return Success(question);
};

export const createSection = async (sectionObj: unknown) => {
    const section = Section.create(sectionObj);
    if (isErr(section)) {
        return handleOperationFailure(section);
    }
    return Success(section);
};
