import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { execute, UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { GetGlobalConfig } from '../../config/useCases/GetGlobalConfigUseCase';
import * as enums from '../../shopping/types/enums';
import { InsuranceApplicationStatusCode } from '../../shopping/types/enums';
import { BundleQuote, CoverageListSummary } from '../entities/BundleQuote';
import { BundleQuoteRepository } from '../repositories';
import { PurchaseBundleQuote } from './PurchaseBundleQuote';
import { RequestHigherLimit } from './RequestHigherLimit';
import { RequestReferredQuoteReview } from './RequestReferredQuoteReview';
import { SetApplicationStatus } from './SetApplicationStatus';

export interface FinalizeBundleQuoteRequest {
    bundleQuote: BundleQuote;
}

export interface FinalizeBundleQuote extends UseCase {
    execute(
        request: FinalizeBundleQuoteRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed>;
}

@injectable()
class FinalizeBundleQuoteUseCase extends UseCase implements FinalizeBundleQuote {
    public static type = Symbol('BundleQuote/FinalizeBundleQuote');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BundleQuoteRepository) private bundleQuoteRepository: BundleQuoteRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: FinalizeBundleQuoteRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed> {
        const globalConfigResult = await execute(GetGlobalConfig);
        if (isErr(globalConfigResult)) {
            return Failure(
                OperationFailed({
                    errors: globalConfigResult.errors,
                }),
            );
        }

        const newBundleFlowEnabled = globalConfigResult.value.config.newBundleFlowEnabled;

        const summary = request.bundleQuote.getCoverageListSummary();

        if (this.isBundleApplicationBindable(summary) || newBundleFlowEnabled) {
            if (summary.bindableQuoteList.length) {
                const purchaseResult = await execute(PurchaseBundleQuote, {
                    bundleQuote: request.bundleQuote,
                });
                if (isErr(purchaseResult)) {
                    return Failure(
                        OperationFailed({
                            errors: purchaseResult.errors,
                        }),
                    );
                }
            }

            if (summary.deselectedQuoteList.length) {
                const cancelResult = await execute(SetApplicationStatus, {
                    applicationStatusList: summary.deselectedQuoteList.map((quote) => ({
                        applicationId: quote.applicationId,
                        applicationStatus: enums.CanceledByAdmin as InsuranceApplicationStatusCode,
                    })),
                });
                if (isErr(cancelResult)) {
                    return Failure(
                        OperationFailed({
                            errors: cancelResult.errors,
                        }),
                    );
                }
            }

            if (
                this.isBundleApplication(request.bundleQuote) &&
                this.isBundleApplicationBindable(summary)
            ) {
                const result = await execute(SetApplicationStatus, {
                    applicationStatusList: [
                        {
                            applicationId: request.bundleQuote.applicationId,
                            applicationStatus: enums.Purchased,
                        },
                    ],
                });
                if (isErr(result)) {
                    return Failure(
                        OperationFailed({
                            errors: result.errors,
                        }),
                    );
                }
            }
        }
        if (!this.isBundleApplicationBindable(summary) || newBundleFlowEnabled) {
            if (summary.indicationList.length) {
                const hlRequestResult = await execute(RequestHigherLimit, {
                    bundleAppId: request.bundleQuote.applicationId,
                    applicationIdList: summary.indicationList.map(
                        (indication) => indication.applicationId,
                    ),
                });
                if (isErr(hlRequestResult)) {
                    return Failure(
                        OperationFailed({
                            errors: hlRequestResult.errors,
                        }),
                    );
                }
            }

            if (summary.referredQuoteList.length || summary.currentLimitExceedList.length) {
                const reviewRequestResult = await execute(RequestReferredQuoteReview, {
                    applicationIdList: [
                        ...summary.referredQuoteList.map((indication) => indication.applicationId),
                        ...summary.currentLimitExceedList.map(
                            (indication) => indication.applicationId,
                        ),
                    ],
                });
                if (isErr(reviewRequestResult)) {
                    return Failure(
                        OperationFailed({
                            errors: reviewRequestResult.errors,
                        }),
                    );
                }
            }
        }

        return Success();
    }

    isBundleApplicationBindable(coverageListSummary: CoverageListSummary) {
        return !(
            coverageListSummary.indicationList.length > 0 ||
            coverageListSummary.currentLimitExceedList.length > 0 ||
            coverageListSummary.referredQuoteList.length > 0
        );
    }

    isBundleApplication(bundleQuote: BundleQuote) {
        const coverageList = bundleQuote.coverageList;
        return !(
            coverageList.length === 1 &&
            bundleQuote.applicationId === coverageList[0].quote?.applicationId
        );
    }
}

export const FinalizeBundleQuote: UseCaseClass<FinalizeBundleQuote> = FinalizeBundleQuoteUseCase;
