import {
    Button,
    ButtonBar,
    ColumnLayout,
    StackLayout,
    Text,
    Tooltip,
} from '@embroker/ui-toolkit/v2';
import React, { PropsWithChildren } from 'react';
import { BundleQuoteCardLayout } from './BundleQuoteCardLayout.view';

export interface BundleQuoteTotalCardProps {
    buttonContent: string;
    label: React.ReactNode;
    onClick: () => void;
    title: React.ReactNode;
    titleTooltip: React.ReactNode;
}

export function BundleQuoteTotalCard({
    children,
    buttonContent,
    label,
    onClick,
    title = 'Quote Total',
    titleTooltip,
}: PropsWithChildren<BundleQuoteTotalCardProps>) {
    return (
        <BundleQuoteCardLayout>
            <StackLayout gap="24">
                <StackLayout gap="12">
                    <StackLayout gap="none">
                        <ColumnLayout gap="4" center>
                            <Text style="heading 4" color="brand-400" data-e2e="quote-total">
                                {title}

                                {titleTooltip ? (
                                    <Tooltip
                                        iconSize="small"
                                        iconColor="ui-500"
                                        placement="top"
                                        text={titleTooltip}
                                    />
                                ) : null}
                            </Text>
                        </ColumnLayout>
                        {children ? (
                            <Text
                                style="heading 1"
                                as="p"
                                color="brand-400"
                                data-e2e="total-premium-value"
                            >
                                {children}
                            </Text>
                        ) : null}
                    </StackLayout>
                    {label ? (
                        <Text style="body 1" as="p" data-e2="quote-expiration">
                            {label}
                        </Text>
                    ) : null}
                </StackLayout>
                <ButtonBar responsive={{ screenWidth: { smallerThan: Infinity } }} gap="16">
                    <Button data-e2e="cta-button" onClick={onClick}>
                        {buttonContent}
                    </Button>
                </ButtonBar>
            </StackLayout>
        </BundleQuoteCardLayout>
    );
}
