import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult, Failure } from '@embroker/shotwell/core/types/Result';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { UnionToIntersection } from 'chart.js/types/utils';
import React from 'react';
import { PremiumRange, Quote } from '../quote/entities/Quote';
import { WizardForm } from '../view/hooks/useWizardForm';
import { BOPChubbCoverageDefinition } from './coverageDefinition/bopChubb/coverageDefinition';
import {
    BundleSummaryComponentProps,
    CoverageDefinition,
    distributedPrefix,
    DocumentTitleType,
    FooterProps,
    GetDocumentDownloadMetadataResponse,
    ModalCoverageSummaryProp,
    ProductNameType,
    ProductTitleType,
} from './coverageDefinition/coverageDefinition';
import { LawCyberCoverageDefinitionCowbell } from './coverageDefinition/cowbellCyber/coverageDefinition';
import { LawCyberCoverageDefinition } from './coverageDefinition/lawCyber/coverageDefinition';
import { LPLCoverageDefinition } from './coverageDefinition/lplEverest/coverageDefinition';
import { MPLCoverageDefinition } from './coverageDefinition/mpl/coverageDefinition';
import { WCChubbCoverageDefinition } from './coverageDefinition/wcChubb/coverageDefinition';
import { BundleQuote } from './entities/BundleQuote';
import {
    BundleCoverageType,
    BundleQuestionnaireDataType,
    BundleQuoteType,
} from './types/BundleQuoteCoverage';
import { DocumentType } from './types/BundleQuoteDocument';
import { BundleQuoteFormData } from './types/BundleQuoteFormData';
import { BuildBundleDocumentListProps } from './view/components/buildBundleDocumentList';
import {
    BundleQuoteCoverageListProps,
    UnderlyingCoverageProps,
} from './view/components/BundleQuoteCoverageList';
import { DocumentsItem } from './view/components/BundleQuoteLandingPage';
import { ESPCoverageDefinition } from './coverageDefinition/esp/coverageDefinition';

type DistributedCoverageDefinition<T> = T extends BundleQuoteType ? CoverageDefinition<T> : never;
export type DistributedBundleSummaryComponent<T> = T extends EntityProps<BundleQuoteType>
    ? React.FC<BundleSummaryComponentProps<T>>
    : never;
export type DistributedBundleModalSummaryComponent<T> = T extends EntityProps<BundleQuoteType>
    ? React.FC<ModalCoverageSummaryProp<T>>
    : never;
export type DistributedBundleQuoteCoverageMetaData<T> = T extends Quote
    ? React.FC<UnderlyingCoverageProps<T>>
    : never;
export type DistributedBundleQuoteFooter<T> = T extends Quote ? React.FC<FooterProps<T>> : never;

interface coverageCatalogDefinition<T extends Array<BundleQuoteType> = Array<BundleQuoteType>> {
    coverages: DistributedCoverageDefinition<T[number]>[];
    findCoverageDefinitionByType: (
        coverageType: BundleCoverageType,
    ) => DistributedCoverageDefinition<T[number]> | undefined;
    getCoverageOptionsComponent: (
        coverageType: BundleCoverageType,
    ) => DistributedBundleQuoteCoverageMetaData<T[number]> | undefined;
    getSummaryComponent: (
        coverageType: BundleCoverageType,
    ) => DistributedBundleSummaryComponent<T[number]> | undefined;
    getModalSummaryComponent: (
        coverageType: BundleCoverageType,
    ) => DistributedBundleModalSummaryComponent<T[number]> | undefined;
    getFooterComponent: (
        coverageType: BundleCoverageType,
    ) => DistributedBundleQuoteFooter<T[number]> | undefined;
    isFieldsValidType: (
        input: any,
        coverageType: BundleCoverageType,
    ) => input is WizardForm<
        OpaqueForm<
            UnionToIntersection<distributedPrefix<Array<BundleQuoteType>[number]['options']>>
        >
    >['fields'];
    isOptionsValidType: (
        input: any,
        coverageType: BundleCoverageType,
    ) => input is UnionToIntersection<T[number]['options']>;
    isValueValidType: (
        input: any,
        coverageType: BundleCoverageType,
    ) => input is UnionToIntersection<distributedPrefix<T[number]['options']>>;
    isQuoteValidType: (
        input: any,
        coverageType: BundleCoverageType,
    ) => input is UnionToIntersection<T[number]>;
    getSelectedProductFormFields: (bundleQuote: BundleQuote) => Record<string, unknown>;
    getProductFormActions: (
        coverageType: BundleCoverageType,
        bundleQuote: BundleQuote,
        abortSignal: AbortSignal,
    ) => Record<string, unknown> | undefined;
    getProductDocuments: (
        coverageType: BundleCoverageType,
        documentListProps: BuildBundleDocumentListProps,
    ) => DocumentsItem[] | undefined;
    getDocumentDownloadMetadata: (
        documentType: DocumentType,
        coverageType: BundleCoverageType,
        quote: Quote,
    ) => AsyncResult<GetDocumentDownloadMetadataResponse, OperationFailed | InvalidArgument>;
    getProductName: (coverageType: BundleCoverageType) => ProductNameType | undefined;
    requiresApplicationAttestation: (coverageType: BundleCoverageType) => boolean;
    isLimitHigherThenAllowed: (
        formValue: Record<string, unknown>,
        bundleQuote: BundleQuote,
    ) => boolean;
    getAttestationComponentList: (
        coverageType: BundleCoverageType,
    ) => React.FC<BundleQuoteCoverageListProps<BundleQuoteFormData>>[] | undefined;

    getProductTitle(coverageType: BundleCoverageType): ProductTitleType | undefined;

    getDocumentTitle(coverageType: BundleCoverageType): DocumentTitleType | undefined;

    getPremiumRange: (coverageType: BundleCoverageType, quote: Quote) => PremiumRange | undefined;
    getHLCoverageSpecificText: (
        formValue: Record<string, unknown>,
        bundleQuote: BundleQuote,
    ) => string;
    getDownloadAppWithManifestDocAction: (
        coverageType: BundleCoverageType,
    ) => ((trigger: WizardForm<OpaqueForm<any>>['trigger']) => void) | undefined;

    overrideBundleAppDocument(coverageType: BundleCoverageType): boolean;
}

class coverageCatalog implements coverageCatalogDefinition {
    readonly coverages: DistributedCoverageDefinition<BundleQuoteType>[];

    constructor(coverages: DistributedCoverageDefinition<BundleQuoteType>[]) {
        this.coverages = coverages;
    }

    findCoverageDefinitionByType(coverageType: BundleCoverageType) {
        return this.coverages.find((coverage) => coverage.type === coverageType);
    }

    getCoverageOptionsComponent(coverageType: BundleCoverageType) {
        const coverageDefinition = this.findCoverageDefinitionByType(coverageType);
        return coverageDefinition?.coverageOptionsComponent;
    }

    getCoverageFooterComponent(coverageType: BundleCoverageType) {
        const coverageDefinition = this.findCoverageDefinitionByType(coverageType);
        return coverageDefinition?.coverageFooterComponent;
    }

    getSummaryComponent(coverageType: BundleCoverageType) {
        const coverageDefinition = this.findCoverageDefinitionByType(coverageType);
        return coverageDefinition?.summaryComponent;
    }

    getModalSummaryComponent(coverageType: BundleCoverageType) {
        const coverageDefinition = this.findCoverageDefinitionByType(coverageType);
        return coverageDefinition?.modalSummaryComponent;
    }

    getFooterComponent(coverageType: BundleCoverageType) {
        const coverageDefinition = this.findCoverageDefinitionByType(coverageType);
        return coverageDefinition?.footerComponent;
    }

    isFieldsValidType(
        input: any,
        coverageType: BundleCoverageType,
    ): input is WizardForm<
        OpaqueForm<UnionToIntersection<distributedPrefix<BundleQuoteType['options']>>>
    >['fields'] {
        const coverageDefinition = this.findCoverageDefinitionByType(coverageType);
        return coverageDefinition ? coverageDefinition.isFieldsValidType(input) : false;
    }

    isOptionsValidType(
        input: any,
        coverageType: BundleCoverageType,
    ): input is UnionToIntersection<BundleQuoteType['options']> {
        const coverageDefinition = this.findCoverageDefinitionByType(coverageType);
        return coverageDefinition ? coverageDefinition.isOptionsValidType(input) : false;
    }

    isValueValidType(
        input: any,
        coverageType: BundleCoverageType,
    ): input is UnionToIntersection<distributedPrefix<BundleQuoteType['options']>> {
        const coverageDefinition = this.findCoverageDefinitionByType(coverageType);
        return coverageDefinition ? coverageDefinition.isFieldsValidType(input) : false;
    }

    isQuoteValidType(
        input: BundleQuoteType,
        coverageType: BundleCoverageType,
    ): input is UnionToIntersection<BundleQuoteType> {
        return this.isOptionsValidType(input['options'], coverageType);
    }

    isQuestionnaireDataValidType(
        input: BundleQuestionnaireDataType,
        coverageType: BundleCoverageType,
    ): input is UnionToIntersection<BundleQuestionnaireDataType> {
        return true;
    }

    getAllProductInitialValues(bundleQuote: BundleQuote): Record<string, unknown> {
        return bundleQuote.getEnabledCoverages().reduce((agg, curr) => {
            const coverageDefinition = this.findCoverageDefinitionByType(curr.type);
            return { ...agg, ...coverageDefinition?.getInitialValues(bundleQuote) };
        }, {});
    }

    getSelectedProductFormFields(bundleQuote: BundleQuote): Record<string, unknown> {
        return bundleQuote.getEnabledCoverages().reduce((agg, curr) => {
            const coverageDefinition = this.findCoverageDefinitionByType(curr.type);
            return { ...agg, ...coverageDefinition?.getProductFields(bundleQuote) };
        }, {});
    }

    isAnyProductFieldDirty(
        coverageType: BundleCoverageType,
        bundleQuote: BundleQuote,
        input: any,
    ): boolean {
        const coverageDefinition = this.findCoverageDefinitionByType(coverageType);
        const productFields = coverageDefinition?.getProductFields(bundleQuote) ?? {};
        const productFieldKeys = Object.keys(productFields);

        const isAnyProductFieldDirty = productFieldKeys
            .map((fieldKey) => input[fieldKey])
            .some((field) => field?.status === 'dirty');

        return isAnyProductFieldDirty;
    }

    getProductFormActions(
        coverageType: BundleCoverageType,
        bundleQuote: BundleQuote,
        abortSignal: AbortSignal,
    ): Record<string, unknown> | undefined {
        const coverageDefinition = this.findCoverageDefinitionByType(coverageType);
        return coverageDefinition?.getProductActions(bundleQuote, abortSignal);
    }

    getProductDocuments(
        coverageType: BundleCoverageType,
        documentListProps: BuildBundleDocumentListProps,
    ): DocumentsItem[] | undefined {
        const coverageDefinition = this.findCoverageDefinitionByType(coverageType);
        return coverageDefinition?.getCoverageDocuments(documentListProps);
    }

    toggleCoverage(coverageType: BundleCoverageType, value: boolean): Record<string, unknown> {
        const toggableCoverage = this.findCoverageDefinitionByType(coverageType);
        return toggableCoverage?.toggleSelected(value) ?? {};
    }

    getMaxFutureDaysAllowed(coverageType: BundleCoverageType): number {
        const coverageDefinition = this.findCoverageDefinitionByType(coverageType);
        return coverageDefinition?.getMaxFutureDaysAllowed() ?? 0;
    }

    async getDocumentDownloadMetadata(
        documentType: DocumentType,
        coverageType: BundleCoverageType,
        quote?: Quote,
    ): AsyncResult<GetDocumentDownloadMetadataResponse, OperationFailed | InvalidArgument> {
        const coverageDefinition = this.findCoverageDefinitionByType(coverageType);
        if (!coverageDefinition) {
            return Failure(InvalidArgument({ argument: 'bundleCoverage', value: coverageType }));
        }
        return coverageDefinition.getDocumentDownloadMetadata(documentType, quote);
    }

    getProductName(coverageType: BundleCoverageType): ProductNameType | undefined {
        const coverageDefinition = this.findCoverageDefinitionByType(coverageType);
        return coverageDefinition?.productName;
    }

    getDocumentTitle(coverageType: BundleCoverageType): DocumentTitleType | undefined {
        const coverageDefinition = this.findCoverageDefinitionByType(coverageType);
        return coverageDefinition?.documentTitle;
    }

    requiresApplicationAttestation(coverageType: BundleCoverageType): boolean {
        const coverageDefinition = this.findCoverageDefinitionByType(coverageType);
        return coverageDefinition?.requiresApplicationAttestation ?? false;
    }

    isSurplusLinesDiscDisabled(coverageType: BundleCoverageType): boolean {
        const coverageDefinition = this.findCoverageDefinitionByType(coverageType);
        return coverageDefinition?.surplusLinesDiscDisabled ?? false;
    }

    isLimitHigherThenAllowed(
        formValue: Record<string, unknown>,
        bundleQuote: BundleQuote,
    ): boolean {
        return this.findCoverageWithHL(formValue, bundleQuote) !== undefined;
    }

    getAttestationComponentList(coverageType: BundleCoverageType) {
        const coverageDefinition = this.findCoverageDefinitionByType(coverageType);
        return coverageDefinition?.attestationComponentList;
    }

    getProductTitle(coverageType: BundleCoverageType): ProductTitleType | undefined {
        const coverageDefinition = this.findCoverageDefinitionByType(coverageType);
        return coverageDefinition?.productTitle;
    }

    getPremiumRange(coverageType: BundleCoverageType, quote?: Quote) {
        const coverageDefinition = this.findCoverageDefinitionByType(coverageType);
        return coverageDefinition?.getPremiumRange(quote);
    }

    getHLCoverageSpecificText(formValue: Record<string, unknown>, bundleQuote: BundleQuote) {
        const hlCoverage = this.findCoverageWithHL(formValue, bundleQuote);
        return hlCoverage?.getHLCoverageSpecificText ? hlCoverage?.getHLCoverageSpecificText() : '';
    }

    private findCoverageWithHL(formValue: Record<string, unknown>, bundleQuote: BundleQuote) {
        return this.coverages.find(
            (coverage) =>
                this.isValueValidType(formValue, coverage.type) &&
                coverage.isLimitHigherThenAllowed(
                    formValue,
                    bundleQuote.isCoverageSelected(coverage.type),
                    bundleQuote.getCoverageQuestionnaire(coverage.type),
                    bundleQuote.getCoverageHigherLimit(coverage.type),
                ) &&
                // we want to eliminiate the coverage that is in indication status
                !bundleQuote.isCoverageIndication(coverage.type),
        );
    }

    getDownloadAppWithManifestDocAction(coverageType: BundleCoverageType) {
        const coverageDefinition = this.findCoverageDefinitionByType(coverageType);
        return coverageDefinition?.getDownloadAppWithManifestDocAction;
    }

    overrideBundleAppDocument(coverageType: BundleCoverageType): boolean {
        const coverageDefinition = this.findCoverageDefinitionByType(coverageType);
        return coverageDefinition?.overrideBundleAppDocument
            ? coverageDefinition?.overrideBundleAppDocument()
            : false;
    }

    isMultipleCoverageCoverageType(coverageType: BundleCoverageType): boolean {
        const multipleCoverageCoverageTypes: BundleCoverageType[] = ['ESPCoverage'];
        return multipleCoverageCoverageTypes.includes(coverageType);
    }
}

export const CoverageCatalog = new coverageCatalog([
    LPLCoverageDefinition,
    MPLCoverageDefinition,
    LawCyberCoverageDefinition,
    LawCyberCoverageDefinitionCowbell,
    BOPChubbCoverageDefinition,
    WCChubbCoverageDefinition,
    ESPCoverageDefinition,
]);
