// **Register active experiment tests here.**
// Note: Be sure to activate test on the abtests service prior to FE deployment,
// 		 or else all accounts will be be auto-assigned with a default test assignment of 0.
export const ExperimentationTestNames = [
    'technology_vertical_onboarding',
    'law-bundle-indicative-premium-test',
    'law-vertical-coverage-recomendation-test',
    'data-driven-form-application-questionnaire',
    'document-ingestion',
    'return-user-funnel-nb-rv-one',
    'quote-comparison',
    'lpl-deselected-popup',
    'tech-vertical-one-by-embroker',
] as const;

export type ExperimentationTestNames = typeof ExperimentationTestNames;

export enum ExperimentationServicePlatforms {
    GrowthBook = 'growthbook',
}
