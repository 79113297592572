import React, { useEffect, useMemo, useState } from 'react';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import { execute } from '@embroker/shotwell/core/UseCase';
import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Success, isOK } from '@embroker/shotwell/core/types/Result';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import {
    Form,
    Nullable,
    SelectOptionAsyncType,
    PageLayout,
    SelectInput,
    StatusMessage,
    Text,
    SelectChangeEvent,
} from '@embroker/ui-toolkit/v2';
import { NaicsCodeRequiresRefinementError } from '../../../errors';
import { PublishOnboardingNaicsChangedEvent } from '../../../useCases/PublishOnboardingNaicsChangedEvent';
import { FormViewLayout } from '../../../../view/components/FormViewLayout.view';
import { PublishOnboardingStartedEvent } from '../../../useCases/PublishOnboardingStartedEvent';
import { PublishOnboardingCompletedEvent } from '../../../useCases/PublishOnboardingCompletedEvent';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { GetDnbNaicsCodeUseCase } from '../../../useCases/GetDnbNaicsCode';
import { PublishDnbNaicsRetrievedEvent } from '../../../useCases/PublishOnboardingDnbNaicsRetrievedEvent';
import { Naics } from '../../../../industries/view/components/Naics';
import { UserOnboardingDetails } from '../../../types/UserOnboardingDetails';
import { PublishOnboardingStepVisitedEvent } from '../../../useCases/PublishOnboardingStepVisitedEvent';
import { OnboardingPageProps } from './OnboardingFlowWrapper';
import { GrowthBookExperimentationService } from '@app/experimentation/services/GrowthBookExperimentationService';
import { MainAreaOfFocusList, NAICS_CODE_TO_VERTICAL } from '@app/userOrg/types/enums';
import { UpdateOrganizationProfile } from '@app/userOrg/useCases/UpdateOrganizationProfile';
import { GetActiveSession } from '@app/userOrg/useCases/GetActiveSession';
import { UUID } from '@embroker/shotwell/core/types/UUID';

interface NaicsConfirmationFormData {
    naics: Nullable<string>;
    techAreaOfFocus: Nullable<string>;
}

const createPrimaryAddressForm = (onCompleteOnboardingStep: () => void) => {
    return createForm<NaicsConfirmationFormData>({
        fields: {
            naics: {
                type: 'selectasync',
                validator: Joi.string().allow(null),
                formatValidationError(error) {
                    return 'You must select naics code';
                },
            },
            techAreaOfFocus: {
                type: 'selectasync',
                validator: Joi.string().optional().allow(null),
            },
        },
        submit: async (formData) => {
            const getActiveSessionResult = await execute(GetActiveSession);
            if (isOK(getActiveSessionResult)) {
                const activeSessionResult = getActiveSessionResult.value;
                await execute(UpdateOrganizationProfile, {
                    naics: formData.naics,
                    techAreaOfFocus: formData.techAreaOfFocus,
                    id: activeSessionResult.session.organizationId as UUID,
                });
            }

            onCompleteOnboardingStep();
            return Success();
        },
        formatSubmitErrors(errors) {
            if (errors.length === 0) {
                return [];
            }

            const refinementError = errors.find(
                (formError) => formError.code === NaicsCodeRequiresRefinementError().code,
            );
            if (refinementError) {
                return [refinementError.message];
            }
            container
                .get<Logger>(Log)
                .warn(`User onboarding - NAICs confirmation page formatSubmitErrors: ${errors}`);
            return ['Sorry, something went wrong. Please try again later.'];
        },
    });
};

export function NaicsConfirmation({ onCompleteOnboardingStep }: OnboardingPageProps) {
    const [prefilledNaicsCodeAction, setPrefilledNaicsCodeAction] = useState(false);
    const GetDnbNaicsCodeUseCaseResult = useUseCase(GetDnbNaicsCodeUseCase);
    const NaicsConfirmationForm = useMemo(
        () => createPrimaryAddressForm(onCompleteOnboardingStep),
        [onCompleteOnboardingStep],
    );

    const { fields, value, messages, setValue, submit } = useForm(NaicsConfirmationForm, {
        naics: null,
        techAreaOfFocus: null,
    });

    const shouldShowTechAreaOfFocus =
        value.naics &&
        NAICS_CODE_TO_VERTICAL[value.naics] === 'TechCompanies' &&
        container
            .get<GrowthBookExperimentationService>(GrowthBookExperimentationService)
            .getFeatureValue('tech-vertical-one-by-embroker', false);

    const [dnbNaics, setDnbNaics] = useState<Nullable<string>>(null);

    useEffect(() => {
        execute(PublishOnboardingStartedEvent);

        return () => {
            execute(PublishOnboardingCompletedEvent);
        };
    }, []);

    useEffect(() => {
        if (
            GetDnbNaicsCodeUseCaseResult.result &&
            isOK(GetDnbNaicsCodeUseCaseResult.result) &&
            value.naics === null &&
            !prefilledNaicsCodeAction
        ) {
            const { naicsCode } = GetDnbNaicsCodeUseCaseResult.result.value;
            execute(PublishDnbNaicsRetrievedEvent, { naicsCode });
            if (naicsCode !== null) {
                UserOnboardingDetails.updateUserOnboardingContext({ dnbNaicsCode: naicsCode });
            }
            setDnbNaics(naicsCode || null);
            setValue({ ...value, naics: naicsCode || null });
            setPrefilledNaicsCodeAction(true);
        }
    }, [GetDnbNaicsCodeUseCaseResult, value, fields, setValue, prefilledNaicsCodeAction]);

    useEffect(() => {
        execute(PublishOnboardingStepVisitedEvent, { step: 'naicsConfirmation' });
    }, []);

    const handleNaicsCodeTypeChange = (newValue?: SelectOptionAsyncType) => {
        const newNaics = newValue?.value ?? null;

        execute(PublishOnboardingNaicsChangedEvent, {
            previousNaicsCode: value.naics,
            naicsCode: newNaics,
        });

        setValue({
            ...value,
            naics: newNaics,
        });
    };

    const handleTechAreaOfFocusChange = (newValue: SelectChangeEvent<Nullable<string>>) => {
        const newTechAreaOfFocus = newValue?.target?.value ?? null;
        setValue({
            ...value,
            techAreaOfFocus: newTechAreaOfFocus as Nullable<string>,
        });
    };

    const confirmIndustrySubTitle = dnbNaics
        ? shouldShowTechAreaOfFocus
            ? 'What industry best describes the work your company does'
            : `We've pre-filled the field below with an industry type from our database. Did we get it right? If not, simply change it.`
        : 'Start typing keywords to describe your business.';

    const displayMessages = messages.filter(
        (formError) => formError !== NaicsCodeRequiresRefinementError().message,
    );

    const disableButton =
        Boolean(!value.naics) ||
        (Boolean(shouldShowTechAreaOfFocus) && Boolean(!value.techAreaOfFocus));

    return (
        <PageLayout.Section>
            <FormViewLayout
                title="What type of work do you do?"
                errorMessages={displayMessages}
                subTitle={confirmIndustrySubTitle}
                disableSubmit={disableButton}
                onFormSubmit={submit}
                data-e2e-submit="user-org-public-submit-btn"
            >
                <Form.Field
                    label="What does your company do?"
                    messages={messages}
                    inputProps={{
                        ...fields.naics.props,
                        value: fields.naics.props.value ?? undefined,
                    }}
                >
                    <Naics
                        data-e2e="user-org-company-profile-naics"
                        {...fields.naics.props}
                        initialValue={fields.naics.props.value ?? undefined}
                        onChange={handleNaicsCodeTypeChange}
                    />
                </Form.Field>
                <NaicsConfirmationHelperText />
                {shouldShowTechAreaOfFocus ? (
                    <React.Fragment>
                        <Text style="body 1">What is the company's main area of focus?</Text>
                        <Form.Field>
                            <SelectInput
                                label="Main area of focus"
                                value={fields.techAreaOfFocus.props.value}
                                items={MainAreaOfFocusList}
                                onChange={handleTechAreaOfFocusChange}
                            />
                        </Form.Field>
                        <NaicsConfirmationHelperText />
                    </React.Fragment>
                ) : null}
            </FormViewLayout>
        </PageLayout.Section>
    );
}

const NaicsConfirmationHelperText = () => (
    <StatusMessage status="helptext">
        <Text style="microcopy" color="ui-500">
            <span>
                Can’t find an exact match?
                <br />
                Pick the category that best describes what you do.
            </span>
        </Text>
    </StatusMessage>
);
