import { State } from '@embroker/shotwell/core/types/StateList';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import {
    InvoiceTable,
    StackLayout,
    Text,
    TextButton,
    Icon,
    Collapsible,
} from '@embroker/ui-toolkit/v2';
import React, { useState } from 'react';
import { BundleSummaryComponentProps } from '../../coverageDefinition';
import { getMPLInsurer, getMPLTitle } from '../buildMPLCoverage';
import { MPLQuote } from '../entities/MPLQuote';
import { CoverageBreakdownList } from '../../../view/components/CoverageBreakdownList.view';

export function MPLQuoteSummary({
    questionnaireData,
    quote,
}: BundleSummaryComponentProps<MPLQuote>) {
    const [showCoverageItems, setShowCoverageItems] = useState<boolean>(false);
    const caretName = !showCoverageItems ? 'caret-down' : 'caret-up';

    const lineItems: { label: string; value: number }[] = [
        { label: 'Aggregate Limit', value: quote.options.limits.aggregateLimit },
        { label: 'Retention', value: quote.options.retention },
    ];

    const state = questionnaireData?.locationWithLargestNumber?.state ?? questionnaireData.state;

    return (
        <StackLayout gap="16">
            <InvoiceTable.Section>
                {quote.details.total_base_premium ? (
                    <React.Fragment>
                        <InvoiceTable.Item
                            title={
                                <React.Fragment>
                                    <Text
                                        style="body 1"
                                        color="brand-500"
                                        as="span"
                                        data-e2e="coverage-list-mpl-title"
                                    >
                                        {getMPLTitle(quote.mplVertical)}
                                        <TextButton
                                            as={Icon}
                                            name={caretName}
                                            onClick={() => setShowCoverageItems(!showCoverageItems)}
                                        />
                                    </Text>
                                    <br />
                                    <Text style="body 2" as="span" data-e2e="mpl-insurer">
                                        {`Insurer: ${getMPLInsurer(state)}`}
                                    </Text>
                                </React.Fragment>
                            }
                        >
                            <Text style="body 1" color="brand-500" as="span" data-e2e="mpl-premium">
                                <MoneyDisplay value={quote.details.total_base_premium} />
                            </Text>
                        </InvoiceTable.Item>
                        <Collapsible state={showCoverageItems ? 'open' : 'closed'}>
                            <CoverageBreakdownList coverageItems={lineItems} />
                        </Collapsible>
                    </React.Fragment>
                ) : null}
                {mplStateWithSurchargeTitleMap.has(state) && quote.fees ? (
                    <React.Fragment>
                        <InvoiceTable.Item />
                        <InvoiceTable.Item
                            title={
                                <Text
                                    style="body 1"
                                    color="brand-500"
                                    as="span"
                                    data-e2e="mpl-surcharge-title"
                                >
                                    {mplStateWithSurchargeTitleMap.get(state)}
                                </Text>
                            }
                        >
                            <Text
                                style="body 1"
                                color="brand-500"
                                as="span"
                                data-e2e="mpl-fees-value"
                            >
                                <MoneyDisplay value={quote.fees} />
                            </Text>
                        </InvoiceTable.Item>
                    </React.Fragment>
                ) : null}
            </InvoiceTable.Section>
            <InvoiceTable.Item
                title={
                    <Text style="heading 5" color="brand-500" as="span" data-e2e="mpl-total">
                        {`Total ${getMPLTitle(quote.mplVertical)}`}
                    </Text>
                }
            >
                <Text
                    style="heading 5"
                    color="brand-500"
                    as="span"
                    data-e2e="mpl-total-payable-value"
                >
                    <MoneyDisplay value={quote.totalPayable} />
                </Text>
            </InvoiceTable.Item>
        </StackLayout>
    );
}

const mplStateWithSurchargeTitleMap = new Map<State, string>([
    ['FL', 'Florida FIGA Surcharge'],
    ['NJ', 'New Jersey PLIGA Surcharge'],
]);
