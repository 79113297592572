import { container } from '@embroker/shotwell/core/di';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { FailedToGetAnswerFromFormValues, InvalidAnswerFromFormValue } from '../errors';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Joi, defineValidator } from '@embroker/shotwell/core/validation/schema';
import {
    AnswerKeysTypes,
    AnswerType,
    AnswerTypes,
    OracleAnswerType,
    QuestionerQuestion,
    answerTypeToKeyTypeMap,
} from './QuestionerQuestionType';
import { getValidatorAsArrayValidation } from '@app/view/components/DataDrivenForm/types/fieldValidationFactory';

export interface OracleAnswer {
    key: string;
    value: OracleAnswerType;
    type: AnswerType;
    multiplicity: number;
}

const OracleAnswerSchema = Joi.object({
    key: Joi.string().required(),
    value: Joi.object()
        .pattern(Joi.string().valid(...AnswerKeysTypes), Joi.any().required())
        .optional(),
    multiplicity: Joi.number().required(),
    type: Joi.string()
        .required()
        .valid(...AnswerTypes),
});

export const OracleAnswer = {
    ...defineValidator<OracleAnswer>(OracleAnswerSchema),
    create(oracleAnswer: unknown) {
        return OracleAnswer.validate(oracleAnswer);
    },
    getAnswerFromQuestionnaire(
        formValue: unknown,
        questionerQuestion: QuestionerQuestion,
    ): OracleAnswer | InvalidAnswerFromFormValue {
        const answerValue =
            questionerQuestion.multiplicity !== 1 && Array.isArray(formValue)
                ? (formValue as unknown[])
                : [formValue];
        // The isArray function will cast formValue as unknown[] | readonly unknown[]
        // using as unknown[] to satisfy typescript

        const { answerKeyType, schemaFunctions } =
            answerTypeToKeyTypeMap[questionerQuestion.answer_type];
        const { validator, serializeAnswer } = schemaFunctions;

        const translatedAnswerValue = answerValue.map((value) =>
            serializeAnswer ? serializeAnswer(value) : value,
        );
        const validateAnswerValue =
            getValidatorAsArrayValidation(validator).validate(translatedAnswerValue);

        if (validateAnswerValue.error) {
            return InvalidAnswerFromFormValue();
        }
        const value = { [answerKeyType]: validateAnswerValue.value } as OracleAnswerType;

        return {
            key: questionerQuestion.key,
            value,
            type: questionerQuestion.answer_type,
            multiplicity: questionerQuestion.multiplicity,
        };
    },
    getAnswersFromQuestionnaire(
        formData: { [key: string]: unknown },
        questionerQuestions: QuestionerQuestion[],
    ): { answers: OracleAnswer[]; errors: FailedToGetAnswerFromFormValues[] } {
        const answers: OracleAnswer[] = [];
        const errors: FailedToGetAnswerFromFormValues[] = [];

        Object.keys(formData).forEach((questionKey) => {
            const value = formData[questionKey];
            const questionerQuestion = questionerQuestions.find(({ key }) => key === questionKey);

            if (!questionerQuestion) {
                errors.push(FailedToGetAnswerFromFormValues());
                return;
            }

            const answer = OracleAnswer.getAnswerFromQuestionnaire(value, questionerQuestion);
            const answerResult = OracleAnswer.create(answer);

            if (!isOK(answerResult)) {
                if (process.env.NODE_ENV === 'production') {
                    // This scenario should never happen we are being very defensive here
                    // If an answer type fails validation before being sent to Oracle service then it has incorectly passed validation in the form
                    //
                    // We still want to surface an error in the logs to catch this scenario
                    container
                        .get<Logger>(Log)
                        .error(
                            `Cannot get answer from questionnaire. Form value: ${value}. Form value type: ${typeof value}. Question: ${JSON.stringify(
                                questionerQuestion,
                            )}.`,
                        );
                } else {
                    errors.push(FailedToGetAnswerFromFormValues());
                }
                return;
            }

            answers.push(answerResult.value as OracleAnswer);
        });

        return { answers, errors };
    },
};
