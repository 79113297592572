import { Joi, SchemaType } from '@embroker/shotwell/core/validation/schema';
import { FormQuestionDefinition } from '../hooks/useDataDrivenForm';
import {
    locationFieldFormatValidationError,
    locationFieldValidator,
} from '@app/userOrg/view/components/LocationFieldSet.view';
import { InputType } from '@embroker/ui-toolkit/v2';
import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { ErrorMsgObject } from './validationObject';
import {
    AddressFieldFormFieldView,
    assertMailingAddressType,
} from '../components/complexFormFieldViews/AddressFieldFormFieldView.view';
import { StaticFieldView } from '../components/complexFormFieldViews/StaticFieldView.view';
import {
    assertInputValueAsArray,
    MultiSelectFieldView,
} from '../components/complexFormFieldViews/MultiSelectFieldView.view';
import {
    assertInputValueAsString,
    NaicsCodeFieldView,
} from '../components/complexFormFieldViews/NaicsCodeField';
import {
    FormFieldViewProps,
    InputFieldProps,
    QuestionProps,
} from '../components/FormFieldView.view';
import {
    FundraisingRoundFormFieldView,
    assertFundraisingRoundType,
    fundraisingRoundFormatValidationError,
} from '../components/complexFormFieldViews/FundraisingRoundFormFieldView.view';
import { fundraisingRoundFormatValidator } from '@app/shoppingQuestioner/types/QuestionerQuestionType';
import {
    assertRevenueType,
    PreviousYearRevenueView,
    revenueFieldFormatValidationError,
} from '../components/complexFormFieldViews/PreviousYearRevenueView.view';
import { Revenue } from '@embroker/shotwell/core/types/Revenue';

interface ComplexFormFieldQuestionProps extends QuestionProps {
    questionType: ComplexFormFieldType;
}
export interface ComplexFormFieldViewProps extends FormFieldViewProps {
    questionProps: ComplexFormFieldQuestionProps;
}

export const getFormFieldProps = (questionProps: QuestionProps) => ({
    title: questionProps.title,
    label: questionProps.label,
    tooltip: questionProps.tooltip,
});

export const getInputProps = (questionProps: QuestionProps, inputFieldProps: InputFieldProps) => ({
    ...inputFieldProps.inputProps,
    placeholder: inputFieldProps.inputProps.placeholder,
    items: questionProps.selectOptions,
});

export const ComplexFormFieldTypes = [
    'fundraisingRound',
    'addressField',
    'static',
    'multiSelect',
    'naicsCode',
    'previousYearRevenue',
] as const;
export type ComplexFormFieldType = (typeof ComplexFormFieldTypes)[number];

export function isComplexQuestionType(
    value: FormQuestionDefinition['questionType'],
): value is ComplexFormFieldType {
    return ComplexFormFieldTypes.includes(value as ComplexFormFieldType);
}

const defaultFormatValidationError = (error: InvalidArgument) =>
    ErrorMsgObject.getValidationMessage(error);

function fallbackTypeAssertion<T>(input: unknown): input is T {
    return true;
}

export interface ComplexFormFieldDefinition {
    type: InputType;
    validator: SchemaType<unknown>;
    renderingComponent: React.FC<ComplexFormFieldViewProps>;
    formatValidationError: (error: any) => string;
    typeAssertion: (input: unknown) => boolean;
}

export const ComplexFormFieldDefinitionMap: Record<
    ComplexFormFieldType,
    ComplexFormFieldDefinition
> = {
    fundraisingRound: {
        type: 'text',
        validator: fundraisingRoundFormatValidator,
        formatValidationError: fundraisingRoundFormatValidationError,
        renderingComponent: FundraisingRoundFormFieldView,
        typeAssertion: assertFundraisingRoundType,
    },
    addressField: {
        type: 'text',
        validator: locationFieldValidator,
        formatValidationError: locationFieldFormatValidationError,
        renderingComponent: AddressFieldFormFieldView,
        typeAssertion: assertMailingAddressType,
    },
    static: {
        type: 'text',
        validator: Joi.any().optional(),
        formatValidationError: defaultFormatValidationError,
        renderingComponent: StaticFieldView,
        typeAssertion: fallbackTypeAssertion,
    },
    multiSelect: {
        type: 'radioGroup',
        validator: Joi.any().optional(),
        formatValidationError: defaultFormatValidationError,
        renderingComponent: MultiSelectFieldView,
        typeAssertion: assertInputValueAsArray,
    },
    naicsCode: {
        type: 'text',
        validator: Joi.string(),
        formatValidationError: defaultFormatValidationError,
        renderingComponent: NaicsCodeFieldView,
        typeAssertion: assertInputValueAsString,
    },
    previousYearRevenue: {
        type: 'currency',
        validator: Revenue.schema,
        formatValidationError: revenueFieldFormatValidationError,
        renderingComponent: PreviousYearRevenueView,
        typeAssertion: assertRevenueType,
    },
};
