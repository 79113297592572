import { JSONSerdes } from '@embroker/shotwell/core/encoding';
import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { Nullable } from '@embroker/shotwell/core/types';
import { Failure, Result, Success, isErr } from '@embroker/shotwell/core/types/Result';
import { State } from '@embroker/shotwell/core/types/StateList';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { Immutable } from '@embroker/ui-toolkit/v2';

export const RevenueRangeTypes = ['LOW', 'MEDIUM', 'HIGH', 'VERY-HIGH'] as const;
export type RevenueRangeType = (typeof RevenueRangeTypes)[number];

interface RevenueList {
    fiscal_year: string;
    gross_revenue_total: number;
}

interface StartAndEndDate {
    policy_end_date: string;
}

interface PolicyDetails {
    start_and_end_date: StartAndEndDate;
}

export interface QuestionnaireData {
    company_name: string;
    mailing_address: string;
    suite?: string | null;
    city: string;
    state: State;
    zip: string;
    county?: string | null;
    first_name: string;
    last_name: string;
    title: string;
    gross_revenue_total?: number;
    revenue_list?: Immutable<RevenueList[]>;
    naics_code: string;
    current_policy_details?: PolicyDetails;
    other_company_locations?: boolean;
}

export const QuestionnaireData = {
    ...defineValidator<QuestionnaireData>({
        title: Joi.string(),
        company_name: Joi.string(),
        city: Joi.string(),
        state: State.schema,
        zip: ZipCode.schema,
        mailing_address: Joi.string(),
        first_name: Joi.string(),
        last_name: Joi.string(),
        naics_code: Joi.string(),
        county: Joi.string().allow(null, '').optional(),
        revenue_list: Joi.array().optional(),
        gross_revenue_total: Joi.number().optional(),
        suite: Joi.string().allow(null, '').optional(),
        current_policy_details: Joi.object().optional(),
        other_company_locations: Joi.boolean().optional(),
    }),
    create(questionnaireData: QuestionnaireData) {
        return QuestionnaireData.validate(questionnaireData);
    },
    deserializeQuestionnaireData(
        questionnaireData: Nullable<string>,
    ): Result<QuestionnaireData, InvalidArgument> {
        const deserializeResult = JSONSerdes.deserialize(questionnaireData ?? '{}');
        if (isErr(deserializeResult)) {
            return Failure(
                InvalidArgument({ argument: 'questionnaireData', value: questionnaireData }),
            );
        }
        const questionnaireDataResp = QuestionnaireData.create(
            deserializeResult.value as QuestionnaireData,
        );
        if (isErr(questionnaireDataResp)) {
            return Failure(
                InvalidArgument({ argument: 'questionnaireData', value: questionnaireData }),
            );
        }

        return Success(questionnaireDataResp.value);
    },
    getRevenueRangeFromQuestionnaire(questionnaireData: QuestionnaireData): RevenueRangeType {
        const grossRevenueTotal = questionnaireData.gross_revenue_total;

        if (!grossRevenueTotal) {
            return 'LOW';
        }

        if (grossRevenueTotal >= 5000000) {
            return 'VERY-HIGH';
        }

        if (grossRevenueTotal >= 1000000) {
            return 'HIGH';
        }

        if (grossRevenueTotal >= 500000) {
            return 'MEDIUM';
        }
        return 'LOW';
    },
};
