import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Immutable } from '@embroker/shotwell/core/types';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import {
    Button,
    ColumnLayout,
    Modal,
    ScrollBox,
    StackLayout,
    Text,
    useModal,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { Invoice } from '../../../entities/Invoice';
import { BundlePayment } from '../../../types/Payment';

interface BundleInvoiceDetailsModal {
    hideModal(): void;
    bundlePayment?: Immutable<BundlePayment>;
    modal: ReturnType<typeof useModal>;
}

export function BundleModalInvoiceDetail({
    bundlePayment,
    modal,
    hideModal,
}: BundleInvoiceDetailsModal) {
    if (bundlePayment == undefined || bundlePayment.invoiceList.length == 0) {
        return null;
    }

    const invoiceList = bundlePayment.invoiceList;

    const effectiveDate = invoiceList[0].policyEffectiveDate;
    return (
        <Modal {...modal}>
            <ScrollBox>
                <StackLayout gap="16">
                    <Text style="heading 3">Invoice details</Text>
                    <StackLayout gap="4">
                        <Text style="heading 4">{bundlePayment.lineOfBusiness}</Text>
                        <Text style="body 2" color="ui-500">
                            {' '}
                            {invoiceList.length} coverages, {invoiceList.length} polices{' '}
                        </Text>
                    </StackLayout>
                    <StackLayout gap="4">
                        <Text style="body 2" color={'brand-500'}>
                            <b>EFFECTIVE</b>
                        </Text>
                        <Text style="body 2" color={'brand-500'}>
                            <DateDisplay value={effectiveDate} />
                        </Text>
                    </StackLayout>
                    <hr />
                    <StackLayout gap={'24'}>
                        <StackLayout gap={'16'}>
                            {invoiceList.map((invoice) => buildInvoicePolicySection(invoice))}
                        </StackLayout>
                        <ColumnLayout>
                            <StackLayout>
                                <Text style={'heading 4'}>Total Amount</Text>
                            </StackLayout>
                            <Text style={'heading 4'}>
                                <MoneyDisplay value={bundlePayment.total} />
                            </Text>
                        </ColumnLayout>
                        <hr />
                    </StackLayout>
                    <Text data-e2e="invoice-details-total-amount-disclaimer">
                        Total may be different if paid by credit card.
                    </Text>
                    <Button
                        data-e2e="invoice-details-got-it-btn"
                        appearance="primary"
                        onClick={hideModal}
                    >
                        Got It
                    </Button>
                </StackLayout>
            </ScrollBox>
        </Modal>
    );
}

function buildInvoicePolicySection(invoice: Immutable<EntityProps<Invoice>>): JSX.Element | null {
    const invoiceItemList = invoice.invoiceItemList;
    const policyPremiumAmount = invoiceItemList.find((item) =>
        item.description.includes('Premium'),
    );
    if (policyPremiumAmount == undefined) {
        return null;
    }

    const indexOfPremium = invoiceItemList.indexOf(policyPremiumAmount);
    let taxesItemList = invoiceItemList.slice(0, indexOfPremium);
    taxesItemList = taxesItemList.concat(invoiceItemList.slice(indexOfPremium + 1));

    return (
        <StackLayout gap={'16'}>
            <ColumnLayout>
                <StackLayout gap={'8'}>
                    <Text color={'brand-500'}>
                        {invoice.lineOfBusiness} {policyPremiumAmount.description}
                    </Text>
                    <StackLayout gap={'8'}>
                        <Text style="label 2" color="ui-400">
                            POLICY NO: {invoice.policyNumber}
                        </Text>
                        <Text style="label 2" color="ui-400">
                            POLICY PROVIDER: {invoice.carrierName}
                        </Text>
                        <Text style="label 2" color="ui-400">
                            INVOICE NO: {invoice.invoiceNumber}
                        </Text>
                    </StackLayout>
                </StackLayout>
                <Text color={'brand-500'}>
                    <b>
                        <MoneyDisplay value={policyPremiumAmount.amount}></MoneyDisplay>
                    </b>
                </Text>
            </ColumnLayout>
            {taxesItemList.map((item) => {
                const key = UUID.create();
                return (
                    <ColumnLayout key={key}>
                        <StackLayout>
                            <Text color={'brand-500'}>{item.description}</Text>
                        </StackLayout>
                        <Text color={'brand-500'}>
                            <b>
                                <MoneyDisplay value={item.amount} />
                            </b>
                        </Text>
                    </ColumnLayout>
                );
            })}
            <hr />
        </StackLayout>
    );
}
