import { StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';

interface ESPEndorsementValueLabelProps {
    title: React.ReactNode;
    value: React.ReactNode;
}

export function ESPEndorsementValueLabel({
    title = '',
    value = '',
}: ESPEndorsementValueLabelProps) {
    return (
        <StackLayout gap="none">
            <Text style="label 2" color="brand-500">
                {title}
            </Text>
            <Text style="body 1">{value}</Text>
        </StackLayout>
    );
}
