import {
    PageLayout,
    Spinner,
    StackLayout,
    useModal,
    ColumnLayout,
    Text,
    Icon,
    Box,
    Hyperlink,
    useResponsive,
    IconName,
} from '@embroker/ui-toolkit/v2';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { BundleHeroBanner } from '../../../bundle/view/components/BundleHeroBanner.view';
import { LeavingConfirmationModal } from '../../../quote/view/components/LeavingConfirmationModal';
import { useNavigation } from 'react-navi';
import {
    CoveragePackageType,
    GetRecommendedCoverageQuotes,
    CoveragePackageTypeEnum,
} from '@app/bundle/useCases/GetRecommendedCoverageQuotes';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { BundleQuoteComparisonView } from './BundleQuoteComparisonComponent.view';
import { BundleQuote } from '@app/bundle/entities/BundleQuote';
import { BundleQuotePackageCard } from '@app/bundle/view/components/BundleQuotePackageCard.view';
import { execute } from '@embroker/shotwell/core/UseCase';
import { PublishShoppingUserClickEvent } from '@app/shopping/useCases/PublishShoppingUserClickEvent';
import { ErrorPage } from '@app/view/components/ErrorPage.view';
import { AppContext } from '@app/view/AppContext';
import { ComparisonQuoteBundleQuoteHLSlideout } from '@app/bundle/view/components/ComparisonQuoteBundleQuoteHLSlideout';
import { LPLCoverageDefinition } from '@app/bundle/coverageDefinition/lplEverest/coverageDefinition';
import { ConfirmRecommendedCoverageSelection } from '@app/bundle/useCases/ConfirmRecommendedCoverageSelection';
import {
    CoverageRecommendationCard,
    RecommendationType,
} from './lawBundle/CoverageRecommendationCard.view';
import { BundleCoverageType } from '@app/bundle/types/BundleQuoteCoverage';
import { URI } from '@embroker/shotwell/core/types/URI';

interface QuoteComparisonProps {
    bundleQuote: BundleQuote;
}

const appTypesData: Record<BundleCoverageType, { title: string; icon: IconName }> = {
    BOPChubbCoverage: { title: 'Liability + Property Insurance', icon: 'bop' },
    LawCyberBundleCoverageCowbell: { title: 'Cyber Insurance', icon: 'cyber' },
    LPLBundleCoverage: { title: 'Lawyers Professional Liability', icon: 'law' },
    WCChubbCoverage: { title: 'Workers Compensation', icon: 'workers-comp' },
    MPLCoverage: { title: '', icon: 'user' },
    LawCyberBundleCoverage: { title: 'Cyber Insurance', icon: 'cyber' },
    ESPCoverage: { title: 'ESP Insurance', icon: 'esp' },
};

export function QuoteComparison({ bundleQuote }: QuoteComparisonProps) {
    const [isLoaded, setIsloaded] = useState(false);
    const [isRequoting, setIsRequoting] = useState(false);
    const { navigate } = useNavigation();
    const { setSlideout, closeSlideout } = useContext(AppContext);
    const isMobile = useResponsive({ screenWidth: { smallerThan: 840 } });

    const abortController = useMemo(() => {
        return new AbortController();
    }, []);

    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, [abortController]);

    useEffect(() => {
        if (!isLoaded) {
            execute(PublishShoppingUserClickEvent, {
                clickEventName: 'Quote Comparison page loaded',
            });
            setIsloaded(true);
        }
    }, [isLoaded, setIsloaded]);

    const leavingConfirmationModal = useModal();

    const handleLeaving = useCallback(() => {
        leavingConfirmationModal.show();
        execute(PublishShoppingUserClickEvent, {
            clickEventName: 'Package Save and Exit clicked',
        });
    }, [leavingConfirmationModal]);

    const handleConfirmLeaving = useCallback(() => {
        leavingConfirmationModal.hide();
        execute(PublishShoppingUserClickEvent, {
            clickEventName: 'Modal Save and Exit CTA clicked',
        });
        navigate('/');
    }, [leavingConfirmationModal, navigate]);

    const getComparisonQuotesResponse = useUseCase(GetRecommendedCoverageQuotes, {
        abortSignal: abortController.signal,
        bundleQuote,
    });

    const recommendCoverageQuotes =
        getComparisonQuotesResponse.result && isOK(getComparisonQuotesResponse.result)
            ? getComparisonQuotesResponse.result.value.recommendCoverageQuotes
            : null;

    const handleQuoteSelected = useCallback(
        async (coveragePackageType: CoveragePackageType) => {
            setIsRequoting(true);
            closeSlideout();
            const selectedQuote = await execute(ConfirmRecommendedCoverageSelection, {
                abortSignal: abortController.signal,
                bundleQuote,
                coveragePackageType,
            });
            if (isOK(selectedQuote)) {
                const url = URI.build('/shopping/bundle/coverage', {
                    applicationId: selectedQuote.value.requotedBundleQuote.applicationId,
                });
                navigate(url);
            }
        },
        [bundleQuote, abortController.signal, closeSlideout, navigate],
    );

    const handleCloseSlideout = useCallback(() => {
        closeSlideout();
        execute(PublishShoppingUserClickEvent, {
            clickEventName: 'Quote Comparison Page Slideout Cancel CTA clicked',
        });
    }, [closeSlideout]);

    const handleDismissSlideout = useCallback(() => {
        closeSlideout();
        execute(PublishShoppingUserClickEvent, {
            clickEventName: 'Quote Comparison Page Slideout Exit CTA clicked',
        });
    }, [closeSlideout]);

    const handleCTAClick = useCallback(() => {
        execute(PublishShoppingUserClickEvent, {
            clickEventName: 'Quote Comparison Page Slideout Proceed with HL CTA clicked',
        });
        if (recommendCoverageQuotes) {
            handleQuoteSelected(CoveragePackageTypeEnum.deluxe);
        }
    }, [recommendCoverageQuotes, handleQuoteSelected]);

    const isHigherLimit = useMemo(
        () =>
            recommendCoverageQuotes &&
            LPLCoverageDefinition.isHigherLimit(recommendCoverageQuotes.deluxe),
        [recommendCoverageQuotes],
    );

    const handleOpenSlideout = useCallback(() => {
        if (recommendCoverageQuotes) {
            execute(PublishShoppingUserClickEvent, {
                clickEventName: 'Deluxe Package Continue CTA clicked',
            });

            if (isHigherLimit) {
                setSlideout(
                    <ComparisonQuoteBundleQuoteHLSlideout
                        handleDismiss={handleDismissSlideout}
                        handleClose={handleCloseSlideout}
                        handleCTAClick={handleCTAClick}
                    />,
                );
            } else {
                handleQuoteSelected(CoveragePackageTypeEnum.deluxe);
            }
        }
    }, [
        recommendCoverageQuotes,
        setSlideout,
        handleDismissSlideout,
        handleCloseSlideout,
        handleCTAClick,
        handleQuoteSelected,
        isHigherLimit,
    ]);

    if (getComparisonQuotesResponse.result && !isOK(getComparisonQuotesResponse.result)) {
        return <ErrorPage errors={getComparisonQuotesResponse.result.errors} />;
    }

    const EnhacedBundleQuotePackageCard = recommendCoverageQuotes && (
        <BundleQuotePackageCard
            variant="enhaced"
            pricePerYear={recommendCoverageQuotes.enhanced.getQuoteTotalValue()}
            onPrimaryClick={() => {
                execute(PublishShoppingUserClickEvent, {
                    clickEventName: 'Enhaced Package Continue CTA clicked',
                });
                handleQuoteSelected(CoveragePackageTypeEnum.enhanced);
            }}
            onSecondaryClick={() => {
                execute(PublishShoppingUserClickEvent, {
                    clickEventName: 'Enhaced Package Customized CTA clicked',
                });
                handleQuoteSelected(CoveragePackageTypeEnum.enhanced);
            }}
        >
            <BundleQuoteComparisonView bundleQuote={recommendCoverageQuotes.enhanced} />
        </BundleQuotePackageCard>
    );

    const StarterBundleQuotePackageCard = recommendCoverageQuotes && (
        <BundleQuotePackageCard
            variant="starter"
            pricePerYear={recommendCoverageQuotes.starter.getQuoteTotalValue()}
            onPrimaryClick={() => {
                execute(PublishShoppingUserClickEvent, {
                    clickEventName: 'Starter Package Continue CTA clicked',
                });
                handleQuoteSelected(CoveragePackageTypeEnum.starter);
            }}
            onSecondaryClick={() => {
                execute(PublishShoppingUserClickEvent, {
                    clickEventName: 'Starter Package Customized CTA clicked',
                });
                handleQuoteSelected(CoveragePackageTypeEnum.starter);
            }}
        >
            <BundleQuoteComparisonView bundleQuote={recommendCoverageQuotes.starter} />
        </BundleQuotePackageCard>
    );
    const ineligibleCoverages = bundleQuote.getIneligibleCoverages();

    return getComparisonQuotesResponse.isLoading || isRequoting ? (
        <Spinner />
    ) : (
        <StackLayout gap="64">
            <BundleHeroBanner
                onDismiss={handleLeaving}
                title="Choose your coverage package"
                subtitle="Simply pick the coverage limits that best suit your unique business risks — you can always customize your quote later."
            />
            <PageLayout.Section>
                <StackLayout gap="64">
                    <StackLayout gap="32">
                        {recommendCoverageQuotes && (
                            <ColumnLayout
                                responsive={{ containerWidth: { smallerThan: 'large-tablet' } }}
                            >
                                {isMobile ? (
                                    <Fragment>
                                        {EnhacedBundleQuotePackageCard}
                                        {StarterBundleQuotePackageCard}
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        {StarterBundleQuotePackageCard}
                                        {EnhacedBundleQuotePackageCard}
                                    </Fragment>
                                )}

                                <BundleQuotePackageCard
                                    variant="deluxe"
                                    pricePerYear={recommendCoverageQuotes.deluxe.getQuoteTotalValue()}
                                    onPrimaryClick={handleOpenSlideout}
                                    onSecondaryClick={() => {
                                        execute(PublishShoppingUserClickEvent, {
                                            clickEventName: 'Deluxe Package Customized CTA clicked',
                                        });
                                        handleQuoteSelected(CoveragePackageTypeEnum.deluxe);
                                    }}
                                >
                                    <BundleQuoteComparisonView
                                        bundleQuote={recommendCoverageQuotes.deluxe}
                                    />
                                </BundleQuotePackageCard>
                            </ColumnLayout>
                        )}
                        <StackLayout gap="8">
                            <Text style="microcopy" color="brand-400">
                                *Prices are for the limits shown above. Customization in the next
                                step may change the cost.
                            </Text>
                            {isHigherLimit && (
                                <Text style="microcopy" color="brand-400">
                                    **Higher limits in the Deluxe option require application review,
                                    so the price shown is only an estimate. Your final price will be
                                    provided after we process your application.
                                </Text>
                            )}
                        </StackLayout>
                        {ineligibleCoverages.length > 0 && (
                            <ColumnLayout
                                gap="12"
                                responsive={{ screenWidth: { smallerThan: 'large-tablet' } }}
                            >
                                {ineligibleCoverages.map((coverage) => (
                                    <CoverageRecommendationCard
                                        iconName={appTypesData[coverage.type].icon}
                                        key={coverage.quote?.applicationId}
                                        recommendationType={RecommendationType.none}
                                        title={appTypesData[coverage.type].title}
                                        isDisabled
                                        disabledText="Ineligible"
                                        className={isMobile ? 'u-1' : 'u-1/3'}
                                    >
                                        <Text>
                                            Unfortunately, based on some of your responses, your
                                            company isn’t eligible for this coverage at this time.
                                            We’ll be in touch if our guidelines change.
                                        </Text>
                                    </CoverageRecommendationCard>
                                ))}
                            </ColumnLayout>
                        )}
                        <Box backgroundColor="ui-50" gap="32" borderRadius="16">
                            <StackLayout gap="32">
                                <ColumnLayout center>
                                    <Icon name="help" />
                                    <Text style="heading 4" color="brand-400">
                                        Key Insurance Terms
                                    </Text>
                                </ColumnLayout>
                                <StackLayout gap="24">
                                    <StackLayout gap="12">
                                        <Text style="overline" color="brand-400">
                                            Limit
                                        </Text>
                                        <Text style="microcopy" color="brand-400">
                                            A limit is the maximum an insurer will pay for covered
                                            claims during the policy period.
                                        </Text>
                                    </StackLayout>
                                    <StackLayout gap="12">
                                        <Text style="overline" color="brand-400">
                                            Deductible
                                        </Text>
                                        <Text style="microcopy" color="brand-400">
                                            The amount of money that you are required to pay, per
                                            claim, before the insurance company will start paying.
                                        </Text>
                                    </StackLayout>
                                    <StackLayout gap="12">
                                        <Text style="overline" color="brand-400">
                                            Defense Costs Limit / Separate claims expense
                                        </Text>
                                        <Text style="microcopy" color="brand-400">
                                            A separate, additional limit for defense costs on a
                                            claim that’s capped at a certain amount; after this
                                            amount is used up, other costs are covered by the
                                            standard limit.{' '}
                                        </Text>
                                    </StackLayout>
                                    <StackLayout gap="12">
                                        <Text style="overline" color="brand-400">
                                            Employers liability policy limit
                                        </Text>
                                        <Text style="microcopy" color="brand-400">
                                            Part of a Workers Compensation policy, this helps cover
                                            claims that an employee’s workplace injuries/illness
                                            were caused by your negligence.
                                        </Text>
                                    </StackLayout>
                                </StackLayout>
                            </StackLayout>
                        </Box>
                    </StackLayout>
                    <StackLayout gap="20">
                        <StackLayout gap="4">
                            <Text color="ui-400">
                                LPL Carrier: Everest, underwritten by Everest National Insurance
                                Company
                            </Text>
                            <Text color="ui-500">
                                Everest National Insurance Company is currently rated AM Best A+
                                (Superior) with a Financial Size Category of XV.
                            </Text>
                        </StackLayout>
                        <StackLayout gap="4">
                            <Text color="ui-400">
                                Cyber Carrier: Cowbell Cyber Inc., underwritten by Spinnaker
                                Insurance Company
                            </Text>
                            <Text color="ui-500">
                                Spinnaker Insurance Company is currently rated AM Best A-
                                (Excellent) with a Financial Size Category of VIII.
                            </Text>
                        </StackLayout>
                        <StackLayout gap="4">
                            <Text color="ui-400">
                                BOP carrier: Chubb, underwritten by ACE Property and Casualty
                                Insurance Company
                            </Text>
                            <Text color="ui-500">
                                ACE Property and Casualty Insurance Company is currently rated AM
                                Best A++ (Superior) with a Financial Size Category of XV.
                            </Text>
                        </StackLayout>
                        <StackLayout gap="4">
                            <Text color="ui-400">
                                WC carrier: May be underwritten by various Chubb companies
                            </Text>
                            <Text color="ui-500">
                                Various Chubb companies are currently rated AM Best A++ (Superior)
                                with a Financial Size Category of XV.
                            </Text>
                        </StackLayout>
                        <StackLayout gap="4">
                            <Text color="ui-400" data-e2e="cyber-carrier-description-terms">
                                AM Best Ratings shown are subject to&nbsp;
                                <Hyperlink href="http://www.embroker.com/terms" target="_blank">
                                    Embroker's Terms
                                </Hyperlink>
                                .
                            </Text>
                            <Text color="ui-400">
                                Please&nbsp;
                                <Hyperlink href="mailto:hello@embroker.com">
                                    contact us
                                </Hyperlink>{' '}
                                with any questions.
                            </Text>
                        </StackLayout>
                    </StackLayout>
                </StackLayout>
            </PageLayout.Section>
            <LeavingConfirmationModal
                modal={leavingConfirmationModal}
                onConfirmLeaving={handleConfirmLeaving}
            />
        </StackLayout>
    );
}
