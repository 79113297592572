import {
    Element,
    FieldElement,
    FormContext,
    getComponentSection,
    registerComponentSection,
    tail,
    uniqueId,
    useStateMachine,
} from '@embroker/service-app-engine';
import { container } from '@embroker/shotwell/core/di';
import { IndexableObject, equal, hasOwnProp, isObject } from '@embroker/shotwell/core/object';
import { Sanitizer } from '@embroker/shotwell/core/sanitization/Sanitizer';
import {
    Form,
    InputStatusMessage,
    Text,
    Tooltip,
    useStableEventHandler,
} from '@embroker/ui-toolkit/v2';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { GroupField } from './components/GroupField';
import { useFormEngineEvent } from './hooks/useFormEngineEvent';

interface WrapFormFieldOptions {
    /**
     * Instances of which constructor to accept as valid PropType for `instance`
     * property
     */
    constructor?: Function;
    /**
     * Additional class names to always add to the wrapper of this field type
     */
    additionalClassNames?: string[];
    /**
     * Converts value from view to model type/format (a.k.a parse operation)
     */
    toModelValue?: (value: any, parseInputs: { [prop: string]: any }) => any;
    /**
     * State property names required for toModelValue, passed as 2nd argument,
     * aggregated as key/value object
     */
    toModelValueInputs?: string[];
    /**
     * Converts value from model to view type/format (a.k.a format operation)
     */
    fromModelValue?: (value: any, formatInputs: { [prop: string]: any }) => any;
    /**
     * State property names required for fromModelValue, passed as 2nd argument,
     * aggregated as key/value object
     */
    fromModelValueInputs?: string[];
    /**
     * Called with (state, actions) and should return an object to spread into
     * forwarded props
     */
    select?: (
        state: Record<string, any>,
        actions: Record<string, (...args: any[]) => any>,
    ) => { [prop: string]: any; readOnly?: boolean };
}

/**
 * A higher-order component factory which wraps the given Component with standard wrapper elements
 * and connects it to the state machine instance that provides model storage and logic.
 *
 * Essentially, this HOC renders following structure:
 *
 *  <div>
 *      <TitleSection />
 *      <DescriptionSection />
 *
 *      <Component />
 *
 *      <ErrorsSection />
 *  </div>
 *
 * Wrapped component is provided with the following props:
 *
 *  - id            {string}                                Identifier of this component
 *  - value         {any}                                   Value to display
 *  - onChange      {(e: SyntheticEvent | any) => void}     Function to call when the value changes
 *  - onFocus       {() => void}                            Function to call when the component receives focus
 *  - readOnly      {bool}                                  Set to true if Form is in readOnly mode
 *  - placeholder   {string}                                Placeholder text to display when input is empty
 *  - autoComplete  {string}                                Value of autocomplete DOM attribute
 *
 * Any additional state machine values can be forwarded by selecting them via options.select function.
 *
 * Wrapped component should also be able to accept a ref (meaning that it should use React.forwardRef
 * if it is a functional component) which should expose focus() method, which will be called when
 * component need to try to acquire focus.
 *
 * The default TitleSection, DescriptionSection and ErrorsSection can be overridden
 * by registering 'Field.Title', 'Field.Description' or 'Field.Errors' (respectively) component
 * type for a desired selector via Form Engine's registerComponentSection(section, selector, component)
 *
 * @export
 * @param {JSX.Element}              Component   A component to wrap
 * @param {WrapFormFieldOptions}     options     Options controlling how HOC is built
 * @returns {JSX.Element}
 */
export function wrapFormField(
    Component: React.ComponentType<any>,
    {
        constructor = FieldElement,
        additionalClassNames = [],
        toModelValue = (value) => value,
        toModelValueInputs = [],
        fromModelValue = (value) => value,
        fromModelValueInputs = [],
        select = () => ({}),
    }: WrapFormFieldOptions = {},
) {
    let WrapperComponent: any = ({ instance, ...props }: any) => {
        const [state, actions] = useStateMachine(instance.machine);
        const { readOnly: readOnlyForm } = useContext(FormContext);

        const onChange = useStableEventHandler(function handleChange(value) {
            if (value && value.target) {
                if (value.target.type === 'checkbox') {
                    value = value.target.checked;
                } else {
                    value = value.target.value;
                }
            }
            const parseInputs: any = {};
            for (const prop of toModelValueInputs) {
                parseInputs[prop] = state[prop];
            }
            actions.dispatch('change', toModelValue(value, parseInputs));
        });

        const onFocus = useStableEventHandler(function handleFocus() {
            actions.dispatch('focus');
        });
        const onBlur = useStableEventHandler(function handleBlur() {
            actions.dispatch('blur');
        });

        const ref: any = useRef(null);

        useFormEngineEvent(instance, 'focus', () => {
            if (!ref.current) {
                return;
            }

            const current = ref.current.inputElement || ref.current;

            // Focus if not already focused or focused on an innner field. Focus
            // on an inner field is more precise, so we leave it be.
            if (!current.contains || !current.contains(document.activeElement)) {
                // EM-21843 Direct call to focus() can prevent form from cleaing
                // corrected input errors.
                // Root cause has not been found, but using setTimeout as
                // general means to resolve timing issues, proves consistently
                // to fixes the issue.
                setTimeout(() => {
                    try {
                        current.focus();
                    } catch (error) {
                        // Silence errors, there's nothing we can do about them
                    }
                }, 0);
            }
        });

        const prevStateName = useRef();
        useEffect(function updatePrevState() {
            prevStateName.current = state.currentState;
        });

        const Title = useMemo(
            () => getComponentSection<Element, typeof TitleSection>('Field.Title', instance),
            [instance],
        );
        const Desc = useMemo(
            () =>
                getComponentSection<Element, typeof DescriptionSection>(
                    'Field.Description',
                    instance,
                ),
            [instance],
        );
        const Errors = useMemo(
            () => getComponentSection<Element, typeof ErrorsSection>('Field.Errors', instance),
            [instance],
        );

        // N.B.: calling select at the top in order to allow it to call hooks
        const {
            readOnly: readOnlyComponent = false,
            note,
            ...selectedProps
        } = select(state, actions);

        const readOnly = readOnlyForm || readOnlyComponent;

        // state.id is always defined unless the state machine is not yet initialized
        // in which case we do not proceed with rendering as the machine is not ready
        if (!state.id || state.visible === false) {
            return null;
        }

        if (
            prevStateName.current !== state.currentState &&
            state.currentState === 'focused' &&
            ref.current &&
            typeof ref.current.focus === 'function'
        ) {
            ref.current.focus();
        }

        const classNames = [
            `e2e-${state.id}-wrapper`,
            `em-field-${state.type}`,
            `em-field-wrap`,
            'em-question-spacing',
        ].concat(
            additionalClassNames,
            // XXX: styleHints are being abused by using them as class names, this needs to be fixed
            state.styleHints,
        );

        if (readOnly) {
            classNames.push('em-read-only');
        }

        if (props.className) {
            classNames.unshift(props.className);
            delete props.className;
        }

        const {
            id,
            title,
            label,
            tooltip,
            description,
            errors,
            placeholder,
            autocomplete = false,
        } = state;

        if (!title) {
            classNames.push('without-title');
        }

        if (errors.size > 0) {
            classNames.push(
                'em-error',
                ...Object.keys(errors.data).map((rule) => `em-error-${rule}`),
            );
        }

        const htmlId = instance.machine.name;

        const formatInputs: any = {};
        for (const prop of fromModelValueInputs) {
            formatInputs[prop] = state[prop];
        }

        const inputProps = {
            ref,
            id: htmlId,
            readOnly,
            placeholder,
            onChange,
            onFocus,
            onBlur,
            value: fromModelValue(state.value, formatInputs),
            autoComplete: autocomplete
                ? tail(id)
                : !autocomplete
                ? uniqueId(tail(id))
                : autocomplete,
            ...props,
            ...selectedProps,
        };

        const style: any = state.enabled ? null : { display: 'none' };

        if ((state.phoenix ?? true) && Component !== GroupField) {
            // NB: had to disable the following ESLint rule because ESLint thinks
            // this assertion is unnecessary while the TSC requires it ¯\_(ツ)_/¯
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
            const messages = Array.from(errors.values()) as InputStatusMessage[];
            return (
                <Form.Field
                    messages={messages}
                    className={classNames.join(' ')}
                    style={style}
                    title={label}
                    tooltip={typeof tooltip === 'string' ? tooltip : undefined}
                >
                    <Component {...inputProps} label={title} />
                    {note ? <Text style="microcopy">{note}</Text> : null}
                </Form.Field>
            );
        }

        return (
            <div className={classNames.join(' ')} style={style}>
                {!Title ? null : <Title {...{ id: htmlId, title, tooltip }} />}
                {!Desc || !description ? null : <Desc {...{ description }} />}
                <Component {...inputProps} />
                {!Errors || errors.size === 0 ? null : <Errors errors={errors} />}
            </div>
        );
    };

    WrapperComponent.displayName = `FormField(${
        typeof Component === 'string'
            ? Component
            : Component.displayName ||
              Component.name ||
              (hasOwnProp(Component, 'render') && hasOwnProp(Component.render, 'name'))
    })`;
    WrapperComponent = React.memo(WrapperComponent, equal);

    return WrapperComponent as React.ComponentType<{ instance: typeof constructor }>;
}

interface TitleSectionProps {
    readonly id: string;
    readonly title: string;
    readonly tooltip?: React.ReactNode;
}

function renderToolTipWithSections(title: string, tooltip: Record<string, string>) {
    let remainingTitle = title;
    const partsOfTitleToExplain = Object.keys(tooltip);
    return partsOfTitleToExplain.map((partOfTitle, index) => {
        const startIndex = remainingTitle.indexOf(partOfTitle);
        const lastIndex = startIndex + partOfTitle.length;
        const beforeTooltipPartOfTitle = remainingTitle.substr(0, startIndex);
        remainingTitle = title.substr(lastIndex);
        return (
            <span key={partOfTitle}>
                {beforeTooltipPartOfTitle.length > 0 && <span>{beforeTooltipPartOfTitle}</span>}
                {partOfTitle} <Tooltip text={tooltip[partOfTitle]} />
                {partsOfTitleToExplain.length === index + 1 && <span>{remainingTitle}</span>}
            </span>
        );
    });
}
export const TitleSection = React.memo(function Title({ id, title, tooltip }: TitleSectionProps) {
    // XXX: this <span> is only necessary because of badly written CSS selectors
    // if we ever cleanup the CSS codebase, remove this!
    const titleElement = title ? (
        <span
            dangerouslySetInnerHTML={{
                __html: container.get<Sanitizer>(Sanitizer).innerHTMLString(title),
            }}
        />
    ) : null;
    return title ? (
        <div className="em-field-label em-question-spacing em-title-section">
            <label htmlFor={id}>
                {tooltip ? (
                    <span>
                        {isObject(tooltip) &&
                        Object.keys(tooltip).every((key) => typeof tooltip[key] === 'string') ? (
                            renderToolTipWithSections(title, tooltip as IndexableObject<string>)
                        ) : (
                            <span>
                                {titleElement}
                                <Tooltip text={tooltip} />
                            </span>
                        )}
                    </span>
                ) : (
                    titleElement
                )}
            </label>
        </div>
    ) : null;
});

registerComponentSection('Field.Title', '@Field', TitleSection, { default: true });

interface DescriptionSectionProps {
    readonly description: string;
}

const DescriptionSection = React.memo(function Description({
    description,
}: DescriptionSectionProps) {
    return (
        <div
            className="em-description em-question-spacing"
            dangerouslySetInnerHTML={{
                __html: container.get<Sanitizer>(Sanitizer).innerHTMLString(description),
            }}
        />
    );
});

registerComponentSection('Field.Description', '@Field', DescriptionSection, { default: true });

interface ErrorsSectionProps {
    readonly errors: [string, string][];
}

const ErrorsSection = React.memo(function Errors({ errors }: ErrorsSectionProps) {
    const errorMessages = [];
    for (const [ruleName, errorText] of errors) {
        errorMessages.push(
            <div key={ruleName} className="em-validation-message">
                {errorText}
            </div>,
        );
    }
    return <div className="em-error-container em-question-spacing">{errorMessages}</div>;
});

registerComponentSection('Field.Errors', '@Field', ErrorsSection, { default: true });

const PreviousValueSection = React.memo(function PreviousValue({ value }: any) {
    return <span className="em-previous-value">{value}</span>;
});

registerComponentSection('Field.PreviousValue', '@Field', PreviousValueSection, { default: true });
