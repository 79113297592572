import {
    ColumnLayout,
    PageLayout,
    Text,
    Hyperlink,
    useResponsive,
    ListLayout,
    Placeholder,
    CenterLayout,
    Pagination,
    Spinner,
} from '@embroker/ui-toolkit/v2';
import React, { useContext, useEffect } from 'react';
import { NotificationViewModel } from '../../entities/Notification';
import { NotificationsContext } from '../../NotificationsContext';
import { useNotificationCenterState } from '../hooks/useNotificationCenterState';
import { Notification } from './Notification';
import { NotificationList } from '../../types/NotificationList';
import { GetActiveNotifications } from '../../useCases/GetActiveNotifications';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';

export function NotificationCenterPage() {
    const { updateContext: updateNotificationContext } = useContext(NotificationsContext);

    const useUseCaseResp = useUseCase(GetActiveNotifications);
    const { isLoading, result } = useUseCaseResp;

    const {
        pagination,
        isNotificationLoadingMap,
        isAllNotificationsLoading,
        pageNotifications,
        unreadNotificationCount,
        updateNotificationStatus,
        updateAllNotificationsStatus,
    } = useNotificationCenterState(NotificationList.getAllNotifications(result));

    useEffect(() => {
        updateNotificationContext({ unreadNotificationCount });
    }, [unreadNotificationCount, updateNotificationContext]);

    const handleMarkAllAsRead = (): void => {
        updateAllNotificationsStatus('read');
    };

    const isBreakpoint = useResponsive({ screenWidth: { smallerThan: 355 } });
    const noNotifications = pageNotifications.length == 0;

    return (
        <React.Fragment>
            <PageLayout.Section>
                <ColumnLayout
                    split="1"
                    center={!isBreakpoint}
                    responsive={{ containerWidth: { smallerThan: 'mobile' } }}
                >
                    <Text style="heading 2">Notifications</Text>
                    <Hyperlink
                        data-e2e="notification-center-mark-all-read"
                        onClick={handleMarkAllAsRead}
                    >
                        Mark all as read
                    </Hyperlink>{' '}
                </ColumnLayout>
            </PageLayout.Section>
            <PageLayout.Section>
                {isLoading ? (
                    <Spinner />
                ) : (
                    <ColumnLayout>
                        <ListLayout>
                            <ListLayout.Body minHeight={noNotifications ? 'small' : 'auto'}>
                                {noNotifications ? (
                                    <Placeholder
                                        title="You don't have any notifications right now."
                                        titleColor="brand-400"
                                        backgroundColor="ui-200"
                                        imageName="illustration-male"
                                    />
                                ) : (
                                    pageNotifications.map((notification: NotificationViewModel) => {
                                        return (
                                            <ListLayout.Item key={notification.id}>
                                                <Notification
                                                    isLoading={
                                                        isNotificationLoadingMap[notification.id] ||
                                                        isAllNotificationsLoading
                                                    }
                                                    onUpdateNotificationStatus={
                                                        updateNotificationStatus
                                                    }
                                                    notificationDto={notification}
                                                />
                                            </ListLayout.Item>
                                        );
                                    })
                                )}
                            </ListLayout.Body>
                            {pagination.totalPages > 1 && !noNotifications && (
                                <ListLayout.Footer>
                                    <CenterLayout>
                                        <Pagination {...pagination} showRelativeButtons="never" />
                                    </CenterLayout>
                                </ListLayout.Footer>
                            )}
                        </ListLayout>
                    </ColumnLayout>
                )}
            </PageLayout.Section>
        </React.Fragment>
    );
}
