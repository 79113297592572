import { container, inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, isOK, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass, execute } from '@embroker/shotwell/core/UseCase';
import { GetActiveOrganizationProfile } from './GetActiveOrganizationProfile';
import { UserOnboardingStepType } from '../types/UserOnboardingDetails';
import { GetUserOnboardingDetails } from './GetUserOnboardingDetails';
import { MailingAddress } from '../types/MailingAddress';
import { Nullable } from '@embroker/ui-toolkit/v2';
import { NAICS_CODE_TO_VERTICAL } from '../types/enums';
import { GetUserOnboardingQuestionnaireData } from './GetUserOnboardingQuestionnaireData';
import { showCoverageRecommendation } from '../../shopping/types/lawBundleWizard';
import { GrowthBookExperimentationService } from '@app/experimentation/services/GrowthBookExperimentationService';

export const NaicsRefinementTypes = ['nonTechConsultant', 'accounting'] as const;
type NaicsRefinementTypesListType = typeof NaicsRefinementTypes;
export type RefinementQuestionType = NaicsRefinementTypesListType[number];

export interface GetUserOnboardingEntrypointPayload {
    stepType: NonNullable<UserOnboardingStepType>;
    stepDetails?: { refinementType: RefinementQuestionType; userNaicsCode: string }; // This type can/should be extended as required
}

export type GetUserOnboardingEntrypointResponse = Nullable<GetUserOnboardingEntrypointPayload>;
export interface GetUserOnboardingEntrypoint extends UseCase {
    execute(): AsyncResult<GetUserOnboardingEntrypointResponse, never>;
}

const NON_TECH_NAICS_CODES_REQUIRE_REFINEMENT = [
    '541611',
    '541612',
    '541613',
    '541614',
    '541618',
    '541620',
];

const ACCOUNTING_NAICS_CODES_REQUIRE_REFINEMENT = ['541219', '541214'];

const ACCOUNTING_DNB_CODES_REQUIRE_REFINEMENT = [
    ...ACCOUNTING_NAICS_CODES_REQUIRE_REFINEMENT,
    '541211',
    '541213',
];

@injectable()
class GetUserOnboardingEntrypointUseCase extends UseCase implements GetUserOnboardingEntrypoint {
    public static type = Symbol('Global/GetUserOnboardingEntrypoint');

    /**
     * constructor for the GetUserOnboardingEntrypoint use case
     * @param eventBus An event bus this Use Case will publish events to.
     * @param entityTypeRepository is the repository used to fetch globaly available data
     */
    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    public async execute(): AsyncResult<GetUserOnboardingEntrypointResponse, never> {
        const getActiveOrganizationProfileResponse = await execute(GetActiveOrganizationProfile);

        const getUserOnboardingDetailsResp = await execute(GetUserOnboardingDetails);
        const UserOnboardingDetailsResp = isOK(getUserOnboardingDetailsResp)
            ? getUserOnboardingDetailsResp.value
            : null;

        if (isErr(getActiveOrganizationProfileResponse)) {
            return Success(null);
        }

        const { value: activeOrganizationProfile } = getActiveOrganizationProfileResponse;
        const {
            organization: { naics, headquarters },
        } = activeOrganizationProfile;

        const isValidHeadquarters = MailingAddress.isValidHeadquarters(headquarters);

        if (!isValidHeadquarters) {
            return Success({ stepType: 'businessLocation' });
        }

        if (!naics) {
            return Success({ stepType: 'naicsConfirmation' });
        }

        const refinementTypeResp = await this.getNaicsRefinementType(naics);
        const refinementType = isOK(refinementTypeResp) ? refinementTypeResp.value : undefined;
        const isNaicsRefined = naics === UserOnboardingDetailsResp?.refinedNaicsCode;

        if (refinementType && !isNaicsRefined) {
            return Success({
                stepType: 'naicsRefinement',
                stepDetails: { refinementType, userNaicsCode: naics },
            });
        }

        const verticalRefinement = await this.getVerticalRefinementType(naics);
        if (isOK(verticalRefinement) && verticalRefinement.value && showCoverageRecommendation()) {
            // This may not be the best way to handle this behaviour but it does the job for now
            // As things scale we may need to begin subdividing onboarding flows,
            // ie. an 'all user flow', then vertical specific flows
            return Success({ stepType: verticalRefinement.value });
        }

        if (
            NAICS_CODE_TO_VERTICAL[naics] === 'TechCompanies' &&
            container
                .get<GrowthBookExperimentationService>(GrowthBookExperimentationService)
                .getFeatureValue('tech-vertical-one-by-embroker', false)
        ) {
            return Success({ stepType: 'staffDetailsPage' });
        }

        return Success(null);
    }

    private async getVerticalRefinementType(
        naics: Nullable<string>,
    ): AsyncResult<UserOnboardingStepType | undefined, never> {
        const vertical = naics && NAICS_CODE_TO_VERTICAL[naics];
        const getUserOnboardingQuestionnaireDataResp = await execute(
            GetUserOnboardingQuestionnaireData,
        );
        if (vertical === 'LawFirm') {
            const lawVerticalRefinementData = isOK(getUserOnboardingQuestionnaireDataResp)
                ? getUserOnboardingQuestionnaireDataResp.value.lawVerticalRefinementData
                : null;

            const refinementType = !lawVerticalRefinementData ? 'lawVerticalRefinement' : null;
            return Success(refinementType);
        }

        return Success(undefined);
    }

    private async getNaicsRefinementType(
        naics: Nullable<string>,
    ): AsyncResult<RefinementQuestionType | undefined, never> {
        const isNonTechRefinementRequiredResp = await this.isNonTechRefinementRequired(naics);
        const isNonTechRefinementRequired = isOK(isNonTechRefinementRequiredResp)
            ? isNonTechRefinementRequiredResp.value
            : false;

        if (isNonTechRefinementRequired) {
            return Success('nonTechConsultant');
        }

        const isAccountingRefinementRequiredResp = await this.isAccountingRefinementRequired(naics);
        const isAccountingRefinementRequired = isOK(isAccountingRefinementRequiredResp)
            ? isAccountingRefinementRequiredResp.value
            : false;

        if (isAccountingRefinementRequired) {
            return Success('accounting');
        }
        return Success(undefined);
    }

    private async isNonTechRefinementRequired(
        naics: Nullable<string>,
    ): AsyncResult<boolean, never> {
        const nonTechRefinementRequired = Boolean(
            NON_TECH_NAICS_CODES_REQUIRE_REFINEMENT.find((naicsCode) => naicsCode === naics),
        );
        return Success(nonTechRefinementRequired);
    }

    private async isAccountingRefinementRequired(
        naics: Nullable<string>,
    ): AsyncResult<boolean, never> {
        const dnbNaicsResp = await execute(GetUserOnboardingDetails);
        if (!isOK(dnbNaicsResp)) {
            return Success(false);
        }

        const dnbNaics = dnbNaicsResp.value?.dnbNaicsCode;
        // If the submitted naics value is one of the codes in the NAICS_CODES_REQUIRE_REFINEMENT
        // We ask the user a refinement question
        const naicsRequiresRefinement = Boolean(
            ACCOUNTING_NAICS_CODES_REQUIRE_REFINEMENT.find((naicsCode) => naicsCode === naics),
        );

        // If the submitted naics value is one of the codes in the DNB_CODES_REQUIRE_REFINEMENT
        // ie if the naics input is prepopulated by DnB and the user submits the same code
        // We ask the user a refinement question
        const dnbNaicsRequiresRefinement =
            naics === dnbNaics &&
            ACCOUNTING_DNB_CODES_REQUIRE_REFINEMENT.some((naicsCode) => naicsCode === naics);

        return Success(naicsRequiresRefinement || dnbNaicsRequiresRefinement);
    }
}

export const GetUserOnboardingEntrypoint: UseCaseClass<GetUserOnboardingEntrypoint> =
    GetUserOnboardingEntrypointUseCase;
