import React from 'react';
import { Form, CheckBoxGroup } from '@embroker/ui-toolkit/v2';
import {
    ComplexFormFieldViewProps,
    getFormFieldProps,
    getInputProps,
} from '../../types/ComplexFieldTypes';

export function assertInputValueAsArray(input: unknown): input is unknown[] {
    return Array.isArray(input);
}

export function MultiSelectFieldView(complexFormFieldViewProps: ComplexFormFieldViewProps) {
    const { questionProps, inputFieldProps } = complexFormFieldViewProps;
    const formFieldProps = getFormFieldProps(questionProps);
    const inputProps = getInputProps(questionProps, inputFieldProps);
    const inputValue = assertInputValueAsArray(inputProps.value) ? inputProps.value : undefined;
    return (
        <Form.Field {...formFieldProps}>
            <CheckBoxGroup appearance="border" {...{ ...inputProps, value: inputValue }} />
        </Form.Field>
    );
}
