import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { map, redirect, route, withData } from 'navi';
import React from 'react';
import { redirectToWhoopsPage } from '../../../view/errors';
import { ESPQuoteLandingPage } from '../../esp/view/components/ESPQuoteLandingPage';
import { URI } from '@embroker/shotwell/core/types/URI';
import { container } from '@embroker/shotwell/core/di';
import { GrowthBookExperimentationService } from '@app/experimentation/services/GrowthBookExperimentationService';

const espWizardRouteHandler = map(async (request) => {
    const { applicationId } = request.query;
    if (!UUID.check(applicationId)) {
        return redirectToWhoopsPage([
            InvalidArgument({ argument: 'applicationId', value: applicationId, validator: 'UUID' }),
        ]);
    }

    return route({
        title: 'ESP quotes',
        view: <ESPQuoteLandingPage applicationId={applicationId} />,
    });
});

export const espRoutes = {
    '/': map((request) => {
        if (
            container
                .get<GrowthBookExperimentationService>(GrowthBookExperimentationService)
                .getFeatureValue('tech-vertical-one-by-embroker', false)
        ) {
            const applicationId = request.params.applicationId;
            return redirect(URI.build('/shopping/bundle', { applicationId }));
        }

        return withData(
            { url: request.mountpath, page: undefined, fullscreen: true },
            espWizardRouteHandler,
        );
    }),

    '/:page': map((request) => {
        if (
            container
                .get<GrowthBookExperimentationService>(GrowthBookExperimentationService)
                .getFeatureValue('tech-vertical-one-by-embroker', false)
        ) {
            const applicationId = request.params.applicationId;
            return redirect(URI.build('/shopping/bundle', { applicationId }));
        }

        return withData(
            { url: request.mountpath, page: request.params.page, fullscreen: true },
            espWizardRouteHandler,
        );
    }),
};
