import { UUID } from '@embroker/shotwell/core/types/UUID';
import {
    BoxLayout,
    Button,
    ColumnLayout,
    Image,
    StackLayout,
    Text,
    WizardLayout,
    useResponsive,
} from '@embroker/ui-toolkit/v2';
import { useNavigation } from '../../../view/hooks/useNavigation';
import React, { useContext } from 'react';
import { URI } from '@embroker/shotwell/core/types/URI';
import { hasRole } from '../../../userOrg/entities/Session';
import { AppContext } from '../../../view/AppContext';

interface ThankYouPageProps {
    applicationId: UUID;
}
export function ThankYouPage({ applicationId }: ThankYouPageProps) {
    const { navigate } = useNavigation();
    const { activeSession } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');

    const isMobile = useResponsive({ screenWidth: { smallerThan: 'tablet' } });

    const navigateToQuotePage = () => {
        navigate(
            URI.build('/shopping/application/quote/esp/coverage', {
                applicationId: applicationId,
            }),
        );
    };

    const navigateToDashboard = () => {
        navigate(URI.build('/summary'));
    };

    return (
        <WizardLayout progress={0} title="Higher Limits Request" onDismiss={navigateToDashboard}>
            <WizardLayout.Cover>
                <ColumnLayout
                    responsive={{ containerWidth: { smallerThan: 'tablet' } }}
                    gap={isMobile ? '32' : 'none'}
                >
                    <StackLayout className="u-1/2@large-tablet u-1/1" gap="24">
                        <Text style="heading 1" color="brand-400" data-e2e="application-received">
                            Application received
                        </Text>
                        <Text style="body 1">
                            Our underwriter will review your application shortly and will be in
                            touch with you via email.
                        </Text>

                        <StackLayout gap={isMobile ? 'none' : '24'}>
                            <span />

                            <ColumnLayout
                                gap="16"
                                responsive={{ containerWidth: { smallerThan: 430 } }}
                            >
                                {isBroker ? (
                                    <Button onClick={navigateToQuotePage} data-e2e="back-to-quote">
                                        Back to Quote
                                    </Button>
                                ) : null}
                                <Button
                                    appearance={isBroker ? 'ghost' : 'primary'}
                                    onClick={navigateToDashboard}
                                    data-e2e="go-to-dashboard"
                                >
                                    Go to Dashboard
                                </Button>
                            </ColumnLayout>
                        </StackLayout>
                    </StackLayout>

                    <BoxLayout className="u-1/2@large-tablet u-1/1">
                        <Image width="100%" name="illustration-laurel" />
                    </BoxLayout>
                </ColumnLayout>
            </WizardLayout.Cover>
        </WizardLayout>
    );
}
