import type * as APIType from '@embroker/shotwell-api/app';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { keysOf } from '@embroker/shotwell/core/object';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import {
    ActionHandler,
    CreateFormParams,
    FormAction,
    OpaqueForm,
} from '@embroker/shotwell/view/hooks/useForm';
import { GetDocumentUrl } from '../../../documents/useCases/GetDocumentUrl';
import { QuotingEngineBOPChubb } from '../../../shopping/types/enums';
import { WizardForm } from '../../../view/hooks/useWizardForm';
import { BundleQuote } from '../../entities/BundleQuote';
import {
    BundleCoverageAppStatusEnum,
    BundleCoverageType,
    BundleCoverageTypeEnum,
} from '../../types/BundleQuoteCoverage';
import { DocumentType } from '../../types/BundleQuoteDocument';
import { BuildBundleDocumentListProps } from '../../view/components/buildBundleDocumentList';
import { DocumentsItem } from '../../view/components/BundleQuoteLandingPage';
import { DownloadDocumentButton } from '../../view/components/DownloadDocumentButton';
import { GenerateDocumentButton } from '../../view/components/GenerateDocumentButton';
import { getDocumentUrl, getStaticDocumentUrl } from '../bundleMappingFunctions';
import {
    BOPChubb,
    BOPChubbDocumentTitle,
    BOPChubbProductName,
    BOPChubbProductTitle,
    CoverageDefinition,
    distributedPrefix,
    GetDocumentDownloadMetadataResponse,
    ProductFormData,
} from '../coverageDefinition';
import { bopChubbFormDataType } from './bopChubbFormData';
import { buildBOPChubbCoverage } from './buildBOPChubbCoverage';
import { BOPChubbBundleCoverageOptions } from './components/BOPChubbBundleCoverageOptions';
import { BOPChubbCoverageModalSummary } from './components/BOPChubbCoverageModalSummary';
import { BOPChubbFooter } from './components/BOPChubbFooter';
import { BOPChubbQuoteSummary } from './components/BOPChubbQuoteSummary';
import { BOPChubbQuote } from './entities/BOPChubbQuote';
import {
    BOPChubbAggregateLimit,
    BOPChubbPerOccurrenceLimit,
    BOPChubbProductCode,
    BOPChubbQuoteOptions,
} from './types/BOPChubbQuoteOptions';
import { getRecommendedQuotingOptionsBopChubb } from './recommendedQuotingOptions';

enum ChubbSpecimenType {
    GL = 'https://embroker.s3.us-west-2.amazonaws.com/Chubb_GL_PolicySpecimen.pdf',
    GL_AUTO = 'https://embroker.s3.us-west-2.amazonaws.com/Chubb_GL_Auto_PolicySpecimen.pdf',
    GL_PROPERTY = 'https://embroker.s3.us-west-2.amazonaws.com/Chubb_GL_Prop_PolicySpecimen.pdf',
    GL_PROPERTY_AUTO = 'https://embroker.s3.us-west-2.amazonaws.com/Chubb_GL_Prop_Auto_PolicySpecimen.pdf',
}

export const BOPChubbCoverageDefinition: CoverageDefinition<BOPChubbQuote> = {
    isLimitHigherThenAllowed(
        formValue: distributedPrefix<BOPChubbQuoteOptions>,
        coverageSelected: boolean,
    ): boolean {
        return false;
    },
    onSuccessHandler(
        value: any,
        action: string,
        setDocumentUrl: (
            url: URI,
            documentType: DocumentType,
            bundleCoverageType: BundleCoverageType,
        ) => void,
        onGenerateDocument: () => void,
    ) {
        switch (action) {
            case 'generateBOPChubbQuoteDocument':
                setDocumentUrl(
                    value.fileUrl,
                    DocumentType.QuoteDocument,
                    BundleCoverageTypeEnum.BOPChubbBundleCoverage,
                );
                onGenerateDocument();
                break;
            case 'downloadBOPChubbGeneralTerms':
                window.open(value.fileUrl as string, '_blank');
                break;
        }
    },
    productName: BOPChubbProductName,
    productTitle: BOPChubbProductTitle,
    documentTitle: BOPChubbDocumentTitle,
    type: BundleCoverageTypeEnum.BOPChubbBundleCoverage,
    quotingEngine: QuotingEngineBOPChubb,
    async buildCoverage(quoteExtended, isBroker) {
        return buildBOPChubbCoverage(quoteExtended, isBroker);
    },
    mapFormDataToQuoteOptions(formData: any): BOPChubbQuoteOptions | undefined {
        if (!isBOPChubbFormData(formData)) {
            return undefined;
        }
        return {
            effectiveDate: formData.effectiveDate,
            perOccurrenceLimit: formData.bopChubbPerOccurrenceLimit,
            aggregateLimit: formData.bopChubbAggregateLimit,
            isDeselected: !formData.bopChubbSelected,
            productCode: formData.bopChubbProductCode,
            omitHiredAndNonOwnedAutoCoverage: formData.bopChubbOmitHiredAndNonOwnedAutoCoverage,
        };
    },
    getRecomendedQuoteOptions: getRecommendedQuotingOptionsBopChubb,
    apiProductDesignation: BOPChubb,
    mapQuoteOptionsToAPI(options: BOPChubbQuoteOptions): APIType.BopChubbQuoteOptions {
        return {
            effective_date: options.effectiveDate,
            is_deselected: options.isDeselected,
            omit_hired_non_owned_auto_coverage: options.omitHiredAndNonOwnedAutoCoverage,
            per_occurrence_limit: options.perOccurrenceLimit,
            aggregate_limit: options.aggregateLimit,
            product_code: options.productCode,
        };
    },
    coverageOptionsComponent: BOPChubbBundleCoverageOptions,
    summaryComponent: BOPChubbQuoteSummary,
    modalSummaryComponent: BOPChubbCoverageModalSummary,
    footerComponent: BOPChubbFooter,
    isFieldsValidType(
        input: any,
    ): input is WizardForm<OpaqueForm<distributedPrefix<BOPChubbQuoteOptions>>>['fields'] {
        return isBOPChubbFormData(input);
    },
    isOptionsValidType(input: any): input is BOPChubbQuoteOptions {
        return isBOPChubbQuoteOptions(input);
    },
    getProductFields(
        bundleQuote: BundleQuote,
    ): CreateFormParams<ProductFormData<distributedPrefix<BOPChubbQuoteOptions>>>['fields'] {
        return {
            bopChubbPerOccurrenceLimit: {
                type: 'select',
                validator: Joi.number().valid(...BOPChubbPerOccurrenceLimit),
            },
            bopChubbAggregateLimit: {
                type: 'select',
                validator: Joi.number().valid(...BOPChubbAggregateLimit),
            },
            bopChubbProductCode: {
                type: 'select',
                validator: Joi.string().valid(...BOPChubbProductCode),
            },
            bopChubbOmitHiredAndNonOwnedAutoCoverage: {
                type: 'checkbox',
                validator: Joi.boolean(),
            },
            bopChubbSelected: {
                type: 'checkbox',
                validator: Joi.boolean(),
            },
        };
    },
    getInitialValues(bundleQuote): Record<string, unknown> {
        const chubbBOPCoverage = bundleQuote.coverageList.find(
            (coverage) => coverage.type === this.type,
        );

        // TODO: improve error handling
        if (
            chubbBOPCoverage === undefined ||
            chubbBOPCoverage.quote === undefined ||
            !this.isOptionsValidType(chubbBOPCoverage.quote.options)
        ) {
            return {};
        }

        const chubbBOPOptions = chubbBOPCoverage.quote.options;
        return {
            bopChubbPerOccurrenceLimit: chubbBOPOptions.perOccurrenceLimit,
            bopChubbAggregateLimit: chubbBOPOptions.aggregateLimit,
            bopChubbProductCode: chubbBOPOptions.productCode,
            bopChubbOmitHiredAndNonOwnedAutoCoverage:
                chubbBOPOptions.omitHiredAndNonOwnedAutoCoverage,
            bopChubbSelected: !chubbBOPOptions.isDeselected,
        };
    },
    getProductActions(
        bundleQuote: BundleQuote,
        abortSignal: AbortSignal,
    ): Record<
        FormAction,
        | {
              action: ActionHandler<BOPChubbQuoteOptions>;
              fields: (keyof distributedPrefix<BOPChubbQuoteOptions>)[];
          }
        | ActionHandler<ProductFormData<BOPChubbQuoteOptions>>
    > {
        const formFields = this.getProductFields(bundleQuote);
        return {
            generateBOPChubbQuoteDocument: {
                action: async () => {
                    return getDocumentUrl(
                        bundleQuote,
                        BundleCoverageTypeEnum.BOPChubbBundleCoverage,
                        DocumentType.QuoteDocument,
                        abortSignal,
                    );
                },
                fields: ['effectiveDate', ...keysOf(formFields)],
            },
            downloadBOPChubbGeneralTerms: {
                action: async () => {
                    return getStaticDocumentUrl(
                        DocumentType.GeneralTerms,
                        BundleCoverageTypeEnum.BOPChubbBundleCoverage,
                        bundleQuote,
                    );
                },
                fields: ['effectiveDate', ...keysOf(formFields)],
            },
        };
    },
    getCoverageDocuments({
        bundleQuote,
        documents,
        trigger,
        isDirty,
        value,
    }: BuildBundleDocumentListProps): DocumentsItem[] | undefined {
        const bopChubbCoverage = bundleQuote.getCoverageByCoverageType(
            BundleCoverageTypeEnum.BOPChubbBundleCoverage,
        );
        const shouldIncludeBopChubbDocs =
            !bundleQuote.isCoverageReferred(BundleCoverageTypeEnum.BOPChubbBundleCoverage) &&
            bundleQuote.isCoverageEnabled(BundleCoverageTypeEnum.BOPChubbBundleCoverage) &&
            bopChubbCoverage?.status !== BundleCoverageAppStatusEnum.NotEligible &&
            value.bopChubbSelected;

        if (!shouldIncludeBopChubbDocs) {
            return undefined;
        }

        const bopChubbQuoteDocument: DocumentsItem = {
            component: GenerateDocumentButton,
            isDisabled: isDirty,
            documentType: DocumentType.QuoteDocument,
            fileUrl: documents.find(
                (document) =>
                    document.documentType === DocumentType.QuoteDocument &&
                    document.coverageType === BundleCoverageTypeEnum.BOPChubbBundleCoverage,
            )?.url,
            handleTrigger: () => trigger('generateBOPChubbQuoteDocument'),
            coverageType: BundleCoverageTypeEnum.BOPChubbBundleCoverage,
        };
        const bopChubbGeneralTerms: DocumentsItem = {
            component: DownloadDocumentButton,
            isDisabled: isDirty,
            documentType: DocumentType.GeneralTerms,
            handleTrigger: () => trigger('downloadBOPChubbGeneralTerms'),
            coverageType: BundleCoverageTypeEnum.BOPChubbBundleCoverage,
        };
        return [bopChubbQuoteDocument, bopChubbGeneralTerms];
    },
    toggleSelected(value: boolean): Record<string, unknown> {
        return { bopChubbSelected: value };
    },
    getMaxFutureDaysAllowed(): number {
        return 90;
    },
    async getDocumentDownloadMetadata(
        documentType: DocumentType,
        bopChubbQuote?: BOPChubbQuote,
    ): AsyncResult<GetDocumentDownloadMetadataResponse, OperationFailed | InvalidArgument> {
        if (bopChubbQuote === undefined) {
            return Failure(InvalidArgument({ argument: 'bopChubbQuote', value: documentType }));
        }

        if (documentType === DocumentType.GeneralTerms) {
            const specimenUrl = getPolicySpecimenUrl(bopChubbQuote);
            return Success({ fileKey: '', downloadUrl: specimenUrl });
        }

        let fileKey;
        switch (documentType) {
            case DocumentType.QuoteDocument:
                fileKey = bopChubbQuote.fileKey;
                break;
            default:
                break;
        }
        if (fileKey === undefined) {
            return Failure(InvalidArgument({ argument: 'fileKey', value: documentType }));
        }
        const downloadUrlResult = await execute(GetDocumentUrl, {
            fileKey: fileKey,
        });
        if (isErr(downloadUrlResult)) {
            return downloadUrlResult;
        }
        return Success({
            fileKey: fileKey,
            downloadUrl: downloadUrlResult.value.downloadUrl,
        });
    },
    getPremiumRange() {
        return undefined;
    },
};

function isBOPChubbQuoteOptions(options: any): options is BOPChubbQuoteOptions {
    return (
        'effectiveDate' in options &&
        'perOccurrenceLimit' in options &&
        'aggregateLimit' in options &&
        'omitHiredAndNonOwnedAutoCoverage' in options &&
        'productCode' in options
    );
}

function isBOPChubbFormData(input: any): input is bopChubbFormDataType {
    return (
        'bopChubbPerOccurrenceLimit' in input &&
        'bopChubbAggregateLimit' in input &&
        'bopChubbProductCode' in input &&
        'bopChubbOmitHiredAndNonOwnedAutoCoverage' in input &&
        'bopChubbSelected' in input
    );
}

function getPolicySpecimenUrl(quote: BOPChubbQuote): ChubbSpecimenType {
    if (quote.options.productCode === 'GeneralLiability') {
        if (quote.options.omitHiredAndNonOwnedAutoCoverage) {
            return ChubbSpecimenType.GL;
        } else {
            return ChubbSpecimenType.GL_AUTO;
        }
    } else {
        if (quote.options.omitHiredAndNonOwnedAutoCoverage) {
            return ChubbSpecimenType.GL_PROPERTY;
        } else {
            return ChubbSpecimenType.GL_PROPERTY_AUTO;
        }
    }
}
