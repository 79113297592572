import { Nullable } from '@embroker/shotwell/core/types';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import {
    Card,
    ColumnLayout,
    Hyperlink,
    StackLayout,
    StatusLabel,
    Text,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import * as enums from '../../../shopping/types/enums';

enum PolicyAutoRenewalStatus {
    pending = 'pending',
    approved = 'approved',
}

export interface AppTypeData {
    name: string;
    description: string;
    icon: JSX.Element;
    estimatedTime: string;
}

export interface InsuranceApplicationCardProps {
    status: enums.InsuranceApplicationStatusCode;
    appType: AppTypeData;
    action: JSX.Element;
    menuItems: JSX.Element[];
    estimatedTimeToComplete: Nullable<string>;
    policyExpirationDate: Nullable<Date>;
    daysToQuoteExpiration?: Nullable<number>;
}

const getQuoteExpirationLabel = (daysToExpire: number | undefined): string => {
    if (!daysToExpire) {
        return '';
    }

    return daysToExpire === -1
        ? 'Quote expired'
        : daysToExpire === 0
        ? 'Quote expires today'
        : daysToExpire === 1
        ? 'Quote expires in ' + daysToExpire + ' day'
        : 'Quote expires in ' + daysToExpire + ' days';
};

export function InsuranceApplicationCard({
    status,
    appType,
    action,
    menuItems,
    estimatedTimeToComplete,
    policyExpirationDate,
    daysToQuoteExpiration,
}: InsuranceApplicationCardProps) {
    // TODO: https://embroker.atlassian.net/browse/EM-43813
    // calculate the policy auto-renewal status based on props.
    // the value should be either
    // PolicyAutoRenewalStatus.pending
    // or
    // PolicyAutoRenewalStatus.approved
    //
    // (Note: we'd only want to set this variable if it's BOP Chubb or WC)
    const autoRenewalStatus: PolicyAutoRenewalStatus | null = null;

    return (
        <Card menuItems={menuItems.length > 0 ? menuItems : null}>
            <Card.Header>
                {appType.icon}
                <Text style="heading 5">{appType.name}</Text>
            </Card.Header>
            <Card.Body>
                {autoRenewalStatus === PolicyAutoRenewalStatus.pending && (
                    <Text>
                        The insurer is currently reviewing your policy, as it may be eligible for
                        automatic renewal. Please check both your mailbox and emails for updates,
                        notices, or renewal forms.
                    </Text>
                )}
                {autoRenewalStatus === PolicyAutoRenewalStatus.approved && (
                    <Text>
                        Please review your auto-renewed policy, now available in your account, and
                        notify us immediately if you require any changes or corrections. Have
                        questions?{' '}
                        <Hyperlink href="mailto:serviceteam@embroker.com">Contact us</Hyperlink>.
                    </Text>
                )}
                {!autoRenewalStatus && <Text>{appType.description}</Text>}

                {daysToQuoteExpiration !== null &&
                    status !== enums.Referred &&
                    status !== enums.ReferredAwaitingReview && (
                        <Text style="microcopy">
                            {getQuoteExpirationLabel(daysToQuoteExpiration)}
                        </Text>
                    )}
            </Card.Body>
            <Card.Footer>
                <StackLayout split="-1" gap={estimatedTimeToComplete ? '8' : 'none'}>
                    {!autoRenewalStatus &&
                    status === enums.QuestionnaireInProgress &&
                    !!estimatedTimeToComplete ? (
                        <Text style="microcopy">Approximately {estimatedTimeToComplete}m</Text>
                    ) : null}
                    <ColumnLayout split={policyExpirationDate ? '-1' : ''}>
                        {!autoRenewalStatus && action}
                        {autoRenewalStatus === PolicyAutoRenewalStatus.pending && (
                            <StatusLabel color="accent-100">RENEWAL DECISION PENDING</StatusLabel>
                        )}
                        {autoRenewalStatus === PolicyAutoRenewalStatus.approved && (
                            <StatusLabel color="positive-500">AUTO-RENEWAL APPROVED</StatusLabel>
                        )}
                        {policyExpirationDate && (
                            <Text style="microcopy">
                                {new Date(Date.now()) <= new Date(policyExpirationDate)
                                    ? 'Expires'
                                    : 'Expired'}
                                <br />
                                <DateDisplay
                                    format="MMM dd, yyyy"
                                    value={new Date(policyExpirationDate)}
                                />
                            </Text>
                        )}
                    </ColumnLayout>
                </StackLayout>
            </Card.Footer>
        </Card>
    );
}
