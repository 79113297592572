import React, { useEffect, useContext, useState } from 'react';
import {
    Button,
    ButtonBar,
    GridLayout,
    Nullable,
    PageLayout,
    StackLayout,
    Text,
    usePageLayout,
    useResponsive,
} from '@embroker/ui-toolkit/v2';
import { UnderlyingApp } from '../../../useCases/GetLawBundleApplications';
import { CoverageInformationSlideout } from './CoverageInformationSlideout.view';
import { AppContext } from '../../../../view/AppContext';
import {
    BOPCoverageRecommendationCard,
    CyberCoverageRecommendationCard,
    LPLCoverageRecommendationCard,
    WCCoverageRecommendationCard,
} from './CoverageRecommendationLayoutCardTemplates';
import { CoverageType } from '../../../types/CoverageType';
import { AppTypeCode } from '../../../types/enums';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Applicant } from '../../../entities/Applicant';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { GetApplicant } from '../../../useCases/GetApplicant';
import { HeaderView } from '../../../../view/components/Header.view';
import { GetActiveOrganizationProfile } from '../../../../userOrg/useCases/GetActiveOrganizationProfile';

interface CoverageRecommendationLayoutProps {
    radioItems: UnderlyingApp[];
    handleGetStarted: () => void;
    changeApplicationSelection: (value: string) => void;
}

type AppTypesProps = Record<CoverageType, AppTypeCode>;

const AppTypes: AppTypesProps = {
    bop: 'AppTypeCodeListBOPChubb',
    cyber: 'AppTypeCodeListCyberCowbell',
    lpl: 'AppTypeCodeListEverestLawyersProfessionalLiability',
    wc: 'AppTypeCodeListWCChubb',
};

export function CoverageRecommendationLayout({
    radioItems,
    handleGetStarted,
    changeApplicationSelection,
}: CoverageRecommendationLayoutProps) {
    const { setSlideout } = useContext(AppContext);
    const [applicant, setApplicant] = useState<Nullable<EntityProps<Applicant>>>(null);
    const { result: getApplicantResult } = useUseCase(GetApplicant);

    useEffect(() => {
        if (getApplicantResult && isOK(getApplicantResult)) {
            setApplicant(getApplicantResult.value.applicant as Applicant);
        }
    }, [getApplicantResult]);
    const isTabletBreakpoint = useResponsive({ screenWidth: { smallerThan: 'tablet' } });
    const { result: getActiveOrganizationProfileResp } = useUseCase(GetActiveOrganizationProfile);
    const organizationName =
        getActiveOrganizationProfileResp && isOK(getActiveOrganizationProfileResp)
            ? getActiveOrganizationProfileResp.value.organization.companyLegalName
            : undefined;

    const pageLayout = usePageLayout();
    const { setHeader } = pageLayout;
    // TODO: Due to the setup of LawBundleWizard, we have to do some hacky things to accomplish the designs
    // The outcome of this SPIKE will address these issues longer term
    // https://embroker.atlassian.net/browse/EM-37307
    useEffect(() => {
        // This is not ideal, but due to the nature of how setHeader saves the header component in state,
        // we need to set the header every time handleGetStarted is updated; otherwise, the state managed within that callback will be out of sync.
        setHeader(
            <HeaderView>
                {!isTabletBreakpoint && (
                    <Button onClick={handleGetStarted}>Start Application</Button>
                )}
            </HeaderView>,
        );
    }, [isTabletBreakpoint, handleGetStarted, setHeader]);

    const onCoverageShowSlideout = (coverageType: CoverageType) => {
        setSlideout(
            <CoverageInformationSlideout
                appTypeCode={AppTypes[coverageType]}
                naicsCode={applicant?.naicsIndustry}
                onClose={() => setSlideout(null)}
            />,
        );
    };

    const lplCoverage = radioItems.find((item) => item.value === CoverageType.LPL);
    const cyberCoverage = radioItems.find((item) => item.value === CoverageType.Cyber);
    const bopCoverage = radioItems.find((item) => item.value === CoverageType.BOP);
    const wcCoverage = radioItems.find((item) => item.value === CoverageType.WC);

    const bobCard = bopCoverage && (
        <BOPCoverageRecommendationCard
            ineligibilityReasons={bopCoverage.ineligibilityReasons}
            lawVerticalRefinementData={bopCoverage.lawVerticalRefinementData}
            isSelected={bopCoverage.isSelected}
            onTitleTooltipClick={() => onCoverageShowSlideout(CoverageType.BOP)}
            onCoverageSelectionChange={() => changeApplicationSelection(CoverageType.BOP)}
        />
    );
    const wcCard = wcCoverage && (
        <WCCoverageRecommendationCard
            ineligibilityReasons={wcCoverage.ineligibilityReasons}
            lawVerticalRefinementData={wcCoverage.lawVerticalRefinementData}
            isSelected={wcCoverage.isSelected}
            onTitleTooltipClick={() => onCoverageShowSlideout(CoverageType.WC)}
            onCoverageSelectionChange={() => changeApplicationSelection(CoverageType.WC)}
        />
    );

    const isBopIneligible = Boolean(bopCoverage?.ineligibilityReasons?.length);
    const isWcEssential =
        wcCoverage?.ineligibilityReasons?.length === 0 &&
        wcCoverage?.lawVerticalRefinementData?.hasEmployees;

    return (
        <PageLayout {...pageLayout}>
            <PageLayout.Section>
                <StackLayout gap="48">
                    <GridLayout>
                        <Text color="brand-400" style="heading 1" className="u-grid-size-12">
                            {organizationName ? `${organizationName}, start` : 'Start'} covering
                            your bases with some of our most essential coverages.
                        </Text>
                    </GridLayout>
                    {isTabletBreakpoint && (
                        <GridLayout>
                            <ButtonBar
                                className="u-grid-size-12"
                                responsive={{ screenWidth: { smallerThan: 'desktop' } }}
                            >
                                <Button onClick={handleGetStarted}>Start Application</Button>
                            </ButtonBar>
                        </GridLayout>
                    )}
                    <GridLayout>
                        {lplCoverage && (
                            <LPLCoverageRecommendationCard
                                isSelected={lplCoverage.isSelected}
                                onTitleTooltipClick={() => onCoverageShowSlideout(CoverageType.LPL)}
                                onCoverageSelectionChange={() =>
                                    changeApplicationSelection(CoverageType.LPL)
                                }
                            />
                        )}
                        {cyberCoverage && (
                            <CyberCoverageRecommendationCard
                                isSelected={cyberCoverage.isSelected}
                                onTitleTooltipClick={() =>
                                    onCoverageShowSlideout(CoverageType.Cyber)
                                }
                                onCoverageSelectionChange={() =>
                                    changeApplicationSelection(CoverageType.Cyber)
                                }
                            />
                        )}
                        {isBopIneligible || isWcEssential ? (
                            <React.Fragment>
                                {wcCard} {bobCard}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {bobCard} {wcCard}
                            </React.Fragment>
                        )}
                    </GridLayout>
                </StackLayout>
            </PageLayout.Section>
        </PageLayout>
    );
}
